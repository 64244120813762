<section class="aayn-language-selection" [ngClass]="{ active: overlay.render }">
  <button
    class="aayn-language-selection--selected-btn"
    (click)="overlay.toggle($event)"
    [ngClass]="{ active: overlay.render }"
  >
    <img src="/assets/images/svgs/languages/{{ selectedLanguage }}.svg" [alt]="selectedLanguage.toLocaleUpperCase()" />
  </button>

  <ayn-overlay #overlay styleClass="aayn-language-selection--body" [useOverlayBlur]="true">
    <ul class="aayn-language-selection--body__list">
      <li
        *ngFor="let language of languages"
        [ngClass]="{ selected: language === selectedLanguage }"
        (click)="setSelectedLanguage(language)"
      >
        <img [src]="'assets/images/svgs/languages/' + language + '.svg'" [alt]="language?.toLocaleUpperCase()" />
      </li>
    </ul>
  </ayn-overlay>
</section>
