import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ChargebeeService, ICoupon, MySubscription, MySubscriptionResponse } from '../';

/**
 * Models
 */
export namespace ChargebeeState {
  export interface State {
    subscription: MySubscription | null;
    appliedCoupons: ICoupon[];
  }
}

/**
 * Actions
 */
export class SetMySubscription {
  static readonly type = '@@SET_MY_SUBSCRIPTION';

  constructor(public payload: MySubscriptionResponse) {}
}

export class GetMySubscription {
  static readonly type = '@@GET_MY_SUBSCRIPTION';

  constructor() {}
}

export class GetAppliedCoupons {
  static readonly type = '@@GET_APPLIED_COUPONS';

  constructor() {}
}

/**
 * Defaults
 */
export const CHARGEBEE_STATE_DEFAULT: ChargebeeState.State = {
  subscription: null,
  appliedCoupons: []
};

@State<ChargebeeState.State>({
  name: 'ChargebeeState',
  defaults: CHARGEBEE_STATE_DEFAULT
})
@Injectable({
  providedIn: 'root'
})
export class ChargebeeState {
  constructor(private chargebeeService: ChargebeeService, private modalService: NgbModal) {}

  @Selector()
  static subscription({ subscription }: ChargebeeState.State): MySubscription | null {
    const isAppsumoPackage = subscription?.package.name.toLowerCase().includes('appsumo');

    const displayName = isAppsumoPackage ? 'Lifetime Ultimate' : subscription?.package.displayName;

    const price = isAppsumoPackage ? 499 : subscription?.package.price;

    if (subscription) {
      subscription.package.displayName = displayName!;
      subscription.package.price = price!;
    }

    return subscription;
  }

  @Selector()
  static appliedCoupons({ appliedCoupons }: ChargebeeState.State) {
    return appliedCoupons;
  }

  @Selector()
  static isAppsumoUser({ subscription }: ChargebeeState.State) {
    return subscription?.package?.name?.toLowerCase()?.includes('appsumo');
  }

  @Selector()
  static isCancelledScheduled({ subscription }: ChargebeeState.State) {
    return !!subscription?.cancelledAt;
  }

  @Action(GetMySubscription)
  getMySubscription({ patchState }: StateContext<ChargebeeState.State>) {
    return this.chargebeeService.getMySubscription$().pipe(
      tap((result) => {
        const subscription = result.data;

        if (subscription?.cancelledAt) subscription.cancelledAt = subscription.cancelledAt * 1000;

        patchState({ subscription });
      })
    );
  }

  @Action(GetAppliedCoupons)
  getAppliedCoupons({ patchState }: StateContext<ChargebeeState.State>) {
    return this.chargebeeService.coupons$().pipe(
      tap((result) => {
        patchState({ appliedCoupons: result.data });
      })
    );
  }

  @Action(SetMySubscription)
  setSubscription({ patchState }: StateContext<ChargebeeState.State>, { payload }: SetMySubscription) {
    patchState({ subscription: payload.mySubscription });
  }
}
