import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AynUITranslateModule } from '@ayn-ui/lib/modules/translate/translate.module';
import { environment } from '@environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VideoModalModule } from '@pages/video-modal/video-modal.module';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { getSentryProvider, ICON_SRC_PROVIDER, TRANSLATE_PROVIDERS } from './providers';
import { AYN_TITLE_PROVIDER } from './providers/title-strategy.provider';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    VideoModalModule,
    CoreModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true
    }),
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AynUITranslateModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:2000'
    })
  ],
  bootstrap: [AppComponent],
  providers: [ICON_SRC_PROVIDER, ...getSentryProvider(), ...TRANSLATE_PROVIDERS, AYN_TITLE_PROVIDER]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
