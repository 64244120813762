import { OpenAI } from '@core/models';
import { makeQuery, QueryResult } from '@core/queries';

const queryName = 'generateTextsWithFramework';

type Output = QueryResult<typeof queryName, OpenAI.GenerateTextsWithFrameworkOutputDto>;

export const getFrameworkTexts = makeQuery<Output, OpenAI.GenerateTextsWithFrameworkInputDto>(`
 query GenerateTextsWithProject(
  $productName: String!
  $description: String!
  $targetAudience: String!
  $tone: String!
  $outputLanguage: String!
  $customRequests: String!
  $framework: String!
) {
  openAI {
    ${queryName}(
      productName: $productName
      description: $description
      targetAudience: $targetAudience
      tone: $tone
      outputLanguage: $outputLanguage
      customRequests: $customRequests
      framework: $framework
    ) {
      advertisingHeadlines
      advertisingPunchlines
      ctaButtonTexts
      primaryText {
        framework
        text
      }
      headlineUnder100Characters
    }
  }
}
`);
