import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';

export type SliderType = 'default' | 'range';

@Component({
  selector: 'ayn-slider',
  template: `
    <div class="custom-slider" [class]="sliderType">
      <ng-container *ngIf="sliderType === 'default'; else range">
        <ngx-slider
          class="sliderType"
          [(value)]="minValue"
          (valueChange)="sliderValueChange.emit(minValue)"
          [options]="options"
        ></ngx-slider>
      </ng-container>

      <ng-template #range>
        <ngx-slider
          class="sliderType"
          [(value)]="minValue"
          (userChange)="sliderRangeValueChange.emit([$event.value, $event.highValue!])"
          [options]="options"
          [(highValue)]="maxValue"
        ></ngx-slider
      ></ng-template>
    </div>
  `
})
export class Slider {
  @Input() sliderType: SliderType = 'default';

  @Input() options = new Options();

  @Input() minValue = 0;

  @Input() maxValue = 70;

  @Output() sliderValueChange = new EventEmitter<number>();

  @Output() sliderRangeValueChange = new EventEmitter<[number, number]>();
}

@NgModule({
  imports: [CommonModule, NgxSliderModule],
  exports: [Slider],
  declarations: [Slider],
  providers: []
})
export class SliderModule {}
