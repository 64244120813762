import { Injectable } from '@angular/core';
import { ValidationService } from '../base';
import { Bing, PlatformStep } from '@core/models';
import { BehaviorSubject } from 'rxjs';
import { BingStateService } from './bing.state';
import { createSubject } from '@core/utils';

@Injectable({ providedIn: 'root' })
export class BingValidationService extends ValidationService {
  constructor(private bingStateService: BingStateService) {
    super();
  }
  getStepValidation(step: PlatformStep): BehaviorSubject<boolean> {
    const adModel = this.bingStateService.adCreationModel;
    if (adModel.selectedTypes.campaign === Bing.CampaignObjective.ImportFromGoogle) {
      switch (step) {
        case PlatformStep.Objective:
        case PlatformStep.BudgetReview:
          return this.objectiveValid$;
        default:
          return createSubject(false);
      }
    }

    return super.getStepValidation(step);
  }
}
