import { makeQuery } from '@core/queries/types';

type Input = { contextId: string };
type Output = { adAccountCreationLink: string };

export const getAdAccountCreationLink = makeQuery<Output, Input>(`
  query GetAdAccountCreationLink($contextId:String!) {
    google {
      adAccountCreationLink(contextId: $contextId)
    }
  }
`);
