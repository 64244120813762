import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { delayWhen, retryWhen, take } from 'rxjs/operators';
import { timer } from 'rxjs';
import { PURE_REQUEST } from '@core/interceptors';

@Injectable({
  providedIn: 'root'
})
export class BingImageService {
  constructor(private http: HttpClient) {}

  getImage(url: string, retry = 10, delayTime = 1500) {
    return this.http.get(url, { responseType: 'blob', context: new HttpContext().set(PURE_REQUEST, true) }).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          delayWhen(() => timer(delayTime)),
          take(retry)
        );
      })
    );
  }
}
