import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type DetailedTargetingOverlayItem = 'includes' | 'excludes' | 'narrow';

export interface IDetailedTargetingOverlayItemActive {
  isActive: boolean;
  type?: DetailedTargetingOverlayItem;
}

@Component({
  selector: 'aayn-detailed-targeting-overlay-item',
  templateUrl: 'detailed-targeting-overlay-item.component.html'
})
export class DetailedTargetingOverlayItemComponent implements OnInit {
  @Input() active?: IDetailedTargetingOverlayItemActive;

  @Input() inactive?: boolean;

  @Input() type?: string;
  @Input() value?: number;
  @Input() isIncludeShow: boolean = true;
  @Input() isExcludeShow: boolean = true;

  @Input() noLimitMaxWidth: boolean = true;

  @Output() onIncludeClick = new EventEmitter();
  @Output() onExcludeClick = new EventEmitter();
  @Output() onNarrowClick = new EventEmitter();

  @Output() onIncludeRemove = new EventEmitter();
  @Output() onExcludeRemove = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  get getActiveClassNames() {
    return {
      active: this.active?.isActive,
      [`active__${this?.active?.type || ''}`]: this.active?.isActive
    };
  }

  includeClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.active?.isActive) {
      if (this.active?.type === 'includes' && event) {
        this.onIncludeRemove.emit();
      }
      return;
    }

    this.onIncludeClick.emit();
  }

  excludeClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.active?.isActive) {
      if (this.active?.type === 'excludes') {
        this.onExcludeRemove.emit();
      }
      return;
    }

    this.onExcludeClick.emit();
  }
}
