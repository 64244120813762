import { NgModule, Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

export type ProgressSize = 'x-small' | 'small' | 'medium' | 'large' | 'millennium';

@Component({
  selector: 'ayn-progressbar',
  template: `
    <div
      [class]="size"
      [ngStyle]="style"
      role="progressbar"
      aria-valuemin="0"
      [attr.aria-valuenow]="value"
      aria-valuemax="100"
      [ngClass]="{
        'ayn-progressbar ': true
      }"
    >
      <div class="ayn-progressbar-value " [style.width]="value + '%'" style="display:flex">
        <div
          *ngIf="showValue"
          class="ayn-progressbar-label"
          [style.display]="value != null && value !== 0 ? 'flex' : 'none'"
        >
          {{ value }}{{ unit }}
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ayn-element'
  }
})
export class ProgressBar {
  @Input() value!: number;

  @Input() showValue: boolean = false;

  @Input() style!: CSSStyleDeclaration;

  @Input() styleClass!: string;

  @Input() unit: string = '%';

  @Input() size: ProgressSize = 'medium';
}

@NgModule({
  imports: [CommonModule],
  exports: [ProgressBar],
  declarations: [ProgressBar]
})
export class ProgressBarModule {}
