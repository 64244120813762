<header class="aayn-cancel-subscription-modal--header">
  <ayn-modal-close [modal]="modal" icon="cross-mini"></ayn-modal-close>

  <ayn-icon name="hello"></ayn-icon>

  <h3 class="aayn-cancel-subscription-modal--header--title">Welcome Back, {{ (user$ | async)?.user?.name }}!</h3>

  <p class="aayn-cancel-subscription-modal--header--description">
    We’re thrilled to see you again! Feel free to dive right in and start using the application.
  </p>
</header>

<main class="aayn-cancel-subscription-modal--body">
  <div class="aayn-cancel-subscription-modal--body--card">
    <h4 class="aayn-cancel-subscription-modal--body--card-title">Your Discount has been applied</h4>
    <p class="aayn-cancel-subscription-modal--body--card-description">
      You now have a lifetime <b>50% discount</b>. As long as your membership remains active, your discount will be,
      too.
    </p>
  </div>

  <button
    class="aayn-cancel-subscription-modal--button"
    ayn-button="primary"
    icon="circle-chevron-right"
    (click)="modal.dismiss()"
  >
    Proceed to the App
  </button>
</main>
