import { UpdateBillingInfoRequest } from '@core/models';

import { makeMutation } from '../types';

type Input = UpdateBillingInfoRequest;
type Output = {
  billingInfo: boolean;
};

export const updateBillingInfo = makeMutation<Output, Input>(`
  mutation UpdateBillingInfo(
    $companyName: String
    $vatNumber: String
    $email: String
    $firstName: String
    $lastName: String
  ) {
    billingInfo(
      companyName: $companyName
      vatNumber: $vatNumber
      email: $email
      firstName: $firstName
      lastName: $lastName
    )
  }
`);
