<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">First Business Created!</section>
  <section class="aayn-video-modal--description">
    <p>
      Great job on creating your first business with ADYOUNEED! To get started, choose “Guide me” for a tour or “Skip”
      to explore on your own. Let’s create amazing ads together!
    </p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary" (click)="guideMe(); videoModal.closeModal()">Guide Me</button>

    <a class="aayn-video-modal--skip" (click)="skipAdTours(); videoModal.closeModal()">Skip</a>
  </section>
</aayn-video-modal>
