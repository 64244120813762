import { ICoupon } from '@core/models';

import { makeQuery } from '../types';

type Output = ICoupon[];

export const coupons = makeQuery<Output>(`
  query Coupons {
    coupons {
      id
      name
      discountType
      discountPercentage
      discountAmount
    }
  }
`);
