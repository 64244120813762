import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injectable,
  Injector,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, UntypedFormControl } from '@angular/forms';

export type radioType = 'default' | 'gender' | 'select' | 'radio' | '';

@Injectable({
  providedIn: 'root'
})
export class RadioControlRegistry {
  private accessors: any[] = [];

  add(control: NgControl, accessor: Radio) {
    this.accessors.push([control, accessor]);
  }

  remove(accessor: Radio) {
    this.accessors = this.accessors.filter((c) => {
      return c[1] !== accessor;
    });
  }

  select(accessor: Radio) {
    this.accessors.forEach((c) => {
      if (this.isSameGroup(c, accessor) && c[1] !== accessor) {
        c[1].writeValue(accessor.value);
      }
    });
  }

  private isSameGroup(controlPair: [NgControl, Radio], accessor: Radio): boolean {
    if (!controlPair[0].control) {
      return false;
    }

    return controlPair[0].control.root === accessor.control?.control?.root && controlPair[1].name === accessor.name;
  }
}

@Component({
  selector: 'ayn-radio',
  templateUrl: 'radio.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Radio),
      multi: true
    }
  ]
})
export class Radio implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() value?: any;

  @Input() name?: string;

  @Input() disabled?: boolean;

  @Input() binary?: boolean;

  @Input() ariaLabelledBy?: string;

  @Input() ariaLabel?: string;

  @Input() tabindex?: number;

  @Input() inputId?: string;

  @Input() style: any;

  @Input() labelStyleClass?: string;

  @Input() formControlName?: string;

  @Input() formControl?: UntypedFormControl;

  @Input() readonly?: boolean;

  @Input() required?: boolean;

  @Input() trueValue: any = true;

  @Input() falseValue: any = false;

  @Input() labelShow: boolean = false;

  @Input() label: string = '';

  @Input() description: string = '';

  @Input() radioType: radioType = 'gender';

  @ViewChild('rb') inputViewChild!: ElementRef;

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  formControlDisabled: boolean = false;

  get disabledState() {
    return this.formControlDisabled || this.disabled;
  }

  model: any;

  onModelChange: Function = () => {};

  checked?: boolean;

  onModelTouched: Function = () => {};

  focused: boolean = false;

  control!: NgControl;

  constructor(private cd: ChangeDetectorRef, private injector: Injector, private registry: RadioControlRegistry) {}

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.checkName();
    this.registry.add(this.control, this);
  }

  handleClick(event: { preventDefault: () => void }, radioButton: { focus: () => void }, focus: any): void {
    event.preventDefault();

    if (this.disabledState) {
      return;
    }

    this.select(event);

    if (focus) {
      radioButton.focus();
    }
  }

  select(event: { preventDefault: () => void }) {
    if (!this.disabledState) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.registry.select(this);
      this.onClick.emit(event);
    }
  }

  writeValue(value: any): void {
    this.checked = value == this.value;

    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.checked = this.checked;
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.formControlDisabled = val;
    this.cd.markForCheck();
  }

  onInputFocus(event: any) {
    this.focused = true;
    this.onFocus.emit(event);
  }

  onInputBlur(event: any) {
    this.focused = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onChange(event: { preventDefault: () => void }) {
    this.select(event);
  }

  focus() {
    this.inputViewChild.nativeElement.focus();
  }

  ngOnDestroy() {
    this.registry.remove(this);
  }

  private checkName() {
    if (this.name && this.formControlName && this.name !== this.formControlName) {
      this.throwNameError();
    }
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }

  private throwNameError() {
    throw new Error(`
          If you define both a name and a formControlName attribute on your radio button, their values
          must match. Ex: <ayn-radio formControlName="food" name="food"></ayn-radio>
        `);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [Radio],
  declarations: [Radio]
})
export class RadioModule {}
