import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonModule } from '@angular/common';
import {
    Component, HostBinding, Inject, Input, ModuleWithProviders, NgModule
} from '@angular/core';

export const ICON_SRC_PREFIX = '__ICON_SRC_PREFIX';
export const ICON_COMPONENT_ATTR_NAME = 'ayn-icon-name';

@Component({
  selector: 'ayn-icon',
  template: ` <svg-icon *ngIf="name?.length" [class]="className" [src]="srcPrefix + name + '.svg'"></svg-icon> `
})
export class IconComponent {
  @HostBinding(`attr.${ICON_COMPONENT_ATTR_NAME}`)
  @Input()
  name!: string;

  @Input() className = '';

  @Input() srcPrefix = '/assets/icons/';

  constructor(@Inject(ICON_SRC_PREFIX) iconSrcPrefix?: string) {
    if (!!iconSrcPrefix) {
      this.srcPrefix = iconSrcPrefix;
    }
  }

  ngOnInit() {}
}

@NgModule({
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  exports: [IconComponent, AngularSvgIconModule],
  declarations: [IconComponent],
  providers: []
})
export class IconModule {
  static forRoot(opts?: { srcPrefix: string }): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: ICON_SRC_PREFIX,
          useValue: opts?.srcPrefix
        }
      ]
    };
  }
}
