import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';

@Component({
  selector: 'ayn-paginator',
  template: `<ul class="ayn-paginator ayn-paginator__{{ type }}">
    <li
      *ngFor="let count of counts; let index = index"
      (click)="
        counts.length > (minCount || 0) && activePage === index
          ? this.deletePaginationItem.emit(count)
          : this.changeActivePaginationItem.emit(index)
      "
      [ngClass]="{ active: activePage === index }"
    >
      <span class="title">{{ count }}</span>
    </li>

    <!--
        INFO: Eğer 'Counts' uzunluğu 'MaxCount' a eşitse gizlenebilir. 
        *ngIf="maxCount > counts.length"
    -->
    <li
      (click)="counts.length < maxCount ? this.addPaginationItem.emit(counts) : null"
      [ngClass]="{
        new: counts.length < maxCount,
        last: counts.length >= maxCount
      }"
    ></li>

    <ng-container *ngIf="showNotAddedPages && notAddedPages.length">
      <li *ngFor="let page of notAddedPages" class="disabled">
        {{ page }}
      </li>
    </ng-container>
  </ul>`
})
export class Paginator {
  @Input() counts: number[] = [1];

  @Input() minCount: number | undefined;

  @Input() maxCount: number = 10;

  @Input() showNotAddedPages = false;

  @Input() activePage = 0;

  @Input() type: 'small' | 'normal' = 'normal';

  @Output() addPaginationItem: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() deletePaginationItem: EventEmitter<number> = new EventEmitter<number>();

  @Output() changeActivePaginationItem: EventEmitter<number> = new EventEmitter<number>();

  get notAddedPages() {
    return new Array(this.maxCount - this.counts.length).fill(undefined).map((_, i) => this.counts.length + i + 1);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [Paginator],
  declarations: [Paginator],
  providers: []
})
export class PaginatorModule {}
