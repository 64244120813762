import { Component, OnInit } from '@angular/core';
import { SmartCampaign } from '@core/models';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';

import { mapObjectives, selectedItem } from '../../utils';

export const OBJECTIVE_LIST__SMART_CAMPAIGN: ICreateAdSelectItem<SmartCampaign.Client.CampaignObjective>[] = [
  {
    id: 1,
    title: 'Website Traffic',
    description: 'Optimize your campaigns to maximize the number of visitors to your website.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: SmartCampaign.Client.CampaignObjective.WebsiteTraffic
  },
  {
    id: 2,
    title: 'Website Conversions',
    description: 'Optimize your campaigns for better conversion rates using your tracking pixels.',
    icon: 'conversions',
    empty: false,
    active: false,
    type: SmartCampaign.Client.CampaignObjective.ConversionWebsite
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  }
];

@Component({
  selector: 'aayn-select-objective--smart-campaign',
  templateUrl: 'select-objective-content.component.html'
})
export class SelectObjectiveContentSmartCampaignComponent implements OnInit {
  selectedObjective: SmartCampaign.Client.CampaignObjective = SmartCampaign.Client.CampaignObjective.WebsiteTraffic;

  ObjectiveTypes = SmartCampaign.Client.CampaignObjective;

  objectives$ = this.smartCampaignStateService.adCreationModel$.pipe(
    mapObjectives(OBJECTIVE_LIST__SMART_CAMPAIGN, 'objective'),
    selectedItem((objective) => {
      this.selectedObjective = objective.type!;
    })
  );

  constructor(private smartCampaignStateService: SmartCampaignStateService) {}

  ngOnInit() {}

  objectiveChangeStatus(item: ICreateAdSelectItem<SmartCampaign.Client.CampaignObjective>) {
    if (this.smartCampaignStateService.adCreationModel.campaign.objective === item.type) return;

    this.smartCampaignStateService.setAdModel({
      ...this.smartCampaignStateService.adCreationModel,
      campaign: {
        ...this.smartCampaignStateService.adCreationModel.campaign,
        objective: item.type!
      },
      selectedTypes: {
        ...this.smartCampaignStateService.adCreationModel.selectedTypes,
        campaign: item.type!
      }
    });
  }
}
