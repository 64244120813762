<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  [adPreviewContainer]="adPreviewService.searchAds"
  aaynTextAiContainer
>
  <aayn-text-generator-ai style="display: block"></aayn-text-generator-ai>
  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Headlines" description="Write short headlines">
      <aayn-create-ad-input
        *ngFor="let control of headlinesArray.controls; index as index"
        [maxCharacterNumber]="30"
        [formControl]="$any(headlinesArray.controls[index])"
        [placeholder]="'Write a short headline' | translate"
        [requiredMessage]="'You must write at least 3 headline.' | translate"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
    <aayn-ad-type-section header="Descriptions" description="Write descriptions">
      <aayn-create-ad-input
        *ngFor="let control of descriptionsArray.controls; index as index"
        [formControl]="$any(descriptionsArray.controls[index])"
        [maxCharacterNumber]="90"
        [placeholder]="'Write a description here' | translate"
        [requiredMessage]="'You must write at least 3 descriptions.' | translate"
        adPreview="description"
        textAiSection="descriptions"
      ></aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Business Name" description="Write your business name">
      <aayn-create-ad-input
        formControlName="businessName"
        [placeholder]="'Business Name' | translate"
        [maxCharacterNumber]="25"
        [requiredMessage]="'You must write a business name.' | translate"
        [textCraftUsable]="false"
        adPreview="businessName"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Website Url" description="">
      <ayn-form-field>
        <div class="ayn-prefix">
          {{ 'URL' | translate }}
        </div>

        <input
          type="text"
          placeholder="https://adyouneed.com"
          formControlName="url"
          adPreview="url"
          [standAlone]="true"
          ayn-input
          #urlInput
        />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-collapse-card
    title="Ad Extensions"
    [active]="false"
    description="Get up to 15% higher clickthrough rate by showing additional information on your ads"
    class="aayn-ad-type--collapse"
  >
    <div class="aayn-ad-type--collapse-extentions">
      <aayn-ad-type-collapse-extentions-item
        title="Sitelink extensions"
        description="Add additional links to your ad"
        [isButtonShow]="adCreationModel.ad.sitelinkExtension?.create?.name != ''"
        (buttonClick)="openSitelinkExtensionsModal()"
        extentionType="sitelinkExtension"
      ></aayn-ad-type-collapse-extentions-item>

      <aayn-ad-type-collapse-extentions-item
        title="Callout extensions"
        description="Add more business information to your ad"
        [isButtonShow]="adCreationModel.ad.calloutExtension?.create?.name != ''"
        (buttonClick)="openCalloutExtensionsModalComponent()"
        extentionType="calloutExtension"
      ></aayn-ad-type-collapse-extentions-item>

      <aayn-ad-type-collapse-extentions-item
        title="Call extensions"
        description="Add a phone number to your ad"
        [isButtonShow]="adCreationModel.ad.callExtension?.create?.name != ''"
        (buttonClick)="openCallExtensionsModalComponent()"
        extentionType="callExtension"
      ></aayn-ad-type-collapse-extentions-item>
    </div>
  </aayn-collapse-card>
</form>
