import { TikTok } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { payload: TikTok.Business.TiktokConnectAdAccountRequest };
type Output = TikTok.Business.TiktokConnectAdAccountResponse;

export const connectAdAccountQuery = makeMutation<Output, Input>(`
mutation ConnectAdAccount($payload: ConnectAdAccountDto!) {
  tiktok{
    connectAdAccount(payload: $payload) {
      businessId
      connectedAdAccountId
      platform
    }
  }
}
`);
