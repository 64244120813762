import { Platforms, SmartCampaign, SupportedSmartCampaignObject } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { params: SmartCampaign.Backend.GetSmartLocationRequest };

type Output = SmartCampaign.Backend.GetSmartLocationResponse;

const platformsObject = {
  [Platforms.Meta]: `facebook { platformSpecificName, key, type, countryCode, countryCodes, countryName, region, regionId, primaryCity, primaryCityId }`,
  [Platforms.LinkedIn]: `linkedIn { platformSpecificName, urn, facetUrn }`,
  [Platforms.Google]: `google { platformSpecificName, fullName, id, code, type }`,
  [Platforms.Bing]: `bing { platformSpecificName, id, type }`,
  [Platforms.TikTok]: `tiktok {  platformSpecificName id confidence level }`
};

export const getSmartLocations = (selectedPlatforms: Platforms[]) =>
  makeQuery<Output, Input>(
    `
  query GetSmartLocations($params: SmartCampaignSearchLocationRequest!) {
    ayn {
      audience {
        getSmartLocations(params: $params) {
          failures {
           ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }

          statuses {
            ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }

          results {
            ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
          }
        }
      }
    }
  }
`,
    { resultPath: 'ayn.audience.getSmartLocations' }
  );
