import { Component, OnInit } from '@angular/core';
import { Bing, Google } from '@core/models';

@Component({
  selector: 'aayn-select-audience--bing',
  templateUrl: 'select-audience-content.component.html'
})
export class SelectAudienceContentBingComponent implements OnInit {
  protected audiences: Google.UI.SelectAudienceItem[] = [
    {
      id: 1,
      title: 'Manual Audience Setup',
      description: 'Manually setup your own target audience with interests and demographics.',
      type: Bing.AudienceType.Manual,
      icon: 'traffic',
      empty: false,
      active: true
    },
    {
      id: 2,
      title: '',
      description: '',
      icon: '',
      empty: true,
      active: false,
      type: undefined
    },
    {
      id: 3,
      icon: '',
      title: '',
      description: '',
      type: undefined,
      empty: true,
      active: false
    }
  ];

  protected selectedAudience: Google.AudienceType = Google.AudienceType.Manual;

  protected AudienceTypes = Google.AudienceType;

  constructor() {}

  ngOnInit() {}

  audienceChangeStatus(item: Google.UI.SelectAudienceItem) {
    this.audiences.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAudience = item.type!;
      } else {
        x.active = false;
      }
    });
  }
}
