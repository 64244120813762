import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import {
    SmartCampaignFormService
} from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.form';
import {
    SmartCampaignValidationService
} from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';

@Component({
  selector: 'aayn-traffic-to-website--smart-campaign',
  templateUrl: 'traffic-to-website.component.html'
})
export class TrafficToWebsiteSmartCampaignComponent implements OnInit {
  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  traficWebsiteForm = new FormGroup({
    name: new FormControl(this.adCreationModel.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private smartCampaginValidationService: SmartCampaignValidationService,
    private smartCampaignFormService: SmartCampaignFormService
  ) {}

  ngOnInit() {
    this.smartCampaignFormService.registerForm(this.traficWebsiteForm);

    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.smartCampaginValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.traficWebsiteForm.markAllAsTouched();
    });

    this.traficWebsiteForm.statusChanges.subscribe((status) => {
      this.smartCampaginValidationService.objectiveValid$.next(status === 'VALID');
    });
  }
}
