import { makeMutation, QueryResult } from '@core/queries/types';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'generateTextProject';
type Input = OpenAI.IGenerateTextProjectRequest;
type Output = QueryResult<typeof queryField, OpenAI.IGenerateTextProjectResponse>;

export const generateTextProjectQuery = makeMutation<Output, Input>(`
query GenerateTextProject(
  $language: String!
  $tone: TextTone
  $type: TextProjectType!
  $cta: String
  $productName: String
  $description: String!
) {
  openAI {
    ${queryField}(
      language: $language
      tone: $tone
      type: $type
      cta: $cta
      productName: $productName
      description: $description
    ) {
      texts
    }
  }
}

`);
