<ng-template #roadmapModal let-modal>
  <header class="modal-header">
    <h4>Roadmap</h4>
    <p>
      The marketing platform that helps you create and optimize ads in minutes so you can focus on what really matters -
      your business.
    </p>
    <button type="button" ayn-button="primary-outline" icon="submit-feedback">Submit Feedback</button>
    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <main class="modal-body">
    <ayn-lottie [options]="{ path: '/assets/lotties/empty-new-ayn.json' }"></ayn-lottie>
    <h4>There was a problem retrieving the roadmap</h4>
    <p>
      Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing
      layouts and visual mockups.
    </p>
    <button type="button" ayn-button="primary-outline">Refresh</button>
  </main>
</ng-template>
