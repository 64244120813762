
import { UserLoginResponse } from '@core/models'

import { makeMutation } from '../types'

type Input = { oldToken: string }
type Output = UserLoginResponse

export const refreshToken = makeMutation<Output, Input>(`
mutation RefreshToken($oldToken: String!) {
  refreshToken(refreshToken: $oldToken) {
    accessToken
    refreshToken
  }
}
`)
