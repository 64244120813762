<div class="aayn-create-ad--item" *ngIf="selectedPlatform$ | async as selectedPlatform">
  <ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
    <div [ngSwitch]="selectedPlatform">
      <div *ngSwitchCase="Platforms.Meta" [@fadeInOnEnter]>
        <aayn-select-ad-type--facebook></aayn-select-ad-type--facebook>
      </div>
      <div *ngSwitchCase="Platforms.Google" [@fadeInOnEnter]>
        <aayn-select-ad-type--google></aayn-select-ad-type--google>
      </div>

      <div *ngSwitchCase="Platforms.Bing" [@fadeInOnEnter]>
        <aayn-select-ad-type--bing></aayn-select-ad-type--bing>
      </div>

      <div *ngSwitchCase="Platforms.LinkedIn" [@fadeInOnEnter]>
        <aayn-select-ad-type--linkedin></aayn-select-ad-type--linkedin>
      </div>

      <div *ngSwitchCase="Platforms.SmartCampaign" [@fadeInOnEnter]>
        <aayn-select-ad-type--smart-campaign></aayn-select-ad-type--smart-campaign>
      </div>

      <div *ngSwitchCase="Platforms.TikTok" [@fadeInOnEnter]>
        <aayn-select-ad-type--tiktok></aayn-select-ad-type--tiktok>
      </div>
    </div>
  </ng-container>

  <aayn-ad-item-footer>
    <button
      ayn-button="primary"
      class="aayn-create-ad--item--btn"
      ayn-button-block
      (click)="nextStep(selectedPlatform)"
      type="submit"
      [width]="260"
      [innerHTML]="'Next Step; Review & Publish' | translate"
    ></button>
  </aayn-ad-item-footer>
</div>
