import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { PipesModule } from '../../pipes';
import { IconModule } from '../icon';
import { LottieComponent } from '../lottie';
import { AynUITranslateModule } from '../../translate/translate.module';
import { ButtonModule } from '../button';
import { TooltipDirective, TooltipModule } from '../tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { isObject } from 'lodash';
import { SkeletonModule } from '@ayn-ui/lib/modules/base/skeleton';

export type URLObject<T> = { url: string } & T;
export type ImageUrls<T extends {} = {}> = string[] | SafeUrl[] | Array<URLObject<T>>;

export function isUrlObject<T>(val: T): val is URLObject<T> {
  return isObject(val) && val.hasOwnProperty('url');
}

export interface FileUploadGroup<T extends {} = {}> {
  imageUrls: ImageUrls<T>;
  label: string;
  className?: string;
  maxImageCount: number;
  groupName: string;
  aspectRatio: number;
  required?: boolean;
  minRequired?: number;
}

export interface FileUploadAreaEvent {
  index: number;
  group?: string;
  image?: ImageUrls[0];
}

export interface ImageChangeEvent {
  imageUrls: ImageUrls;
  imageGroups: FileUploadGroup[];
}

@Component({
  selector: 'ayn-file-upload-area',
  templateUrl: 'file-upload-area.html',
  animations: [
    fadeInOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100 }),
    fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })
  ]
})
export class FileUploadAreaComponent implements OnInit, OnChanges {
  @Input() imageUrls: ImageUrls<{}> = [];

  @Input() imageGroups: FileUploadGroup[] = [];

  @Input() header = 'Click here to upload your creatives!';

  @Input() descriptionTitle = 'Recommended: 1080x1080';

  @Input() description: string =
    'Facebook is recommending this size to be in this pixel range to get the best results possible.';

  @Input() className = '';

  @Input() showPrevNextButtons = true;

  @Input() showRequiredAlert = false;

  @Input() showGridAlways = false;

  @Input() imageCount = 10;

  @Input() single = false;

  @Output() fileUploadClick = new EventEmitter();

  @Output() fileRemove = new EventEmitter<FileUploadAreaEvent>();
  @Output() fileHover = new EventEmitter<MouseEvent>();
  @Output() imageError = new EventEmitter<ErrorEvent & Partial<FileUploadAreaEvent>>();

  @ContentChild('footer') footer!: TemplateRef<ElementRef>;

  @ViewChildren(TooltipDirective) tooltipDirectives!: QueryList<TooltipDirective>;

  images$ = new BehaviorSubject<ImageChangeEvent>({
    imageUrls: [],
    imageGroups: []
  });

  get placeholderArray() {
    const count = this.imageCount - this.imageUrls.length;
    return count > 0 ? new Array(count).fill(0) : [];
  }

  get isFilled() {
    return !!(this.imageUrls.length || this.groupHasImages || this.showGridAlways);
  }

  get groupHasImages() {
    return this.imageGroups.length && this.imageGroups.some((group) => !!group.imageUrls.length);
  }

  isUrlObject = isUrlObject;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageUrls || changes.imageGroups) {
      this.images$.next({
        imageUrls: [...(this.imageUrls || [])],
        imageGroups: [...(this.imageGroups || [])]
      });
    }
  }

  getGroupPlaceHolderImages(item: FileUploadGroup) {
    const maxImageCount =
      this.imageCount > item.maxImageCount || this.imageCount < item.imageUrls.length
        ? item.maxImageCount
        : this.imageCount;
    return new Array(maxImageCount - item.imageUrls.length).fill(0);
  }

  removeSelectedFile(event: MouseEvent, index: number, group?: string, image?: ImageUrls[0]) {
    event.stopPropagation();
    event.preventDefault();
    this.fileRemove.emit({ index, group, image });
  }

  checkGroupInvalid(group: FileUploadGroup, images: ImageUrls, index: number) {
    return (
      this.imageGroups?.length &&
      this.showRequiredAlert &&
      group?.required &&
      (images?.length || 0) + index < (group?.minRequired || 0)
    );
  }

  protected readonly Object = Object;
  groupTrackBy = (_: number, item: FileUploadGroup) => item.groupName;
  imageTrackBy = (_: number, item: string | SafeUrl) => item;

  onImageError(event: ErrorEvent, index: number, image: URLObject<SafeUrl> | SafeUrl) {
    this.imageError.emit({ index, image, ...event });
  }
}

const COMPONENTS = [FileUploadAreaComponent];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    LottieComponent,
    PipesModule,
    AynUITranslateModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
    SkeletonModule
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: []
})
export class FileUploadModule {}
