<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__filled': adCreationModel.audience.locations.length,
    'aayn-audience--location-selection__active': overlay?.render
  }"
>
  <aayn-location-selection-selected-items type="inclusions" *ngIf="adCreationModel.audience.locations.length">
    <aayn-location-selection-selected-item
      *ngFor="let include of adCreationModel.audience.locations; let index = index"
    >
      <ayn-badge color="primary" [text]="include.name" icon="map-marker-dot" (click)="removeItem(index)"></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      ayn-input
      [required]="!adCreationModel.audience.locations.length"
      [placeholder]="'Type the search' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />
    <ayn-icon name="map-marker"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length || geoLocations?.length"
  >
    <aayn-location-selection-overlay-list *ayn-loader="loader$ | async">
      <aayn-location-selection-overlay-item
        *ngFor="let geoLocation of geoLocations"
        [type]="geoLocation.level"
        (onIncludeClick)="onIncludeClick(geoLocation)"
        (onIncludeRemove)="onIncludeRemove(geoLocation)"
        [active]="isActive(geoLocation)"
        [canExclude]="false"
      >
        {{ geoLocation.name }}
      </aayn-location-selection-overlay-item>
      <div aayn-location-selection-overlay-message *ngIf="!geoLocations.length">
        {{ 'No location found or your ad account not allowed to target searched location' | translate }}
      </div>
    </aayn-location-selection-overlay-list>
  </ayn-overlay>
</section>
