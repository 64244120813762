import { Linkedin } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Linkedin.Business.IOauthRequest;
type Output = Linkedin.Business.IOauthResponse;

export const oauthLinkedin = makeMutation<Output, Input>(`
query LinkedinOauth($contextId: String!) {
  linkedin {
    oauth(contextId: $contextId) {
      oauthUrl
    }
  }
}
`);
