import { AdPlatforms, SmartCampaign } from '@core/models';

import { createAdBaseModel, CreateAdCommon, ICreateAdCommonValidate, UsesInitialization } from '../common';

export module SmartCampaignCreateAd {
  export type AdRequest = {
    campaign: SmartCampaign.Client.Campaign;
    audience: SmartCampaign.Client.Audience;
    ad: SmartCampaign.Client.Ad;
  };

  export type AdType = any;

  function createEmptyModel() {
    return {
      ...createAdBaseModel(
        SmartCampaign.Client.CampaignObjective.WebsiteTraffic,
        SmartCampaign.Client.AudienceType.Manual,
        SmartCampaign.Client.AdType.Default
      ),

      selectedPlatforms: [] as AdPlatforms[],

      campaign: {
        name: '',
        objective: SmartCampaign.Client.CampaignObjective.WebsiteTraffic,
        platforms: {
          facebook: {},
          google: {},
          bing: {},
          linkedin: {},
          tiktok: {}
        },
        budget: {
          amount: 0,
          preferredCurrency: 'USD',
          type: SmartCampaign.Client.SmartCampaignBudgetType.Daily,
          useSmartBudget: true,
          platforms: {}
        },
        startDate: new Date()
      } as SmartCampaign.Client.Campaign,

      audience: {
        name: '',
        age: {
          min: 18,
          max: 65
        },
        gender: {
          male: true,
          female: true
        },
        geoLocations: {
          inclusions: {}
        },

        detailedTargetings: {} as SmartCampaign.Backend.SmartCampaignInterestResults,
        detailedTargetingResults: {} as SmartCampaign.Backend.SmartCampaignInterestResults,

        languages: [],
        precisionAudience: {
          url: '',
          description: '',
          targetAudience: ''
        }
      } as SmartCampaign.Client.Audience,

      ad: {
        name: '',

        shortHeadlines: ['', '', '', '', ''],
        headlines: ['', '', '', '', ''],
        descriptions: ['', '', '', '', ''],
        primaryTexts: ['', '', '', '', ''],

        callToAction:
          {} as SmartCampaign.Backend.SmartCampaignCallToActionItemDto<SmartCampaign.Backend.SmartCampaignCallToActionPlatformDetailDto>,
        websiteUrl: '',
        images: {
          bing: [],
          linkedin: [],
          facebook: [],
          google: [],
          tiktok: []
        },

        businessName: ''
      } as SmartCampaign.Client.Ad
    };
  }

  export type ICreateAdModel = ReturnType<typeof createEmptyModel>;

  const deepClonedModel = structuredClone({ ...createEmptyModel() });

  @UsesInitialization(deepClonedModel)
  export class CreateAd {
    get isValidImages(): boolean {
      return true; // TODO: this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd extends CreateAdCommon<ICreateAdModel, AdRequest, any> {
    mapToApiRequest(type?: string | number): AdRequest;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
