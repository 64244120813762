import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleLanguageConstantRequest;
type Output = Google.CreateAd.IGoogleLanguageConstantResponse;

export const languageSearch = makeMutation<Output, Input>(`
query LanguageSearch($limit: Float, $searchKey: String) {
  google {
    languageSearch(limit: $limit, searchKey: $searchKey) {
      id
      name
      code
    }
  }
}

`);
