import { of } from 'rxjs';
import { skipUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Google } from '@core/models';
import { DraftService } from '@core/services/draft.service';
import {
    GoogleFormService, GoogleStateService, GoogleValidationService
} from '@pages/create-ad/state';

@Component({
  selector: 'aayn-manual-audience--google',
  templateUrl: 'manual-audience.component.html'
})
export class ManualAudienceGoogleComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  form = new FormGroup({
    audienceName: new FormControl(this.adCreationModel.audience?.create.audienceName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    minAge: new FormControl(this.adCreationModel.audience?.create.minAge, [Validators.required]),
    maxAge: new FormControl(this.adCreationModel.audience?.create.maxAge, [Validators.required]),
    male: new FormControl(this.adCreationModel.audience?.create.male, [Validators.required]),
    female: new FormControl(this.adCreationModel.audience?.create.female, [Validators.required]),
    languageIds: new FormControl(this.adCreationModel.audience?.create.languageIds),
    includedLocations: new FormControl(this.adCreationModel.audience?.create.includedLocations, [Validators.required]),
    excludedLocations: new FormControl(this.adCreationModel.audience?.create.excludedLocations),
    customAudiences: new FormControl(this.adCreationModel.audience?.create.customAudiences),
    interests: new FormControl(
      this.adCreationModel.audience?.create.interests as Google.CreateAd.GooglePMInterestDetails[]
    ),
    yourDatas: new FormControl(this.adCreationModel.audience?.create.yourDatas)
  });

  constructor(
    private googleStateService: GoogleStateService,
    private googleValidationService: GoogleValidationService,
    private googleFormService: GoogleFormService,
    private draftService: DraftService
  ) {}

  ngOnInit() {
    this.googleFormService.registerForm(this.form);

    this.googleStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        audienceName: o.audience.create.audienceName,
        languageIds: o.audience.create.languageIds,
        male: o.audience.create.male,
        female: o.audience.create.female,
        maxAge: o.audience.create.maxAge,
        minAge: o.audience.create.minAge,
        excludedLocations: o.audience.create.excludedLocations as Google.CreateAd.ILocationSearch[] | null | undefined,
        includedLocations: o.audience.create.includedLocations as Google.CreateAd.ILocationSearch[] | null | undefined,
        customAudiences: o.audience.create.customAudiences as Google.GoogleKeywordCriterion[] | null | undefined,
        interests: o.audience.create.interests as Google.CreateAd.GooglePMInterestDetails[] | null | undefined,
        yourDatas: o.audience.create.yourDatas
      });

      this.googleValidationService.audienceValid$.next(this.form.status === 'VALID');
    });

    this.form.valueChanges.pipe(skipUntil(this.draftService.getDraftLoader$ || of())).subscribe((o) => {
      this.adCreationModel!.audience!.create = {
        audienceName: o.audienceName,
        languageIds: o.languageIds,
        male: o.male,
        female: o.female,
        maxAge: o.maxAge,
        minAge: o.minAge,
        excludedLocations: o.excludedLocations,
        includedLocations: o.includedLocations,
        customAudiences: o.customAudiences,
        interests: o.interests,
        yourDatas: o.yourDatas
      } as Google.CreateAd.GooglePMAudienceCreate;

      this.googleValidationService.audienceValid$.next(this.form.status === 'VALID');
    });
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    this.form!.patchValue({ minAge: min });
    this.form!.patchValue({ maxAge: max });
  }
}
