export const SUPPORTED_LANGUAGES = [
  'en',
  'fr',
  'es',
  'pt',
  'de',
  'it',
  'tr',
  'pl',
  'se',
  'no',
  'bg',
  'lv',
  'in',
  'dk',
  'fi',
  'is'
] as const;

export type SupportedLanguages = typeof SUPPORTED_LANGUAGES[number];
