import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';

export type HttpHeaderType =
  | HttpHeaders
  | {
      [header: string]: string | string[];
    };

export type HttpParamsType =
  | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> }
  | any;

export class BaseHttpService {
  protected api = environment.apiUrl;
  protected apiPrefix: string = '';
  private headers?: HttpHeaderType;

  get apiUrl() {
    return `${this.api}${this.apiPrefix}`;
  }

  /**
   * @param __http {HttpClient}
   * @param apiPrefix {string} * Default empty string.
   * @param headers {HttpHeaders}?
   */
  constructor(private __http: HttpClient, apiPrefix: string = '', headers?: HttpHeaderType) {
    this.apiPrefix = apiPrefix;
    this.headers = headers;
  }

  protected get<T>(url: string, params?: HttpParamsType, headers?: HttpHeaderType, requestOptions?: Object) {
    return this.__http.get<T>(`${this.api}${this.apiPrefix}${url}`, {
      headers: headers || this.headers,
      params,
      ...requestOptions
    });
  }

  protected post<T>(url: string, body: any = {}, headers?: HttpHeaderType, requestOptions?: Object) {
    return this.__http.post<T>(`${this.api}${this.apiPrefix}${url}`, body, {
      headers: headers || this.headers,
      ...requestOptions
    });
  }

  protected put<T>(url: string, body: any, headers?: HttpHeaderType, requestOptions?: Object) {
    return this.__http.put<T>(`${this.api}${this.apiPrefix}${url}`, body, {
      headers: headers || this.headers,
      ...requestOptions
    });
  }

  protected delete<T>(url: string, headers?: HttpHeaderType, requestOptions?: Object) {
    return this.__http.delete<T>(`${this.api}${this.apiPrefix}${url}`, {
      headers: headers || this.headers,
      ...requestOptions
    });
  }
}
