import { makeMutation } from '@core/queries';
import { Linkedin } from '@core/models';

type Input = { input: Linkedin.CreateCustomAudienceDto };
export const createCustomAudience = makeMutation<void, Input>(`
mutation CreateCustomAudience($input: LinkedInCreateCustomAudienceModel!) {
    linkedin{
        createCustomAudience(input: $input)
    }
}
`);
