import { SmartCampaign } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { campaign: SmartCampaign.Backend.SmartCampaignCreateDto };
type Output = SmartCampaign.Backend.SmartCampaignValidationResultDto;

export const validateSmartCampaign = makeQuery<Output, Input>(
  `
query ValidateSmartCampaign($campaign: SmartCampaignCreateDto!) {
  ayn {
    validateSmartCampaign(campaign: $campaign) {
      isValid
      errorMessages
    }
  }
}
`,
  { resultPath: 'ayn.validateSmartCampaign' }
);
