<ng-template #modalPage let-modal>
  <section
    class="ayn-modal-page"
    [ngClass]="{
        'ayn-modal-page--has-header':!!headerTemplate,
        'ayn-modal-page--has-body': !!bodyTemplate,
        'ayn-modal-page--has-footer': !!footerTemplate
    }"
  >
    <ng-container *ngIf="!!headerTemplate">
      <header class="ayn-modal-page--header">
        <ng-container [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{modal: modal}"></ng-container>
      </header>
    </ng-container>

    <section class="ayn-modal-page--body" *ngIf="!!bodyTemplate">
      <ng-container [ngTemplateOutlet]="bodyTemplate" [ngTemplateOutletContext]="{modal: modal}"></ng-container>
    </section>

    <section
      class="ayn-modal-page--footer"
      *ngIf="!!footerTemplate"
      [ngTemplateOutlet]="footerTemplate"
      [ngTemplateOutletContext]="{modal: modal}"
    ></section>
  </section>
</ng-template>
