import { Settings } from '@core/models';

import { makeMutation } from '../types';

type Input = { input: Settings.IChangeUserInfoRequest };
type Output = Settings.IChangeUserInfoResponse;

export const changeUserInfo = makeMutation<Output, Input>(`
   mutation ChangeUserInfo($input: ChangeUserInfoDto!) {
    changeUserInfo(input: $input) {
 
      accessToken
      refreshToken
      changed
      user {
        sub
        email
        name
        surname
      }
    }
  }
`);
