import { Component, Input } from '@angular/core';
import { Facebook, Platforms } from '@core/models';
import { FacebookStateService } from '@pages/create-ad/state';
import { AdPreviewService } from '../../ad-preview.service';
import {
  AdPreviewInstagramPlatform,
  AdPreviewMetaPlatform
} from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-dynamic-creative-preview--facebook',
  templateUrl: './dynamic-creative-preview.component.html'
})
export class DynamicCreativePreviewComponent {
  @Input() businessPageDetail?: Facebook.IBusinessPageDetail;

  protected adCreationModel = this.facebookStateService.adCreationModel;

  Platforms = Platforms;

  @Input() title =
    ' Unleash the full potential of your brand with ADYOUNEED, the leading web application designed to revolutionize your social media advertising game on Facebook. 💪✨🌟';
  selectedPlatform = Platforms.Meta;

  platforms = [AdPreviewMetaPlatform, AdPreviewInstagramPlatform];

  constructor(private facebookStateService: FacebookStateService, public adPreviewService: AdPreviewService) {}
}
