import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Input = Bing.AudienceCampaign.LocationSearchRequest;
type Output = Bing.AudienceCampaign.LocationSearchResponse;

export const locationSearchBing = makeQuery<Output, Input>(`
query LocationSearch($limit: Float, $searchKey: String) {
  bing {
    locationSearch(limit: $limit, searchKey: $searchKey) {
      fullName
      id
      type
    }
  }
}
`);
