import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdPreviewComponent } from '../../../ad-preview.component';
import { CarouselModule, IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-meta-carousel',
  templateUrl: './carousel.component.html',
  imports: [
    NgIf,
    NgTemplateOutlet,
    AdPreviewComponent,
    CarouselModule,
    NgForOf,
    PipesModule,
    TranslateModule,
    IconModule
  ],
  standalone: true
})
export class AdPreviewMetaCarouselComponent {
  @Input() businessName = '';
  @Input() businessImage = '';
  @Input() headline = '';
  @Input() websiteUrl = '';
  @Input() cta = '';

  @Input() cards: Array<{
    title?: string;
    image?: string[];
    body?: string;
  }> = [];

  @Input() activeIndex: number = 0;
  @Output() activeIndexChange = new EventEmitter<number>();

  headlinePlaceholder = 'Your Headline';
  bodyPlaceholder = 'Your Ad Headline';
  ctaPlaceholder = 'CTA';

  placeholder = '/assets/images/pngs/placeholder.png';

  onImgError(event) {
    event.target.src = '/assets/images/pngs/placeholder.png';
  }
}
