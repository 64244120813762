import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  Pipe,
  PipeTransform
} from '@angular/core';

export const RESOURCES_TOKEN = new InjectionToken<any>('RESOURCES_TOKEN');

export const TRANSLATE_INTERPOLATION_FN = new InjectionToken<(value: string, key: string, paramValue: any) => string>(
  'TRANSLATE_INTERPOLATION_FN'
);

export const DEFAULT_TRANSLATE_INTERPOLATION_FN = (value: string, paramKey: string, paramValue: any) => {
  // REGEX_PATTERN = {{ value }}
  return value.replace(new RegExp(`{{(${paramKey}|\\s+${paramKey}\\s+)}}`, 'g'), paramValue);
};

@Injectable()
export class AynUITranslateService {
  private resources$ = new ReplaySubject<any>(1);

  constructor(
    @Inject(RESOURCES_TOKEN) private resources: any,
    @Optional()
    @Inject(TRANSLATE_INTERPOLATION_FN)
    private interpolationFn: (value: string, paramKey: string, paramValue: any) => string,
    private injector: Injector
  ) {
    this.resources$.next(resources);
    if (!this.interpolationFn) {
      this.interpolationFn = DEFAULT_TRANSLATE_INTERPOLATION_FN;
    }
  }

  translate(key: string, params?: any) {
    return this.resources$.pipe(map((resources) => this.translateByKey(resources, key, params)));
  }

  instant(key: string, params?: any) {
    return this.translateByKey(this.resources, key, params);
  }

  translateMultiple<T extends string, Params>(
    keys: T[],
    params?: { [key in T]?: Params }
  ): Observable<{ [key in T]: string }> {
    return this.resources$.pipe(map((resources) => this.translateMultipleByKey(resources, keys, params)));
  }

  translateByKey(resources: any, key: string, params?: any) {
    let value = resources[key] || key;

    if (params) {
      value = this.interpolate(value, params);
    }
    return value;
  }

  translateMultipleByKey<T extends string, Params>(resources: any, keys: T[], params?: { [key in T]?: Params }) {
    return keys.reduce((acc, key) => {
      acc[key] = this.translateByKey(resources, key, params);
      return acc;
    }, {} as { [key in T]: string });
  }

  interpolate(value: string, params?: any) {
    return Object.entries(params).reduce(
      (acc, [paramKey, paramValue]) => this.interpolationFn(acc, paramKey, paramValue),
      value
    );
  }

  changeLanguage(resources: any) {
    this.resources$.next(resources);
    this.resources = resources;
  }

  getLocale() {
    return this.injector.get(LOCALE_ID, 'en-US').toString();
  }
}

@Pipe({
  name: 'aynTranslate'
})
export class AynUITranslatePipe implements PipeTransform {
  constructor(private translateService: AynUITranslateService) {}

  transform(key: string, params?: any) {
    return this.translateService.translate(key, params);
  }
}

@NgModule({
  declarations: [AynUITranslatePipe],
  exports: [AynUITranslatePipe]
})
export class AynUITranslateModule {
  static forRoot(options?: { resources?: { [key: string]: string } }): ModuleWithProviders<AynUITranslateModule> {
    return {
      ngModule: AynUITranslateModule,
      providers: [
        {
          provide: RESOURCES_TOKEN,
          useValue: options?.resources || {}
        },
        AynUITranslateService
      ]
    };
  }
}
