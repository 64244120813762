<section
  class="aayn-audience--detailed-targeting--added-items"
  [class]="'aayn-audience--detailed-targeting--added-items__' + addedItemType"
>
  <header class="aayn-audience--detailed-targeting--added-items-header">
    <p class="aayn-audience--detailed-targeting--added-items-description">
      <strong>{{ addedItemType | titlecase }}</strong> people WHO match
    </p>
  </header>

  <div class="aayn-audience--detailed-targeting--added-items-body">
    <ng-content></ng-content>
  </div>
</section>
