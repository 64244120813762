<section
  class="ayn-file-upload {{ className }}"
  [ngClass]="{
  'ayn-file-upload__filled': imageUrls.length,
  'ayn-file-upload__required': showRequiredAlert && !groupHasImages,
  'ayn-file-upload--group': groupHasImages,
  'ayn-file-upload--single': single,
  }"
  (click)="fileUploadClick.emit()"
>
  <div class="ayn-file-upload--inner" [class.ayn-file-upload--inner--description]="!isFilled">
    <ng-container *ngIf="showRequiredAlert && !groupHasImages">
      <div class="ayn-file-upload--inner--description__required">
        <ayn-icon
          name="info"
          [ayn-tooltip]="(imageGroups?.length ? 'You must upload at least 1 image to each ratio.' : 'You must upload at least 1 image') | aynTranslate | async"
          [dismissible]="false"
          position="right"
          tooltipClass="ayn-file-upload--tooltip"
          zIndex="998"
        ></ayn-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="!isFilled; else imageGroupTemplate">
      <div class="ayn-file-upload--inner-content">
        <!-- <img src="/assets/images/svgs/file.svg" alt="Upload File" /> -->
        <ayn-icon name="upload-file"></ayn-icon>
        <strong class="ayn-file-upload--placeholder">{{header | aynTranslate | async}}</strong>

        <p class="ayn-file-upload--description">
          <b>{{ descriptionTitle | aynTranslate | async}}</b>
          {{description}}
        </p>

        <ng-template [ngTemplateOutlet]="footer"></ng-template>
      </div>
    </ng-container>

    <ng-template #imagesTemplate let-images="images" let-placeholderImages="placeholderImages" let-group="group">
      <section class="ayn-file-upload--image" *ngIf="!single; else singleImageTemplate">
        <ng-container *ngIf="{_images : images | cast: imageUrls} as data ">
          <div
            class="image-container"
            [style.aspect-ratio]="group?.aspectRatio"
            *ngFor="let image of (data._images || imageUrls); index as i; trackBy:imageTrackBy"
          >
            <ng-container *ngIf="(isUrlObject(image) ? image.url : image) as url">
              <div class="image">
                <img
                  [src]="url | aynSafe: 'url'"
                  [alt]="url"
                  (error)="onImageError($event, i, image)"
                  ayn-skeleton-img
                />
              </div>
            </ng-container>

            <ng-container
              [ngTemplateOutlet]="hoverTemplate"
              [ngTemplateOutletContext]="{index:i, group:group?.groupName, image: image}"
            ></ng-container>
          </div>
          <div
            class="image-container image-container--placeholder"
            [style.aspect-ratio]="group?.aspectRatio"
            [class.invalid]="checkGroupInvalid(group, images, index)"
            *ngFor="let p of (placeholderImages || placeholderArray); index as index"
          >
            <ayn-icon name="upload-file"></ayn-icon>
            <ng-container *ngIf="checkGroupInvalid(group, images, index)">
              <div
                class="ayn-file-upload--inner--description__required ayn-file-upload--inner--description__required--single"
              >
                <ayn-icon
                  name="info"
                  [ayn-tooltip]="'You must upload at least 1 image.' | aynTranslate | async"
                  [dismissible]="false"
                  position="right"
                  tooltipClass="ayn-file-upload--tooltip"
                  zIndex="998"
                ></ayn-icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </section>
    </ng-template>

    <ng-template #imageGroupTemplate>
      <ng-container *ngIf="imageGroups.length; else imagesTemplate">
        <ng-container *ngFor="let group of imageGroups; trackBy: groupTrackBy">
          <section class="ayn-file-upload--group-header">
            <h5 class="ayn-file-upload--group-header__label-container">
              <span class="fw-semibold">{{ group.label | translate }} </span>
              <div class="divider"></div>
            </h5>
          </section>
          <ng-container
            *ngTemplateOutlet="imagesTemplate; context: {images: group.imageUrls, placeholderImages: getGroupPlaceHolderImages(group), group: group}"
          ></ng-container>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #singleImageTemplate>
      <section class="ayn-file-upload--single-image">
        <div class="image-container">
          <div class="image">
            <img
              [src]="imageUrls[0] | aynSafe: 'url'"
              [alt]="imageUrls[0]"
              (error)="imageError.emit($event)"
              ayn-skeleton-img
            />
          </div>
          <ng-container [ngTemplateOutlet]="hoverTemplate" [ngTemplateOutletContext]="{index:0}"></ng-container>
        </div>
      </section>
    </ng-template>
  </div>
</section>

<ng-template #hoverTemplate let-index="index" let-group="group" let-image="image">
  <div class="ayn-file-upload--image--hover" (click)="removeSelectedFile($event, index, group, image)">
    <button
      ayn-button="only-icon"
      class="btn--only-icon ayn-file-upload--image--hover_remove-btn hover-disabled"
      icon="trash-flat"
    ></button>
  </div>
</ng-template>
