<div
  class="aayn-input-group"
  [class.invalid]="invalid"
  *ngIf="{ loader: loader$ | async, textCraftActive: textAiContainerService.textCraftActive$ | async } as data"
>
  <div class="aayn-input-group--inner" [class.has-preview]="previewVisible">
    <input
      #valueInput
      #aynInput="aynInput"
      *ngIf="inputType === 'input'"
      (blur)="onInputBlur()"
      [class.invalid]="invalid"
      type="text"
      ayn-input
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      [placeholder]="placeholder + (isRequired ? ' *' : ' (Optional)') | translate"
      [id]="inputId"
      [disabled]="disabled"
      [readOnly]="data.loader"
      [maxlength]="maxCharacterNumber"
    />

    <textarea
      #valueInput
      #aynInput="aynInput"
      *ngIf="inputType === 'textarea'"
      (blur)="onInputBlur()"
      rows="25"
      ayn-input
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      [placeholder]="placeholder + (isRequired ? ' *' : ' (Optional)') | translate"
      [id]="inputId"
      [disabled]="disabled"
      [readOnly]="data.loader"
      [maxlength]="maxCharacterNumber"
    >
    </textarea>

    <span *ngIf="maxCharacterNumber && !invalid" class="aayn-input-group--inner-character-number">
      {{ maxCharacterNumber - length }}
    </span>

    <button
      type="button"
      ayn-button="only-icon"
      [class.active]="previewActive"
      *ngIf="previewVisible && !invalid"
      [ayn-tooltip]="'Show in preview' | translate"
      tooltipClass="aayn-tooltip-create-ad"
      position="bottom"
      (click)="toggleActive()"
      [disabled]="!value"
      tabindex="-1"
    >
      <ayn-icon name="eye"></ayn-icon>
    </button>

    <ayn-icon
      name="information"
      *ngIf="invalid"
      [ayn-tooltip]="(errors | async) || requiredMessage"
      position="right"
      [show]="true"
      zIndex="99999"
      class="aayn-input-group--error-info"
    ></ayn-icon>
  </div>

  <div class="aayn-create-ad-input--button" *ngIf="textCraftUsable">
    <button
      [class.active]="brainActive"
      type="button"
      ayn-button
      #brainButton
      class="aayn-input-group--ai-button"
      (click)="toggleTextCraft($event)"
      tabindex="-1"
      [disabled]="data.loader || data.textCraftActive"
    >
      <ayn-icon
        *ngIf="data.loader"
        className="aayn-input-group--ai-button__loading"
        srcPrefix="/assets/images/svgs/tools/"
        name="button-loading-dark"
      ></ayn-icon>
      <ayn-icon *ngIf="!data.loader" name="brain"></ayn-icon>
    </button>
    <aayn-text-craft
      [value]="valueHolder"
      (valueChange)="textCraftValueChange($event)"
      (save)="textCraftValueSave($event)"
      [loader$]="loader$"
      [(active)]="brainActive"
      [maxCharacterNumber]="maxCharacterNumber"
      [targetButton]="brainButton"
    ></aayn-text-craft>
  </div>
</div>
