import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { FileUploadAreaComponent } from '@ayn-ui/lib/modules/base/file-upload-area';
import { CreateAdInputComponent } from '@shared/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { AdPreviewDirective } from '@shared/components/input-preview.directive';

@UntilDestroy()
@Component({
  selector: 'aayn-carousel-card',
  templateUrl: './carousel-card.component.html'
})
export class CarouselCardComponent implements AfterViewInit {
  @Input() saveDisabled = false;

  @Input() removeDisabled = false;

  @Output() remove = new EventEmitter<void>();

  @Output() save = new EventEmitter<void>();

  @ContentChildren(NgControl, { descendants: true }) inputs!: QueryList<NgControl>;

  @ContentChild(FileUploadAreaComponent, { descendants: true }) fileUploadArea!: FileUploadAreaComponent;

  @ContentChild(FileUploadAreaComponent, { descendants: true, read: AdPreviewDirective })
  fileAdPreview!: AdPreviewDirective;

  @ContentChildren(CreateAdInputComponent, { descendants: true }) createAdInputs!: QueryList<CreateAdInputComponent>;

  @ContentChildren(CreateAdInputComponent, { descendants: true, read: AdPreviewDirective })
  adPreview!: QueryList<AdPreviewDirective>;

  @ViewChild(CdkDrag) cdkDrag!: CdkDrag;

  @Input() previewActive = false;

  @Output() previewActiveChange = new EventEmitter<boolean>();

  @Input() collapsed = false;

  constructor(private cdr: ChangeDetectorRef) {}

  saveCard() {
    this.collapsed = true;
    this.save.emit();
  }

  enablePreview() {
    this.previewActive = true;
    this.previewActiveChange.emit(true);
  }

  disablePreview() {
    this.previewActive = false;
  }

  ngAfterViewInit() {
    this.createAdInputs.changes.pipe(untilDestroyed(this), startWith(this.createAdInputs)).subscribe((inputs) => {
      this.createAdInputs.forEach((input) => {
        input.previewVisible = false;
      });
      this.cdr.detectChanges();
    });
  }
}
