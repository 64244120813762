<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  aaynTextAiContainer
  [adPreviewContainer]="adPreviewService.dynamicCreativeAds"
>
  <aayn-ad-type-sections className="aayn-tiktok-identity-select-area">
    <aayn-ad-type-section
      header="Business Logo & Name"
      description="Upload your logo and write the business name that will be used in ads."
    >
      <aayn-identity-select formControlName="identity" adPreview="identity" [standAlone]="true"></aayn-identity-select>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    (fileRemove)="removeFile($event)"
    [description]="'Add your images and videos' | translate"
    [imageUrls]="uploadedAssetUrls"
    (imageError)="getImages($event.image!)"
    [showRequiredAlert]="!uploadedAssetUrls.length && _form.submitted"
    adPreview="images"
    [standAlone]="true"
    [imageCount]="imageCount"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Ad Texts" description="Add up to 5 texts">
      <aayn-create-ad-input
        *ngFor="let control of adTextsFormArray.controls; index as index"
        [maxCharacterNumber]="100"
        [formControl]="$any(adTextsFormArray.controls[index])"
        [placeholder]="'Tell people what your ad is about'"
        [requiredMessage]="'You must write at least 1 ad text.' | translate"
        adPreview="adText"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToAction"
            [ngModel]="adCreationModel.ad.callToAction"
            adPreview="callToAction"
            [standAlone]="true"
            [placeholder]="'Call To Action' | translate"
            [showTick]="true"
            [showSearch]="false"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction.label | translate }}"
            >
              {{ callToAction.label | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="url"
            placeholder="https://adyouneed.com"
            formControlName="url"
            ayn-input
            [ngModel]="adCreationModel.ad.url"
            [standAlone]="true"
            adPreview="url"
            #urlInput
          />
        </ayn-form-field>
      </section>

      <ayn-form-field class="aayn-ad-type--control aayn-ad-type__mt-spacing">
        <input
          type="text"
          ayn-input
          [placeholder]="'Ad Name' | translate"
          formControlName="name"
          [ngModel]="adCreationModel.ad.name"
          (ngModelChange)="adCreationModel.ad.name = $event"
        />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
