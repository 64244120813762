import { Bing } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Bing.Dashboard.IAdsWithMetricsRequest;
type Output = Bing.Dashboard.IAdsWithMetricsResponse;

export const adsWithMetricsBing = makeQuery<Output, Input>(`
query AdsWithMetricsBing(
  $skip: Int
  $take: Int
  $adGroupId: String!
  $searchKey: String
  $status: BingInsightStatus
  $startDate: SafeInt
  $endDate: SafeInt
  $datePreset: DatePreset
) {
  bing {
    adsWithMetrics(
      skip: $skip
      take: $take
      adGroupId: $adGroupId
      searchKey: $searchKey
      status: $status
      startDate: $startDate
      endDate: $endDate
      datePreset: $datePreset
    ) {
      edges {
        cursor
        node {
          id
          resourceName
          status
          clicks
          impressions
          ctr
          costPerClick
          conversions
          costPerConversion
          revenue
          spend
          adDetails {
            devicePreference
            editorialStatus
            finalUrlSuffix
            finalUrls
            type
            textAd {
              destinationUrl
              displayUrl
              text
              title
            }
            productAd {
              promotionalText
            }
            hotelAd {
              promotionalText
            }
            appInstallAd {
              appPlatform
            }
            expandedTextAd {
              domain
              path1
              path2
              text
              textPart2
              titlePart1
              titlePart2
              titlePart3
            }
            dynamicSearchAd {
              path1
              path2
              text
              textPart2
            }
            responsiveAd {
              businessName
              callToAction
              callToActionLanguage
              headline
              headlines
              images {
                id 
                url
              }
              longHeadline
              longHeadlineString
              longHeadlines
              text
              videos {
                id
                url
              }
            }
            responsiveSearchAd {
              descriptions
              domain
              headlines
              path1
              path2
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}

`);
