<aayn-ad-type-sections>
  <aayn-ad-type-section
    [containerClass]="collapsed ? 'aayn-carousel-card-collapsed' : ''"
    cdkDrag
    [cdkDragDisabled]="!collapsed"
    cdkDragPreviewClass="carousel-drag--preview"
  >
    <div class="aayn-carousel-card-container" [class.collapsed]="collapsed">
      <button class="aayn-carousel-card-container--move-button" *ngIf="collapsed" type="button" cdkDragHandle>
        <ayn-icon name="dots-square"></ayn-icon>
      </button>

      <ng-container *ngIf="!collapsed; else preview">
        <ng-content></ng-content>
      </ng-container>

      <button
        [ayn-button]="'white'"
        class="aayn-ad-type--carousel-container--form--button"
        type="button"
        (click)="remove.emit()"
        [disabled]="removeDisabled"
      >
        {{ 'Remove' | translate }} <ayn-icon name="trash-flat"></ayn-icon>
      </button>
      <button
        [ayn-button]="'white'"
        class="aayn-ad-type--carousel-container--form--button"
        type="button"
        (click)="saveCard()"
        [disabled]="saveDisabled"
      >
        {{ 'Save' | translate }} <ayn-icon className="check-icon" name="check"></ayn-icon>
      </button>
      <div class="aayn-carousel-card-container--button-container" *ngIf="collapsed">
        <button
          type="button"
          ayn-button="white"
          [class.active]="previewActive"
          class="aayn-carousel-card-container--button-container__preview-button"
          [ayn-tooltip]="'Show in preview' | translate"
          tooltipClass="aayn-tooltip-create-ad"
          position="bottom"
          (click)="enablePreview()"
          tabindex="-1"
          icon="eye"
        ></button>
        <button
          class="aayn-carousel-card-container--button-container__collapse-button"
          type="button"
          (click)="collapsed = false"
        >
          <ayn-icon name="chevron-down"></ayn-icon>
        </button>
      </div>
    </div>
  </aayn-ad-type-section>
</aayn-ad-type-sections>
<ng-template #preview>
  <img
    [src]="fileUploadArea.imageUrls[0] | aynSafe: 'url'"
    [alt]="fileUploadArea.imageUrls[0]"
    *ngIf="fileUploadArea.imageUrls[0]"
    class="aayn-carousel-card-container--preview-image"
  />
  <div class="aayn-carousel-card-container--preview-inputs">
    <span *ngFor="let control of inputs.toArray()">
      {{ control.value }}
    </span>
  </div>
</ng-template>
