import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { GoogleCreateAd } from '@core/ad-platforms';
import { Draft, Google } from '@core/models';
import { CreateAnAdSteps } from '@pages/create-ad/models';

import { SaveDraft } from '../base';

@Injectable({ providedIn: 'root' })
export class GoogleStateService extends SaveDraft<GoogleCreateAd.ICreateAdModel> {
  public createAd = new GoogleCreateAd.CreateAd();

  adCreationModel$ = new BehaviorSubject(this.createAd.createAdModel);

  get adCreationModel() {
    return this.adCreationModel$.value;
  }

  adCreationModelOf<AT = GoogleCreateAd.ICreateAdModel>() {
    return this.adCreationModel$.value as unknown as GoogleCreateAd.ICreateAdModel<AT>;
  }

  constructor() {
    super();
  }

  setAdModel(value: GoogleCreateAd.ICreateAdModel) {
    this.adCreationModel$.next(value);
    this.createAd.createAdModel = value;
  }

  reset() {
    this.createAd = new GoogleCreateAd.CreateAd();
    this.setAdModel(this.createAd.createAdModel);
    this.draftModel = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;
  }

  changeAdType(adType: Google.AdType) {
    const changedModel = this.createAd.changeType!('ad', adType);

    this.adCreationModel.selectedTypes.ad = adType;

    this.setAdModel(changedModel);
  }

  updateActiveStep(step: CreateAnAdSteps) {
    this.adCreationModel.activeStep = step;
  }

  mapToApiRequest() {
    const selectedAdType = this.adCreationModel.selectedTypes.ad;

    if (typeof selectedAdType == 'undefined') return;

    return this.createAd.mapToApiRequest(selectedAdType);
  }
}
