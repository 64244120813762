import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { createAynSentryErrorHandler } from '@core/error-handler/sentry-error.handler';
import { isSentryEnabled } from '@core/utils/sentry.util';

const SENTRY_PROVIDERS: Provider[] = [
  {
    provide: Sentry.TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true
  },
  {
    provide: ErrorHandler,
    useValue: createAynSentryErrorHandler({
      logErrors: true
    })
  }
];

export function getSentryProvider() {
  if (!isSentryEnabled) return [];

  return SENTRY_PROVIDERS;
}
