import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';

import { LoaderAdd, LoaderProgress, LoaderRemove, LoaderRemoveAll } from '../actions';
import { Loader } from '../models';
import { notNull } from '../utils';

export const LOADER_DEFAULTS: Loader.State = {
  list: [],
  progress: []
};

@State<Loader.State>({
  name: 'LoaderState',
  defaults: LOADER_DEFAULTS
})
@Injectable()
export class LoaderState {
  @Selector()
  static getList({ list }: Loader.State) {
    return list;
  }

  static getAny(itemOrItems: Loader.Item | Loader.Item[]) {
    if (itemOrItems instanceof Array) {
      return createSelector(
        [LoaderState],
        ({ list }: Loader.State) => !!list.find((key) => itemOrItems.indexOf(key) >= 0)
      );
    }

    return createSelector([LoaderState], ({ list }: Loader.State) => !!list.find((key) => key == itemOrItems));
  }

  static getProgress(item: Loader.Progress) {
    return createSelector([LoaderState], ({ progress }: Loader.State) => progress.find(({ id }) => id === item.id));
  }

  @Action(LoaderAdd)
  addItem({ getState, patchState }: StateContext<Loader.State>, { payload }: LoaderAdd) {
    const state = getState();

    patchState({
      list: filterList(state?.list, payload).concat(payload)
    });
  }

  @Action(LoaderProgress)
  progressItem({ getState, patchState }: StateContext<Loader.State>, { payload }: LoaderProgress) {
    const { progress } = getState();

    patchState({
      progress: filterProgress(progress, payload).concat(notNull(payload.status) ? payload : [])
    });
  }

  @Action(LoaderRemove)
  removeItem({ getState, patchState }: StateContext<Loader.State>, { payload }: LoaderRemove) {
    const state = getState();

    patchState({
      list: filterList(state?.list, payload)
    });
  }

  @Action(LoaderRemoveAll)
  removeAll({ patchState }: StateContext<Loader.State>) {
    patchState({ list: [] });
  }
}

function filterList(list: Loader.Item[], payload: Loader.Item[] | Loader.Item) {
  if (!list?.length) return [];

  if (Array.isArray(payload)) {
    return list.filter((name) => payload.indexOf(name) < 0);
  }

  return list.filter((name) => name !== payload);
}

function filterProgress(progress: Loader.Progress[], payload: Loader.Progress) {
  return progress.filter(({ id }) => id !== payload.id);
}
