import { environment } from '@environment';
import { distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { FileUploadAreaEvent } from '@ayn-ui/lib/modules/base/file-upload-area';
import { LinkedinCreateAd } from '@core/ad-platforms/linkedin';
import { Linkedin, PlatformStep } from '@core/index';
import { CanvaService } from '@core/services/canva.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LinkedinAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/ad-preview/ad-preview.service';
import {
  LinkedinFormService,
  LinkedinStateService,
  LinkedinValidationService
} from '@pages/create-ad/state/platforms/linkedin';

import { UploadAdsImagesLinkedinComponent } from '../modal';

@UntilDestroy()
@Component({
  selector: 'aayn-dynamic-image-ads--linkedin',
  templateUrl: './dynamic-image-ads.component.html'
})
export class DynamicImageAdsLinkedinComponent implements OnInit {
  protected adCreationModel = this.linkedinStateService
    .adCreationModel as LinkedinCreateAd.ICreateAdModel<Linkedin.AdModelSingleImage>;
  protected callToActions = Linkedin.CTA_LABELS;

  private _images?: Linkedin.IdWithUrl[];
  protected uploadedImageUrls: SafeUrl[] = [];

  protected form = new FormGroup({
    body: new FormControl(this.adCreationModel.ad.creative.title, [Validators.required]),
    description: new FormControl(this.adCreationModel.ad.creative.commentary, [Validators.required]),
    callToActionType: new FormControl(this.adCreationModel.ad.creative.ctaLabel, [Validators.required]),
    websiteUrl: new FormControl(this.adCreationModel.ad.creative.contentLandingPage, [Validators.required]),
    // TODO: Linkedin: send name of the ad to linkedin
    name: new FormControl(this.adCreationModel.ad.creative.name, [Validators.required])
  });

  constructor(
    private linkedinStateService: LinkedinStateService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private linkedinValidationService: LinkedinValidationService,
    private canvaService: CanvaService,
    private linkedinFormService: LinkedinFormService,
    public adPreviewService: LinkedinAdPreviewService
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      if (!environment.production) {
        console.error(`CreateAd.Linkedin.DynamicCreativeAds.CanvaService`, error);
      }
    }

    this.linkedinValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.linkedinFormService.registerForm(this.form);

    this.linkedinStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;
      const images = o.ad.creative?.images || [];
      this.uploadedImageUrls = images.map((o) => o.url);
      this._images = images;

      this.form.patchValue(
        {
          body: o.ad.creative.title,
          callToActionType: o.ad.creative.ctaLabel,
          description: o.ad.creative.commentary,
          name: o.ad.creative.name,
          websiteUrl: o.ad.creative.contentLandingPage
        },
        { emitEvent: true }
      );
    });

    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((o) => {
      this.fillField('title', o.body!);
      this.fillField('commentary', o.description!);
      this.fillField('ctaLabel', o.callToActionType!);
      this.fillField('name', o.name!);
      this.fillField('contentLandingPage', o.websiteUrl!);
    });
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesLinkedinComponent);

    if (this._images) modalRef.componentInstance.uploadedImages = this._images;
    modalRef.closed.subscribe((result: { images: Linkedin.IdWithUrl[] }) => {
      if (!result?.images) return;

      this.updateImages(result.images);

      this.cdr.detectChanges();
    });
  }

  fillField<T extends Linkedin.AdModelSingleImage, K extends keyof Linkedin.AdModelSingleImage>(
    property: K,
    value: T[K]
  ) {
    this.adCreationModel.ad.creative[property] = value;
  }

  removeFile(event: FileUploadAreaEvent) {
    this.updateImages(this._images!.filter((_, i) => i !== event.index));
  }

  updateImages(images: Linkedin.IdWithUrl[]) {
    this._images = images;
    this.uploadedImageUrls = images.map((o) => o.url);
    this.adCreationModel.ad!.creative!.imageIds = images.map((o) => o.id);
    this.adCreationModel.ad!.creative!.images = [...images];
    this.linkedinStateService.saveDraft$();
  }
}
