import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-upgrade-success',
  templateUrl: 'upgrade-success.component.html'
})
export class UpgradeSuccessComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  dismissModals() {
    this.modalService.dismissAll();
  }
}
