import { makeMutation } from '@core/queries';
import { Linkedin } from '@core/models';

interface Input {
  input: Linkedin.LinkedInCampaignUpdateModel;
}

export const updateCampaignLinkedin = makeMutation<void, Input>(`
mutation LinkedInCampaignUpdate($input: LinkedInCampaignUpdate!) {
    linkedin{
        updateCampaign(input: $input)
    }
}`);
