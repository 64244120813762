import { Observable, OperatorFunction } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

/**
 * Projects each source value to an Observable which is merged in the output Observable,
 * emitting values only after return of project Observable is completed.
 *
 * It's like `switchMap` but it emits the source value instead of the inner value.
 * @param project A function that, when applied to an item emitted by the source Observable, returns an Observable.
 *
 * @example
 *
 * const switched = of(1, 2, 3)
 *     .pipe(
 *         switchTap((x: number) =>
 *             of('any value').pipe(
 *                 tap(() => console.log('Inside SwitchTap'))
 *             )
 *         )
 *     );
 * switched.subscribe(x => console.log(x));
 * // outputs
 * - Inside SwitchTap
 * - 1
 * - Inside SwitchTap
 * - 2
 * - Inside SwitchTap
 * - 3
 */
export function switchTap<T, O extends Observable<any>>(
  project: (value: T, index: number) => O
): OperatorFunction<T, T> {
  return (source: Observable<T>) => source.pipe(switchMap((r: T, i: number) => project(r, i).pipe(mapTo(r))));
}
