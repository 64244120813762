import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PURE_REQUEST } from '@core/interceptors/injection-tokens';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPureRequest = req.context.get(PURE_REQUEST);
    if (isPureRequest) return next.handle(req);
    req = req.clone({
      setHeaders: {
        'Accept-Language': 'en'
      }
    });

    return next.handle(req);
  }
}
