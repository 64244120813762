<header
  class="ayn-layout--topbar"
  *ngIf="{
    isCancelledScheduled: isCancelledScheduled$ | async,
    isBusinessSelectionAvailable: layoutService.isBusinessSelectionAvailable$ | async
  } as _"
>
  <aayn-business-selection
    *ngIf="!_.isCancelledScheduled && !detectedAdBlock"
    [businessSelectionDisabled]="!_.isBusinessSelectionAvailable"
  ></aayn-business-selection>

  <aayn-alert-bar
    text="Your account is scheduled to be cancelled! Changed your mind?"
    buttonText="Start Membership"
    [buttonLoading]="(loader$ | async) || false"
    *ngIf="!detectedAdBlock && _.isCancelledScheduled"
    (onClickHide)="_.isCancelledScheduled = false"
  >
  </aayn-alert-bar>

  <aayn-alert-bar
    leftIcon="adblock"
    title="Ad blocker detected"
    text="Please disable to ad blocker and refresh the page for the application to function properly."
    buttonText="Refresh Page"
    buttonIcon=""
    *ngIf="detectedAdBlock"
    (onButtonClick)="reloadPage()"
  >
  </aayn-alert-bar>

  <section class="ayn-layout--right">
    <!-- <aayn-level></aayn-level> -->

    <!-- <aayn-notifications></aayn-notifications> -->

    <a
      routerLink="/main/upgrade"
      ayn-button="primary"
      icon="upgrade"
      icon-direction="left"
      class="aayn-layout--topbar-upgrade"
      *ngIf="!(isAppsumoUser$ | async)"
    >
      Upgrade
    </a>

    <aayn-language-selection></aayn-language-selection>

    <aayn-user-menu></aayn-user-menu>
  </section>
</header>
