import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { patchWithHttps } from '@core/utils';

@UntilDestroy()
@Directive({
  selector: 'input[aaynUrlInput]',
  standalone: true
})
export class UrlInputDirective implements OnInit {
  constructor(private formControl: NgControl) {}

  ngOnInit() {
    if (this.formControl) {
      this.formControl.valueChanges
        ?.pipe(
          filter(() => !!this.formControl.invalid),
          debounceTime(100),
          untilDestroyed(this)
        )
        .subscribe((url) => {
          patchWithHttps(this.formControl.control!, url);
        });
    }
  }
}
