import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { BusinessCategoryMap, IBusiness, IPlatformContext, PlatformIcons, PlatformKeyByValues } from '@core/models';
import { PlatformContextService } from '@core/services';
import { BusinessState, GetBusinesses } from '@core/state/business.state';
import { LoaderState } from '@core/state/loader.state';
import { GetPlatformContexts, PlatformContextState } from '@core/state/platform-context.state';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Component({
  selector: 'aayn-disconnect-confirmation-modal',
  templateUrl: 'disconnect-confirmation-modal.component.html'
})
export class DisconnectConfirmationModalComponent implements OnInit {
  platformContext?: IPlatformContext;

  protected businessesByConnectedPlatform: IBusiness[] = [];

  BusinessCategoryMap = BusinessCategoryMap;

  PlatformKeyByValues = PlatformKeyByValues;

  PlatformIcons = PlatformIcons;

  @Select(LoaderState.getAny('DisconnectPlatform')) loader$?: Observable<boolean>;

  constructor(
    private store: Store,
    private activeModal: NgbActiveModal,
    private platformContextService: PlatformContextService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.platformContext) return;

    this.businessesByConnectedPlatform = this.store
      .selectSnapshot(BusinessState.Businesses)
      .filter((o) => o.connectedAdAccounts.some((ca) => ca.source === this.platformContext?.source!));
  }

  goBack() {
    this.activeModal.dismiss();
  }

  disconnect$() {
    this.platformContextService
      .disconnectPlatform$(this.platformContext?.id!, this.platformContext?.source!)
      .subscribe((result) => {
        if (result.data) {
          this.store.dispatch([new GetPlatformContexts(), new GetBusinesses()]).subscribe(() => {
            const platforms = this.store.selectSnapshot(PlatformContextState.RequireReconnectionPlatforms);

            if (platforms.length) {
              this.goBack();

              return;
            }

            const hasAdAccount = this.store.selectSnapshot(BusinessState.SelectedBusinessHasAdAccount);
            const businessId = this.store.selectSnapshot(BusinessState.SelectedBusiness)?.id;
            if (!hasAdAccount) {
              this.router.navigate(['/main/business/update', businessId]);
            }

            this.modalService.dismissAll();
          });
        }
      });
  }
}
