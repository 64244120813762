<div class="aayn-ad-type--collapse-extentions-item" [style.display]="!isButtonShow ? 'block' : 'flex'">
  <div (click)="buttonClick.emit()" [class.w-100]="!isButtonShow">
    <h3>{{ title | translate }}</h3>
    <p>{{ description | translate }}</p>
  </div>
  <div class="aayn-ad-type--collapse-extentions-item--actions">
    <button
      *ngIf="isButtonShow"
      (mouseover)="isEditHover = true"
      (mouseout)="isEditHover = false"
      class="aayn-ad-type--collapse-extentions-item--edit"
      [ayn-button]="isEditHover ? 'accent' : 'light-accent'"
      (click)="buttonClick.emit($event)"
    >
      <ayn-icon name="edit"></ayn-icon>
    </button>

    <button
      *ngIf="isButtonShow"
      (mouseover)="isClearHover = true"
      (mouseout)="isClearHover = false"
      class="aayn-ad-type--collapse-extentions-item--clear"
      [ayn-button]="isClearHover ? 'orange' : 'light-primary'"
      (click)="clearExtentions()"
    >
      <ayn-icon [name]="isClearHover ? 'cross-mini' : 'fill-check'"></ayn-icon>
    </button>
  </div>
</div>
