import { environment } from 'apps/ayn-maldives/src/environments/environment';
import { ToastrService } from 'ngx-toastr';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  Bing,
  createUploadImageItemWithInfos,
  Facebook,
  IdWithUrl,
  IdWithUrlGlobal,
  Linkedin,
  Platforms,
  SmartCampaign,
  TikTok,
  UploadImageItemWithInfo
} from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';

import { SmartCampaignService } from '@core/services/smart-campaign.service';
import { Store } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';
import { GoogleImageService } from '@pages/create-ad/services/google-image.service';
import { uniqueId } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { delay, filter, map, take, tap } from 'rxjs/operators';
import { SmartCampaignToastComponent } from '@core/components';
import { pipe } from 'fp-ts/lib/function';
import * as R from 'fp-ts/Record';

type ImageResult = (
  | Facebook.CreateAd.IUploadImageResponse
  | Bing.AudienceCampaign.FileResponse
  | TikTok.CreateAd.Backend.TikTokUploadImageResponse
  | IdWithUrl
  | Linkedin.CreateAd.UploadResponse
) & {
  uploadUrl?: string | null;
};

export const isImagesValid = (
  imageConfig: SmartCampaign.Backend.ImageConfigDto[],
  uploadedImages: SmartCampaign.Client.ImageAssets
): { isValid: boolean; unValidConfigs: SmartCampaign.Backend.ImageConfigDto[] } => {
  let isValid = true;
  const unValidConfigs: SmartCampaign.Backend.ImageConfigDto[] = [];
  const requiredGroups = imageConfig.filter((config) => config.required);
  requiredGroups.forEach((config) => {
    const isGroupValid = config.includedPlatforms.every((platform) => {
      const uploadedGroupImages = uploadedImages[platform as SmartCampaign.Client.ImageAssetPlatforms]
        ?.filter(Boolean)
        .find(({ groupName }) => groupName === config.groupName)?.images;

      return (
        (uploadedGroupImages?.length || 0) >=
        config.platforms[platform as SmartCampaign.Client.ImageAssetPlatforms]!.quantity.min
      );
    });
    if (!isGroupValid) {
      unValidConfigs.push(config);
    }
    isValid = isValid && isGroupValid;
  });
  return { isValid, unValidConfigs };
};
@Component({
  selector: 'aayn-upload-ads-images--smart-campaign',
  templateUrl: 'upload-ads-images.component.html'
})
export class UploadAdsImagesSmartCampaignComponent implements OnInit {
  @Input() protected uploadedImages: SmartCampaign.Client.ImageAssets = {
    facebook: [],
    bing: [],
    linkedin: [],
    tiktok: [],
    google: []
  };

  @Input() protected images = {} as { [key: string]: UploadImageItemWithInfo[] };

  @Input() imageConfig: SmartCampaign.Backend.ImageConfigDto[] = [];

  configInitialized$ = new ReplaySubject(1);

  protected isValid: boolean = true;

  groupNames = SmartCampaign.Backend.ImageGroupName;

  get logoConfig() {
    return this.imageConfig.find((c) => c.groupName === SmartCampaign.Backend.ImageGroupName.Logo);
  }

  get landScapeLogo() {
    return this.imageConfig.find((c) => c.groupName === SmartCampaign.Backend.ImageGroupName.LogoLandscape);
  }

  constructor(
    public modal: NgbActiveModal,
    private toastrService: ToastrService,
    private smartCampaignService: SmartCampaignService,
    private store: Store,
    private googleImageService: GoogleImageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  initialize(imageConfig: SmartCampaign.Backend.ImageConfigDto[]) {
    this.imageConfig = imageConfig || [];
    this.imageConfig.sort((c1, c2) => {
      if (c1.groupName === SmartCampaign.Backend.ImageGroupName.Logo) return -1;
      if (
        c1.groupName === SmartCampaign.Backend.ImageGroupName.LogoLandscape &&
        c2.groupName !== SmartCampaign.Backend.ImageGroupName.Logo
      )
        return -1;
      return 1;
    });
    this.imageConfig?.forEach((value) => {
      this.images[value.groupName] = createUploadImageItemWithInfos(value.common.quantity.min || 1);
    });

    this.configInitialized$.next();
  }

  runAfterConfig(cb: () => void) {
    this.configInitialized$.pipe(take(1)).subscribe(() => {
      cb.call(this);
    });
  }

  uploadImage(config: SmartCampaign.Backend.ImageConfigDto, { file, fileUrl }: IFileAddedEvent) {
    const formData = new FormData();
    formData.append('image', file);
    const request = this.buildRequest(config);

    const imageIndex = this.images[config.groupName].findIndex((image) => !image.startedUploading && !image.completed);
    const image = this.images[config.groupName][imageIndex]!;
    this.smartCampaignService
      .uploadImage$(formData, request)
      .pipe(
        tap((uploadResult) => {
          image.startedUploading = true;
          image.progressValue = uploadResult.progress;
          this.cdr.detectChanges();

          if (!environment.production) console.log('[CreateAd.SmartCampaign.UploadResult]: ', uploadResult);
        }),
        filter((uploadResult) => uploadResult.state === 'DONE'),
        delay(2000),
        map((uploadResult) => uploadResult.result),
        filter((body): body is SmartCampaign.Backend.FileUploadResponse => !!body)
      )
      .subscribe({
        next: (body) => {
          const uid = uniqueId('images_');
          image.id = uid;
          const failures = Object.entries(body.failures || {});

          if (failures.length) {
            this.processErrors(failures, image);
            return;
          }

          Object.entries(body.results).forEach(([platform, imageResult]) => {
            if (!this.uploadedImages[platform]) this.uploadedImages[platform] = {};

            this.mapToUploadImage(image, imageResult as ImageResult, platform, fileUrl, uid, config);
          });
          image.progressValue = 100;

          this.toastrService.success('Image upload successful.', 'Successful');
        },
        error: (error: { error: IRestError }) => {
          if (Array.isArray(error?.error?.message)) {
            error.error.message?.forEach((o) => {
              this.toastrService.warning(o);
            });
          }
          image.startedUploading = false;
          image.progressValue = 0;
        }
      });
  }

  private buildRequest(config: SmartCampaign.Backend.ImageConfigDto) {
    return config.includedPlatforms.reduce((acc, platform) => {
      if (platform === 'bing') {
        acc.bing = { type: config.platforms.bing?.typeName as SmartCampaign.Backend.BingMsanImage };
      } else if (platform == 'linkedin') {
        const platform = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatformsDetails);
        const pageId = platform?.find((x) => x?.source == Platforms.LinkedIn)?.pageId;
        if (pageId)
          acc.linkedin = {
            owner: pageId
          };
      } else {
        acc[platform] = true;
      }
      return acc;
    }, {} as SmartCampaign.Backend.UploadImageDetails);
  }

  private mapToUploadImage(
    image: UploadImageItemWithInfo,
    imageResult: ImageResult,
    platform: string,
    fileUrl: string | undefined,
    uid: string,
    config: SmartCampaign.Backend.ImageConfigDto
  ) {
    if (!image.fileUrl) {
      image.fileUrl = this.mapToIdWithUrl(imageResult, platform, fileUrl, uid).url;
    }

    const typeName = config.platforms[platform]?.typeName;

    if (typeName) {
      const typeNameObject = this.uploadedImages[platform]?.find((x) => x?.typeName === typeName);
      if (typeNameObject) {
        typeNameObject.images.push(this.mapToIdWithUrl(imageResult, platform, fileUrl, uid));
      } else {
        this.uploadedImages[platform].push({
          typeName: typeName,
          groupName: config.groupName,
          images: [this.mapToIdWithUrl(imageResult, platform, fileUrl, uid)]
        });
      }
    } else {
      let group = this.uploadedImages[platform].find(({ groupName }) => groupName === config.groupName);
      if (!group) {
        this.uploadedImages[platform].push({ images: [], groupName: config.groupName });
        group = this.uploadedImages[platform][this.uploadedImages[platform].length - 1];
      }
      group.images.push(this.mapToIdWithUrl(imageResult, platform, fileUrl, uid));
    }
  }

  mapToIdWithUrl(imageResult: ImageResult, platform: string, fileUrl: string | undefined, uid: string) {
    switch (platform) {
      case 'bing':
        return {
          id: (imageResult as Bing.AudienceCampaign.FileResponse).id,
          url: (imageResult as Bing.AudienceCampaign.FileResponse).url,
          globalId: uid
        };

      case 'facebook':
        return {
          id: (imageResult as Facebook.CreateAd.IUploadImageResponse).hash,
          url: (imageResult as Facebook.CreateAd.IUploadImageResponse).url,
          globalId: uid
        };

      case 'google':
        return {
          id: (imageResult as IdWithUrl).id,
          url: this.googleImageService.getImageUrl((imageResult as IdWithUrl).url) || fileUrl!,
          globalId: uid
        };

      case 'linkedin':
        return {
          id: (imageResult as Linkedin.CreateAd.UploadResponse).image,
          url: imageResult.uploadUrl || fileUrl,
          globalId: uid
        };

      case 'tiktok':
        return {
          id: (imageResult as TikTok.CreateAd.Backend.TikTokUploadImageResponse).imageId,
          url: (imageResult as TikTok.CreateAd.Backend.TikTokUploadImageResponse).imageUrl,
          globalId: uid
        };
      default:
        return { id: '', url: '', globalId: uid };
    }
  }

  remove(configName: string, id: string) {
    this.eachUploadedGroup((group) => {
      if (group.groupName !== configName) return;

      const index = group.images.findIndex((o) => o?.globalId === id);
      if (index > -1) {
        group.images = group.images.filter((_, i) => i !== index);
      }
    });

    this.images[configName] = [
      ...this.images[configName].filter((image) => image.id !== id),
      new UploadImageItemWithInfo()
    ];
  }

  setUploadImages(images: SmartCampaign.Client.ImageAssets) {
    this.runAfterConfig(() => {
      this.uploadedImages = images;
      this.eachUploadedGroup((group) => {
        if (!this.images[group.groupName]) return;
        group.images.forEach((image, index) => this.setImage(group.groupName, image));
        this.cdr.detectChanges();
      });
    });
  }

  private setImage(groupName: string, image: IdWithUrlGlobal<string>) {
    const isExist = this.images[groupName].find((item) => item.id === image.globalId);
    if (isExist) return;

    let uploadImageItem = this.images[groupName].find((item) => !item.startedUploading && !item.completed);

    if (!uploadImageItem) {
      uploadImageItem = new UploadImageItemWithInfo(true);
      this.images[groupName].push(uploadImageItem);
    } else {
      uploadImageItem.complete();
    }

    uploadImageItem.fileUrl = image.url;
    uploadImageItem.id = image.globalId;
  }

  private eachUploadedGroup(cb: (group: SmartCampaign.Client.ImageItem) => void) {
    Object.values(this.uploadedImages).forEach((groups) => groups.forEach((group) => cb(group)));
  }

  closeModal() {
    this.modal.dismiss();
  }

  saveModal() {
    this.validateImages();
    if (!this.isValid) return;
    this.modal.close({
      images: this.getValidUploadedImages()
    });
  }

  validateImages() {
    const { isValid, unValidConfigs } = isImagesValid(this.imageConfig, this.uploadedImages);
    this.isValid = isValid;
    this.imageConfig.forEach((config) => {
      config.unValid = unValidConfigs.some((c) => c.groupName === config.groupName);
    });
    this.cdr.detectChanges();
  }

  getValidUploadedImages() {
    return pipe(
      this.uploadedImages,
      R.filter((groups) => !!groups?.length),
      R.map((groups) =>
        groups.filter(Boolean).map((group) => ({ ...group, images: group.images.filter((image) => !!image.id) }))
      )
    );
  }

  selectedFileChange(id: string) {
    this.eachUploadedGroup((group) => {
      const index = group.images.findIndex((o) => o?.globalId === id);
      if (index > -1) {
        group.images[index] = {
          id: '',
          url: '',
          globalId: ''
        };
      }
      this.cdr.detectChanges();
    });
  }

  addMoreImages(groupName: SmartCampaign.Backend.ImageGroupName) {
    this.images[groupName] = [...this.images[groupName], ...createUploadImageItemWithInfos(1)];
  }

  removeEmptyFile(groupName: SmartCampaign.Backend.ImageGroupName, index: number) {
    this.images[groupName] = this.images[groupName].filter((_, i) => i !== index);
  }

  private processErrors(failures: Array<[string, string[]]>, image: UploadImageItemWithInfo) {
    failures.forEach(([platform, errors]) => {
      this.toastrService.error(errors.join(' ,'), '', {
        toastComponent: SmartCampaignToastComponent,
        payload: {
          platform
        }
      });
    });
    image.reset();
    this.cdr.detectChanges();
  }
}
