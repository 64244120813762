import { Component, Input } from '@angular/core';
import { TikTok } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-ad-preview--tiktok',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewTiktokComponent {
  @Input() selectedAdType: TikTok.CreateAd.AdType = TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS;

  protected AdTypes = TikTok.CreateAd.AdType;
}
