<div [ngSwitch]="selectedAdType">
  <div *ngSwitchCase="AdTypes.DynamicCreative">
    <aayn-dynamic-creative-preview--facebook
      [businessPageDetail]="businessPageDetail"
    ></aayn-dynamic-creative-preview--facebook>
  </div>

  <div *ngSwitchCase="AdTypes.CarouselAds">
    <aayn-carausel-preview--facebook [businessPageDetail]="businessPageDetail"></aayn-carausel-preview--facebook>
  </div>

  <div *ngSwitchCase="AdTypes.InstantExperienceAds">
    <aayn-existing-post-preview--facebook
      [businessPageDetail]="businessPageDetail"
    ></aayn-existing-post-preview--facebook>
  </div>
</div>
