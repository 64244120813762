import { Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { PipesModule } from '../../pipes/pipes.module';
import { AynUITranslateModule } from '../../translate/translate.module';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { LoaderModule } from '../loader';
import { Select as SelectComponent, SelectModule } from '../select';

export interface ExpandableTableColumn<T extends any = any> {
  displayName?: string;
  key?: string;
  rowTemplate?: TemplateRef<any>;
  columnTemplate?: TemplateRef<any>;
  component?: any;
  width?: number;
  style?: string;
  align?: 'left' | 'right' | 'center';
  isSortable?: boolean;
  sortKey?: string;
  sortDirection?: 'asc' | 'desc';
  valueTransform?: (row: ExpandableTableRow<T>, column: ExpandableTableColumn) => any;
  visible?: boolean;
  changeable?: boolean;
  order?: number;
}

export type ExpandableTableRow<T> = {
  parent?: ExpandableTableRow<T>;
  children: ExpandableTableRow<T>[];
  activeType?: 'primary' | 'accent' | '';
  template?: TemplateRef<any>;
  collapsed: boolean;
  loading: boolean;
  collapsable?: boolean;
  childLevel?: number;
  class?: string;
} & T;

export interface ExpandableTableConfig<T> {
  rowClick?: (row: ExpandableTableRow<T>) => Observable<Array<T & Partial<ExpandableTableRow<T>>>>;
  serverSidePagination?: boolean;
  disablePaginate?: boolean;
  serverSideSorting?: boolean;
  rowIdentifier?: string;
}

export interface ExpandableTableOffsetPagination {
  page: number;
  size: number;
  totalPages: number;
}

export interface ExpandableTableCursorPagination {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
  page: string;
  size: number;
}

@UntilDestroy()
@Component({
  selector: 'ayn-expandable-table',
  templateUrl: 'expandable-table.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableTable<T> implements OnChanges {
  @Input() columns: Array<ExpandableTableColumn> = [];

  @Input() config: ExpandableTableConfig<T> = {
    rowClick: () => of([]),
    rowIdentifier: 'id'
  };

  @Input() rows!: Array<Partial<ExpandableTableRow<T>> & T>;

  @Output() rowsChange = new EventEmitter<Array<ExpandableTableRow<T>>>();

  @Input() showPagination: boolean = false;

  @Input() trackByFn = (row) => row;

  @Input() pagination?: ExpandableTableOffsetPagination = {
    page: 1,
    size: 5,
    totalPages: 0
  };

  @Input() cursorPagination?: ExpandableTableCursorPagination;

  @Output() columnsChange = new EventEmitter<Array<ExpandableTableColumn>>();

  @Output() paginationChange = new EventEmitter<{ page: number; size: number }>();

  @Output() cursorPaginationChange = new EventEmitter<{ page: string; size: number; isNext: boolean }>();

  @ViewChildren('columnSelect') columnSelects!: QueryList<SelectComponent>;

  @ContentChild('emptyContent') emptyContent?: TemplateRef<any>;

  _rows!: Array<ExpandableTableRow<T>>;

  paginatedRows: Array<ExpandableTableRow<T>> = [];

  lastActiveRowIdentifierValue: string | number | null = null;

  pageSizes = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 }
  ];
  searchTerm = '';

  get size() {
    if (this.cursorPagination) {
      return this.cursorPagination.size;
    }

    return this.pagination!.size;
  }

  set size(size: number) {
    if (this.cursorPagination) {
      this.cursorPagination.size = size;
    } else {
      this.pagination!.size = size;
    }
  }

  get changeableColumns() {
    return this.columns.filter((column) => column.changeable);
  }

  get activeColumns() {
    return this.columns.filter((column) => column.visible);
  }

  get gridColumns() {
    return this.activeColumns
      .map((o) => (o.width ? `${o.width}px` : '1fr'))
      .slice(1, this.activeColumns.length - 1)
      .join(' ');
  }

  get firstGridColumn() {
    return this.activeColumns.map((o) => (o.width ? `${o.width}px` : '1fr')).at(0);
  }

  get lastGridColumn() {
    return this.activeColumns.map((o) => (o.width ? `${o.width}px` : '1fr')).at(-1);
  }

  get hasNextPage() {
    if (this.cursorPagination) {
      return this.cursorPagination.hasNextPage;
    }
    return this.pagination!.page < this.pagination!.totalPages;
  }

  get hasPrevPage() {
    if (this.cursorPagination) {
      return this.cursorPagination?.hasPreviousPage;
    }

    return this.pagination!.page > 1;
  }

  get lastActiveRow() {
    if (!this.lastActiveRowIdentifierValue) {
      return null;
    }
    return this.findRow(
      this.lastActiveRowIdentifierValue,
      this.config.rowIdentifier as keyof ExpandableTableRow<T>,
      this._rows
    );
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columns) {
      this.columns.forEach((o, index) => {
        if (typeof o.visible == 'undefined') o.visible = true;
        if (typeof o.key == 'undefined' && typeof o.displayName != 'undefined')
          o.key = o.displayName?.toLowerCase().replaceAll(/s/g, '-');
      });

      this.orderColumns();
    }

    if (changes.rows) {
      this._rows = this.mapRows(changes.rows.currentValue);
      this.createPagination();
    }
  }

  toggle(event: MouseEvent, row: ExpandableTableRow<T>) {
    if (!this.config.rowIdentifier) return;

    event.preventDefault();
    event.stopPropagation();
    if (!row.collapsable || row.loading) {
      return;
    }

    if (!row.collapsed) {
      if (!row.collapsed && this.lastActiveRowIdentifierValue !== row[this.config.rowIdentifier]) {
        const lastActiveRow = this.lastActiveRow;
        if (lastActiveRow) {
          this.collapse(lastActiveRow);
        }
        this.lastActiveRowIdentifierValue = row[this.config.rowIdentifier];
        return;
      } else {
        this.collapse(row);
        return;
      }
    }
    row.collapsed = !row.collapsed;
    if (!row.collapsed) {
      row.loading = true;
      this.lastActiveRowIdentifierValue = row[this.config.rowIdentifier];
      this.config.rowClick!(row)
        .pipe(
          take(1),
          finalize(() => {
            row.loading = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe((children) => {
          row.children = children.map((child) => {
            return {
              parent: row,
              children: [],
              collapsed: true,
              loading: false,
              collapsable: true,
              activeType: 'accent',
              childLevel: row.childLevel ? row.childLevel + 1 : 1,
              ...child
            };
          });
          this.rowsChange.emit(this._rows);
          this.cdr.detectChanges();
        });
    }
  }

  changeVisibleColumn(column: ExpandableTableColumn, newColumnKey: string) {
    const newColIndex = this.columns.findIndex((col) => col.key === newColumnKey);
    const oldColIndex = this.columns.findIndex((col) => col.key === column.key);
    if (newColIndex !== -1 && oldColIndex !== -1) {
      const old = this.columns[oldColIndex];
      this.columns[oldColIndex] = { ...this.columns[newColIndex], visible: true };
      this.columns[newColIndex] = { ...old, visible: false };
      this.columnsChange.emit([...this.columns]);
    }
  }

  prevPage() {
    if (this.cursorPagination) {
      this.cursorPaginationChange.emit({
        page: this.cursorPagination.startCursor,
        size: this.cursorPagination.size,
        isNext: false
      });
    } else {
      --this.pagination!.page;
      this.paginate(this.pagination!.page);
    }
  }

  nextPage() {
    if (this.cursorPagination) {
      this.cursorPaginationChange.emit({
        page: this.cursorPagination.endCursor,
        size: this.cursorPagination.size,
        isNext: true
      });
    } else {
      ++this.pagination!.page;
      this.paginate(this.pagination!.page);
    }
  }

  paginate(page: number) {
    if (!this.config.serverSidePagination) {
      this.paginatedRows = this._rows.slice((page - 1) * this.pagination!.size, page * this.pagination!.size);
    }
    this.lastActiveRowIdentifierValue = null;
    if (this.pagination) {
      this.paginationChange.emit({
        ...this.pagination,
        page
      });
    }
  }

  createPagination() {
    if (this.config.disablePaginate) {
      this.paginatedRows = this._rows;
      return;
    }
    if (!this.config.serverSidePagination) {
      this.paginatedRows = this._rows.slice(
        (this.pagination!.page - 1) * this.pagination!.size,
        this.pagination!.page * this.pagination!.size
      );
      this.pagination!.totalPages = Math.ceil(this._rows.length / this.pagination!.size);
    } else {
      this.paginatedRows = this._rows;
    }
  }

  pageSizeChange(size: number) {
    if (this.config.serverSidePagination) {
      if (this.cursorPagination) {
        this.cursorPaginationChange.emit({
          page: '',
          size,
          isNext: false
        });
      } else {
        this.paginationChange.emit({
          ...this.pagination!,
          size,
          page: 1
        });
      }
    } else {
      this.pagination!.page = 1;
      this.pagination!.size = size;
      this.createPagination();
    }
  }

  mapRows(rows: Array<Partial<ExpandableTableRow<T>> & T>) {
    return rows.map((row) => {
      return {
        children: [],
        collapsed: true,
        loading: false,
        collapsable: true,
        activeType: 'primary',
        ...row
      };
    });
  }

  collapse(row: ExpandableTableRow<T>, event?: MouseEvent) {
    if (!row.collapsed) {
      event?.preventDefault();
      event?.stopPropagation();
      this.collapseAll(row);
      this.lastActiveRowIdentifierValue = row.parent?.[this.config.rowIdentifier!] || this.getFirstExpandedRow();
    }
  }

  collapseAll(row: ExpandableTableRow<T>) {
    row.collapsed = true;
    row?.children?.forEach((child) => this.collapseAll(child));
    while (row.parent) {
      row = row.parent;
      row.collapsed = true;
    }
  }

  getFirstExpandedRow(): ExpandableTableRow<T> | null {
    return this.findRow(false, 'collapsed', this._rows)?.[this.config.rowIdentifier!] || null;
  }

  private orderColumns() {
    const orderIndexes = this.columns.filter((o) => typeof o.order !== 'undefined').map((o) => o.order!);
    if (orderIndexes.length > 0) {
      this.columns.forEach((column, index) => {
        if (!column.order) {
          const orderIndex = orderIndexes.find((o) => o === index);
          if (orderIndex) {
            column.order = orderIndex + 1;
            orderIndexes.push(orderIndex + 1);
          } else {
            column.order = index;
          }
        }
      });
      this.columns.sort((a, b) => a.order! - b.order!);
    }
  }

  isRowActive(row: ExpandableTableRow<T>) {
    return (
      this.lastActiveRowIdentifierValue === row[this.config.rowIdentifier!] ||
      (row.parent && this.isRowActive(row.parent))
    );
  }

  findRow(
    value: any,
    key: keyof ExpandableTableRow<T>,
    rows: Array<ExpandableTableRow<T>>
  ): ExpandableTableRow<T> | undefined {
    let row = rows.find((row) => row[key] === value);
    if (!row) {
      rows.forEach((_row) => {
        if (!row) {
          row = this.findRow(value, key, _row.children);
        }
      });
    }
    return row;
  }
}

@NgModule({
  imports: [
    CommonModule,
    LoaderModule,
    SelectModule,
    FormsModule,
    IconModule,
    InputModule,
    ButtonModule,
    PipesModule,
    AynUITranslateModule
  ],
  exports: [ExpandableTable],
  declarations: [ExpandableTable],
  providers: []
})
export class ExpandableTableModule {}
