<ayn-modal-page #modalPage>
  <ng-template #body>
    <aayn-account-select-modal-content
      [modal]="modalPage.modalRef"
      [activeStep]="activeStep"
      [(query)]="query"
      [buttonDisabled]="completeDisabled"
      (buttonClick)="complete()"
      [headerLeftTemplate]="headerLeftTemplate"
      icon="tiktok"
    >
      <aayn-account-ad-account-select
        *ngIf="activeStep === 0"
        [adAccounts]="(adAccounts$ | async) || []"
        (selectAdAccount)="selectAdAccount($event)"
        [platform]="platforms.TikTok"
        [query]="query"
      ></aayn-account-ad-account-select>
    </aayn-account-select-modal-content>

    <ng-template #headerLeftTemplate>
      <div *ngIf="false" class="aayn-account-select--google__header" (click)="showCreateNewAccountAccountModal()">
        <button ayn-button="white-primary" icon="plus">{{ 'Create a New Ad Account' | translate }}</button>
      </div>
    </ng-template>
  </ng-template>
</ayn-modal-page>
