import { Component } from '@angular/core';
import { Platforms } from '@core/models';
import { GoogleAdPreviewService } from '../../ad-preview.service';
import { AdPreviewGooglePlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-display-ads-preview--google',
  templateUrl: './display-ads.component.html'
})
export class DisplayAdsPreviewGoogleComponent {
  Platforms = Platforms;

  platforms = [AdPreviewGooglePlatform];

  constructor(public adPreviewService: GoogleAdPreviewService) {}
}
