import { Select } from '@ngxs/store';
import { debounceTime } from 'rxjs/operators';

export function ViewSelect(rawSelector: any, ...paths: string[]): PropertyDecorator {
  return function (target, key) {
    const selector = rawSelector || ((state) => state);
    const decorator = Select(selector, ...paths);
    decorator(target, key);
    const observable = target[key];
    Object.defineProperty(target, key, {
      get() {
        return observable.pipe(debounceTime(0));
      }
    });
  };
}
