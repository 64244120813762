import { Platforms, SmartCampaign, SupportedSmartCampaignObject } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { params: SmartCampaign.Backend.SmartCampaignInterestGenerateRequestDto };
type Output = SmartCampaign.Backend.SmartCampaignInteresetGenerateResponseDto;

const platformsObject = {
  [Platforms.Meta]: `facebook { id, name, type, audienceSizeLowerBound, audienceSizeUpperBound, description, path }`,
  [Platforms.Google]: `google { id, name, type, resourceName, interestType }`,
  [Platforms.Bing]: `bing { id, name, type, audienceNetworkSize }`,
  [Platforms.LinkedIn]: `linkedIn { id, name, type, urn, facetUrn }`,
  [Platforms.TikTok]: `tiktok { id, name, type }`
};

export const getInterest = (selectedPlatforms: Platforms[]) =>
  makeQuery<Output, Input>(
    `
  query GetSmartInterest($params: SmartCampaignInterestGenerateRequestDto!) {
    ayn {
      audience {
        interests (params: $params) {
          failures {
           ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }

          statuses {
            ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }

          results {
            ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
          }
        }
      }
    }
  }
`,
    { resultPath: 'ayn.audience.interests' }
  );
