import { NgModule } from '@angular/core';
import { ButtonModule, IconModule, ModalPageModule, VimeoPlayerModule } from '@ayn-ui/public-api';
import {
  ClickFraudProtectionComponent,
  FirstBrandCreatedComponent,
  SorryToSeeYouGoComponent,
  TimeToPutYourCardComponent,
  UnpaidInvoicesComponent,
  VideoModalComponent,
  WelcomeToAdYouNeedComponent,
  WelcomeUniveristyComponent
} from './components';

@NgModule({
  declarations: [
    VideoModalComponent,
    WelcomeToAdYouNeedComponent,
    FirstBrandCreatedComponent,
    SorryToSeeYouGoComponent,
    UnpaidInvoicesComponent,
    WelcomeUniveristyComponent,
    ClickFraudProtectionComponent,
    TimeToPutYourCardComponent
  ],
  imports: [ModalPageModule, ButtonModule, VimeoPlayerModule, IconModule]
})
export class VideoModalModule {}
