import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'identities';
type Output = QueryResult<typeof queryField, TikTok.Business.TikTokIdentityOutputDto[]>;
export const getIdentitiesQuery = makeQuery<Output, void>(`
query TikTokIdentities {
  tiktok {
    ${queryField} {
      displayName
      availableStatus
      identityAuthorizedBcId
      profileImage
      canPushVideo
      identityId
      canUseLiveAds
      canPullVideo
      identityType
    }
  }
}
`);
