import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bing, BingService, COUNTRIES, Country, CURRENCIES_LIST, ICurrency, Language, LANGUAGES } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { PlatformContextState } from '@core/state/platform-context.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aayn-ad-account-creation',
  templateUrl: './ad-account-creation.component.html'
})
export class AdAccountCreationComponent implements OnInit {
  accountCreationForm = new FormGroup({
    adAccountName: new FormControl<string | null>(null, [Validators.required]),
    language: new FormControl<string | null>(null, [Validators.required]),
    currencyCode: new FormControl<string | null>(null, [Validators.required]),
    businessName: new FormControl<string | null>(null, [Validators.required]),
    countryCode: new FormControl<string | null>(null, [Validators.required]),
    stateOrProvinceCode: new FormControl<string | null>(null),
    city: new FormControl<string | null>(null, [Validators.required]),
    line1: new FormControl<string | null>(null, [Validators.required]),
    line2: new FormControl<string | null>(null),
    line3: new FormControl<string | null>(null),
    line4: new FormControl<string | null>(null),
    postalCode: new FormControl<number | null>(null)
  });

  countries: Country[] = COUNTRIES;

  languages: Language[] = LANGUAGES;

  currencies: ICurrency[] = CURRENCIES_LIST;

  @Select(LoaderState.getAny(['ConnectAdAccount']))
  loader$?: Observable<boolean>;

  constructor(public modal: NgbActiveModal, private bingService: BingService, private store: Store) {}

  ngOnInit(): void {}

  createAdAccountSubmit(): void {
    if (this.accountCreationForm.valid) {
      const {
        language,
        adAccountName,
        currencyCode,
        businessName,
        city,
        stateOrProvinceCode,
        countryCode,
        postalCode,
        line1,
        line2,
        line3,
        line4
      } = this.accountCreationForm.value;

      const payload = {
        language,
        adAccountName,
        currencyCode,
        businessAddress: {
          businessName,
          city,
          countryCode: countryCode?.toUpperCase(),
          stateOrProvinceCode: stateOrProvinceCode?.toUpperCase(),
          postalCode,
          line1,
          line2,
          line3,
          line4
        }
      } as Bing.Business.IBingCreatedAdAccountRequest;

      this.bingService.createAdAccount$(payload).subscribe(
        (data) => {
          window.open(data?.directUrl, '_blank');
          this.modal.close({
            connectedAdAccounts: data
          });
        },
        () => this.modal.close()
      );
    }
  }
}
