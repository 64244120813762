import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'minCurrencyValue';

type Input = { code: TikTok.Business.TikTokCurrency };
type Output = QueryResult<typeof queryField, number>;

export const minimumCurrencyValueQuery = makeQuery<Output, Input>(`
query TikTokMinCurrencyValue($code: TikTokCurrency!){
  tiktok {
    ${queryField}(code:$code)
  }
}
`);
