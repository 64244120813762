import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.ITurnCampaignsRequest;
type Output = { turnOff: boolean };

export const turnOffGoogle = makeMutation<Output, Input>(`
  mutation GoogleTurnOff(
    $resourceNames: [String!]!
    $resourceType: GoogleIdType!
  ) {
    google {
      turnOff(
        resourceNames: $resourceNames
        resourceType: $resourceType
      )
    }
  }
`);
