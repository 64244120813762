<div
  class="ayn-select"
  [style.min-width.px]="minWidth"
  [class]="containerClassName + ' ' + overlayPositionClass"
  [ngClass]="{
      invalid: error?.value,
      show: overlay.render,
      'ayn-select__has-flipped-overlay': overlay.isOverlayFlipped,
      'ayn-select__has-value': !selectedValue,
      'ayn-select__open-search': showSearch,
      'ayn-select__disabled': _disabled,
  }"
  #select
>
  <div class="ayn-select--inner">
    <ng-container *ngIf="!overlay.render || !showSearch; else searchTemplate">
      <button class="ayn-select--btn" type="button" (click)="clickedBtn($event)">
        <ng-container *ngIf="!loading; else loadingTemp">
          <ng-container *ngIf="!dontSelect; else _placeholderTemplate">
            <ng-container *ngIf="!setValueHtml; else htmlTemplate">
              <ng-container *ngIf="!selectedValue && placeholderTemplate">
                <ng-template [ngTemplateOutlet]="placeholderTemplate"></ng-template>
              </ng-container>

              <ng-container *ngIf="!selectedItemTemplate">
                <ng-container *ngIf="updateValue; else _placeholderTemplate">
                  <ng-container *ngIf="selectedValue">{{ selectedValue }}</ng-container>
                  <ng-container *ngIf="!selectedValue">
                    <ng-container *ngTemplateOutlet="_placeholderTemplate"></ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container
                *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: selectedValue}"
              ></ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="error?.value; else elseTemplate">
            <ayn-icon
              name="information"
              [ayn-tooltip]="(error?.message | async) || requiredMessage"
              position="right"
              [show]="true"
              zIndex="99999"
            ></ayn-icon>
          </ng-container>

          <ng-template #elseTemplate>
            <ng-content select="ayn-icon"></ng-content>
          </ng-template>

          <ng-container *ngIf="showTick">
            <ayn-icon name="tick" className="ayn-select--tick"></ayn-icon>
          </ng-container>
        </ng-container>
      </button>

      <ng-template #htmlTemplate>
        <ng-container *ngIf="!selectedOptionHtml && placeholderTemplate">
          <ng-template [ngTemplateOutlet]="placeholderTemplate"></ng-template>
        </ng-container>

        <ng-container *ngIf="updateValue; else _placeholderTemplate">
          <div *ngIf="selectedOptionHtml; else _placeholderTemplate" [innerHTML]="selectedOptionHtml"></div>
        </ng-container>
      </ng-template>

      <ng-template #loadingTemp>
        <span class>Loading...</span>
        <div class="loader"></div>
      </ng-template>
    </ng-container>

    <ng-template #searchTemplate>
      <div
        class="ayn-select--search"
        #overlayTarget
        (click)="stopPropagation ? $event.stopPropagation() : null; !readonly ? overlay.show($event): null"
      >
        <input
          type="text"
          [formControl]="searchControl"
          [(ngModel)]="searchTerm"
          [placeholder]="searchPlaceholder | aynTranslate| async"
          autofocus
        />
        <ayn-icon name="search"></ayn-icon>
      </div>
    </ng-template>
  </div>

  <ayn-overlay #overlay [appendTo]="appendTo" [styleClass]="overlayContainerClassName" [baseZIndex]="overlayBaseZIndex">
    <div
      class="ayn-select--content"
      [class]="overlayClassName"
      [style.max-height.px]="overlayMaxHeight"
      [ngClass]="{'ayn-select__append-body': appendTo, 'ayn-select__has-scrollbar': select.scrollHeight > select.clientHeight}"
      [style.--width.px]="select?.clientWidth"
    >
      <ng-content select="[ayn-select-header]"></ng-content>
      <p class="ayn-select--content-label" *ngIf="contentLabel">{{ contentLabel }}</p>

      <ng-content select="ayn-option"></ng-content>

      <ng-container *ngIf="_options?.length">
        <ayn-option
          *ngFor="let option of _options | aynFilter : searchTerm"
          [value]="option.value"
          (selectOption)="click($event)"
          [stopPropagation]="stopPropagation"
        >
          {{ option.label }}
        </ayn-option>
      </ng-container>
      <ng-content select="[ayn-select-footer]"></ng-content>

      <ayn-loader-lottie *ngIf="loading"></ayn-loader-lottie>
    </div>
  </ayn-overlay>
</div>

<ng-template #_placeholderTemplate>
  <ng-template *ngIf="placeholderTemplate" [ngTemplateOutlet]="placeholderTemplate"></ng-template>

  <ng-container *ngIf="!placeholderTemplate"
    ><span class="ayn-select--placeholder">{{ placeholder }}</span></ng-container
  >
</ng-template>
