import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@core/models/base.model';
import { GetPlatformContexts } from '@core/state/platform-context.state';
import { Store } from '@ngxs/store';

import { AuthService, GqlFilterErrorService, PURE_REQUEST, SmartCampaign, SUSPEND_ERRORS } from '../';
import { isSentryEnabled } from '@core/utils/sentry.util';
import { get } from 'lodash';
import { combineSmartCampaignFailures } from '@core/utils/notify-smart-campaign-pipe';
import { captureSentryException } from '@core/error-handler/sentry-error.handler';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private store: Store,
    private filterErrorService: GqlFilterErrorService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPureRequest = req.context.get(PURE_REQUEST);
    if (isPureRequest) return next.handle(req);
    let resultPath = '';
    if (isSentryEnabled && req.body?.variables?.__aayn_result_path) {
      resultPath = req.body.variables.__aayn_result_path;
      delete req.body.variables.__aayn_result_path;
    }
    return next.handle(req).pipe(
      tap((result: any) => {
        const responseBody = result?.body as BaseResponse;

        if (responseBody?.errors) {
          throw responseBody;
        }
        this.checkFailuresForSentry(req, resultPath, result);
      }),
      catchError((error: HttpErrorResponse | BaseResponse) => {
        if (req?.body?.operationName) {
          const isFiltered = this.filterErrorService.getFilterError(req.body.operationName)(error);
          if (isFiltered) {
            throw error;
          }
        }

        if (error instanceof HttpErrorResponse) {
          this.handleHttpError(req, error);
        } else {
          this.handleBaseResponseError(req, error);
        }

        throw error;
      })
    );
  }

  private reconnectPlatformsForBingControl(error: BaseResponse<any>) {
    if (!error.errors) return;

    const hasRequireReconnectError = error.errors?.some((o) => o.extensions.code === '105');

    if (hasRequireReconnectError) {
      this.store.dispatch(new GetPlatformContexts());
    }
  }

  private retryLoginWithRefreshToken() {
    return this.authService.refreshToken().subscribe();
  }

  private handleHttpError(request: HttpRequest<any>, error: HttpErrorResponse) {
    error['__aayn_requestBody'] = request.body;
    if (error.status === 0) {
      this.toastrService.warning('Unable to connect to backend.', 'Network Error');
    }

    if (error.status === HttpStatusCode.Unauthorized && request.headers.has('Authorization')) {
      this.retryLoginWithRefreshToken();
    }

    if (error?.error?.errors?.length) {
      error.error.errors.forEach((err: unknown) => {
        if (typeof err == 'string') {
          this.toastrService.error(err, 'Error');
        }
      });
    }
  }

  private handleBaseResponseError(request: HttpRequest<any>, error: BaseResponse<any>) {
    if (!request.context.get(SUSPEND_ERRORS) && error?.errors) {
      error.errors.forEach((err) => {
        this.toastrService.error(err.message, 'Error');
      });

      // 105 = RequireReconnect for Bing
      this.reconnectPlatformsForBingControl(error);

      if (isSentryEnabled) {
        throw new BaseResponse({
          errors: error.errors,
          data: error.data,
          __aayn_requestBody: request.body
        });
      }
    }
  }

  checkFailuresForSentry(request: HttpRequest<any>, resultPath: string, response: any) {
    if (!isSentryEnabled) return;

    const operationName = request.body?.operationName;
    if (!response.body?.data || !operationName || !request?.body?.query) return;

    const operationResult = get(
      response.body.data,
      resultPath || request.body.operationName
    ) as SmartCampaign.Backend.SmartCampaignBaseResultDto;

    if (!operationResult?.failures || !combineSmartCampaignFailures(operationResult.failures).length) return;

    const smartCampaignFailureError = new Error();
    smartCampaignFailureError.name = `💥🧠 Smart Campaign Failure - ${request.body.operationName}`;

    captureSentryException(smartCampaignFailureError, {
      captureContext: {
        extra: {
          operationName,
          operationResult,
          body: request.body
        }
      }
    });
  }
}
