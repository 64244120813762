import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay, Tab } from '@ayn-ui/lib/modules';
import {
  BusinessService,
  OpenAI,
  OpenAiService,
  PlatformIcons,
  PlatformKeyByValues,
  Platforms,
  SmartCampaign
} from '@core/index';
import { SmartCampaignService } from '@core/services/smart-campaign.service';
import { LoaderState } from '@core/state/loader.state';
import { addHttpsPrefix, createOrderedStream, patchWithHttps } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import {
  ChildTabValidateFn,
  FormRegisterService,
  ISmartResponseCatcherDirectiveParams,
  SmartResponseCatcherDirective,
  TabValidateStatus
} from '@shared/directives';
import { TypewriteService } from '@shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BusinessState } from '@core/state/business.state';
import { TEXT_GENERATE_LANGUAGES } from '@shared/constants';

@UntilDestroy()
@Component({
  selector: 'aayn-detailed-targeting--smart-campaign',
  templateUrl: 'detailed-targeting.component.html',
  providers: [TypewriteService],
  host: {
    style: 'z-index: 999'
  }
})
export class DetailedTargetingSmartCampaignComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  @ViewChild(Overlay) overlay!: Overlay;

  @Select(LoaderState.getAny(['GetProductDescription']))
  getProductDescriptionLoader$?: Observable<boolean>;

  @Select(LoaderState.getAny('GetSmartInterest'))
  getInterestLoader$?: Observable<boolean>;

  @ViewChild(Tab) tab?: Tab;

  urlControl = new FormControl('', [Validators.required]);

  textGeneratorForm = new FormGroup({
    description: new FormControl('', [Validators.required]),
    targetAudience: new FormControl('', [Validators.required])
  });

  @ViewChild(SmartResponseCatcherDirective, { static: false })
  smartResponseCatcherDirective?: SmartResponseCatcherDirective;

  protected PlatformKeyByValues = PlatformKeyByValues;
  protected PlatformIcons = PlatformIcons;
  protected Platforms = Platforms;

  private _subscription?: Subscription;

  get smartCampaignResponseCatcherParams() {
    return Object.entries(this.adCreationModel.audience.detailedTargetings).reduce((acc, [key, value]) => {
      const platform = SmartCampaign.Client.getInterestPlatform(
        key as SmartCampaign.Backend.SmartCampaignInterestPlatforms
      );
      acc[platform] = value;
      return acc;
    }, {} as ISmartResponseCatcherDirectiveParams);
  }

  validateTiktokInterest: ChildTabValidateFn = () => {
    if (
      this.formRegisterService.isFormSubmitted(this.form) &&
      !this.adCreationModel.audience.detailedTargetings?.tiktok?.length
    ) {
      return TabValidateStatus.Invalid;
    }
    return undefined;
  };

  get selectedPlatforms() {
    return {
      facebook: this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Meta),
      google: this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Google),
      linkedIn: this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.LinkedIn),
      bing: this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Bing),
      tiktok: this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.TikTok)
    };
  }

  constructor(
    private smartCampaignService: SmartCampaignService,
    private smartCampaignStateService: SmartCampaignStateService,
    private cdr: ChangeDetectorRef,
    private openaiService: OpenAiService,
    private typewriteService: TypewriteService,
    private toastr: ToastrService,
    private formRegisterService: FormRegisterService,
    private store: Store,
    private businessService: BusinessService
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      this.fillTextGeneratorForm();
    });

    this.urlControl.valueChanges
      .pipe(
        filter((o: string | null) => (o || '').length > 0),
        debounceTime(350),
        distinctUntilChanged()
      )
      .subscribe((url) => {
        if (this.urlControl.invalid) return;

        patchWithHttps(this.urlControl, url!);
        this.patchPrecisionAudience({ url: this.urlControl.value! });
        this.adCreationModel.textAi.url = this.urlControl.value!;
      });

    this.formRegisterService
      .onSubmit$(this.form)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.smartResponseCatcherDirective?.processTabs());

    this.textGeneratorForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.patchPrecisionAudience({ description: value.description || '', targetAudience: value.targetAudience || '' });

      this.adCreationModel.textAi.productDescription = value.description || '';
      this.adCreationModel.textAi.targetAudience = value.targetAudience || '';

      this.smartCampaignStateService.saveDraft$();
    });
  }

  patchPrecisionAudience(value: Partial<SmartCampaign.Client.PrecisionAudience>) {
    this.adCreationModel.audience.precisionAudience = Object.assign(
      {},
      this.adCreationModel.audience.precisionAudience,
      value
    );
  }

  scan(properties: Array<'description' | 'targetAudience'> = ['description', 'targetAudience']) {
    const url = this.urlControl.value;
    if (url) {
      const parsed = addHttpsPrefix(url);
      this._subscription = this.openaiService.getProductDescription({ url: parsed }).subscribe((res) => {
        createOrderedStream(properties.map((property) => this.typeWrite(property, res[property]))).subscribe({
          next: () => {
            const value = properties.reduce((acc, property) => ({ ...acc, [property]: res[property] }), {});

            this.adCreationModel.audience.precisionAudience = {
              ...this.adCreationModel.audience.precisionAudience,
              ...value
            };
            this.adCreationModel.textAi.productName = res.name;
            this.adCreationModel.textAi.language = TEXT_GENERATE_LANGUAGES.find(
              (lang) => lang.label === res.language
            )?.value;
            this.adCreationModel.textAi.tone = OpenAI.TextTone.Professional;
            this.smartCampaignStateService.saveDraft$();

            this.textGeneratorForm.patchValue(value);
            this.textGeneratorForm.markAsTouched();

            this.cdr.detectChanges();
          }
        });
      });
    }
  }

  cancelGetProductDescriptionCall() {
    this._subscription?.unsubscribe();
  }

  getInterests() {
    if (this.textGeneratorForm.invalid) return;

    const location =
      this.adCreationModel.audience.geoLocations.inclusions?.[this.adCreationModel.selectedPlatforms.at(0) || 0]?.at(0);

    const locationName = location?.platformSpecificName || location?.name;

    if (!locationName) {
      this.toastr.warning('Please choose location for ad.');

      return;
    }

    const { description, targetAudience } = this.textGeneratorForm.value;

    this.smartCampaignService
      .getInterest$(this.adCreationModel.selectedPlatforms, {
        description: description!,
        targetAudience: targetAudience!,
        location: locationName
      })
      .subscribe((o) => {
        this.setDetailedTargetings(o.data!.results);

        this.saveForm();

        this.cdr.detectChanges();

        this.tab?.selectTabWithIndex(0);

        this.smartResponseCatcherDirective?.processTabs();

        this.cdr.detectChanges();
      });
  }

  private saveForm() {
    this.form?.patchValue({ detailedTargetings: this.adCreationModel.audience.detailedTargetings });
  }

  typeWrite(field: keyof typeof this.textGeneratorForm.controls, value: any) {
    return this.typewriteService.typeWrite$(value, (val) => this.textGeneratorForm.patchValue({ [field]: val }), 1);
  }

  setDetailedTargetings(response: SmartCampaign.Backend.SmartCampaignInterestResults) {
    const [interestsToTake, rest] = SmartCampaign.Client.divideInterestResults(response);
    this.adCreationModel.audience.detailedTargetings = interestsToTake;
    this.adCreationModel.audience.detailedTargetingResults = rest;
  }

  fillTextGeneratorForm() {
    if (!this.adCreationModel.audience.precisionAudience.url) {
      const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
      if (selectedBusiness) {
        this.businessService.getBusinessAndBrand(selectedBusiness.id).subscribe((business) => {
          this.adCreationModel.audience.precisionAudience.url = business.url;
          this.urlControl.patchValue(business.url);

          if (business.description) {
            this.textGeneratorForm?.patchValue({
              description: business.description
            });
            this.adCreationModel.audience.precisionAudience.description = business.description;
            this.scan(['targetAudience']);
          } else {
            this.scan();
          }
        });
      }
    } else {
      this.textGeneratorForm.patchValue({
        description: this.adCreationModel.audience.precisionAudience.description,
        targetAudience: this.adCreationModel.audience.precisionAudience.targetAudience
      });
      this.urlControl.patchValue(this.adCreationModel.audience.precisionAudience.url);
    }
  }
}
