import { Component, OnInit } from '@angular/core';
import { Linkedin } from '@core/models';
import { LinkedinStateService } from '@pages/create-ad/state';

export const AUDIENCE_LIST__LINKEDIN: Linkedin.UI.SelectAudienceItem[] = [
  {
    id: 1,
    title: 'Manual Audience Setup',
    description: 'Manually setup your own target audience with interests and demographics.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: Linkedin.AudienceType.Manual
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: Linkedin.AudienceType.AiAudienceFinder
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: Linkedin.AudienceType.Retargeting
  }
];

@Component({
  selector: 'aayn-select-audience--linkedin',
  templateUrl: './select-audience-content.component.html'
})
export class SelectAudienceContentLinkedinComponent implements OnInit {
  protected audiences = AUDIENCE_LIST__LINKEDIN;

  protected selectedAudience: Linkedin.AudienceType = Linkedin.AudienceType.Manual;

  protected AudienceTypes = Linkedin.AudienceType;

  constructor(private linkedinStateService: LinkedinStateService) {}

  ngOnInit() {}

  audienceChangeStatus(item: Linkedin.UI.SelectAudienceItem) {
    this.audiences.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAudience = item.type!;
      } else {
        x.active = false;
      }
    });

    this.linkedinStateService.adCreationModel.selectedTypes.audience = item.type!;
  }
}
