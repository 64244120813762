<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">Welcome to ADYOUNEED</section>
  <section class="aayn-video-modal--description">
    <p>
      ADYOUNEED is Your one-stop ad creation solution. Craft effective ads effortlessly across multiple platforms in
      just seconds. No marketing expertise required! Boost your results and focus on what truly matters: your business.
    </p>
    <p class="aayn-video-modal--hit-line">If you would like us to show you around quickly, use the button below,</p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary" (click)="showArround(); videoModal.closeModal()">Show Me Arround</button>
    <a class="aayn-video-modal--skip" (click)="videoModal.closeModal()">Skip</a>
  </section>
</aayn-video-modal>
