import {Injectable} from '@angular/core';
import {IPlatformContext, Platforms} from '@core/models';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import {PlatformContextState} from './platform-context.state';

/**
 * Models
 */
export namespace DashboardNS {
  export interface State {
    getPlatform: Platforms;
  }
}

/**
 * Actions
 */
export class SetPlatform {
  static readonly type = '[setPlatform] set Platform';
  static readonly desc = 'setting platform';
  constructor(public payload: Platforms) {}
}

/**
 * Defaults
 */
export const DASHBOARD_DEFAULTS: DashboardNS.State = {
  getPlatform: Platforms.Meta as Platforms
};

@State<DashboardNS.State>({
  name: 'DashboardState',
  defaults: DASHBOARD_DEFAULTS
})
@Injectable()
export class DashboardState {
  constructor() {}

  @Selector([PlatformContextState.ConnectedPlatformContexts])
  static getPlatform({ getPlatform }: DashboardNS.State, connectedPlatformContexts: IPlatformContext[]): Platforms {
    let platform = getPlatform;

    return platform || getPlatform;
  }

  @Action(SetPlatform)
  setPlatform({ patchState }: StateContext<DashboardNS.State>, { payload }: SetPlatform) {
    patchState({ getPlatform: payload });
  }
}
