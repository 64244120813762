import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LS_LANG_KEY, SUPPORTED_LANGUAGES, SupportedLanguages } from '../';

@Injectable()
export class I18NClientService {
  selectedLanguage: SupportedLanguages = (this._getDefaultLanguage() as SupportedLanguages) || SUPPORTED_LANGUAGES[0];

  get activeLanguage() {
    return this.translateService.currentLang as SupportedLanguages;
  }

  constructor(private translateService: TranslateService) {
    translateService.addLangs(SUPPORTED_LANGUAGES as unknown as string[]);
    translateService.setDefaultLang(this.selectedLanguage);
  }

  changeLanguage(lang: SupportedLanguages = this._getDefaultLanguage() as SupportedLanguages) {
    this.translateService.use(lang);

    this._saveLanguage(lang);

    return lang;
  }

  private _saveLanguage(lang: SupportedLanguages) {
    localStorage.setItem(LS_LANG_KEY, lang);
  }

  private _getDefaultLanguage() {
    const lsLang = localStorage.getItem(LS_LANG_KEY);

    if (lsLang) return lsLang;

    const browserLang = 'en'; // TODO: Localizasyonda eksikler olduğu için şimdilik kapandı. getBrowserLanguage();

    const existingLang = SUPPORTED_LANGUAGES.some((o) => o === browserLang);

    if (!existingLang) return 'en';

    return browserLang;
  }
}
