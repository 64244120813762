import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

Injectable();
export class LinkedinAdPreviewService {
  dynamicImage: Partial<{
    title: string;
    description: string;
    callToAction: string;
    websiteUrl: string;
    images: string[];
  }> = {
    title: '',
    description: '',
    callToAction: '',
    websiteUrl: '',
    images: []
  };
  carouselAds: Partial<{
    cards: Array<{
      image: string[];
      body: string;
    }>;
    title: string;
  }> & {
    activeIndex$: BehaviorSubject<number>;
  } = {
    cards: [],
    title: '',
    activeIndex$: new BehaviorSubject<number>(0)
  };

  cdr?: ChangeDetectorRef;
}
