import {
  accountImages,
  audienceSearch,
  conversionGoal,
  createDisplayCampaign,
  createPMCampaign,
  createSearchCampaign,
  interestsSearch,
  languageSearch,
  locationSearch,
  minimumBudgetAmount,
  yourDataSearch,
  youtubeSearch,
  youtubeUrlValidation
} from './create-ad';
import {
  accountOverviewTotalGoogle,
  accountOverviewWithMetricsGoogle,
  adGroupsWithMetrics,
  assetGroups,
  breakdownsGoogle,
  campaignRemoveQuery,
  campaignsGoogle,
  campaignsWithMetricsGoogle,
  editAdGroupGoogle,
  editCampaignBudgets,
  editCampaignGoogle,
  funnelDashboardGoogle,
  getGoogleAdGroupAdsWithMetrics,
  turnOffGoogle,
  turnOnCampaignsGoogle
} from './dashboard';
import {
  adAccountsGoogle,
  connectAdAccountGoogle,
  getAdAccountCreationLink,
  isCreationPossibleGoogle,
  oauthGoogle
} from './business';

export default {
  adAccountsGoogle,
  connectAdAccountGoogle,
  isCreationPossibleGoogle,
  oauthGoogle,
  getAdAccountCreationLink,
  adCreation: {
    audienceSearch,
    interestsSearch,
    languageSearch,
    locationSearch,
    yourDataSearch,
    youtubeSearch,
    youtubeUrlValidation,
    createPMCampaign,
    conversionGoal,
    minimumBudgetAmount,
    createDisplayCampaign,
    accountImages,
    createSearchCampaign
  },
  dashboard: {
    turnOnCampaignsGoogle,
    turnOffCampaignsGoogle: turnOffGoogle,
    accountOverviewTotalGoogle,
    accountOverviewWithMetricsGoogle,
    campaignsWithMetricsGoogle,
    campaignsGoogle,
    funnelDashboardGoogle,
    breakdownsGoogle,
    adGroupsWithMetrics,
    assetGroups,
    getGoogleAdGroupAdsWithMetrics,
    editCampaignBudgets,
    editCampaignGoogle,
    editAdGroupGoogle,
    campaignRemoveQuery
  }
};
