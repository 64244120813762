import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Bing } from '@core/models';

@Component({
  selector: 'aayn-gender-selection--bing',
  template: `
    <section class="aayn-audience--gender-selection">
      <button
        ayn-button="primary"
        type="button"
        icon="male"
        [class.active]="!isSelectedAllGenders && isSelectedMale"
        (click)="selectGender(Gender.Men)"
      >
        Male
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="female"
        [class.active]="!isSelectedAllGenders && isSelectedFemale"
        (click)="selectGender(Gender.Women)"
      >
        Female
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="all-genders"
        [class.active]="isSelectedAllGenders"
        (click)="selectAllGender()"
      >
        {{ 'All Genders' | translate }}
      </button>
    </section>
  `
})
export class GenderSelectionBingComponent implements OnInit {
  @Input() form?: FormGroup;

  protected Gender = Bing.Gender;

  constructor() {}

  ngOnInit() {}

  protected get isSelectedMale() {
    return this.form?.value?.male;
  }

  protected get isSelectedFemale() {
    return this.form?.value?.female;
  }

  protected get isSelectedAllGenders() {
    return this.form?.value.female && this.form.value.male;
  }

  protected selectGender(gender: Bing.Gender) {
    if (gender == Bing.Gender.Men) {
      this.form!.patchValue({ male: true, female: false });
    }
    if (gender == Bing.Gender.Women) {
      this.form!.patchValue({ male: false, female: true });
    }
  }

  protected selectAllGender() {
    this.form?.patchValue({
      male: true,
      female: true
    });
  }
}
