import { Component, Input, OnInit } from '@angular/core';

export type DetailedTargetingAddedItemType = 'inclusions' | 'exclusions' | 'narrows';

@Component({
  selector: 'aayn-detailed-targeting-added-item',
  templateUrl: 'detailed-targeting-added-item.component.html'
})
export class DetailedTargetingAddedItemComponent implements OnInit {
  @Input() addedItemType?: DetailedTargetingAddedItemType;

  constructor() {}

  ngOnInit() {}
}
