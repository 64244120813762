<section class="aayn-user-menu">
  <button
    class="aayn-user-menu--btn"
    *ngIf="(user$ | async)?.user as user"
    (click)="overlay.toggle($event)"
    [ngClass]="{ active: overlay.render }"
  >
    <span class="aayn-user-menu--btn_img-placeholder">{{ user.name.charAt(0) + user.surname.charAt(0) }}</span>

    <div class="aayn-user-menu--btn_info">
      <p class="aayn-user-menu--btn_info__name">{{ user.name }} {{ user.surname }}</p>
      <p class="aayn-user-menu--btn_info__role">
        {{ role | translate }}
      </p>
    </div>

    <ayn-icon name="tick"></ayn-icon>
  </button>
  <ayn-overlay #overlay styleClass="aayn-user-menu--body" [useOverlayBlur]="true">
    <ul>
      <li *ngIf="!(user$ | async)?.user?.inviter?.inviterId">
        <a class="aayn-user-menu--list-item" routerLink="/main/settings" (click)="overlay.hide()">
          {{ 'Settings' | translate }}
          <ayn-icon name="settings"></ayn-icon>
        </a>
      </li>

      <li>
        <a class="aayn-user-menu--list-item" href="https://intercom.help/adyouneedv3/en/" target="_blank">
          {{ 'Help Center' | translate }}
          <ayn-icon name="help"></ayn-icon>
        </a>
      </li>

      <li class="aayn-user-menu--upgrade-btn" *ngIf="false">
        <a class="aayn-user-menu--list-item" routerLink="/main/upgrade" (click)="overlay.hide()">
          {{ 'Upgrade' | translate }}
          <ayn-icon name="upgrade"></ayn-icon>
        </a>
      </li>

      <li *ngIf="false">
        <a class="aayn-user-menu--list-item" (click)="openRoadmap()">
          {{ 'Roadmap' | translate }}
          <ayn-icon name="roadmap"></ayn-icon>
        </a>
      </li>

      <li>
        <button class="aayn-user-menu--list-item" (click)="logout()">
          {{ 'Logout' | translate }}
          <ayn-icon name="logout"></ayn-icon>
        </button>
      </li>
    </ul>

    <ul class="aayn-user-menu--footer-list">
      <li class="p:relative">
        <a
          href="javascript:void(0)"
          class="aayn-user-menu--list-item aayn-user-menu--list-item_secure"
          (click)="secureOverlay.toggle($event)"
        >
          {{ 'Secure' | translate }}
          <ayn-icon name="shield"></ayn-icon>
        </a>

        <ayn-overlay #secureOverlay styleClass="popup aayn-user-menu--body--secure-popup">
          <!-- TOOD: Cihan'a sorulacak..  -->
          <div class="popup--inner">
            <div class="aayn-user-menu--body--secure-popup__header">
              <ayn-icon name="secure" class="aayn-user-menu--body--secure-popup__icon"></ayn-icon>
              <h4 class="aayn-user-menu--body--secure-popup__title">{{ 'Secure' | translate }}</h4>
            </div>

            <p class="aayn-user-menu--body--secure-popup__description">
              {{
                'ADYOUNEED cares about your privacy and security, which is why all your data is encrypted. Neither the ADYOUNEED team or any third party has access to your data with out authorized consent from you.'
                  | translate
              }}
            </p>
          </div>
        </ayn-overlay>
      </li>
    </ul>
  </ayn-overlay>
</section>

<aayn-roadmap></aayn-roadmap>
