<aayn-upload-image-modal
  header="Select Images"
  icon="folder"
  description="Upload your images."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
>
  <aayn-upload-image-modal--header
    [headerLeft]="'Add up to ' + images.length + ' images'"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid"
      (fileAdded)="uploadImage($event)"
      (remove)="remove($event)"
      [selectedFileUrl]="uploadedImages[index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index)"
      [progressItem]="file"
      [aspectRatio]="null"
      [maintainAspectRatio]="false"
      [maxTotalPixels]="36152320"
      [isDynamicRatio]="true"
    ></aayn-file-upload-item>
  </section>
</aayn-upload-image-modal>
