/**
 * Search an array of objects
 * @param values The array of objects to search
 * @param term The term to search
 */
export function arraySearch<T>(values: T[], term = '') {
  return values.filter((o) =>
    JSON.stringify(Object.values(o as any))
      .toLocaleLowerCase()
      .includes(term.toLocaleLowerCase())
  );
}

/**
 * Divide array from given index
 * @param array The array to divide
 * @param index The index to divide the array
 */
export function divideArray<T>(array: T[], index: number): Array<Array<T>> {
  return [array.slice(0, index), array.slice(index)];
}
