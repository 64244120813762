import { makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';
type Input = { input: Linkedin.LinkedInFindPricingInsightModel };
type Output = Linkedin.LinkedInAdBudgetPricingResponse;

export const getSuggestedBudget = makeQuery<Output, Input>(`
query GetBidding($input: LinkedInFindPricingInsightModel!){
  linkedin{
    getSuggestedBudget(input: $input){
      edges{
        node
        {
          bidLimits {
            min {
              amount currencyCode
            }
            max { amount currencyCode }
          }
          suggestedBid {
            min { amount currencyCode }
            max { amount currencyCode }
            default { amount currencyCode }
          }
          dailyBudgetLimits {
            min { amount currencyCode }
            max { amount currencyCode }
            default { amount currencyCode }
          }
        }
      }
    }
  }
}`);
