import { IPackage } from '@core/models';

import { makeQuery } from '../types';

type Output = IPackage[];

export const allPackages = makeQuery<Output>(`
  query GetPackages {
    packages {
      id
      name
      displayName
      currencyCode
      degree
      visibility
      period
      periodUnit
      pricingModel
      price
      status
      group
      featuresJson
      adSpendLimit
      externalId
    }
  }
`);
