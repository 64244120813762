import { Google } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Google.Dashboard.IGetGoogleAdGroupAdsWithMetricsRequest;
type Output = Google.Dashboard.IGetGoogleAdGroupAdsWithMetricsResponse;

export const getGoogleAdGroupAdsWithMetrics = makeQuery<Output, Input>(`
query GetGoogleAdGroupAdsWithMetrics(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $adGroupId: Float
  $searchKey: String
  $startDate: SafeInt
  $endDate: SafeInt
  $status: GoogleInsightStatus
  $datePreset: DatePreset
) {
  google {
    adGroupAdsWithMetrics(
      first: $first
      after: $after
      last: $last
      before: $before
      adGroupId: $adGroupId
      searchKey: $searchKey
      startDate: $startDate
      endDate: $endDate
      status: $status
      datePreset: $datePreset
    ) {
      edges {
        cursor
        node {
          name
          id
          status
          type
          finalUrls
          displayAd {
            businessName
            descriptions
            headlines
            logoImages {
              url
            }
            longHeadline
            mainColor
            marketImages {
              url
            }
            pricePrefix
            promoText
            squareLogoImages {
              url
            }
            squareMarketingImages {
              url
            }
            videos {
              url
            }
          }
          videoAd {
            breadcrumb1
            breadcrumb2
            callToActions
            companionBanners
            descriptions
            headlines
            longHeadlines
            videos {
              url
              resourceName
            }
          }
          searchAd {
            descriptions
            headlines
            path1
            path2
          }
          metrics {
            impressions
            clicks
            costPerClick
            cost
            conversions
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
`);
