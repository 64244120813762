import { Component, OnInit } from '@angular/core';
import { IPlatformContext, WindowService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aayn-create-account--linkedin',
  templateUrl: 'create-account.component.html'
})
export class CreateAccountLinkedinComponent implements OnInit {
  protected platformContext?: IPlatformContext;

  protected accountCreationLink?: string;

  @Select(LoaderState.getAny(['GetAdAccountCreationLink'])) loader$?: Observable<boolean>;

  constructor(public modal: NgbActiveModal, private windowService: WindowService) {}

  ngOnInit() {
    // TODO:LINKEDIN: REQUEST TO GET AD ACCOUNTS
  }
}
