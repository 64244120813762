import { Component } from '@angular/core';
import { Bing } from '@core/models';
import { BingStateService } from '@pages/create-ad/state';
import { ICreateAdSelectItem } from '@pages/create-ad/models/ad-create.model';
import { mapObjectives } from '@pages/create-ad/components/select-objective/utils';
import { selectedItem } from '@pages/create-ad/components/select-objective/utils/selected-item';
import { Store } from '@ngxs/store';
import { SetAdSteps } from '@pages/create-ad/state/create-ad-steps.state';
import { BingImportFromGoogleSteps, TypedAdSteps } from '@pages/create-ad/state/constants/steps';

export const OBJECTIVE_LIST__BING: ICreateAdSelectItem<Bing.CampaignObjective>[] = [
  {
    id: 1,
    title: 'Traffic to my website',
    description: 'For reveiving as many visitors as possible to your website.',
    icon: 'traffic',
    type: Bing.CampaignObjective.TrafficToMyWebsite,
    empty: false,
    active: true
  },
  {
    id: 2,
    title: 'Conversions on my website',
    description: 'To receive as many conversions on your website as possible.',
    icon: 'conversions',
    empty: false,
    active: false,
    type: Bing.CampaignObjective.ConversionsOnMyWebsite
  },
  {
    id: 3,
    title: 'Import from Google',
    description: 'Import your existing Google Campaigns to your Bing Ad Account.',
    icon: 'import-from-google',
    empty: false,
    active: false,
    type: Bing.CampaignObjective.ImportFromGoogle
  }
];

@Component({
  selector: 'aayn-select-objective--bing',
  templateUrl: 'select-objective-content.component.html'
})
export class SelectObjectiveBingComponent {
  ObjectiveTypes = Bing.CampaignObjective;

  objectives$ = this.bingStateService.adCreationModel$.pipe(
    mapObjectives<Bing.CampaignObjective, undefined>(OBJECTIVE_LIST__BING),
    selectedItem((objective) => {
      this.selectedObjective = objective.type!;
    })
  );

  selectedObjective?: Bing.CampaignObjective;

  constructor(private bingStateService: BingStateService, private store: Store) {}

  objectiveChangeStatus(objective: ICreateAdSelectItem<Bing.CampaignObjective>) {
    if (objective.type !== this.bingStateService.adCreationModel?.selectedTypes.campaign) {
      this.bingStateService.setAdModel({
        ...this.bingStateService.adCreationModel,
        selectedTypes: {
          ...this.bingStateService.adCreationModel.selectedTypes,
          campaign: objective.type!
        }
      });
      this.bingStateService.saveDraft$();
      if (objective.type === Bing.CampaignObjective.ImportFromGoogle) {
        this.store.dispatch(new SetAdSteps(BingImportFromGoogleSteps));
      } else {
        this.store.dispatch(new SetAdSteps(TypedAdSteps));
      }
    }
  }
}
