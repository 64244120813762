import { Injectable } from '@angular/core';
import * as Query from '@core/queries';
import { Store } from '@ngxs/store';

import { GraphQLService, Platforms } from '../';

@Injectable({ providedIn: 'root' })
export class PlatformContextService {
  constructor(private gqlService: GraphQLService, private store: Store) {}

  get$() {
    return this.gqlService.get(Query.allContexts());
  }

  disconnectPlatform$(contextId: string, platform: Platforms) {
    return this.gqlService.post(Query.disconnectPlatform({ contextId, platform }));
  }

  createUnownedPlatformContexts$() {
    return this.gqlService.post(Query.createUnownedPlatformContexts());
  }
}
