import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'makeShorten';
type Output = QueryResult<typeof queryField, string>;

export const makeShortenQuery = makeQuery<Output, OpenAI.TextCraftCommonInput>(`
query MakeShorten($content: String!, $productName: String!, $productDescription: String!, $textLimit: Float!){
  openAI {
    ${queryField}(content: $content, productName: $productName, productDescription: $productDescription, textLimit:$textLimit)
  }
}
`);
