import { environment } from '@environment';
import { ReplaySubject } from 'rxjs';

import { Injectable, isDevMode } from '@angular/core';

import { LazyAssetService } from './lazy-asset.service';

const CHARGEBEE_URL = 'https://js.chargebee.com/v2/chargebee.js';

declare var Chargebee;

@Injectable({
  providedIn: 'root'
})
export class ChargebeeClientService {
  private _chargebeeInstance?: ChargebeeInstance;

  chargeBeeLoader$ = new ReplaySubject<boolean>();

  get chargebeeInstance() {
    return this._chargebeeInstance;
  }

  constructor(private lazyAssetService: LazyAssetService) {}

  init() {
    if (this._chargebeeInstance) return;
    this.chargeBeeLoader$.next(true);
    this.lazyAssetService.loadScript(CHARGEBEE_URL).subscribe(
      () => {
        if (isDevMode()) {
          console.log('ChargebeeClientService.constructor: Chargebee is loaded');
        }

        this._chargebeeInstance = Chargebee.init({
          site: environment.chargebeeSite,
          publishableKey: environment.chargebeePublisableKey,
          domain: environment.applicationUrl
        });

        this.chargeBeeLoader$.next(false);
        if (isDevMode()) {
          console.log('ChargebeeClientService.constructor: Chargebee has initialized.', this._chargebeeInstance);
        }
      },
      (error) => {
        this.chargeBeeLoader$.error(error);
        console.error('ChargebeeClientService.Error:.', error);
      }
    );
  }
}

export interface IChargebeeAdditionalData {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  stateCode: string;
  zip: string;
  countryCode: string;
}
