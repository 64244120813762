import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, GraphQLService } from '@core/backend';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { pipe } from 'fp-ts/lib/function';
import { deleteAt } from 'fp-ts/Record';
import { AdCreative } from '@core/models/platforms/adcreative';

export type CheckProgressResponse = {
  $id: string;
  $values: AdCreative.Backend.ImageGenerationProgressResponse[];
};
@Injectable({
  providedIn: 'root'
})
export class AdcService extends BaseHttpService {
  images = new Map<string, Blob>();

  constructor(private gqlService: GraphQLService, private http: HttpClient) {
    super(http, '/adcreative');
  }

  createImageGenerationTask(params: AdCreative.Backend.CreateImageGenerationTask) {
    const form = new FormData();
    form.append('brandImage', params.brandImage);
    form.append('backgroundImage', params.backgroundImage);
    form.append('actionText', params.actionText);
    form.append('color1Hex', '#' + params.color1Hex);
    form.append('color2Hex', '#' + params.color2Hex);
    form.append('mainHeadline', params.mainHeadline);
    form.append('punchline', params.punchline);
    form.append('description', params.description);
    form.append('buttonIconClass', params.buttonIconClass || 'fa-solid fa-angle-right');
    form.append('templates', JSON.stringify(params.templates));
    if (params.fontIdentifier) {
      form.append('fontIdentifier', params.fontIdentifier);
    }

    return this.http
      .post<AdCreative.Backend.ImageGenerationProgressResponse>(`${this.apiUrl}/image`, form)
      .pipe(
        map(
          (response) =>
            ({ ...response, tasks: pipe(response.tasks, deleteAt('$id')) } as AdCreative.Client.ImageGenerationProgress)
        )
      );
  }

  getProgress(imageRenderProcessId: string) {
    return this.http.get<CheckProgressResponse>(`${this.apiUrl}/image/progress`, { params: { imageRenderProcessId } });
  }

  downloadImage(taskId: string, imageRenderProcessId: string): Observable<Blob> {
    //TODO: Cache request for same imageId. If request is processing, return the same observable.
    if (this.images.has(taskId)) {
      return of(this.images.get(taskId)!);
    }

    return this.http.get(`${this.apiUrl}/image`, { params: { taskId, imageRenderProcessId }, responseType: 'blob' });
  }

  /**
   * Calculate the nearest integer dimensions based on the aspect ratio. Returns not float dimensions.
   * @param width
   * @param height
   * @param aspectRatio
   */
  calculateNearestIntegerDimensions(
    width: number,
    height: number,
    aspectRatio: number
  ): { width: number; height: number } {
    const ratio = width / height;
    if (ratio > aspectRatio) {
      return { width: Math.floor(height * aspectRatio), height };
    }
    return { width, height: Math.floor(width / aspectRatio) };
  }
}
