<div
  class="accordion-input-wrapper--container"
  (click)="toggle($event)"
  [class.accordion-input-wrapper--container__active]="!collapsed"
>
  <div class="accordion-input-wrapper--header">
    <div class="accordion-input-wrapper--header__collapse">
      <ayn-icon [name]="collapsed ? 'chevron-right' : 'chevron-down'"></ayn-icon>
    </div>
    <div class="accordion-input-wrapper--header--title">
      <ng-content select="[accordion-title]"></ng-content>
    </div>
    <div class="accordion-input-wrapper--header--badge">
      <ng-content select="[accordion-badge]"></ng-content>
    </div>
  </div>
  <div #contentTemp class="accordion-input-wrapper--content">
    <ng-content select="[accordion-content]"></ng-content>
  </div>
</div>
