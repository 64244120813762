<div
  class="aayn-create-ad--header"
  *ngIf="{
    selectedPlatform: selectedPlatform$ | async
  } as data"
>
  <div *ngIf="data.selectedPlatform" class="aayn-create-ad--header--platform" [ngSwitch]="data.selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta" class="aayn-create-ad--header--platform__item">
      <ayn-icon name="meta"></ayn-icon>
      Meta Ads
    </div>

    <div *ngSwitchCase="Platforms.Google" class="aayn-create-ad--header--platform__item">
      <ayn-icon name="google"></ayn-icon>
      Google Ads
    </div>
    <div *ngSwitchCase="Platforms.Bing" class="aayn-create-ad--header--platform__item">
      <ayn-icon name="bing"></ayn-icon>
      Bing Ads
    </div>
    <div *ngSwitchCase="Platforms.LinkedIn" class="aayn-create-ad--header--platform__item">
      <ayn-icon name="linkedin"></ayn-icon>
      Linkedin Ads
    </div>
    <div *ngSwitchCase="Platforms.SmartCampaign" class="aayn-create-ad--header--platform__item">
      <ng-container *ngIf="adCreationModel$ && adCreationModel$ | async as adCreationModel">
        <div *ngIf="adCreationModel?.selectedPlatforms?.length" class="aayn-create-ad--header--platform__smart">
          <ayn-icon
            *ngFor="let item of adCreationModel?.selectedPlatforms || []"
            [name]="PlatformIcons[item]"
          ></ayn-icon>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="Platforms.InstantAd" class="aayn-create-ad--header--platform__item">
      <div *ngIf="instantAdPlatform" class="aayn-create-ad--header--platform__smart fw-bold">
        <ayn-icon [name]="PlatformIcons[instantAdPlatform]"></ayn-icon>
        <span>{{ 'Instant AI - Driven Campaigns' | translate }}</span>
      </div>
    </div>
  </div>
</div>
