import { Component, OnInit } from '@angular/core';
import { Base, Google, GoogleService } from '@core/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BingStateService } from '@pages/create-ad/state';

@Component({
  selector: 'ayn-google-campaign-import-modal',
  templateUrl: './google-campaign-import-modal.component.html'
})
export class GoogleCampaignImportModalComponent implements OnInit {
  pages: Google.IGoogleCampaignTable[] = [];
  query = '';

  protected adCreationModel = this.bingStateService.adCreationModel;

  constructor(
    public modal: NgbActiveModal,
    private googleService: GoogleService,
    private bingStateService: BingStateService
  ) {}

  ngOnInit(): void {
    this.googleService
      .getCampaigns$({
        datePreset: Base.Dashboard.Global.InsightDatePreset.ThisYear
      } as Google.Dashboard.ICampaignRequest)
      .subscribe((data) => {
        data?.campaigns.edges
          .filter((x) => x.node.status === Google.Dashboard.ICampaignStatus.ENABLED)
          .map((x) => {
            this.pages.push({
              id: x.node.id,
              status: x.node.status,
              type: x.node.type,
              name: x.node.name,
              checked: this.adCreationModel.googleImport.selectedCampaigns?.some((k) => k.campaignId === x.node.id)
            });
          });
      });
  }

  selectedCampaign() {
    this.modal.close(
      this.pages
        .filter((x) => x.checked)
        ?.map((x) => {
          return { campaignId: x.id, campaignName: x.name };
        })
    );
  }

  checkDisabled(): boolean {
    return !this.pages.some((x) => x.checked);
  }
}
