import { makeMutation } from '@core/queries';
import { TikTok } from '@core/models';

type Output = TikTok.Business.TikTokAdAccountResponse;

export const getAdAccountsQuery = makeMutation<Output, void>(`
query AdAccounts {
  tiktok {
    adAccounts {
      name
      adAccountId
      status
      accessRole
      accountType
      ownerBusinessCenterId
    }
  }
}
`);
