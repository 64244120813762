import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { IconModule } from '../icon';
import { AynUITranslateModule } from '../../translate/translate.module';
import { ButtonModule } from '../button';

export type AlertType = 'default' | 'primary' | 'orange' | '' | string;

@Component({
  selector: 'ayn-alert',
  templateUrl: './alert.component.html'
})
export class Alert {
  @Input() type: AlertType = 'default';

  @Input() icon?: string;

  @Input() title?: string;

  @Input() description?: string;

  @Input() buttonText?: string;

  @Input() buttonIcon?: string;

  @Input() closeButton: boolean = false;

  @Output() notificationButton: EventEmitter<Event> = new EventEmitter<Event>();

  public showAlertBox: boolean = true;

  closeAlert() {
    this.showAlertBox = !this.showAlertBox;
  }
}

@NgModule({
  imports: [CommonModule, IconModule, AynUITranslateModule, ButtonModule],
  exports: [Alert],
  declarations: [Alert],
  providers: []
})
export class AlertModule {}
