export interface IRoute {
  name: string;
  url?: string;
  icon: string;

  action?: string;
  disabled?: boolean;

  badgeIcon?: 'beta-feature' | 'new-feature';
  needAdAccount?: boolean;
  needAdminAccount?: boolean;
}

export enum MenuName {
  BUSINESS = 'Businesses',
  CLICK_FRAUD_PROTECTION = 'Clickfraud Protection',
  CREATE_AD = 'Create Ad',
  DASHBOARD = 'Dashboard',
  HELP_CENTER = 'Help Center',
  LOGOUT = 'Logout',
  SETTINGS = 'Settings',
  SOCIAL_ACCOUNTS = 'Accounts',
  UPGRADE = 'Upgrade'
}

export const ROUTE_DEFINITIONS: Record<keyof typeof MenuName, IRoute> = {
  BUSINESS: {
    name: MenuName.BUSINESS,
    icon: 'business',
    url: '/main/business'
  },
  CLICK_FRAUD_PROTECTION: {
    name: MenuName.CLICK_FRAUD_PROTECTION,
    icon: 'guard',
    url: '/main/clickfraud-protection',
    needAdAccount: true,
    needAdminAccount: true
  },
  CREATE_AD: {
    name: MenuName.CREATE_AD,
    icon: 'create-ad',
    url: '/main/create-ad',
    needAdAccount: true
  },
  DASHBOARD: {
    name: MenuName.DASHBOARD,
    icon: 'dashboard',
    url: '/main/dashboard',
    needAdAccount: true
  },
  HELP_CENTER: {
    name: MenuName.HELP_CENTER,
    icon: 'help',
    action: 'help-center'
  },
  LOGOUT: {
    name: MenuName.LOGOUT,
    icon: 'logout',
    action: 'logout'
  },
  SETTINGS: {
    name: MenuName.SETTINGS,
    icon: 'settings',
    url: '/main/settings',
    needAdminAccount: true
  },
  SOCIAL_ACCOUNTS: {
    name: MenuName.SOCIAL_ACCOUNTS,
    icon: 'accounts',
    url: '/main/social-accounts',
    needAdminAccount: true
  },
  UPGRADE: {
    name: MenuName.UPGRADE,
    icon: 'upgrade',
    url: '/main/upgrade',
    needAdminAccount: true
  }
};

export const ROUTES: IRoute[] = [
  ROUTE_DEFINITIONS.DASHBOARD,
  ROUTE_DEFINITIONS.CLICK_FRAUD_PROTECTION,
  ROUTE_DEFINITIONS.BUSINESS,
  ROUTE_DEFINITIONS.SOCIAL_ACCOUNTS,
  ROUTE_DEFINITIONS.SETTINGS
];

export const MOBILE_ROUTES: IRoute[] = [
  ROUTE_DEFINITIONS.UPGRADE,
  ROUTE_DEFINITIONS.DASHBOARD,
  ROUTE_DEFINITIONS.CLICK_FRAUD_PROTECTION,
  ROUTE_DEFINITIONS.BUSINESS,
  ROUTE_DEFINITIONS.SOCIAL_ACCOUNTS,
  ROUTE_DEFINITIONS.SETTINGS,
  ROUTE_DEFINITIONS.HELP_CENTER,
  ROUTE_DEFINITIONS.LOGOUT
];

export const MOBILE_TOP_ROUTES: IRoute[] = [
  ROUTE_DEFINITIONS.DASHBOARD,
  ROUTE_DEFINITIONS.CLICK_FRAUD_PROTECTION,
  ROUTE_DEFINITIONS.BUSINESS,
  ROUTE_DEFINITIONS.CREATE_AD,
  ROUTE_DEFINITIONS.SOCIAL_ACCOUNTS,
  ROUTE_DEFINITIONS.SETTINGS,
  {
    name: 'Menu',
    icon: 'menu'
  }
];
