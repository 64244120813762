import { Enviroment } from './types';

export const environment: Enviroment = {
  production: true,
  name: 'testing',
  apiUrl: 'https://test-api.adyouneed.com',
  graphQl: '/graphql',
  wsUrl: 'wss://test-api.adyouneed.com',
  applicationUrl: 'https://test.adyouneed.com/',
  draftLog: false,

  // 3rd Party Integrations
  canvaApiKey: 'HAyUBanwdit_BQwoaElrAACH',
  chargebeeSite: 'aicreative-test',
  chargebeePublisableKey: 'test_YAJJclQ6Q72WnnAoYXIjs5X6Eyl2SF7U',
  googleMapKey: 'AIzaSyBuQum_Q_l5J3HX6LRCf9wn1ng2gz9cVxI',
  sentryDsn: 'https://20eed62b0170957160c02283bffd7467@us.sentry.io/4506700009701376'
};
