import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TiktokService } from '@core/services';
import { TikTok, UploadImageItem } from '@core/models';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';
import { AspectRatio } from '@shared/models';
import { environment } from '@environment';
import { createRequiredValidator, Upload } from '@core/utils';
import { ToastrService } from 'ngx-toastr';
import { IRestError } from '@core/models/base.model';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { LoaderState } from '@core/state/loader.state';
import { Observable, ReplaySubject } from 'rxjs';
import { Select as AynSelect } from '@ayn-ui/lib/modules';
import { RunAfter } from '@ayn-ui/lib/helpers';

@Component({
  selector: 'aayn-identity-select',
  templateUrl: './identity-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: IdentitySelectComponent, multi: true }]
})
export class IdentitySelectComponent implements OnInit, ControlValueAccessor {
  identityImage: { url: string; id: string } = { url: '', id: '' };
  identityProgressItem = new UploadImageItem();

  identities: TikTok.Business.TikTokIdentityOutputDto[] = [];

  ratio = '1:1' as AspectRatio.Ratios;
  businessName = '';

  identityForm = new FormGroup(
    {
      name: new FormControl<string>('', [Validators.required, Validators.minLength(2)])
    },
    createRequiredValidator(() => !!this.identityImage.id)
  );
  showCreate = false;

  @Select(LoaderState.getAny(['CreateIdentity', 'TikTokIdentities'])) identityLoader$!: Observable<boolean>;

  selectedIdentity?: TikTok.Business.TikTokIdentityOutputDto;

  onChange: any = () => {};

  afterIdentityLoad$ = new ReplaySubject();

  constructor(
    private tiktokService: TiktokService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tiktokService.getIdentities().subscribe((identities) => {
      this.identities =
        identities?.filter(
          ({ identityType }) => identityType === TikTok.Business.TikTokIdentityEnumType.CUSTOMIZED_USER
        ) || [];
      this.afterIdentityLoad$.next();
      this.cdr.detectChanges();
    });
  }

  uploadFile({ file }: IFileAddedEvent, select: AynSelect) {
    const formData = new FormData();
    formData.append('image', file);
    if (select) {
      select.overlay.bindDocumentClickListener();
    }
    this.tiktokService.uploadImage$(formData).subscribe({
      next: (uploadResult) => {
        if (uploadResult) {
          this.processFileUpload(uploadResult);
          this.cdr.detectChanges();
        }
      },
      error: ({ error }: { error: IRestError }) => {
        if (!(error.message instanceof Array)) {
          this.toastrService.warning(error.message);

          this.identityProgressItem.reset();
        }
      }
    });
  }

  removeIdentityImage() {
    this.identityProgressItem = new UploadImageItem();
    this.identityImage = { id: '', url: '' };
    this.cdr.detectChanges();
  }

  private processFileUpload(uploadResult: Upload<TikTok.CreateAd.Backend.TikTokUploadImageResponse | null>) {
    this.identityProgressItem!.progress(uploadResult.progress);

    if (!environment.production) console.log('[CreateAd.Tiktok.UploadResult]: ', uploadResult);

    if (uploadResult.state != 'DONE') return;

    this.identityProgressItem!.progressValue = 100;

    if (uploadResult.result) {
      this.identityImage.id = uploadResult.result?.imageId;
      this.identityImage.url = uploadResult.result?.imageUrl;
      this.identityForm.updateValueAndValidity();
    }

    this.toastrService.success('Image upload successful.', 'Successful');
  }

  saveIdentity(select?: AynSelect) {
    if (!this.identityForm.valid) {
      return;
    }
    this.tiktokService
      .createIdentity({
        displayName: this.identityForm.value.name!,
        imageUri: this.identityImage.id
      })
      .pipe()
      .subscribe((identity) => {
        this.identities = [
          ...this.identities,
          {
            identityId: identity!.identityId,
            displayName: this.identityForm.value.name!,
            profileImage: this.identityImage.url
          } as TikTok.Business.TikTokIdentityOutputDto
        ];
        this.selectedIdentity = this.identities.find((i) => i.identityId === identity?.identityId);
        this.toastrService.success('Business created successfully.');
        this.reset();
        this.onChange(this.selectedIdentity);
        select?.overlay.hide();
      });
  }

  cropperOpen(select: AynSelect) {
    if (select) {
      select.overlay.unbindDocumentClickListener();
    }
  }

  reset() {
    this.showCreate = false;
    this.identityForm.patchValue({ name: '' });
    this.identityImage = { id: '', url: '' };
    this.identityProgressItem = new UploadImageItem();
    this.identityForm.markAsUntouched();
    this.identityForm.updateValueAndValidity();
  }

  writeValue(identity: TikTok.Business.TikTokIdentityOutputDto) {
    if (identity?.identityId) {
      this.selectIdentity(identity);
    } else {
      this.selectFirstIdentity();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched() {}
  setDisabledState() {}

  @RunAfter<IdentitySelectComponent>('afterIdentityLoad$')
  selectFirstIdentity() {
    this.selectedIdentity = this.identities[0];
    this.onChange(this.selectedIdentity);
  }

  @RunAfter<IdentitySelectComponent>('afterIdentityLoad$')
  selectIdentity(identity: TikTok.Business.TikTokIdentityOutputDto) {
    this.selectedIdentity = this.identities.find((i) => i.identityId === identity.identityId);
    this.onChange(this.selectedIdentity);
  }
}
