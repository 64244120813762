import { isBetween } from '@core/utils/number';

/**
 * Gets the random hex color.
 */
export function getRandomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function rgbToHex(r, g, b) {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}

export function rgbFormat(color: string) {
  return color.match(/\d+/g)?.join(',') || color;
}

function isPointValid(point: string) {
  const parsed = Number(point);
  return !isNaN(parsed) && isBetween(parsed, 0, 255);
}

export function validateRgbPoints(rgbPoints: string) {
  const points = rgbPoints.split(',').map((point) => point.trim());

  return points.length === 3 && points.every(isPointValid);
}

export function rgbFromPoints(rgbPoints: string) {
  if (validateRgbPoints(rgbPoints)) {
    return `rgb(${rgbPoints})`;
  }
  throw new Error('Invalid RGB points');
}
