import { Injectable } from '@angular/core';
import { TikTokQ } from '@core/queries';
import { GraphQLService } from '../backend/graphql/graphql-service';
import { map, scan } from 'rxjs/operators';
import { TikTok } from '@core/models';
import { calculateState, Upload } from '@core/utils';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TiktokService {
  constructor(private graphqlService: GraphQLService, private http: HttpClient) {}

  getOAuthUri$(contextId: string) {
    return this.graphqlService
      .post(TikTokQ.business.oauthTikTok({ contextId }))
      .pipe(map((result) => result.data?.oauth));
  }

  getAdAccounts$() {
    return this.graphqlService
      .post(TikTokQ.business.getAdAccountsQuery())
      .pipe(map((result) => result.data?.adAccounts));
  }

  connectAdAccount$(payload: TikTok.Business.TiktokConnectAdAccountRequest) {
    return this.graphqlService
      .post(TikTokQ.business.connectAdAccountQuery({ payload }))
      .pipe(map((result) => result.data?.connectAdAccount));
  }

  accountOverview$(payload: TikTok.Dashboard.TikTokAccountOverviewRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.accountOverviewQuery(payload))
      .pipe(map((result) => result.data?.accountOverview));
  }

  accountOverviewTotal$(payload: TikTok.Dashboard.TikTokAccountOverviewRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.accountOverviewTotalQuery(payload))
      .pipe(map((result) => result.data?.accountOverview[0]));
  }

  funnelDashboard$(payload: TikTok.Dashboard.TikTokAccountOverviewTotalRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.funnelDashboardQuery(payload))
      .pipe(map((result) => result.data?.funnelDashboard));
  }

  breakdowns$(payload: TikTok.Dashboard.TikTokBreakdownsRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.breakdownsQuery(payload))
      .pipe(map((result) => result.data?.breakdowns));
  }

  campaigns$(input: { ids: string[] }) {
    return this.graphqlService.get(TikTokQ.dashboard.campaigns(input)).pipe(map((result) => result.data.campaigns));
  }

  campaignWithMetrics$(input: TikTok.Dashboard.TikTokCampaignsWithMetricsRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.campaignsWithMetricsQuery(input))
      .pipe(map((result) => result.data));
  }

  campaignWithMetricsSmartSorting$(input: TikTok.Dashboard.TikTokCampaignsWithMetricsRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.campaignsWithMetricsSmartSorting(input))
      .pipe(map((result) => result.data));
  }

  adGroupWithMetrics$(input: TikTok.Dashboard.TikTokAdGroupsWithMetricsRequest) {
    return this.graphqlService
      .get(TikTokQ.dashboard.adGroupsWithMetricsQuery(input))
      .pipe(map((result) => result.data));
  }

  adsWithMetrics$(input: TikTok.Dashboard.TikTokAdsWithMetricsRequest) {
    return this.graphqlService.get(TikTokQ.dashboard.adsWithMetricsQuery(input)).pipe(map((result) => result.data));
  }

  editCampaign$(input: TikTok.Dashboard.UpdateCampaign) {
    return this.graphqlService.post(TikTokQ.dashboard.updateCampaignQuery(input)).pipe(map((result) => result.data));
  }

  turnOnResources$(request: TikTok.Dashboard.TikTokTurnOnOffRequest) {
    return this.graphqlService.post(TikTokQ.dashboard.turnOnQuery(request)).pipe(map((result) => result.data?.turnOn));
  }

  turnOffResources$(request: TikTok.Dashboard.TikTokTurnOnOffRequest) {
    return this.graphqlService
      .post(TikTokQ.dashboard.turnOffQuery(request))
      .pipe(map((result) => result.data?.turnOff));
  }

  updateAdGroup$(input: TikTok.Dashboard.UpdateAdGroup) {
    return this.graphqlService.post(TikTokQ.dashboard.updateAdGroupQuery(input)).pipe(map((result) => result.data));
  }

  pixelSearch$(input?: TikTok.CreateAd.Backend.TikTokPixelDto) {
    return this.graphqlService
      .get(TikTokQ.createAd.pixelSearchTiktokQuery(input || {}))
      .pipe(map((result) => result.data?.pixelSearch));
  }

  getLocations$(input: TikTok.CreateAd.Backend.LocationSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.getLocationsQuery(input))
      .pipe(map((result) => result.data?.locationSearch));
  }

  interestSearch$(input: TikTok.CreateAd.Backend.InterestSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.interestSearchQuery(input))
      .pipe(map((result) => result.data?.interestSearch));
  }

  keywordSearch$(input: TikTok.CreateAd.Backend.InterestSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.keywordsSearchQuery(input))
      .pipe(map((result) => result.data?.keywordSearch));
  }

  customAudienceSearch(input?: TikTok.CreateAd.Backend.CustomAudienceSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.customAudienceSearchQuery(input || {}))
      .pipe(map((result) => result.data?.customAudienceSearch));
  }

  languageSearch$(input: TikTok.CreateAd.Backend.LanguageSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.languageSearchQuery(input))
      .pipe(map((result) => result.data?.languageSearch));
  }

  uploadImage$(formData: FormData) {
    const initialState: Upload<TikTok.CreateAd.Backend.TikTokUploadImageResponse | null> = {
      state: 'PENDING',
      progress: 0
    };

    return this.http
      .post<TikTok.CreateAd.Backend.TikTokUploadImageResponse>(`${environment.apiUrl}/tiktok/upload-image`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(scan(calculateState, initialState));
  }

  uploadVideo$(formData: FormData) {
    const initialState: Upload<TikTok.CreateAd.Backend.TikTokVideoUploadResponse | null> = {
      state: 'PENDING',
      progress: 0
    };

    return this.http
      .post<TikTok.CreateAd.Backend.TikTokVideoUploadResponse>(`${environment.apiUrl}/tiktok/upload-video`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(scan(calculateState, initialState));
  }

  getAccountImages$(input?: TikTok.CreateAd.Backend.TikTokImageSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.accountImagesQuery(input || {}))
      .pipe(map((imageResponse) => imageResponse.data?.accountImages));
  }

  getAccountVideos$(input?: TikTok.CreateAd.Backend.TikTokVideoSearch) {
    return this.graphqlService
      .get(TikTokQ.createAd.accountVideosQuery(input || {}))
      .pipe(map((imageResponse) => imageResponse.data?.accountVideos));
  }

  createCampaign(campaign: TikTok.CreateAd.Backend.TikTokCampaignDto) {
    return this.graphqlService
      .post(TikTokQ.createAd.createCampaignQuery({ campaign }))
      .pipe(map((result) => result.data?.createCampaign));
  }

  getIdentities() {
    return this.graphqlService
      .get(TikTokQ.business.getIdentitiesQuery())
      .pipe(map((response) => response.data?.identities));
  }

  getMinimumCurrencyValue(code: TikTok.Business.TikTokCurrency) {
    return this.graphqlService
      .get(TikTokQ.createAd.minimumCurrencyValueQuery({ code }))
      .pipe(map((response) => response.data?.minCurrencyValue));
  }

  createIdentity(payload: TikTok.Business.TikTokCreateIdentityDto) {
    return this.graphqlService
      .post(TikTokQ.business.createIdentityQuery({ payload }))
      .pipe(map((response) => response.data?.createIdentity));
  }
}
