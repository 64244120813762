import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ChargebeeService, ChargebeeState, detectAdBlock } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { LayoutService } from '@pages/layout.service';

@Component({
  selector: 'aayn-topbar',
  templateUrl: 'topbar.component.html'
})
export class TopbarComponent implements OnInit {
  @Select(ChargebeeState.isCancelledScheduled) isCancelledScheduled$?: Observable<boolean>;

  @Select(ChargebeeState.isAppsumoUser) isAppsumoUser$?: Observable<boolean>;

  detectedAdBlock = false;

  @Select(LoaderState.getAny('AbortSubscriptionCancellation')) loader$?: Observable<boolean>;

  constructor(
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    detectAdBlock().then((o) => {
      this.detectedAdBlock = o;
    });
  }

  abortCancellation() {
    this.chargebeeService.abortCancellationSubscription$().subscribe(() => {
      this.toastrService.success('Reactivation successful.');
    });
  }

  reloadPage() {
    location.reload();
  }
}
