import { changeSentimentQuery } from './changeSentiment.query';
import { createVariationsQuery } from './createVariations.query';
import { getBrandDescriptionQuery } from './getBrandDescription.query';
import { getProductDescriptionQuery } from './getProductDescription.query';
import { makeShortenQuery } from './makeShorten.query';
import { translateQuery } from './translate.query';
import { generateTextProjectQuery } from './generate-text-project.query';
import { generateTextsQuery } from './generate-texts.query';
import { getFrameworkTexts } from './get-texts-with-framework.query';
import { searchLogosQuery } from '@core/queries/openAi/search-logos.query';
import { searchImagesQuery } from '@core/queries/openAi/search-images.query';

export const OpenAiQ = {
  changeSentimentQuery,
  createVariationsQuery,
  generateTextProjectQuery,
  generateTextsQuery,
  getBrandDescriptionQuery,
  getProductDescriptionQuery,
  makeShortenQuery,
  translateQuery,
  getFrameworkTexts,
  searchLogosQuery,
  searchImagesQuery
};
