<header class="modal-v3--header">
  <ayn-icon name="warning-fill"></ayn-icon>

  <div class="modal-v3--header-content">
    <h5>Platform reconnection needed!</h5>
    <p>To continue without disruption, the account(s) listed below require either reconnection or disconnection</p>
  </div>
</header>

<main class="modal-v3--content">
  <aayn-base-social-accounts
    platformClass="col-12 mb:4"
    [showComingSoon]="false"
    [showMoreButtons]="true"
    [showHasReconnectionPlatforms]="true"
  ></aayn-base-social-accounts>
</main>

<footer class="modal-v3--footer">
  <button ayn-button="primary" [disabled]="!!(reconnectPlatforms$ | async)?.length" (click)="closeModal()">
    Continue
  </button>
</footer>
