import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { AdcService } from '@core/services/adc.service';
import { GraphQLService } from '@core/backend';
import {
  AddImageGenerationTasks,
  UpdateImageGenerationTask
} from '@pages/create-ad/state/adc-image-generation/actions';
import * as Query from '@core/queries';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EventHubEventName } from '@core/models';
import { of, Subject, throwError } from 'rxjs';
import { AdCreative } from '@core/models/platforms/adcreative';
import { removeItem } from '@core/utils';

export interface ImageEvent {
  imageRenderProcessId: string;
  tasks: {
    [key: string]: number;
  };
}

export interface EventTask {
  id: string;
  status: number;
  file?: string;
}
@Injectable({
  providedIn: 'root'
})
export class AdcImageEventService {
  constructor(private store: Store, private adcService: AdcService, private gqlService: GraphQLService) {}

  addImageGenerationTask(imageEvent: ImageEvent) {
    const tasks = Object.entries(imageEvent.tasks)
      .filter(([key]) => key !== '$id')
      .map(([id, status]) => ({
        id,
        status,
        imageRenderProcessId: imageEvent.imageRenderProcessId
      }));
    return this.store.dispatch(new AddImageGenerationTasks(tasks));
  }

  updateImageGenerationTask(task: EventTask) {
    return this.store.dispatch(new UpdateImageGenerationTask({ id: task.id, status: task.status }));
  }

  subscribeToEvent(
    imageRenderProcess: Pick<AdCreative.Client.ImageGenerationProgress, 'imageRenderProcessId' | 'tasks'>
  ) {
    const finished$ = new Subject();
    let tasks = Object.keys(imageRenderProcess.tasks);
    return this.gqlService
      .subscription(Query.adcEvent({ imageRenderProcessId: imageRenderProcess.imageRenderProcessId }))
      .pipe(
        switchMap((response) => {
          if (response?.data?.eventName === EventHubEventName.SingleCreativeCreationFinished) {
            const event = response.data.data.value;

            if (!event) {
              return throwError('No event data found');
            }
            tasks = removeItem(tasks, event.taskId);
            if (!tasks.length) {
              finished$.next();
            }
            if (event.isSuccessful) {
              return this.updateImageGenerationTask({ status: 5, id: event.taskId });
            } else {
              return this.updateImageGenerationTask({ status: -1, id: event.taskId }).pipe(
                switchMap(() => throwError(event!.errorMessage))
              );
            }
          }
          return of(response.data);
        }),
        takeUntil(finished$)
      );
  }

  /*
  createImageGenerationTask(payload: { ratios: number[] }) {
    return this.adcService
      .createImageGenerationTask({
        actionText: '',
        backgroundImage: new File([], ''),
        brandImage: new File([], ''),
        color1Hex: '',
        color2Hex: '',
        mainHeadline: '',
        punchline: '',
        description: '',
        buttonIconClass: ''
      })
      .pipe(
        switchMap((imageGenerationTask) =>
          this.addImageGenerationTask(imageGenerationTask).pipe(
            map(() =>
              Object.entries(imageGenerationTask.tasks).map(([id, status], index) => ({
                id,
                status,
                imageGenerationProgressId: imageGenerationTask.imageRenderProcessId,
                ratio: payload.ratios[index]
              }))
            )
          )
        ),
        tap((tasks) => {
          const ss = this.store.selectSnapshot(AdcImageGenerationState);
          const subs = interval(500).subscribe(() => {
            const task = tasks.findIndex(({ status }) => status < 5);
            if (task > -1) {
              console.log(task);
              if (typeof tasks[task].status === 'number') {
                (tasks[task].status as number) += 1;
                this.updateImageGenerationTask(tasks[task] as unknown as EventTask);
              }
            } else {
              subs.unsubscribe();
            }
          });
        })
      );
  }*/
}
