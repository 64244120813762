import { pipe } from 'fp-ts/lib/function';
import { cloneDeep, omit } from 'lodash';
import { modifyW, remove, rename } from 'spectacles-ts';

import { Google } from '@core/models';
import { KeyName } from '@core/models/base.model';
import { environment } from '@environment';

import {
  ApplyTypes,
  ApplyValidations,
  createAdBaseModel,
  CreateAdCommon,
  ICreateAdCommonValidate,
  UsesInitialization,
  WithMap
} from '../common';

import {
  createDefaultCallExt,
  createDefaultCalloutExt,
  createDefaultSiteLinkExt,
  createDefaultStructedSnippetExt,
  createDefaultYoutubeExt,
  removeEmptyExtensions
} from './utils';

export module GoogleCreateAd {
  export type AdRequest<CT = Google.CreateAd.GooglePMCampaign> = {
    campaign: CT;
  };

  function createEmptyModel<AT = Google.CreateAd.GooglePMCampaign>() {
    return {
      ...createAdBaseModel(
        Google.CampaignObjective.ConversionsOnMyWebsite,
        Google.AudienceType.Manual,
        Google.AdType.PerformanceMax
      ),
      campaign: {
        campaignName: '',
        paused: !environment.production,
        conversionGoal: [] as Google.CreateAd.GoogleConversionGoalInput[] | undefined,
        conversionValueTargetRoas: undefined,
        url: '',
        callToAction: Google.GoogleCallToAction.ApplyNow as Google.GoogleCallToAction | undefined,
        budgetAmount: 0,
        startDate: new Date(),
        endDate: undefined as Date | undefined,

        _campaignId: undefined as string | undefined
      },

      audience: {
        create: {
          audienceName: '',
          minAge: 18,
          maxAge: 65,
          male: true,
          female: true,
          languageIds: [] as KeyName[],
          includedLocations: [] as Google.CreateAd.ILocationSearch[],
          excludedLocations: [] as Google.CreateAd.ILocationSearch[],
          customAudiences: [] as Google.GoogleKeywordCriterion[],
          interests: [] as Google.CreateAd.GooglePMInterestDetails[],
          yourDatas: [] as Google.CreateAd.IYourDataSearch[]
        } as Google.CreateAd.GooglePMAudienceCreate
      } as Google.CreateAd.GooglePMAudience,

      ad: {
        assets: {
          longHeadlines: ['', '', ''] as string[],
          shortHeadlines: ['', '', ''] as string[],
          descriptions: ['', '', ''] as string[],
          shortDescription: '',
          businessName: '',
          images: {
            squareMarketingIds: [] as { id: number; url: string }[] | undefined,
            landscapeMarketingIds: [] as { id: number; url: string }[] | undefined,
            portraitMarketingIds: [] as { id: number; url: string }[] | undefined,
            logoIds: [] as { id: number; url: string }[] | undefined,
            landscapeLogoIds: [] as { id: number; url: string }[] | undefined
          }
        },
        ...createDefaultSiteLinkExt(),
        ...createDefaultCallExt(),
        ...createDefaultCalloutExt(),
        ...createDefaultYoutubeExt()
      } as unknown as AT
    };
  }

  export type ICreateAdModel<AT = Google.CreateAd.GooglePMCampaign> = ReturnType<typeof createEmptyModel<AT>>;

  const deepClonedModel = structuredClone({ ...createEmptyModel() });

  @UsesInitialization(deepClonedModel)
  @WithMap({
    [Google.AdType.PerformanceMax]: function (model: ICreateAdModel) {
      const mapToEmptyExtensions = (o: ICreateAdModel) => {
        o.ad = removeEmptyExtensions(o.ad);
        return o;
      };

      const mapAudienceToOriginal = (adCreationModel: ICreateAdModel) => {
        return pipe(
          adCreationModel.audience,
          cloneDeep,
          modifyW('create.includedLocations.[]>', (o) => o.id),
          rename('create.includedLocations', 'includedLocationIds'),
          modifyW('create.excludedLocations.[]>', (o) => o.id),
          rename('create.excludedLocations', 'excludedLocationIds'),
          modifyW('create.customAudiences?.[]>', (o) => o.keyword),
          modifyW('create.interests.[]>', ({ id, interestType }) => ({ id, type: interestType })),
          modifyW('create.yourDatas.[]>', (o) => o.id),
          rename('create.yourDatas', 'yourDataIds'),
          modifyW('create.languageIds.[]>', (o) => o.key)
        );
      };

      return pipe(
        model,
        cloneDeep,

        (o) => {
          let {
            ad: {
              assets: { images }
            }
          } = o;

          const modifiedImages = pipe(
            images,
            modifyW('squareMarketingIds', (o) => o!.map((img) => +img.id)),
            modifyW('landscapeMarketingIds', (o) => o!.map((img) => +img.id)),
            modifyW('portraitMarketingIds', (o) => o!.map((img) => +img.id)),
            modifyW('logoIds', (o) => o!.map((img) => +img.id)),
            modifyW('landscapeLogoIds', (o) => o!.map((img) => +img.id))
          );
          // FIXME: Tip düzeltilecek.
          (o as any).ad.assets.images = modifiedImages as any;

          return o;
        },
        (o) => {
          o = mapToEmptyExtensions(o);

          (o.campaign.startDate as unknown as number) = o.campaign.startDate.getTime() / 1000;

          if (o.campaign.endDate) {
            (o.campaign.endDate as unknown as number) = o.campaign.endDate.getTime() / 1000;
          }
          o.ad.assets.longHeadlines = o.ad.assets.longHeadlines.filter(Boolean);
          o.ad.assets.shortHeadlines = o.ad.assets.shortHeadlines.filter(Boolean);
          o.ad.assets.descriptions = o.ad.assets.descriptions.filter(Boolean);

          const adRequest = {
            campaign: {
              ...omit(o.campaign, '_campaignId'),
              ...o.ad,
              audience: mapAudienceToOriginal(o)
            }
          };

          return adRequest;
        }
      );
    },

    [Google.AdType.DisplayAds]: function (model: ICreateAdModel<Google.CreateAd.GoogleDisplayCampaign>) {
      const mapToAudience = (o: typeof model) => {
        return pipe(
          o.audience,
          cloneDeep,
          modifyW('create.includedLocations.[]>', (o) => o.id),
          rename('create.includedLocations', 'includedLocationIds'),
          modifyW('create.excludedLocations.[]>', (o) => o.id),
          rename('create.excludedLocations', 'excludedLocationIds'),
          modifyW('create.interests.[]>', ({ id }) => id),
          rename('create.customAudiences', 'keywords'),
          remove('create.yourDatas'),
          modifyW('create.languageIds.[]>', (o) => o.key)
        );
      };

      return pipe(
        model,
        cloneDeep,
        (o) => {
          o.ad = removeEmptyExtensions(o.ad);

          return o;
        },
        (model) => {
          let {
            ad: {
              assets: { images }
            }
          } = model;

          const modifiedImages = pipe(
            images,
            modifyW('squareMarketingIds', (o) => o!.map((img) => +img.id)),
            modifyW('logoIds', (o) => o!.map((img) => +img.id)),
            modifyW('squareLogoIds', (o) => o!.map((img) => +img.id)),
            modifyW('marketingIds', (o) => o!.map((img) => +img.id))
          );

          // FIXME: Tip düzeltilecek.
          (model as any).ad.assets.images = modifiedImages as any;

          return model;
        },
        (o) => {
          delete o.campaign.callToAction;
          delete o.ad.assets.campaignName;

          const {
            images,
            campaignName,
            businessName,
            descriptions,
            headlines,
            callToActionText,
            longHeadline,
            accentColor,
            mainColor,
            allowFlexibleColor,
            promoText,
            pricePrefix
          } = o.ad.assets;
          o.ad.assets = {
            images,
            campaignName,
            businessName,
            descriptions: descriptions.filter(Boolean),
            headlines: headlines.filter(Boolean),
            callToActionText,
            longHeadline,
            accentColor,
            mainColor,
            allowFlexibleColor,
            promoText,
            pricePrefix
          };

          return o;
        },
        (o) => {
          (o.campaign.startDate as unknown as number) = o.campaign.startDate.getTime() / 1000;

          if (o.campaign.endDate) {
            (o.campaign.endDate as unknown as number) = o.campaign.endDate.getTime() / 1000;
          }

          const adRequest = {
            campaign: {
              ...omit(o.campaign, '_campaignId'),
              ...o.ad,
              audience: mapToAudience(o)
            }
          };

          return adRequest;
        }
      );
    },

    [Google.AdType.SearchAds]: function (model: ICreateAdModel<Google.CreateAd.GoogleSearchCampaign>) {
      const mapToEmptyExtensions = (o: ICreateAdModel<Google.CreateAd.GoogleSearchCampaign>) => {
        o.ad = removeEmptyExtensions(o.ad);
        return o;
      };

      const mapAudienceToOriginal = (adCreationModel: ICreateAdModel<Google.CreateAd.GoogleSearchCampaign>) => {
        return pipe(
          adCreationModel.audience,
          cloneDeep,
          modifyW('create.includedLocations.[]>', (o) => o.id),
          rename('create.includedLocations', 'includedLocationIds'),
          modifyW('create.excludedLocations.[]>', (o) => o.id),
          rename('create.excludedLocations', 'excludedLocationIds'),
          rename('create.customAudiences', 'keywords'),
          modifyW('create.interests.[]>', ({ id }) => id),
          remove('create.yourDatas'),
          modifyW('create.languageIds.[]>', (o) => o.key)
        );
      };

      return pipe(
        model,
        cloneDeep,
        (o) => {
          o.campaign.callToAction = undefined;
          o.campaign.conversionValueTargetRoas = undefined;
          o.campaign.conversionGoal = undefined;

          const { businessName, descriptions, headlines, path1, path2 } = o.ad.assets;
          o.ad.assets = {
            businessName,
            descriptions: descriptions.filter(Boolean),
            headlines: headlines.filter(Boolean),
            path1,
            path2
          };

          return o;
        },
        (o) => {
          o = mapToEmptyExtensions(o);

          (o.campaign.startDate as unknown as number) = o.campaign.startDate.getTime() / 1000;

          if (o.campaign.endDate) {
            (o.campaign.endDate as unknown as number) = o.campaign.endDate.getTime() / 1000;
          }

          const adRequest = {
            campaign: {
              ...omit(o.campaign, '_campaignId'),
              ...o.ad,
              audience: mapAudienceToOriginal(o)
            }
          };

          return adRequest;
        }
      );
    }
  })
  @ApplyTypes({
    ad: {
      [Google.AdType.PerformanceMax]: {
        ad: {
          assets: {
            longHeadlines: ['', '', ''] as string[],
            shortHeadlines: ['', '', ''] as string[],
            descriptions: ['', '', ''] as string[],
            shortDescription: '',
            businessName: '',
            images: {
              squareMarketingIds: [] as { id: number; url: string }[] | undefined,
              landscapeMarketingIds: [] as { id: number; url: string }[] | undefined,
              portraitMarketingIds: [] as { id: number; url: string }[] | undefined,
              logoIds: [] as { id: number; url: string }[] | undefined,
              landscapeLogoIds: [] as { id: number; url: string }[] | undefined
            }
          },
          ...createDefaultSiteLinkExt(),
          ...createDefaultCallExt(),
          ...createDefaultCalloutExt(),
          ...createDefaultYoutubeExt()
        }
      },

      [Google.AdType.DisplayAds]: {
        ad: {
          assets: {
            longHeadline: '',
            headlines: ['', '', ''],
            descriptions: ['', '', ''],
            businessName: '',
            images: {
              logoIds: [],
              marketingIds: [],
              squareLogoIds: [],
              squareMarketingIds: []
            },
            callToActionText: Google.GoogleCallToActionForDisplayAds.ApplyNow,

            campaignName: ''
          } as Google.CreateAd.DisplayCampaignAssets,

          ...createDefaultYoutubeExt()
        }
      },

      [Google.AdType.SearchAds]: {
        ad: {
          assets: {
            headlines: ['', ''],
            descriptions: ['', ''],
            path1: '',
            path2: '',
            businessName: ''
          } as Google.CreateAd.GoogleSearchCampaignAssets,
          ...createDefaultSiteLinkExt(),
          ...createDefaultCallExt(),
          ...createDefaultCalloutExt(),
          ...createDefaultStructedSnippetExt()
        }
      }
    },

    campaign: {
      [Google.AdType.PerformanceMax]: {
        campaign: {
          campaignName: '',
          paused: !environment.production,
          conversionGoal: [] as Google.CreateAd.GoogleConversionGoalInput[],
          conversionValueTargetRoas: undefined,
          url: '',
          callToAction: Google.GoogleCallToAction.ApplyNow,
          budgetAmount: 0,
          startDate: Math.floor(new Date().getTime() / 1000),
          endDate: undefined
        },

        audience: {
          create: {
            audienceName: '',
            minAge: 18,
            maxAge: 65,
            male: true,
            female: true,
            languageIds: [] as KeyName[],
            includedLocations: [] as Google.CreateAd.ILocationSearch[],
            excludedLocations: [] as Google.CreateAd.ILocationSearch[],
            customAudiences: [] as Google.GoogleKeywordCriterion[],
            interests: [] as Google.CreateAd.GooglePMInterestDetails[],
            yourDatas: [] as Google.CreateAd.IYourDataSearch[]
          } as Google.CreateAd.GooglePMAudienceCreate
        } as Google.CreateAd.GooglePMAudience
      },
      [Google.AdType.DisplayAds]: {
        campaign: {
          campaignName: '',
          conversionGoal: [],
          url: '',
          budgetAmount: 0,
          startDate: new Date().getTime(),
          endDate: undefined,
          paused: !environment.production,
          conversionValueTargetRoas: undefined
        },

        audience: {
          create: {
            audienceName: '',
            includedLocations: [] as Google.CreateAd.ILocationSearch[],
            excludedLocations: [] as Google.CreateAd.ILocationSearch[],
            minAge: 18,
            maxAge: 64,
            female: true,
            male: true,
            keywords: [] as Google.GoogleKeywordCriterion[],
            topicIds: [],
            userInterests: [] as Google.CreateAd.GooglePMInterestDetails[],
            languageIds: [] as number[]
          }
        }
      }
    }
  })
  @ApplyValidations({
    ad: {
      [Google.AdType.PerformanceMax]: function (model: ICreateAdModel) {
        const images = model.ad.assets.images;

        return !!(
          (images.logoIds?.length || images.landscapeLogoIds?.length) &&
          images.landscapeMarketingIds?.length &&
          images.portraitMarketingIds?.length &&
          images.squareMarketingIds?.length
        );
      },
      [Google.AdType.DisplayAds]: function (model: ICreateAdModel<Google.CreateAd.GoogleDisplayCampaign>) {
        const images = model.ad.assets.images;

        return !!(images.marketingIds?.length && images.squareMarketingIds?.length);
      }
    }
  })
  export class CreateAd {
    get isValidImages() {
      return true;

      // TODO: Burada createAdModel güncellenmediği için doğru validasyon sağlanmıyor.
      return this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd extends CreateAdCommon<ICreateAdModel, AdRequest, Google.AdType> {
    mapToApiRequest(type?: string | number): AdRequest;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
