import { isEqual } from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, take, takeUntil } from 'rxjs/operators';

import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  isDevMode,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AynUITranslateService } from '../../translate/translate.module';
import { Overlay } from '../overlay';
import { IOption, Option } from './option';

export type SelectType = 'default';

const SELECT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Select),
  multi: true
};

type OptionValueType = string | number | object;

@UntilDestroy()
@Component({
  selector: 'ayn-select',
  templateUrl: './select.html',
  providers: [SELECT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'aynSelect'
})
export class Select implements AfterContentInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  selectedOptionHtml!: string;

  @Input() model?: OptionValueType | OptionValueType[];

  @Output() valueChange = new EventEmitter();

  @Input() placeholder = '';

  @Input() placeholderTemplate?: TemplateRef<ElementRef>;

  @Input() type: SelectType = 'default';

  @Input() containerClassName = '';

  @Input() overlayClassName = '';

  @Input() className = '';

  @Input() setValueHtml = false;

  @Input() minWidth = 0;

  @Input('options') _options!: IOption[];

  @Input('panelMaxHeight') overlayMaxHeight = 330;

  @Input() showSearch = true;

  @Input() searchTerm = '';

  @Input() searchPlaceholder = 'Search...';

  @Input() showTick = true;

  @Input() appendTo?: 'body';

  @Input() contentLabel?: string;

  @Input() loading = false;

  @Input() readonly: boolean | null = false;

  @Input() multiple = false;

  @Input() overlayContainerClassName = '';

  @Input() stopPropagation = false;

  @Input() updateValue = true;

  @Input() requiredMessage = 'Required field.';

  @Input() dontSelect = false;

  @Input() hideOnSelectOverlay = true;

  @Input() overlayBaseZIndex = 0;

  @Input() set disabled(value: boolean | null | undefined) {
    this._disabled = value;

    if (value) this.formGroupDirective?.control?.disable();
    else this.formGroupDirective?.control?.enable();
  }

  @Output()
  get searchControlChange() {
    return (this.searchControl.valueChanges as Observable<string>).pipe(
      untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged()
    );
  }

  @ContentChildren(Option, { descendants: true }) contentOptions!: QueryList<Option>;

  @ViewChildren(Option) viewOptions!: QueryList<Option>;

  @ContentChild('selectedItem', { static: false }) selectedItemTemplate!: TemplateRef<ElementRef>;

  @ViewChild(Overlay) overlay!: Overlay;
  @ViewChild('overlayTarget', { static: false }) overlayTarget?: ElementRef<HTMLDivElement>;

  protected _disabled?: boolean | null = false;

  initialized$ = new ReplaySubject<void>(1);

  public searchControl = new FormControl(this.searchTerm);

  get selectedValue() {
    if (this.multiple && this.model instanceof Array) {
      return this.model
        .map((o) => {
          const option = this.__options.find((op) => isEqual(op?.value, o));
          return option?.label;
        })
        .join(', ')
        .replace(/,\s$/, '');
    }

    return this.__options.find((o) => isEqual(o.value, this.model))?.label;
  }

  get __options() {
    let options: IOption[] = [];

    if (this._options?.length) options = this._options;

    if (this.contentOptions?.length)
      options = this.contentOptions.map((o) => ({ label: o.nativeElement.innerText, value: o.value } as IOption));

    return options;
  }

  get error(): { message: Observable<string>; value: any } | null {
    const ngControl = this.injector.get(NgControl, null);

    if (!ngControl) return null;

    const errors = Object.entries(ngControl?.control?.errors || {});

    if ((!this.formGroupDirective?.submitted && ngControl.untouched) || !errors.length) {
      return null;
    }
    const [key, value] = errors[0];

    return {
      message: this.errorMessage(key),
      value
    };
  }

  get overlayPositionClass() {
    if (this.overlay?.render && this.overlay?.el?.nativeElement) {
      return (this.overlay.el.nativeElement as HTMLElement).classList.contains('ayn-overlay--center_top')
        ? 'ayn-select__overlay--position-bottom'
        : 'ayn-select__overlay--position-top';
    }
    return '';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private translateService: AynUITranslateService,
    @Inject(Injector) private injector: Injector,
    @Optional() private formGroupDirective?: FormGroupDirective
  ) {}

  ngAfterContentInit(): void {
    this.contentOptions.changes.pipe(startWith(null), untilDestroyed(this)).subscribe(() => {
      this.prepareComponent();
    });
  }

  ngAfterViewInit() {
    this.initialized$.next();

    this.formGroupDirective?.ngSubmit.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.viewOptions?.changes.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.model) {
        this.updateSelectedOption(this.model);
      }
    });

    if (this.overlay) {
      this.overlay.onHide.pipe(untilDestroyed(this)).subscribe(() => {
        this.onTouched();
      });
    }
  }

  ngOnDestroy(): void {}

  runAfterInitialize(cb: () => void) {
    this.initialized$.pipe(take(1)).subscribe(() => {
      cb.call(this);
    });
  }

  open() {
    if (this.overlayTarget) {
      this.overlay.show(null, this.overlayTarget.nativeElement);
      this.cdr.detectChanges();
    }
  }

  click(option: Option) {
    if (this.multiple) {
      const model: OptionValueType[] = (this.model as OptionValueType[]) || [];
      const hasValue = model.some((value) => value == option.value);
      let value: OptionValueType[];
      if (hasValue) {
        value = model.filter((x) => x != option.value);
      } else {
        value = [option.value, ...model];
      }
      this.writeValue(value);
    } else {
      this.writeValue(option.value);
    }
    this.notifyValueChange();

    if (this.hideOnSelectOverlay) this.overlay.hide();

    this.onTouched();
  }

  resetOptions(options?: QueryList<Option>) {
    this.runAfterInitialize(() => {
      const _options = options || this.viewOptions.length ? this.viewOptions : this.contentOptions;
      _options?.forEach((option) => (option.selected = false));
    });
  }

  notifyValueChange() {
    this.valueChange.emit(this.model);

    this.onChange(this.model);
  }

  writeValue(value: OptionValueType | OptionValueType[]) {
    if (isEqual(this.model, value)) return;

    this.resetOptions();

    this.model = value;

    this.updateSelectedOption(this.model);

    this.cdr.markForCheck();
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clickedBtn($event: MouseEvent) {
    if (this.stopPropagation) {
      $event.stopPropagation();
    }

    if (this.readonly || this._disabled) return;

    this.overlay.toggle($event);
  }

  private errorMessage(error: string): Observable<string> {
    switch (error) {
      case 'required':
        return this.translateService.translate(this.requiredMessage);
      default:
        return of('');
    }
  }

  private prepareComponent() {
    this.initContentOptions();
    this.cdr.detectChanges();
  }

  private updatePlaceholderTemplate(component: Option) {
    setTimeout(() => {
      const option = component.nativeElement.firstElementChild?.cloneNode(true) as HTMLElement;

      option?.querySelector('ayn-icon[name=check]')?.remove();

      const trustedHtml = this.sanitizer.bypassSecurityTrustHtml(option?.innerHTML || '');

      this.selectedOptionHtml = trustedHtml as string;
      this.cdr.detectChanges();
    }, 100);
  }

  private initContentOptions() {
    this.contentOptions?.forEach((o) => {
      if ((isDevMode() && typeof o.value == 'undefined') || o.value == null) {
        throw new Error('Dropdown options value is not defined.');
      }
      this.listenOptionClick(o);
      if (isEqual(o.value, this.model)) {
        this.updateSelectedOption(o);
      }
    });
  }

  private updateSelectedOption(value: Array<Option | OptionValueType> | Option | OptionValueType) {
    if (this.dontSelect) return;

    this.runAfterInitialize(() => {
      if (Array.isArray(value) && this.multiple) {
        value.forEach((v) => {
          this.updateSelectedOption(v);
        });
        return;
      }
      const option = value instanceof Option ? value : this.findOptionByValue(value);
      if (!option) {
        return;
      }
      if (this.updateValue) {
        option.viewInit.pipe(take(1)).subscribe(() => {
          option.selected = true;
          this.cdr.markForCheck();
        });
      }

      if (this.setValueHtml && this.updateValue) {
        option.viewInit.pipe(take(1)).subscribe(() => {
          this.updatePlaceholderTemplate(option);
          this.cdr.detectChanges();
        });
      }
      this.cdr.detectChanges();
    });
  }

  private findOptionByValue(value: OptionValueType) {
    const options = this.contentOptions?.length ? this.contentOptions : this.viewOptions;

    return Array.from(options || [])?.find((o) => isEqual(o.value, value));
  }

  private listenOptionClick(option: Option) {
    option.$click.pipe(takeUntil(this.contentOptions.changes)).subscribe((optionComponent: Option) => {
      if (!!optionComponent) {
        this.click(optionComponent);
      }
    });
  }
}
