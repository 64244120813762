<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__filled':
      adCreationModel.audience.create.includedLocations.length ||
      adCreationModel.audience.create.excludedLocations.length,
    'aayn-audience--location-selection__active': overlay?.render
  }"
>
  <aayn-location-selection-selected-items
    type="inclusions"
    *ngIf="adCreationModel.audience.create.includedLocations.length"
  >
    <aayn-location-selection-selected-item
      *ngFor="let include of adCreationModel.audience.create.includedLocations; let index = index"
    >
      <ayn-badge
        color="primary"
        [text]="include.fullName"
        icon="map-marker-dot"
        (click)="removeItem(index, '_inclusions')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <aayn-location-selection-selected-items
    type="exclusions"
    *ngIf="adCreationModel.audience.create.excludedLocations.length"
  >
    <aayn-location-selection-selected-item
      *ngFor="let exclude of adCreationModel.audience.create.excludedLocations; let index = index"
    >
      <ayn-badge
        color="orange"
        [text]="exclude.fullName"
        icon="map-marker-dot"
        (click)="removeItem(index, '_exclusions')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      ayn-input
      [required]="!adCreationModel.audience.create.includedLocations.length"
      [placeholder]="'Type the search' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />
    <ayn-icon name="map-marker"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length || geoLocations?.length"
  >
    <aayn-location-selection-overlay-list *ayn-loader="loader$ | async">
      <aayn-location-selection-overlay-item
        *ngFor="let geoLocation of geoLocations"
        [type]="geoLocation.type"
        (onIncludeClick)="onIncludeClick(geoLocation)"
        (onExcludeClick)="onExcludeClick(geoLocation)"
        (onIncludeRemove)="onIncludeRemove(geoLocation)"
        (onExcludeRemove)="onExcludeRemove(geoLocation)"
        [active]="isActive(geoLocation)"
      >
        {{ geoLocation.fullName }}
      </aayn-location-selection-overlay-item>
    </aayn-location-selection-overlay-list>
  </ayn-overlay>
</section>
