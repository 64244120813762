<aayn-ad-preview-card [selectedPlatform]="Platforms.Google" [platforms]="platforms">
  <aayn-ad-preview-google-tabs
    [availableTabs]="['display', 'search', 'youtube']"
    [title]="googleAdPreviewService.performanceMax.headline || ''"
    [titles]="googleAdPreviewService.performanceMax.headlines || []"
    [linkUrl]="googleAdPreviewService.performanceMax.url || ''"
    [cta]="googleAdPreviewService.performanceMax.callToAction || ''"
    [description]="googleAdPreviewService.performanceMax.description || ''"
    [image]="googleAdPreviewService.performanceMax.imageUrls?.[0] || ''"
    [logoUrl]="googleAdPreviewService.performanceMax.logoUrl || ''"
  >
  </aayn-ad-preview-google-tabs>
</aayn-ad-preview-card>
