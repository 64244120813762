import { makeMutation, makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';

type Output = Linkedin.CreateAd.GetApplicationCampaignGroupIdResponse;
export const getApplicationCampaignGroupId = makeQuery<Output, void>(`
query GetApplicationCampaignGroupId{
    linkedin{
        getApplicationCampaignGroupId
    }
}
`);
