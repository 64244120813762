<ng-container *ayn-loader="!!(loader$ | async); maxWidth: 75">
  <form [formGroup]="pageSelectForm">
    <ayn-table
      *ngIf="selectedPageAccount"
      [showPagination]="false"
      class="aayn-account-select-modal--stepper__body--table table-selected"
      type="primary"
    >
      <ng-template #header>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </ng-template>
      <ng-template #body>
        <ng-container>
          <tr>
            <td class="img">
              <img [src]="selectedPageAccount.imageUrl" [alt]="selectedPageAccount.name" />
            </td>
            <td class="title">
              {{ selectedPageAccount.name }}
            </td>
            <td class="table-account">
              <ayn-form-field>
                <ayn-select
                  formControlName="account"
                  [showTick]="true"
                  [placeholder]="'Select Account'"
                  [showSearch]="false"
                  containerClassName="account__select"
                  [options]="socialAccounts"
                  (ngModelChange)="onSocialAccountChange.emit($event)"
                >
                </ayn-select>
              </ayn-form-field>
            </td>
            <td>
              {{ selectedPageAccount.category }}
            </td>
            <td class="select">
              <button
                class="select-btn"
                ayn-button="accent"
                (click)="removeSelectPage()"
                ayn-button-block
                type="submit"
              >
                {{ 'Selected' | translate }}
              </button>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </ayn-table>
  </form>
  <ng-container *ngIf="pages.length; else noContent">
    <ng-container *ngIf="pages | aynFilterNotEqual: { id: selectedPageAccount?.id || '' } as list">
      <cdk-virtual-scroll-viewport style="height: 350px" *ngIf="list.length">
        <ayn-table [showPagination]="false" class="aayn-account-select-modal--stepper__body--table" type="primary">
          <ng-template #header>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
          </ng-template>

          <ng-template #body>
            <tr *ngFor="let item of list">
              <td class="img">
                <img [src]="item.imageUrl" [alt]="item.name" />
              </td>
              <td class="title">
                {{ item.name }}
              </td>
              <td class="table-account">{{ 'Select the Page First' | translate }}</td>
              <td>
                {{ item.category }}
              </td>
              <td class="select">
                <button
                  class="select-btn"
                  ayn-button="primary"
                  ayn-button-block
                  (click)="selectPage(item)"
                  type="submit"
                >
                  {{ getButtonName(item) }}
                </button>
              </td>
            </tr>
          </ng-template>
        </ayn-table>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noContent>
  <ayn-table [showPagination]="false" class="aayn-account-select-modal--stepper__body--table" type="primary">
    <ng-template #header>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-template>

    <ng-template #body>
      <ng-container>
        <tr>
          <td colspan="5">
            <aayn-empty-content
              [showButton]="false"
              [title]="'No Records Found' | translate"
              description="{{ 'Please try again.' | translate }}"
            ></aayn-empty-content>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </ayn-table>
</ng-template>

<ng-template #headerTemplate>
  <tr>
    <th>#</th>
    <th>{{ 'Facebook Page' | translate }}</th>
    <th>{{ 'Instagram Account' | translate }}</th>
    <th>{{ 'Page Type' | translate }}</th>
    <th></th>
  </tr>
</ng-template>
