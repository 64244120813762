import { AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { AdPlatforms, PlatformConfig, PlatformIcons, Platforms, SmartCampaign } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LinkedinBudgetCampaign, PlatformBudgetService } from '@core/services/platform-budget.service';
import { IconModule, InputModule, PipesModule, RadioModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { filterNotNil } from '@core/utils';

@UntilDestroy()
@Component({
  selector: 'aayn-smart-campaign-budget',
  templateUrl: './platform-budget.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartCampaignPlatformBudgetComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SmartCampaignPlatformBudgetComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [RadioModule, TranslateModule, PipesModule, InputModule, ReactiveFormsModule, IconModule, NgIf]
})
export class SmartCampaignPlatformBudgetComponent
  implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, Validator
{
  form = new FormGroup({
    amount: new FormControl(0, Validators.required),
    type: new FormControl<SmartCampaign.Client.SmartCampaignBudgetType>(
      SmartCampaign.Client.SmartCampaignBudgetType.Daily,
      Validators.required
    )
  });

  @Input() currency?: string;

  @Input() platform!: AdPlatforms;

  @Input() campaign?: LinkedinBudgetCampaign;

  @Input() isHorizontal = false;

  Platforms = Platforms;

  PlatformIcons = PlatformIcons;

  BudgetType = SmartCampaign.Client.SmartCampaignBudgetType;

  onChangeFn!: (val: typeof this.form.value) => void;

  constructor(private platformBudgetService: PlatformBudgetService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campaign) {
      this.platformValidations();
    }
  }

  ngOnInit(): void {
    this.currency = this.platformBudgetService.getCurrency(this.platform);
  }

  ngAfterViewInit() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.onChangeFn(val);
    });
    this.platformValidations();
  }

  writeValue(obj: any) {
    this.form.patchValue(obj);
  }

  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {}

  validate(_: AbstractControl): ValidationErrors | null {
    return this.form.errors;
  }

  private platformValidations() {
    this.platformBudgetService
      .getMinimumBudget(this.platform, this.form.value.type!, this.campaign)
      .pipe(filterNotNil)
      .subscribe((val) => {
        if (!this.form.value.amount) {
          this.form.controls.amount.setValue(val.budget);
        }
        this.form.controls.amount.addValidators(Validators.min(val.budget));
        this.form.updateValueAndValidity();
      });
  }

  getPlatformName(platform: Platforms) {
    return PlatformConfig.find((x) => x.value === platform)?.label;
  }
}
