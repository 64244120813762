<aayn-modal-for-payments
  icon="circle-arrow-down"
  title="You are downgrading!"
  description="You are downgrading your plan. Your new {{ isMonthly ? 'monthly' : 'yearly' }} amount is shown below."
  color="var(--orange)"
>
  <div class="aayn-payment-price-table">
    <div class="aayn-payment-price-table--row">
      <div class="aayn-payment-price-table--col">Description</div>
      <div class="aayn-payment-price-table--col">Amount</div>
    </div>

    <div class="aayn-payment-price-table--inner" *ngIf="oldPackage">
      <div class="aayn-payment-price-table--row">
        <div class="aayn-payment-price-table--col">
          <span class=":from">
            {{ oldPackage.displayName }}
            <ayn-icon name="chevron-right"></ayn-icon>
          </span>

          <span class=":to">{{ newPackage.displayName }}</span>
        </div>
        <div class="aayn-payment-price-table--col">
          <span class=":from">
            {{ oldPackage.price | currency: oldPackage.currencyCode }}
            <ayn-icon name="chevron-right"></ayn-icon>
          </span>

          <span class=":to">{{ newPackage.price | currency: newPackage.currencyCode }}</span>
        </div>
      </div>

      <div class="aayn-payment-price-table--row" *ngIf="newPackage.discountPrice">
        <div class="aayn-payment-price-table--col">Total Discount</div>
        <div class="aayn-payment-price-table--col">
          {{ newPackage.discountPrice | currency: newPackage.currencyCode }}
        </div>
      </div>

      <div class="aayn-payment-price-table--row color:primary">
        <div class="aayn-payment-price-table--col">Final Amount</div>
        <div class="aayn-payment-price-table--col">
          {{ newPackage.discountAppliedPrice | currency: newPackage.currencyCode }}
        </div>
      </div>
    </div>
  </div>

  <button
    ayn-button="orange"
    icon="circle-chevron-right"
    (click)="downgradeNow()"
    [disabled]="loader$ | async"
    [loading]="loader$ | async"
  >
    Downgrade
  </button>
</aayn-modal-for-payments>
