import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ISubmitCustomOfferRequest } from '@core/models';
import { ChargebeeService } from '@core/services';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';

@Component({
  selector: 'aayn-request-custom-offer',
  templateUrl: 'request-custom-offer.component.html'
})
export class RequestCustomOfferComponent implements OnInit {
  protected form = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    companyName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    website: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.max(50)])
  });

  @Select(LoaderState.getAny('SubmitCustomOffer')) loader$?: Observable<boolean>;

  constructor(
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService,
    private activemodal: NgbActiveModal
  ) {}

  ngOnInit() {}

  submit() {
    if (this.form.invalid) return;

    this.chargebeeService.submitCustomOffer$(this.form.value as ISubmitCustomOfferRequest).subscribe((result) => {
      if (result.data) {
        this.toastrService.success(
          'Your offer has been successfully sent to us. Return will be made as soon as possible.'
        );

        this.activemodal.dismiss();
      } else {
        this.toastrService.error('An error occurred while submitting the form. Please try again later.');
      }
    });
  }
}
