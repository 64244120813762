import { BiPredicate } from '@core/models/function';
import { Eq } from 'fp-ts/Eq';
import { getMonoid, Ord } from 'fp-ts/Ord';
import { concatAll } from 'fp-ts/Monoid';
import { Comparer } from '@core/models/ord';
import { toOrdWithEquality } from './to-ord';

/**
 * Creates order generator with equality function
 *
 * @param {(BiPredicate<T> | Eq<T>)} equalsFn The equals function
 */
export function concatAllWithEquality<T>(equalsFn: BiPredicate<T> | Eq<T>) {
  /**
   * Concats Ord and Comparer functions.
   *
   * @param {(Array<Ord<T> | Comparer<T>>)} ordList
   */
  return function _concatAllWithEquality(ordList: Array<Ord<T> | Comparer<T>>): Ord<T> {
    const monoid = getMonoid<T>();
    const concatFn = concatAll(monoid);
    return concatFn(ordList.map(toOrdWithEquality(equalsFn)));
  };
}
