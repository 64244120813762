<section class="aayn-instant-ad--describe-project">
  <form class="aayn-instant-ad--describe-project-scan--form" [formGroup]="form" standAloneForm>
    <h3 class="aayn-instant-ad--describe-project-scan--form_title">
      {{ 'Scan from website.' | translate }}
    </h3>
    <p class="aayn-instant-ad--describe-project-scan--form_description">
      {{ 'Let our AI scan your website to learn more about what you advertise.' | translate }}
    </p>

    <div class="aayn-instant-ad--describe-project-scan--form-row">
      <ayn-form-field>
        <div class="ayn-prefix">
          {{ 'URL' | translate }}
        </div>
        <input
          type="text"
          formControlName="websiteUrl"
          placeholder="https://www.adyouneed.com"
          ayn-input
          aaynControlTriggerSubmit
          aaynUrlInput
          submitEvent="keydown:enter"
          (keydown.enter)="scan()"
          id="url"
        />
        <ayn-icon
          name="cross-mini"
          class="ayn-instant-ad--describe-project-scan--cancel-button"
          *ngIf="loader$ | async"
          (click)="cancelGetProductDescriptionCall()"
        ></ayn-icon>
      </ayn-form-field>

      <button
        type="submit"
        ayn-button="primary"
        class="aayn-instant-ad--describe-project-scan--form-button"
        [width]="220"
        ayn-button-block
        style="--skeleton-border-radius: 28px"
        [loading]="loader$ | async"
        loadingColor="dark"
        (click)="scan()"
      >
        <span> {{ 'Scan website' | translate }}</span>
      </button>
    </div>
  </form>

  <aayn-collapse-card
    #collapseCardComponent
    title="Scan you website or enter required content manually"
    [active]="false"
    description="Scan your website to have our AI automatically fill in the brief, or you can enter it manually."
    class="aayn-instant-ad--describe-project--collapse"
    collapseIconDirection="right"
    headerIcon="pen-bold"
    *ngIf="{ loader: loader$ | async } as data"
  >
    <form
      class="aayn-instant-ad--describe-project--collapse-content-form"
      [formGroup]="manualForm"
      (ngSubmit)="onSubmit()"
      #_form="ngForm"
    >
      <div class="aayn-instant-ad--describe-project--collapse-content-form_row">
        <ayn-form-field [ayn-skeleton]="data.loader">
          <input
            type="text"
            ayn-input
            formControlName="targetAudience"
            [placeholder]="'Your Target Audience (Example: Advertisers and Social Marketing Manager..)' | translate"
          />
        </ayn-form-field>
        <ayn-form-field [ayn-skeleton]="data.loader">
          <input
            type="text"
            formControlName="cta"
            ayn-input
            [placeholder]="'Your Call to Action (Example: Sign up now!)' | translate"
          />
        </ayn-form-field>
      </div>
      <div class="aayn-instant-ad--describe-project--collapse-content-form_row">
        <ayn-form-field [ayn-skeleton]="data.loader">
          <input
            type="text"
            formControlName="productName"
            ayn-input
            [placeholder]="'Product Name (Example: ADYOUNEED)' | translate"
          />
        </ayn-form-field>

        <ayn-form-field [ayn-skeleton]="data.loader">
          <ayn-select
            formControlName="language"
            [disabled]="!!data.loader"
            [minWidth]="120"
            [showTick]="true"
            [placeholder]="'Output Language' | translate"
            [setValueHtml]="true"
          >
            <ayn-option *ngFor="let item of outputLanguages | aynOptionFilter | async" [value]="item.value">
              <img [src]="'/assets/images/svgs/languages/' + item.flag + '.svg'" />
              {{ item.label | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>
      </div>
      <div class="aayn-instant-ad--describe-project--collapse-content-form_row">
        <ayn-form-field [ayn-skeleton]="data.loader">
          <ayn-select
            [minWidth]="120"
            [showTick]="true"
            [disabled]="!!data.loader"
            [placeholder]="'Choose a tone' | translate"
            [setValueHtml]="true"
            formControlName="tone"
          >
            <ayn-option *ngFor="let chooseTone of chooseTones | aynOptionFilter | async" [value]="chooseTone.value">
              <ayn-icon [name]="chooseTone.value.toLowerCase()"></ayn-icon>
              {{ chooseTone.label | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>
        <ayn-form-field>
          <input
            type="text"
            ayn-input
            formControlName="customRequests"
            [placeholder]="
              'Custom Requests (Mention our patented technology; don’t use certain words, etc…)' | translate
            "
          />
        </ayn-form-field>
      </div>
      <div class="aayn-instant-ad--describe-project--collapse-content-form_row">
        <ayn-form-field [ayn-skeleton]="data.loader">
          <textarea
            type="text"
            ayn-input
            class="text-area"
            [placeholder]="
              'Product/Service Description (Example: The marketing platform that helps you create and optimize ads in minutes so you can focus on what really matters - your business.)'
                | translate
            "
            formControlName="productDescription"
            rows="3"
            [disabled]="!!(loader$ | async)"
          ></textarea>
        </ayn-form-field>
      </div>
    </form>
    <div class="aayn-instant-ad--describe-project--collapse-content-creatives">
      <div class="has-divider">
        <span class="fw-semibold">{{ 'Manual Creative Setup' | translate }} </span>
        <div class="divider"></div>
      </div>

      <div class="aayn-instant-ad--describe-project--collapse-content-creatives--upload">
        <div
          class="aayn-instant-ad--describe-project--collapse-content-creatives--upload--item"
          [class.selected]="uploadedImages[index]?.selected"
          *ngFor="let file of images; let index = index"
          (click)="selectFile(index)"
        >
          <aayn-file-upload-item
            [ayn-skeleton]="(loader$ | async) && !uploadedImages[index]?.isCustom"
            [isPrimary]="false"
            [class.filled]="!!uploadedImages[index]?.url"
            [isError]="_form.submitted && !uploadedImages[index]?.url && selectedImages.length < 1 && isFormChanged()"
            (fileAdded)="onFileAdded($event)"
            [selectedFileUrl]="uploadedImages[index]?.url"
            [maxImageSize]="{ width: 1024, height: 1024 }"
            [canRemoveImage]="false"
            [progressItem]="file"
            [canvaVisible]="false"
            [disabled]="!!(loader$ | async) && !uploadedImages[index]?.isCustom"
          >
            <div class="aayn-file-upload-item--custom-content">
              <div
                class="aayn-file-upload-item--custom-content__tooltip"
                *ngIf="_form.submitted && isEmptyImageInvalid(index)"
              >
                <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
              </div>
              <ayn-icon name="upload-icon-outline"></ayn-icon>
              <h3>Upload your images</h3>
              <p>or drag & drop here.</p>
            </div>
            <div
              class="aayn-file-upload-item--selected-custom-content"
              (click)="remove($event, index)"
              (mouseenter)="hoverIndex = index"
              (mouseleave)="hoverIndex = -1"
            >
              <div
                class="aayn-file-upload-item--custom-content__tooltip"
                *ngIf="_form.submitted && isFilledImageInvalid(index)"
              >
                <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
              </div>
              <div class="aayn-file-upload-item--selected-custom-content__delete">
                <ayn-icon
                  [name]="hoverIndex === index ? 'trash-flat-rounded-close--filled' : 'trash-flat-rounded-close'"
                ></ayn-icon>
              </div>
            </div>
          </aayn-file-upload-item>
          <div
            *ngIf="uploadedImages[index]?.selected"
            class="aayn-instant-ad--describe-project--collapse-content-creatives--upload--item_selected-content"
          >
            <div class="selected-text">{{ 'Selected' | translate }}</div>
            <ayn-icon name="circle-filled-check"></ayn-icon>
          </div>
        </div>
      </div>
    </div>
  </aayn-collapse-card>
</section>
<ng-template #tooltipTemplate>
  <ayn-icon
    name="info"
    [ayn-tooltip]="'You must select at least 1 image' | aynTranslate | async"
    [dismissible]="false"
    position="right"
    tooltipClass="ayn-file-upload--tooltip"
    zIndex="998"
    [calculatePosition$]="calculateTooltipPosition"
  ></ayn-icon>
</ng-template>
