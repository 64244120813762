<form [formGroup]="form!" *ngIf="form">
  <aayn-ad-form-input label="Bing" platform="bing">
    <ayn-form-field>
      <ayn-select
        [placeholder]="'Use Default Account Conversion' | translate"
        (searchControlChange)="searchValueChange()"
        (valueChange)="valueChange($event)"
        [loading]="!!(loader$ | async)"
        [options]="conversionEvents"
        [multiple]="true"
        class="aayn-create-ad--select-v1"
      >
      </ayn-select>
    </ayn-form-field>
  </aayn-ad-form-input>
</form>
