import { pipe } from 'fp-ts/lib/function';
import { cloneDeep, omit } from 'lodash';

import { IOption } from '@ayn-ui/public-api';
import { LinkedinCreateAd } from '@core/ad-platforms/linkedin/create-ad';
import { environment } from '@environment';
import { ICreateAdSelectItem } from '@pages/create-ad/models';

import { Base } from './base';
import { CursorPaginationArgs, OffsetPaginationArgs } from '@core/models/base.model';

export module Linkedin {
  export interface DateRange {
    start: Date;
    end: Date;
  }

  export interface IdWithUrl {
    id: ImageUrn;
    url: string;
  }

  export type Urn<T extends string = string> = `urn:li:${T}`;
  export type SponsoredCampaignUrn = Urn<`sponsoredCampaign:${number}`>;
  export enum FacetName {
    Degrees = 'degrees',
    Interests = 'interests',
    GrowthRate = 'growthRate',
    Employers = 'employers',
    EmployersPast = 'employersPast',
    EmployersAll = 'employersAll',
    AgeRanges = 'ageRanges',
    StaffCountRanges = 'staffCountRanges',
    Skills = 'skills',
    Locations = 'locations',
    DynamicSegments = 'dynamicSegments',
    TitlesPast = 'titlesPast',
    InterfaceLocales = 'interfaceLocales',
    JobFunctions = 'jobFunctions',
    YearsOfExperienceRanges = 'yearsOfExperienceRanges',
    Industries = 'industries',
    MemberBehaviors = 'memberBehaviors',
    Genders = 'genders',
    Schools = 'schools',
    Groups = 'groups',
    FieldsOfStudy = 'fieldsOfStudy',
    TitlesAll = 'titlesAll',
    ProfileLocations = 'profileLocations',
    CompanyCategory = 'companyCategory',
    Titles = 'titles',
    FollowedCompanies = 'followedCompanies',
    AudienceMatchingSegments = 'audienceMatchingSegments',
    FirstDegreeConnectionCompanies = 'firstDegreeConnectionCompanies',
    Seniorities = 'seniorities',
    Revenue = 'revenue'
  }

  export enum ConversionType {
    AddToCart = 'ADD_TO_CART',
    Download = 'DOWNLOAD',
    Install = 'INSTALL',
    KeyPageView = 'KEY_PAGE_VIEW',
    Lead = 'LEAD',
    Purchase = 'PURCHASE',
    SignUp = 'SIGN_UP',
    Other = 'OTHER'
  }

  interface LinkedInConversionValue {
    amount: string;
    currencyCode: string;
  }
  export enum ConversionAttributionType {
    LastTouchByCampaign = 'LAST_TOUCH_BY_CAMPAIGN',
    LastTouchByConversion = 'LAST_TOUCH_BY_CONVERSION'
  }

  export enum UrlMatchRuleMatchType {
    Exact = 'EXACT',
    StartsWith = 'STARTS_WITH',
    Contains = 'CONTAINS'
  }

  export interface LinkedInConversionAssociatedCampaigns {
    campaign: string;
    associatedAt: number;
    conversion: string;
  }

  export interface LinkedInConversionUrlMatchRuleExpression {
    matchType: UrlMatchRuleMatchType;
    matchValue: string;
  }

  export interface LinkedInConversion {
    account: string;
    associatedCampaigns: LinkedInConversionAssociatedCampaigns[];
    attributionType: ConversionAttributionType;
    enabled: boolean;
    id: number;
    imagePixelTag?: string;
    lastCallbackAt: number;
    latestFirstPartyCallbackAt: number;
    name: string;
    postClickAttributionWindowSize: number;
    type: ConversionType;
    urlMatchRuleExpression: Array<Array<LinkedInConversionUrlMatchRuleExpression>>;
    value: LinkedInConversionValue;
    viewThroughAttributionWindowSize: number;
  }

  export interface FindConversionsByAccountResponse {
    findConversionsByAccount: {
      edges: [{ node: LinkedInConversion; cursor: string }];
    };
  }
  export type SponsoredCampaignGroupUrn = Urn<`sponsoredCampaignGroup:${number}`>;
  export type SponsoredCreativeUrn = Urn<`sponsoredCreative:${number}`>;

  export type SponsoredAdAccountUrn = Urn<`sponsoredAccount:${number}`>;

  export type AdTargetingFacetUrn = Urn<`adTargetingFacet:${FacetName}`>;

  export interface LinkedInCarouselCreativeInputModel {
    cards: CarouselCreativeInputModelCards[];
    commentary: string;
    campaign: string;
    contentLandingPage: string;
  }

  export interface CarouselCreativeInputModelCards {
    media: CarouselCreativeInputModelCardsMedia;
    langingPage: string;
  }

  export interface CarouselCreativeInputModelCardsMedia {
    id: string;
    title: string;
    altText?: string;
  }

  export interface AdModelSingleImage {
    altText: string;
    campaign: string;
    commentary: string;
    ctaLabel: string;
    imageIds: string[];
    images: Linkedin.IdWithUrl[];
    title: string;
    contentLandingPage?: string;
    //TODO: linkedin: send name field to backend
    name?: string;
  }
  export type ImageUrn = Urn<`image:${string}`>;

  export interface AdModelCarouselImage {
    campaign: string;
    contentLandingPage?: string;
    //TODO: linkedin: send name field to backend
    name?: string;
    commentary: string;
    carouselCards: {
      title: string;
      id: ImageUrn;
      altText?: string;
      imageUrl: string;
    }[];
  }

  export type AdTypeModels = AdModelSingleImage | AdModelCarouselImage | LinkedInCarouselCreativeInputModel;

  export interface IPageInfo {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    from: number;
    to: number;
  }

  export interface OffsetPageInfo {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    from: number;
    to: number;
  }

  export enum LinkedInAdAccountStatus {
    Active = 'Active',
    Canceled = 'Canceled',
    Draft = 'Draft',
    Removed = 'Removed',
    PendingDeletion = 'PendingDeletion'
  }

  export enum PlatformEnum {
    Unset,
    Facebook,
    Google,
    LinkedIn,
    Bing
  }

  export enum ICampaignStatus {
    ACTIVE = 'ACTIVE',
    REMOVED = 'REMOVED',
    PAUSED = 'PAUSED'
  }

  export enum LinkedInTimeGranularity {
    All = 'All',
    Daily = 'Daily',
    Monthly = 'Monthly',
    Yearly = 'Yearly'
  }

  export const parseIntervalToTimeGranularity = (
    interval: Base.Dashboard.Global.InsightInterval
  ): LinkedInTimeGranularity => {
    switch (interval) {
      case Base.Dashboard.Global.InsightInterval.Date:
        return LinkedInTimeGranularity.Daily;
      case Base.Dashboard.Global.InsightInterval.Month:
        return LinkedInTimeGranularity.Monthly;
      default:
        return LinkedInTimeGranularity.All;
    }
  };

  export enum LinkedInInsightType {
    Account = 'Account',
    Campaign = 'Campaign',
    Company = 'Company',
    Conversion = 'Conversion',
    MemberCompanySize = 'MemberCompanySize',
    MemberIndustry = 'MemberIndustry',
    MemberSeniority = 'MemberSeniority',
    MemberJobTitle = 'MemberJobTitle',
    MemberJobFunction = 'MemberJobFunction',
    MemberCompany = 'MemberCompany'
  }

  export enum AccountOverviewType {
    Clicks = 'clicks',
    Impressions = 'impressions',
    OneClickLeadFormOpens = 'oneClickLeadFormOpens',
    OneClickLeads = 'oneClickLeads',
    Reactions = 'reactions',
    Registrations = 'registrations',
    DownloadClicks = 'downloadClicks',
    CostInLocalCurrency = 'costInLocalCurrency',
    Comments = 'comments',
    CompanyPageClicks = 'companyPageClicks'
  }

  export enum AdType {
    SingleImage = 'SINGLE_IMAGE',
    CarouselImage = 'CAROUSEL_IMAGE'
  }

  export enum AudienceType {
    Manual = 'MANUAL',
    AiAudienceFinder = 'AI_AUDIENCE_FINDER',
    Retargeting = 'RETARGETING'
  }

  export enum LinkedInCostType {
    CPM = 'CPM',
    CPC = 'CPC',
    CPV = 'CPV'
  }
  export enum LinkedInCreativeSelection {
    Optimized = 'Optimized',
    RoundRobin = 'RoundRobin'
  }

  export interface LinkedInCampaignCreationModelDailyBudget {
    amount: string;
    currencyCode: string;
  }

  export interface LinkedInCampaignCreationModelRunSchedule {
    start: number;
    end?: number | null;
  }

  export interface LinkedInCampaignCreationModelUnitCost {
    amount: string | number;
    currencyCode?: string;
  }

  export interface LinkedInCampaignCreationModelTargetingCriteria {
    include: { [key: string]: Array<any> };
    exclude?: { [key: string]: Array<any> };
  }
  export type FacetNameString = `${FacetName}`;

  export interface ITargetingCriteriaOrRule {
    or?: ITargetingCriteriaCondition;
  }

  export type ITargetingCriteriaCondition = {
    [key in IFacetUrn]?: string[];
  };

  export interface ITargetingCriteriaAndRule {
    and: [ITargetingCriteriaOrRule, ...ITargetingCriteriaOrRule[]] | ITargetingCriteriaOrRule[];
  }
  export type ITargetingCriteria = {
    include: ITargetingCriteriaAndRule;
    exclude?: ITargetingCriteriaOrRule;
  };

  export type IFacetUrn<TKey extends FacetName | FacetNameString = FacetNameString> = Urn<`adTargetingFacet:${TKey}`>;

  export interface LinkedInCampaignCreationModelLocale {
    language: string;
    country: string;
  }

  export enum LinkedInCampaignObjectiveType {
    BRAND_AWARENESS = 'BrandAwareness',
    CREATIVE_ENGAGEMENT = 'CreativeEngagement',
    ENGAGEMENT = 'Engagement',
    JOB_APPLICANT = 'JobApplicant',
    LEAD_GENERATION = 'LeadGeneration',
    TALENT_LEAD = 'TalentLead',
    WEBSITE_CONVERSION = 'WebsiteConversion',
    WEBSITE_VISIT = 'WebsiteVisit',
    VIDEO_VIEW = 'VideoView'
  }

  export enum LinkedInCampaignType {
    TEXT_AD = 'TextAd',
    SPONSORED_UPDATES = 'SponsoredUpdates',
    SPONSORED_INMAILS = 'SponsoredInMails',
    DYNAMIC = 'Dynamic'
  }

  export enum LinkedInCampaignStatus {
    Active = 'Active',
    Paused = 'Paused',
    Archived = 'Archived',
    Completed = 'Completed',
    Canceled = 'Canceled',
    Draft = 'Draft'
  }

  export interface LinkedInCampaignCreationModel {
    account: string;
    audienceExpansionEnabled: boolean;
    campaignGroup?: string;
    creativeSelection: LinkedInCreativeSelection;
    costType: LinkedInCostType;
    dailyBudget?: LinkedInCampaignCreationModelDailyBudget;
    locale: LinkedInCampaignCreationModelLocale;
    runSchedule: LinkedInCampaignCreationModelRunSchedule;
    status: LinkedInCampaignStatus;
    name: string;
    pacingStrategy?: string | 'LIFETIME';
    objectiveType: LinkedInCampaignObjectiveType;
    offsiteDeliveryEnabled: boolean;
    optimizationTargetType: OptimizationTargetType;
    targetingCriteria: LinkedInCampaignCreationModelTargetingCriteria;
    totalBudget?: LinkedInCampaignCreationModelDailyBudget;
    type: LinkedInCampaignType;
    unitCost?: LinkedInCampaignCreationModelUnitCost;
    //Added for ui
    budgetType?: 'daily' | 'total';
    conversionType?: LinkedInConversion;
  }

  export enum OptimizationTargetType {
    NONE = 'None',
    ENHANCED_CONVERSION = 'EnhancedConversion',
    MAX_IMPRESSION = 'MaxImpression',
    MAX_CLICK = 'MaxClick',
    MAX_CONVERSION = 'MaxConversion',
    MAX_VIDEO_VIEW = 'MaxVideoView',
    MAX_LEAD = 'MaxLead',
    MAX_REACH = 'MaxReach',
    TARGET_COST_PER_CLICK = 'TargetCostPerClick',
    TARGET_COST_PER_IMPRESSION = 'TargetCostPerImpression',
    TARGET_COST_PER_VIDEO_VIEW = 'TargetCostPerVideoView'
  }

  export enum CtaLabelEnum {
    APPLY,
    DOWNLOAD,
    VIEW_QUOTE,
    LEARN_MORE,
    SIGN_UP,
    SUBSCRIBE,
    REGISTER,
    JOIN,
    ATTEND,
    REQUEST_DEMO,
    SEE_MORE
  }

  export interface LinkedInAdBudgetPricingResponse {
    getSuggestedBudget: {
      edges: {
        node: LinkedInAdBudgetPricing;
      }[];
    };
  }

  export interface AssociateCampaignWithConversionDto {
    conversion: string;
    campaign: string;
  }

  export interface LinkedInAdBudgetPricing {
    bidLimits: AdBudgetPricingBidLimits;
    suggestedBid: BudgetHasDefault;
    dailyBudgetLimits: BudgetHasDefault;
  }

  interface LinkedInAdBudgetPricing_BidLimit {
    amount: string;
    currencyCode: string;
  }

  interface BudgetHasDefault extends AdBudgetPricingBidLimits {
    default: LinkedInAdBudgetPricing_BidLimit;
  }

  interface AdBudgetPricingBidLimits {
    min: LinkedInAdBudgetPricing_BidLimit;
    max: LinkedInAdBudgetPricing_BidLimit;
  }

  export enum AdBudgetingMatchType {
    Exact = 'Exact',
    AudienceExpanded = 'Audience_Expanded'
  }

  export interface LinkedInFindPricingInsightModel {
    campaignType: LinkedInCampaignType;
    account: string;
    bidType: LinkedInCostType;
    currency?: string;
    countryCode?: string;
    matchType: AdBudgetingMatchType;
    targetingCriteria: LinkedInCampaignCreationModelTargetingCriteria;
    dailyBudget?: LinkedInCampaignCreationModelDailyBudget;
    objectiveType?: LinkedInCampaignObjectiveType;
    optimizationTargetType?: OptimizationTargetType;
  }

  export const CTA_LABELS = Object.keys(CtaLabelEnum).filter((val) => isNaN(Number(val)));

  export interface LinkedInCreateImageModel {
    altText?: string;
    campaign: string;
    commentary: string;
    ctaLabel: string;
    imageId: string;
    title: string;
    contentLandingPage?: string;
  }

  export interface CreateCustomAudienceDto {
    account: SponsoredAdAccountUrn;
    name: string;
    description?: string;
    targetingCriteria: LinkedInCampaignCreationModelTargetingCriteria;
  }

  export function mapDraftCampaignToOriginalModel<T extends AdTypeModels>(model: LinkedinCreateAd.ICreateAdModel<T>) {
    return pipe(model, cloneDeep, (model) => {
      if (environment.production) {
        model.campaign.status = Linkedin.LinkedInCampaignStatus.Active;
      }
      if (model.campaign.budgetType === 'daily') {
        model.campaign = { ...omit(model.campaign, 'totalBudget') };
      } else {
        model.campaign = { ...omit(model.campaign, 'dailyBudget') };
      }
      model.campaign.targetingCriteria = getTargetingModel(model);

      if (!model.campaign.runSchedule.end) {
        model.campaign.runSchedule = { ...omit(model.campaign.runSchedule, 'end') };
      }
      if (model.campaign.budgetType === 'daily' && !model.campaign.runSchedule.end) {
        model.campaign.pacingStrategy = 'LIFETIME';
      } else {
        model.campaign = { ...omit(model.campaign, 'pacingStrategy') };
      }
      model.campaign = { ...omit(model.campaign, ['unitCost', 'budgetType']) };

      if (
        model.campaign.objectiveType === LinkedInCampaignObjectiveType.WEBSITE_CONVERSION &&
        model.campaign.conversionType
      ) {
      }
      return model;
    });
  }

  export const toLinkedinConversionURN = (conversion: LinkedInConversion) =>
    `urn:lla:llaPartnerConversion:${conversion.id}`;

  export const getTargetingModel = (model: Pick<LinkedinCreateAd.ICreateAdModel, 'audience'>) => {
    const genderUrns = { include: parseGendersToUrn(model.audience.gender.include) };
    const geoLocationUrns = {
      include: model.audience.geoLocations.include.map((geo) => geo.node.urn),
      exclude: model.audience.geoLocations.exclude.map((geo) => geo.node.urn)
    };

    const facetMap = {
      organization: FacetName.Employers,
      degree: FacetName.Degrees,
      title: FacetName.TitlesAll,
      interest: Linkedin.FacetName.Interests
    };

    const parseUrn = (facets: any[] = []) => {
      return facets.reduce((acc, facet) => {
        const key = facet.node.urn.split(':')[2];
        return {
          ...acc,
          [facetMap[key]]: [...(acc[facetMap[key]] || []), facet.node.urn]
        };
      }, {});
    };

    const detailedTargetingUrns = {
      include: parseUrn(model.audience.detailedTargetings.include),
      exclude: parseUrn(model.audience.detailedTargetings.exclude)
    };

    const exclude = {
      ...(geoLocationUrns.exclude.length ? { locations: geoLocationUrns.exclude } : {}),
      ...detailedTargetingUrns.exclude
    };
    const interfaceLocales = model.audience.locales?.include?.map((locale) => `urn:li:locale:${locale}`) || [];

    return {
      include: {
        [FacetName.Locations]: geoLocationUrns.include,
        [FacetName.Genders]: genderUrns.include,
        [FacetName.AgeRanges]: model.audience.ageRange.include,
        ...((interfaceLocales.length && { [FacetName.InterfaceLocales]: interfaceLocales }) || {}),
        ...detailedTargetingUrns.include
      },

      ...(Object.keys(exclude || {}).length ? { exclude } : {})
    };
  };

  export const removeEmptyChars = (str: string | string[]) =>
    Array.isArray(str) ? str.map((s) => removeEmptyChars(s)) : str.replace(/\s/g, '');

  export const mapImagesToLinkedinImageModel = (
    model: LinkedinCreateAd.ICreateAdModel<AdModelCarouselImage>
  ): LinkedinCreateAd.ICreateAdModel<LinkedInCarouselCreativeInputModel> => {
    return pipe(model, cloneDeep, (model) => {
      return {
        ...model,
        ad: {
          creative: {
            campaign: '',
            contentLandingPage: model.ad.creative.contentLandingPage!,
            commentary: model.ad.creative.commentary,
            cards: model.ad.creative.carouselCards.map(
              (card) =>
                ({
                  media: {
                    title: card.title,
                    id: card.id
                  },
                  langingPage: model.ad.creative.contentLandingPage!
                } as CarouselCreativeInputModelCards)
            )
          }
        }
      };
    });
  };

  export function mapToAudience<T extends AdTypeModels>(model: LinkedinCreateAd.ICreateAdModel<T>) {
    return pipe(model, cloneDeep, (o) => {
      (o.audience.locales.include as unknown as string[]) = o.audience.locales.include.map((l) => l.key);

      return o;
    });
  }

  export const getAgeRange = (urns: AgeRangeURN[]): { min: AgeBreakpoints; max: AgeBreakpoints } => {
    const range = urns.reduce((acc, urn) => {
      const URN_PATTERN = /(\d+),(\d+)/;
      const ageRange = urn.split(':')[3];
      const urnRange = URN_PATTERN.exec(ageRange);
      if (!urnRange || !urnRange[1] || !urnRange[2]) {
        return acc;
      }
      if (!acc[0]) {
        acc[0] = +urnRange[1] as AgeBreakpoints;
      }
      acc[1] = +urnRange[1] as AgeBreakpoints;
      return acc;
    }, [] as Array<AgeBreakpoints>);
    return {
      min: Number(range?.[0] || 18) as AgeBreakpoints,
      max: Number(range?.[1] || 55) as AgeBreakpoints
    };
  };

  export type AgeBreakpoints = 18 | 25 | 35 | 55;
  export const generateUrns = (min: AgeBreakpoints, max: AgeBreakpoints): AgeRangeURN[] => {
    const breakpoints = [18, 25, 35, 55, 2147483647];
    const urns: AgeRangeURN[] = [];
    const minIndex = breakpoints.indexOf(min);
    const maxIndex = breakpoints.indexOf(max);
    for (let i = minIndex; i <= maxIndex; i++) {
      const min = breakpoints[i];
      const max = breakpoints[i + 1];
      urns.push(`urn:li:ageRange:(${min},${max === 2147483647 ? '2147483647' : max - 1})` as AgeRangeURN);
    }
    return urns;
  };

  export const parseGendersToUrn = (genders: Array<Targeting.Gender>): GenderURN[] => {
    return genders.map((gender) => `urn:li:gender:${gender.toUpperCase()}` as GenderURN);
  };

  export const getImageModelWithCampaignId = (
    campaignId: string,
    model: AdModelSingleImage
  ): LinkedInCreateImageModel[] => {
    const { ctaLabel, altText, title, commentary, contentLandingPage } = model;
    return model.imageIds.map((imageId) => ({
      campaign: campaignId,
      imageId,
      ctaLabel,
      altText,
      title,
      commentary,
      contentLandingPage
    }));
  };

  // https://learn.microsoft.com/en-us/linkedin/shared/references/v2/ads/targeting-criteria-facet-urns?view=li-lms-2023-03
  export type AgeRangeURN =
    | 'urn:li:ageRange:(18,24)'
    | 'urn:li:ageRange:(25,34)'
    | 'urn:li:ageRange:(35,54)'
    | 'urn:li:ageRange:(55, 2147483647)';
  export type GenderURN = `urn:li:gender:${'MALE' | 'FEMALE'}`;
  export const AccountOverviewTypes: IOption[] = [
    { label: 'Clicks', value: 'clicks' },
    { label: 'Impressions', value: 'impressions' },
    { label: 'One Click Lead Form Opens', value: 'oneClickLeadFormOpens' },
    { label: 'One Click Leads', value: 'oneClickLeads' },
    { label: 'Reactions', value: 'reactions' },
    { label: 'Registrations', value: 'registrations' },
    { label: 'Download Clicks', value: 'downloadClicks' },
    { label: 'Cost In Local Currency', value: 'costInLocalCurrency' },
    { label: 'Comments', value: 'comments' },
    { label: 'Company Page Clicks', value: 'companyPageClicks' }
  ];
  export enum CampaignFormat {
    Carousel = 'CAROUSEL',
    FollowCompany = 'FOLLOW_COMPANY',
    Jobs = 'JOBS',
    SingleVideo = 'SINGLE_VIDEO',
    SponsoredInMails = 'SPONSORED_INMAILS',
    SponsoredMessage = 'SPONSORED_MESSAGE',
    Spotlight = 'SPOTLIGHT',
    StandardUpdate = 'STANDARD_UPDATE',
    TextAd = 'TEXT_AD',
    Unsupported = 'UNSUPPORTED'
  }

  export enum ServingStatus {
    Runnable = 'RUNNABLE',
    Stopped = 'STOPPED',
    BillingHold = 'BILLING_HOLD',
    AccountTotalBudgetHold = 'ACCOUNT_TOTAL_BUDGET_HOLD',
    AccountEndDateHold = 'ACCOUNT_END_DATE_HOLD',
    RestrictedHold = 'RESTRICTED_HOLD',
    InternalHold = 'INTERNAL_HOLD'
  }

  export interface IChangeAuditStamp {
    actor?: Urn;
    time: number;
  }

  export interface IChangeAuditStamps {
    created: IChangeAuditStamp;
    lastModified: IChangeAuditStamp;
  }

  export interface ICampaignSchema {
    id?: number;
    account: `urn:li:sponsoredAccount:${number | string}`;
    associatedEntity?: Urn;
    audienceExpansionEnabled?: boolean;
    campaignGroup: SponsoredCampaignGroupUrn;
    changeAuditStamps: IChangeAuditStamps;
    costType: LinkedInCostType;
    creativeSelection?: LinkedInCreativeSelection;
    dailyBudget?: {
      amount?: string;
      currencyCode?: string;
    };
    locale: Required<LinkedInCampaignCreationModelLocale>;
    name: string;
    objectiveType?: LinkedInCampaignObjectiveType;
    offsiteDeliveryEnabled?: boolean;
    offsitePreferences?: any;
    runSchedule: LinkedInCampaignCreationModelRunSchedule;
    targetingCriteria: ITargetingCriteria;
    totalBudget?: LinkedInCampaignCreationModelDailyBudget;
    type: LinkedInCampaignType;
    unitCost?: {
      amount?: number;
      currencyCode?: string;
    };
    versionTag?: string;
    version?: {
      versionTag: string;
    };
    status: LinkedInCampaignStatus;
    optimizationTargetType?: OptimizationTargetType;
    format?: CampaignFormat;
    pacingStrategy?: string | 'LIFETIME';
    test?: boolean;
    servingStatuses?: ServingStatus;
  }

  export interface LinkedInCampaignUpdateModel {
    campaignId: number;
    patch: Partial<ICampaignSchema>;
  }

  export module Business {
    export interface IOauthRequest {
      contextId: string;
    }

    export interface IOauthResponse {
      oauth: ILinkedinOAuthOutput;
    }

    export interface ILinkedinOAuthOutput {
      oauthUrl: string;
    }
    export interface IConnectAdAccountRequest {
      contextId: string;
      adAccountId: string;
      loginId?: string;
    }

    export interface IConnectAdAccountResponse {
      connectAdAccount: {
        businessId: string;
        connectedAdAccountId: string;
        platform: PlatformEnum;
      };
    }

    export interface IAdAccountRequest {
      after: string;
      first: number;
      names?: string[];
      ids?: string[];
      statuses: LinkedInAdAccountStatus[];
    }

    export interface IAdAccountResponse {
      adAccounts: {
        edges: [
          {
            cursor: string;
            node: LinkedInAdAccountOutput;
          }
        ];
        totalCount: number;
        pageInfo: OffsetPageInfo;
      };
    }

    export interface LinkedInAdAccountOutput {
      id: number;
      name: string;
      currency: string;
      notifiedOnCampaignOptimization: boolean;
      notifiedOnCreativeApproval: boolean;
      notifiedOnCreativeRejection: boolean;
      notifiedOnEndOfCampaign: boolean;
    }

    export interface ILinkedinConnectedAdAccountOutput {
      businessId: string;
      connectedAdAccountId: string;
    }
  }

  export module Dashboard {
    export interface CampaignParams {
      startDate: number;
      endDate?: number;
      names?: string[];
      statuses?: LinkedInCampaignStatus[];
      ids?: number[];
    }

    export type ISmartCampaignRequest = OffsetPaginationArgs & CampaignParams;

    export type ICampaignRequest = CursorPaginationArgs & CampaignParams;

    export interface ICampaignResponse {
      campaigns: {
        edges: [{ node: ILinkedInCampaignOutput }];
        totalCount: number;
        pageInfo: { from: number; hasNextPage: boolean; hasPreviousPage: boolean; to: number };
      };
    }

    export interface LinkedinSingleCampaignInputDto {
      ids: number[];
    }

    export interface LinkedinSingleCampaignOutputDto {
      id: number;
      status: string;
      name: string;
      budget: number;
      budgetPeriod: string;
      dateRange: DateRange;
      campaignGroup: SponsoredCampaignGroupUrn;
      type: Linkedin.LinkedInCampaignType;
      costType: LinkedInCostType;
      optimizationTargetType: Linkedin.OptimizationTargetType;
      objectiveType: LinkedInCampaignObjectiveType;
      targetingCriteria: Record<any, any>;
    }

    export interface ILinkedInCampaignOutput {
      externalId: string;
      status: string;
      name: string;
      budget: number;
      budgetPeriod: string;
      dateRange: DateRange;
      conversionValue: number;
      conversion: string;
      impressions: number;
      clicks: number;
      costPerClick: number;
      totalSpent: number;
      currencyCode: string;
      campaignGroup: string;
      targetingCriteria: Record<any, any>;
      type: Linkedin.LinkedInCampaignType;
      costType: LinkedInCostType;
      objectiveType: LinkedInCampaignObjectiveType;
      optimizationTargetType: Linkedin.OptimizationTargetType;
    }

    export interface IDashboardInsightsRequest {
      skip?: number;
      take: number;
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
      accounts?: string[];
      campaigns: string[];
      timeGranularity: LinkedInTimeGranularity;
      type: LinkedInInsightType;
    }

    export interface LinkedInSearchCreativeInput {
      account?: number;
      campaigns?: string[];
      contentReferences?: string[];
      creatives?: string[];
      intendedStatuses?: LinkedInCreativeStatus[];
      isTestAccount?: boolean;
      isTotalIncluded?: boolean;
      leadgenCreativeCallToActionDestinations?: string[];
      sortOrder?: SortOrder;
    }

    export interface LinkedInSearchCreativeEdge {
      account: string;
      campaign: string;
      content: {
        reference: string;
      };
      createdAt?: number;
      createdBy?: string;
      intendedStatus?: LinkedInCreativeStatus;
      isServing?: boolean;
      lastModifiedAt?: number;
      lastModifiedBy?: string;
      review?: {
        status: CreativeReviewStatus;
        reasons?: RejectionReason[];
      };
      servingHoldReasons: ServingHoldReason[];
      id: string;
    }

    export enum LinkedInCreativeStatus {
      Active = 'ACTIVE',
      Paused = 'PAUSED',
      Draft = 'DRAFT',
      Archived = 'ARCHIVED',
      Cancelled = 'CANCELLED',
      PendingDeletion = 'PENDING_DELETION',
      Removed = 'REMOVED'
    }

    export enum SortOrder {
      Ascending = 'ASCENDING',
      Descending = 'DESCENDING',
      Default = Ascending
    }

    export enum CreativeReviewStatus {
      Pending = 'PENDING',
      Approved = 'APPROVED',
      Rejected = 'REJECTED',
      NeedsReview = 'NEEDS_REVIEW'
    }

    export enum RejectionReason {
      AdultContent = 'ADULT_CONTENT',
      AffiliateAdvertising = 'AFFILIATE_ADVERTISING',
      Alcohol = 'ALCOHOL',
      AnimalOrAnimalProducts = 'ANIMAL_OR_ANIMAL_PRODUCTS',
      BackButtonNotWorking = 'BACK_BUTTON_NOT_WORKING',
      ChinaPolicyViolation = 'CHINA_POLICY_VIOLATION',
      CopyrightedContent = 'COPYRIGHTED_CONTENT',
      CounterfeitGoods = 'COUNTERFEIT_GOODS',
      Cryptocurrency = 'CRYPTOCURRENCY',
      CurrentSensitiveEvents = 'CURRENT_SENSITIVE_EVENTS',
      DataCollectionAndPrivacy = 'DATA_COLLECTION_AND_PRIVACY',
      DietarySupplements = 'DIETARY_SUPPLEMENTS',
      DrugsAndRelatedProducts = 'DRUGS_AND_RELATED_PRODUCTS',
      ExcessiveCapitalization = 'EXCESSIVE_CAPITALIZATION',
      ExcessiveContactInformation = 'EXCESSIVE_CONTACT_INFORMATION',
      ExcessiveNegativeResponse = 'EXCESSIVE_NEGATIVE_RESPONSE',
      FakeDocument = 'FAKE_DOCUMENT',
      GamblingSweepstakesAndVirtualCurrency = 'GAMBLING_SWEEPSTAKES_AND_VIRTUAL_CURRENCY',
      GeneralTrademarkIssues = 'GENERAL_TRADEMARK_ISSUES',
      HackingAndCracking = 'HACKING_AND_CRACKING',
      HateViolenceDiscriminationAndOpposition = 'HATE_VIOLENCE_DISCRIMINATION_AND_OPPOSITION',
      HealthMatters = 'HEALTH_MATTERS',
      ImpliedAffiliation = 'IMPLIED_AFFILIATION',
      InappropriateLanguageOrImage = 'INAPPROPRIATE_LANGUAGE_OR_IMAGE',
      InappropriateName = 'INAPPROPRIATE_NAME',
      InconsistentDisplayAndLandingPageUrls = 'INCONSISTENT_DISPLAY_AND_LANDING_PAGE_URLS',
      JobPostingWithoutListedState = 'JOB_POSTING_WITHOUT_LISTED_STATE',
      LinkedinMentionedInAdText = 'LINKEDIN_MENTIONED_IN_AD_TEXT',
      LoansAndFinancialServices = 'LOANS_AND_FINANCIAL_SERVICES',
      MisleadingClaims = 'MISLEADING_CLAIMS',
      MismatchedAdTextAndLandingPageContent = 'MISMATCHED_AD_TEXT_AND_LANDING_PAGE_CONTENT',
      MissingPrivacyPolicy = 'MISSING_PRIVACY_POLICY',
      MissingUrlForWebsiteObjective = 'MISSING_URL_FOR_WEBSITE_OBJECTIVE',
      NonMatchingLanguages = 'NON_MATCHING_LANGUAGES',
      NonStandardText = 'NON_STANDARD_TEXT',
      NonfunctionalSite = 'NONFUNCTIONAL_SITE',
      OccultPursuits = 'OCCULT_PURSUITS',
      OffensiveLanguage = 'OFFENSIVE_LANGUAGE',
      OffensiveToGoodTaste = 'OFFENSIVE_TO_GOOD_TASTE',
      OfferNotFound = 'OFFER_NOT_FOUND',
      PopUpOrPopUnder = 'POP_UP_OR_POP_UNDER',
      ProhibitedDatingServices = 'PROHIBITED_DATING_SERVICES',
      ProhibitedPoliticalContent = 'PROHIBITED_POLITICAL_CONTENT',
      QuestionableDownloads = 'QUESTIONABLE_DOWNLOADS',
      ResaleOfEventTickets = 'RESALE_OF_EVENT_TICKETS',
      RingtonesAndInappropriateVideoGames = 'RINGTONES_AND_INAPPROPRIATE_VIDEO_GAMES',
      SolicitationOfFunds = 'SOLICITATION_OF_FUNDS',
      TestAds = 'TEST_ADS',
      TestTakingServices = 'TEST_TAKING_SERVICES',
      Tobacco = 'TOBACCO',
      UnclearBillingPractices = 'UNCLEAR_BILLING_PRACTICES',
      UnsupportedCompetitiveClaims = 'UNSUPPORTED_COMPETITIVE_CLAIMS',
      UnsupportedLanguage = 'UNSUPPORTED_LANGUAGE',
      WeaponsAndFireworks = 'WEAPONS_AND_FIREWORKS'
    }

    export enum ServingHoldReason {
      Stopped = 'STOPPED',
      UnderReview = 'UNDER_REVIEW',
      Rejected = 'REJECTED',
      FormHold = 'FORM_HOLD',
      Processing = 'PROCESSING',
      ProcessingFailed = 'PROCESSING_FAILED',
      ReferredContentQualityHold = 'REFERRED_CONTENT_QUALITY_HOLD',
      JobPostingOnHold = 'JOB_POSTING_ON_HOLD',
      JobPostingInvalid = 'JOB_POSTING_INVALID',
      CampaignStopped = 'CAMPAIGN_STOPPED',
      AccountTotalBudgetHold = 'ACCOUNT_TOTAL_BUDGET_HOLD',
      AccountEndDateHold = 'ACCOUNT_END_DATE_HOLD',
      CampaignStartDateHold = 'CAMPAIGN_START_DATE_HOLD',
      CampaignEndDateHold = 'CAMPAIGN_END_DATE_HOLD',
      CampaignTotalBudgetHold = 'CAMPAIGN_TOTAL_BUDGET_HOLD',
      CampaignAudienceCountHold = 'CAMPAIGN_AUDIENCE_COUNT_HOLD',
      CampaignGroupStartDateHold = 'CAMPAIGN_GROUP_START_DATE_HOLD',
      CampaignGroupEndDateHold = 'CAMPAIGN_GROUP_END_DATE_HOLD',
      CampaignGroupTotalBudgetHold = 'CAMPAIGN_GROUP_TOTAL_BUDGET_HOLD',
      CampaignGroupStatusHold = 'CAMPAIGN_GROUP_STATUS_HOLD',
      AccountServingHold = 'ACCOUNT_SERVING_HOLD'
    }

    export interface IDashboardInsightsResponse {
      insights: IDashboardInsights;
    }

    export interface IDashboardInsights {
      edges: [{ cursor: number; node: ILinkedInInsightOutput }];
      totalCount: number;
      pageInfo: IPageInfo;
    }

    export interface ILinkedInInsightOutput {
      clicks: number;
      impressions: number;
      oneClickLeadFormOpens: number;
      oneClickLeads: number;
      reactions: number;
      registrations: number;
      downloadClicks: number;
      costInLocalCurrency: number;
      comments: number;
      companyPageClicks: number;
      pivotValues: string[];
      dateRange: DateRange;
    }
  }

  export module CreateAd {
    export interface UploadResponse {
      uploadUrlExpiresAt: number;
      uploadUrl: string;
      image: string;
    }
    export interface ImageDetailResponse {
      getSingleImageDetail: LinkedinImageDetail;
    }
    export interface LinkedinImageDetail {
      owner: string;
      downloadUrlExpiresAt: number;
      downloadUrl: string;
      status: string;
      id: string;
    }
    export interface ImageDetailRequest {
      imageId: string;
    }

    export interface GetApplicationCampaignGroupIdResponse {
      getApplicationCampaignGroupId: string;
    }

    export interface LinkedinOrganizationResponse {
      vanityName: string;
      localizedName: string;
      groups: [];
      versionTag: string;
      organizationType: string;
      defaultLocale: {
        country: string;
        language: string;
      };
      alternativeNames: [];
      specialties: [];
      localizedSpecialties: [];
      industries: string[];
      name: {
        localized: {
          [key: string]: string;
        };
        preferredLocale: {
          country: string;
          language: string;
        };
      };
      primaryOrganizationType: string;
      locations: [];
      id: number;
      logoV2: {
        cropped: string;
        original: string;
        cropInfo: {
          x: number;
          width: number;
          y: number;
          height: number;
        };
      };
    }

    export interface GetOrganizationsResponse {
      getOrganizations: LinkedinMultipleResult<LinkedinOrganizationResponse>;
    }

    export interface GetOrganizationsWithLogosResponse extends GetOrganizationsResponse {
      getOrganizationLogos: LinkedinMultipleResult<LinkedinImageDetail>;
    }

    export interface LinkedinMultipleResult<T> {
      results: { [key: string]: T };
      statuses: { [key: string]: number };
      errors: { [key: string]: string };
    }

    export interface SearchByFacetResponse {
      searchByFacet: {
        edges: SearchByFacetEdge[];
      };
    }

    export interface SearchByFacetEdge {
      cursor: string;
      node: SearchByFacetEdgeNode;
    }

    export interface SearchByFacetEdgeNode {
      name: string;
      urn: string;
    }

    export interface SearchByFacetRequest {
      facet: string;
      query: string;
      omitLocale?: boolean;
    }

    export interface CreateCampaignResponse {
      createCampaign: string;
    }

    export interface CreateImageAdResponse {
      createImageAd: string;
    }
  }

  export module Targeting {
    export interface AudienceSection {
      inclusions: Linkedin.CreateAd.SearchByFacetEdge[];
      exclusions: Linkedin.CreateAd.SearchByFacetEdge[];
    }
  }

  export module UI {
    export type SelectAudienceItem = ICreateAdSelectItem<Linkedin.AudienceType>;
  }

  export module Targeting {
    export enum Gender {
      Men = 'MALE',
      Women = 'FEMALE'
    }
    export const Languages = [
      {
        code: 'en_US',
        label: 'English'
      },
      {
        code: 'ar_AE',
        label: 'Arabic'
      },
      {
        code: 'zh_CN',
        label: 'Chinese - Simplified'
      },
      {
        code: 'zh_TW',
        label: 'Chinese - Traditional'
      },
      {
        code: 'cs_CZ',
        label: 'Czech'
      },
      {
        code: 'da_DK',
        label: 'Danish'
      },
      {
        code: 'in_ID',
        label: 'Indonesian'
      },
      {
        code: 'ms_MY',
        label: 'Malaysian'
      },
      {
        code: 'nl_NL',
        label: 'Dutch'
      },
      {
        code: 'fr_FR',
        label: 'French'
      },
      {
        code: 'fi_FI',
        label: 'Finnish'
      },
      {
        code: 'de_DE',
        label: 'German'
      },
      {
        code: 'it_IT',
        label: 'Italian'
      },
      {
        code: 'ja_JP',
        label: 'Japanese'
      },
      {
        code: 'ko_KR',
        label: 'Korean'
      },
      {
        code: 'no_NO',
        label: 'Norwegian'
      },
      {
        code: 'pl_PL',
        label: 'Polish'
      },
      {
        code: 'pt_BR',
        label: 'Portuguese'
      },
      {
        code: 'ro_RO',
        label: 'Romanian'
      },
      {
        code: 'ru_RU',
        label: 'Russian'
      },
      {
        code: 'es_ES',
        label: 'Spanish'
      },
      {
        code: 'sv_SE',
        label: 'Swedish'
      },
      {
        code: 'th_TH',
        label: 'Thai'
      },
      {
        code: 'tl_PH',
        label: 'Filipino'
      },
      {
        code: 'tr_TR',
        label: 'Turkish'
      }
    ];
  }
}
