import { Bing } from '@core/models/platforms/bing';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Business.IConnectAdAccountRequest;
type Output = Bing.Business.IConnectAdAccountResponse;

export const connectAdAccountBing = makeMutation<Output, Input>(`
mutation ConnectAdAccount(
  $contextId: String!
  $adAccountId: String!
  $loginId: String
) {
  bing {
    connectAdAccount(
      payload: {
        contextId: $contextId
        adAccountId: $adAccountId
        loginId: $loginId
      }
    ) {
      businessId
      connectedAdAccountId
    }
  }
}
`);
