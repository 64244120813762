export interface IPackage {
  id: string;
  name: string;
  displayName: string;
  currencyCode: string;
  degree: number;
  visibility: number;
  period: number;
  periodUnit: PeriodUnit;
  pricingModel: number;
  price: number;
  status: string;
  group: number;
  featuresJson?: PackageFeatures | null;
  adSpendLimit: number;
  externalId: string;

  //added for ui
  discountPrice: number;
  discountAppliedPrice: number;
}

export interface PackageFeatures {
  hasSmartCampaign: boolean;
  hasUnlimitedAccounts: boolean;
  hasAllAdPlatforms: boolean;
  hasclickFraud: boolean;
  subAccountsCount: number;
  hasDedicatedMarketer: boolean;
  badge: string;
}

export enum ExternalPackage {
  PremiumUSDMonthly = 'ayn-premium-USD-Monthly',
  PremiumUSDYearly = 'ayn-premium-USD-Yearly',
  AynPackage1USDYearly = 'ayn-package-1-USD-Yearly',
  AynPackage2USDYearly = 'ayn-package-2-USD-Yearly',
  AynPackage3USDYearly = 'ayn-package-3-USD-Yearly',
  AynPackage1USDMonthly = 'ayn-package-1-USD-Monthly',
  AynPackage2USDMonthly = 'ayn-package-2-USD-Monthly',
  AynPackage3USDMonthly = 'ayn-package-3-USD-Monthly'
}

export const enum PeriodUnit {
  /**
   * Charge based on day(s).
   */
  Day = 1,

  /**
   * Charge based on week(s).
   */
  Week,

  /**
   * Charge based on month(s).
   */
  Month,

  /**
   * Charge based on year(s).
   */
  Year
}

export interface MySubscription {
  id: string;
  packageId: string;

  currentTermEnd: number;
  cancelledAt: number;

  package: {
    id: string;
    name: string;
    displayName: string;
    currencyCode: string;
    adSpendLimit: number;
    price: number;
    degree: number;
    period: number;
    periodUnit: PeriodUnit;
  };

  status: string;

  billingCompanyName: string;
  billingEmail: string;
  billingVatNumber: string;
  billingFullName: string;

  cardLast4: string;

  totalDues: number;
}

export interface MySubscriptionResponse {
  mySubscription: MySubscription;
}

export interface ApplyAppsumoCouponRequest {
  coupon: string;
}

export interface PaymentIntent {
  id: string;
  status: string;
  currency_code: string;
  amount: number;
  gateway_account_id: string;
  expires_at: number;
  reference_id: string | null;
  payment_method_type: string;
  success_url: string | null;
  failure_url: string | null;
  created_at: number;
  modified_at: number;
  resource_version: string | number | null;
  updated_at: number | null;
  gateway: string;
  business_entity_id: number | null;
}

export interface PaymentSourceFromPaymentIntentInput {
  id: string;
  gateway_account_id?: string;
  reference_id?: string;
  payment_method_type?: string;
  gw_token?: string;
}

export interface UpdateBillingInfoRequest {
  companyName?: string;
  vatNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface InvoiceItem {
  id: string;
  notes: string[];
  total: number;
  subTotal: number;
  date: Date;
  status: number;
  currency: string;
}

export type DiscountType = 'fixed_amount' | 'percentage';

export interface ICoupon {
  id: string;
  name: string;
  discountType: DiscountType;
  discountPercentage: number;
  discountAmount: number;
}

export interface ISubmitCustomOfferRequest {
  fullName: string;
  email: string;
  website: string;
  companyName: string;
  description: string;
}
