export namespace OpenAI {
  export enum TextTone {
    Friendly = 'Friendly',
    Luxury = 'Luxury',
    Relaxed = 'Relaxed',
    Professional = 'Professional',
    Bold = 'Bold',
    Adventures = 'Adventures',
    Witty = 'Witty',
    Persuasive = 'Persuasive'
  }

  export enum TextProjectType {
    ShortHeadline = 'ShortHeadline',
    General = 'General',
    Listicle = 'Listicle',
    PainAgitateSolution = 'PainAgitateSolution',
    BeforeAfterBridge = 'BeforeAfterBridge',
    FeatureAdvantageBenefits = 'FeatureAdvantageBenefits'
  }

  export interface IGenerateTextProjectRequest {
    language: string;
    tone: TextTone;
    type: TextProjectType;
    cta: string;
    productName: string;
    description: string;
  }

  export interface IGenerateTextProjectResponse {
    texts: string[];
  }

  export interface IGenerateTextsRequest {
    outputLanguage: string;
    description: string;
    targetAudience?: string;
    headlines?: IGenerateTextsSection[];
    descriptions?: IGenerateTextsSection[];
    ctas?: IGenerateTextsSection[];
    punchlines?: IGenerateTextsSection[];
  }

  export interface IGenerateTextsSection {
    length: number;
    count: number;
    platform: string;
    tone: TextTone;
  }

  export interface GeneratedTextsOutputDto {
    ctas?: string[];
    descriptions?: string[];
    punchlines?: string[];
    headlines?: string[];
  }

  export interface TextCraftCommonInput {
    productName: string;
    productDescription: string;
    content: string;
    textLimit: number;
  }

  export interface ChangeSentimentInput extends TextCraftCommonInput {
    tone: string;
  }

  export interface TranslateInput extends TextCraftCommonInput {
    language: string;
  }

  export interface UrlInput {
    url: string;
  }

  export interface GetProductDescriptionResult {
    name: string;
    description: string;
    targetAudience: string;
    adGoal: string;
    cta: string;
    language: string;
  }

  export interface GenerateTextsWithFrameworkInputDto {
    productName: string;
    description: string;
    targetAudience: string;
    tone: string;
    outputLanguage: string;
    customRequests: string;
    framework: string;
  }

  export interface LogosOutput {
    logos: Array<string>;
  }

  export interface TextAIFormValue {
    productDescription: string;
    productName: string;
    targetAudience: string;
    tone: OpenAI.TextTone;
    language: string;
    url: string;
  }

  export interface GenerateTextsWithFrameworkOutputDto {
    advertisingHeadlines: Array<string>;
    advertisingPunchlines: Array<string>;
    ctaButtonTexts: Array<string>;
    primaryText: GenerateTextsWithFrameworkPrimaryTextOutputDto;
    headlineUnder100Characters: string;
  }

  export interface GenerateTextsWithFrameworkPrimaryTextOutputDto {
    framework: string;
    text: string;
  }
}
