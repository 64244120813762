import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

type Output = QueryResult<'campaigns', TikTok.Dashboard.TikTokCampaign[]>;

export const campaigns = makeQuery<Output, { ids: string[] }>(`
query TiktokCampaign($ids: [String!]!) {
  tiktok {
    campaigns(ids: $ids) {
      campaignName
      campaignId
      campaignType
      budget
      budgetMode
      operationStatus
      objective
      objectiveType
      createTime
    }
  }
}
`);
