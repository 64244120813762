import { Component, OnInit } from '@angular/core';
import { TikTok } from '@core/models';
import { TiktokStateService } from '@pages/create-ad/state';
import { ICreateAdSelectItem } from '@pages/create-ad/models';

export const AUDIENCE_LIST__TIKTOK: ICreateAdSelectItem<TikTok.CreateAd.AudienceType>[] = [
  {
    id: 1,
    title: 'Manual Audience Setup',
    description: 'Manually setup your own target audience with interests and demographics.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: TikTok.CreateAd.AudienceType.MANUAL_AUDIENCE
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: '' as TikTok.CreateAd.AudienceType
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: '' as TikTok.CreateAd.AudienceType
  }
];

@Component({
  selector: 'aayn-select-audience--tiktok',
  templateUrl: './select-audience-content.component.html'
})
export class SelectAudienceContentTiktokComponent implements OnInit {
  protected audiences = AUDIENCE_LIST__TIKTOK;

  protected selectedAudience: TikTok.CreateAd.AudienceType = TikTok.CreateAd.AudienceType.MANUAL_AUDIENCE;

  protected AudienceTypes = TikTok.CreateAd.AudienceType;

  constructor(private tiktokStateService: TiktokStateService) {}

  ngOnInit() {}

  audienceChangeStatus(item: ICreateAdSelectItem<TikTok.CreateAd.AudienceType>) {
    this.audiences.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAudience = item.type!;
      } else {
        x.active = false;
      }
    });

    this.tiktokStateService.adCreationModel.selectedTypes.audience = item.type!;
  }
}
