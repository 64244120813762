import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/public-api';
import { Facebook, FacebookService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { LocationSelectionOverlayItemActiveType } from '@pages/create-ad/components/shared/manuel-audience-setup/components/location-selection/components/location-selection-overlay-item/location-selection-overlay-item.component';

export interface FacebookLocation {
  geoLocations: Facebook.GeoLocationItem[] | null;
  excludedGeoLocations: Facebook.GeoLocationItem[] | null;
}

export type FacebookLocationControls = {
  [K in keyof FacebookLocation]: FormControl<FacebookLocation[K]>;
};

type FacebookLocationForm =
  | FormGroup<FacebookLocationControls>
  | AbstractControl<Partial<FacebookLocation>, FacebookLocation>;

@Component({
  selector: 'aayn-location-selection--facebook',
  templateUrl: 'location-selection.component.html'
})
export class AudienceLocationSelectionFacebookComponent implements OnInit {
  @Input() form?: FacebookLocationForm;

  @ViewChild(Overlay) overlay?: Overlay;

  protected locationSearchControl = new FormControl('', [Validators.minLength(1)]);

  @Select(LoaderState.getAny(['GetGeoLocations'])) loader$?: Observable<boolean>;

  protected geoLocations: { node: Facebook.GeoLocationItem }[] = [];

  get inclusions() {
    return this.form?.value.geoLocations || [];
  }

  get exclusions() {
    return this.form?.value.excludedGeoLocations || [];
  }

  constructor(private facebookService: FacebookService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.locationSearchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((term) => {
      if (this.locationSearchControl.invalid) return;

      if (this.inclusions.length < 1 && this.exclusions.length < 1) {
        this.locationSearchControl.setErrors({ inValid: true });
      }

      this.facebookService
        .getGeoLocations$(term!)
        .pipe(map((o) => o.data))
        .subscribe((result) => {
          this.geoLocations = result.geoLocations.edges;

          if (!this.overlay?.render) this.overlay?.show(null);

          this.cdr.detectChanges();
        });
    });
  }

  protected onIncludeClick(geoLocation: Facebook.GeoLocationItem) {
    this.form?.patchValue({
      geoLocations: [...this.inclusions, geoLocation]
    });
  }

  protected onExcludeClick(geoLocation: Facebook.GeoLocationItem) {
    this.form?.patchValue({
      excludedGeoLocations: [...this.exclusions, geoLocation]
    });
  }

  protected isActive(geoLocation: Facebook.GeoLocationItem) {
    const hasAddedInclusions = this.inclusions.some((o) => o.key === geoLocation.key);
    const hasAddedExclusions = this.exclusions.some((o) => o.key === geoLocation.key);

    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'inclusions' : hasAddedExclusions ? 'exclusions' : ''
    } as LocationSelectionOverlayItemActiveType;
  }

  protected removeItem(index: number, arrName: keyof FacebookLocationControls) {
    this.form?.patchValue({
      [arrName]: this.form?.value[arrName]!.filter((_, i) => i !== index)
    });
  }

  onIncludeRemove(geoLocation: Facebook.GeoLocationItem) {
    this.form?.patchValue({
      geoLocations: this.inclusions.filter((o) => o.key !== geoLocation.key)
    });
  }

  onExcludeRemove(geoLocation: Facebook.GeoLocationItem) {
    this.form?.patchValue({
      excludedGeoLocations: this.exclusions.filter((o) => o.key !== geoLocation.key)
    });
  }
}
