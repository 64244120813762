import { OpenAI } from '@core/models/open-ai.model';
import { makeQuery, QueryResult } from '@core/queries';

const queryField = 'getProductDescription';
type Output = QueryResult<typeof queryField, OpenAI.GetProductDescriptionResult>;

export const getProductDescriptionQuery = makeQuery<Output, OpenAI.UrlInput>(`
query GetProductDescription($url: String!) {
  openAI {
    ${queryField}(url: $url){
      name,
      description,
      targetAudience,
      adGoal,
      cta,
      language
    }
  }
}
`);
