import { Facebook, SmartCampaign } from '@core/models';
import { CursorPaginatedQueryResult, makeQuery } from '@core/queries/types';

const queryField = 'facebook';

type Output = CursorPaginatedQueryResult<
  typeof queryField,
  SmartCampaign.Client.SmartCampaignResult<Facebook.Dashboard.ICampaign>
>;

type Input = Facebook.Dashboard.GetCampaignsRequest;

export const getCampaigns = makeQuery<Output, Input>(`
query GetCampaigns(
  $dateRange: DateRangeInput
  $datePreset: FacebookInsightDatePreset
  $name: String
  $first: Int
  $after: String
) {
  facebook {
    campaign {
      ${queryField}(
        dateRange: $dateRange
        datePreset: $datePreset
        name: $name
        first: $first
        after: $after
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            status
            name
            startTime
            stopTime
            dailyBudget
            lifeTimeBudget
            isSmartCampaign
            insights {
              impressions
              costPerClick
              clicks
              spend
              averageCost1KImpressions
              averageCost1KReach
              reach
              startDate
              endDate
              actions {
                value
                cost
              }
            }
          }
          cursor
        }
      }
    }
  }
}
`);
