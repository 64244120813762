import * as Query from '@core/queries';
import { forkJoin } from 'rxjs';
import { map, scan } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { GraphQLService } from '../backend';
import { Linkedin } from '../models/platforms/linkedin';
import { calculateState, Upload } from '../utils';

@Injectable({ providedIn: 'root' })
export class LinkedinService {
  constructor(private graphqlService: GraphQLService, private http: HttpClient) {}

  getOAuthUri(contextId: string) {
    return this.graphqlService
      .post(Query.LinkedinQ.adAccounts.oauthLinkedin({ contextId }))
      .pipe(map((result) => result.data?.oauth));
  }

  getAdAccounts(payload: Linkedin.Business.IAdAccountRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.adAccounts.adAccountsLinkedin(payload))
      .pipe(map((result) => result.data?.adAccounts));
  }

  getApplicationCampaignGroupId() {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.getApplicationCampaignGroupId())
      .pipe(map((result) => result.data?.getApplicationCampaignGroupId));
  }

  connectAdAccount(body: Linkedin.Business.IConnectAdAccountRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.adAccounts.connectAdAccountLinkedin(body))
      .pipe(map((result) => result.data?.connectAdAccount));
  }

  getCampaigns(payload: Linkedin.Dashboard.ICampaignRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.dashboard.campaignsLinkedin(payload))
      .pipe(map((result) => result.data));
  }

  getCampaignsSmartSorting(payload: Linkedin.Dashboard.ISmartCampaignRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.dashboard.campaignsSmartSorting(payload))
      .pipe(map((result) => result.data?.campaignsSmartSorting));
  }

  searchCreatives(input: Linkedin.Dashboard.LinkedInSearchCreativeInput) {
    return this.graphqlService
      .get(Query.LinkedinQ.dashboard.searchCreativesQueryLinkedin({ input }))
      .pipe(map((result) => result.data));
  }

  getAccountOverviewTotal(payload: Linkedin.Dashboard.IDashboardInsightsRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.dashboard.accountOverviewTotalLinkedin(payload))
      .pipe(map((result) => result.data?.insights));
  }

  getAccountOverview(payload: Linkedin.Dashboard.IDashboardInsightsRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.dashboard.accountOverviewLinkedin(payload))
      .pipe(map((result) => result.data?.insights));
  }

  getFunnelDashboard(payload: Linkedin.Dashboard.IDashboardInsightsRequest) {
    return this.graphqlService
      .post(Query.LinkedinQ.dashboard.funnelDashboardLinkedin(payload))
      .pipe(map((result) => result.data?.insights));
  }

  getBreakDown(payload: Linkedin.Dashboard.IDashboardInsightsRequest) {
    return this.graphqlService
      .get(Query.LinkedinQ.dashboard.breakdownsLinkedin(payload))
      .pipe(map((result) => result.data?.insights));
  }

  uploadImage$(owner: string, formData: FormData) {
    const initialState: Upload = { state: 'PENDING', progress: 0 };

    return this.http
      .put<PromiseSettledResult<Linkedin.CreateAd.UploadResponse>[]>(
        `${environment.apiUrl}/linkedin/images`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
          params: { owner }
        }
      )
      .pipe(scan(calculateState, initialState));
  }

  getSingleImageDetail(imageId: string) {
    return this.graphqlService
      .post(Query.LinkedinQ.createAd.getSingleImageDetail({ imageId }))
      .pipe(map((result) => result.data?.getSingleImageDetail));
  }

  searchByFacet(params: Linkedin.CreateAd.SearchByFacetRequest) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.searchByFacet(params))
      .pipe(map((result) => result.data?.searchByFacet));
  }

  getAudienceCounts(input: any) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.getAudienceCounts(input))
      .pipe(map((result) => result?.data?.getAudienceCounts));
  }

  createCampaign(input: Linkedin.LinkedInCampaignCreationModel) {
    return this.graphqlService
      .post(Query.LinkedinQ.createAd.createCampaignLinkedin({ input }))
      .pipe(map((result) => result.data?.createCampaign));
  }

  createImageAd(input: Linkedin.LinkedInCreateImageModel[]) {
    return forkJoin(
      input.map((model) =>
        this.graphqlService
          .post(Query.LinkedinQ.createAd.createImageAd({ input: model }))
          .pipe(map((result) => result.data?.createImageAd))
      )
    );
  }

  getOrganizations(ids: number[]) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.getOrganizations({ ids }))
      .pipe(map((result) => result.data.getOrganizations));
  }

  getOrganizationWithLogos(ids: number[]) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.getOrganizationsWithLogos({ ids }))
      .pipe(map((result) => result.data));
  }

  findConversionsByAccount(id: number) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.findConversionsByAccount({ id }))
      .pipe(map((result) => result.data?.findConversionsByAccount));
  }

  getSuggestedBudget(input: Linkedin.LinkedInFindPricingInsightModel) {
    return this.graphqlService
      .get(Query.LinkedinQ.createAd.getSuggestedBudget({ input }))
      .pipe(map((result) => result.data?.getSuggestedBudget));
  }

  associateCampaignWithConversion(input: Linkedin.AssociateCampaignWithConversionDto) {
    return this.graphqlService.post(Query.LinkedinQ.createAd.associateCampaignWithConversion(input));
  }

  createCustomAudience(input: Linkedin.CreateCustomAudienceDto) {
    return this.graphqlService.post(Query.LinkedinQ.createAd.createCustomAudience({ input }));
  }

  createCarouselAd(input: Linkedin.LinkedInCarouselCreativeInputModel) {
    return this.graphqlService
      .post(Query.LinkedinQ.createAd.createCarouselAd({ input }))
      .pipe(map((result) => result.data?.createCarouselAd));
  }

  updateCampaign(input: Linkedin.LinkedInCampaignUpdateModel) {
    return this.graphqlService.post(Query.LinkedinQ.dashboard.updateCampaignLinkedin({ input }));
  }

  getCampaignsByIds(input: Linkedin.Dashboard.LinkedinSingleCampaignInputDto) {
    return this.graphqlService
      .get(Query.LinkedinQ.dashboard.campaignsByIds(input))
      .pipe(map((result) => result.data.campaignsByIds));
  }
}
