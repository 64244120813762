import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = {
  contextId: string;
};

type Output = {
  pages: {
    edges: {
      node: Facebook.IPage;
    }[];
  };
};

export const getPages = makeQuery<Output, Input>(`
  query GetPages($contextId: String!) {
    facebook {
      pages(contextId: $contextId) {
        edges {
          node {
            id,
            name,
            imageUrl,
            category
          }
        }
      }
    }
  }
`);
