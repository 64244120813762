<section class="aayn-ad-preview aayn-ad-preview-google-search">
  <section class="aayn-ad-preview-content">
    <div class="aayn-ad-preview-google-search_header-placeholder"></div>
    <div class="aayn-ad-preview-google-search-content">
      <div class="aayn-ad-preview-google-search--texts">
        <p class="aayn-ad-preview-google-search--texts__url"><strong>Ad</strong> {{ linkUrl || linkUrlPlaceholder }}</p>
        <div class="aayn-ad-preview-google-search--texts__headline">
          <ng-container *ngIf="titles.length; else headlinePlaceholder">
            <ng-container *ngFor="let item of titles; index as index">
              {{ item }}
              <ng-container *ngIf="index < 2"> &nbsp;|&nbsp; </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="aayn-ad-preview-google-search--texts__description">{{ description }}</div>
      </div>
      <div class="aayn-ad-preview-google-search_placeholder-box">
        <ayn-icon srcPrefix="/assets/images/svgs/create-ads/" name="google-search-placeholder-lines"></ayn-icon>
      </div>
      <div class="aayn-ad-preview-google-search_placeholder-box">
        <ayn-icon srcPrefix="/assets/images/svgs/create-ads/" name="google-search-placeholder-lines"></ayn-icon>
      </div>
    </div>
  </section>
</section>
<ng-template #headlinePlaceholder> {{ 'Your headline' | translate }} </ng-template>
