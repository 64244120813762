import { Clickfraud } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Clickfraud.ITurnOffClickfraudRequest;
type Output = Clickfraud.ITurnOffClickfraudResponse;

export const turnOffClickfraudProtection = makeMutation<Output, Input>(`
mutation TurnOffClickfraudProtection($source: Int!) {
  turnOffCFP(source: $source)
}
`);
