import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Facebook } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';
import { FacebookFormService } from '@pages/create-ad/state/platforms/facebook/facebook.form';

@UntilDestroy()
@Component({
  selector: 'aayn-traffic-to-website--facebook',
  templateUrl: './traffic-to-website.component.html'
})
export class TraficToWebsiteFacebookComponent implements OnInit {
  protected adCreationModel = this.facebookStateService.adCreationModel;

  traficWebsiteForm = new FormGroup({
    name: new FormControl(this.adCreationModel.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService,
    private facebookForm: FacebookFormService
  ) {}

  ngOnInit() {
    this.facebookForm.registerForm(this.traficWebsiteForm);

    this.facebookStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.adCreationModel.adSet.optimizationGoal = Facebook.OptimizationGoal.LINK_CLICKS;
    });

    this.facebookValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.traficWebsiteForm.markAllAsTouched();
    });

    this.traficWebsiteForm.statusChanges.subscribe((status) => {
      this.facebookValidationService.objectiveValid$.next(status === 'VALID');
    });
  }
}
