<div class="aayn-ad-account-creation-bing">
  <header class="aayn-ad-account-creation-bing--header">
    <ayn-icon name="circle-add-square"></ayn-icon>

    <div class="aayn-ad-account-creation-bing--header__content">
      <h5 class="aayn-ad-account-creation-bing--header__title">{{ 'Create a New Ad Account' | translate }}</h5>
      <p class="aayn-ad-account-creation-bing--header__description">
        After setting up your time zone and billing details, close the Google popup, click “<strong
          >My Account is Ready!</strong
        >” button below to start using your ad account.
      </p>
    </div>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <div class="aayn-ad-account-creation-bing--main">
    <form
      [formGroup]="accountCreationForm"
      (ngSubmit)="createAdAccountSubmit()"
      class="aayn-ad-account-creation-bing--main__form"
    >
      <div class="row">
        <ayn-form-field class="col-md-12" [maxCharacterNumber]="65">
          <input
            type="text"
            ayn-input
            formControlName="adAccountName"
            [placeholder]="'Ad account name' | translate"
            id="adAccountName"
          />
        </ayn-form-field>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="aayn-ad-account-creation-bing--main__form--select-with-label">
            <label for="country">{{ 'Language' | translate }}</label>
            <ayn-select
              [setValueHtml]="true"
              [minWidth]="120"
              id="country"
              formControlName="language"
              [placeholder]="'Search language' | translate"
            >
              <ayn-option
                *ngFor="let item of languages | aynOptionFilter | async"
                [value]="item.name"
                [title]="item.name"
              >
                <span class="flag-icon" [ngClass]="'flag-icon-' + item.code"></span>
                {{ item.name }}
              </ayn-option>
              <ayn-icon name="location" class="ayn-icon-left"></ayn-icon>
            </ayn-select>
          </div>
        </div>
        <div class="col-md-6">
          <ayn-select
            formControlName="currencyCode"
            [minWidth]="120"
            [placeholder]="'Ad account currency' | translate"
            [setValueHtml]="true"
          >
            <ayn-option
              *ngFor="let item of currencies | aynOptionFilter | async"
              [value]="item.code"
              [title]="item.name"
            >
              {{ item.name }}
            </ayn-option>
          </ayn-select>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <ayn-form-field [maxCharacterNumber]="35">
            <input type="text" ayn-input formControlName="city" [placeholder]="'City' | translate" id="city" />
          </ayn-form-field>
        </div>
        <div class="col-md-6">
          <div class="aayn-ad-account-creation-bing--main__form--select-with-label">
            <label for="country">{{ 'Country' | translate }}</label>
            <ayn-select
              [setValueHtml]="true"
              id="countryCode"
              formControlName="countryCode"
              [placeholder]="'Search country' | translate"
            >
              <ayn-option
                *ngFor="let item of countries | aynOptionFilter | async"
                [value]="item.code"
                [title]="item.name"
              >
                <span class="flag-icon" [ngClass]="'flag-icon-' + item.code"></span>
                {{ item.name }}
              </ayn-option>
              <ayn-icon name="location" class="ayn-icon-left"></ayn-icon>
            </ayn-select>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <ayn-form-field [maxCharacterNumber]="35">
          <input type="text" ayn-input formControlName="line1" [placeholder]="'Adress line' | translate" id="line1" />
        </ayn-form-field>
      </div>
      <div class="row mt-3">
        <ayn-form-field [maxCharacterNumber]="35">
          <input
            type="text"
            ayn-input
            formControlName="line2"
            [placeholder]="'Adress line 2 (Optional)' | translate"
            id="line2"
          />
        </ayn-form-field>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <ayn-form-field [maxCharacterNumber]="10">
            <input
              type="number"
              ayn-input
              formControlName="postalCode"
              [placeholder]="'Postal code (Optional)' | translate"
              id="postalCode"
            />
          </ayn-form-field>
        </div>

        <div class="col-md-6">
          <ayn-form-field [maxCharacterNumber]="5">
            <input
              type="text"
              ayn-input
              formControlName="stateOrProvinceCode"
              [placeholder]="'State/Province code (Optional)' | translate"
              id="stateOrProvinceCode"
            />
          </ayn-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <ayn-form-field [maxCharacterNumber]="100">
          <input
            type="text"
            ayn-input
            formControlName="businessName"
            [placeholder]="'Business name' | translate"
            id="businessName"
          />
        </ayn-form-field>
      </div>
    </form>
  </div>
  <div class="aayn-ad-account-creation-bing--footer">
    <button
      ayn-button="primary"
      [disabled]="accountCreationForm.invalid"
      ayn-button-block
      type="submit"
      [disabled]="accountCreationForm.invalid || (loader$ | async)"
      [loading]="!!(loader$ | async)"
      (click)="createAdAccountSubmit()"
    >
      {{ 'Setup Ad Account' | translate }}
    </button>
  </div>
</div>
