import { pipe } from 'fp-ts/lib/function';
import { get, set } from 'lodash';

import { DeepPartial } from '@core/utils/types';

import { CreateAdCommon, MainStep, New } from '../';

export type ApplyTypesParams<T = any> = {
  [step in MainStep]?: Partial<{
    [K in keyof T]?: Partial<T[K]> extends infer ATC extends Partial<Record<string, any>> ? DeepPartial<ATC> : never;
  }>;
};

/**
 * @description It is used in `{$Platform}CreateAd` class to change the type of `createAdModel` according to scenario and flow.
 * @example
 *   [Facebook.AdType.InstantExperienceAds]: {
        ad: {
          creative: {
            name: '',
            callToAction: Facebook.CallToActionType.LearnMore,
            destination: {
              displayUrl: '',
              url: ''
            },
            pagePostId: ''
          }
        }
      },
 * @param stepObject {ApplyTypesParams}
 * @returns {Function}
 */
export function ApplyTypes<
  ICreateAdModel extends {
    selectedTypes: {};
  },
  AR,
  AdType extends string | number
>(stepObject: ApplyTypesParams): Function {
  if (typeof stepObject != 'object') throw new Error(`@ApplyTypes -> 'stepObject' is not object.`);

  return function <C extends New>(constructor: C) {
    return class extends constructor {
      public changeType(step: MainStep, type: AdType): ICreateAdModel {
        const vm = this as unknown as CreateAdCommon<ICreateAdModel, AR, AdType>;

        if (typeof vm.createAdModel === 'undefined')
          throw new Error(
            '@ApplyTypes * The `createAdModel` object does not exist. Either create the `createAdModel` property or call the @UsesInitialize decorator.'
          );

        const targetType = pipe(get(stepObject, `${step}`)!, Object.keys, (o) =>
          o.find((t: string | number) => t == type)
        );

        vm.createAdModel.selectedTypes[step] = +targetType!;

        const targetStepObject = get(stepObject, `${step}.${targetType}`)!;

        Object.keys(targetStepObject).forEach((path) => {
          set(vm.createAdModel, path, targetStepObject[path]);
        });

        return vm.createAdModel;
      }
    };
  };
}
