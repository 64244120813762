import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GQLHttpCancelService {
  private requests: {
    [key: string]: Subject<any>;
  } = {};

  takeUntil<T>(id: string) {
    return takeUntil<T>(this.registerRequest(id));
  }

  finalize<T>(id: string) {
    return finalize<T>(() => {
      this.cancelRequest(id);
    });
  }

  private registerRequest(requestId: string) {
    const takeUntil$ = new Subject<void>();

    this.requests[requestId] = takeUntil$;
    return takeUntil$.asObservable();
  }

  private cancelRequest(requestId: string) {
    if (this.requests[requestId]) {
      this.requests[requestId].next();
      this.requests[requestId].complete();
      delete this.requests[requestId];
    }
  }
}
