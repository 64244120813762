import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Input = Bing.AudienceCampaign.ProfileDataSearchRequest;
type Output = Bing.AudienceCampaign.ProfileDataSearchResponse;

export const profileDataSearchBing = makeQuery<Output, Input>(`
query ProfileDataSearch(
  $limit: Float
  $searchKey: String
  $type: BingProfileType!
) {
  bing {
    profileDataSearch(limit: $limit, searchKey: $searchKey, type: $type) {
      fullName
      id
      type
    }
  }
}


`);
