import { makeQuery, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Output = QueryResult<'fetchTotalBudget', SmartCampaign.Backend.SmartTotalBudgetDto>;

export const fetchTotalBudgetQuery = makeQuery<Output, SmartCampaign.Backend.SmartCampaignId>(`
query FetchTotalBudget($smartCampaignId: String!) {
  ayn {
    fetchTotalBudget(smartCampaignId: $smartCampaignId) {
        budget
        smartCampaignId
        preferredCurrency
    }
  }
}
`);
