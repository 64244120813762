import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    Google, Linkedin, Platforms, SmartCampaignPlatforms, SupportedSmartCampaignObject
} from '@core/models';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import {
    SmartCampaignFormService
} from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.form';
import {
    SmartCampaignValidationService
} from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';

type PlatformsType = FormGroup<{
  [key in SmartCampaignPlatforms]?: FormGroup;
}>;

interface ConversionMyWebsiteForm {
  name: FormControl<string | null>;

  platforms: PlatformsType;
}

const platformForms = {
  facebook: new FormGroup({
    pixel: new FormControl('', [Validators.required]),
    conversionEvent: new FormControl('', [Validators.required])
  }),
  google: new FormGroup({
    conversionGoal: new FormControl([] as Google.CreateAd.GoogleConversionGoalInput[])
  }),
  bing: new FormGroup({
    conversionEvent: new FormControl('')
  }),
  linkedIn: new FormGroup({
    conversionEvent: new FormControl<Linkedin.LinkedInConversion | null>(null)
  }),
  tiktok: new FormGroup({
    pixelId: new FormControl('', [Validators.required]),
    pixelEventCode: new FormControl<string | null>(null, [Validators.required])
  })
};

@Component({
  selector: 'aayn-conversion-my-website--smart-campaign',
  templateUrl: 'conversion-my-website.component.html'
})
export class ConversionMyWebsiteSmartCampaignComponent implements OnInit {
  form = new FormGroup<ConversionMyWebsiteForm>({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),

    platforms: new FormGroup({})
  });

  adCreationModel = this.smartCampaignStateService.adCreationModel;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private formService: SmartCampaignFormService,
    private validationService: SmartCampaignValidationService
  ) {}

  ngOnInit() {
    this.formService.registerForm(this.form);

    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.adCreationModel.selectedPlatforms.forEach((platform) => {
        const platformName = SupportedSmartCampaignObject[platform];

        this.form.controls.platforms.addControl(platformName, platformForms[platformName]);
      });
    });

    this.form.statusChanges.subscribe((status) => {
      this.validationService.objectiveValid$.next(status === 'VALID');
    });
  }

  get facebookForm() {
    return this.form.get(['platforms', 'facebook']) as FormGroup;
  }

  get googleForm() {
    return this.form.get(['platforms', 'google']) as FormGroup;
  }

  get bingForm() {
    return this.form.get(['platforms', 'bing']) as FormGroup;
  }

  get linkedinForm() {
    return this.form.get(['platforms', 'linkedIn']) as FormGroup;
  }

  get tiktokForm() {
    return this.form.get(['platforms', 'tiktok']) as FormGroup;
  }

  get hasSelectedFacebook() {
    return this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Meta);
  }

  get hasSelectedGoogle() {
    return this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Google);
  }

  get hasSelectedBing() {
    return this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.Bing);
  }

  get hasSelectedLinkedin() {
    return this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.LinkedIn);
  }

  get hasSelectedTiktok() {
    return this.adCreationModel.selectedPlatforms.some((o) => o == Platforms.TikTok);
  }
}
