<aayn-ad-preview-card
  [platforms]="platforms"
  [(selectedPlatform)]="selectedPlatform"
  (selectedPlatformChange)="getBusiness($event)"
  [className]="(loader$ | async) ? 'aayn-ad-preview--card_loading' : ''"
>
  <div *ayn-loader="loader$ | async">
    <aayn-ad-preview-meta-single
      *ngIf="selectedPlatform === Platforms.Meta"
      [businessName]="business.businessName || ''"
      [businessImage]="business.businessLogo || ''"
      [cta]="adPreviewSmartCampaignService.dynamicCreative.callToAction || ''"
      [headline]="adPreviewSmartCampaignService.dynamicCreative.primaryText || ''"
      [shortHeadline]="adPreviewSmartCampaignService.dynamicCreative.headline || ''"
      [imageUrls]="adPreviewSmartCampaignService.dynamicCreative.images || []"
      [websiteUrl]="adPreviewSmartCampaignService.dynamicCreative.websiteUrl || ''"
      [description]="adPreviewSmartCampaignService.dynamicCreative.description || ''"
    >
    </aayn-ad-preview-meta-single>

    <aayn-ad-preview-instagram-single
      *ngIf="selectedPlatform === Platforms.Instagram"
      [businessName]="business.businessName || ''"
      [businessImage]="business.businessLogo || ''"
      [cta]="adPreviewSmartCampaignService.dynamicCreative.callToAction || ''"
      [headline]="adPreviewSmartCampaignService.dynamicCreative.primaryText || ''"
      [imageUrls]="adPreviewSmartCampaignService.dynamicCreative.images || []"
      [websiteUrl]="adPreviewSmartCampaignService.dynamicCreative.websiteUrl || ''"
    >
    </aayn-ad-preview-instagram-single>

    <aayn-ad-preview-google-tabs
      *ngIf="selectedPlatform === Platforms.Google"
      [availableTabs]="['display', 'search', 'youtube']"
      [title]="adPreviewSmartCampaignService.dynamicCreative.primaryText || ''"
      [titles]="adPreviewSmartCampaignService.dynamicCreative.googleHeadlines || []"
      [linkUrl]="adPreviewSmartCampaignService.dynamicCreative.websiteUrl || ''"
      [cta]="adPreviewSmartCampaignService.dynamicCreative.callToAction || ''"
      [description]="adPreviewSmartCampaignService.dynamicCreative.description || ''"
      [image]="adPreviewSmartCampaignService.dynamicCreative.images?.[0] || ''"
      [logoUrl]="adPreviewSmartCampaignService.dynamicCreative.businessLogo || ''"
    >
    </aayn-ad-preview-google-tabs>

    <aayn-ad-preview-linkedin-single
      *ngIf="selectedPlatform === Platforms.LinkedIn"
      [businessName]="business.businessName || ''"
      [businessImage]="business.businessLogo || ''"
      [websiteUrl]="adPreviewSmartCampaignService.dynamicCreative.websiteUrl || ''"
      [cta]="adPreviewSmartCampaignService.dynamicCreative.callToAction || ''"
      [headline]="adPreviewSmartCampaignService.dynamicCreative.shortHeadline || ''"
      [description]="adPreviewSmartCampaignService.dynamicCreative.description || ''"
      [imageUrls]="adPreviewSmartCampaignService.dynamicCreative.images || []"
    ></aayn-ad-preview-linkedin-single>

    <aayn-ad-preview-bing-single
      *ngIf="selectedPlatform === Platforms.Bing"
      [businessName]="adPreviewSmartCampaignService.dynamicCreative.businessName || ''"
      [headline]="adPreviewSmartCampaignService.dynamicCreative.shortHeadline || ''"
      [description]="adPreviewSmartCampaignService.dynamicCreative.description || ''"
      [imageUrls]="adPreviewSmartCampaignService.dynamicCreative.images || []"
    >
    </aayn-ad-preview-bing-single>

    <aayn-ad-preview-tiktok-single
      *ngIf="selectedPlatform === Platforms.TikTok"
      [imageUrls]="adPreviewSmartCampaignService.dynamicCreative.images || []"
      [headline]="adPreviewSmartCampaignService.dynamicCreative.shortHeadline || ''"
      [cta]="adPreviewSmartCampaignService.dynamicCreative.callToAction || ''"
      [websiteUrl]="adPreviewSmartCampaignService.dynamicCreative.websiteUrl || ''"
      [businessName]="adPreviewSmartCampaignService.dynamicCreative.tiktokBusiness?.displayName || ''"
      [businessLogo]="adPreviewSmartCampaignService.dynamicCreative.tiktokBusiness?.profileImage || ''"
    >
    </aayn-ad-preview-tiktok-single>
  </div>
</aayn-ad-preview-card>
