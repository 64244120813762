import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-location-selection-overlay-list',
  template: `
    <ul>
      <ng-content select="aayn-location-selection-overlay-item"></ng-content>
    </ul>
    <ng-content select="[aayn-location-selection-overlay-message]"></ng-content>
  `
})
export class LocationSelectionOverlayListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
