import { Base, Google } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Base.Dashboard.Global.IStartEndDateRequest;
type Output<BT extends Google.Dashboard.BreakdownType> = {
  breakdowns: {
    [key in BT]: Google.Dashboard.IAccountBreakdownResponse[key];
  };
};

export const breakdownsGoogle = <BT extends Google.Dashboard.BreakdownType>(
  breakdown: Google.Dashboard.BreakdownType
) =>
  makeQuery<Output<BT>, Input>(`
query GetBreakdowns($datePreset: DatePreset) {
  google {
    breakdowns(datePreset: $datePreset) {
       ${breakdown} {
        ${breakdown}
        costPerClick
        clicks
        cost
        clickRate
        conversions
        costPerConversion
        impressions
        conversionMetrics {
          conversions
          conversionCategory
          costPerConversions
        }
      }
    }
  }
}
`);
