import { Draft } from '@core/models';

import { makeQuery } from '../types';

type Input = { id: string };
type Output = Draft.IDraftItem;

export const getDraft = makeQuery<Output, Input>(`
  query GetDraft(
    $id: String!
  ) {
    draft(
      id: $id
    ) {
      id
      createdAt
      updatedAt
      name
      businessId
      content
      source
      adAccountId
    }
  }
`);
