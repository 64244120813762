import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { GQLHttpCancelService } from '@core/services/gql-http-cancel.service';
import { Observable } from 'rxjs';
import { WithRequestId } from '@core/queries/types';

@Injectable()
export class GraphqlCancelRequestInterceptor implements HttpInterceptor {
  constructor(private gqlHttpCancelService: GQLHttpCancelService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestId = (req.body as WithRequestId<{ variables: {} }>)?.variables?.__aayn_gql_req_id;
    if (!req.url.includes('graphql') || !requestId) {
      return next.handle(req);
    }

    (req.body as WithRequestId<{ variables: {} }>).variables.__aayn_gql_req_id = undefined;
    return next.handle(req).pipe(this.gqlHttpCancelService.takeUntil(requestId));
  }
}
