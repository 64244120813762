import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type LocationSelectionOverlayItemActiveType = {
  type: 'inclusions' | 'exclusions' | '';
  isActive: boolean;
};
// TODO: Implement ControlValueAccessor
@Component({
  selector: 'aayn-location-selection-overlay-item',
  templateUrl: './location-selection-overlay-item.component.html'
})
export class LocationSelectionOverlayItemComponent implements OnInit {
  @Input() type?: string;

  @Input() active?: LocationSelectionOverlayItemActiveType;
  @Input() canExclude = true;

  @Input() includeTexts = {
    includeText: 'Include',
    includedText: 'Added'
  };

  @Input() showLoader = false;

  @Output() onIncludeClick = new EventEmitter();
  @Output() onExcludeClick = new EventEmitter();
  @Output() onIncludeRemove = new EventEmitter();
  @Output() onExcludeRemove = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  includeClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.active?.isActive) {
      if (this.active?.type === 'inclusions' && event) {
        this.onIncludeRemove.emit();
      }
      return;
    }

    this.onIncludeClick.emit();
  }

  excludeClick(event?: MouseEvent) {
    event?.stopPropagation();
    if (this.active?.isActive) {
      if (this.active?.type === 'exclusions') {
        this.onExcludeRemove.emit();
      }
      return;
    }

    this.onExcludeClick.emit();
  }
}
