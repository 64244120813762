import { makeQuery, QueryResult } from '@core/queries';

export const isSmartBudgetActiveQuery = makeQuery<
  QueryResult<'isSmartBudgetActive', boolean>,
  { smartCampaignId: string }
>(`
query IsSmartBudgetActive($smartCampaignId: String!) {
  ayn {
    isSmartBudgetActive(smartCampaignId: $smartCampaignId)
  }
}
`);
