import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-callout-extensions-modal',
  templateUrl: './callout-extensions-modal.component.html'
})
export class CalloutExtensionsModalComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(public modal: NgbActiveModal, private googleStateService: GoogleStateService) {}

  calloutForm = new FormGroup({
    text1: new FormControl<string>(this.adCreationModel.ad.calloutExtension!.create!.texts[0], [Validators.required]),
    text2: new FormControl<string>(this.adCreationModel.ad.calloutExtension!.create!.texts[1], [Validators.required]),
    name: new FormControl<string>(this.adCreationModel.ad.calloutExtension!.create!.name, [Validators.required])
  });

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.calloutForm.valid) {
      const { name, text1, text2 } = this.calloutForm.value;
      const texts: string[] = [];
      texts.push(text1!, text2!);
      this.adCreationModel.ad.calloutExtension!.create.name = name!;
      this.adCreationModel.ad.calloutExtension!.create.texts = texts!;
      this.modal.close();
    }
  }
}
