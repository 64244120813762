import { CommonModule } from '@angular/common';
import {
    Component, ContentChild, ElementRef, EventEmitter, Input, NgModule, Output, TemplateRef
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InputModule } from '../input';
import { SelectModule } from '../select';

export type SortType = 'desc' | 'asc';

export type TableType = 'default' | 'primary' | '' | string;

export class TableConfigModel {
  rows?: TableRowModel[] | any[];
  columns?: TableColumnModel[];
}

export interface TableColumnModel {
  id?: number;
  name: string;
  displayText: string;
  selected?: boolean;

  /**
   * @description This prop using => pipes: 'date=>value'
   */
  pipes?: string;

  sorting?: boolean;
}

export interface TableRowModel {
  selected: false;
  progressValue?: number;
}

export interface PaginationSelectModel {
  displayText: string;
  value: number;
  selected?: boolean;
}

@Component({
  selector: 'ayn-table',
  templateUrl: 'table.component.html'
})
export class Table {
  @Input() config!: TableConfigModel;

  @ContentChild('header', { static: false }) header!: TemplateRef<ElementRef<HTMLTableHeaderCellElement>>;

  @ContentChild('body', { static: true }) body!: TemplateRef<ElementRef<HTMLTableRowElement[]>>;

  @Input() showPagination = this.config != null;

  @Input() info = false;

  @Input() totalCount = 0;

  @Input() totalCountPlaceholder?: number;

  @Input() pageViewCount = 10;

  @Input() currentPage = 1;

  @Input() tableHeight?: number;

  @Input() paginationOptions: PaginationSelectModel[] = [
    { displayText: '10', value: 10, selected: true },
    { displayText: '25', value: 25 },
    { displayText: '50', value: 50 },
    { displayText: '100', value: 100 }
  ];

  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  @Output() pageViewCountChange: EventEmitter<number> = new EventEmitter();

  @Input() type: TableType = '';

  sortDirection: SortType = 'asc';

  sortedColumn = '';

  isSorted = false;

  constructor() {}

  ngOnInit() {}

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }

  onPageViewCountChange(count: number) {
    this.pageViewCountChange.emit(count);
  }

  getColumnNameByIndex(col: TableColumnModel): string {
    const column = this.config.columns?.find((x) => x.name === col.name);

    return column?.name || '';
  }

  sort(col: TableColumnModel) {
    this.isSorted = true;

    this.config.rows = this.config?.rows?.sort((a, b) => {
      return this.sortDirection === 'asc' ? (a[col.name] > b[col.name] ? 1 : -1) : a[col.name] < b[col.name] ? 1 : -1;
    });

    this.sortedColumn = col.name;

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  }
}

@NgModule({
  imports: [CommonModule, SelectModule, FormsModule, InputModule],
  exports: [Table],
  declarations: [Table],
  providers: []
})
export class TableModule {}
