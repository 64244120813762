<form [formGroup]="paymentForm" (ngSubmit)="onSubmitChargebee($event)">
  <div class="aayn-auth--form-card">
    <ayn-form-field *ngIf="showAppsumoInput">
      <img
        class="aayn-auth--form-card-appsumo-icon ayn-prefix"
        src="/assets/images/pngs/appsumo-icon.png"
        alt="AppSumo"
      />

      <input
        type="text"
        ayn-input
        formControlName="appsumoCode"
        placeholder="AppSumo Code (Optional)"
        id="companyName"
      />

      <ayn-icon
        name="info"
        *ngIf="!paymentForm.controls.appsumoCode.value?.length"
        [ayn-tooltip]="appsumoDetail"
        position="right"
        [hideDelay]="2000"
      ></ayn-icon>

      <ng-template #appsumoDetail>
        <div class="aayn-auth-payment--appsumo-tooltip">
          <h6>I have a AppSumo Code</h6>

          <p>
            You can find your AppSumo code on your
            <a href="https://appsumo.com/account/products/" target="_blank">AppSumo Products Page </a>. <br />
            You can apply up to seven codes that you have purchased.
            <br />
            Please note, ADYOUNEED has not offered AppSumo packages <br />
            since 2020. This applies to existing codes only.
          </p>
        </div>
      </ng-template>

      <button
        ayn-button="primary"
        type="button"
        [loading]="applyAppsumoCodeLoader$ | async"
        [disabled]="applyAppsumoCodeLoader$ | async"
        *ngIf="paymentForm.controls.appsumoCode.value?.length"
        (click)="applyAppSumoCode()"
      >
        Apply Coupon
      </button>
    </ayn-form-field>
    <span class="aayn-payment--or-text" *ngIf="showAppsumoInput"></span>

    <p class="aayn-payment--applied-text" *ngIf="!showCreditCardForm">
      You may apply up to 7 codes <b>({{ appliedCodes.length }} code already applied)</b>
    </p>

    <ng-container *ngIf="showCreditCardForm">
      <div>
        <ayn-form-field>
          <input
            type="text"
            ayn-input
            formControlName="companyName"
            placeholder="Company name (Optional)"
            id="companyName"
          />
          <ayn-icon name="bag"></ayn-icon>
        </ayn-form-field>

        <ayn-form-field>
          <input
            type="text"
            ayn-input
            formControlName="cardHolderName"
            placeholder="Card holder name *"
            id="cardHolderName"
          />
          <ayn-icon name="user"></ayn-icon>
        </ayn-form-field>

        <div
          cbCardField
          [icon]="true"
          id="card-field"
          [fonts]="fonts"
          [styles]="styles"
          locale="en"
          [classes]="classes"
          (ready)="onReady($event)"
          class="ayn-input--payment fieldset field"
        >
          <div
            id="card-number"
            cbNumberField
            class="field empty"
            placeholder="Card Number *"
            (ready)="setFocus($event)"
            (change)="onChange($event)"
          ></div>

          <div
            id="card-expiry"
            cbExpiryField
            class="field empty"
            placeholder="MM / YY"
            (change)="onChange($event)"
          ></div>

          <div id="card-cvv" cbCvvField class="field empty" placeholder="CVV" (change)="onChange($event)"></div>

          <div class="loader" *ngIf="cbLoader"></div>
        </div>

        <div class="aayn-payment--country">
          <label for="country">Country *</label>

          <ayn-form-field>
            <ayn-select
              [setValueHtml]="true"
              [showTick]="true"
              [minWidth]="120"
              id="country"
              formControlName="country"
              placeholder="Search country"
            >
              <ayn-option
                *ngFor="let item of countries | aynOptionFilter | async"
                [value]="item.code"
                [title]="item.name"
              >
                <span class="flag-icon" [ngClass]="'flag-icon-' + item.code"></span>
                {{ item.name }}
              </ayn-option>
              <ayn-icon name="location" class="ayn-icon-left"></ayn-icon>
            </ayn-select>
          </ayn-form-field>
        </div>
      </div>
    </ng-container>
  </div>

  <button
    [ayn-button]="buttonType"
    [loadingColor]="buttonType == 'primary' ? '' : 'dark'"
    [disabled]="loading$ | async"
    type="submit"
    [loading]="loading$ | async"
    class="aayn-auth--submit-button"
    [style.width.px]="270"
  >
    {{ submitButtonProps.text }}

    <ayn-icon [name]="submitButtonProps.icon" *ngIf="submitButtonProps.icon" class="ml:4"></ayn-icon>
  </button>
</form>
