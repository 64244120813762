import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Platforms } from '@core/models';
import { Select } from '@ngxs/store';
import {
  BingStateService,
  CreateAdState,
  FacebookStateService,
  GoogleStateService,
  LinkedinStateService,
  SmartCampaignStateService,
  TiktokStateService
} from '@pages/create-ad/state';

@Component({
  selector: 'aayn-select-an-ad-type-platforms--header',
  templateUrl: './select-an-ad-type-platforms-header.component.html'
})
export class SelectAnAdTypePlatformsHeaderComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  protected googleAdCreationModel = this.googleStateService.adCreationModel;

  protected facebookAdCreationModel = this.facebookStateService.adCreationModel;

  protected bingAdCreationModel = this.bingStateService.adCreationModel;

  protected linkedinAdCreationModel = this.linkedInStateService.adCreationModel;

  protected tiktokAdCreationModel = this.tiktokStateService.adCreationModel;

  protected smartCampaignAdCreationModel = this.smartCampaignStateService.adCreationModel;

  constructor(
    private googleStateService: GoogleStateService,
    private facebookStateService: FacebookStateService,
    private bingStateService: BingStateService,
    private linkedInStateService: LinkedinStateService,
    private tiktokStateService: TiktokStateService,
    private smartCampaignStateService: SmartCampaignStateService
  ) {}

  ngOnInit(): void {}
}
