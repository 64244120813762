import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { createUploadImages, Facebook, FacebookService, getFileExtension, Upload, UploadImageItem } from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';

import { LibraryFacebookComponent } from '../library/library.component';
import { AspectRatio } from '@shared/models';

@Component({
  selector: 'aayn-upload-ads-images--facebook',
  templateUrl: 'upload-ads-images.component.html'
})
export class UploadAdsImagesFacebookComponent implements OnInit {
  uploadedImages: Facebook.IdWithUrl[] = [];

  protected images: UploadImageItem[] = createUploadImages(10);

  protected isValid: boolean = true;
  aspectRatios: AspectRatio.AspectRatioItem[] = [
    {
      label: '1:1',
      ratio: '1:1',
      selected: true
    },
    {
      label: '1.91:1',
      ratio: '1.91:1'
    },
    {
      label: '9:16',
      ratio: '9:16'
    },
    {
      label: 'Original'
    }
  ];

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private facebookService: FacebookService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.uploadedImages.forEach((_, i) => {
      this.images[i].startedUploading = true;
      this.images[i].progressValue = 100;
    });
  }

  openLibrary() {
    const modalRef = this.modalService.open(LibraryFacebookComponent, {});
    (modalRef.componentInstance as LibraryFacebookComponent).maxImages = 10 - this.uploadedImages.length;
    modalRef.closed.subscribe((result: { images: Facebook.IdWithUrl[] }) => {
      if (!result?.images) return;

      result.images.forEach((image) => {
        const existsImage = this.uploadedImages.some((o) => o.id === image.id);

        if (existsImage) return;

        if (this.uploadedImages.length >= 10) return;
        this.uploadedImages = this.uploadedImages.filter((o) => !!o.id);
        this.uploadedImages.push(image);
        const imageIndex = this.images.findIndex((o) => !o.completed);

        if (imageIndex !== -1) {
          this.images[imageIndex].progressValue = 100;
        }
      });

      this.cdr.detectChanges();
    });
  }

  onFileAdded(fileObject: IFileAddedEvent) {
    if (fileObject.file.type.indexOf('video') > -1) {
      this.uploadVideo(fileObject);

      return;
    }

    const fileExt = getFileExtension(fileObject.file);

    const isImage = fileExt?.match(/jpeg|png|jpg/) || fileObject.file.type.indexOf('image');

    if (isImage) {
      this.uploadImage(fileObject);
    }
  }

  uploadVideo({ file, fileUrl }: IFileAddedEvent) {
    const formData = new FormData();

    formData.append('file', file);

    const video = this.images.find((image) => !image.startedUploading && !image.completed)!;

    this.facebookService.uploadVideo$(formData).subscribe((uploadResult) => {
      video.startedUploading = true;
      video.progressValue = uploadResult.progress;

      if (!environment.production) console.log('[CreateAd.Facebook.UploadResult]: ', uploadResult);

      if (uploadResult.state !== 'DONE') return;

      video.progressValue = 100;

      const uploadImageIndex = this.images.findIndex((i) => i === video);
      this.uploadedImages[uploadImageIndex] = {
        id: uploadResult.result.id,
        url: fileUrl || '',
        type: 'video'
      };

      this.toastrService.success('Video upload successful.', 'Successful');
    });
  }

  uploadImage({ file, fileUrl }: IFileAddedEvent) {
    const formData = new FormData();

    formData.append('file', file);

    const image = this.images.find((image) => !image.startedUploading && !image.completed)!;

    this.facebookService.uploadImage$(formData).subscribe({
      next: (uploadResult) => {
        image.startedUploading = true;
        image.progressValue = uploadResult.progress;

        if (!environment.production) console.log('[CreateAd.Facebook.UploadResult]: ', uploadResult);

        if (uploadResult.state !== 'DONE') return;

        image.progressValue = 100;

        const body: Facebook.CreateAd.IUploadImageResponse = (uploadResult as Upload)?.result;

        const uploadImageIndex = this.images.findIndex((i) => i === image);
        this.uploadedImages[uploadImageIndex] = {
          id: body.hash,
          url: fileUrl!,
          type: 'image'
        };

        this.toastrService.success('Image upload successful.', 'Successful');
      },
      error: ({ error }: { error: IRestError }) => {
        if (error?.message) {
          error?.message?.forEach((o) => {
            this.toastrService.warning(o);
          });

          image.startedUploading = false;
          image.progressValue = 0;
        }
      }
    });
  }

  remove({ fileUrl }: { fileUrl: string }) {
    const index = this.uploadedImages.findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages = this.uploadedImages.filter((_, i) => i !== index);
      this.images.forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images.length - 1 && this.images[i + 1].startedUploading) {
            this.images[i] = this.images[i + 1];
            this.images[i + 1] = new UploadImageItem();
          } else {
            this.images[i] = new UploadImageItem();
          }
        }
      });
    }
    this.cdr.detectChanges();
  }

  closeModal() {
    this.modal.close({
      images: this.uploadedImages
    });
  }

  saveModal() {
    if (this.uploadedImages.length > 0) {
      this.modal.close({
        images: this.uploadedImages.filter((o) => !!o.id)
      });
    } else this.isValid = false;
  }

  selectedFileChange(index: number) {
    this.uploadedImages[index] = {
      id: '',
      url: ''
    };
  }
}
