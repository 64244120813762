import { Google } from '@core/models';
import { EdgeResult, makeQuery } from '@core/queries/types';

const queryField = 'assetGroups';

type Input = Google.Dashboard.IAssetsGroupRequest;
type Output = EdgeResult<typeof queryField, Google.Dashboard.IAssetGroup>;

export const assetGroups = makeQuery<Output, Input>(`
query GetAssetGroups(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $searchKey: String
  $status: GoogleInsightStatus
  $campaignId: Float!
) {
  google {
    ${queryField}(
      first: $first
      after: $after
      last: $last
      status: $status
      before: $before
      searchKey: $searchKey
      campaignId: $campaignId
    ) {
      edges {
        node {
          name
          id
          status
          resourceName
          adStrength
          finalMobileUrls
          finalUrls 
        }
      }
    }
  }
}
`);
