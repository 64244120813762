<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Name your campaign' | translate }}</h4>
    <p class="aayn-select-objective--header__description">
      {{ 'This will only be visible to you in your dashboard.' | translate }}
    </p>
  </header>

  <form [formGroup]="traficWebsiteForm">
    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="name"
          aaynDraftName
          [placeholder]="'Campaign Name' | translate"
          id="campaignName"
          [(ngModel)]="adCreationModel!.campaign.name"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
