<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  aaynTextAiContainer
  [adPreviewContainer]="adPreviewService.carouselCreative"
>
  <aayn-text-generator-ai></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Headline for ad" description="Write a headline">
      <aayn-create-ad-input
        inputType="textarea"
        formControlName="primaryText"
        [placeholder]="'Tell people what your ad is about'"
        [requiredMessage]="'You must write at least 1 headline.' | translate"
        adPreview="primaryText"
        textAiSection="headlines"
        [standAlone]="true"
        [previewActive]="true"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <section
    class="aayn-ad-type__mt-spacing aayn-ad-type--carousel-container"
    cdkDropList
    (aynDrag)="dropped($event)"
    dragControl
  >
    <ng-container *ngFor="let carouselCard of carouselCards.controls; index as i">
      <form [formGroup]="$any(carouselCard)" class="aayn-ad-type--carousel-container--form" [ignoreInvalid]="true">
        <aayn-carousel-card
          [saveDisabled]="carouselCard.invalid"
          [removeDisabled]="carouselCards.controls.length <= 2"
          (remove)="deletePaginationItem(i)"
          [collapsed]="!carouselCard.invalid && !carouselCard.dirty"
          adPreview="cards"
        >
          <ayn-file-upload-area
            [imageCount]="1"
            (fileUploadClick)="openFileModal()"
            title="Click this area to UPLOAD"
            description="Facebook is recommending this size to be in this pixel range to get the best results possible."
            [imageUrls]="carouselCard.get('imageUrl')?.value ? [carouselCard.get('imageUrl')?.value] : []"
            [single]="true"
            (imageError)="getImages(i)"
            [showRequiredAlert]="!carouselCard.get('imageUrl')?.value && _form.submitted"
            adPreview="image"
          >
          </ayn-file-upload-area>

          <aayn-create-ad-input
            [placeholder]="'Tell people what your ad is about' | translate"
            [requiredMessage]="'You must write at least 1 description.' | translate"
            formControlName="description"
            [maxCharacterNumber]="100"
            adPreview="title"
            textAiSection="headlines"
          >
          </aayn-create-ad-input>

          <aayn-create-ad-input
            [placeholder]="'Write a short headline' | translate"
            [requiredMessage]="'You must write short description.' | translate"
            formControlName="headline"
            [maxCharacterNumber]="100"
            adPreview="body"
            textAiSection="headlines"
          >
          </aayn-create-ad-input>
        </aayn-carousel-card>
      </form>
    </ng-container>
    <button
      class="aayn-carousel-add-more"
      type="button"
      (click)="addPaginationItem()"
      [disabled]="carouselCards.invalid || carouselCards.controls.length >= 10"
    >
      {{ 'Add More Carousel Card +' | translate }}
    </button>
  </section>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToAction"
            adPreview="cta"
            [standAlone]="true"
            [placeholder]="'Select CTA' | translate"
            [showTick]="true"
            [showSearch]="false"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <form formGroupName="destination" [ignoreInvalid]="true">
          <ayn-form-field>
            <div class="ayn-prefix">
              {{ 'URL' | translate }}
            </div>

            <input
              type="text"
              placeholder="https://adyouneed.com"
              formControlName="displayUrl"
              ayn-input
              adPreview="websiteUrl"
              (ngModelChange)="setDestinationUrl($event)"
              [ngModel]="adCreationModel?.ad?.creative?.destination?.url"
              #urlInput
            />
          </ayn-form-field>
        </form>
      </section>
      <ayn-form-field class="aayn-ad-type--control aayn-ad-type__mt-spacing">
        <input
          type="text"
          ayn-input
          [placeholder]="'Ad Name' | translate"
          formControlName="name"
          (ngModelChange)="adCreationModel.ad.name = $event"
        />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
