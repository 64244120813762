import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip';
import { TooltipDirective } from './tooltip.directive';

const COMPONENTS = [TooltipComponent, TooltipDirective];

@NgModule({
  imports: [CommonModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: []
})
export class TooltipModule {}
