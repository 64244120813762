import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef,
    Input, NgModule, Output, ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

import { ObjectUtils } from '../utils';

export type CheckBoxType = 'default' | 'toggle' | 'payment' | 'chart' | 'audience' | '';

@Component({
  selector: 'ayn-checkbox',
  templateUrl: 'checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Checkbox),
      multi: true
    }
  ]
})
export class Checkbox {
  @Input() value?: any;

  @Input() name?: string;

  @Input() disabled?: boolean | null;

  @Input() binary?: boolean;

  @Input() ariaLabelledBy?: string;

  @Input() ariaLabel?: string;

  @Input() tabindex?: number;

  @Input() inputId?: string;

  @Input() style: any;

  @Input() checkBoxType?: CheckBoxType = 'default';

  @Input() labelStyleClass?: string;

  @Input() formControl?: UntypedFormControl;

  @Input() readonly?: boolean;

  @Input() required?: boolean;

  @Input() trueValue: any = true;

  @Input() falseValue: any = false;

  @Input() labelShow: boolean = false;

  @Input() label: string = '';

  @ViewChild('cb') inputViewChild?: ElementRef;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Input() model: any;

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  focused: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  onClick(
    event: { preventDefault: () => void; stopPropagation: () => void },
    checkbox: { focus: () => void },
    focus: boolean
  ) {
    event.preventDefault();
    event.stopPropagation();

    if (this.disabled || this.readonly) {
      return;
    }

    this.updateModel(event);

    if (focus) {
      checkbox.focus();
    }
  }

  updateModel(event: { preventDefault: () => void }) {
    let newModelValue;

    if (!this.binary) {
      if (this.checked) newModelValue = this.model.filter((val: any) => !ObjectUtils.equals(val, this.value));
      else newModelValue = this.model ? [...this.model, this.value] : [this.value];

      this.onModelChange(newModelValue);
      this.model = newModelValue;

      if (this.formControl) {
        this.formControl.setValue(newModelValue);
      }
    } else {
      newModelValue = this.checked ? this.falseValue : this.trueValue;
      this.model = newModelValue;
      this.onModelChange(newModelValue);
    }

    this.onChange.emit({ checked: newModelValue, originalEvent: event });
  }

  handleChange(event: { preventDefault: () => void }) {
    if (!this.readonly) {
      this.updateModel(event);
    }
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
    this.onModelTouched();
  }

  focus() {
    this.inputViewChild?.nativeElement.focus();
  }

  writeValue(model: any): void {
    this.model = model;
    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  get checked() {
    return this.binary ? this.model === this.trueValue : ObjectUtils.contains(this.value, this.model);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [Checkbox],
  declarations: [Checkbox],
  providers: []
})
export class CheckboxModule {}
