import { makeQuery } from '../types';
import { IGetBusinessBrandResponse } from '@core/models';

type Input = {
  businessId: string;
};
type Output = IGetBusinessBrandResponse;

export const getBusinessBrand = makeQuery<Output, Input>(`
query GetBusinessBrand($businessId: String!) {
  getBusinessBrand(businessId: $businessId) {
    brandUrl
    color1
    color2
    id
  }
}

`);
