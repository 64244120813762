import { makeQuery, OffsetPaginatedQueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'accountVideos';

type Input = TikTok.TiktokPaginationRequest;
type Output = OffsetPaginatedQueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokAccountVideo>;

export const accountVideosQuery = makeQuery<Output, Input>(`
query TiktokAccountVideos($skip: Int, $take:Int, $page:Int, $videoIds: [String!]) {
  tiktok {
    accountVideos(skip:$skip, take:$take, page:$page, videoIds: $videoIds) {
      edges {
        cursor
        node {
          fileName
          videoId
          videoCoverUrl
          previewUrl
          format
          displayable
          height
          width
          bitRate
          createTime
          modifyTime
          signature
          duration
          size
          allowedPlacements
          allowDownload
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        to
        from
      }
    }
  }
}
`);
