<aayn-manuel-audience-setup>
  <form [formGroup]="form">
    <aayn-audience-group>
      <aayn-audience-item
        title="Location"
        description="Choose your target locations. Your ads will only be shown to people in these areas."
      >
        <aayn-location-selection--bing [form]="form"></aayn-location-selection--bing>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience--height:300">
      <aayn-audience-item
        [title]="'Age Range' | translate"
        description="Define your target age range. Your ads will only appear to individuals within this age bracket."
      >
        <aayn-audience-age-range
          [value]="adCreationModel.campaign.criteria.minAge"
          [maxValue]="adCreationModel.campaign.criteria.maxAge"
          (ageRangeChanged)="ageRangeChanged($event)"
        ></aayn-audience-age-range>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Gender' | translate"
        [title]="'Gender' | translate"
        description="Select the gender of your target audience. Your ads will be displayed exclusively to the selected gender."
      >
        <aayn-gender-selection--bing [form]="form"></aayn-gender-selection--bing>
      </aayn-audience-item>

      <aayn-audience-item [title]="'Narrow Targeting' | translate">
        <ayn-form-field>
          <ayn-select
            [placeholder]="'Use narrower criterion' | translate"
            (valueChange)="valueChange($event)"
            [multiple]="true"
            [options]="criterions"
            class="aayn-create-ad--select-v1"
            formControlName="narrowerTargetCriteria"
          >
          </ayn-select>
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item
        [title]="'Detailed Targeting' | translate"
        description="Determine your target audience to avoid ad display for uninterested individuals."
      >
        <aayn-detailed-targeting--bing [form]="form"></aayn-detailed-targeting--bing>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item [title]="'Audience Name' | translate" [flexed]="true">
        <ayn-form-field [style.max-width]="'65%'" class="ml:auto">
          <input
            type="text"
            formControlName="audienceName"
            ayn-input
            [placeholder]="'Write a audience name' | translate"
          />
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>
  </form>
</aayn-manuel-audience-setup>
