import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { selections: Facebook.IdTypeModel[] };

type Output = {
  locales: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
};

export const getDetailedTargetingSuggesstions = makeQuery<Output, Input>(`
  query GetDetailedSuggesstions($selections: [SuggesstionArg!]!) {
    facebook {
      detailedTargetingSuggesstions(selections: $selections){
        id
        name
        type
        path
        description
        audienceSizeLowerBound
        audienceSizeUpperBound
      }
    }
  }
`);
