import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICreateAdSelectItem } from '@pages/create-ad/models';

type ObjectiveKeys = 'objective' | 'objectiveType';
//TODO: Make generic operator
export function mapObjectives<T, K extends ObjectiveKeys | undefined = ObjectiveKeys>(
  objectives: Array<ICreateAdSelectItem<T>>,
  objectiveKey?: K
): (
  source$: Observable<{
    campaign?: K extends undefined ? any : { [key in Exclude<K, undefined>]: T };
    selectedTypes: { campaign: T };
  }>
) => Observable<Array<ICreateAdSelectItem<T>>> {
  return (source$) =>
    source$.pipe(
      map((model) =>
        objectives.map((objective) => ({
          ...objective,
          active:
            objectiveKey && model?.campaign?.[objectiveKey as ObjectiveKeys]
              ? objective.type === model.campaign[objectiveKey as ObjectiveKeys]
              : objective.type === model.selectedTypes.campaign
        }))
      )
    );
}

type AdTypeKeys = 'ad';
//TODO: Make generic operator
export function mapAdTypes<T, K extends AdTypeKeys | undefined = AdTypeKeys>(
  adTypes: Array<ICreateAdSelectItem<T>>,
  adTypeKey?: K
): (
  source$: Observable<{
    campaign?: any;
    selectedTypes?: { ad: any; campaign: any };
  }>
) => Observable<Array<ICreateAdSelectItem<T>>> {
  return (source$) =>
    source$.pipe(
      map((model) =>
        adTypes.map((adType) => ({
          ...adType,
          active:
            adTypeKey && model?.selectedTypes?.[adTypeKey as AdTypeKeys]
              ? adType.type === model.selectedTypes?.[adTypeKey as AdTypeKeys]
              : adType.type === model.selectedTypes?.ad
        }))
      )
    );
}
