<aayn-upload-image-modal
  header="Select Images"
  icon="folder"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
>
  <!-- LandscapeImageMedia -->
  <aayn-upload-image-modal--header
    headerLeft="1.91:1 Landscape Images"
    headerLeftDescription="Add up to 3 Landscape"
    headerRight="Choose From Library"
    (headerRightClick)="openLibrary('landscapeImageMedia', 3)"
    headerRightIcon="file"
  ></aayn-upload-image-modal--header>
  <section class="upload-ads-images--body">
    <!-- Reason of min image size is 764x400 instead of 703x368, 703x368 ratio is not exactly 1.91:1 nearest dimension is 764x400-->
    <aayn-file-upload-item
      *ngFor="let file of images.landscapeImageMedia; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.landscapeImageMedia.valid"
      aspectRatio="1.91:1"
      (fileAdded)="uploadImage('landscapeImageMedia', $event)"
      (remove)="remove($event, 'landscapeImageMedia')"
      [selectedFileUrl]="uploadedImages.landscapeImageMedia![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'landscapeImageMedia')"
      [progressItem]="file"
      [minImageSize]="{ width: 764, height: 400 }"
      [maxImageSize]="{ width: 2592 }"
      canvaDesignType="FacebookAd"
      aspectRatioLabel="1.91:1 landscape Marketing"
    ></aayn-file-upload-item>
  </section>
  <!-- LandscapeImageMedia End-->

  <!-- 1:1 SQUARE -->
  <aayn-upload-image-modal--header
    headerLeft="1:1 Square Images"
    headerLeftDescription="Add up to 3 Square"
    headerRight="Choose From Library"
    (headerRightClick)="openLibrary('squareImageMedia', 3)"
    headerRightIcon="file"
  ></aayn-upload-image-modal--header>
  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.squareImageMedia; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.squareImageMedia.valid"
      aspectRatio="1:1"
      (fileAdded)="uploadImage('squareImageMedia', $event)"
      (remove)="remove($event, 'squareImageMedia')"
      [selectedFileUrl]="uploadedImages.squareImageMedia![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'squareImageMedia')"
      [minImageSize]="{ width: 128, height: 128 }"
      [maxImageSize]="{ width: 2048 }"
      [progressItem]="file"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1:1 Square Marketing"
    ></aayn-file-upload-item>
  </section>
  <!-- 1:1 SQUARE END -->

  <!-- 1.5:1 imageMedia15x10 -->
  <aayn-upload-image-modal--header
    headerLeft="1.5:1 image Media"
    headerLeftDescription="Add up to 3 images"
    headerRight="Choose From Library"
    (headerRightClick)="openLibrary('imageMedia15X10', 3)"
    headerRightIcon="file"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.imageMedia15X10; let index = index"
      [isPrimary]="false"
      aspectRatio="1.5:1"
      (fileAdded)="uploadImage('imageMedia15X10', $event)"
      (remove)="remove($event, 'imageMedia15X10')"
      [selectedFileUrl]="uploadedImages.imageMedia15X10![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'imageMedia15X10')"
      [progressItem]="file"
      [minImageSize]="{ width: 300, height: 200 }"
      [maxImageSize]="{ width: 1500, height: 1000 }"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1.5:1 imageMedia15x10 Marketing"
    ></aayn-file-upload-item>
  </section>
  <!-- 1:1 imageMedia15x10 END -->

  <!-- 1.78:1 imageMedia178X100 -->
  <aayn-upload-image-modal--header
    headerLeft="1.78:1 image Media"
    headerLeftDescription="Add up to 3 images"
    headerRight="Choose From Library"
    (headerRightClick)="openLibrary('imageMedia178X100', 3)"
    headerRightIcon="file"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.imageMedia178X100; let index = index"
      [isPrimary]="false"
      aspectRatio="1.78:1"
      (fileAdded)="uploadImage('imageMedia178X100', $event)"
      (remove)="remove($event, 'imageMedia178X100')"
      [selectedFileUrl]="uploadedImages.imageMedia178X100![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'imageMedia178X100')"
      [progressItem]="file"
      [minImageSize]="{ width: 40, height: 40 }"
      [maxImageSize]="{ width: 2592 }"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1.78:1 imageMedia178X100 Marketing"
    ></aayn-file-upload-item>
  </section>
  <!-- 1:1 imageMedia15x10 END -->

  <!-- 1.33:1 imageMedia133X100 -->
  <aayn-upload-image-modal--header
    headerLeft="1.33:1 image Media"
    headerLeftDescription="Add up to 3 images"
    headerRight="Choose From Library"
    (headerRightClick)="openLibrary('imageMedia133X100', 3)"
    headerRightIcon="file"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.imageMedia133X100; let index = index"
      [isPrimary]="false"
      aspectRatio="1.33:1"
      (fileAdded)="uploadImage('imageMedia133X100', $event)"
      (remove)="remove($event, 'imageMedia133X100')"
      [selectedFileUrl]="uploadedImages.imageMedia133X100![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'imageMedia133X100')"
      [progressItem]="file"
      [minImageSize]="{ width: 40, height: 40 }"
      [maxImageSize]="{ width: 2592 }"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1.33:1 imageMedia133X100 Marketing"
    ></aayn-file-upload-item>
  </section>
  <!-- 1:1 imageMedia15x10 END -->
</aayn-upload-image-modal>
