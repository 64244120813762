import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { AdcImageGenerationState } from '@pages/create-ad/state/adc-image-generation/adc-image-generation.state';
import { AdcService } from '@core/services/adc.service';
import { createObjectURL, revokeObjectURL } from '@core/utils';
import { ImageSkeletonDirective } from '@ayn-ui/lib/modules/base/skeleton';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ImageGenerationTask } from '@pages/create-ad/state/adc-image-generation/actions';

@UntilDestroy()
@Directive({
  selector: 'img[ayn-adc-image-tracker]',
  exportAs: 'adcImageTracker'
})
export class AdcImageTrackerDirective implements OnChanges, OnDestroy {
  @Input('ayn-adc-image-tracker') taskId!: string;
  @Output() imageLoad = new EventEmitter<ImageGenerationTask & { url: string }>();

  loader$ = new BehaviorSubject(true);

  private url?: string;

  private subscription?: Subscription;
  constructor(
    private store: Store,
    private elRef: ElementRef<HTMLImageElement>,
    private adcService: AdcService,
    @Optional() imageSkeletonDirective?: ImageSkeletonDirective
  ) {
    if (imageSkeletonDirective) {
      imageSkeletonDirective.setLoaderDirection('cross-right');
      imageSkeletonDirective.skeletonImage = '';
      imageSkeletonDirective.setLoader(this.loader$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskId) {
      this.subscription?.unsubscribe();
      if (changes.taskId.currentValue) {
        this.loader$.next(true);
        this.subscription = this.store
          .select(AdcImageGenerationState.getTask(this.taskId))
          .pipe(
            filter((task) => task?.status === 5),
            switchMap((task) => this.getImage(task!)),
            untilDestroyed(this)
          )
          .subscribe();
      } else {
        this.loader$.next(false);
      }
    }
  }

  getImage(task: ImageGenerationTask) {
    return this.adcService.downloadImage(task.id, task.imageRenderProcessId).pipe(
      tap((image) => {
        this.url = createObjectURL(image);
        this.elRef.nativeElement.src = this.url;
        this.loader$.next(false);
        this.imageLoad.next({ ...task, url: this.url });
        this.subscription?.unsubscribe();
      })
    );
  }

  ngOnDestroy() {
    if (this.url) {
      revokeObjectURL(this.url);
    }
  }
}
