import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export function selectedItem<T>(
  factory: (objective: ICreateAdSelectItem<T>) => void
): (source$: Observable<Array<ICreateAdSelectItem<T>>>) => Observable<Array<ICreateAdSelectItem<T>>> {
  return (source$) =>
    source$.pipe(
      tap((objectives) => {
        const objective = objectives.find((x) => x.active);
        if (objective) {
          factory(objective);
        }
      })
    );
}
