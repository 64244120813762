import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IGoogleEditableAdGroupRequest;
type Output = { editAdGroup: boolean };

export const editAdGroupGoogle = makeMutation<Output, Input>(`
mutation editAdGroupGoogle(
  $editableAdGroups: [GoogleEditableAdGroupInputDto!]!
) {
  google {
    editAdGroup(editableAdGroups: $editableAdGroups)
  }
}
`);
