import { Injectable } from '@angular/core';
import { pipe } from 'fp-ts/lib/function';
import { map } from 'fp-ts/lib/Array';
import { fromEntries } from 'fp-ts/lib/Record';
import { deriveTuple } from '@core/utils';

export type ThemeColorVariables = 'primary-rgb' | 'accent-rgb' | 'gray-rgb' | 'gray-500' | 'body-bg-img' | 'body-bg';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() {}

  colorNames: ThemeColorVariables[] = ['primary-rgb', 'accent-rgb', 'gray-rgb', 'gray-500', 'body-bg-img', 'body-bg'];

  modifyThemeColor(color: ThemeColorVariables, value: string): void {
    document.documentElement.style.setProperty(`--${color}`, value);
  }

  resetThemeColor(color: ThemeColorVariables): void {
    document.documentElement.style.removeProperty(`--${color}`);
  }

  resetAllThemeColors(): void {
    this.colorNames.forEach((color) => {
      this.resetThemeColor(color);
    });
  }

  resetFavicon(): void {
    this.modifyFavicon('favicon.ico');
  }

  getThemeColor(color: ThemeColorVariables): string {
    return getComputedStyle(document.body).getPropertyValue(`--${color}`);
  }

  getThemeColors(): Record<ThemeColorVariables, string> {
    return pipe(this.colorNames, map(deriveTuple(this.getThemeColor)), fromEntries);
  }

  modifyFavicon(icon: string): void {
    const link = document.querySelector('#aynFavicon') as HTMLLinkElement;
    link.href = icon;
  }
}
