import { Directive, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ObjectUtils, TabItem } from '@ayn-ui/public-api';
import { ReplaySubject } from 'rxjs';
import { ChildTabValidateFn, TabValidateDirective, TabValidateStatus } from './tab-validate.directive';
import { AdPlatforms } from '@core/models';

export type ISmartResponseCatcherDirectiveParams<Key extends string | number = string, T = any> = Record<Key, T[]>;

@Directive({
  selector: '[aayn-smart-response-catcher]',
  standalone: true,
  hostDirectives: [TabValidateDirective]
})
export class SmartResponseCatcherDirective implements OnChanges {
  @Input('aayn-smart-response-catcher') apiResponse?: ISmartResponseCatcherDirectiveParams;
  @Input() validateFn?: ChildTabValidateFn;
  @Input() selectedPlatforms: AdPlatforms[] = [];
  readonly tabValidate = inject(TabValidateDirective);

  constructor() {
    this.tabValidate.validateFn = (tabItem) => this.validate(tabItem);
    this.tabValidate.validate$ = new ReplaySubject<void>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apiResponse.currentValue) {
      setTimeout(() => {
        this.processTabs();
      }, 500);
    }
  }

  processTabs() {
    this.tabValidate.validate$.next();
  }

  validate(tabItem: TabItem): TabValidateStatus {
    const status = this.validateFn?.(tabItem);
    if (status) return status;
    if (!this.apiResponse || ObjectUtils.isEmpty(this.apiResponse)) return TabValidateStatus.Raw;
    if (tabItem.index == -1) return TabValidateStatus.Raw;
    const platform = this.selectedPlatforms[tabItem.index];
    const data = this.apiResponse[platform];
    return !!data?.length ? TabValidateStatus.Valid : TabValidateStatus.Invalid;
  }
}
