import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export type TypedPropertyKeys<C, T, K extends keyof C = keyof C> = {
  [P in K]: C[P] extends T ? P : never;
}[K];

export type ObservableKeys<T> = TypedPropertyKeys<T, Observable<any>>;

/**
 * Decorator that will run the decorated method after the specified property has emitted.
 * @param propertyName The name of the property that emits the value to trigger the decorated method.
 */
export function RunAfter<T, K extends ObservableKeys<T> = ObservableKeys<T>>(propertyName: K) {
  return function _runAfter(_: T, __: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const stream = (this as T)[propertyName] as Observable<any>;
      stream.pipe(take(1)).subscribe(() => {
        original.apply(this, args);
      });
    };
    return descriptor;
  };
}
