import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'interestSearch';
type Input = TikTok.CreateAd.Backend.InterestSearch;
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[]>;

export const interestSearchQuery = makeQuery<Output, Input>(`
query InterestsSearch($searchKey:String!, $limit:SafeInt) {
  tiktok {
    interestSearch(searchKey: $searchKey, limit: $limit) {
      name
      id
    }
  }
}
`);
