import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[ayn-skeleton]' })
export class SkeletonDirective {
  @Input('ayn-skeleton') isLoading?: boolean | Observable<boolean> | null = false;

  private skeletonClassName = 'ayn-skeleton';

  subscription?: Subscription;

  constructor(protected el: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      let isLoading = changes.isLoading.currentValue;
      this.subscription?.unsubscribe();
      if (isLoading instanceof Observable) {
        this.subscription = isLoading.pipe(untilDestroyed(this)).subscribe((value) => this.processIsLoading(value));
      } else {
        this.processIsLoading(isLoading);
      }
    }
  }

  processIsLoading(value: boolean) {
    if (value) {
      this.el.nativeElement.classList.add(this.skeletonClassName);
    } else {
      this.el.nativeElement.classList.remove(this.skeletonClassName);
    }
  }
}

@UntilDestroy()
@Directive({ selector: 'img[ayn-skeleton-img]' })
export class ImageSkeletonDirective implements AfterViewInit, OnChanges {
  afterViewInit$ = new ReplaySubject();

  @Input('ayn-skeleton-img-loader') isLoading?: boolean | Observable<boolean> | null = false;

  @Input('loaderDirection') loaderDirection?: 'horizontal' | 'vertical' | 'cross-right' | 'cross-left' = 'horizontal';

  @Input('skeletonImageClassName')
  skeletonImageClassName = 'ayn-skeleton-img';

  @Input('skeletonImage') skeletonImage = '/assets/images/pngs/placeholder.png';

  subscription?: Subscription;

  isSrcLoaded$ = new BehaviorSubject(false);

  @HostListener('load')
  @HostListener('error')
  loaded() {
    this.runAfterViewInit(() => {
      if (this.el.nativeElement.src) {
        this.isSrcLoaded$.next(true);
      }
    });
  }

  protected skeletonClassName = 'ayn-skeleton';

  constructor(protected el: ElementRef<HTMLImageElement>, private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.subscribeToLoader();
    }
  }

  setLoader(loader: boolean | Observable<boolean>) {
    this.isLoading = loader;
    this.subscribeToLoader();
  }

  setLoaderDirection(direction: 'horizontal' | 'vertical' | 'cross-right' | 'cross-left') {
    this.loaderDirection = direction;
  }

  subscribeToLoader() {
    this.subscription?.unsubscribe();

    const isLoading = this.isLoading;
    if (isLoading instanceof Observable) {
      this.subscription = combineLatest([isLoading, this.isSrcLoaded$])
        .pipe(untilDestroyed(this))
        .subscribe(([loader, srcLoaded]) => this.processIsLoading(loader || !srcLoaded));
    } else {
      this.subscription = this.isSrcLoaded$.pipe(untilDestroyed(this)).subscribe((srcLoaded) => {
        this.processIsLoading(isLoading || !srcLoaded);
      });
    }
  }

  ngAfterViewInit() {
    this.afterViewInit$.next();
  }

  runAfterViewInit(cb: () => void) {
    this.afterViewInit$.pipe(untilDestroyed(this)).subscribe(() => cb());
  }

  processIsLoading(value: boolean) {
    if (value) {
      if (this.el.nativeElement.parentElement?.classList.contains(this.skeletonClassName)) {
        return;
      }
      this.el.nativeElement.parentElement?.classList.add(this.skeletonClassName);
      this.el.nativeElement.parentElement?.classList.add(`loader-${this.loaderDirection}`);
      this.el.nativeElement.classList.add('d-none');
      if (this.skeletonImage) {
        const imgElement = new Image();
        imgElement.src = this.skeletonImage;
        imgElement.classList.add(this.skeletonImageClassName);
        this.el.nativeElement.parentElement?.insertBefore(imgElement, this.el.nativeElement);
      }
    } else {
      this.el.nativeElement.classList.remove('d-none');
      this.el.nativeElement.parentElement?.classList.remove(this.skeletonClassName);
      const imgElement = this.el.nativeElement.parentElement?.querySelector(`.${this.skeletonImageClassName}`);
      if (imgElement) {
        this.el.nativeElement.parentElement?.removeChild(imgElement);
      }
    }
  }
}

@UntilDestroy()
@Directive({
  selector: '[ayn-skeleton-text]'
})
export class TextSkeletonDirective extends SkeletonDirective {
  @Input('ayn-skeleton-text') isLoading?: boolean | Observable<boolean> | null = false;

  @Input() rowCount = 3;

  @Input() wrapperClassName = '';

  element?: Element | null;

  display = '';

  processIsLoading(value: boolean) {
    if (value) {
      const nodeString = `
      ${Array.from(
        { length: this.rowCount },
        () => '<div class="ayn-skeleton-text"><div class="ayn-skeleton-text--bar"></div></div>'
      ).join('')}
 `;

      const node = document.createElement('div');
      node.classList.add('ayn-skeleton-text-wrapper');
      if (this.wrapperClassName) {
        node.classList.add(this.wrapperClassName);
      }
      node.innerHTML = nodeString;

      this.element = this.el.nativeElement.insertAdjacentElement('beforebegin', node);
      this.display = this.el.nativeElement.style.display;
      this.el.nativeElement.style.display = 'none';
    } else {
      this.el.nativeElement.style.display = this.display;
      this.element?.remove();
    }
  }
}

const COMPONENTS = [SkeletonDirective, ImageSkeletonDirective, TextSkeletonDirective];

@NgModule({
  imports: [CommonModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: []
})
export class SkeletonModule {}
