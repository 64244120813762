import { forkJoin, of } from 'rxjs';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { AppValidations } from '@auth/utils';
import { FileUploadAreaEvent } from '@ayn-ui/lib/modules/base/file-upload-area';
import { Facebook, Platforms, PlatformStep } from '@core/models';
import { FacebookService } from '@core/services';
import { CanvaService } from '@core/services/canva.service';
import { BusinessState } from '@core/state/business.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';
import { FacebookFormService } from '@pages/create-ad/state/platforms/facebook/facebook.form';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

import { UploadAdsImagesFacebookComponent } from '../modal';
import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'aayn-dynamic-creative-ads--facebook',
  templateUrl: 'dynamic-creative-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class DynamicCreativeAdsFacebookComponent implements OnInit {
  protected adCreationModel = this.facebookStateService.adCreationModel;

  protected callToActions = Facebook.CallToActionTypes;

  private _images?: Facebook.IdWithUrl[];
  protected uploadedImageUrls: SafeUrl[] =
    this.adCreationModel.ad.creative.dynamicAssets?.images
      .map((o) => o.url)
      .concat(this.adCreationModel.ad.creative.dynamicAssets.videos.map((o) => o.url)) || [];

  protected businessPageDetail?: Facebook.IBusinessPageDetail;

  protected form = new FormGroup({
    bodies: new FormArray(
      [
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.bodies[0] || '', [Validators.required]),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.bodies[1] || '', [Validators.required]),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.bodies[2] || ''),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.bodies[3] || ''),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.bodies[4] || '')
      ],
      [AppValidations.uniqueValidator()]
    ),
    titles: new FormArray(
      [
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.titles[0] || '', [Validators.required]),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.titles[1] || '', [Validators.required]),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.titles[2] || ''),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.titles[3] || ''),
        new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.titles[4] || '')
      ],
      [AppValidations.uniqueValidator()]
    ),
    description: new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.descriptions[0], [
      Validators.required
    ]),
    callToActionType: new FormControl(this.adCreationModel.ad.creative!.dynamicAssets?.callToActionTypes[0], [
      Validators.required
    ]),
    websiteUrl: new FormControl(this.adCreationModel.ad.creative.dynamicAssets?.linkUrls[0].websiteUrl, [
      Validators.required
    ]),
    name: new FormControl(this.adCreationModel.ad.creative.name, [Validators.required])
  });

  get bodiesFormArray() {
    return this.form.controls['bodies'] as FormArray;
  }

  get titlesFormArray() {
    return this.form.controls['titles'] as FormArray;
  }

  constructor(
    private facebookStateService: FacebookStateService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private facebookService: FacebookService,
    private facebookValidationService: FacebookValidationService,
    private canvaService: CanvaService,
    private facebookForm: FacebookFormService,
    public adPreviewService: AdPreviewService,
    private store: Store
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      if (!environment.production) {
        console.error(`CreateAd.Facebook.DynamicCreativeAds.CanvaService`, error);
      }
    }
    this.facebookForm.registerForm(this.form);

    this.listenModel();

    this.facebookValidationService.registerForm(this.form, PlatformStep.AdType, this);
    this.listenForm();
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesFacebookComponent);

    if (this._images) modalRef.componentInstance.uploadedImages = this._images;

    modalRef.closed.pipe(take(1)).subscribe((result: { images: Facebook.IdWithUrl[] }) => {
      if (!result?.images) return;

      this.processVideos(result).subscribe(() => {
        this.updateImages(result.images);

        this.cdr.detectChanges();
      });
    });
  }

  private processVideos(result: { images: Facebook.IdWithUrl[] }) {
    const videos = result.images.filter((o) => o.type == 'video');

    if (!videos.length) return of([]);

    const getAdVideos = forkJoin(videos.map((o) => this.facebookService.getAdVideoDetail$(o.id)));

    return getAdVideos.pipe(
      tap((o) => {
        o.forEach((videoDetail) => {
          if (!videoDetail.data.adVideo.thumbnails) {
            return;
          }

          const thumbnailIndex =
            videoDetail.data.adVideo.thumbnails.findIndex((thumbnail) => thumbnail.isPreferred) || 0;
          const thumbnail = videoDetail.data.adVideo.thumbnails[thumbnailIndex];

          if (!thumbnail) return;

          const video = result.images.find((asset) => asset.id == videoDetail.data.adVideo.id);

          if (video) {
            video.url = thumbnail.url;
          }
        });
      })
    );
  }

  removeFile(event: FileUploadAreaEvent) {
    this.updateImages(this._images!.filter((_, i) => i !== event.index));
  }

  updateImages(images: Facebook.IdWithUrl[]) {
    this._images = images;

    this.uploadedImageUrls = images.map((o) => o.url);

    this.adCreationModel.ad!.creative!.dynamicAssets.images = images
      .filter((o) => o.type == 'image')
      .map(({ id, url }) => ({ id, url, type: 'image' }));
    this.adCreationModel.ad!.creative!.dynamicAssets.videos = images
      .filter((o) => o.type == 'video')
      .map(({ id, url }) => ({ id, url, type: 'video' }));

    this.cdr.detectChanges();

    this.facebookStateService.saveDraft$();
  }

  listenModel() {
    this.facebookStateService.adCreationModel$
      .pipe(
        untilDestroyed(this),
        filter((o) => !!o.ad.creative.dynamicAssets)
      )
      .subscribe((o) => {
        this.adCreationModel = o;

        this.adCreationModel.adSet.isDynamicCreative = true;

        this.form.patchValue({
          bodies: o.ad.creative.dynamicAssets.bodies,
          callToActionType: o.ad.creative.dynamicAssets.callToActionTypes?.at(0),
          description: o.ad.creative.dynamicAssets.descriptions.at(0),
          name: o.ad.creative.name,
          titles: o.ad.creative.dynamicAssets.titles,
          websiteUrl: o.ad.creative.dynamicAssets.linkUrls.at(0)?.websiteUrl
        });

        const businessDetail = this.store.selectSnapshot(
          BusinessState.SelectedBusinessAdAccountByPlatform(Platforms.Meta)
        );
        this.adCreationModel.ad.creative.identity = {
          pageId: businessDetail?.pageId!,
          instagramAccountId: businessDetail?.instagramAccountId!
        };

        this.updateImages([
          ...o.ad.creative.dynamicAssets.images.map((image) => ({
            ...image,
            type: 'image' as Facebook.AssetType
          })),
          ...o.ad.creative.dynamicAssets.videos.map((video) => ({
            ...video,
            type: 'video' as Facebook.AssetType
          }))
        ]);
      });
  }

  listenForm() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((o) => {
      this.adCreationModel.ad.creative.dynamicAssets.bodies = o.bodies?.filter(Boolean) as string[];
      this.adCreationModel.ad.creative.dynamicAssets.titles = o.titles?.filter(Boolean) as string[];
      this.adCreationModel.ad.creative.dynamicAssets.descriptions = [o.description] as string[];
      this.adCreationModel.ad.creative.dynamicAssets.callToActionTypes = [
        o.callToActionType
      ] as Facebook.CallToActionType[];
      this.adCreationModel.ad.creative.dynamicAssets.linkUrls = [{ websiteUrl: o.websiteUrl! }];
      this.adCreationModel.ad.creative.name = o.name!;
      this.adCreationModel.ad.name = o.name!;
    });
  }

  getSource(index: number) {
    const sourceUrl = this.uploadedImageUrls[index];
    if (sourceUrl) {
      const image = this.adCreationModel.ad.creative.dynamicAssets.images.find((_image) => _image.url === sourceUrl);
      if (image) {
        this.getImage(image.id);
        return;
      }
      const video = this.adCreationModel.ad.creative.dynamicAssets.videos.find((_video) => _video.url === sourceUrl);
      if (video) {
        this.getVideo(video.id);
      }
    }
  }

  getImage(imageHash: string) {
    if (imageHash) {
      this.facebookService.getAdImages$([imageHash]).subscribe((images) => {
        const updateImages = this._images!.map((image) => ({
          ...image,
          url: image.id === imageHash ? images.data.adImages.edges[0].node.url : image.url
        }));
        this.updateImages(updateImages);
      });
    }
  }

  getVideo(videoHash: string) {
    this.facebookService.getAdVideoDetail$(videoHash).subscribe((videoDetail) => {
      if (!videoDetail.data.adVideo.thumbnails) {
        return;
      }
      const thumbnailIndex = videoDetail.data.adVideo.thumbnails.findIndex((thumbnail) => thumbnail.isPreferred) || 0;
      const thumbnail = videoDetail.data.adVideo.thumbnails[thumbnailIndex];
      const updateVideos = this._images!.map((asset) => ({
        ...asset,
        url: asset.id === videoDetail.data.adVideo.id ? thumbnail.url : asset.url
      }));
      this.updateImages(updateVideos);
    });
  }
}
