import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BadgeList, IOption } from '@ayn-ui/lib/modules';
import { TiktokService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { TiktokStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-language-selection--tiktok',
  templateUrl: 'language-selection.component.html'
})
export class LanguageSelectionTiktokComponent implements OnInit {
  @Input() form?: FormGroup;

  protected languages: IOption[] = [];

  @Select(LoaderState.getAny(['LanguageSearch'])) loader$?: Observable<boolean>;

  adCreationModel = this.tiktokStateService.adCreationModel;

  @ViewChild(BadgeList) badgeList?: BadgeList;

  constructor(
    private tiktokService: TiktokService,
    private cdr: ChangeDetectorRef,
    private tiktokStateService: TiktokStateService
  ) {}

  ngOnInit() {
    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  searchValueChange(term: string) {
    this.tiktokService.languageSearch$({ searchKey: term }).subscribe((result) => {
      this.languages = result?.map((o) => ({ label: o.name, value: o.code } as IOption)) || [];
      this.cdr.detectChanges();
    });
  }

  protected valueChange(option: IOption) {
    const existLocale = this.adCreationModel!.audience.languages?.some((o) => o.code == option.value);

    if (existLocale) return;

    this.adCreationModel!.audience.languages?.push({
      name: option.label,
      code: option.value as string
    });

    this.form?.patchValue({
      locales: this.adCreationModel.audience.languages
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }

  optionDisabled(option: IOption) {
    return !!this.adCreationModel?.audience?.languages?.some((o) => o.code == option.value);
  }

  removeLocale(index: number) {
    this.adCreationModel.audience.languages?.splice(index, 1);

    this.form?.patchValue({
      locales: this.adCreationModel.audience.languages
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }
}
