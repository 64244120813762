export interface UserResponse {
  sub: string;
  name: string;
  email: string;
  surname: string;
  emailConfirmed: boolean;
  inviter: Inviter;
  language: string;
  companyName: string;
  companyType: string;
  country: string;
  flags: UserFlags;
}

export enum UserFlags {
  None = 'None',
  OnboardingCompleted = 'OnboardingCompleted'
}

export interface Inviter {
  inviterId: string;
}

export interface UserLoginResponse {
  accessToken: string;
  refreshToken: string;
  user: UserResponse;
}

export interface JwtTokenResponse {
  aud: string;
  email: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  sub: string;
  surname: string;
}

export interface SignupRequest {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordAgain: string;
  companyType: string;
  language: string;
}

export enum UserAccountStatus {
  Active = 'Active',
  Invited = 'Invited',
  Deleted = 'Deleted'
}

export interface SubAccountsOutput {
  email: string;
  name: string;
  surname: string;
  status: UserAccountStatus;
  id: string;
}

export interface RemainingInvitationRightsOutput {
  total: number;
  remaining: number;
}
