<div class="aayn-ad-form-input">
  <div class="aayn-ad-form-input__label">
    <ayn-icon *ngIf="platform" [name]="platform"></ayn-icon>
    <label *ngIf="label">
      {{ label }}
      <ng-content select="[label]"></ng-content>
    </label>
  </div>

  <div class="aayn-ad-form-input__content">
    <ng-content></ng-content>
  </div>
</div>
