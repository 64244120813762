import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { createUploadImages, Linkedin, LinkedinService, Upload, UploadImageItem } from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';

import { LibraryLinkedinComponent } from '../library/library.component';
import { delay, delayWhen, filter, map, retryWhen, switchMap } from 'rxjs/operators';
import { throwError, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';

export enum LinkedInSingleImageStatusEnum {
  PROCESSING = 'Processing',
  PROCESSING_FAILED = 'ProcessingFailed',
  AVAILABLE = 'Available',
  WAITING_UPLOAD = 'WaitingUpload'
}

@Component({
  selector: 'aayn-upload-ads-images--linkedin',
  templateUrl: './upload-ads-images.component.html'
})
export class UploadAdsImagesLinkedinComponent implements OnInit {
  protected uploadedImages: Linkedin.IdWithUrl[] = [];

  protected images: UploadImageItem[] = createUploadImages(5);

  protected isValid: boolean = true;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private linkedinService: LinkedinService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.uploadedImages.forEach((_, i) => {
      this.images[i].startedUploading = true;
      this.images[i].progressValue = 100;
    });
  }

  openLibrary() {
    const modalRef = this.modalService.open(LibraryLinkedinComponent, {});
    modalRef.closed.subscribe((result: { images: Linkedin.IdWithUrl[] }) => {
      if (!result?.images) return;

      result.images.forEach((image, index) => {
        const existsImage = this.uploadedImages.some((o) => o.id === image.id);

        if (existsImage) return;

        if (this.uploadedImages.length >= 5) return;

        this.uploadedImages.push(image);

        this.images[index].progressValue = 100;
      });

      this.cdr.detectChanges();
    });
  }

  uploadImage({ file, fileUrl }: IFileAddedEvent) {
    const formData = new FormData();
    formData.append('files', file);
    const image = this.images.find((image) => !image.startedUploading && !image.completed)!;

    const platform = this.store.selectSnapshot(CreateAdState.selectedPlatformDetail);
    if (platform?.pageId) {
      this.linkedinService
        .uploadImage$(platform.pageId, formData)
        .pipe(
          map((uploadResult) => {
            image.startedUploading = true;
            if (uploadResult.progress !== 100) {
              image.progressValue = uploadResult.progress;
            }
            this.cdr.detectChanges();

            if (!environment.production) console.log('[CreateAd.Linkedin.UploadResult]: ', uploadResult);

            return uploadResult;
          }),
          filter<Upload>((uploadResult) => uploadResult.state === 'DONE'),
          delay(3000),
          map((val) => val?.result),
          switchMap((uploadResponse: PromiseSettledResult<Linkedin.CreateAd.UploadResponse>[]) =>
            uploadResponse[0].status == 'fulfilled'
              ? this.linkedinService.getSingleImageDetail(uploadResponse[0].value.image).pipe(
                  map((singleImageDetail) => {
                    if (
                      [LinkedInSingleImageStatusEnum.PROCESSING, LinkedInSingleImageStatusEnum.WAITING_UPLOAD].includes(
                        singleImageDetail?.status as LinkedInSingleImageStatusEnum
                      )
                    ) {
                      throw { error: { message: ['WAIT_AVAILABLE'] } };
                    } else if (singleImageDetail?.status === LinkedInSingleImageStatusEnum.PROCESSING_FAILED) {
                      throw { error: { message: ['PROCESSING_FAILED'] } };
                    }
                    return { uploadResponse, singleImageDetail };
                  }),
                  retryWhen((errors) => {
                    return errors.pipe(
                      delayWhen((error) =>
                        error?.error?.message?.includes('WAIT_AVAILABLE') ? timer(3000) : throwError(error)
                      )
                    );
                  })
                )
              : throwError(uploadResponse[0].reason)
          )
        )
        .subscribe({
          next: (response) => {
            const { uploadResponse, singleImageDetail } = response;
            if (singleImageDetail) {
              //TODO: move progress complete
              image.progressValue = 100;
              const uploadImageIndex = this.images.findIndex((i) => i === image);
              this.uploadedImages[uploadImageIndex] = {
                id: (uploadResponse[0] as PromiseFulfilledResult<Linkedin.CreateAd.UploadResponse>).value
                  .image as Linkedin.ImageUrn,
                url: singleImageDetail?.downloadUrl || ''
              };
              this.cdr.detectChanges();
              this.toastrService.success('Image upload successful.', 'Successful');
            }
          },
          error: (err: { error: IRestError }) => {
            if (err.error?.message) {
              err.error.message.forEach((o) => {
                this.toastrService.warning(o);
              });

              image.startedUploading = false;
              image.progressValue = 0;
            }
          }
        });
    }
  }

  remove({ fileUrl }: { fileUrl: string }) {
    const index = this.uploadedImages.findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages = this.uploadedImages.filter((_, i) => i !== index);
      this.images.forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images.length - 1 && this.images[i + 1].startedUploading) {
            this.images[i] = this.images[i + 1];
            this.images[i + 1] = new UploadImageItem();
          } else {
            this.images[i] = new UploadImageItem();
          }
        }
      });
    }
    this.cdr.detectChanges();
  }

  closeModal() {
    this.modal.close({
      images: this.uploadedImages
    });
  }

  saveModal() {
    if (this.uploadedImages.length > 0) {
      this.modal.close({
        images: this.uploadedImages.filter((i) => !!i.url && !!i.id)
      });
    } else this.isValid = false;
  }

  setImages(count: number) {
    this.images = createUploadImages(count);
  }

  selectedFileChange(index: number) {
    this.uploadedImages[index] = {
      id: '' as Linkedin.ImageUrn,
      url: ''
    };
  }
}
