import { Observable } from 'rxjs';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BadgeList, IOption } from '@ayn-ui/lib/modules';
import { Platforms, SmartCampaign } from '@core/models';
import { SmartCampaignService } from '@core/services/smart-campaign.service';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';

@Component({
  selector: 'aayn-language-selection--smart-campaign',
  templateUrl: 'language-selection.component.html'
})
export class LanguageSelectionSmartCampaignComponent implements OnInit {
  @Input() form?: FormGroup;

  @Select(LoaderState.getAny('GetLanguagesSmartCampaign')) loader$?: Observable<boolean>;

  @ViewChild(BadgeList) badgeList?: BadgeList;

  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected languages: IOption[] = [];

  private _languages: SmartCampaign.Backend.SmartCampaignLanguageDto[] = [];

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private smartCampaignService: SmartCampaignService
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    const supportedLanguages = this.adCreationModel.selectedPlatforms.filter((o) => o != Platforms.Bing);

    this.smartCampaignService.getLanguages$(supportedLanguages).subscribe((o) => {
      const result = o.data!.results;
      this._languages = result;

      this.languages = result.map((o) => {
        return {
          label: o.name,
          value: o.name
        } as IOption;
      });
    });
  }

  protected valueChange(option: IOption) {
    const existLocale = this.adCreationModel!.audience.languages.some((o) => o.name == option.value);

    if (existLocale) return;

    const language = this._languages.find((o) => o.name == option.value);

    if (!language) return;

    this.adCreationModel!.audience.languages.push(language);

    this.form?.patchValue({
      languages: this.adCreationModel.audience.languages
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }

  optionDisabled(option: IOption) {
    return this.adCreationModel.audience.languages.some((o) => o.name == option.value);
  }

  removeLocale(index: number) {
    this.adCreationModel!.audience.languages.splice(index, 1);

    this.form?.patchValue({
      languages: this.adCreationModel.audience.languages
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }
}
