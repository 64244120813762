<aayn-ad-preview
  [businessName]="businessName"
  [profileImage]="businessImage"
  className="aayn-ad-preview-linkedin-carousel"
>
  <p class="aayn-ad-preview-linkedin-carousel_header">
    {{ headline || (headlinePlaceholder | translate) }}
  </p>
  <ayn-carousel
    class="aayn-ad-preview-linkedin-carousel_slide"
    [activeIndex]="activeIndex!"
    [itemsPerSlide]="2"
    (activeIndexChange)="activeIndexChange.emit($event)"
    previewType="ordered"
  >
    <ng-template ayn-carousel-item *ngFor="let card of cards; index as index">
      <div class="aayn-ad-preview-linkedin-carousel_slide-item">
        <img
          class="aayn-ad-preview-linkedin-carousel_slide-item__image"
          [src]="card.image?.[0] || '/assets/images/pngs/placeholder.png'"
        />

        <section class="aayn-ad-preview-linkedin-carousel_slide-item__content">
          <div class="aayn-ad-preview-linkedin-carousel_slide-item__content__labels">
            <p class="aayn-ad-preview-linkedin-carousel_slide-item__content__body">
              {{ card.body }}
            </p>
          </div>
        </section>
      </div>
    </ng-template>
  </ayn-carousel>
</aayn-ad-preview>
