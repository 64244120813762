import { Linkedin } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Linkedin.Business.IAdAccountRequest;
type Output = Linkedin.Business.IAdAccountResponse;

export const adAccountsLinkedin = makeMutation<Output, Input>(`
query AdAccounts(
  $after: String
  $first: Int
  $names: [String!]
  $ids: [String!]
  $statuses: [LinkedInAdAccountStatus!]
) {
  linkedin {
    adAccounts(
      after: $after
      first: $first
      names: $names
      ids: $ids
      statuses: $statuses
    ) {
      edges {
        cursor
        node {
          id
          name
          currency
          notifiedOnCampaignOptimization
          notifiedOnCreativeApproval
          notifiedOnCreativeRejection
          notifiedOnEndOfCampaign
          reference
          status
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
`);
