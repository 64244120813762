import { makeMutation } from '@core/queries';
import { TikTok } from '@core/models';

export const updateCampaignQuery = makeMutation<void, TikTok.Dashboard.UpdateCampaign>(`
mutation TikTokUpdateCampaign(
  $campaignId: String!
  $budget: Float
  $campaignName: String
) {
  tiktok {
    updateCampaign(
      campaignId: $campaignId
      budget: $budget
      campaignName: $campaignName
    )
  }
}
`);
