import { Base, Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Base.IPaginationRequest;

type Output = {
  instagramMedia: {
    edges: {
      node: Facebook.IInstagramMedia;
    }[];
  };
};

export const getInstagramMedias = makeQuery<Output, Input>(`
  query GetInstagramMedias(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    facebook {
      instagramMedia(
        first: $first
        after: $after
        last: $last
        before: $before
      ) { 
      edges {
        node {
          id
          imageUrl
          likes
          comments
          caption
          type
          createdAt
          }
        }
      }
    }
  }
`);
