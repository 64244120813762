import { Component, Input } from '@angular/core';
import { IconModule } from '@ayn-ui/lib/modules';
import { NgIf } from '@angular/common';

@Component({
  selector: 'aayn-ad-preview-youtube-ads',
  templateUrl: './youtube-ads.component.html',
  imports: [IconModule, NgIf],
  standalone: true
})
export class YoutubeAdsComponent {
  @Input() title = '';
  @Input() linkUrl = '';
  @Input() image = '';
  @Input() logoUrl = '';
  @Input() cta = 'LEARN MORE';

  titlePlaceholder = 'Your headline';
  linkUrlPlaceholder = 'https://your-url.com';
  ctaPlaceholder = 'LEARN MORE';

  logoPlaceholder = '/assets/images/svgs/create-ads/youtube-logo-placeholder.svg';
}
