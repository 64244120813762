<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">Add a payment method to continue!</section>
  <section class="aayn-video-modal--description">
    <p>
      To keep using ADYOUNEED’s incredible ad creation features, please add your credit card details or enter your
      lifetime deal coupon.
    </p>
    <p class="aayn-video-modal--hit-line aayn-video-modal--emphasis">Let’s keep growing your business together!</p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="accent" icon="credit-card" (click)="videoModal.closeModal()">Update Payment Info</button>
  </section>
</aayn-video-modal>
