import { Component } from '@angular/core';
import { IntercomService } from '@core/services';

@Component({
  templateUrl: './welcome-to-adyouneed.component.html'
})
export class WelcomeToAdYouNeedComponent {
  videoId = '817578303';

  constructor(private intercomService: IntercomService) {}

  showArround() {
    this.intercomService.startTour('WELCOME_TO_ADYOUNEED');
  }
}
