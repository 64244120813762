<aayn-ad-preview-card [selectedPlatform]="Platforms.Google" [platforms]="platforms">
  <aayn-ad-preview-google-tabs
    [availableTabs]="['display']"
    [title]="adPreviewService.displayAds.shortHeadline || ''"
    [linkUrl]="adPreviewService.displayAds.url || ''"
    [cta]="adPreviewService.displayAds.callToAction || ''"
    [description]="adPreviewService.displayAds.description || ''"
    [image]="adPreviewService.displayAds.imageUrls?.[0] || ''"
    [logoUrl]="adPreviewService.displayAds.logoUrl || ''"
  >
  </aayn-ad-preview-google-tabs>
</aayn-ad-preview-card>
