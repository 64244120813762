import { fromEvent, Subscription } from 'rxjs';

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { defaultInvalidScroll } from '../handlers/default-scroll.handler';

export const INVALID_SCROLL_HANDLER = new InjectionToken<(el: ElementRef) => void>('INVALID_SCROLL_HANDLER', {
  factory: () => {
    return defaultInvalidScroll;
  }
});

@UntilDestroy()
@Directive({ selector: 'form:not([standAloneForm])', exportAs: 'scrollToInvalid' })
export class FormDirective implements OnChanges, AfterViewInit {
  @Input() ignoreInvalid = false;

  @Input() extraClassName?: string;

  subscription?: Subscription;

  constructor(
    public el: ElementRef<HTMLFormElement>,
    @Inject(INVALID_SCROLL_HANDLER) public scrollHandler: (el: ElementRef) => void
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.ignoreInvalid?.currentValue) {
      this.listenSubmit();
    }
  }

  ngAfterViewInit() {
    if (!this.ignoreInvalid) {
      this.listenSubmit();
    }
  }

  listenSubmit() {
    this.subscription?.unsubscribe();
    this.subscription = fromEvent(this.el.nativeElement, 'submit')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onScrollToInvalid();
      });
  }

  onScrollToInvalid() {
    setTimeout(() => {
      if (
        this.el.nativeElement.classList.contains('ng-invalid') ||
        (this.extraClassName && this.el.nativeElement.classList.contains(this.extraClassName))
      ) {
        this.scrollHandler(this.el);
      }
    }, 1);
  }
}
