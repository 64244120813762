import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-error-modal-content',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalContent {
  @Input() title: string = 'Delete';

  @Input() description: string = '';

  @Input() content: string = '';

  @Input() subContent: string = '';

  @Input() headerClass = '';

  @Input() bodyClass = '';

  @Input() footerClass = '';

  @Input() modalClass = '';

  @Input() confirmButtonText = 'Confirm';

  @Input() cancelButtonText = 'Cancel';

  @Output() confirmButton: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() cancelButton: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event: Event) {
    this.confirmButton.emit($event);
    this.activeModal.close(true);
  }

  cancel($event: Event) {
    this.cancelButton.emit($event);
    this.activeModal.dismiss('Modal Close');
  }
}
