<div class="aayn-empty-content" [class]="type || ''">
  <ayn-lottie [name]="animation"></ayn-lottie>

  <h4>{{ title | translate }}</h4>

  <p *ngIf="description">{{ description | translate }}</p>

  <button *ngIf="showButton" [ayn-button]="buttonType" [icon]="btnIcon" (click)="this.buttonClick.emit($event)">
    {{ buttonName | translate }}
  </button>
</div>
