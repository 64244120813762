import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ayn-vimeo-player',
  template: `
    <div class="ayn-video-player">
      <iframe
        *ngIf="!!safeSrc"
        [src]="safeSrc"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  `
})
export class VimeoPlayerComponent {
  safeSrc: SafeResourceUrl | null = null;

  private readonly vimeoPlayerDomain = 'https://player.vimeo.com/video';
  private readonly vimeoDefaultQs = 'title=0&byline=0&portrait=0';

  @Input()
  set vimeoVideoId(value: string) {
    if (!!value) {
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.vimeoPlayerDomain}/${value}?${this.vimeoDefaultQs}`
      );
    } else {
      this.safeSrc = null;
    }
  }

  constructor(private readonly sanitizer: DomSanitizer) {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [VimeoPlayerComponent],
  exports: [VimeoPlayerComponent]
})
export class VimeoPlayerModule {}
