import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-notification-list',
  template: `
    <h6 class="aayn-notifications--body-main-title">{{ title }}</h6>

    <ul class="aayn-notifications--body-main-list">
      <ng-content select="aayn-notification-item"></ng-content>
    </ul>
  `
})
export class NotificationListComponent implements OnInit {
  @Input() title = '';

  constructor() {}

  ngOnInit() {}
}
