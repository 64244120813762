import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList
} from '@angular/core';
import { ICON_COMPONENT_ATTR_NAME, TabItem } from '@ayn-ui/lib/modules';
import { ReplaySubject } from 'rxjs';

export enum TabValidateStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Raw = 'Raw'
}
export type TabValidateFn = (tabItem: TabItem) => TabValidateStatus;
export type ChildTabValidateFn = (tabItem: TabItem) => TabValidateStatus | undefined;

@Directive({
  selector: 'ayn-tab-item[aayn-tab-validate]',
  standalone: true
})
export class TabItemValidate {
  @Input('aayn-tab-validate') validateFn!: ChildTabValidateFn;

  constructor(public tabItem: TabItem) {}
}

@UntilDestroy()
@Directive({
  selector: 'ayn-tab[aayn-tab-validate]',
  standalone: true
})
export class TabValidateDirective implements AfterViewInit {
  @Input('aayn-tab-validate') validateFn!: TabValidateFn;

  @ContentChildren(TabItem) tabItems?: QueryList<TabItem>;
  @ContentChildren(TabItemValidate, { descendants: true }) tabItemValidates?: QueryList<TabItemValidate>;

  @Input() validate$!: ReplaySubject<void>;
  @Input() markValid = true;

  constructor(private el: ElementRef<HTMLElement>, private cdr: ChangeDetectorRef) {
    this.el.nativeElement.classList.add('ayn-tab__validate');
  }

  ngAfterViewInit() {
    this.validate$.pipe(untilDestroyed(this)).subscribe(() => {
      this.validate();
    });
  }

  validate() {
    this.tabItems?.forEach((item) => {
      if (!item.node) {
        throw new Error(`[TabValidateDirective] can't find node of ${item.tabTitle}`);
      }
      const childValidator = this.tabItemValidates?.find((child) => child.tabItem === item);
      const childStatus = childValidator?.validateFn(item);
      const status = childStatus || this.validateFn(item);
      switch (status) {
        case TabValidateStatus.Valid:
          this.valid(item);
          break;
        case TabValidateStatus.Invalid:
          this.inValid(item);
          break;
        case TabValidateStatus.Raw:
          this.reset(item);
      }
    });
    this.cdr.detectChanges();
  }

  valid(item: TabItem) {
    const { node } = item;
    node!.classList.remove('ayn-tab--li__invalid');

    const icon = node!.querySelector('ayn-icon')?.getAttribute(ICON_COMPONENT_ATTR_NAME);

    if (this.markValid) {
      if (icon && icon != 'check-lg') {
        node!.setAttribute('ayn-prev-icon', icon);
      }

      item!.icon = 'check-lg';
    }

    node!.querySelectorAll('.ayn-tab--li__invalid-circle').forEach((circle) => circle.remove());
  }

  inValid(item: TabItem) {
    const { node } = item;

    node!.classList.add('ayn-tab--li__invalid');

    if (this.markValid) {
      const prevIcon = node?.getAttribute('ayn-prev-icon');

      if (prevIcon) {
        item!.icon = prevIcon;
      }
    }

    const span = document.createElement('span');
    span.classList.add('ayn-tab--li__invalid-circle');
    node!.appendChild(span);
  }

  reset(item: TabItem) {
    const { node } = item;
    node!.classList.remove('ayn-tab--li__invalid');
    node!.querySelectorAll('.ayn-tab--li__invalid-circle').forEach((circle) => circle.remove());

    if (this.markValid) {
      const prevIcon = node?.getAttribute('ayn-prev-icon');

      if (prevIcon) {
        item!.icon = prevIcon;
      }
    }
  }
}
