import { Injectable } from '@angular/core';

@Injectable()
export class AdPreviewBingService {
  performanceMax: Partial<{
    businessName: string;
    businessImage: string;
    finalUrl: string;
    description: string;
    headline: string;
    imageUrls: string[];
  }> = {};
}
