<form [formGroup]="form" *ngIf="form">
  <div class="aayn-platform-budget" [class.aayn-platform-budget--horizontal]="isHorizontal">
    <div class="aayn-platform-budget--platform" *ngIf="platform">
      <ayn-icon className="aayn-platform-budget--platform-icon" [name]="PlatformIcons[platform]"></ayn-icon>
      <span class="aayn-platform-budget--platform-title"> {{ getPlatformName(platform) | aynTitleCase }} Budget </span>
    </div>

    <div class="aayn-platform-budget--row">
      <ayn-form-field class="aayn-platform-budget--row__input">
        <div class="ayn-prefix">
          {{ 'Budget' | translate }}
          <span class="aayn-platform-budget--row__input-currency"> ({{ currency }}) </span>
        </div>

        <input type="number" ayn-input id="budget" placeholder="00.00" formControlName="amount" />
      </ayn-form-field>

      <div class="aayn-platform-budget--row__budget">
        <ayn-radio
          name="type"
          [value]="BudgetType.Daily"
          [binary]="true"
          [labelShow]="true"
          [label]="'Daily' | translate"
          inputId="daily"
          formControlName="type"
          radioType="default"
        >
        </ayn-radio>

        <ayn-radio
          name="type"
          [value]="BudgetType.Total"
          [binary]="true"
          [labelShow]="true"
          [disabled]="true"
          [label]="'Total' | translate"
          inputId="total"
          formControlName="type"
          radioType="default"
        >
        </ayn-radio>
      </div>
    </div>
  </div>
</form>
