import { CursorPaginatedQueryResult, makeQuery } from '@core/queries/types';
import { Base } from '@core/models';

type Input = { filter: Base.CreateAd.ImageAssetFilter };
type Output = CursorPaginatedQueryResult<'accountImages', { id: number; url: string }>;

export const accountImages = makeQuery<Output, Input>(`
  query GetAccountImages($filter:ImageAssetFilter!) {
    google {
      accountImages(filter: $filter) {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  }
`);
