import { makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';

type Input = { id: number };
type Output = Linkedin.FindConversionsByAccountResponse;
export const findConversionsByAccount = makeQuery<Output, Input>(`
query FindConversionsByAccount($id: Int) {
  linkedin{
    findConversionsByAccount(id: $id, pagination: {take:10}){
      edges {
        node {
          id
          account
          name
          associatedCampaigns {
            campaign
            conversion
          }
          attributionType
          urlMatchRuleExpression {
            matchType
            matchValue
          }
          postClickAttributionWindowSize
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasPreviousPage
        from
        to
      }
    }
  }
}
`);
