import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleYourDataRequest;
type Output = Google.CreateAd.IGoogleYourDataResponse;

export const yourDataSearch = makeMutation<Output, Input>(`
query YourDataSearch($number: Float, $searchKey: String) {
  google {
    yourDataSearch(number: $number, searchKey: $searchKey) {
      id
      name
      type
    }
  }
}

`);
