import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aynFilterText'
})
export class FilterTextPipe implements PipeTransform {
  transform(value: string | number, filterText: string): string {
    return `${value}`.replace(filterText, '');
  }
}
