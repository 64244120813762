import { __Business } from './business';
import { __Dashboard } from './dashboard';
import { __CreateAd } from './create-ad';

export module TikTok {
  export import Business = __Business;
  export import Dashboard = __Dashboard;
  export import CreateAd = __CreateAd;

  export interface TiktokPaginationRequest {
    skip?: number;
    take?: number;
    page?: number;
  }
}
