import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { createUploadImages, TikTok, TiktokService, Upload, UploadImageItem } from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspectRatio } from '@shared/models';
import { LibraryTiktokComponent } from '@pages/create-ad/components/select-adtype/platforms';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';
import { isVideoFile } from '@shared/utils/is-video-file';
import { isImageFile } from '@shared/utils/is-image-file';
import { Observable, of } from 'rxjs';

type ImageResponse = TikTok.CreateAd.Backend.TikTokUploadImageResponse;
type VideoResponse =
  | TikTok.CreateAd.Backend.TikTokVideoUploadResponse
  | TikTok.CreateAd.Backend.TikTokExistVideoUpload[];
type TiktokUploadResult = Upload<
  TikTok.CreateAd.Backend.TikTokUploadImageResponse | TikTok.CreateAd.Backend.TikTokVideoUploadResponse | null
>;

@Component({
  selector: 'aayn-upload-dynamic-creative-ads-images--tiktok',
  templateUrl: './upload-dynamic-creative-ads-images.component.html'
})
export class UploadDynamicCreativeAdsImagesTiktokComponent implements OnInit {
  @Input() protected uploadedImages: TikTok.CreateAd.Client.IdWithUrl[] = [];

  @Input() protected images: UploadImageItem[] = createUploadImages(30);

  maxWidth = 1280;
  minImageSize: { width: number; height: number } = { width: 640, height: 640 };

  protected isValid: boolean = true;

  aspectRatios: AspectRatio.AspectRatioItem[] = [
    {
      label: '1:1',
      ratio: AspectRatio.TikTok.Square,
      selected: true
    },
    {
      label: '9:16',
      ratio: AspectRatio.TikTok.Vertical
    },
    {
      label: '1.91:1',
      ratio: AspectRatio.TikTok.HorizontalBanner
    }
  ];

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private tiktokService: TiktokService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.uploadedImages.forEach((_, i) => {
      this.images[i].startedUploading = true;
      this.images[i].progressValue = 100;
    });
  }

  openLibrary() {
    const modalRef = this.modalService.open(LibraryTiktokComponent, {});
    (modalRef.componentInstance as LibraryTiktokComponent).maxImages = 30 - this.uploadedImages.length;
    modalRef.closed.subscribe((result: { images: TikTok.CreateAd.Client.IdWithUrl[] }) => {
      if (!result?.images) return;

      result.images.forEach((image) => {
        const existsImage = this.uploadedImages.some((o) => o.id === image.id);

        if (existsImage) return;

        if (this.uploadedImages.length >= 30) return;
        this.uploadedImages = this.uploadedImages.filter((o) => !!o.id);
        this.uploadedImages.push(image);
        const imageIndex = this.images.findIndex((o) => !o.completed);

        if (imageIndex !== -1) {
          this.images[imageIndex].progressValue = 100;
        }
      });

      this.cdr.detectChanges();
    });
  }

  onFileAdded(fileObject: IFileAddedEvent) {
    let type: 'image' | 'video' = 'image';
    let stream: Observable<TiktokUploadResult | null> = of(null);
    if (isVideoFile(fileObject.file)) {
      stream = this.uploadVideo(fileObject);
      type = 'video';
    } else if (isImageFile(fileObject.file)) {
      stream = this.uploadImage(fileObject);
    }
    const file = this.images.find((o) => !o.completed && !o.startedUploading);

    stream?.subscribe({
      next: (uploadResult) => {
        if (uploadResult && file) {
          this.processFileUpload(file, uploadResult, fileObject.fileUrl || '', type);
        }
      },
      error: ({ error }: { error: IRestError }) => {
        if (!(error.message instanceof Array)) {
          this.toastrService.warning(error.message);

          file!.startedUploading = false;
          file!.progressValue = 0;
        }
      }
    });
  }

  uploadImage({ file }: { file: File; fileUrl?: string }) {
    const formData = new FormData();
    formData.append('image', file);
    return this.tiktokService.uploadImage$(formData);
  }

  uploadVideo({ file }: IFileAddedEvent) {
    const formData = new FormData();

    formData.append('video', file);
    return this.tiktokService.uploadVideo$(formData);
  }

  private processFileUpload(
    file: UploadImageItem,
    uploadResult: TiktokUploadResult,
    fileUrl: string,
    type: 'image' | 'video'
  ) {
    file!.startedUploading = true;
    file!.progressValue = uploadResult.progress;

    if (!environment.production) console.log('[CreateAd.Tiktok.UploadResult]: ', uploadResult);

    if (uploadResult.state != 'DONE') return;

    file!.progressValue = 100;
    if (uploadResult.result) {
      let id = '';
      let url = '';
      if (type === 'video') {
        const result = uploadResult.result as VideoResponse;
        if (Array.isArray(result)) {
          id = result[0].videoId;
          url = result[0].videoCoverUrl || fileUrl;
        }
      } else if (type === 'image') {
        const result = uploadResult.result as ImageResponse;
        id = result.imageId;
        url = result.imageUrl || fileUrl;
      }
      const index = this.images.findIndex((o) => o === file);
      if (id && url) {
        this.uploadedImages![index] = { id, url, type };
      }
    }

    this.toastrService.success(`${type === 'image' ? 'Image' : 'Video'} upload successful.`, 'Successful');
  }

  remove({ fileUrl }: { fileUrl: string }) {
    const index = (this.uploadedImages || []).findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages = this.uploadedImages!.filter((_, i) => i !== index);
      this.images.forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images.length - 1 && this.images[i + 1].startedUploading) {
            this.images[i] = this.images[i + 1];
            this.images[i + 1] = new UploadImageItem();
          } else {
            this.images[i] = new UploadImageItem();
          }
        }
      });
    }
  }

  saveModal() {
    if (this.isUploadValid()) {
      this.modal.close({
        images: this.uploadedImages
      });
    } else this.isValid = false;
  }

  isUploadValid(): boolean {
    return this.images?.at(0)?.completed || this.uploadedImages!.filter(({ id }) => !!id).length > 0;
  }
  closeModal() {
    this.modal.close({});
  }

  selectedFileChange(index: number) {
    this.uploadedImages[index] = {
      id: '',
      url: ''
    };
  }

  ratioSelect(ratioItem: AspectRatio.AspectRatioItem) {
    if (ratioItem.ratio) {
      const ratio = ratioItem.ratio.split(':');
      const ratioWidth = Number(ratio[0]);
      const ratioHeight = Number(ratio[1]);
      const aspectRatio = ratioWidth / ratioHeight;
      if (ratioWidth > ratioHeight) {
        this.maxWidth = 1200;
        this.minImageSize = { width: 640, height: Math.ceil(640 / aspectRatio) };
      } else {
        this.maxWidth = Math.floor(1200 * aspectRatio);
        this.minImageSize = { width: Math.ceil(640 * aspectRatio), height: 640 };
      }
    } else {
      this.maxWidth = 0;
    }
    this.cdr.detectChanges();
  }
}
