<footer *ngIf="list.length" class="ayn-badge-list" #filledFooter>
  <div class="ayn-badge-list-inner" #filledFooterInner>
    <ayn-badge
      *ngFor="let locale of list; let index = index"
      color="primary"
      [text]="locale.name"
      [icon]="hideRemove ? '' : 'cross-mini-bold'"
      (click)="removeItem(index)"
      [class]="badgeItemClass"
    >
    </ayn-badge>
  </div>

  <button ayn-button="dark" type="button" *ngIf="wrappedItemsLength" (click)="overlay.toggle($event)">
    +{{ wrappedItemsLength }}
  </button>

  <ayn-overlay #overlay class="ayn-badge-list--overlay" styleClass="ayn-badge-list--more-items">
    Other Languages

    <ul>
      <li
        *ngFor="
          let locale of list
            | slice: list.length - wrappedItemsLength;
          let index = index
        "
      >
        <ayn-badge
          color="primary"
          [text]="locale.name"
          icon="cross-mini-bold"
          (click)="onRemoveItem.emit(list.length - wrappedItemsLength + index)"
        >
        </ayn-badge>
      </li>
    </ul>
  </ayn-overlay>
</footer>
