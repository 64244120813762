<ayn-modal-page [options]="{ backdrop: 'static', keyboard: false }">
  <ng-template #body>
    <section class="aayn-payment-modal">
      <section class="aayn-payment-modal--header">
        <h4 class="aayn-payment-modal--header-title" [innerHTML]="title"></h4>
        <p class="aayn-payment-modal--header-description" [innerHTML]="description"></p>
      </section>

      <section class="aayn-payment-modal--body">
        <aayn-payment-form
          buttonType="primary"
          [isRouteAfterSuccess]="false"
          (appliedAppsumoCode)="changeTexts()"
        ></aayn-payment-form>
      </section>
    </section>
  </ng-template>

  <ng-template #footer>
    <div class="aayn-payment-modal--logout-footer">
      <button (click)="logout()">
        <ayn-icon name="power"></ayn-icon>
        Logout
      </button>
    </div>
  </ng-template>
</ayn-modal-page>
