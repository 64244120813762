import { IAllPlatformContextsResponse } from '@core/models';

import { makeQuery } from '../types';

type Output = IAllPlatformContextsResponse;

export const allContexts = makeQuery<Output>(`
query {
  allContexts {
    id
    source
    externalId
    status
  }
}
`);
