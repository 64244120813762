<aayn-upload-image-modal
  header="Select Images"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
  icon="folder"
>
  <aayn-upload-image-modal--header headerLeft="Logos" headerRight="Add up to 2 logos"></aayn-upload-image-modal--header>
  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      [isPrimary]="true"
      [isError]="!isValid && !imageInfo.logo.valid"
      (fileAdded)="uploadImage('logo', $event)"
      (remove)="remove($event, 'logo')"
      [selectedFileUrl]="uploadedImages.logo![0]?.url"
      (selectedFileUrlChange)="selectedFileChange(0, 'logo')"
      [progressItem]="images['logo'][0]"
      [maxImageSize]="{ width: 2048 }"
      [cropperScalable]="true"
      imageOutput="png"
      canvaDesignType="Logo"
      aspectRatioLabel="1:1 Square Logo"
    ></aayn-file-upload-item>

    <aayn-file-upload-item
      [isPrimary]="true"
      [isError]="!isValid && !imageInfo.landscapeLogo.valid"
      aspectRatio="4:1"
      (fileAdded)="uploadImage('landscapeLogo', $event)"
      (remove)="remove($event, 'landscapeLogo')"
      [selectedFileUrl]="uploadedImages.landscapeLogo![0]?.url"
      (selectedFileUrlChange)="selectedFileChange(0, 'landscapeLogo')"
      [progressItem]="images['landscapeLogo'][0]"
      [maxImageSize]="{ width: 2048 }"
      [cropperScalable]="true"
      imageOutput="png"
      canvaDesignType="FacebookAd"
      aspectRatioLabel="4:1 Landscape Logo"
    ></aayn-file-upload-item>
  </section>

  <!-- 1:1 SQUARE -->

  <aayn-upload-image-modal--header
    headerLeft="1:1 Square Images"
    headerLeftDescription="Add up to 5 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary('squareMarketing', 5)"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.squareMarketing; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.squareMarketing.valid"
      aspectRatio="1:1"
      (fileAdded)="uploadImage('squareMarketing', $event)"
      (remove)="remove($event, 'squareMarketing')"
      [selectedFileUrl]="uploadedImages.squareMarketing![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'squareMarketing')"
      [progressItem]="images['squareMarketing'][index]"
      [maxImageSize]="{ width: 2048 }"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1:1 Square Images"
    ></aayn-file-upload-item>
  </section>

  <!-- 4:5 VERTICAL -->

  <aayn-upload-image-modal--header
    headerLeft="4:5 Vertical Images"
    headerLeftDescription="Add up to 5 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary('portraitMarketing', 5)"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.portraitMarketing; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.portraitMarketing.valid"
      aspectRatio="4:5"
      (fileAdded)="uploadImage('portraitMarketing', $event)"
      (remove)="remove($event, 'portraitMarketing')"
      [selectedFileUrl]="uploadedImages.portraitMarketing![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'portraitMarketing')"
      [progressItem]="images['portraitMarketing'][index]"
      [maxImageSize]="{ width: 1638.4 }"
      canvaDesignType="InstagramPostPortrait"
      aspectRatioLabel="4:5 Vertical Images"
    ></aayn-file-upload-item>
  </section>

  <!-- 1.91:1 LANDSCAPE -->
  <aayn-upload-image-modal--header
    headerLeft="1.91:1 Landscape Images"
    headerLeftDescription="Add up to 5 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary('landscapeMarketing', 5)"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.landscapeMarketing; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.landscapeMarketing.valid"
      aspectRatio="1.91:1"
      (fileAdded)="uploadImage('landscapeMarketing', $event)"
      (remove)="remove($event, 'landscapeMarketing')"
      [selectedFileUrl]="uploadedImages.landscapeMarketing![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'landscapeMarketing')"
      [progressItem]="images['landscapeMarketing'][index]"
      [maxImageSize]="{ width: 2048 }"
      canvaDesignType="FacebookAd"
      aspectRatioLabel="1.91:1 Landscape Marketing"
    ></aayn-file-upload-item>
  </section>
</aayn-upload-image-modal>
