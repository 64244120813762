import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { createObjectURL, revokeObjectURL } from '@core/utils/url';

export interface Upload<T extends any = any> {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  result?: T;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

export const calculateState = <T>(upload: Upload, event: HttpEvent<T>): Upload<T | null> => {
  if (isHttpProgressEvent(event)) {
    return {
      progress: event.total ? Math.round((90 * event.loaded) / event.total) : upload.progress,
      state: 'IN_PROGRESS'
    };
  }

  if (isHttpResponse(event)) {
    return {
      progress: 90,
      state: 'DONE',
      result: event.body
    };
  }

  return upload;
};

export function getVideoCover(file: File, seekTo = 0.0): Promise<Blob | PromiseLike<Blob> | null> {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      console.log(ex);
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */
        );
        const url = videoPlayer.getAttribute('src');
        if (url) {
          revokeObjectURL(url);
        }
      });
    });
  });
}
