import {
  CreativesCreationStartedEvent,
  EventHubEventName,
  IEventResponseByEvent,
  SingleCreativeCreationFinishedEvent
} from '@core/models';
import { makeSubscription } from '../types';

type Output =
  | IEventResponseByEvent<EventHubEventName.CreativesCreationStarted, CreativesCreationStartedEvent>
  | IEventResponseByEvent<EventHubEventName.SingleCreativeCreationFinished, SingleCreativeCreationFinishedEvent>;

export const adcEvent = makeSubscription<Output, { imageRenderProcessId: string }>(`
subscription AdcreativeEvent($imageRenderProcessId: String!) {
  adCreativeEvent(imageRenderProcessId: $imageRenderProcessId) {
    eventName
    data
  }
}
`);
