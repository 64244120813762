import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';

import { LabelType, Options } from '@angular-slider/ngx-slider';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { Linkedin } from '@core/models';
import { KeyName } from '@core/models/base.model';
import { LinkedinService } from '@core/services';
import {
  LinkedinFormService,
  LinkedinStateService,
  LinkedinValidationService
} from '@pages/create-ad/state/platforms/linkedin';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
  selector: 'aayn-manual-audience--linkedin',
  templateUrl: './manual-audience.component.html'
})
export class ManualAudienceLinkedinComponent implements OnInit {
  adCreationModel = this.linkedinStateService.adCreationModel;

  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel.audience.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    minAge: new FormControl<Linkedin.AgeBreakpoints | null>(null, []),
    maxAge: new FormControl<Linkedin.AgeBreakpoints | null>(null, []),
    genders: new FormControl(this.adCreationModel.audience.gender.include, [AppValidations.minLengthArray(1)]),
    geoLocations: new FormControl(this.adCreationModel.audience.geoLocations.include, [Validators.required]),
    excludedGeoLocations: new FormControl(this.adCreationModel.audience.geoLocations.exclude),
    flexibleDetailedTargetings: new FormControl(this.adCreationModel.audience.detailedTargetings.include),
    excludedDetailedTargetings: new FormControl(this.adCreationModel.audience.detailedTargetings.exclude),
    locales: new FormControl<KeyName[]>([])
  });

  protected estimatedReach = 0;

  private isValidByReach$ = new BehaviorSubject(false);

  labelFormatter = (value: number, label: LabelType, ageOnly = false) => {
    const labels = {
      18: '24',
      25: '34',
      35: '54',
      55: '55+'
    };
    if (label === LabelType.Low) {
      if (ageOnly) {
        return value === 55 ? '' : `${value}`;
      }
      return value === 55 ? '' : `Age ${value}`;
    } else if (label === LabelType.High) {
      if (ageOnly) {
        return `${labels[value]}`;
      }
      return `Age ${labels[value]}`;
    }
    return labels[value];
  };

  ageRangeFooterLabelFormatter = (value: number, label: LabelType) => {
    return this.labelFormatter(value, label, true);
  };

  sliderOptions: Options = {
    stepsArray: [
      { value: 18, legend: '18-24' },
      { value: 25, legend: '25-34' },
      { value: 35, legend: '35-54' },
      { value: 55, legend: '55+' }
    ],
    enforceStepsArray: false,
    translate: this.labelFormatter,

    combineLabels: (minLabel: string, maxLabel: string) => {
      return minLabel === maxLabel ? minLabel : `${minLabel} - ${maxLabel}`;
    }
  };

  constructor(
    private linkedinService: LinkedinService,
    private linkedinStateService: LinkedinStateService,
    private linkedinValidationService: LinkedinValidationService,
    private cdr: ChangeDetectorRef,
    private linkedinFormService: LinkedinFormService
  ) {}

  ngOnInit() {
    this.linkedinFormService.registerForm(this.form);

    this.form.valueChanges
      .pipe(
        //TODO: Linkedin: Make rxjs operator distincUntilChangeWithout prop
        distinctUntilChanged((prev, curr) =>
          isEqual({ ...prev, name: 'PLACEHOLDER' }, { ...curr, name: 'PLACEHOLDER' })
        ),
        debounceTime(1500)
      )
      .subscribe(() => {
        this.getEstimateReach();
      });

    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
      const { min, max } = Linkedin.getAgeRange(o.audience.ageRange.include);
      this.form.patchValue({
        name: o.audience.name,
        minAge: min,
        maxAge: o.audience.ageRange.include.length === 1 ? min : max,
        genders: o.audience.gender.include,
        geoLocations: o.audience.geoLocations.include,
        excludedGeoLocations: o.audience.geoLocations.exclude,
        excludedDetailedTargetings: o.audience.detailedTargetings.exclude,
        flexibleDetailedTargetings: o.audience.detailedTargetings.include,
        locales: o.campaign.targetingCriteria.include.locales
      });

      this.getEstimateReach();
    });
    /*
    // TODO: LINKEDIN: ADD REQUEST
    this.linkedinService
      .getSavedAudiences$()
      .pipe(map((o) => o.data))
      .subscribe((result) => {
        this._savedAudiences = result.savedAudiences.edges;
      });
*/
    this.form.valueChanges.subscribe((o) => {
      this.adCreationModel.audience.name = o.name!;
      this.adCreationModel.audience.geoLocations.include = o.geoLocations || [];
      this.adCreationModel.audience.geoLocations.exclude = o.excludedGeoLocations || [];
      this.adCreationModel.audience.detailedTargetings.include = o.flexibleDetailedTargetings || [];
      this.adCreationModel.audience.detailedTargetings.exclude = o.excludedDetailedTargetings || [];
      this.adCreationModel!.campaign.targetingCriteria.include.locales = o.locales || [];
    });

    combineLatest([this.form.statusChanges.pipe(startWith(this.form.status)), this.isValidByReach$]).subscribe(
      ([status, isValidByReach]) => {
        this.linkedinValidationService.audienceValid$.next(status === 'VALID' && isValidByReach);
      }
    );
  }

  private getEstimateReach() {
    if (!this.hasAddedGeoLocations) {
      this.estimatedReach = 0;

      return;
    }

    const {
      locales,
      geoLocations,
      excludedGeoLocations,
      flexibleDetailedTargetings,
      excludedDetailedTargetings,
      genders
    } = this.form.value;
    const input = Linkedin.getTargetingModel({
      audience: {
        name: '',
        geoLocations: {
          include: geoLocations || [],
          exclude: excludedGeoLocations || []
        },
        detailedTargetings: {
          include: flexibleDetailedTargetings || [],
          exclude: excludedDetailedTargetings || []
        },
        gender: {
          include: genders || []
        },
        ageRange: {
          include: Linkedin.generateUrns(this.form.value.minAge!, this.form.value.maxAge!)
        },
        locales: {
          include: locales || []
        }
      }
    });
    this.linkedinValidationService.audienceValid$.next(false);
    this.linkedinService.getAudienceCounts({ input }).subscribe((result) => {
      this.estimatedReach = result.total;
      this.isValidByReach$.next(result.total > 300);
      this.cdr.detectChanges();
    });
  }

  private get hasAddedGeoLocations() {
    const geoLocations = this.adCreationModel.audience.geoLocations;
    return geoLocations?.include?.length;
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    this.adCreationModel.audience.ageRange!.include = Linkedin.generateUrns(
      min as Linkedin.AgeBreakpoints,
      max as Linkedin.AgeBreakpoints
    );
    if (min && max) {
      this.form.patchValue({
        minAge: min as Linkedin.AgeBreakpoints,
        maxAge: max as Linkedin.AgeBreakpoints
      });
    }
  }

  getAgeRange(urn: Linkedin.AgeRangeURN[], type: 'min' | 'max') {
    const range = Linkedin.getAgeRange(urn);
    return range[type];
  }
}
