<aayn-manuel-audience-setup>
  <form [formGroup]="form" #_form="ngForm">
    <aayn-audience-group style="z-index: 99; position: relative">
      <aayn-audience-item
        title="Location"
        description="Choose your target locations. Your ads will only be shown to people in these areas."
      >
        <aayn-location-selection--smart-campaign [form]="form"></aayn-location-selection--smart-campaign>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience--height:300">
      <aayn-audience-item
        [title]="'Age Range' | translate"
        description="Define your target age range. Your ads will only appear to individuals within this age bracket."
      >
        <aayn-audience-age-range
          [value]="adCreationModel.audience.age.min"
          [maxValue]="adCreationModel.audience.age.max"
          (ageRangeChanged)="ageRangeChanged($event)"
          [texts]="{ age: 'Age' | translate }"
        ></aayn-audience-age-range>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Gender' | translate"
        description="Select the gender of your target audience. Your ads will be displayed exclusively to the selected gender."
      >
        <aayn-gender-selection--smart-campaign [form]="form"></aayn-gender-selection--smart-campaign>
      </aayn-audience-item>

      <aayn-audience-item
        *aynSmartHasPlatform="[platforms.Meta, platforms.Google, platforms.LinkedIn, platforms.TikTok]"
        [title]="'Audience Language' | translate"
        description="Specify your target language(s). Your ads will only be shown to users who speak the selected language(s)."
      >
        <aayn-language-selection--smart-campaign [form]="form"></aayn-language-selection--smart-campaign>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience-item--precision-audience-creation">
      <aayn-audience-item
        [title]="'Precision Audience Creation AI' | translate"
        description="Share details about your product or service, and let our advanced AI dynamically generate, refine, and optimize target audiences for you."
        icon="brain"
      >
        <aayn-detailed-targeting--smart-campaign [form]="form"></aayn-detailed-targeting--smart-campaign>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item [title]="'Audience Name' | translate" [flexed]="true" headerStyle="margin-bottom: 0">
        <ayn-form-field [style.max-width]="'65%'" class="ml:auto">
          <input
            type="text"
            ayn-input
            [placeholder]="'Write a audience name' | translate"
            formControlName="name"
            [ngModel]="adCreationModel.audience.name"
          />
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>
  </form>
</aayn-manuel-audience-setup>
