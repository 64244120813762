import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Linkedin } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    LinkedinAdPreviewService
} from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/ad-preview/ad-preview.service';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { LinkedinStateService } from '@pages/create-ad/state';

@UntilDestroy()
@Component({
  selector: 'aayn-select-ad-type--linkedin',
  templateUrl: './select-ad-type-content.component.html',
  providers: [LinkedinAdPreviewService]
})
export class SelectAdTypeContentLinkedinComponent implements OnInit {
  protected adTypes: ICreateAdSelectItem<Linkedin.AdType>[] = [
    {
      id: 1,
      title: 'Dynamic Image Ads',
      description: 'Perfect for advertising 1 product/service using a single image or video.',
      icon: 'dynamic',
      empty: false,
      active: true,
      type: Linkedin.AdType.SingleImage
    },
    {
      id: 2,
      title: 'Carousel Ads',
      description: 'Perfect for advertising 1 product/service using a single image or video.',
      icon: 'carousel',
      empty: false,
      active: false,
      type: Linkedin.AdType.CarouselImage
    },
    {
      id: 3,
      title: '',
      description: '',
      icon: '',
      empty: true,
      active: false,
      type: undefined
    }
  ];

  protected AdTypes = Linkedin.AdType;

  protected selectedAdType: Linkedin.AdType = Linkedin.AdType.SingleImage;

  constructor(private linkedinStateService: LinkedinStateService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.linkedinStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adTypes.forEach((x) => {
        if (x.type === o.selectedTypes.ad) {
          x.active = true;
          this.selectedAdType = x.type!;
        } else {
          x.active = false;
        }
      });
      this.cdr.detectChanges();
    });
  }

  adTypeChangeStatus(item: ICreateAdSelectItem<Linkedin.AdType>) {
    this.adTypes.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAdType = item.type!;
      } else {
        x.active = false;
      }
    });

    this.linkedinStateService.changeAdType(item.type!);
  }
}
