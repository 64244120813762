import { Injectable } from '@angular/core';
import { OpenAI } from '@core/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { OpenAiService } from '@core/services/open-ai.service';

export namespace TextGeneratorAi {
  export interface State {
    getTexts: string[];
  }
}

export class OpenAiGenerateTextProject {
  static readonly type = '[TextGeneratorAi] OpenAi';
  static readonly desc = 'get openAi';
  constructor(public payload: OpenAI.IGenerateTextProjectRequest) {}
}

export class RemoveAllText {
  static readonly type = '[TextGeneratorAi] RemoveAllText';
  static readonly desc = 'delete RemoveAllText';
}

export const TEXT_GENERATOR_AI_DEFAULTS: TextGeneratorAi.State = {
  getTexts: []
};

@State<TextGeneratorAi.State>({
  name: 'TextGeneratorAiState',
  defaults: TEXT_GENERATOR_AI_DEFAULTS
})
@Injectable()
export class TextGeneratorAiState {
  constructor(private openAiService: OpenAiService) {}

  @Selector()
  static GetTexts({ getTexts }: TextGeneratorAi.State) {
    return getTexts;
  }

  @Action(OpenAiGenerateTextProject)
  get({ patchState }: StateContext<TextGeneratorAi.State>, { payload }: OpenAiGenerateTextProject) {
    return this.openAiService.getOpenAiGenerateTextProject(payload).pipe(
      tap((result) => {
        patchState({ getTexts: result?.texts });
      })
    );
  }

  @Action(RemoveAllText)
  removeAll({ patchState }: StateContext<TextGeneratorAi.State>) {
    patchState({ getTexts: [] });
  }
}
