import { Injectable } from '@angular/core';
import { OpenAI } from '@core/models/open-ai.model';
import { Store } from '@ngxs/store';
import { BusinessState } from '@core/state/business.state';
import { BehaviorSubject } from 'rxjs';

export type ProductInfo = Pick<OpenAI.TextCraftCommonInput, 'productName' | 'productDescription'>;

@Injectable({ providedIn: 'root' })
export class TextAiContainerService {
  productInfo = {} as ProductInfo;

  textCraftActive$ = new BehaviorSubject(false);

  constructor(private store: Store) {}

  setProductInfo(info: ProductInfo) {
    const business = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    this.productInfo = {
      productDescription: info.productDescription,
      productName: business?.name || info.productName
    };
  }
}
