import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-select-platform-platforms--header',
  templateUrl: './select-platform-platforms-header.component.html'
})
export class SelectPlatformPlatformsHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
