import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Facebook } from '@core/models';
import { FacebookStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-gender-selection--facebook',
  template: `
    <section class="aayn-audience--gender-selection">
      <button
        ayn-button="primary"
        type="button"
        icon="male"
        [class.active]="isSelectedMale"
        (click)="selectGender(Gender.Men)"
      >
        {{ 'Male' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="female"
        [class.active]="isSelectedFemale"
        (click)="selectGender(Gender.Women)"
      >
        {{ 'Female' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="all-genders"
        [class.active]="isSelectedAllGenders"
        (click)="selectAllGender()"
      >
        {{ 'All Genders' | translate }}
      </button>
    </section>
  `
})
export class GenderSelectionFacebookComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.facebookStateService.adCreationModel;

  protected Gender = Facebook.Targeting.Gender;

  constructor(private facebookStateService: FacebookStateService) {}

  ngOnInit() {
    this.facebookStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  protected get isSelectedMale() {
    return (
      this.adCreationModel?.adSet.targeting?.genders.length == 1 &&
      this.adCreationModel?.adSet.targeting?.genders.at(0) === Facebook.Targeting.Gender.Men
    );
  }

  protected get isSelectedFemale() {
    return (
      this.adCreationModel?.adSet.targeting?.genders.length == 1 &&
      this.adCreationModel?.adSet.targeting?.genders.at(0) === Facebook.Targeting.Gender.Women
    );
  }

  protected get isSelectedAllGenders() {
    return this.adCreationModel?.adSet.targeting?.genders?.length == 2;
  }

  protected selectGender(gender: Facebook.Targeting.Gender) {
    this.adCreationModel!.adSet.targeting!.genders = [gender];

    this.form?.patchValue({
      genders: this.adCreationModel?.adSet.targeting?.genders
    });
  }

  protected selectAllGender() {
    this.adCreationModel!.adSet.targeting!.genders = [Facebook.Targeting.Gender.Men, Facebook.Targeting.Gender.Women];

    this.form?.patchValue({
      genders: this.adCreationModel?.adSet.targeting?.genders
    });
  }
}
