import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  openCenteredWindow(url: string, title: string, width: number, height: number) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const _width = window.innerWidth || document.documentElement.clientWidth || screen.width;
    const _height = window.innerHeight || document.documentElement.clientHeight || screen.height;

    const systemZoom = _width / window.screen.availWidth;
    const left = (_width - width) / 2 / systemZoom + dualScreenLeft;
    const top = (_height - height) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
          scrollbars=yes,
          width=${width / systemZoom},
          height=${height / systemZoom},
          top=${top},
          left=${left}
          `
    );

    newWindow?.focus();

    return newWindow;
  }
}
