<aayn-upload-image-modal
  header="Select Images"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
  icon="folder"
>
  <aayn-upload-image-modal--header headerLeft="Logos" headerRight="Add up to 2 logos"></aayn-upload-image-modal--header>
  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      [isPrimary]="false"
      [maintainAspectRatio]="true"
      [progressItem]="images['squareLogo'][0]"
      [cropperScalable]="true"
      [selectedFileUrl]="uploadedImages.squareLogo![0]?.url"
      (selectedFileUrlChange)="selectedFileChange(0, 'squareLogo')"
      (fileAdded)="uploadImage('squareLogo', $event)"
      (remove)="remove($event, 'squareLogo')"
      [minImageSize]="{ width: 128, height: 128 }"
      [maxImageSize]="{ width: 2048 }"
      imageOutput="png"
      aspectRatioLabel="1:1 Square Logo"
      canvaDesignType="FacebookAd"
    ></aayn-file-upload-item>

    <aayn-file-upload-item
      [isPrimary]="false"
      [maintainAspectRatio]="true"
      [progressItem]="images['logo'][0]"
      [cropperScalable]="true"
      [selectedFileUrl]="uploadedImages.logo![0]?.url"
      (selectedFileUrlChange)="selectedFileChange(0, 'logo')"
      (fileAdded)="uploadImage('logo', $event)"
      (remove)="remove($event, 'logo')"
      [minImageSize]="{ width: 512, height: 128 }"
      [maxImageSize]="{ width: 2048 }"
      aspectRatio="4:1"
      imageOutput="png"
      aspectRatioLabel="4:1 Landspace Logo"
      canvaDesignType="Logo"
    ></aayn-file-upload-item>
  </section>

  <!-- 1:1 SQUARE -->
  <aayn-upload-image-modal--header
    headerLeft="1:1 Square Images"
    headerLeftDescription="Add up to 5 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary('squareMarketing', 5)"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.squareMarketing; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.squareMarketing.valid"
      aspectRatio="1:1"
      (fileAdded)="uploadImage('squareMarketing', $event)"
      (remove)="remove($event, 'squareMarketing')"
      [selectedFileUrl]="uploadedImages.squareMarketing![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'squareMarketing')"
      [progressItem]="file"
      canvaDesignType="InstagramPost"
      aspectRatioLabel="1:1 Square Images"
      [minImageSize]="{ width: 300, height: 300 }"
      [maxImageSize]="{ width: 2048 }"
      [maintainAspectRatio]="true"
    ></aayn-file-upload-item>
  </section>

  <!-- 1.91:1 LANDSCAPE -->

  <aayn-upload-image-modal--header
    headerLeft="1.91:1 Landscape Images"
    headerLeftDescription="Add up to 5 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary('marketing', 5)"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images.marketing; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid && !imageInfo.marketing.valid"
      aspectRatio="1.91:1"
      (fileAdded)="uploadImage('marketing', $event)"
      (remove)="remove($event, 'marketing')"
      [selectedFileUrl]="uploadedImages.marketing![index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index, 'marketing')"
      [progressItem]="file"
      canvaDesignType="FacebookAd"
      aspectRatioLabel="1.91:1 Landscape Marketing"
      [minImageSize]="{ width: 600, height: 314 }"
      [maxImageSize]="{ width: 2048 }"
      [maintainAspectRatio]="true"
    ></aayn-file-upload-item>
  </section>
</aayn-upload-image-modal>
