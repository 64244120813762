import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  Input,
  OnChanges,
  Optional,
  QueryList,
  SimpleChanges,
  SkipSelf
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateAdInputComponent } from '@shared/components/create-ad-input';
import { filter, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { FileUploadAreaComponent, ImageUrls, isUrlObject } from '@ayn-ui/lib/modules/base/file-upload-area';
import { CarouselCardComponent } from '@shared/components/carousel-card';
import { Select } from '@ayn-ui/lib/modules';

@UntilDestroy()
@Directive({
  selector: '[adPreview]'
})
export class AdPreviewDirective implements OnChanges {
  @Input() adPreview!: string;

  @Input() standAlone = false;

  destroy$ = new Subject();

  index?: number;

  get container() {
    if (this.parent && this.index !== undefined) {
      return this.adPreviewContainer!.adPreviewContainer[this.parent.adPreview][this.index];
    }
    return this.adPreviewContainer!.adPreviewContainer;
  }

  set container(value) {
    this.adPreviewContainer!.adPreviewContainer[this.parent!.adPreview][this.index!] = value;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @SkipSelf() private parent?: AdPreviewDirective,
    @Optional() private adPreviewContainer?: AdPreviewContainerDirective,
    @Optional() private control?: NgControl,
    @Optional() private fileUploadAreaComponent?: FileUploadAreaComponent,
    @Optional() private aynSelect?: Select
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standAlone) {
      if (changes.standAlone.currentValue) {
        this.listen();
      } else {
        this.unsubscribe();
      }
    }
  }

  listen() {
    if (this.adPreviewContainer) {
      if (this.control) {
        this.listenFormElement();
      } else if (this.fileUploadAreaComponent) {
        this.listenFileUploadArea();
      }
    }
  }

  listenFormElement() {
    this.control!.valueChanges?.pipe(
      untilDestroyed(this),
      takeUntil(this.destroy$),
      startWith(this.control!.value)
    ).subscribe((value) => {
      if (!this.container) {
        this.container = {};
      }
      if (this.aynSelect) {
        this.aynSelect.initialized$
          .pipe(
            switchMap(() => (this.aynSelect!.__options.length ? of('') : this.aynSelect!.contentOptions.changes)),
            take(1)
          )
          .subscribe(() => {
            this.container[this.adPreview] = this.aynSelect!.selectedValue;
          });
      } else {
        this.container[this.adPreview] = value;
      }
      this.cdr.detectChanges();
    });
  }

  listenFileUploadArea() {
    this.fileUploadAreaComponent!.images$.pipe(untilDestroyed(this), takeUntil(this.destroy$)).subscribe(
      (imageEvent) => {
        const { imageUrls, imageGroups } = imageEvent;
        if (imageUrls.length) {
          this.container[this.adPreview] = [...imageUrls.map((image) => (isUrlObject(image) ? image.url : image))];
        } else if (imageGroups.length) {
          this.container[this.adPreview] = imageGroups.reduce(
            (acc, group) => [...acc, ...group.imageUrls.map((image) => (isUrlObject(image) ? image.url : image))],
            [] as ImageUrls
          );
        }
        this.cdr.detectChanges();
      }
    );
  }

  unsubscribe() {
    this.destroy$.next();
  }

  removeParent() {
    if (this.parent && this.index !== undefined) {
      this.adPreviewContainer!.adPreviewContainer[this.parent.adPreview].splice(this.index, 1);
    }
  }
}

type AdPreviewGroup = { [key: string]: Array<[CreateAdInputComponent, AdPreviewDirective]> };

@UntilDestroy()
@Component({
  selector: '[adPreviewContainer]',
  template: `<ng-content></ng-content>`
})
export class AdPreviewContainerDirective implements AfterViewInit {
  @Input() adPreviewContainer!: any;

  @ContentChildren(CreateAdInputComponent, { descendants: true })
  createAdInputContents!: QueryList<CreateAdInputComponent>;

  @ContentChildren(CreateAdInputComponent, { read: AdPreviewDirective, descendants: true })
  adPreviewDirective!: QueryList<AdPreviewDirective>;

  @ContentChildren(CarouselCardComponent, { descendants: true })
  carouselCards?: QueryList<CarouselCardComponent>;

  @ContentChildren(CarouselCardComponent, { descendants: true, read: AdPreviewDirective })
  cardAdPreviews?: QueryList<AdPreviewDirective>;

  get createAdInputComponents() {
    return this.createAdInputContents;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.carouselCards?.toArray().length) {
      this.listenCarouselCards();
    } else {
      this.listenCreateAdInputComponents();
    }
  }

  listenCarouselCards() {
    let cards: Array<CarouselCardComponent> = [];
    this.carouselCards!.changes.pipe(startWith(this.carouselCards), filter(Boolean), untilDestroyed(this)).subscribe(
      () => {
        if (cards) {
          cards.forEach((card) => {
            card.fileAdPreview.removeParent();
          });
        }
        cards = this.carouselCards!.toArray();
        cards.forEach((card, index) => {
          card.adPreview.toArray().forEach((inputAdPreview) => {
            inputAdPreview.index = index;
            inputAdPreview.listen();
          });
          card.fileAdPreview.index = index;
          card.fileAdPreview.listen();
          card.previewActiveChange
            .pipe(takeUntil(this.carouselCards!.changes), untilDestroyed(this))
            .subscribe((active) => {
              if (active) {
                this.adPreviewContainer.activeIndex$.next(index);
                cards.forEach((_card, _index) => {
                  if (index !== _index) {
                    _card.disablePreview();
                  }
                  this.cdr.detectChanges();
                });
              }
            });
          if (index === 0) {
            card.enablePreview();
          }
        });
        this.adPreviewContainer.activeIndex$
          .pipe(takeUntil(this.carouselCards!.changes), untilDestroyed(this))
          .subscribe((activeIndex: number) => {
            cards.forEach((card, index) => {
              if (index === activeIndex) {
                card.previewActive = true;
              } else {
                card.disablePreview();
              }
            });
            this.cdr.detectChanges();
          });
      }
    );
  }

  listenCreateAdInputComponents() {
    this.createAdInputComponents.changes
      .pipe(startWith(this.createAdInputComponents), filter(Boolean), untilDestroyed(this))
      .subscribe(() => {
        const groups = this.createAdInputComponents.toArray().reduce((acc, item, index) => {
          const adPreview = this.adPreviewDirective.get(index);
          if (adPreview) {
            acc[adPreview.adPreview] = [...(acc[adPreview.adPreview] || []), [item, adPreview]];
          }
          return acc;
        }, {} as AdPreviewGroup);
        this.listenValueChanges(groups, this.createAdInputComponents.changes, true);
      });
  }

  listenValueChanges(groups: AdPreviewGroup, changes: Observable<any>, emitFirstOrSelected: boolean) {
    Object.values(groups).forEach((group) => {
      group.forEach(([component, adPreview], index) => {
        component.previewActiveChange.pipe(takeUntil(changes), untilDestroyed(this)).subscribe((active) => {
          if (active) {
            const activeIndex = index;
            adPreview.listen();
            group.forEach(([item, preview], index) => {
              if (activeIndex !== index) {
                item.previewActive = false;
                preview.unsubscribe();
              }
            });
          } else {
            adPreview.unsubscribe();
          }
        });
      });

      if (emitFirstOrSelected) {
        const active = group.find(([component]) => component.previewActive);
        if (active) {
          active[0].previewActiveChange.next(true);
        } else {
          const [component] = group[0];
          component.previewActive = true;
          component.previewActiveChange.next(true);
        }
      }
    });
  }
}
