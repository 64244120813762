import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  savedAudiences: {
    edges: { node: Facebook.SavedAudienceItem }[];
  };
};

export const getSavedAudiences = makeQuery<Output>(`
  query GetSavedAudiences {
    facebook {
      savedAudiences {
        edges {
          node {
            id
            name
            targeting {
              minAge
              maxAge
              locales
              genders
            }
          }
        }
      }
    }
  }
`);
