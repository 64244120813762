import { Linkedin, SmartCampaign } from '@core/models';
import { makeMutation, OffsetPaginatedQueryResult } from '@core/queries/types';

const queryField = 'linkedin';

type Input = Linkedin.Dashboard.ICampaignRequest;
type Output = OffsetPaginatedQueryResult<
  typeof queryField,
  SmartCampaign.Client.SmartCampaignResult<Linkedin.Dashboard.ILinkedInCampaignOutput>
>;

export const getCampaigns = makeMutation<Output, Input>(`
query CampaignsLinkedin(
  $skip: Int
  $take: Int
  $startDate: SafeInt!
  $endDate: SafeInt
  $names: [String!]
  $statuses: [LinkedInCampaignStatus!]
) {
  ayn {
    campaign {
      ${queryField}(
        skip: $skip
        take: $take
        startDate: $startDate
        endDate: $endDate
        names: $names
        statuses: $statuses
      ) {
        edges {
          cursor
          node {
            isSmartCampaign
            externalId
            status
            name
            budget
            budgetPeriod
            dateRange {
              start
              end
            }
            conversionValue
            conversion
            impressions
            clicks
            costPerClick
            totalSpent
            currencyCode
            campaignGroup
          }
        }
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          from
          to
        }
      }
    }
  }
}
`);
