import { environment } from '@environment';

import { PropertyPath, ValueByPropertyPath } from './types';

/**
 * Extending object that entered in first argument.
 *
 * Returns extended object or false if have no target object or incorrect type.
 *
 * If you wish to clone source object (without modify it), just use empty new
 * object as first argument, like this:
 *   deepExtend({}, yourObj_1, [yourObj_N]);
 */
export const deepExtend = function (...objects: any[]): any {
  if (arguments.length < 1 || typeof arguments[0] !== 'object') {
    return false;
  }

  if (arguments.length < 2) {
    return arguments[0];
  }

  const target = arguments[0];

  // convert arguments to array and cut off target object
  const args = Array.prototype.slice.call(arguments, 1);

  let val, src;

  args.forEach(function (obj: any) {
    // skip argument if it is array or isn't object
    if (typeof obj !== 'object' || Array.isArray(obj)) {
      return;
    }

    Object.keys(obj).forEach(function (key) {
      src = target[key]; // source value
      val = obj[key]; // new value

      // recursion prevention
      if (val === target) {
        return;

        /**
         * if new value isn't object then just overwrite by new value
         * instead of extending.
         */
      } else if (typeof val !== 'object' || val === null) {
        target[key] = val;

        return;

        // just clone arrays (and recursive clone objects inside)
      } else if (Array.isArray(val)) {
        target[key] = deepCloneArray(val);

        return;

        // custom cloning and overwrite for specific objects
      } else if (isSpecificValue(val)) {
        target[key] = cloneSpecificValue(val);

        return;

        // overwrite by new value if source isn't object or array
      } else if (typeof src !== 'object' || src === null || Array.isArray(src)) {
        target[key] = deepExtend({}, val);

        return;

        // source value and new value is objects both, extending...
      } else {
        target[key] = deepExtend(src, val);

        return;
      }
    });
  });

  return target;
};

function isSpecificValue(val: any) {
  return val instanceof Date || val instanceof RegExp;
}

function cloneSpecificValue(val: any): any {
  if (val instanceof Date) {
    return new Date(val.getTime());
  } else if (val instanceof RegExp) {
    return new RegExp(val);
  } else {
    throw new Error('cloneSpecificValue: Unexpected situation');
  }
}

/**
 * Recursive cloning array.
 */
function deepCloneArray(arr: any[]): any {
  const clone: any[] = [];
  arr.forEach(function (item: any, index: any) {
    if (typeof item === 'object' && item !== null) {
      if (Array.isArray(item)) {
        clone[index] = deepCloneArray(item);
      } else if (isSpecificValue(item)) {
        clone[index] = cloneSpecificValue(item);
      } else {
        clone[index] = deepExtend({}, item);
      }
    } else {
      clone[index] = item;
    }
  });

  return clone;
}

// getDeepFromObject({result: {data: 1}}, 'result.data', 2); // returns 1
export function getDeepFromObject(object = {}, name: string, defaultValue?: any) {
  const keys = name.split('.');
  // clone the object
  let level = deepExtend({}, object || {});
  keys.forEach((k) => {
    if (level && typeof level[k] !== 'undefined') {
      level = level[k];
    } else {
      level = undefined;
    }
  });

  return typeof level === 'undefined' ? defaultValue : level;
}

export function dig<T extends object, TPath extends PropertyPath<T>>(
  object: T,
  name: TPath,
  defaultValue?: ValueByPropertyPath<T, TPath>
): ValueByPropertyPath<T, TPath> | undefined {
  if (typeof object !== 'object') {
    if (!environment.production) {
      console.warn(`[dig] - Given parameter was not an object`);
    }

    return defaultValue;
  }

  const propPath = name.split('.');
  let current = deepExtend({}, object || {});

  for (const prop of propPath) {
    if (prop in current) {
      current = current[prop];
    } else {
      return defaultValue;
    }
  }

  return current ?? defaultValue;
}

export function decodeToken(token: string) {
  const _decodeToken = (token: string) => {
    try {
      return JSON.parse(atob(token));
    } catch {
      return;
    }
  };
  return token
    .split('.')
    .map((token) => _decodeToken(token))
    .reduce((acc, curr) => {
      if (!!curr) acc = { ...acc, ...curr };
      return acc;
    }, Object.create(null));
}

export function notNull(value: any): boolean {
  return value != null;
}

export const notNil = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const truthy = <T>(value: T): value is NonNullable<T> => {
  return !!value;
};

export function getDateTime(value: any): number {
  return Math.floor(new Date(value).getTime() / 1000);
}

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function tryStringify<T>(value: T): string | T {
  try {
    return JSON.stringify(value);
  } catch {
    return value;
  }
}

export function tryParse<T>(value: string): undefined | T {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}
