import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from '@ayn-ui/public-api';

export type EmptyContentType = 'default' | 'dashboard' | 'clickfraud' | '';

@Component({
  selector: 'aayn-empty-content',
  templateUrl: './empty-content.component.html'
})
export class EmptyContentComponent implements OnInit {
  @Input() title: string = '';

  @Input() description?: string;

  @Input() showButton: boolean = false;

  @Input() buttonName = 'Refresh';

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter<Event>();

  @Input() buttonType: ButtonType = 'primary-outline';

  @Input() btnIcon: string = '';

  @Input() animation: string = 'empty-new-ayn';

  @Input() type: EmptyContentType = 'default';

  constructor() {}

  ngOnInit(): void {}
}
