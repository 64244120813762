<div class="aayn-create-ad--header">
  <div class="aayn-create-ad--header--audience">
    <div class="aayn-create-ad--header--audience__name" *ngIf="locations" [title]="locations.join(', ')">
      {{ locations.slice(0, 5).join(', ') }}
      <ng-container *ngIf="locations.length > maxLocationCount"
        >, +{{ locations.length - maxLocationCount }} Locations</ng-container
      >
    </div>
  </div>
</div>
