import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IOauthRequest;
type Output = Google.Dashboard.IOauthResponse;

export const oauthGoogle = makeMutation<Output, Input>(`
query GetGoogleOAuthUrl($contextId: String!) {
  google {
    oauth(contextId: $contextId) {
      oauthUrl
    }
  }
}
`);
