import { IOption } from '@ayn-ui/lib/modules';
import { OffsetPaginationArgs } from '@core/models/base.model';

export module __Dashboard {
  export interface TikTokAccountOverviewRequest {
    startDate: number;
    endDate: number;
    interval: TikTokInsightInterval;
  }

  export interface TikTokAccountOverviewTotalRequest {
    startDate: number;
    endDate: number;
  }

  export enum TikTokAudienceDimension {
    Gender = 'Gender',
    Age = 'Age',
    CountryCode = 'CountryCode',
    AudienceNetwork = 'AudienceNetwork',
    Language = 'Language',
    Platform = 'Platform',
    Placement = 'Placement'
  }

  export interface TikTokBreakdownsRequest extends TikTokAccountOverviewTotalRequest {
    identifier: TikTokAudienceDimension;
  }

  export interface TikTokCampaign {
    campaignName: string;
    campaignId: string;
    campaignType: string;
    budget: number;
    budgetMode: string;
    operationStatus: TikTokCampaignOperationStatusEnumType;
    objective: string;
    objectiveType: string;
    createTime: string;
  }

  export enum TikTokInsightInterval {
    Hour = 'Hour',
    Date = 'Date'
  }

  export interface TikTokCommonMetricsOutputDto {
    reach?: string;
    appInstall?: string;
    clicks?: string;
    clickThroughRate?: string;
    comments?: string;
    conversion?: string;
    follows?: string;
    frequency?: string;
    impressions?: string;
    inAppAddToCart?: string;
    inAppAddToWishlist?: string;
    inAppPurchase?: string;
    inAppSubscribe?: string;
    likes?: string;
    offlineAddPaymentInfo?: string;
    offlineAddToCart?: string;
    offlineAddToWishlist?: string;
    offlineCompletePayment?: string;
    offlineCompleteRegistration?: string;
    offlineDownload?: string;
    offlineSubscribe?: string;
    onsiteAddBilling?: string;
    onsiteAddToCart?: string;
    onsiteAddToWishList?: string;
    onsiteCompletePayment?: string;
    profileVisits?: string;
    result?: string;
    shares?: string;
    videoViews?: string;
    websiteAddPaymentInfo?: string;
    websiteAddToCart?: string;
    websiteAddToWishlist?: string;
    websiteCompletePayment?: string;
    websiteCompleteRegistration?: string;
    websiteDownload?: string;
    websitePageView?: string;
    websiteSubscribe?: string;
  }

  export interface TikTokAccountOverviewOutputDto extends TikTokCommonMetricsOutputDto {
    timePeriod?: string;
    costPer1KImpressions?: string;
    costPerConversion?: string;
    costPerClick?: string;
    costPerReach?: string;
    spend?: string;
    costPer1KReach?: string;
  }

  export enum AccountOverviewType {
    AppInstall = 'appInstall',
    Clicks = 'clicks',
    ClickThroughRate = 'clickThroughRate',
    Comments = 'comments',
    Conversion = 'conversion',
    CostPer1KImpressions = 'costPer1KImpressions',
    CostPer1KReach = 'costPer1KReach',
    CostPerClick = 'costPerClick',
    CostPerConversion = 'costPerConversion',
    CostPerReach = 'costPerReach',
    Follows = 'follows',
    Frequency = 'frequency',
    Impressions = 'impressions',
    InAppAddToCart = 'inAppAddToCart',
    InAppAddToWishlist = 'inAppAddToWishlist',
    InAppPurchase = 'inAppPurchase',
    InAppSubscribe = 'inAppSubscribe',
    Likes = 'likes',
    OfflineAddPaymentInfo = 'offlineAddPaymentInfo',
    OfflineAddToCart = 'offlineAddToCart',
    OfflineAddToWishlist = 'offlineAddToWishlist',
    OfflineCompletePayment = 'offlineCompletePayment',
    OfflineCompleteRegistration = 'offlineCompleteRegistration',
    OfflineDownload = 'offlineDownload',
    OfflineSubscribe = 'offlineSubscribe',
    OnsiteAddBilling = 'onsiteAddBilling',
    OnsiteAddToCart = 'onsiteAddToCart',
    OnsiteAddToWishList = 'onsiteAddToWishList',
    OnsiteCompletePayment = 'onsiteCompletePayment',
    ProfileVisits = 'profileVisits',
    Reach = 'reach',
    Result = 'result',
    Shares = 'shares',
    Spend = 'spend',
    TimePeriod = 'timePeriod',
    VideoViews = 'videoViews',
    WebsiteAddPaymentInfo = 'websiteAddPaymentInfo',
    WebsiteAddToCart = 'websiteAddToCart',
    WebsiteAddToWishlist = 'websiteAddToWishlist',
    WebsiteCompletePayment = 'websiteCompletePayment',
    WebsiteCompleteRegistration = 'websiteCompleteRegistration',
    WebsiteDownload = 'websiteDownload',
    WebsitePageView = 'websitePageView',
    WebsiteSubscribe = 'websiteSubscribe'
  }

  export const AccountOverviewTypes: IOption<keyof TikTokCommonMetricsOutputDto>[] = [
    { label: 'App Install', value: 'appInstall' },
    { label: 'Clicks', value: 'clicks' },
    { label: 'Click Through Rate', value: 'clickThroughRate' },
    { label: 'Comments', value: 'comments' },
    { label: 'Conversion', value: 'conversion' },
    { label: 'Follows', value: 'follows' },
    { label: 'Frequency', value: 'frequency' },
    { label: 'Impressions', value: 'impressions' },
    { label: 'In App Add To Cart', value: 'inAppAddToCart' },
    { label: 'In App Add To Wishlist', value: 'inAppAddToWishlist' },
    { label: 'In App Purchase', value: 'inAppPurchase' },
    { label: 'In App Subscribe', value: 'inAppSubscribe' },
    { label: 'Likes', value: 'likes' },
    { label: 'Offline Add Payment Info', value: 'offlineAddPaymentInfo' },
    { label: 'Offline Add To Cart', value: 'offlineAddToCart' },
    { label: 'Offline Add To Wishlist', value: 'offlineAddToWishlist' },
    { label: 'Offline Complete Payment', value: 'offlineCompletePayment' },
    { label: 'Offline Complete Registration', value: 'offlineCompleteRegistration' },
    { label: 'Offline Download', value: 'offlineDownload' },
    { label: 'Offline Subscribe', value: 'offlineSubscribe' },
    { label: 'Onsite Add Billing', value: 'onsiteAddBilling' },
    { label: 'Onsite Add To Cart', value: 'onsiteAddToCart' },
    { label: 'Onsite Add To Wishlist', value: 'onsiteAddToWishList' },
    { label: 'Onsite Complete Payment', value: 'onsiteCompletePayment' },
    { label: 'Profile Visits', value: 'profileVisits' },
    { label: 'Reach', value: 'reach' },
    { label: 'Result', value: 'result' },
    { label: 'Shares', value: 'shares' },
    { label: 'Video Views', value: 'videoViews' },
    { label: 'Website Add Payment Info', value: 'websiteAddPaymentInfo' },
    { label: 'Website Add To Cart', value: 'websiteAddToCart' },
    { label: 'Website Add To Wishlist', value: 'websiteAddToWishlist' },
    { label: 'Website Complete Payment', value: 'websiteCompletePayment' },
    { label: 'Website Complete Registration', value: 'websiteCompleteRegistration' },
    { label: 'Website Download', value: 'websiteDownload' },
    { label: 'Website Page View', value: 'websitePageView' },
    { label: 'Website Subscribe', value: 'websiteSubscribe' }
  ];

  type TikTokCommonBreakdownsMetricsOutputDto = Pick<
    TikTokAccountOverviewOutputDto,
    'costPerClick' | 'impressions' | 'spend' | 'clickThroughRate'
  >;

  export interface TikTokBreakdownsOutputDto extends TikTokCommonBreakdownsMetricsOutputDto {
    identifier?: string;
    result?: string;
    costPerResult?: string;
  }
  export type BreakdownType = keyof TikTokBreakdownsOutputDto;

  export enum TikTokInsightStatus {
    Enabled = 'enabled',
    Paused = 'paused'
  }

  export interface TikTokCampaignsWithMetricsRequest extends OffsetPaginationArgs {
    searchKey?: string;
    status?: TikTokInsightStatus;
    startDate: number;
    endDate: number;
  }

  export interface TikTokAdGroupsWithMetricsRequest extends TikTokCampaignsWithMetricsRequest {
    campaignId: string;
  }

  export interface TikTokAdsWithMetricsRequest extends TikTokCampaignsWithMetricsRequest {
    adGroupId: string;
  }

  export enum TikTokCampaignStatus {
    DISABLE = 'Disable',
    ENABLE = 'Enable',
    REMOVED = 'REMOVED'
  }

  export interface TikTokCampaignEntityCommonSummaryOutputDto {
    name?: string;
    id?: string;
    status?: TikTokCampaignStatus;
    budget?: string;
    budgetMode?: string;
    startDate?: string;
    endDate?: string;
    clicks?: string;
    costPerClick?: string;
    impressions?: string;
    conversion?: string;
    purchase?: string;
    result?: string;
    spend?: string;
  }

  export interface TikTokCampaignSummaryOutputDto extends TikTokCampaignEntityCommonSummaryOutputDto {
    campaignType?: string;
    objective?: string;
    objectiveType?: string;
    isSmartCampaign?: boolean;
  }

  export type TikTokCustomizedCampaignEntityCommonSummaryOutputDto = Omit<
    TikTokCampaignEntityCommonSummaryOutputDto,
    'budgetMode'
  >;

  export enum TikTokAdType {
    SINGLE_IMAGE = 'SingleImage',
    SINGLE_VIDEO = 'SingleVideo',
    LIVE_CONTENT = 'LiveContent'
  }

  export interface TikTokAdSummaryOutputDto extends TikTokCustomizedCampaignEntityCommonSummaryOutputDto {
    adFormat?: TikTokAdType;
    adName?: string;
    adText?: string;
    adTexts?: string;
    callToAction?: string;
    imageUrls?: string[];
    landingPageUrl?: string;
    profileImageUrl?: string;
    isAco: boolean;
  }

  export interface UpdateCampaign {
    budget?: number;
    budgetMode?: string;
    campaignName?: string;
    campaignId: string;
  }

  export interface UpdateCampaignStatus {
    campaignIds: string[];
    status: TikTokCampaignOperationStatusEnumType;
  }

  export enum TikTokCampaignOperationStatusEnumType {
    DELETE = 'DELETE',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE'
  }

  export enum TikTokIdResourceType {
    Campaign = 'Campaign',
    AdGroup = 'AdGroup',
    Ad = 'Ad',
    AcoAd = 'AcoAd'
  }

  export type TikTokTurnOnOffRequest = {
    resourceIds: string[];
    resourceType: TikTokIdResourceType;
  };

  export type UpdateAdGroup = {
    adGroupId: string;
    adGroupName?: string;
  };
}
