<ayn-modal-page #modalPage>
  <ng-template #body>
    <aayn-account-select-modal-content
      [modal]="modalPage.modalRef"
      [activeStep]="activeStep"
      [(query)]="query"
      [buttonDisabled]="completeDisabled"
      (buttonClick)="complete()"
      [headerLeftTemplate]="headerLeftTemplate"
      title="Bing Ad Account Select"
      description="Select the ad account of this business."
      icon="bing"
    >
      <aayn-account-ad-account-select
        *ngIf="activeStep === 0"
        [adAccounts]="(adAccounts$ | async) || []"
        (selectAdAccount)="selectAdAccount($event)"
        [platform]="platforms.Bing"
        [query]="query"
      ></aayn-account-ad-account-select
      >>
    </aayn-account-select-modal-content>

    <ng-template #headerLeftTemplate>
      <ayn-alert
        *ngIf="isCreationPossible"
        icon="credits"
        title="$150 Free Ad Credits!"
        description="Our partner, AdCreative.ai, swiftly generates conversion-focused ad creatives using AI."
        buttonText="Create a New Ad Account"
        (notificationButton)="showCreateNewAccountAccountModal()"
      >
      </ayn-alert>
    </ng-template>
  </ng-template>
</ayn-modal-page>
