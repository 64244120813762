import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-select-an-ad-type--header',
  templateUrl: './select-an-ad-type-header.component.html'
})
export class SelectAnAdTypeHeaderComponent implements OnInit {
  @Input() url?: string;
  @Input() classes = '';

  ngOnInit(): void {}
}
