import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Platforms } from '@core/models';
import { IntercomService } from '@core/services';
import { findAndRequestSubmit } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';
import { InstantAdValidationService } from '@pages/create-ad/state/platforms/instant-ad/instant-ad.validation';

@Component({
  selector: 'aayn-select-ai-generated',
  templateUrl: './select-ai-generated.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectAiGeneratedComponent implements OnInit {
  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  constructor(
    private instantAdValidationService: InstantAdValidationService,
    private el: ElementRef<HTMLElement>,
    private intercomService: IntercomService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);

    //todo: instant ads: intercom
  }

  nextStep(selectedPlatform: Platforms) {
    findAndRequestSubmit(this.el.nativeElement);

    if (this.nextStepValidator(selectedPlatform)) this.next.emit();
  }

  nextStepValidator(selectedPlatform: Platforms): boolean {
    switch (selectedPlatform) {
      case Platforms.InstantAd:
        return this.instantAdValidationService.selectAiGeneratedAdValid$.value;
      default:
        return false;
    }
  }
}
