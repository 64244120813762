import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/public-api';
import { removeItemByIndex, TiktokService } from '@core/index';
import { TikTok } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { LocationSelectionOverlayItemActiveType } from '@pages/create-ad/components/shared/manuel-audience-setup/components/location-selection/components/location-selection-overlay-item/location-selection-overlay-item.component';
import { TiktokStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-location-selection--tiktok',
  templateUrl: './location-selection.component.html'
})
export class AudienceLocationSelectionTiktokComponent implements OnInit {
  @Input() form?: FormGroup;

  @ViewChild(Overlay) overlay?: Overlay;

  protected locationSearchControl = new FormControl('', [Validators.minLength(1)]);

  protected geoLocations: TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto[] = [];

  protected adCreationModel = this.tiktokStateService.adCreationModel;

  @Select(LoaderState.getAny(['LocationSearch'])) loader$?: Observable<boolean>;

  get locations() {
    return this.form?.value?.locations || [];
  }

  constructor(
    private tiktokService: TiktokService,
    private cdr: ChangeDetectorRef,
    private tiktokStateService: TiktokStateService
  ) {}

  ngOnInit() {
    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.locationSearchControl.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((term) => {
      if (this.locationSearchControl.invalid) return;
      if (this.form?.value.locations?.length < 1) {
        this.locationSearchControl.setErrors({ inValid: true });
      }
      const objective = this.adCreationModel.campaign.objectiveType;

      this.tiktokService
        .getLocations$({ searchKey: term!, objective } as TikTok.CreateAd.Backend.LocationSearch)
        .subscribe((result) => {
          this.geoLocations = result || [];

          if (!this.overlay?.render) this.overlay?.show(null);

          this.cdr.detectChanges();
        });
    });
  }

  protected onIncludeClick(geoLocation: TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto) {
    if (this.checkItemClick(geoLocation)) return;
    this.form!.patchValue({
      locations: [...this.locations, geoLocation]
    });
  }

  protected checkItemClick(geoLocation: TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto) {
    return !!this.form?.value.locations?.some((x) => x.id == geoLocation.id);
  }

  protected isActive(geoLocation: TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto) {
    const hasAddedInclusions = this.form?.value?.locations?.some((o) => o.id === geoLocation.id);

    return {
      isActive: hasAddedInclusions,
      type: 'inclusions'
    } as LocationSelectionOverlayItemActiveType;
  }

  protected removeItem(index: number) {
    const locations = removeItemByIndex(this.locations, index);
    this.form!.patchValue({
      locations
    });
  }

  onIncludeRemove(geoLocation: TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto) {
    this.form?.patchValue({
      locations: this.locations.filter((x) => x.id !== geoLocation.id)
    });
  }
}
