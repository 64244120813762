import { SmartCampaign, SmartCampaignPlatform } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = SmartCampaign.Backend.SmartCampaignCallToActionResultDto;

const platformsObject = {
  [SmartCampaignPlatform.Meta]: `facebook { id, platformSpecificName }`,
  [SmartCampaignPlatform.LinkedIn]: `linkedIn { id, platformSpecificName }`,
  [SmartCampaignPlatform.Google]: `google { id, platformSpecificName }`,
  [SmartCampaignPlatform.TikTok]: `tiktok { id, platformSpecificName }`
};

export const callToActions = (selectedPlatforms: SmartCampaignPlatform[]) =>
  makeQuery<Output>(
    `
  query CallToActions {
    ayn {
      callToActions {
        failures {
          ${selectedPlatforms.map((o) => o).join(' ')}
        }
        statuses {
          ${selectedPlatforms.map((o) => o).join(' ')}
        }
        results {
          name
          ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
        }
      }
    }
  }
`,
    { resultPath: 'ayn.callToActions' }
  );
