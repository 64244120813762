import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TiktokService } from '@core/services/tiktok.service';
import { TikTok } from '@core/models/platforms/tiktok';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  TiktokFormService,
  TiktokStateService,
  TiktokValidationService
} from '@pages/create-ad/state/platforms/tiktok';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--tiktok',
  templateUrl: './conversion-my-website.component.html'
})
export class ConversionMyWebsiteTiktokComponent implements OnInit {
  protected adCreationModel = this.tiktokStateService.adCreationModel;

  protected pixels: TikTok.CreateAd.Backend.TikTokPixelSearchOutput[] = [];

  protected conversionForm = new FormGroup({
    pixelId: new FormControl(this.adCreationModel.campaign?.pixelId, [Validators.required]),
    pixelEventCode: new FormControl(this.adCreationModel.campaign.pixelEventCode, [Validators.required]),

    campaignName: new FormControl(this.adCreationModel?.campaign.campaignName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  get selectedPixel() {
    return this.pixels.find((p) => p.id === this.adCreationModel.campaign?.pixelId);
  }

  constructor(
    private tiktokStateService: TiktokStateService,
    private tiktokService: TiktokService,
    private tiktokValidationService: TiktokValidationService,
    private tiktokFormService: TiktokFormService
  ) {}

  ngOnInit() {
    this.tiktokFormService.registerForm(this.conversionForm);

    this.conversionForm.statusChanges.subscribe((status) => {
      this.tiktokValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
      this.conversionForm.patchValue({
        pixelEventCode: this.adCreationModel.campaign.pixelEventCode,
        pixelId: this.adCreationModel.campaign.pixelId,
        campaignName: this.adCreationModel.campaign.campaignName
      });
    });

    this.conversionForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.adCreationModel.campaign.pixelEventCode = value.pixelEventCode || '';
      this.adCreationModel.campaign.pixelId = value.pixelId || '';
      this.adCreationModel.campaign.campaignName = value.campaignName || '';
    });

    this.tiktokService.pixelSearch$().subscribe((pixels) => {
      this.pixels = pixels;
    });
  }
}
