import { makeQuery, QueryResult } from '@core/queries';
import { Linkedin } from '@core/models';

type Input = Linkedin.Dashboard.LinkedinSingleCampaignInputDto;
type Output = QueryResult<'campaignsByIds', Linkedin.Dashboard.LinkedinSingleCampaignOutputDto[]>;

export const campaignsByIds = makeQuery<Output, Input>(`
query LinkedinCampaignsByIds($ids: [Float!]) {
  linkedin {
    campaignsByIds(ids: $ids) {
      id
      status
      name
      budget
      budgetPeriod
      dateRange {
        start
        end
      }
      campaignGroup
      type
      costType
      optimizationTargetType
      objectiveType
      targetingCriteria
    }
  }
}
`);
