import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleCreateAd } from '@core/ad-platforms';
import { Google, PlatformStep } from '@core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleFormService, GoogleStateService, GoogleValidationService } from '@pages/create-ad/state';

import {
  CallExtensionsModalComponent,
  CalloutExtensionsModalComponent,
  ExtensionsYoutubeModalComponent,
  SitelinkExtensionsModalComponent
} from '../modal';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HEADLINES, truthy } from '@core/utils';

@UntilDestroy()
@Component({
  selector: 'aayn-search-ads--google',
  templateUrl: 'search-ads.component.html'
})
export class SearchAdsGoogleComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModelOf<Google.CreateAd.GoogleSearchCampaign>();

  protected form = new FormGroup({
    headlines: new FormArray([
      new FormControl(this.adCreationModel.ad.assets.headlines.at(0), [
        Validators.required,
        Validators.pattern(HEADLINES)
      ]),
      new FormControl(this.adCreationModel.ad.assets.headlines.at(1), [
        Validators.required,
        Validators.pattern(HEADLINES)
      ]),
      new FormControl(this.adCreationModel.ad.assets.headlines.at(2), [
        Validators.required,
        Validators.pattern(HEADLINES)
      ]),
      new FormControl(this.adCreationModel.ad.assets.headlines.at(3), [Validators.pattern(HEADLINES)]),
      new FormControl(this.adCreationModel.ad.assets.headlines.at(4), [Validators.pattern(HEADLINES)])
    ]),
    descriptions: new FormArray([
      new FormControl(this.adCreationModel.ad.assets.descriptions.at(0), [Validators.required]),
      new FormControl(this.adCreationModel.ad.assets.descriptions.at(1), [Validators.required]),
      new FormControl(this.adCreationModel.ad.assets.descriptions.at(3)),
      new FormControl(this.adCreationModel.ad.assets.descriptions.at(4))
    ]),
    businessName: new FormControl(this.adCreationModel.ad.assets.businessName, [Validators.required]),

    url: new FormControl(this.adCreationModel.campaign.url, [Validators.required])
  });

  constructor(
    private googleStateService: GoogleStateService,
    private googleFormService: GoogleFormService,
    private googleValidationService: GoogleValidationService,
    private modalService: NgbModal,
    public adPreviewService: GoogleAdPreviewService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.googleFormService.registerForm(this.form);
    this.listenModel();

    this.googleValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((o) => {
      this.adCreationModel.ad.assets.descriptions = o.descriptions?.filter(truthy) || [];
      this.adCreationModel.ad.assets.headlines = Google.Utils.replaceExclamationMark(o.headlines);
      this.adCreationModel.ad.assets.businessName = o.businessName!;

      this.adCreationModel.campaign.url = o.url!;
      this.adPreviewService.searchAds.headlines = this.adCreationModel.ad.assets.headlines;
    });
  }

  get headlinesArray() {
    return this.form.controls['headlines'] as FormArray;
  }

  get descriptionsArray() {
    return this.form.controls['descriptions'] as FormArray;
  }

  openExtensionsYoutubeModalComponent() {
    const modalRef = this.modalService.open(ExtensionsYoutubeModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openSitelinkExtensionsModal() {
    const modalRef = this.modalService.open(SitelinkExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openCalloutExtensionsModalComponent() {
    const modalRef = this.modalService.open(CalloutExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openCallExtensionsModalComponent() {
    const modalRef = this.modalService.open(CallExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  listenModel() {
    this.googleStateService.adCreationModel$
      .pipe(
        filter((model) => model.selectedTypes.ad === Google.AdType.SearchAds),
        untilDestroyed(this)
      )
      .subscribe((o) => {
        const model = o as unknown as GoogleCreateAd.ICreateAdModel<Google.CreateAd.GoogleSearchCampaign>;

        this.adCreationModel = model;
        this.adPreviewService.searchAds.headlines = model.ad.assets.headlines;

        this.form.patchValue({
          businessName: model.ad.assets.businessName,
          descriptions: model.ad.assets.descriptions,
          headlines: model.ad.assets.headlines,
          url: model.campaign.url
        });
      });
  }
}
