import { Google } from '@core/models';
import { makeMutation, QueryResult } from '@core/queries/types';

const queryField = 'turnOn';

type Input = Google.Dashboard.ITurnCampaignsRequest;
type Output = QueryResult<typeof queryField, boolean>;

export const turnOnCampaignsGoogle = makeMutation<Output, Input>(`
  mutation GoogleTurnOn(
    $resourceNames: [String!]!
    $resourceType: GoogleIdType!
  ) {
    google {
      ${queryField}(
        resourceNames: $resourceNames
        resourceType: $resourceType
      )
    }
  }
`);
