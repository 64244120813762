<section class="existing-post--container">
  <aayn-ad-type-sections class="existing-post--container--tab_sections">
    <aayn-ad-type-section icon="folder" header="Existing Post" description="Advertise using already published posts">
      <ayn-tab class="existing-post--tab">
        <ayn-tab-item tabTitle="Facebook" leftIcon="facebook" (tabClick)="onFacebookClick()">
          <aayn-existing-post--facebook-tab
            *ngIf="activeFBPlatform == FacebookPlatforms.Facebook"
          ></aayn-existing-post--facebook-tab>
        </ayn-tab-item>

        <ayn-tab-item tabTitle="Instagram" leftIcon="instagram" (tabClick)="onInstagramClick()">
          <aayn-existing-post--instagram-tab
            *ngIf="activeFBPlatform == FacebookPlatforms.Instagram"
          ></aayn-existing-post--instagram-tab>
        </ayn-tab-item>
      </ayn-tab>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</section>

<form class="existing-post--form" [formGroup]="form">
  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToAction"
            [placeholder]="'Call To Action' | translate"
            [showTick]="true"
            [showSearch]="false"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input type="text" placeholder="https://adyouneed.com" formControlName="url" ayn-input #urlInput />
        </ayn-form-field>
      </section>

      <ayn-form-field class="aayn-ad-type__mt-spacing">
        <input ayn-input type="text" [placeholder]="'Ad Name' | translate" formControlName="name" />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
