import { Component, OnInit, ViewChild } from '@angular/core';
import { Overlay } from 'apps/ayn-ui/src/public-api';
import { ILevelItem } from './components/level-item/level-item.component';

interface ILevel {
  name: string;
  selected?: boolean;
}

const LEVELS: ILevel[] = [
  { name: 'Monday' },
  { name: 'Tuesday', selected: true },
  { name: 'Wednesday', selected: true },
  { name: 'Thursday', selected: true },
  { name: 'Friday' },
  { name: 'Saturday' },
  { name: 'Sunday' }
];

@Component({
  selector: 'aayn-level',
  templateUrl: 'level.component.html'
})
export class LevelComponent implements OnInit {
  level = 10;

  levels = LEVELS;

  tasks: ILevelItem[] = [
    { level: 1, xp: 100, task: 'Onboarding Finished', isPassed: true },
    { level: 2, xp: 300, task: 'University Videos Watched', isActive: true },
    { level: 3, xp: 100, task: 'Dark Mode Activated' },
    { level: 4, xp: 100, task: 'First ad created' },
    { level: 5, xp: 100, task: 'Lorem ipsum Dolor' },
    { level: 5, xp: 1000, task: 'Suprise Gift', isGift: true },
    { level: 6, xp: 200, task: 'Subscription Started' },
    { level: 7, xp: 200, task: 'First Month Finished' },
    { level: 8, xp: 100, task: 'Share your Invitation Link' },
    { level: 9, xp: 200, task: 'Sub Account Invited' },
    { level: 10, xp: 200, task: '1 Sign-up from Friend' },
    { level: 10, xp: 2000, task: 'Suprise Gift', isGift: true },
    { level: 11, xp: 200, task: 'Finish a Perfect Week' },
    { level: 13, xp: 100, task: 'Switched to Yearly Package' }
  ];

  @ViewChild(Overlay) overlay!: Overlay;

  constructor() {}

  ngOnInit() {}
}
