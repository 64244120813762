import { makeMutation, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Output = QueryResult<'editPlatformCampaignBudgets', SmartCampaign.Backend.SmartCampaignPlatformBudgetOutputDto[]>;

export const editPlatformCampaignBudgets = makeMutation<
  Output,
  SmartCampaign.Backend.EditPlatformCampaignBudgetsInput
>(`
mutation EditPlatformCampaignBudgets(
  $smartCampaignId: String!
  $budgets: [SmartCampaignPlatformBudgetInputDto!]!
) {
  ayn {
    editPlatformCampaignBudgets(
      smartCampaignId: $smartCampaignId
      budgets: $budgets
    ) {
      budget
      platform
      success
    }
  }
}
`);
