<div class="existing-post--tab-row">
  <ayn-form-field class="existing-post--search-input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Existing post the filter' | translate"
      [(ngModel)]="term"
      [ngModelOptions]="{ standalone: true }"
    />

    <ayn-icon name="search"></ayn-icon>
  </ayn-form-field>

  <span class="-or-text">{{ 'or' | translate }}</span>

  <ayn-form-field class="existing-post--search-input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Enter POST ID Here' | translate"
      [(ngModel)]="postIdTerm"
      [ngModelOptions]="{ standalone: true }"
    />

    <ayn-icon name="search"></ayn-icon>
  </ayn-form-field>
</div>

<ng-container *ngIf="pagePosts.length; else noContent">
  <aayn-existing-post-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
    <aayn-existing-post-list-item
      *ngFor="let pagePost of pagePosts | aynFilterObj: { message: term, id: postIdTerm }"
      [message]="pagePost.message"
      [imageUrl]="pagePost.imageUrl"
      [shares]="pagePost.shares"
      [likes]="pagePost.likes"
      [comments]="pagePost.comments"
      [date]="pagePost.createdAt"
      [active]="pagePost.id == adCreationModel.ad.creative.pagePostId"
      (click)="onClickPagePost(pagePost)"
    ></aayn-existing-post-list-item>
  </aayn-existing-post-list>
</ng-container>

<ng-template #noContent>
  <aayn-empty-content
    [title]="'There is no data yet.' | translate"
    [description]="'There are no posts in your Meta account.' | translate"
    *ayn-loader="loader$ | async"
    class="d-block mb:5"
  ></aayn-empty-content>
</ng-template>
