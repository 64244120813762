import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderState } from '@core/state/loader.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'aayn-create-ad--draft-loader',
  template: `
    <span class="aayn-create-ad--item--loading" *ngIf="isLoading">
      Saving changes
      <img src="/assets/images/svgs/mini-loading.svg" class="aayn-create-ad--item--loading-img" />
    </span>

    <span
      class="aayn-create-ad--item--loading"
      [class.aayn-create-ad--item--loading_has-loaded]="hasLoaded"
      *ngIf="hasLoaded"
    >
      Automatically saved
      <ayn-icon name="checked-circle" class="aayn-create-ad--item--loading-img"></ayn-icon>
    </span>
  `
})
export class DraftLoaderComponent implements OnInit {
  protected isLoading = false;

  protected hasLoaded = false;

  @Select(LoaderState.getAny(['UpsertDraft'])) loader$?: Observable<boolean>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.cdr.detectChanges();

    this.loader$?.pipe(untilDestroyed(this)).subscribe({
      next: (result) => {
        if (this.isLoading && !result) {
          this.hasLoaded = true;
          this.cdr.detectChanges();

          setTimeout(() => {
            this.hasLoaded = false;

            this.cdr.detectChanges();
          }, 1500);
        }

        this.isLoading = result;

        this.cdr.detectChanges();
      }
    });
  }
}
