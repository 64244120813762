import { Component, Input, OnInit } from '@angular/core';
import { IntercomService, IntercomStateKeys } from '@core/index';

@Component({
  selector: 'aayn-need-help',
  template: `
    <a class="aayn-need-help" [style]="style" (click)="doAction()">
      {{ 'Need Help?' | translate }}
      <ayn-icon name="play" srcPrefix="/assets/images/svgs/"></ayn-icon>
    </a>
  `
})
export class NeedHelpComponent implements OnInit {
  @Input('style') style!: string;

  @Input() routerLink = '/main/university/';

  @Input() tourName?: IntercomStateKeys;

  constructor(private intercomService: IntercomService) {}

  ngOnInit() {}

  doAction() {
    if (this.tourName) this.intercomService.startTour(this.tourName, true);
  }
}
