import { Component, Input } from '@angular/core';
import { Facebook, Platforms } from '@core/models';
import { FacebookStateService } from '@pages/create-ad/state';
import { FacebookCreateAd } from '@core/ad-platforms';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';

export enum FacebookPlatforms {
  Facebook,
  Instagram
}

@Component({
  selector: 'aayn-existing-post-preview--facebook',
  templateUrl: './existing-post-preview.component.html'
})
export class ExistingPostPreviewComponent {
  @Input() businessPageDetail?: Facebook.IBusinessPageDetail;

  protected FacebookPlatforms = FacebookPlatforms;

  protected adCreationModel = this.facebookStateService.adCreationModel;

  Platforms = Platforms;

  constructor(private facebookStateService: FacebookStateService, public adPreviewService: AdPreviewService) {}

  get selectedPostIFrameUrl() {
    const pagePostId = this.selectedPagePostId;

    return `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FpageId%2Fposts%2F${pagePostId}%2F&width=410&show_text=true&height=650&appId`;
  }

  get selectedIgMediaId() {
    return (this.adCreationModel as unknown as FacebookCreateAd.ICreateAdModel<Facebook.AdExistingPostInstagram>).ad
      .creative.instagramMediaId;
  }

  get selectedIgMediaUrl() {
    return `https://www.instagram.com/p/${this.selectedIgMediaId}/embed`;
  }

  get selectedPagePostId() {
    const _pagePostId = (
      this.adCreationModel as unknown as FacebookCreateAd.ICreateAdModel<Facebook.AdExistingPostPage>
    ).ad.creative.pagePostId;

    const pagePostId = _pagePostId.split('_').at(-1) || '';

    return pagePostId;
  }
}
