import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-content',
  template: `
    <section class="ayn-layout--main-inner__content" [class]="className">
      <ng-content></ng-content>
    </section>
  `
})
export class ContentComponent implements OnInit {
  @Input() className!: string;

  constructor() {}

  ngOnInit() {}
}
