import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  TiktokFormService,
  TiktokStateService,
  TiktokValidationService
} from '@pages/create-ad/state/platforms/tiktok';

@UntilDestroy()
@Component({
  selector: 'aayn-traffic-to-website--tiktok',
  templateUrl: './traffic-to-website.component.html'
})
export class TrafficToWebsiteTiktokComponent implements OnInit {
  protected adCreationModel = this.tiktokStateService.adCreationModel;

  trafficWebsiteForm = new FormGroup({
    name: new FormControl(this.adCreationModel.campaign.campaignName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private tiktokStateService: TiktokStateService,
    private tiktokValidationService: TiktokValidationService,
    private tiktokFormService: TiktokFormService
  ) {}

  ngOnInit() {
    this.tiktokFormService.registerForm(this.trafficWebsiteForm);

    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.tiktokValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.trafficWebsiteForm.markAllAsTouched();
    });

    this.trafficWebsiteForm.statusChanges.subscribe((status) => {
      this.tiktokValidationService.objectiveValid$.next(status === 'VALID');
    });
  }
}
