<section class="aayn-sitelink-extensions-modal">
  <header class="aayn-sitelink-extensions-modal--header">
    <ayn-icon name="circle-check-list"></ayn-icon>
    <div class="aayn-sitelink-extensions-modal--header__content">
      <h4>{{ 'Sitelink Extensions' | translate }}</h4>
      <p>{{ 'Add additional links to your ad' | translate }}</p>
    </div>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <section>
    <form [formGroup]="sitelinkForm" (ngSubmit)="onSubmit()">
      <div class="aayn-sitelink-extensions-modal--form">
        <h5>{{ 'Sitelink 1' | translate }}</h5>

        <div class="aayn-sitelink-extensions-modal--row">
          <div class="aayn-sitelink-extensions-modal--row__item">
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="25">
              <input type="text" ayn-input formControlName="sitelinkText" [placeholder]="'Sitelink text' | translate" />
            </ayn-form-field>
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
              <input
                type="text"
                ayn-input
                formControlName="firstDescription"
                [placeholder]="'Description line 1 (recommended)' | translate"
              />
            </ayn-form-field>
          </div>

          <div class="aayn-sitelink-extensions-modal--row__item">
            <ayn-input-with-button class="aayn-ad-type--control" type="stretch">
              <label>{{ 'Final URL' | translate }}</label>
              <input type="text" formControlName="finalUrl" ayn-input #urlInput placeholder="https://adyouneed.com" />
            </ayn-input-with-button>
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
              <input
                type="text"
                ayn-input
                formControlName="secondDescription"
                [placeholder]="'Description line 2 (recommended)' | translate"
              />
            </ayn-form-field>
          </div>
        </div>

        <h5 class="mt:3">Sitelink 2</h5>
        <div class="aayn-sitelink-extensions-modal--row">
          <div class="aayn-sitelink-extensions-modal--row__item">
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="25">
              <input
                type="text"
                ayn-input
                formControlName="sitelinkText2"
                [placeholder]="'Sitelink text' | translate"
              />
            </ayn-form-field>
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
              <input
                type="text"
                formControlName="firstDescription2"
                ayn-input
                [placeholder]="'Description line 1 (recommended)' | translate"
              />
            </ayn-form-field>
          </div>
          <div class="aayn-sitelink-extensions-modal--row__item">
            <ayn-input-with-button class="aayn-ad-type--control" type="stretch">
              <label>{{ 'Final URL' | translate }}</label>
              <input type="text" formControlName="finalUrl2" ayn-input #urlInput placeholder="https://adyouneed.com" />
            </ayn-input-with-button>

            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
              <input
                type="text"
                formControlName="secondDescription2"
                ayn-input
                [placeholder]="'Description line 2 (recommended)' | translate"
              />
            </ayn-form-field>
          </div>
        </div>

        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="70">
          <input type="text" ayn-input formControlName="name" [placeholder]="'Extension name' | translate" />
        </ayn-form-field>
      </div>

      <button
        ayn-button="primary"
        class="aayn-sitelink-extensions-modal--button"
        ayn-button-flex
        type="submit"
        [disabled]="sitelinkForm.invalid"
      >
        {{ 'Save Sitelink Extensions' | translate }}
      </button>
    </form>
  </section>
</section>
