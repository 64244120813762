<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Import from Google' | translate }}</h4>
    <p class="aayn-select-objective--header__description">
      {{ 'Import your existing Google Campaigns to your Bing Ad Account.' | translate }}
    </p>
  </header>

  <form [formGroup]="googleImportForm">
    <aayn-ad-form-input label="Import type">
      <div class="aayn-select-objective--row">
        <ayn-radio
          name="importType"
          formControlName="importType"
          value="all"
          [binary]="true"
          [labelShow]="true"
          [label]="'Import all existing and new campaings' | translate"
          radioType="select"
          (click)="clearCampaignIds()"
        >
        </ayn-radio>

        <ayn-radio
          name="importType"
          value="specific"
          formControlName="importType"
          [binary]="true"
          [labelShow]="true"
          [label]="'Import specific campaigns and ad groups' | translate"
          radioType="select"
          (click)="googleImportModal()"
        >
        </ayn-radio>
      </div>
    </aayn-ad-form-input>

    <aayn-ad-form-input label="Microsoft Credential ID">
      <span label>
        {{ 'Your Microsoft Credentials ID can be found' | translate }}
        <a [href]="directionUrl" target="_blank"> {{ 'here' | translate }} </a>.
      </span>
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="credentialId"
          [placeholder]="'Microsoft Credential ID' | translate"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
    <div class="ayn-form-row"></div>
  </form>
</div>
