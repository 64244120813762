export class UploadImageItem {
  startedUploading = false;

  progressValue = 0;

  constructor(completed?: boolean) {
    if (completed) {
      this.complete();
    }
  }

  get completed() {
    return this.progressValue === 100;
  }

  get loading() {
    return this.startedUploading && !this.completed;
  }

  complete() {
    this.startedUploading = true;
    this.progressValue = 100;
  }

  reset() {
    this.startedUploading = false;
    this.progressValue = 0;
  }

  progress(value: number) {
    if (value) {
      this.startedUploading = true;
      this.progressValue = value;
    }
  }
}

export function createUploadImages(size = 10) {
  const items: UploadImageItem[] = [];

  for (let i = 0; i < size; i++) {
    items.push(new UploadImageItem());
  }

  return items;
}

export class UploadImageItemWithInfo extends UploadImageItem {
  fileUrl: string | undefined = '';
  id = '';

  constructor(completed?: boolean) {
    super(completed);
  }

  complete() {
    super.complete();
    this.fileUrl = '';
    this.id = '';
  }
}

export function createUploadImageItemWithInfos(size = 10) {
  const items: UploadImageItemWithInfo[] = [];

  for (let i = 0; i < size; i++) {
    items.push(new UploadImageItemWithInfo());
  }

  return items;
}
