import { makeQuery, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Output = QueryResult<'fetchPlatformBudgets', SmartCampaign.Backend.SmartCampaignPlatformBudgetDto[]>;
type Input = SmartCampaign.Backend.SmartCampaignId;

export const fetchPlatformBudgetsQuery = makeQuery<Output, Input>(`
query FetchPlatformBudgets($smartCampaignId: String!) {
  ayn {
    fetchPlatformBudgets(smartCampaignId: $smartCampaignId) {
      platformCampaignId
      budget
      platform
      currency
    }
  }
}
`);
