import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Platforms } from '@core/models';
import { IntercomService } from '@core/services';
import { findAndRequestSubmit } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import {
  BingStateService,
  BingValidationService,
  CreateAdState,
  FacebookStateService,
  FacebookValidationService,
  GoogleStateService,
  GoogleValidationService,
  TiktokStateService,
  TiktokValidationService
} from '@pages/create-ad/state';
import { LinkedinStateService, LinkedinValidationService } from '@pages/create-ad/state/platforms/linkedin';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';

@Component({
  selector: 'aayn-select-ad-type',
  templateUrl: './select-adtype.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectAdtypeComponent implements OnInit {
  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  constructor(
    private facebookValidationService: FacebookValidationService,
    private facebookStateService: FacebookStateService,
    private googleValidationService: GoogleValidationService,
    private bingValidationService: BingValidationService,
    private googleStateService: GoogleStateService,
    private bingStateService: BingStateService,
    private linkedinValidationService: LinkedinValidationService,
    private linkedinStateService: LinkedinStateService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private smartCampaignStateService: SmartCampaignStateService,
    private tikTokStateService: TiktokStateService,
    private tikTokValidationService: TiktokValidationService,
    private el: ElementRef<HTMLElement>,
    private intercomService: IntercomService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);

    if (selectedPlatform == Platforms.SmartCampaign) {
      this.intercomService.startTour('CREATE_AD_STEP_4_FOR_SMART_CAMPAIGN');
    } else {
      this.intercomService.startTour('CREATE_AD_STEP_4');
    }
  }

  nextStep(selectedPlatform: Platforms) {
    findAndRequestSubmit(this.el.nativeElement);

    if (this.nextStepValidator(selectedPlatform)) this.next.emit();
  }

  nextStepValidator(selectedPlatform: Platforms): boolean {
    switch (selectedPlatform) {
      case Platforms.Meta:
        return this.facebookValidationService.adTypeValid$.value && this.facebookStateService.createAd.isValidImages;

      case Platforms.Google:
        return this.googleValidationService.adTypeValid$.value && this.googleStateService.createAd.isValidImages;

      case Platforms.Bing:
        return this.bingValidationService.adTypeValid$.value && this.bingStateService.createAd.isValidImages;

      case Platforms.LinkedIn:
        return this.linkedinValidationService.adTypeValid$.value && this.linkedinStateService.createAd.isValidImages;

      case Platforms.SmartCampaign:
        return (
          this.smartCampaignValidationService.adTypeValid$.value &&
          this.smartCampaignStateService.createAd.isValidImages
        );

      case Platforms.TikTok:
        return this.tikTokValidationService.adTypeValid$.value && this.tikTokStateService.createAd.isValidImages;

      default:
        return false;
    }
  }
}
