<header class="aayn-cancel-subscription-modal--header">
  <ayn-icon name="avatar-cancel"></ayn-icon>

  <h3 class="aayn-cancel-subscription-modal--header--title">You are about to delete your account!</h3>

  <p class="aayn-cancel-subscription-modal--header--description">
    Ready to reactivate? Use the <b>“Activate Membership”</b> button below.
  </p>
</header>

<main class="aayn-cancel-subscription-modal--body">
  <div class="aayn-cancel-subscription-modal--body--card">
    <h4 class="aayn-cancel-subscription-modal--body--card-title">Ready to come back?</h4>
    <p class="aayn-cancel-subscription-modal--body--card-description">
      We’ve missed you and to show our appreciation, we’re extending a lifetime <b>discount of 50%</b>. Just click on
      <b>“Activate Membership”</b> below, and let us welcome you back with this special offer!
    </p>
  </div>

  <button
    class="aayn-cancel-subscription-modal--button"
    ayn-button="primary"
    icon="circle-filled-check"
    (click)="abortCancellation()"
  >
    Activate Membership
  </button>
</main>

<footer class="aayn-cancel-subscription-modal--footer">
  <a (click)="logout()">Log-out</a>
</footer>
