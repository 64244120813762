<aayn-upload-image-modal
  header="Select Images"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
  icon="folder"
>
  <ng-container *ngIf="logoConfig">
    <aayn-upload-image-modal--header
      [headerLeft]="'Logos'"
      [headerRight]="'Add up to 2 logos'"
    ></aayn-upload-image-modal--header>

    <section class="upload-ads-images--body">
      <aayn-file-upload-item
        [isPrimary]="true"
        [isError]="!!logoConfig.unValid"
        [aspectRatio]="logoConfig.common.aspectRatio"
        (fileAdded)="uploadImage(logoConfig, $event)"
        (remove)="remove(logoConfig.groupName, images[logoConfig.groupName][0].id)"
        (selectedFileUrlChange)="selectedFileChange(images[logoConfig.groupName][0].id)"
        [selectedFileUrl]="images[logoConfig.groupName][0].fileUrl"
        [progressItem]="images[logoConfig.groupName][0]"
        [minImageSize]="{
          width: logoConfig.common.dimensions.min.width,
          height: logoConfig.common.dimensions.min.height
        }"
        [maxImageSize]="{ width: logoConfig.common.dimensions.max.width }"
        [canvaDesignType]="'InstagramPost'"
        [aspectRatioLabel]="logoConfig.groupName"
        [cropperScalable]="true"
        imageOutput="png"
      ></aayn-file-upload-item>

      <aayn-file-upload-item
        *ngIf="landScapeLogo"
        [isPrimary]="false"
        [isError]="!!landScapeLogo.unValid"
        [aspectRatio]="landScapeLogo.common.aspectRatio"
        (fileAdded)="uploadImage(landScapeLogo, $event)"
        (remove)="remove(landScapeLogo.groupName, images[landScapeLogo.groupName][0].id)"
        (selectedFileUrlChange)="selectedFileChange(images[landScapeLogo.groupName][0].id)"
        [selectedFileUrl]="images[landScapeLogo.groupName][0].fileUrl"
        [progressItem]="images[landScapeLogo.groupName][0]"
        [minImageSize]="{
          width: landScapeLogo.common.dimensions.min.width,
          height: landScapeLogo.common.dimensions.min.height
        }"
        [maxImageSize]="{ width: landScapeLogo.common.dimensions.max.width }"
        [canvaDesignType]="'InstagramPost'"
        [aspectRatioLabel]="landScapeLogo.groupName"
        [cropperScalable]="true"
        imageOutput="png"
      ></aayn-file-upload-item>
    </section>
  </ng-container>

  <ng-container
    *ngFor="
      let config of imageConfig
        | aynFilterNotEqual: { groupName: groupNames.Logo }
        | aynFilterNotEqual: { groupName: groupNames.LogoLandscape }
    "
  >
    <aayn-upload-image-modal--header
      [headerLeft]="config.groupName"
      [headerRight]="'Add up to ' + config?.common?.quantity?.max + ' images'"
    ></aayn-upload-image-modal--header>

    <section class="upload-ads-images--body">
      <ng-container *ngFor="let file of images[config.groupName]; let index = index">
        <aayn-file-upload-item
          [isPrimary]="index < config.common.quantity.min"
          [isError]="index < config.common.quantity.min && !!config.unValid"
          [aspectRatio]="config.common.aspectRatio"
          (fileAdded)="uploadImage(config, $event)"
          (remove)="remove(config.groupName, file.id)"
          (selectedFileUrlChange)="selectedFileChange(file.id)"
          [selectedFileUrl]="file.fileUrl"
          [progressItem]="images[config.groupName][index]"
          [minImageSize]="{ width: config.common.dimensions.min.width, height: config.common.dimensions.min.height }"
          [maxImageSize]="{ width: config.common.dimensions.max.width }"
          [canvaDesignType]="'InstagramPost'"
          [aspectRatioLabel]="config.groupName"
          [canRemove]="index >= config.common.quantity.min && index !== 0"
          (removeEmptyClick)="removeEmptyFile(config.groupName, index)"
        ></aayn-file-upload-item>
        <aayn-file-upload-item
          *ngIf="
            images[config.groupName].length < config.common.quantity.max && index == images[config.groupName].length - 1
          "
          [aspectRatio]="config.common.aspectRatio"
          [isAddMore]="true"
          (addMoreClick)="addMoreImages(config.groupName)"
        ></aayn-file-upload-item>
      </ng-container>
    </section>
  </ng-container>
</aayn-upload-image-modal>
