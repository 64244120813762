import { Component, Input } from '@angular/core';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AdPreviewComponent } from '../../../ad-preview.component';
import { CarouselModule, IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-meta-single',
  templateUrl: './single.component.html',
  imports: [
    NgIf,
    NgTemplateOutlet,
    AdPreviewComponent,
    CarouselModule,
    NgForOf,
    PipesModule,
    TranslateModule,
    IconModule
  ],
  standalone: true
})
export class AdPreviewMetaSingleComponent {
  @Input() businessName = '';
  @Input() businessImage = '';
  @Input() headline = '';
  @Input() imageUrls: string[] = [];
  @Input() websiteUrl = '';
  @Input() shortHeadline = '';
  @Input() description = '';
  @Input() cta = '';

  headlinePlaceholder = 'Your Headline';
  shortHeadlinePlaceholder = 'Your Short Headline';
  descriptionPlaceholder = 'Your Description';
  urlPlaceholder = 'https://yourwebsiteurl';
  ctaPlaceholder = 'CTA';

  placeholder = '/assets/images/pngs/placeholder.png';

  onImgError(event) {
    event.target.src = this.placeholder;
  }
}
