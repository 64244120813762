import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-page-header',
  templateUrl: 'header.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
export class PageHeaderComponent implements OnInit {
  @Input() title = '';

  @Input() description = '';

  @HostBinding('style.margin-top.px')
  @Input('mt')
  marginTop: string | number = 0;

  @HostBinding('style.margin-bottom.px')
  @Input('mb')
  marginBottom: string | number = 0;

  @HostBinding('style.margin')
  @Input('m')
  margin: string = '0';

  constructor() {}

  ngOnInit() {}
}
