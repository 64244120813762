import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'aayn-file-upload-item--border',
  templateUrl: './border.svg'
})
export class FileUploadItemBorderComponent implements AfterViewInit, OnDestroy {
  @Input() parentElement!: HTMLElement;
  observer?: ResizeObserver;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.observer = new ResizeObserver(() => {
      this.cdr.detectChanges();
    });
    this.observer.observe(this.parentElement);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }
}
