import { makeMutation, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'updateAdGroup';
type Input = TikTok.Dashboard.UpdateAdGroup;
type Output = QueryResult<typeof queryField, boolean>;

export const updateAdGroupQuery = makeMutation<Output, Input>(`
mutation TikTokAdGroupUpdate($adGroupId: String!, $adGroupName: String!) {
  tiktok {
    ${queryField}(adGroupId: $adGroupId, adGroupName: $adGroupName) 
  }
}
`);
