import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { filterNotNil } from './filter-not-nil';

/**
 * Returns an observable that emits the result of applying a mapper function to the source value.
 * This occurs only when both the source value and the mapper function result value are not null or undefined.
 * @param mapper {(a: T) => Observable<R>} The mapper function.
 *
 * @example
 * const source = of(1, null, 2);
 * const example = source.pipe(switchMapDefined(x => x === 2 ? null : of(x * 2)));
 * example.subscribe(val => console.log(val));
 * // 2
 */
export function switchMapDefined<T, R>(mapper: (a: NonNullable<T>) => Observable<R>) {
  return function _switchMapDefined(source: Observable<T>): Observable<NonNullable<R>> {
    return (source as Observable<NonNullable<T>>).pipe(filterNotNil, switchMap(mapper), filterNotNil) as Observable<
      NonNullable<R>
    >;
  };
}

/**
 * Returns an observable that emits the result of applying a mapper function to the source value.
 * This occurs only when both the source value and the mapper function result are not null or undefined.
 * @param mapper {(a: T) => R} The mapper function.
 *
 * @example
 * const source = of(1, null, 2);
 * const example = source.pipe(mapDefined(x => x === 2 ? null : x * 2));
 * example.subscribe(val => console.log(val));
 * // 2
 */
export function mapDefined<T, R>(mapper: (a: T) => R) {
  return function _mapDefined(source: Observable<T>): Observable<NonNullable<R>> {
    return source.pipe(filterNotNil, map(mapper), filterNotNil) as Observable<NonNullable<R>>;
  };
}
