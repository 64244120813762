import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { GoogleStateService } from './google.state';

@Injectable({ providedIn: 'root' })
export class GoogleFormService extends FormListener {
  constructor(private googleStateService: GoogleStateService) {
    super();

    this.subscribeForms(() => {
      this.googleStateService.saveDraft$();
    });
  }
}
