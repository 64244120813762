import { Bing } from '@core/models/platforms/bing';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Business.IBingCreatedAdAccountRequest;
type Output = Bing.Business.IBingCreatedAdAccountReponse;

export const createAdAccountBing = makeMutation<Output, Input>(`
mutation CreateAdAccount(
  $adAccountName: String!
  $language: BingLanguage!
  $currencyCode: String!
  $businessAddress: BingBusinessAddress!
) {
  bing {
    createAdAccount(
      adAccountName: $adAccountName
      language: $language
      currencyCode: $currencyCode
      businessAddress: $businessAddress
    ) {
      adAccountId
      loginCustomerId
      directUrl
    }
  }
}
`);
