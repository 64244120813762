import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IAccountOverViewTotalRequest;
type Output = Google.Dashboard.IAccountOverViewTotalResponse;

export const accountOverviewTotalGoogle = makeMutation<Output, Input>(`
query GetAccountOverviewTotal(
  $startDate: SafeInt
  $endDate: SafeInt
  $datePreset: DatePreset
) {
  google {
    accountOverviewTotal(
      datePreset: $datePreset
      startDate: $startDate
      endDate: $endDate
    ) {
      costPerClick
      clicks
      cost
      conversions
      clickRate
      impressions
      cpm
      default
      pageView
      purchase
      purchase
      signup
      download
      addToCart
      beginCheckout
      subscribePaid
      phoneCallLead
      importedLead
      submitLeadForm
      bookAppointment
      requestQuote
      getDirections
      outboundClick
      contact
      engagement
      storeVisit
      storeSale
      qualifiedLead
      convertedLead
    }
  }
}

`);
