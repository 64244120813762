import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-audience-group',
  template: `
    <div class="aayn-audience--group">
      <ng-content select="aayn-audience-item"></ng-content>
    </div>
  `
})
export class AudienceGroupComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
