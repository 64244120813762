<li class="aayn-notifications--body-main-list-item" [class.aayn-notifications--body-main-list-item__new]="isNew">
  <img
    src="/assets/images/icons/{{ notification.platform }}.svg"
    [alt]="notification.platform.toLocaleUpperCase()"
    class="aayn-notifications--body-main-list-item__img"
  />

  <div class="aayn-notifications--body-main-list-item__body">
    <p class="aayn-notifications--body-main-list-item__body_content">
      {{ notification.text }}
    </p>

    <div class="aayn-notifications--body-main-list-item__body_footer">
      <p class="aayn-notifications--body-main-list-item__body_footer_date">{{ notification.date }}</p>

      <ayn-icon [name]="notification.icon || 'check'"></ayn-icon>
    </div>
  </div>
</li>
