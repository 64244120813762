<div
  cbCardField
  [icon]="true"
  id="card-field"
  [fonts]="fonts"
  [styles]="styles"
  locale="en"
  [classes]="classes"
  (ready)="onReady($event)"
  class="ayn-input--payment fieldset field"
>
  <div
    id="card-number"
    cbNumberField
    class="field empty"
    [placeholder]="subscription?.cardLast4 ? '**** **** **** ' + subscription?.cardLast4 : 'Card Number *'"
    (ready)="setFocus($event)"
    (change)="onChange($event)"
  ></div>

  <div id="card-expiry" cbExpiryField class="field empty" placeholder="MM / YY" (change)="onChange($event)"></div>

  <div id="card-cvv" cbCvvField class="field empty" placeholder="CVV" (change)="onChange($event)"></div>
</div>
