<form [formGroup]="form!" *ngIf="form">
  <aayn-ad-form-input label="LinkedIn" platform="linkedin">
    <ayn-form-field>
      <!-- TODO: Linkedin: Linkedin allowed selecting multiple events. Consider make multiple event select -->
      <ayn-select
        formControlName="conversionEvent"
        placeholder="Select a conversion event"
        [(ngModel)]="selectedConversionEvent"
        (ngModelChange)="onChangedSelectedConversionEvent($event)"
        class="aayn-select-objective--has-disabled-info"
      >
        <ayn-option
          *ngFor="let conversionEvent of conversionEvents | aynOptionFilter | async"
          [value]="conversionEvent"
        >
          {{ conversionEvent.name | aynTitleCase }}
        </ayn-option>
      </ayn-select>
    </ayn-form-field>
  </aayn-ad-form-input>
</form>
