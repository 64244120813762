import { Component, OnInit } from '@angular/core';
import { Facebook } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { selectedItem } from '@pages/create-ad/components/select-objective/utils/selected-item';
import { FacebookStateService } from '@pages/create-ad/state';

import { ICreateAdSelectItem } from '../../../../models/ad-create.model';
import { mapObjectives } from '../../utils/mapObjectives';

export const OBJECTIVE_LIST__FACEBOOK: ICreateAdSelectItem<Facebook.CampaignObjective>[] = [
  {
    id: 1,
    title: 'Traffic to my website',
    description: 'For receiving as many visitors as possible to your website.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: Facebook.CampaignObjective.OUTCOME_TRAFFIC
  },
  {
    id: 2,
    title: 'Conversions on my website',
    description: 'For receiving conversions on your website, must have Facebook Pixel installed.',
    icon: 'conversions',
    empty: false,
    active: false,
    type: Facebook.CampaignObjective.OUTCOME_LEADS
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  }
];

@UntilDestroy()
@Component({
  selector: 'aayn-select-objective--facebook',
  templateUrl: 'select-objective-content.component.html'
})
export class SelectObjectiveFacebookComponent implements OnInit {
  selectedObjective: Facebook.CampaignObjective = Facebook.CampaignObjective.OUTCOME_TRAFFIC;

  ObjectiveTypes = Facebook.CampaignObjective;

  objectives$ = this.facebookStateService.adCreationModel$.pipe(
    mapObjectives(OBJECTIVE_LIST__FACEBOOK, 'objective'),
    selectedItem((objective) => {
      this.selectedObjective = objective.type!;
    })
  );

  constructor(private facebookStateService: FacebookStateService) {}

  ngOnInit() {}

  objectiveChangeStatus(item: ICreateAdSelectItem<Facebook.CampaignObjective>) {
    if (this.facebookStateService.adCreationModel.campaign.objective !== item.type) {
      this.facebookStateService.setAdModel({
        ...this.facebookStateService.adCreationModel,
        campaign: { ...this.facebookStateService.adCreationModel.campaign, objective: item.type! }
      });
    }
  }
}
