import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { InstantAdCreateAd } from '@core/ad-platforms/instant-ad';
import { Draft, OpenAI, Platforms } from '@core/models';
import { CreateAnAdSteps } from '@pages/create-ad/models';

import { SaveDraft } from '../base';
import { OpenAiService } from '@core/services';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AdcService } from '@core/services/adc.service';
import { Store } from '@ngxs/store';
import { AdcImageEventService } from '@core/services/adc-image-event.service';
import { filterNotNil, switchTap } from '@core/utils';
import { AdCreative } from '@core/models/platforms/adcreative';
import { runIsDev } from '@shared/utils';
import { uniqueId } from 'lodash';

export const AdCreativeTemplateMap = [
  [4, 122, 50, 154, 25, 24, 121, 29],
  [123, 9, 72, 124, 164, 5, 136, 49],
  [103, 27, 139, 51, 120, 36, 155],
  [152, 140, 80, 127, 58, 157, 83]
];

export interface ImageOptions {
  backgroundImages: File[];
  brandImage: File;
  color1Hex: string;
  color2Hex: string;
}

@Injectable({ providedIn: 'root' })
export class InstantAdStateService extends SaveDraft<InstantAdCreateAd.ICreateAdModel> {
  createAd = new InstantAdCreateAd.CreateAd();

  adCreationModel$ = new BehaviorSubject(this.createAd.createAdModel);

  loader$ = new BehaviorSubject<boolean>(false);
  textLoader$ = new BehaviorSubject<boolean>(false);

  get adCreationModel() {
    return this.adCreationModel$.value;
  }

  constructor(
    private openAiService: OpenAiService,
    private adcService: AdcService,
    private store: Store,
    private adcImageEventService: AdcImageEventService
  ) {
    super();
  }

  setAdModel(value: InstantAdCreateAd.ICreateAdModel) {
    this.adCreationModel$.next(value);
    this.createAd.createAdModel = value;
  }

  reset() {
    this.createAd = new InstantAdCreateAd.CreateAd();
    this.setAdModel(this.createAd.createAdModel);
    this.draftModel = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;
  }

  updateActiveStep(step: CreateAnAdSteps) {
    this.adCreationModel.activeStep = step;
  }

  mapToApiRequest<P extends InstantAdCreateAd.InstantAdPlatforms = InstantAdCreateAd.InstantAdPlatforms>() {
    const selectedPlatform = this.adCreationModel.selectedPlatform;

    if (!selectedPlatform) return;

    return this.createAd.mapToApiRequest<P>(selectedPlatform as P);
  }

  generateAdTexts(
    generateTextOptions: Omit<OpenAI.GenerateTextsWithFrameworkInputDto, 'framework'>,
    imageOptions: ImageOptions
  ) {
    this.loader$.next(true);
    this.textLoader$.next(true);
    this.adCreationModel.ad.headlines = [];
    this.adCreationModel.ad.shortHeadlines = [];
    this.adCreationModel.ad.descriptions = [];
    this.adCreationModel.ad.primaryTexts = [];
    this.adCreationModel.ad.tasks = [];

    const frameworks = [
      'BAB Formula: Before-After-Bridge Style Ad Texts',
      'APP Formula: Agree-Promise-Preview Style Ad Texts',
      'AIDA Model: Attention, Interest, Desire, Action',
      'QUEST Method: Qualify, Understand, Educate, Stimulate, Transition'
    ];

    let completed = 0;

    const requests = frameworks.map((framework, index) => {
      const generateTextRequest = this.generateTexts(framework, generateTextOptions);
      return generateTextRequest.pipe(
        tap(() => {
          completed++;
          if (completed === frameworks.length) {
            this.textLoader$.next(false);
          }
        }),
        switchMap(({ response, id }) =>
          this.startImageGeneration(response, imageOptions, index).pipe(
            catchError((err) => {
              runIsDev(() => {
                console.log(`[InstantAdStateService] - Error while starting image generation: ${err.message}`);
              });
              return of(null);
            }),
            filterNotNil,
            map((imageGeneration) => ({ imageGeneration, id }))
          )
        ),
        switchMap(({ imageGeneration, id }) => this.assignTasksToAdCreationModel(imageGeneration, id))
      );
    });

    forkJoin(requests)
      .pipe(finalize(() => this.loader$.next(false)))
      .subscribe();
  }

  private generateTexts(
    framework: string,
    {
      targetAudience,
      description,
      outputLanguage,
      productName,
      tone,
      customRequests
    }: Omit<OpenAI.GenerateTextsWithFrameworkInputDto, 'framework'>
  ) {
    return this.openAiService
      .getFrameworkTexts({
        customRequests,
        description,
        framework,
        outputLanguage,
        productName,
        targetAudience,
        tone
      })
      .pipe(
        map((response) => {
          const data = response.advertisingHeadlines;
          const id = uniqueId('text_');
          const makeText = (text: string) => ({ text, id });
          if (this.adCreationModel.selectedPlatform === Platforms.Meta) {
            this.adCreationModel.ad.headlines.push(makeText(response.headlineUnder100Characters));
            this.adCreationModel.ad.shortHeadlines.push(makeText(data[0]));
            this.adCreationModel.ad.descriptions = [makeText(data[1])];
          }
          this.setAdModel(this.adCreationModel);
          return { response, id };
        })
      );
  }

  private startImageGeneration(
    texts: OpenAI.GenerateTextsWithFrameworkOutputDto,
    imageOptions: ImageOptions,
    index: number
  ) {
    const { backgroundImages, brandImage, color1Hex, color2Hex } = imageOptions;
    let images: File[];
    switch (backgroundImages.length) {
      case 4:
        images = backgroundImages;
        break;
      case 3:
        images = [...backgroundImages, backgroundImages[0]];
        break;
      case 2:
        images = backgroundImages.concat(backgroundImages);
        break;
      case 1:
        images = new Array(4).fill(backgroundImages[0]);
        break;
      default:
        images = [];
    }

    const templateMap: AdCreative.Backend.ImageTemplate[][] = AdCreativeTemplateMap.map((templateIndexes) =>
      templateIndexes.map(
        (index) => ({ type: 2, kind: AdCreative.Backend.RenderKind.Post, index } as AdCreative.Backend.ImageTemplate)
      )
    );

    return this.adcService
      .createImageGenerationTask({
        actionText: texts.ctaButtonTexts[0],
        backgroundImage: images[index],
        brandImage,
        color1Hex,
        color2Hex,
        mainHeadline: texts.headlineUnder100Characters,
        punchline: texts.advertisingPunchlines[0],
        description: texts.advertisingHeadlines[1],
        buttonIconClass: '',
        templates: templateMap[index]
      })
      .pipe(switchTap((imageGeneration) => this.adcImageEventService.addImageGenerationTask(imageGeneration)));
  }

  private assignTasksToAdCreationModel(imageGeneration: AdCreative.Client.ImageGenerationProgress, textId: string) {
    const taskIds = Object.keys(imageGeneration.tasks);
    this.adCreationModel.ad.tasks = this.adCreationModel.ad.tasks.concat(
      taskIds.map((taskId) => ({
        taskId,
        conversionScore: 0,
        textId
      }))
    );
    const saveDraft$ = new Observable((observer) => {
      this.saveDraft$(false, () => {
        this.setAdModel(this.adCreationModel);
        observer.next();
        observer.complete();
      });
    });
    return forkJoin([this.adcImageEventService.subscribeToEvent(imageGeneration), saveDraft$]);
  }
}
