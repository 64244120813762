<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  [adPreviewContainer]="adPreviewService.performanceMax"
  aaynTextAiContainer
>
  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    [description]="
      'Bing is recommending this size to be in this pixel range to get the best results possible.' | translate
    "
    [imageGroups]="uploadedImageUrls"
    [showRequiredAlert]="_form.submitted"
    (fileRemove)="removeFile($event)"
    adPreview="imageUrls"
    [standAlone]="true"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Long Headlines" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of longHeadlinesArray.controls; index as index"
        [formControl]="$any(longHeadlinesArray.controls[index])"
        [maxCharacterNumber]="90"
        [requiredMessage]="'You must write at least 3 long headline.' | translate"
        placeholder="Tell people what your ad is about"
        [ngModel]="adCreationModel.campaign.assets.longHeadlines[index]"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Headlines" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of headlinesArray.controls; index as index"
        [formControl]="$any(headlinesArray.controls[index])"
        [maxCharacterNumber]="30"
        [requiredMessage]="'You must write at least 3 headline.' | translate"
        [placeholder]="'Write a short headline' | translate"
        [ngModel]="adCreationModel.campaign.assets.headlines[index]"
        [previewActive]="index === 0"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Descriptions" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of descriptionsArray.controls; index as index"
        [formControl]="$any(descriptionsArray.controls[index])"
        [maxCharacterNumber]="90"
        [requiredMessage]="'You must write at least 3 description.' | translate"
        [placeholder]="'Write description' | translate"
        [ngModel]="adCreationModel.campaign.assets.descriptions[index]"
        adPreview="description"
        textAiSection="descriptions"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Business Name & Destination URL"
      description="Select the most appropriate business name and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="mr:4">
          <input
            type="text"
            formControlName="businessName"
            ayn-input
            [placeholder]="'Business Name' | translate"
            adPreview="businessName"
            [ngModel]="adCreationModel.campaign.assets.businessName"
            [standAlone]="true"
          />
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="text"
            placeholder="https://adyouneed.com"
            formControlName="url"
            adPreview="finalUrl"
            [standAlone]="true"
            ayn-input
            [ngModel]="adCreationModel.campaign.assets.finalUrl"
            #urlInput
          />
        </ayn-form-field>
      </section>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
