<aayn-ad-preview
  [businessName]="businessName"
  [profileImage]="businessImage"
  className="aayn-ad-preview-instagram-carousel"
>
  <ayn-carousel
    class="aayn-ad-preview-instagram-carousel_slide"
    [activeIndex]="activeIndex!"
    [itemsPerSlide]="1"
    (activeIndexChange)="activeIndexChange.emit($event)"
    previewType="ordered"
    [isDynamicHeight]="true"
  >
    <ng-template ayn-carousel-item *ngFor="let card of cards">
      <div class="aayn-ad-preview-instagram-carousel_slide-item">
        <div class="aayn-ad-preview-placeholder" *ngIf="!card.image?.[0]">
          <ayn-icon name="null-image-icon"></ayn-icon>
        </div>

        <img
          *ngIf="card.image?.[0]"
          class="aayn-ad-preview-instagram-carousel_slide-item__image"
          [src]="card.image?.[0]"
        />
        <p class="aayn-ad-preview-instagram-carousel_slide-item__content__cta">
          <span>{{ cta || (ctaPlaceholder | translate) }}</span>
          <ayn-icon name="chevron-right"></ayn-icon>
        </p>

        <section class="aayn-ad-preview-instagram-carousel_slide-item__content">
          <p class="aayn-ad-preview-instagram-carousel_slide-item__content__body">
            <span class="aayn-ad-preview-instagram-single_content__body-business_name">
              {{ businessName }}
            </span>
            {{ card.body || (bodyPlaceholder | translate) }}
          </p>
        </section>
      </div>
    </ng-template>
  </ayn-carousel>
</aayn-ad-preview>
