import { Bing } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = Bing.AudienceCampaign.BingGoogleCampaignImport;
type Output = { importGoogleCampaigns: boolean };
export const importGoogleCampaigns = makeMutation<Output, Input>(`
mutation ImportGoogleCampaigns($campaignImport: BingGoogleCampaignImport!) {
  bing {
    importGoogleCampaigns(campaignImport: $campaignImport)
  }
}

`);
