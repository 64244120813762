import { makeMutation } from '@core/queries';
import { Linkedin } from '@core/models';

type Input = Linkedin.AssociateCampaignWithConversionDto;

export const associateCampaignWithConversion = makeMutation<void, Input>(`
mutation AssociateCampaignWithConversion($campaign:String!, $conversion: String!) {
    linkedin{
        associateCampaignWithConversion(campaign: $campaign, conversion: $conversion)
    }
}
`);
