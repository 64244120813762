import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Platforms } from '@core/models';
import { Select } from '@ngxs/store';
import {
  BingStateService,
  CreateAdState,
  FacebookStateService,
  GoogleStateService,
  LinkedinStateService,
  SmartCampaignStateService,
  TiktokStateService
} from '@pages/create-ad/state';

import { OBJECTIVE_LIST__FACEBOOK, OBJECTIVE_LIST__GOOGLE } from '../../select-objective';
import { OBJECTIVE_LIST__BING } from '../../select-objective/platforms/bing';
import { OBJECTIVE_LIST__LINKEDIN } from '@pages/create-ad/components/select-objective/platforms/linkedin';
import { OBJECTIVE_LIST__TIKTOK } from '@pages/create-ad/components/select-objective/platforms/tiktok';
import { OBJECTIVE_LIST__SMART_CAMPAIGN } from '@pages/create-ad/components/select-objective/platforms/smart-campaign';

@Component({
  selector: 'aayn-select-an-objective-platforms--header',
  templateUrl: './select-an-objective-platforms-header.component.html'
})
export class SelectAnObjectivePlatformsHeaderComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  protected googleAdCreationModel = this.googleStateService.adCreationModel;

  protected facebookAdCreationModel = this.facebookStateService.adCreationModel;

  protected bingAdCreationModel = this.bingStateService.adCreationModel;

  constructor(
    private googleStateService: GoogleStateService,
    private facebookStateService: FacebookStateService,
    private bingStateService: BingStateService,
    private linkedInStateService: LinkedinStateService,
    private tiktokStateService: TiktokStateService,
    private smartCampaignStateService: SmartCampaignStateService
  ) {}

  get selectedObjective() {
    return OBJECTIVE_LIST__FACEBOOK.find((o) => o.type === this.facebookAdCreationModel.campaign.objective);
  }

  get selectedObjectiveBing() {
    return OBJECTIVE_LIST__BING.find((o) => o.type === this.bingAdCreationModel.selectedTypes.campaign);
  }

  get selectedObjectiveGoogle() {
    return OBJECTIVE_LIST__GOOGLE.find((o) => o.type === this.googleAdCreationModel.selectedTypes.campaign);
  }

  get selectedObjectiveLinkedin() {
    return OBJECTIVE_LIST__LINKEDIN.find(
      (o) => o.type === this.linkedInStateService.adCreationModel.selectedTypes.campaign
    );
  }

  get selectedObjectiveTiktok() {
    return OBJECTIVE_LIST__TIKTOK.find(
      (o) => o.type === this.tiktokStateService.adCreationModel.selectedTypes.campaign
    );
  }

  get selectedObjectiveSmartCampaign() {
    return OBJECTIVE_LIST__SMART_CAMPAIGN.find(
      (o) => o.type === this.smartCampaignStateService.adCreationModel.selectedTypes.campaign
    );
  }

  ngOnInit(): void {}
}
