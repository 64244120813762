import { makeQuery } from '../types';

type Input = {
  invoiceId: string;
};

type Output = {
  downloadUrl: string;
  validTill: number;
  mimeType: string;
};

export const invoiceDownloadInfo = makeQuery<Output, Input>(`
  query InvoiceDownloadInfo($invoiceId: String!) {
    invoiceDownloadInfo(
      invoiceId: $invoiceId
    ) {
      downloadUrl
      validTill
      mimeType
    }
  }
`);
