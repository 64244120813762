<div
  class="aayn-level-item"
  [ngClass]="{
    'aayn-level-item__active': task.isActive,
    'aayn-level-item__passed': task.isPassed,
    'aayn-level-item__gift': task.isGift
  }"
>
  <header class="aayn-level-item--header">
    <span class="aayn-level-item--name">
      Level {{ task.level }}
      <ayn-icon name="check"></ayn-icon>
    </span>

    <span class="aayn-level-item--xp__for-gift" *ngIf="task?.isGift">
      <ayn-icon name="gift"></ayn-icon>

      {{ task.xp }}xp
    </span>

    <span class="aayn-level-item--icon">
      <ayn-icon name="level"></ayn-icon>
      <span class="aayn-level-item--icon__point">{{ task.level }}</span>

      <p class="aayn-level-item--icon__xp">+{{ task.xp }}xp</p>
    </span>
  </header>

  <main class="aayn-level-item--main">
    <a href="javascript:void(0)" class="aayn-level-item--go-task" *ngIf="task?.isActive">
      Go to task <ayn-icon name="chevron-right"></ayn-icon>
    </a>

    <strong class="aayn-level-item--task">{{ task.task }}</strong>

    <img src="/assets/images/svgs/sparkles.svg" alt="Sparkles" class="aayn-level-item--gift-bg" *ngIf="task?.isGift" />
  </main>
</div>
