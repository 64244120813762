import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CreateAdStep, CreateAdSteps, InstantAdSteps } from './constants/steps';
import { isEqual } from 'lodash';

export class SetAdSteps {
  static readonly type = '@@CREATE_AD_SET_STEPS';
  static readonly desc = '[Create Ad.Set Steps]';

  constructor(public payload?: CreateAdStep[]) {}
}

export interface CreateAdStepsStateModel {
  steps: CreateAdStep[];
}

@State({
  name: 'CreateAdStepsState',
  defaults: {
    steps: InstantAdSteps
  }
})
@Injectable({ providedIn: 'root' })
export class CreateAdStepsState {
  @Selector()
  static steps(state: CreateAdStepsStateModel) {
    return state.steps.map((step) => {
      const stepConfig = Object.values(CreateAdSteps).find((config) => {
        return config.stepName === step.stepName;
      });
      return {
        ...stepConfig
      };
    });
  }
  constructor() {}

  @Action(SetAdSteps)
  setAdSteps(ctx: StateContext<CreateAdStepsStateModel>, { payload }: SetAdSteps) {
    const steps = ctx?.getState()?.steps;
    if (!steps) {
      ctx.patchState({ steps: payload });
      return;
    }
    if (payload?.length === steps.length && isEqual(payload, steps)) {
      return;
    }

    ctx.patchState({ steps: payload });
  }
}
