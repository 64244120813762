<section
  class="aayn-audience--detailed-targeting"
  [ngClass]="{ 'aayn-audience--detailed-targeting__active': overlay.render }"
>
  <aayn-detailed-targeting-added-item
    [addedItemType]="'inclusions'"
    *ngIf="_inclusions.length"
    (onClickRemove)="onCloseClickGroup()"
  >
    <ayn-badge
      color="primary"
      *ngFor="let includeDetailedTargeting of _inclusions; let index = index"
      [text]="includeDetailedTargeting.name"
      icon="cross-mini-bold"
      (click)="onCloseClick(includeDetailedTargeting)"
    >
      {{ includeDetailedTargeting.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <ayn-form-field class="aayn-audience--detailed-targeting--input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Add audience, demographics, interest or behaviours' | translate"
      (click)="overlay.show($event)"
      [formControl]="detailedTargetingSearchControl"
    />
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--detailed-targeting--overlay"
    styleClass="aayn-audience--detailed-targeting--overlay-inner"
  >
    <ayn-tab tab-type="ayn-tab--default">
      <ayn-tab-item [tabTitle]="'Interest' | translate" (tabClick)="changeActiveTab('interest')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of insterests"
            [type]="detailedTargeting.type | titlecase"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            [isExcludeShow]="false"
          >
            {{ detailedTargeting.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="'Your Data' | translate"
        (tabClick)="changeActiveTab('yourData')"
        *ngIf="yourDatas.length"
      >
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let item of yourDatas"
            [type]="item.type"
            (onIncludeClick)="onIncludeClick(item)"
            (onIncludeRemove)="onIncludeRemove(item)"
            [isExcludeShow]="false"
          >
            {{ item.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Custom Keywords' | translate" (tabClick)="changeActiveTab('custom')">
        <aayn-detailed-targeting-overlay-list>
          <aayn-detailed-targeting-overlay-item
            *ngFor="let item of customDataItems"
            [isIncludeShow]="false"
            [isExcludeShow]="false"
            [noLimitMaxWidth]="false"
            [inactive]="!detailedTargetingSearchControl.value?.length"
          >
            {{ 'People who searched on Google' | translate: { value: item.keyword } }}

            <div actions class="aayn-audience--detailed-targeting--li-actions">
              <button
                type="button"
                ayn-button="primary"
                icon="plus"
                class=":inclusion-btn"
                (click)="onClickExact(item)"
              >
                {{ 'Exact Match' | translate }}
              </button>
              <button type="button" ayn-button="dark" class=":inclusion-btn" icon="plus" (click)="onClickPhrase(item)">
                {{ 'Phrase Match' | translate }}
              </button>
              <button type="button" ayn-button="gray" class=":inclusion-btn" icon="plus" (click)="onClickBroad(item)">
                {{ 'Broad Match' | translate }}
              </button>
            </div>
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>
    </ayn-tab>
  </ayn-overlay>
</section>
