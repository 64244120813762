import { getFileExtension } from '@core/utils';

export function isImageFile(
  file: File,
  extensions: Array<'jpg' | 'jpeg' | 'png'> = ['jpg', 'jpeg', 'png'],
  lookUpType = true
) {
  const regexp = new RegExp(extensions.join('|'), 'gi');
  const fileExt = getFileExtension(file);
  return fileExt?.match(regexp) || (lookUpType && file.type.indexOf('image'));
}
