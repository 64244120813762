<ayn-modal-page #modalPage>
  <ng-template #body>
    <ng-container *ngIf="adAccounts$ | async as adAccounts">
      <aayn-account-select-modal-content
        [modal]="modalPage.modalRef"
        [activeStep]="activeStep"
        [(query)]="query"
        [buttonDisabled]="completeDisabled"
        (buttonClick)="complete()"
        [headerLeftTemplate]="headerLeftTemplate"
        icon="google"
      >
        <aayn-account-ad-account-select
          *ngIf="activeStep === 0"
          [adAccounts]="adAccounts"
          (selectAdAccount)="selectAdAccount($event)"
          [platform]="platforms.Google"
          [query]="query"
        ></aayn-account-ad-account-select>
      </aayn-account-select-modal-content>
    </ng-container>

    <ng-template #headerLeftTemplate>
      <ayn-alert
        icon="credits"
        *ngIf="isCreationPossible"
        title="$500 Free Ad Credits!"
        description="Our partner, AdCreative.ai, swiftly generates conversion-focused ad creatives using AI."
        buttonText="Create a New Ad Account"
        (notificationButton)="showCreateNewAccountAccountModal()"
      >
      </ayn-alert>
    </ng-template>
  </ng-template>
</ayn-modal-page>
