<aayn-video-modal [videoId]="videoId">
  <section class="aayn-video-modal--title">You have unpaid invoices!</section>
  <section class="aayn-video-modal--description">
    <p>To continue using ADYOUNEED, please clear pending payments by retrying or updating your payment method.</p>
    <p class="aayn-video-modal--hit-line">Let’s resolve this and create more awesome ads together!</p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary" icon="refresh">Try Again</button>
    <button ayn-button="accent" icon="credit-card">Update Payment Info</button>
  </section>
</aayn-video-modal>
