import { Clickfraud } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Clickfraud.IClickfraudProtectionStatusRequest;
type Output = string;

export const clickfraudProtectionStatus = makeQuery<Output, Input>(`
query ClickfraudProtectionStatus($source: Int!) {
  statusCFP(source: $source)
}
`);
