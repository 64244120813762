import { Google } from '@core/models';
import { QueryResult, makeQuery } from '@core/queries/types';

const queryField = 'isCreationPossible';

type Input = Google.IAdAccountRequest;
type Output = QueryResult<typeof queryField, boolean>;

export const isCreationPossibleGoogle = makeQuery<Output, Input>(`
query IsCreationPossibleGoogle($contextId: String!) {
  google {
    isCreationPossible(contextId: $contextId)
  }
}
`);
