import { Component, Input, OnInit } from '@angular/core';
import { ROUTES } from '@core/constants';
import { Select, Store } from '@ngxs/store';
import { CreateAdAllPlatformsService } from '@pages/create-ad/services/all-platforms.service';
import {
  SetInstantAdPlatform,
  SetPlatform,
  SetSelectedAdType,
  SetSmartCampaignPlatforms
} from '@pages/create-ad/state';
import { UserState } from '@core/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'aayn-sidenav',
  templateUrl: 'sidenav.component.html'
})
export class SidenavComponent implements OnInit {
  protected routes = ROUTES;

  @Input() hasAnyBusiness: boolean = false;
  @Input() hasAnyAdAccount: boolean = false;
  @Input() isInviterUser: boolean = false;

  @Select(UserState.getCompanyLogo) logo$!: Observable<string>;

  defaultLogo: string = '/assets/images/svgs/logo.svg';
  miniLogo: string = '/assets/images/svgs/logo-mini.svg';

  protected isMenuExpanded = false;

  constructor(private store: Store, private allPlatformsService: CreateAdAllPlatformsService) {}

  ngOnInit() {}

  protected resetCreateAdStore() {
    this.allPlatformsService.resetCreateAdState();
    this.allPlatformsService.resetCreateAdValidationState();

    this.store.dispatch([
      new SetPlatform(),
      new SetSmartCampaignPlatforms(),
      new SetSelectedAdType(),
      new SetInstantAdPlatform()
    ]);
  }

  protected toggleExpand() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }
}
