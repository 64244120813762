<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__filled':
      adCreationModel.campaign.criteria.target?.locationCriterionIds?.length ||
      adCreationModel.campaign.criteria.exclude?.locationCriterionIds?.length,
    'aayn-audience--location-selection__active': overlay?.render
  }"
>
  <aayn-location-selection-selected-items
    type="inclusions"
    *ngIf="adCreationModel.campaign.criteria.target?.locationCriterionIds?.length"
  >
    <aayn-location-selection-selected-item
      *ngFor="let include of adCreationModel.campaign.criteria.target?.locationCriterionIds; let index = index"
    >
      <ayn-badge
        color="primary"
        [text]="include.fullName"
        icon="map-marker-dot"
        (click)="removeItem(index, '_inclusions')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <aayn-location-selection-selected-items
    type="exclusions"
    *ngIf="adCreationModel.campaign.criteria.exclude?.locationCriterionIds?.length"
  >
    <aayn-location-selection-selected-item
      *ngFor="let exclude of adCreationModel.campaign.criteria.exclude?.locationCriterionIds; let index = index"
    >
      <ayn-badge
        color="orange"
        [text]="exclude.fullName"
        icon="map-marker-dot"
        (click)="removeItem(index, '_exclusions')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      ayn-input
      [required]="!adCreationModel.campaign.criteria.target?.locationCriterionIds?.length"
      [placeholder]="'Type the search' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />
    <ayn-icon name="map-marker"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length || geoLocations?.length"
  >
    <ng-container *ngIf="geoLocations?.length || (loader$ | async); else noResult">
      <aayn-location-selection-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
        <aayn-location-selection-overlay-item
          *ngFor="let geoLocation of geoLocations"
          [type]="geoLocation.type"
          (onIncludeClick)="!isActive(geoLocation)?.isActive ? onIncludeClick(geoLocation) : null"
          (onExcludeClick)="!isActive(geoLocation)?.isActive ? onExcludeClick(geoLocation) : null"
          (onIncludeRemove)="onIncludeRemove(geoLocation)"
          (onExcludeRemove)="onExcludeRemove(geoLocation)"
          [active]="isActive(geoLocation)"
        >
          {{ geoLocation.fullName }}
        </aayn-location-selection-overlay-item>
      </aayn-location-selection-overlay-list>
    </ng-container>
  </ayn-overlay>
</section>

<ng-template #noResult>
  <aayn-empty-content
    class="mt-3 mb-3"
    [showButton]="false"
    [title]="'No Records Found' | translate"
    [description]="'Please try again.' | translate"
  ></aayn-empty-content>
</ng-template>
