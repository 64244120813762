import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aayn-library-image',
  template: `
    <section
      class="aayn-library-image"
      [ngClass]="{ 'aayn-library-image__selected': selected }"
      (click)="onSelectImageClick.emit()"
    >
      <img ayn-skeleton-img [src]="url" />

      <div class="aayn-library-image:hover">
        <!-- TODO: Şu an silmek için endpint yok. Gelince açılacak! -->
        <!-- <ayn-icon name="trash"></ayn-icon> -->

        <ayn-icon name="check"></ayn-icon>
      </div>
      <div class="aayn-library-image__selected--icon" [hidden]="!selected">
        <ayn-icon name="check"></ayn-icon>
      </div>
    </section>
  `
})
export class LibraryImageComponent implements OnInit {
  @Input() url?: string;

  @Input() selected = false;

  @Input() selectImageText = 'Select Image';

  @Output() onSelectImageClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
