import { Component, OnInit, TemplateRef } from '@angular/core';

import { TooltipPosition } from './tooltip.enum';

@Component({
  selector: 'ayn-tooltip',
  templateUrl: './tooltip.html'
})
export class TooltipComponent implements OnInit {
  position: TooltipPosition = TooltipPosition.DEFAULT;

  classList: string[] = [];

  tooltip: string | TemplateRef<HTMLElement> | HTMLElement = '';

  left = 0;
  top = 0;

  style = '';

  visible = false;

  zIndex = 1000;

  constructor() {}

  ngOnInit(): void {}

  get isTooltipString() {
    return typeof this.tooltip === 'string';
  }

  get isTooltipTemplateRef() {
    return this.tooltip instanceof TemplateRef;
  }

  get isTooltipHtmlElement() {
    return this.tooltip instanceof HTMLElement;
  }

  get tooltipHtml() {
    return (this.tooltip as HTMLElement).innerHTML;
  }
}
