import { Linkedin } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Linkedin.CreateAd.SearchByFacetRequest;
type Output = Linkedin.CreateAd.SearchByFacetResponse;

export const searchByFacet = makeQuery<Output, Input>(`
query LinkedinSearchByFacet($facet: LinkedInFacetsByTypeahead!, $query: String!, $omitLocale: Boolean) {
    linkedin {
        searchByFacet(facet: $facet, query: $query, omitLocale: $omitLocale) {
            edges {
                cursor
                node {
                    name
                    urn
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                to
            }
        }
    }
}
`);
