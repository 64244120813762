import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ObjectUtils, Overlay } from '@ayn-ui/public-api';
import {
  Bing,
  Facebook,
  Google,
  Linkedin,
  PlatformIcons,
  PlatformKeyByValues,
  Platforms,
  SupportedSmartCampaignObject
} from '@core/index';
import { GoogleMapService } from '@core/services/google-map.service';
import { SmartCampaignService } from '@core/services/smart-campaign.service';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { BaseAsyncComponent } from '@shared/components/base';
import { ChildTabValidateFn, SmartResponseCatcherDirective, TabValidateStatus } from '@shared/directives';
import { FormRegisterService } from '@shared/directives/form-register.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-location-selection--smart-campaign',
  templateUrl: 'location-selection.component.html'
})
export class AudienceLocationSelectionSmartCampaignComponent extends BaseAsyncComponent implements OnInit {
  @Input() form?: FormGroup;

  @Input() adCreationModel = this.smartCampaignStateService.adCreationModel;

  @ViewChild(Overlay) overlay?: Overlay;

  protected locationSearchControl = new FormControl('', [Validators.minLength(1)]);

  @Select(LoaderState.getAny(['GetGeoLocations'])) loader$?: Observable<boolean>;

  protected locationPredictions: google.maps.places.AutocompleteResponse = { predictions: [] };

  protected Platforms = Platforms;

  protected PlatformKeyByValues = PlatformKeyByValues;
  protected PlatformIcons = PlatformIcons;

  protected getDetailLoader = false;

  protected getDetailLoaderIds: string[] = [];

  @ViewChild(SmartResponseCatcherDirective, { static: false })
  smartResponseCatcherDirective?: SmartResponseCatcherDirective;

  validateLocationSelection: ChildTabValidateFn = (tabItem) => {
    if (this.form && this.formRegisterService.isFormSubmitted(this.form)) {
      const platform = this.adCreationModel.selectedPlatforms[tabItem.index];
      if (!this.adCreationModel.audience.geoLocations.inclusions[platform]?.length) {
        return TabValidateStatus.Invalid;
      }
    }
    return undefined;
  };

  get isAnyPlatformNotSelected() {
    const inclusions = this.form?.value.geoLocations;
    return (
      ObjectUtils.isEmpty(inclusions) ||
      Object.values(inclusions).flat().length !== this.adCreationModel.selectedPlatforms.length
    );
  }

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private smartCampaignService: SmartCampaignService,
    private googleMapService: GoogleMapService,
    private cdr: ChangeDetectorRef,
    private formRegisterService: FormRegisterService
  ) {
    super();

    this.googleMapService.loadScript$().subscribe();
  }

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.locationSearchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (term) => {
      if (this.locationSearchControl.invalid || !term?.trim().length) return;

      this.setLoading(true);
      this.locationPredictions = await this.googleMapService.searchLocation$(term);
      this.setLoading(false);

      this.overlay?.show(null);

      this.cdr.detectChanges();
    });

    this.formRegisterService
      .onSubmit$(this.form)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.smartResponseCatcherDirective?.processTabs();
      });
  }

  private _mapGeoLocations() {
    this.form?.patchValue({
      geoLocations: this.adCreationModel.audience.geoLocations.inclusions
    });

    this.smartResponseCatcherDirective?.processTabs();
  }

  protected async onIncludeClick(locationPrediction: google.maps.places.QueryAutocompletePrediction) {
    const index = () => this.getDetailLoaderIds.findIndex((o) => o == locationPrediction.place_id);

    if (index() > -1) return;

    if (!locationPrediction.place_id) return;

    this.getDetailLoaderIds.push(locationPrediction.place_id);

    this.cdr.detectChanges();

    const addressComponents = await this.googleMapService.getLocationDetailAddressComponents$(
      locationPrediction.place_id
    );

    this.smartCampaignService
      .getSmartLocations$(this.adCreationModel.selectedPlatforms, {
        addressComponents
      })
      .subscribe((o) => {
        this.getDetailLoader = false;

        this.adCreationModel.selectedPlatforms.forEach((platform) => {
          const data = o.data!.results[SupportedSmartCampaignObject[platform]];

          if (!data) return;

          if (this.adCreationModel.audience.geoLocations.inclusions[platform]) {
            const hasExists = this._hasExistsLocation(platform, data);

            if (hasExists) return;

            this.adCreationModel.audience.geoLocations.inclusions[platform]!.push(data);
          } else this.adCreationModel.audience.geoLocations.inclusions[platform] = [data];
        });

        this.getDetailLoaderIds.splice(index(), 1);

        this._mapGeoLocations();

        this.overlay?.hide();

        this.cdr.detectChanges();
      });
  }

  private _hasExistsLocation(platform: Platforms, location: unknown) {
    let hasExists = false;

    switch (platform) {
      case Platforms.Meta:
        hasExists = !!this.adCreationModel.audience.geoLocations.inclusions[platform]?.some(
          (o) => o.key == (location as Facebook.GeoLocationItem).key
        );
        break;

      case Platforms.Google:
        hasExists = !!this.adCreationModel.audience.geoLocations.inclusions[platform]?.some(
          (o) => o.id == (location as Google.CreateAd.ILocationSearch).id
        );
        break;

      case Platforms.Bing:
        hasExists = !!this.adCreationModel.audience.geoLocations.inclusions[platform]?.some(
          (o) => o.id == (location as Bing.AudienceCampaign.BingLocationConstant).id
        );
        break;

      case Platforms.LinkedIn:
        hasExists = !!this.adCreationModel.audience.geoLocations.inclusions[platform]?.some(
          (o) => o.urn == (location as Linkedin.CreateAd.SearchByFacetEdgeNode).urn
        );
        break;

      default:
        break;
    }

    return hasExists;
  }

  showLocationPredictionLoader(location: google.maps.places.QueryAutocompletePrediction) {
    return this.getDetailLoaderIds.some((o) => o == location.place_id);
  }

  protected removeItem(index: number, arrName: 'inclusions' | 'exclusions') {
    this.adCreationModel.audience.geoLocations[arrName].splice(index, 1);

    this._mapGeoLocations();
  }
}
