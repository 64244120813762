import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Platforms } from '@core/models';
import { Store } from '@ngxs/store';
import {
  BingStateService,
  BingValidationService,
  CreateAdState,
  FacebookStateService,
  FacebookValidationService,
  GoogleStateService,
  GoogleValidationService,
  LinkedinStateService,
  LinkedinValidationService,
  TiktokStateService,
  TiktokValidationService
} from '@pages/create-ad/state';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';
import { InstantAdValidationService } from '@pages/create-ad/state/platforms/instant-ad/instant-ad.validation';

@Injectable({
  providedIn: 'root'
})
export class AdModelService {
  constructor(
    private store: Store,
    private bingStateService: BingStateService,
    private facebookStateService: FacebookStateService,
    private googleStateService: GoogleStateService,
    private linkedinStateService: LinkedinStateService,
    private smartCampaignStateService: SmartCampaignStateService,
    private tiktokStateService: TiktokStateService,
    private instantAdStateService: InstantAdStateService,
    private bingValidationService: BingValidationService,
    private facebookValidationService: FacebookValidationService,
    private googleValidationService: GoogleValidationService,
    private linkedinValidationService: LinkedinValidationService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private tiktokValidationService: TiktokValidationService,
    private instantAdValidationService: InstantAdValidationService
  ) {}

  getAdModel() {
    const stateService = this.getStateService();
    if (stateService) {
      return stateService?.adCreationModel;
    }
    return null;
  }

  getAdModel$() {
    return this.getStateService$().pipe(
      switchMap((stateService) => {
        if (stateService) {
          return stateService?.adCreationModel$ ?? of(null);
        }
        return of(null);
      })
    );
  }

  getStateService() {
    const platform = this.store.selectSnapshot(CreateAdState.selectedPlatform);
    return this.getStateServiceByPlatform(platform);
  }

  getStateService$() {
    return this.store
      .select(CreateAdState.selectedPlatform)
      .pipe(map((platform) => this.getStateServiceByPlatform(platform)));
  }

  getValidationService() {
    const platform = this.store.selectSnapshot(CreateAdState.selectedPlatform);
    return this.getValidationServiceByPlatform(platform);
  }

  getValidationService$() {
    return this.store
      .select(CreateAdState.selectedPlatform)
      .pipe(map((platform) => this.getValidationServiceByPlatform(platform)));
  }

  getStateServiceByPlatform(platform?: Platforms) {
    switch (platform) {
      case Platforms.Meta:
        return this.facebookStateService;
      case Platforms.Google:
        return this.googleStateService;
      case Platforms.LinkedIn:
        return this.linkedinStateService;
      case Platforms.Bing:
        return this.bingStateService;
      case Platforms.SmartCampaign:
        return this.smartCampaignStateService;
      case Platforms.TikTok:
        return this.tiktokStateService;
      case Platforms.InstantAd:
        return this.instantAdStateService;
      default:
        return null;
    }
  }

  getValidationServiceByPlatform(platform?: Platforms) {
    switch (platform) {
      case Platforms.Meta:
        return this.facebookValidationService;
      case Platforms.Google:
        return this.googleValidationService;
      case Platforms.LinkedIn:
        return this.linkedinValidationService;
      case Platforms.Bing:
        return this.bingValidationService;
      case Platforms.SmartCampaign:
        return this.smartCampaignValidationService;
      case Platforms.TikTok:
        return this.tiktokValidationService;
      case Platforms.InstantAd:
        return this.instantAdValidationService;
      default:
        return null;
    }
  }
}
