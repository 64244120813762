import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Facebook.Dashboard.IGetAdsetsOrAdRequest;
type Output = {
  adsets: {
    edges: {
      node: Facebook.Dashboard.IGetAdsetsResponse;
    }[];
  };
};

export const getAdsets = makeQuery<Output, Input>(`
  query GetFacebookAdsets(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $name: String
    $nodeId: String
    $datePreset: FacebookInsightDatePreset
    $dateRange: DateRangeInput
  ) {
    facebook {
      adsets (
        nodeId: $nodeId
        datePreset: $datePreset
        dateRange: $dateRange
        first: $first
        after: $after
        last: $last
        before: $before
        name: $name
        ) {
          edges {
            node {
            id
            status
            name
            startTime
            endTime
            dailyBudget
            lifeTimeBudget
            campaignId
            isDynamicCreative
            billingEvent
            bidStrategy
            optimizationGoal
            promotedObject {
              customEvenType 
            }
            recommendations {
              code
              title
              code
            }
            insights {
              costPerClick
              clicks
              averageCost1KImpressions
              averageCost1KReach
              spend
              impressions
              ctr
              reach
              startDate
              endDate
              gender
              age
              country
            }
          }
        }
      }
    }
}
`);
