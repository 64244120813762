import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MOBILE_TOP_ROUTES } from '@core/constants/routes';

@Component({
  selector: 'aayn-mobile-nav',
  templateUrl: './mobilenav.component.html'
})
export class MobilenavComponent implements OnInit {
  protected routes = MOBILE_TOP_ROUTES;

  @Input() hasAnyBusiness: boolean = false;
  @Input() hasAnyAdAccount: boolean = false;

  @Input() isMobileMenu: boolean = false;

  @Input() isInviterUser: boolean = false;

  @Output() mobileMenuClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {}

  openMobileMenu() {
    this.mobileMenuClick.emit(!this.isMobileMenu);
  }
}
