import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  adImages: Facebook.IGetAdImagesResponse;
};

type Input = {
  imageHashes?: string[];
};

export const getAdImages = makeQuery<Output, Input>(`
  query GetAdImages($imageHashes: [String!]) {
    facebook {
      adImages(imageHashes: $imageHashes) {
          edges{
            node{
              id
              url
              hash
              url128
              name
            }
          }
          pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
          }
        }
      }
    }
`);
