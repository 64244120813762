import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-location-selection-selected-items',
  template: `
    <div class="aayn-audience--location-selection--{{ type }}">
      <label class="aayn-audience--location-selection--{{ type }}--label">{{ type | aynTitleCase | translate }}</label>

      <ul class="aayn-audience--location-selection--{{ type }}--content">
        <ng-content select="aayn-location-selection-selected-item"></ng-content>
      </ul>
    </div>
  `
})
export class LocationSelectionsSelectedItemsComponent implements OnInit {
  @Input() type?: 'inclusions' | 'exclusions';

  constructor() {}

  ngOnInit() {}
}
