import { makeMutation } from '../types';
import { UserLoginResponse } from '@core/models';

type Input = {
  token: string;
  password: string;
  passwordAgain: string;
  invitationId: string;
};

type Output = UserLoginResponse;

export const setPassword = makeMutation<Output, Input>(`
mutation SetPassword(
  $token: String!
  $password: String!
  $passwordAgain: String!
  $invitationId: String!
) {
  setPassword(
    data: {
      token: $token
      password: $password
      passwordAgain: $passwordAgain
      invitationId: $invitationId
    }
  ) {
    user {
      email
      name
      surname
      inviter {
        inviterId
      }
    }
    accessToken
    refreshToken
  }
}
`);
