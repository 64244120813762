<aayn-ad-preview-card [platforms]="platforms" [(selectedPlatform)]="selectedPlatform">
  <aayn-ad-preview-meta-carousel
    *ngIf="selectedPlatform === Platforms.Meta"
    [businessName]="businessPageDetail?.name || ''"
    [businessImage]="businessPageDetail?.imageUrl || ''"
    [activeIndex]="(adPreviewService.carouselCreative.activeIndex$ | async) || 0"
    (activeIndexChange)="adPreviewService.carouselCreative.activeIndex$.next($event)"
    [cards]="adPreviewService.carouselCreative.cards!"
    [headline]="adPreviewService.carouselCreative.primaryText || ''"
    [cta]="adPreviewService.carouselCreative.cta || ''"
  >
  </aayn-ad-preview-meta-carousel>
  <aayn-ad-preview-instagram-carousel
    *ngIf="selectedPlatform === Platforms.Instagram"
    [businessName]="businessPageDetail?.name || ''"
    [businessImage]="businessPageDetail?.imageUrl || ''"
    [activeIndex]="(adPreviewService.carouselCreative.activeIndex$ | async) || 0"
    (activeIndexChange)="adPreviewService.carouselCreative.activeIndex$.next($event)"
    [cards]="adPreviewService.carouselCreative.cards!"
    [cta]="adPreviewService.carouselCreative.cta || ''"
  >
  </aayn-ad-preview-instagram-carousel>
</aayn-ad-preview-card>
