import { New } from '../';

/**
 * It is the decorator used to fill the `createAdModel` in the `CreateAd` class.
 * @param obj {T}
 * @returns {Function}
 */
export function UsesInitialization<T>(obj: T) {
  return function <C extends New>(constructor: C) {
    return class InitializedProxy extends constructor {
      createAdModel = structuredClone(obj);
    };
  };
}
