import { environment } from '@environment';
import { uniqueId } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { Component, Input, OnInit } from '@angular/core';
import { createObjectURL, createUploadImages, GoogleService, IdWithUrl, UploadImageItem } from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountImageItem, LibraryGoogleComponent } from '../image-library';
import { GoogleImageService } from '@pages/create-ad/services/google-image.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

export type DisplayAdsImageType = 'squareMarketing' | 'marketing' | 'logo' | 'squareLogo';
export const DISPLAY_ADS_IMAGE_RATIOS: Record<DisplayAdsImageType, number> = {
  squareMarketing: 1,
  marketing: 1.91,
  logo: 4,
  squareLogo: 1
};
export type DisplayAdsImages = {
  [key in DisplayAdsImageType]?: IdWithUrl[];
};

@Component({
  selector: 'aayn-upload-ads-images--google-display',
  templateUrl: 'upload-ads-images-display.component.html'
})
export class UploadAdsImagesGoogleDisplayComponent implements OnInit {
  @Input() protected uploadedImages: DisplayAdsImages = {
    squareMarketing: [],
    marketing: [],
    logo: [],
    squareLogo: []
  } as DisplayAdsImages;

  @Input() protected images = {
    squareMarketing: createUploadImages(5),
    marketing: createUploadImages(5),
    logo: createUploadImages(1),
    squareLogo: createUploadImages(1)
  };

  protected isValid: boolean = true;

  originalImages: DisplayAdsImages = {
    squareMarketing: [],
    marketing: [],
    logo: [],
    squareLogo: []
  } as DisplayAdsImages;

  constructor(
    public modal: NgbActiveModal,
    private googleService: GoogleService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private googleImageService: GoogleImageService
  ) {}

  ngOnInit() {
    Object.keys(this.uploadedImages).forEach((key) => {
      const uploadedImageGroup = this.uploadedImages[key];

      if (!uploadedImageGroup.length) return;
      this.images[key].slice(0, uploadedImageGroup.length).forEach((o) => {
        o.startedUploading = true;
        o.progressValue = 100;
      });
    });
  }

  openLibrary(imageType: DisplayAdsImageType, maxImageSelectLength: number) {
    const modalRef = this.modalService.open(LibraryGoogleComponent);

    modalRef.componentInstance.maxImageSelectLength = maxImageSelectLength;
    modalRef.componentInstance.ratio = DISPLAY_ADS_IMAGE_RATIOS[imageType];

    modalRef.closed.subscribe((result) => {
      const images = result.images as AccountImageItem[];

      if (images?.length) {
        this.images[imageType] = createUploadImages(maxImageSelectLength);
        this.uploadedImages[imageType] = [];

        images
          .filter((image) => image.id)
          .forEach((image) => {
            const imageIndex = this.images[imageType].findIndex((o) => !o.completed && !o.startedUploading);
            const url = this.googleImageService.getImageUrl(image.url);
            this.uploadedImages[imageType]![imageIndex] = { id: +image.id, url };
            this.originalImages[imageType]![imageIndex] = {
              id: +image.id,
              url
            };
            this.images[imageType]![imageIndex].complete();
          });

        // TODO:QUESTION: Image Library'den gelecek olan resimler daha önce yüklenmiş olan resimlere override etmeli mi? Şimdilik edecek!
        images
          .filter((image) => !image.id)
          .forEach(async (o) => {
            const downloadedImage = await fetch(o.url)
              .then((response) => response.blob())
              .then(
                (blob) =>
                  new File([blob], `${uniqueId()}$.png`, {
                    type: blob.type
                  })
              );

            this.uploadImage(imageType, { file: downloadedImage, fileUrl: o.url });
          });
      }
    });
  }

  uploadImage(type: DisplayAdsImageType, { file, fileUrl }: { file: File; fileUrl?: string }) {
    const image = this.images[type].find((o) => !o.completed && !o.startedUploading);
    this.googleService
      .uploadDisplayCampaignImages({
        [type]: [file]
      })
      .pipe(
        switchMap((uploadResult) => {
          if (uploadResult.state != 'DONE') return of({ uploadResult, imageUrl: null });

          return this.googleImageService.getImage(uploadResult.result[type][0].url).pipe(
            map((image) => ({
              uploadResult,
              imageUrl: createObjectURL(image)
            }))
          );
        })
      )
      .subscribe({
        next: ({ uploadResult, imageUrl }) => {
          image!.startedUploading = true;
          image!.progressValue = uploadResult.progress;

          if (!environment.production) console.log('[CreateAd.Google.UploadResult]: ', uploadResult);

          if (uploadResult.state != 'DONE') return;

          image!.progressValue = 100;

          uploadResult.result[type]?.forEach((o) => {
            const index = this.images[type].findIndex((o) => o === image);
            let url = imageUrl || this.googleImageService.getImageUrl(o.url) || fileUrl!;
            this.uploadedImages[type]![index] = { id: +o.id, url: url };
            this.originalImages[type]![index] = {
              id: +o.id,
              url: this.googleImageService.getImageUrl(o.url) || fileUrl!
            };
          });

          this.toastrService.success('Image upload successful.', 'Successful');
        },
        error: ({ error }: { error: IRestError }) => {
          if (!(error.message instanceof Array)) {
            this.toastrService.warning(error.message);

            image!.startedUploading = false;
            image!.progressValue = 0;
          }
        }
      });
  }

  remove({ fileUrl }: { fileUrl: string }, type: DisplayAdsImageType) {
    const index = (this.uploadedImages[type] || []).findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages[type] = this.uploadedImages[type]!.filter((_, i) => i !== index);
      this.images[type].forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images[type].length - 1 && this.images[type][i + 1].startedUploading) {
            this.images[type][i] = this.images[type][i + 1];
            this.images[type][i + 1] = new UploadImageItem();
          } else {
            this.images[type][i] = new UploadImageItem();
          }
        }
      });
    }
  }

  saveModal() {
    if (this.isUploadValid('squareMarketing') && this.isUploadValid('marketing')) {
      const images = Object.fromEntries(
        Object.entries<IdWithUrl[]>(this.uploadedImages).map(([key, value]) => [
          key,
          value
            .filter((o) => o.id)
            .map((o, index) => ({
              id: o.id,
              url: this.originalImages[key as DisplayAdsImageType]?.[index]?.url || o.url
            }))
        ])
      );
      this.modal.close({
        images
      });
    } else this.isValid = false;
  }

  isUploadValid(type: DisplayAdsImageType): boolean {
    return this.images[type]?.at(0)?.completed || this.uploadedImages[type]!.length > 0;
  }

  get imageInfo() {
    return {
      squareMarketing: {
        valid: this.isUploadValid('squareMarketing')
      },
      marketing: {
        valid: this.isUploadValid('marketing')
      },
      logo: {
        valid: this.isUploadValid('logo')
      },
      squareLogo: {
        valid: this.isUploadValid('squareLogo')
      }
    };
  }

  closeModal() {
    this.modal.close({});
  }

  selectedFileChange(index: number, type: DisplayAdsImageType) {
    this.uploadedImages[type]![index] = {
      id: 0,
      url: ''
    };
  }
}
