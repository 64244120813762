import { makeQuery } from '@core/queries/types';

type Input = { contextId: string };
type Output = {
  oauth: {
    oauthUrl: string;
  };
};

export const getOauthUri = makeQuery<Output, Input>(`
query GetOAuthUri($contextId: String!) {
  facebook {
    oauth(contextId: $contextId) {
      oauthUrl
    }
  }
}
`);
