<aayn-modal-for-payments
  title="Request Custom Offer"
  description="Get in touch with us to get a custom package designed for your needs."
  class="aayn-request-custom-offer"
>
  <form class="aayn-form" [formGroup]="form">
    <div class="aayn-form-row">
      <ayn-form-field class="aayn-form-col">
        <input type="text" ayn-input placeholder="Full Name" formControlName="fullName" />
      </ayn-form-field>

      <ayn-form-field class="aayn-form-col">
        <input type="email" ayn-input placeholder="Email" formControlName="email" />
      </ayn-form-field>
    </div>

    <div class="aayn-form-row">
      <ayn-form-field class="aayn-form-col">
        <input type="text" ayn-input placeholder="Company Name" formControlName="companyName" />
      </ayn-form-field>

      <ayn-form-field class="aayn-form-col">
        <input type="url" ayn-input placeholder="Website" formControlName="website" />
      </ayn-form-field>
    </div>

    <div class="aayn-form-row">
      <ayn-form-field class="aayn-form-col">
        <textarea
          rows="3"
          ayn-input
          placeholder="Tell us a bit about your needs"
          formControlName="description"
        ></textarea>
      </ayn-form-field>
    </div>

    <button ayn-button="primary" icon="circle-chevron-right" [disabled]="loader$ | async" [loading]="loader$ | async">
      Request a Quote
    </button>
  </form>
</aayn-modal-for-payments>
