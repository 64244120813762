import { environment } from '@environment';
import { pipe } from 'fp-ts/lib/function';
import { cloneDeep } from 'lodash';
import { modifyW, remove, rename } from 'spectacles-ts';

import { Bing } from '@core/models';

import {
  ApplyValidations,
  createAdBaseModel,
  CreateAdCommon,
  ICreateAdCommonValidate,
  UsesInitialization,
  WithMap
} from '../common';

export module BingCreateAd {
  export type AdRequest = {
    campaign: Bing.AudienceCampaign.AudienceCampaignRequestContent;
  };

  function createEmptyModel() {
    return {
      ...createAdBaseModel(
        Bing.CampaignObjective.TrafficToMyWebsite,
        Bing.AudienceType.Manual,
        Bing.AdType.PerformanceMax
      ),

      googleImport: {
        googleAdAccountId: 0,
        credentialId: '',
        importType: 'all',
        selectedCampaigns: [] as Bing.AudienceCampaign.BingMultipleCampaignOption[],
        increase: false,
        budgetPercentage: 0,
        syncFrequency: Bing.BingGoogleImportFrequency.Auto
      },

      campaign: {
        name: '',
        audienceName: '',
        dailyBudget: 0.05,
        paused: environment.name == 'dev' || environment.name == 'testing',
        goals: [] as Bing.AudienceCampaign.IBingConversionGoalOutput[],
        startDate: new Date().getTime(),
        endDate: undefined as number | undefined,
        assets: {
          businessName: '',
          finalUrl: '',
          descriptions: [''] as string[],
          headlines: [''] as string[],
          longHeadlines: [''] as string[],
          images: {
            landscapeImageMedia: [] as { id: number; url: string }[] | undefined,
            squareImageMedia: [] as { id: number; url: string }[] | undefined,
            imageMedia15X10: [] as { id: number; url: string }[] | undefined,
            imageMedia178X100: [] as { id: number; url: string }[] | undefined,
            imageMedia133X100: [] as { id: number; url: string }[] | undefined
          }
        },
        criteria: {
          narrowerTargetCriteria: [],
          minAge: 18,
          maxAge: 65,
          male: true,
          female: false,
          target: {
            locationCriterionIds: [] as Bing.AudienceCampaign.BingLocationConstant[],
            audienceCriterionIds: [] as Bing.AudienceCampaign.BingDetailTargetingCriterion[],
            profileDataCriteria: [] as Bing.AudienceCampaign.BingDetailTargetingCriterion[]
          },
          exclude: {
            locationCriterionIds: [] as Bing.AudienceCampaign.BingLocationConstant[],
            audienceCriterionIds: [] as Bing.AudienceCampaign.BingDetailTargetingCriterion[],
            profileDataCriteria: [] as Bing.AudienceCampaign.BingDetailTargetingCriterion[]
          }
        }
      }
    };
  }

  export type ICreateAdModel = ReturnType<typeof createEmptyModel>;

  const deepClonedModel = cloneDeep({ ...createEmptyModel() });

  @UsesInitialization(deepClonedModel)
  @WithMap({
    [Bing.AdType.PerformanceMax]: function (adCreationModel: ICreateAdModel) {
      return pipe(
        adCreationModel,
        cloneDeep,
        modifyW('campaign.goals.[]>', (o) => o.id),
        rename('campaign.goals', 'goalIds'),
        modifyW('campaign.criteria.target.locationCriterionIds.[]>', (o) => o.id),
        modifyW('campaign.criteria.target.audienceCriterionIds.[]>', (o) => o.id),
        modifyW('campaign.criteria.target.profileDataCriteria.[]>', (o) => ({ id: o.id, type: o.type })),
        modifyW('campaign.criteria.exclude.locationCriterionIds.[]>', (o) => o.id),
        modifyW('campaign.criteria.exclude.audienceCriterionIds.[]>', (o) => o.id),
        modifyW('campaign.criteria.exclude.profileDataCriteria.[]>', (o) => ({ id: o.id, type: o.type })),
        modifyW('campaign.assets.images.landscapeImageMedia?.[]>', (o) => o.id.toString()),
        modifyW('campaign.assets.images.squareImageMedia?.[]>', (o) => o.id.toString()),
        modifyW('campaign.assets.images.imageMedia15X10?.[]>', (o) => o.id.toString()),
        modifyW('campaign.assets.images.imageMedia178X100?.[]>', (o) => o.id.toString()),
        modifyW('campaign.assets.images.imageMedia133X100?.[]>', (o) => o.id.toString()),
        remove('campaign.audienceName'),
        (o) => {
          o.campaign.assets.descriptions = o.campaign.assets.descriptions.filter(Boolean);
          o.campaign.assets.headlines = o.campaign.assets.headlines.filter(Boolean);
          o.campaign.assets.longHeadlines = o.campaign.assets.longHeadlines.filter(Boolean);

          o.campaign.startDate = Math.floor(o.campaign.startDate / 1000);

          if (!o.campaign.endDate) {
            delete o.campaign.endDate;
          } else {
            o.campaign.endDate = Math.floor(o.campaign.endDate / 1000);
          }

          return o;
        }
      );
    }
  })
  @ApplyValidations({
    ad: {
      [Bing.AdType.PerformanceMax]: function (model: ICreateAdModel) {
        const images = model.campaign.assets.images;
        return !!images.landscapeImageMedia?.length;
      }
    }
  })
  export class CreateAd {
    get isValidImages(): boolean {
      return this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd extends CreateAdCommon<ICreateAdModel, AdRequest, Bing.AdType> {
    mapToApiRequest(type?: string | number): AdRequest;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
