import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FacebookCreateAd } from '@core/ad-platforms';
import { Facebook } from '@core/models';
import { FacebookService } from '@core/services';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-existing-post--instagram-tab',
  templateUrl: 'instagram-tab.component.html'
})
export class ExistingPostInstagramTabComponent implements OnInit {
  protected instagramIdTerm = '';

  protected instagramMedias: Facebook.IInstagramMedia[] = [];

  protected hasError = false;

  @Select(LoaderState.getAny(['GetInstagramMedias'])) loader$?: Observable<boolean>;

  protected adCreationModel = this.facebookStateService
    .adCreationModel as unknown as FacebookCreateAd.AdRequest<Facebook.AdExistingPostInstagram>;

  constructor(
    private facebookService: FacebookService,
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService
  ) {}

  ngOnInit() {
    this.facebookService.getInstagramMedias$({}).subscribe(
      (result) => {
        this.instagramMedias = result.data.instagramMedia.edges.map((o) => o.node);
      },
      () => {
        this.hasError = true;
      }
    );
  }

  onClickMI(igMedia: Facebook.IInstagramMedia) {
    this.adCreationModel.ad.creative.instagramMediaId = igMedia.id;

    this.facebookValidationService.adTypeValid$.next(true);
  }
}
