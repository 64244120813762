import { Linkedin } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Linkedin.CreateAd.ImageDetailRequest;
type Output = Linkedin.CreateAd.ImageDetailResponse;

export const getSingleImageDetail = makeMutation<Output, Input>(`
query GetSingleImageDetail($imageId: String!) {
    linkedin{
        getSingleImageDetail(imageId: $imageId){
            owner
            downloadUrlExpiresAt
            downloadUrl
            status
            id
        }
    }
}
`);
