import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule, IconModule, InputModule, SelectModule } from '@ayn-ui/lib/modules';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Platforms } from '@core/models';
import { aynGetFontEmbedCSS, toPng } from 'ayn-html-to-image';

export interface AdPreviewPlatform {
  platform: Platforms;
  icon: string;
  label: string;
}
export const AdPreviewMetaPlatform = {
  platform: Platforms.Meta,
  icon: 'meta',
  label: 'Meta'
};

export const AdPreviewInstagramPlatform = {
  platform: Platforms.Instagram,
  icon: 'instagram-flat',
  label: 'Instagram'
};

export const AdPreviewGooglePlatform = {
  platform: Platforms.Google,
  icon: 'google',
  label: 'Google'
};

export const AdPreviewLinkedInPlatform = {
  platform: Platforms.LinkedIn,
  icon: 'linkedin',
  label: 'LinkedIn'
};

export const AdPreviewBingPlatform = {
  platform: Platforms.Bing,
  icon: 'bing',
  label: 'Bing'
};

export const AdPreviewTikTokPlatform = {
  platform: Platforms.TikTok,
  icon: 'tiktok',
  label: 'TikTok'
};

@Component({
  selector: 'aayn-ad-preview-card',
  templateUrl: './ad-preview-card.component.html',
  animations: [fadeInOnEnterAnimation()],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    InputModule,
    AsyncPipe,
    SelectModule,
    IconModule,
    ButtonModule,
    NgIf,
    NgForOf,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdPreviewCardComponent {
  @Input() platforms?: AdPreviewPlatform[];
  @Input() className = '';

  @Input() selectedPlatform?: Platforms = Platforms.Meta;
  @Output() selectedPlatformChange = new EventEmitter<Platforms>();

  constructor(private elRef: ElementRef<HTMLElement>) {}

  selectedPlatformChanged(platform: Platforms) {
    this.selectedPlatformChange.emit(platform);
  }

  async toPng() {
    const css = await aynGetFontEmbedCSS(
      this.elRef.nativeElement,
      {},
      {
        styleFilter: (cssRule) => cssRule.href === null || cssRule.href.includes(window.location.host)
      }
    );
    if (css.includes('SF Pro Display')) {
      const imageData = await toPng(this.elRef.nativeElement, {
        skipFonts: true,
        fontEmbedCSS: css,
        backgroundColor: '#eef2fc',
        quality: 1,
        filter: (node) => !node.classList?.contains('aayn-ad-preview--card-footer')
      });

      const link = document.createElement('a');
      link.download = 'ad-preview.png';
      link.href = imageData;
      link.click();
    }
  }
}
