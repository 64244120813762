import { uniqueId } from 'lodash';
import { OutputFormat } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UploadImageItem } from '@core/models';
import { ICanvaOnDesignPublishOptions } from '@core/services/canva.service';
import { createObjectURL, getFileExtension, getFileWidthAndHeight, getVideoCover } from '@core/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspectRatio } from '@shared/models';
import { parseAspectRatio } from '@shared/utils';

import { ImageCropComponent } from '../modals/image-crop/image-crop.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

//TODO: Linkedin: Canvada linkedin ile alakalı sadece video post oluşturma var. Burası boş geçilebilir.
export type CanvaDesignType = 'InstagramPost' | 'InstagramPostPortrait' | 'FacebookAd' | 'Logo';

export interface IFileAddedEvent {
  file: File;
  fileUrl?: string;
}

export interface IFileRemoveEvent {
  fileUrl: string;
}

declare var CanvaApi: any;

@UntilDestroy()
@Component({
  selector: 'aayn-file-upload-item',
  templateUrl: './file-upload-item.component.html'
})
export class FileUploadItemComponent implements OnInit, OnChanges {
  @Input() isPrimary = false;

  @Input() isError = false;

  @Input() isDynamicRatio = false;

  @Input() disabled = false;

  @Input() aspectRatio?: AspectRatio.Ratios | null = '1:1';

  @Input() aspectRatios?: AspectRatio.AspectRatioItem[];

  @Input() minImageSize?: { width: number; height: number };
  @Input() maxImageSize?: { width: number; height?: number };

  @Input() openCropper = true;

  @Input() aspectRatioLabel: string = '';

  @Input() maintainAspectRatio = true;

  @Input() maxTotalPixels = 0;

  @Input() selectedFileUrl?: string;

  @Input() progressItem?: UploadImageItem;

  @Input() canvaDesignType: CanvaDesignType = 'InstagramPost';

  @Input() canvaIconDirection: 'left' | 'right' = 'left';

  @Input() className = '';

  @Input() canvaVisible = true;

  @Input() onRemoveDeleteComponentVariables = false;

  @Input() isSingle = false;

  @Input() isAddMore = false;
  @Input() canRemove = false;
  @Input() canRemoveImage = true;
  @Input() cropperScalable = false;
  @Input() imageOutput?: OutputFormat;

  @Input() allowVideo = false;

  @Output() fileAdded = new EventEmitter<IFileAddedEvent>();

  @Output() remove = new EventEmitter<IFileRemoveEvent>();

  @Output() selectedFileUrlChange = new EventEmitter<string>();
  @Output() ratioSelect: EventEmitter<AspectRatio.AspectRatioItem> = new EventEmitter();

  @Output() addMoreClick = new EventEmitter();

  @Output() removeEmptyClick = new EventEmitter();
  @Output() cropperOpen = new EventEmitter<ImageCropComponent>();

  cropperTakeUntil$ = new Subject();

  protected componentInstance?: ImageCropComponent;

  private lastOpenedCanvaDesignId?: string;

  protected id = `__file-upload_${(Math.random() * 10 * Date.now()).toString(32)}`;

  public selectedFile?: File | null;

  fileModel: any;

  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  async ngOnInit() {}

  ngOnChanges({ selectedFileUrl, maxImageSize, minImageSize }: SimpleChanges): void {
    if (selectedFileUrl) {
      if (selectedFileUrl.currentValue) {
        this.progressItem = new UploadImageItem(true);
        this.selectedFile ??= {} as File;
      } else {
        this.selectedFile = null;
        this.fileModel = null;
      }
    }
    if (maxImageSize && this.componentInstance) {
      this.componentInstance.maxImageSize = maxImageSize.currentValue;
    }

    if (minImageSize && this.componentInstance) {
      this.componentInstance.minImageSize = minImageSize.currentValue;
    }
  }

  async inputChange(event: Event | FileList) {
    this.cropperTakeUntil$.next();
    this.cropperTakeUntil$ = new Subject();
    this.isError = false;
    let files: FileList | null;
    if (event instanceof FileList) files = event;
    else {
      files = (event.target as HTMLInputElement).files;
    }

    if (files?.length) {
      this.selectedFile = files.item(0);

      if (!this.selectedFile) {
        this.fileModel = null;
        return;
      }

      if (this.allowVideo && this.selectedFile.type.indexOf('video') > -1) {
        // File Size Control less than 2gb
        if (this.selectedFile.size > 2_000_000_000) {
          this.selectedFile = null;
          this.fileModel = null;
          this.toastrService.error(`Video size is too large. Please upload a video under 2gb.`);

          return;
        }

        const videoCover = await getVideoCover(this.selectedFile, 0);

        if (!videoCover) return;

        const thumbnail = createObjectURL(videoCover);

        this.selectedFileUrl = thumbnail;

        this.fileAdded.emit({ file: this.selectedFile, fileUrl: thumbnail });

        return;
      }

      if (!this._controlFileExtension()) {
        this.selectedFile = null;
        this.fileModel = null;
        this.toastrService.error('File extension not supported. Please upload jpeg,jpg,png or svg file');
        return;
      }

      const isBigger = await this._controlFileWidthAndHeight();
      if (!isBigger) {
        this.selectedFile = null;
        this.fileModel = null;
        this.toastrService.error(
          `File size is too small. Please upload minimum ${this.minImageSize?.width}x${this.minImageSize?.height} px file`
        );

        return;
      }

      this.cdr.detectChanges();
      const fileUrl = createObjectURL(this.selectedFile!);

      if (this.openCropper) {
        const modalRef = this.modalService.open(ImageCropComponent, { backdrop: 'static' });
        this.componentInstance = (modalRef as { componentInstance: ImageCropComponent }).componentInstance;

        this.componentInstance.maintainAspectRatio = this.maintainAspectRatio;
        this.componentInstance.maxTotalPixels = this.maxTotalPixels;

        if (this.minImageSize) {
          this.componentInstance.minImageSize = this.minImageSize;
        }
        if (this.maxImageSize) {
          this.componentInstance.maxImageSize = this.maxImageSize;
        }

        if (this.aspectRatios) {
          this.componentInstance.ratios = this.aspectRatios;
          this.componentInstance.ratioSelect$
            .pipe(takeUntil(this.cropperTakeUntil$))
            .subscribe((v) => this.ratioSelect.emit(v));
          this.componentInstance.aspectRatio = null;
        } else if (this.aspectRatio) {
          this.componentInstance.aspectRatio = parseAspectRatio(this.aspectRatio);
        } else {
          this.componentInstance.aspectRatio = null;
        }

        this.componentInstance.aspectRatioLabel = this.aspectRatioLabel;

        const fileExtension = this.selectedFile.type.split('/');

        this.componentInstance.format = this.imageOutput || (fileExtension[fileExtension.length - 1] as OutputFormat);

        this.componentInstance.isScalable = this.cropperScalable;

        this.componentInstance.imageUrl = fileUrl;
        this.componentInstance.fileName = this.selectedFile.name;

        this.cropperOpen.emit(this.componentInstance);

        modalRef.closed.subscribe((file: File) => {
          this.cropperTakeUntil$.next();
          if (!file) {
            this.selectedFile = null;
            this.fileModel = null;

            return;
          }

          const croppedFileUrl = createObjectURL(file);

          this.selectedFileUrl = croppedFileUrl;
          this.selectedFileUrlChange.emit(croppedFileUrl);

          this.fileAdded.emit({ file, fileUrl: croppedFileUrl });
        });
      } else {
        this.selectedFileUrl = fileUrl;
        this.selectedFileUrlChange.emit(fileUrl);

        this.fileAdded.emit({ file: this.selectedFile, fileUrl });
      }
    }
  }

  private _controlFileExtension() {
    const extension = getFileExtension(this.selectedFile!);

    return extension?.match(/jpeg|jpg|png|svg/g);
  }

  private async _controlFileWidthAndHeight() {
    if (!this.minImageSize?.width && !this.minImageSize?.height) {
      return true;
    }
    const { width, height } = await getFileWidthAndHeight(this.selectedFile!);
    return this.minImageSize.width <= width && this.minImageSize.height <= height;
  }

  removeSelectedFile() {
    if (this.onRemoveDeleteComponentVariables) {
      this.selectedFile = null;
      this.selectedFileUrl = undefined;
      this.fileModel = null;
      this.selectedFileUrlChange.emit(undefined);

      this.progressItem!.progressValue = 0;
      this.progressItem!.startedUploading = false;
    }

    this.remove.emit({ fileUrl: this.selectedFileUrl! });
  }

  openCanva() {
    const onDesignPublish = async (options: ICanvaOnDesignPublishOptions) => {
      const exportUrl = options.exportUrl;

      fetch(exportUrl)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new File([blob], `${uniqueId()}${options.designId}.png`, {
              type: blob.type
            })
        )
        .then((file) => {
          const files = makeFileList([file]);

          this.zone.run(() => {
            this.inputChange(files);
          });
        });
    };

    if (this.lastOpenedCanvaDesignId) {
      CanvaApi.editDesign.bind(this)({
        design: {
          id: this.lastOpenedCanvaDesignId
        },
        onDesignPublish
      });

      return;
    }

    CanvaApi.createDesign.bind(this)({
      editor: {
        publishLabel: 'Send to ADYOUNEED'
      },
      design: {
        type: this.canvaDesignType
      },
      onDesignPublish,
      onDesignOpen: (opts: { designId: string }) => {
        this.lastOpenedCanvaDesignId = opts?.designId;
      }
    });
  }
}

export const makeFileList = (files: File[]) => {
  const reducer = (dataTransfer, file) => {
    dataTransfer.items.add(file);
    return dataTransfer;
  };

  return files.reduce(reducer, new DataTransfer()).files;
};
