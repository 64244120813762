import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.IBingEditAdGroupRequest;
type Output = { editAdGroup: boolean };

export const editAdGroupBing = makeMutation<Output, Input>(`
mutation editAdGroupBing(
  $campaignId: Float!
  $editableAdGroups: [BingEditableAdGroup!]!
) {
  bing {
    editAdGroup(campaignId: $campaignId, editableAdGroups: $editableAdGroups)
  }
}

`);
