import { distinctUntilChanged, map, take } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { IdWithUrl, PlatformStep, TikTok } from '@core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TiktokAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/tiktok/components/ad-preview/ad-preview.service';
import { TiktokStateService, TiktokValidationService } from '@pages/create-ad/state';
import { TiktokFormService } from '@pages/create-ad/state/platforms/tiktok/tiktok.form';

import { UploadDynamicCreativeAdsImagesTiktokComponent } from '../modal/upload-dynamic-creative-ads-images/upload-dynamic-creative-ads-images.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CanvaService } from '@core/services/canva.service';
import { TiktokService } from '@core/services';
import { FileUploadAreaEvent } from '@ayn-ui/lib/modules/base/file-upload-area';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';
import { of } from 'rxjs';
import { isBlobUrl, runIsDev } from '@shared/utils';
import { SafeUrl } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'aayn-dynamic-creative-ads--tiktok',
  templateUrl: './dynamic-creative-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class DynamicCreativeAdsTiktokComponent implements OnInit {
  protected adCreationModel = this.tiktokStateService.adCreationModel;

  protected callToActions = TikTok.CreateAd.Backend.CTA_OPTIONS;

  private _assets: TikTok.CreateAd.Client.IdWithUrl[] = [];

  protected uploadedAssetUrls: string[] = [];

  protected form = new FormGroup(
    {
      adTexts: new FormArray(
        [
          new FormControl(this.adCreationModel.ad.adTexts[0] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad.adTexts[1] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad.adTexts[2] || ''),
          new FormControl(this.adCreationModel.ad.adTexts[3] || ''),
          new FormControl(this.adCreationModel.ad.adTexts[4] || '')
        ],
        [AppValidations.uniqueValidator()]
      ),
      identity: new FormControl(this.adCreationModel.campaign.identity || {}, [Validators.required]),
      callToAction: new FormControl(this.adCreationModel.ad.callToAction, [Validators.required]),
      url: new FormControl(this.adCreationModel.ad.url, [Validators.required]),
      name: new FormControl(this.adCreationModel.ad.name, [Validators.required])
    },
    (): ValidationErrors | null => {
      return !this.uploadedAssetUrls.length ? { images: true } : null;
    }
  );

  get adTextsFormArray() {
    return this.form.controls['adTexts'] as FormArray;
  }

  get imageCount() {
    if (this.uploadedAssetUrls.length < 10) {
      return 10;
    }
    const imageCount =
      this.uploadedAssetUrls.length +
      (this.uploadedAssetUrls.length % 5 > 0 ? 5 - (this.uploadedAssetUrls.length % 5) : 0);
    return imageCount;
  }

  constructor(
    private tiktokStateService: TiktokStateService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private tiktokService: TiktokService,
    private tiktokValidationService: TiktokValidationService,
    private canvaService: CanvaService,
    private tiktokForm: TiktokFormService,
    public adPreviewService: TiktokAdPreviewService
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      runIsDev(() => {
        console.error(`CreateAd.Tiktok.DynamicCreativeAds.CanvaService`, error);
      });
    }
    this.tiktokForm.registerForm(this.form);

    this.tiktokValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.listenModel();

    this.listenForm();
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadDynamicCreativeAdsImagesTiktokComponent);

    if (this._assets) modalRef.componentInstance.uploadedImages = this._assets;

    modalRef.closed.pipe(take(1)).subscribe((result: { images: TikTok.CreateAd.Client.IdWithUrl[] }) => {
      if (!result?.images) return;

      const libraryAssetsAndImages = result.images.filter(
        (o) => o.type === 'image' || (o.type === 'video' && !isBlobUrl(o.url))
      );
      const uploadedVideos = result.images.filter(
        (i) => !libraryAssetsAndImages.some((assetAndImage) => assetAndImage.id === i.id)
      );
      this.updateAssets(libraryAssetsAndImages);
      this.getVideos(uploadedVideos).subscribe((videos) => {
        this.updateAssets(libraryAssetsAndImages.concat(videos));
      });
    });
  }

  removeFile(event: FileUploadAreaEvent) {
    this.updateAssets(this._assets!.filter((_, i) => i !== event.index));
  }

  updateAssets(assets?: IdWithUrl<string>[]) {
    this._assets = assets || [];

    this.uploadedAssetUrls = this._assets.map((o) => o.url);

    this.adCreationModel.ad!.images = this.filterImageAndMap('image');
    this.adCreationModel.ad!.videos = this.filterImageAndMap('video');
    this.cdr.detectChanges();

    this.form.updateValueAndValidity();

    this.tiktokStateService.saveDraft$();
  }

  private filterImageAndMap(type: 'image' | 'video') {
    return this._assets.filter((file) => file.type === type).map((o) => ({ id: o.id, url: o.url }));
  }

  listenModel() {
    this.tiktokStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        adTexts: o.ad.adTexts,
        callToAction: o.ad.callToAction,
        name: o.ad.name,
        url: o.ad.url,
        identity: o.campaign.identity
      });

      const addType = <T>(assets: T[], type: 'image' | 'video') =>
        assets?.map(
          (asset) =>
            ({
              ...asset,
              type: 'image'
            } as T)
        ) || [];

      const images = addType(o.ad.images, 'image');
      const videos = addType(o.ad.videos, 'video');

      this._assets = [...images, ...videos];
      this.uploadedAssetUrls = this._assets.map((o) => o.url);
      this.form.updateValueAndValidity();
    });
  }

  listenForm() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((o) => {
      this.adCreationModel.ad.adTexts = o.adTexts?.filter(Boolean) as string[];
      this.adCreationModel.ad.callToAction = o.callToAction!;
      this.adCreationModel.ad.url = o.url!;
      this.adCreationModel.ad.name = o.name!;
      this.adCreationModel.campaign.identity = o.identity!;
    });
  }

  private getVideos(videos: TikTok.CreateAd.Client.IdWithUrl[]) {
    if (!videos.length) {
      return of([]);
    }
    return this.tiktokService.getAccountVideos$({ videoIds: videos.map((o) => o.id) }).pipe(
      map((response) => {
        if (!response.edges.length) {
          return videos;
        }
        return videos.map((video) => ({
          ...video,
          url: response.edges.find((v) => v.node.videoId === video.id)?.node.videoCoverUrl || video.url
        }));
      })
    );
  }

  getImages(url: SafeUrl) {
    const { id, type } = this._assets.find((o) => o.url === url) || {};
    if (id) {
      const stream =
        type === 'image'
          ? this.tiktokService.getAccountImages$({ imageIds: [id] }).pipe(map((o) => o.edges[0].node.imageUrl))
          : this.tiktokService.getAccountVideos$({ videoIds: [id] }).pipe(map((o) => o.edges[0].node.videoCoverUrl));

      stream.subscribe((url) => {
        const updateImages = this._assets!.map((asset) => ({
          ...asset,
          url: asset.id === id ? url : asset.url
        }));
        this.updateAssets(updateImages);
      });
    }
  }
}
