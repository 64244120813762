<div
  class="aayn-create-ad--item"
  *ngIf="{
    platformContexts: platformContexts$ | async,
    selectedPlatform: selectedPlatform$ | async
  } as data"
>
  <section class="aayn-create-ad--item--container">
    <div class="aayn-create-ad--item--row mb-3">
      <aayn-select-platform-campaign-item
        title="Instant AI-Driven Campaigns"
        description="Simply provide your website URL, and let our AI handle the rest.
             ADYOUNEED will generate engaging creatives, compelling text,
             and complete campaign details for you. Review and launch your ads instantly with minimal effort."
        [selected]="selectedAdType == 'instant-ad'"
        [isBeta]="true"
        type="instant-ads"
        (click)="setAdType('instant-ad')"
        class="aayn-create-ad--instant-ad-item"
      ></aayn-select-platform-campaign-item>
    </div>

    <div class="aayn-create-ad--item--row">
      <aayn-select-platform-campaign-item
        title="Smart Multi-Platform Campaign"
        type="smart-campaign"
        description="Let our AI to publish campaigns on your selected platforms, optimize them, and allocate your budget in real time."
        [selected]="selectedAdType == 'smart-campaign'"
        (click)="setAdType('smart-campaign')"
        class="aayn-create-ad--smart-campaign-item"
      ></aayn-select-platform-campaign-item>

      <aayn-select-platform-campaign-item
        [selected]="selectedAdType == 'manual'"
        type="single-campaign"
        title="Manual Single-Platform Campaign"
        description="Take full control of your campaign. Swiftly set up and launch your ads on your preferred platform."
        (click)="setAdType('manual')"
        class="aayn-create-ad--manual-campaign-item"
      ></aayn-select-platform-campaign-item>
    </div>

    <div class="aayn-create-ad--item--platform-header" *ngIf="selectedAdType">
      <h4 class="aayn-create-ad--item--platform-header__title">{{ headerTexts.title }}</h4>
      <p class="aayn-create-ad--item--platform-header__description">
        {{ headerTexts.description }}
      </p>
    </div>

    <section class="aayn-create-ad--item--row" *ngIf="selectedAdType">
      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Meta', platform: 'meta' }"
          [selected]="selectedPlatforms.facebook"
          description="Advertise on Meta, which includes Facebook, Instagram, WhatsApp, and their extensive audience network."
          [status]="!businessConnectedAccounts.facebook.connected ? 'connect-first' : ''"
          (btnClick)="onClickPlatform(Platforms.Meta, businessConnectedAccounts.facebook.connected)"
        ></aayn-select-platform-item>
      </div>

      <div [class]="platformClass">
        <aayn-select-platform-item
          description="Advertise on Google Ads, where you can reach audiences through Google Search, YouTube, and a vast network of partner websites."
          [platform]="{ name: 'Google', platform: 'google' }"
          [selected]="selectedPlatforms.google"
          [status]="
            selectedAdType === 'instant-ad'
              ? 'coming-soon'
              : !businessConnectedAccounts.google.connected
              ? 'connect-first'
              : ''
          "
          (btnClick)="onClickPlatform(Platforms.Google, businessConnectedAccounts.google.connected)"
        ></aayn-select-platform-item>
      </div>
      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Linkedin', platform: 'linkedin' }"
          [selected]="selectedPlatforms.linkedin"
          description="Advertise on LinkedIn Ads, which targets a diverse community of professionals, businesses, and thought leaders."
          [status]="
            selectedAdType === 'instant-ad'
              ? 'coming-soon'
              : !businessConnectedAccounts.linkedin.connected
              ? 'connect-first'
              : ''
          "
          (btnClick)="onClickPlatform(Platforms.LinkedIn, businessConnectedAccounts.linkedin.connected)"
        ></aayn-select-platform-item>
      </div>

      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Bing', platform: 'bing' }"
          [selected]="selectedPlatforms.bing"
          description="Advertise on Bing Ads, accessing users of Bing, Yahoo, and other Microsoft services for broad outreach."
          [status]="
            selectedAdType === 'instant-ad'
              ? 'coming-soon'
              : !businessConnectedAccounts.bing.connected
              ? 'connect-first'
              : ''
          "
          (btnClick)="onClickPlatform(Platforms.Bing, businessConnectedAccounts.bing.connected)"
        ></aayn-select-platform-item>
      </div>
      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'TikTok', platform: 'tiktok' }"
          [selected]="selectedPlatforms.tiktok"
          description="Advertise on Bing Ads, accessing users of Bing, Yahoo, and other Microsoft services for broad outreach."
          [status]="
            selectedAdType === 'instant-ad'
              ? 'coming-soon'
              : !businessConnectedAccounts.tiktok.connected
              ? 'connect-first'
              : ''
          "
          (btnClick)="onClickPlatform(Platforms.TikTok, businessConnectedAccounts.tiktok.connected)"
        ></aayn-select-platform-item>
      </div>

      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Twitter', platform: 'twitter' }"
          description="Advertise on Twitter Ads, where you can reach a wide audience involved in real-time conversation and trending topics."
          status="coming-soon"
        ></aayn-select-platform-item>
      </div>

      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Spotify', platform: 'spotify' }"
          description="Advertise on Spotify Ads, targeting a music-loving audience that engages with both free and premium streaming services."
          status="coming-soon"
        ></aayn-select-platform-item>
      </div>

      <div [class]="platformClass">
        <aayn-select-platform-item
          [platform]="{ name: 'Pinterest', platform: 'pinterest' }"
          description="Advertise on Pinterest Ads, connecting with a community that seeks inspiration and ideas across a variety of categories."
          status="coming-soon"
        ></aayn-select-platform-item>
      </div>
    </section>
  </section>
  <aayn-ad-item-footer>
    <button
      ayn-button="primary"
      class="aayn-create-ad--item--btn"
      ayn-button-block
      (click)="onClickNext()"
      type="submit"
      [width]="204"
      [disabled]="nextButtonDisabled(data.selectedPlatform)"
      [innerHTML]="'Next Step; Objective' | translate"
    ></button>
  </aayn-ad-item-footer>
</div>
