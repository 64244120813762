<div class="ayn-carousel" [ngClass]="previewType">
  <div class="ayn-slides-container" #slideContainer [style.transform]="transform">
    <div
      class="ayn-carousel-slide"
      [class.ayn-carousel-slide--dynamic-height]="isDynamicHeight"
      *ngFor="let slides of slideChunks; let i = index"
    >
      <ng-container *ngFor="let slide of slides">
        <ng-container [ngTemplateOutlet]="slide.template"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="previewType === 'ordered'">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </ng-container>
</div>

<ng-container *ngIf="previewType === 'full-image'">
  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-container>

<ng-template #buttons>
  <div class="ayn-carousel--btn_container" [ngClass]="'ayn-carousel--btn_container_'+previewType+'--buttons'">
    <button class="btn btn--carousel ayn-carousel-prev-button" (click)="prevSlide()">
      <ayn-icon name="chevron-left"></ayn-icon>
    </button>
    <button class="btn btn--carousel ayn-carousel-next-button" (click)="nextSlide()">
      <ayn-icon name="chevron-right"></ayn-icon>
    </button>
  </div>
</ng-template>
