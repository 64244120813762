import { Component } from '@angular/core';
import { Linkedin } from '@core/models';
import { ICreateAdSelectItem } from '../../../../models/ad-create.model';
import { LinkedinStateService } from '@pages/create-ad/state/platforms/linkedin';
import { mapObjectives } from '@pages/create-ad/components/select-objective/utils';
import { selectedItem } from '@pages/create-ad/components/select-objective/utils/selected-item';

export const OBJECTIVE_LIST__LINKEDIN: ICreateAdSelectItem<Linkedin.LinkedInCampaignObjectiveType>[] = [
  {
    id: 1,
    title: 'Traffic to my website',
    description: 'For reveiving as many visitors as possible to your website.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: Linkedin.LinkedInCampaignObjectiveType.WEBSITE_VISIT
  },
  {
    id: 2,
    title: 'Conversions on my website',
    description: 'For receiving conversions on your website, must have Facebook Pixel installed.',
    icon: 'conversions',
    empty: false,
    active: false,
    type: Linkedin.LinkedInCampaignObjectiveType.WEBSITE_CONVERSION
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  }
];
@Component({
  selector: 'aayn-select-objective--linkedin',
  templateUrl: './select-objective-content.component.html'
})
export class SelectObjectiveLinkedInComponent {
  selectedObjective: Linkedin.LinkedInCampaignObjectiveType = Linkedin.LinkedInCampaignObjectiveType.WEBSITE_VISIT;

  ObjectiveTypes = Linkedin.LinkedInCampaignObjectiveType;

  objectives$ = this.linkedinStateService.adCreationModel$.pipe(
    mapObjectives(OBJECTIVE_LIST__LINKEDIN, 'objectiveType'),
    selectedItem((objective) => (this.selectedObjective = objective.type!))
  );

  protected adCreationModel = this.linkedinStateService.adCreationModel;

  constructor(private linkedinStateService: LinkedinStateService) {}

  objectiveChangeStatus(item: ICreateAdSelectItem<Linkedin.LinkedInCampaignObjectiveType>) {
    if (item.type !== this.linkedinStateService.adCreationModel.campaign.objectiveType) {
      let costType = this.linkedinStateService.adCreationModel.campaign.costType;
      let optimizationTargetType = this.linkedinStateService.adCreationModel.campaign.optimizationTargetType;

      switch (item.type) {
        case Linkedin.LinkedInCampaignObjectiveType.WEBSITE_VISIT:
          optimizationTargetType = Linkedin.OptimizationTargetType.MAX_CLICK;
          costType = Linkedin.LinkedInCostType.CPM;
          break;
        case Linkedin.LinkedInCampaignObjectiveType.WEBSITE_CONVERSION:
          optimizationTargetType = Linkedin.OptimizationTargetType.MAX_CONVERSION;
          costType = Linkedin.LinkedInCostType.CPC;
          break;
      }

      this.linkedinStateService.setAdModel({
        ...this.linkedinStateService.adCreationModel,
        campaign: {
          ...this.linkedinStateService.adCreationModel.campaign,
          objectiveType: item.type!,
          optimizationTargetType,
          costType
        }
      });
    }
  }
}
