import {
  Bing,
  BingAdAccount,
  Facebook,
  FacebookAdAccount,
  Google,
  GoogleAdAccount,
  Linkedin,
  LinkedinAdAccount,
  Platforms,
  TikTok,
  TikTokAdAccount
} from '@core/models';
import { Edge } from '@core/queries';

export function mapMetaAdAccount(adAccounts: Edge<Facebook.IAdAccount>[], externalId: string): FacebookAdAccount[] {
  return adAccounts.map((element) => ({
    ...element.node,
    name: element.node.name || element?.node?.id.toString() || 'Unnamed',
    source: Platforms.Meta,
    selected: element.node.id === externalId
  }));
}

export function mapGoogleAdAccount(
  adAccounts: Array<Google.IGoogleAdAccountOutput>,
  externalId: string
): GoogleAdAccount[] {
  return adAccounts.map((element) => ({
    ...element,
    name: element.name || element?.adAccountId?.toString() || 'Unnamed',
    owner: element.loginCustomerId,
    id: String(element.adAccountId),
    source: Platforms.Google,
    selected: element.adAccountId == +externalId
  }));
}

export function mapBingAdAccount(
  adAccounts: Array<Bing.Business.IBingAdAccountOutput>,
  externalId: string
): BingAdAccount[] {
  return adAccounts.map((element) => ({
    ...element,
    name: element.name || `${element?.adAccountId}` || 'Unnamed',
    currency: 'NS',
    owner: element.loginCustomerId,
    id: String(element.adAccountId),
    source: Platforms.Bing,
    selected: element.adAccountId == +externalId
  }));
}

export function mapLinkedinAdAccount(
  adAccounts: Array<Edge<Linkedin.Business.LinkedInAdAccountOutput>>,
  externalId: string
): LinkedinAdAccount[] {
  return adAccounts.map((element) => ({
    ...element.node,
    source: Platforms.LinkedIn,
    selected: element.node.id == +externalId,
    id: String(element.node.id)
  }));
}

export function mapTikTokAdAccount(
  adAccounts: Array<TikTok.Business.TikTokAdAccountOutputDto>,
  externalId: string
): TikTokAdAccount[] {
  return (adAccounts || []).map((adAccount) => ({
    ...adAccount,
    id: adAccount.adAccountId,
    source: Platforms.TikTok,
    selected: adAccount.adAccountId === externalId
  }));
}
