import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-ad-type',
  template: `
    <section class="aayn-ad-type">
      <h4 class="aayn-create-ad--inner-title">{{ title || '' | translate }}</h4>
      <p class="aayn-create-ad--inner-description">
        {{ description || '' | translate }}
      </p>

      <ng-content></ng-content>
    </section>
  `
})
export class AdTypeComponent implements OnInit {
  @Input() title?: string;

  @Input() description?: string;

  constructor() {}

  ngOnInit() {}
}
