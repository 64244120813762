import { match } from './match';
import { minLengthArray } from './min-length-array';
import { minWordValidator } from './min-word';
import { patternValidator } from './pattern';
import { uniqueValidator } from './unique';

export const AppValidations = {
  match,
  minLengthArray,
  patternValidator,
  uniqueValidator,
  minWordValidator
};
