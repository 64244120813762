<div class="aayn-ad-type-section" [ngClass]="containerClass">
  <div class="aayn-ad-type-section--header" [class.justify-content-between]="iconDirection === 'right'">
    <div class="aayn-ad-type-section--icon" [ngClass]="{ 'order-2 m-0': iconDirection === 'right' }" *ngIf="icon">
      <ayn-icon [name]="icon"></ayn-icon>
    </div>
    <div class="aayn-ad-type-section--info" *ngIf="header || description">
      <h4 class="aayn-ad-type-section--info--header">{{ header | translate }}</h4>
      <span class="aayn-ad-type-section--info--description"> {{ description | translate }}</span>
    </div>
  </div>

  <ng-content></ng-content>
</div>
