import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-select-an-audience--header',
  templateUrl: './select-an-audience-header.component.html'
})
export class SelectAnAudienceHeaderComponent implements OnInit {
  @Input() locations?: Array<string>;
  @Input() maxLocationCount = 5;

  constructor() {}

  ngOnInit(): void {}
}
