<ayn-modal-page #modalPage>
  <ng-template #body>
    <aayn-account-select-modal-content
      [modal]="modalPage.modalRef"
      [activeStep]="activeStep"
      [(query)]="query"
      [buttonDisabled]="completeDisabled"
      (buttonClick)="complete()"
      [onboardingSteps]="[]"
      icon="linkedin"
    >
      <aayn-account-ad-account-select
        *ngIf="adAccounts$ | async as adAccounts"
        [adAccounts]="adAccounts | aynFilterObj: { name: query }"
        (selectAdAccount)="selectAdAccount($event)"
        [platform]="platforms.LinkedIn"
        [validateSelect]="hasOrganization"
        [buttonLoader$]="buttonLoader$"
        [query]="query"
      ></aayn-account-ad-account-select>
    </aayn-account-select-modal-content>

    <ng-template #headerLeftTemplate>
      <ayn-alert
        icon="credits"
        description="Our partner, AdCreative.ai, swiftly generates conversion-focused ad creatives using AI."
        buttonText="Create a New Ad Account"
        (notificationButton)="showCreateNewAccountAccountModal()"
      >
      </ayn-alert>
    </ng-template>
  </ng-template>
</ayn-modal-page>
