import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Directive,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { LottieComponent } from '../lottie';

@Component({
  selector: 'ayn-loader-lottie',
  template: ` <ayn-lottie name="loading" [maxWidth]="maxWidth" [class]="className"></ayn-lottie> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() className = '';
  @Input() maxWidth = 100;
}

@Directive({
  selector: '[ayn-loader]'
})
export class LoaderDirective implements OnChanges {
  @Input('ayn-loader') aynLoader?: string | boolean | undefined | null;

  @Input('ayn-loaderClassname') aynLoaderClassName = '';

  @Input('ayn-loaderMaxWidth') aynLoaderMaxWidth = 100;

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.aynLoader.currentValue) {
      this.createLoader();
    } else {
      this.clearLoader();
    }
  }

  private createLoader(): void {
    this.vcRef.clear();
    const component = this.vcRef.createComponent(LoaderComponent);

    component.instance.className = this.aynLoaderClassName;
    component.instance.maxWidth = this.aynLoaderMaxWidth;
  }

  private clearLoader(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef);
  }
}

@NgModule({
  imports: [CommonModule, LottieComponent],
  exports: [LoaderDirective, LoaderComponent],
  declarations: [LoaderDirective, LoaderComponent],
  providers: []
})
export class LoaderModule {}
