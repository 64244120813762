import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Platforms } from '@core/models';
import { IntercomService } from '@core/services';
import { Select } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-select-review-and-publish',
  templateUrl: './select-review-and-publish.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectReviewAndPublishComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  constructor(private intercomService: IntercomService) {}

  ngOnInit(): void {
    this.intercomService.startTour('CREATE_AD_STEP_5');
  }
}
