import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-audience-gender-selection',
  templateUrl: 'gender-selection.component.html'
})
export class AudienceGenderSelectionComponent implements OnInit {
  value = 'male';

  constructor() {}

  ngOnInit() {}
}
