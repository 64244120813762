import { Component, Input } from '@angular/core';
import { AdPreviewComponent } from '../../../ad-preview.component';
import { CarouselModule, IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-instagram-single',
  templateUrl: './instagram-single.component.html',
  imports: [
    AdPreviewComponent,
    CarouselModule,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    PipesModule,
    TranslateModule,
    IconModule
  ],
  standalone: true
})
export class AdPreviewInstagramSingleComponent {
  @Input() businessName = '';
  @Input() businessImage = '';
  @Input() headline = '';
  @Input() imageUrls: string[] = [];
  @Input() websiteUrl = '';
  @Input() cta = '';

  headlinePlaceholder = 'Your Headline';
  ctaPlaceholder = 'CTA';
  onImgError(event) {
    //event.target.src = this.placeholder;
  }
}
