import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/lib/modules';
import { Google, GoogleService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { environment } from '@environment';
import { Select } from '@ngxs/store';
import { IDetailedTargetingOverlayItemActive } from '@pages/create-ad/components/shared/manuel-audience-setup/components/detailed-targeting/components/detailed-targeting-overlay-item/detailed-targeting-overlay-item.component';
import { GoogleStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-detailed-targeting--google',
  templateUrl: 'detailed-targeting.component.html'
})
export class DetailedTargetingGoogleComponent implements OnInit {
  @Input() form?: FormGroup;

  @ViewChild(Overlay) overlay!: Overlay;

  protected activeTab: 'interest' | 'yourData' | 'custom' = 'interest';

  protected detailedTargetingSearchControl = new FormControl('', [Validators.minLength(1)]);

  protected insterests: Google.CreateAd.IInterestsSearch[] = [];
  protected yourDatas: Google.CreateAd.IYourDataSearch[] = [];
  protected customDataItems: Google.GoogleKeywordCriterion[] = [];

  @Select(LoaderState.getAny(['InterestsSearch', 'YourDataSearch'])) loader$?: Observable<boolean>;

  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(
    private googleService: GoogleService,
    private cdr: ChangeDetectorRef,
    private googleStateService: GoogleStateService
  ) {}

  get _inclusions() {
    return [
      ...this.adCreationModel.audience.create.interests.map((o) => ({ ...o, itemType: 'interests' })),
      ...(this.formattedCustomAudiences?.map((o) => ({ ...o, itemType: 'customAudiences' })) || []),
      ...this.adCreationModel.audience.create.yourDatas.map((o) => ({ ...o, itemType: 'yourDatas' }))
    ];
  }

  private get formattedCustomAudiences() {
    return this.adCreationModel.audience.create.customAudiences?.map(
      (o) =>
        ({
          name: `People who searched “${o.keyword}” on Google`
        } as Google.CreateAd.IInterestsSearch)
    );
  }

  ngOnInit() {
    this.googleStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.detailedTargetingSearchControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((term) => {
        if (this.detailedTargetingSearchControl.invalid && !term?.length) return;

        if (this.activeTab === 'interest') {
          this.googleService
            .interestsSearch({ searchKey: term, limit: 10 } as Google.CreateAd.IGoogleInterestRequest)
            .subscribe((result) => {
              try {
                this.insterests = result || [];

                if (!this.overlay?.render) this.overlay.show(null);

                this.cdr.detectChanges();
              } catch (error) {
                !environment.production && console.log(`[CreateAd.Google.DetailTargeting]: `, error);
              }
            });
        } else if (this.activeTab === 'yourData') {
          this.googleService
            .yourDataSearch({ searchKey: term, number: 10 } as Google.CreateAd.IGoogleYourDataRequest)
            .subscribe((result) => {
              try {
                this.yourDatas = result || [];
                if (!this.overlay?.render) this.overlay.show(null);
                this.cdr.detectChanges();
              } catch (error) {
                !environment.production && console.log(`[CreateAd.Google.DetailTargeting]: `, error);
              }
            });
        } else if (this.activeTab === 'custom') {
          this.customDataItems = [
            {
              keyword: term!
            } as Google.GoogleKeywordCriterion
          ];

          this.cdr.detectChanges();
        }
      });
  }

  protected onIncludeClick(detailedTargeting: Google.CreateAd.IInterestsSearch | Google.CreateAd.IYourDataSearch) {
    if (this.activeTab === 'interest') {
      this.form!.patchValue({
        interests: [...this.adCreationModel.audience.create.interests, detailedTargeting]
      });
    } else if (this.activeTab == 'custom') {
      this.form!.patchValue({
        customAudiences: [...(this.adCreationModel.audience.create.customAudiences || []), detailedTargeting]
      });
    } else if (this.activeTab == 'yourData') {
      this.form!.patchValue({
        yourDatas: [...this.adCreationModel.audience.create.yourDatas, detailedTargeting]
      });
    }
  }

  private getArrayNameByType(type: string) {
    return this.adCreationModel.audience.create[`${type}`] as (
      | Google.CreateAd.IInterestsSearch
      | Google.CreateAd.IYourDataSearch
    )[];
  }

  protected onCloseClickGroup() {
    this.adCreationModel.audience.create.customAudiences = [];
    this.adCreationModel.audience.create.interests = [];
    this.adCreationModel.audience.create.yourDatas = [];

    this.form!.patchValue({
      customAudiences: [],
      interests: [],
      yourDatas: []
    });
  }

  protected onCloseClick(
    detailedTargeting: (Google.CreateAd.IInterestsSearch | Google.CreateAd.IYourDataSearch) & { itemType: string }
  ) {
    const originalIndex = this.getArrayNameByType(detailedTargeting.itemType).findIndex(
      (o) => o.name === detailedTargeting.name
    );

    this.getArrayNameByType(detailedTargeting.itemType).splice(originalIndex, 1);

    this.form!.patchValue({
      [detailedTargeting.itemType]: this.getArrayNameByType(detailedTargeting.itemType)
    });
  }

  protected isActive(detailedTargeting: Google.CreateAd.IInterestsSearch | Google.CreateAd.IYourDataSearch) {
    const hasAddedInclusions = this.adCreationModel.audience.create.interests.some(
      (o) => o.id === detailedTargeting.id
    );
    const hasAddedExclusions = this.adCreationModel.audience.create.interests.some(
      (o) => o.id === detailedTargeting.id
    );

    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'includes' : hasAddedExclusions ? 'excludes' : ''
    } as IDetailedTargetingOverlayItemActive;
  }

  changeActiveTab(tab: 'interest' | 'yourData' | 'custom') {
    this.activeTab = tab;

    this.detailedTargetingSearchControl.setValue('');
  }

  hasExistingKeyword(keyword: string) {
    return this.adCreationModel.audience.create.customAudiences?.some((o) => o.keyword == keyword);
  }

  onClickExact(item: Google.GoogleKeywordCriterion) {
    if (!this.detailedTargetingSearchControl.value?.trim().length || this.hasExistingKeyword(item.keyword)) {
      return;
    }

    this.form!.patchValue({
      customAudiences: [
        ...(this.adCreationModel.audience.create.customAudiences || []),
        { keyword: item.keyword, matchType: Google.GoogleKeywordMatchType.Exact } as Google.GoogleKeywordCriterion
      ]
    });
  }

  onClickPhrase(item: Google.GoogleKeywordCriterion) {
    if (!this.detailedTargetingSearchControl.value?.trim().length || this.hasExistingKeyword(item.keyword)) {
      return;
    }

    this.form!.patchValue({
      customAudiences: [
        ...(this.adCreationModel.audience.create.customAudiences || []),
        { keyword: item.keyword, matchType: Google.GoogleKeywordMatchType.Phrase } as Google.GoogleKeywordCriterion
      ]
    });
  }

  onClickBroad(item: Google.GoogleKeywordCriterion) {
    if (!this.detailedTargetingSearchControl.value?.trim().length || this.hasExistingKeyword(item.keyword)) {
      return;
    }

    this.form!.patchValue({
      customAudiences: [
        ...(this.adCreationModel.audience.create.customAudiences || []),
        { keyword: item.keyword, matchType: Google.GoogleKeywordMatchType.Broad } as Google.GoogleKeywordCriterion
      ]
    });
  }

  onIncludeRemove(detailedTargeting: Google.CreateAd.IInterestsSearch | Google.CreateAd.IYourDataSearch) {
    if (this.activeTab === 'interest') {
      this.form!.patchValue({
        interests: this.adCreationModel.audience.create.interests.filter((o) => o.id !== detailedTargeting.id)
      });
    } else if (this.activeTab == 'yourData') {
      this.form!.patchValue({
        yourDatas: this.adCreationModel.audience.create.yourDatas.filter((o) => o.id !== detailedTargeting.id)
      });
    }
  }
}
