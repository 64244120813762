import * as bing from './bing';
import * as facebook from './facebook';
import * as linkedin from './linkedin';
import * as smartBudget from './smart-budget';
import * as smartStatus from './smart-status';

export default {
  bing,
  facebook,
  linkedin,
  ...smartBudget,
  ...smartStatus
};
