import { makeQuery } from '@core/queries';
import { OpenAI } from '@core/models';

export const searchImagesQuery = makeQuery<
  { getImages: { src: string; width: string; height: string }[] },
  OpenAI.UrlInput
>(`
query GetImages($url:String!){ 
  ayn {
    getImages(url: $url){
        src
        width
        height
        parentString
    }
  }
}
`);
