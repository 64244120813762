<aayn-upload-image-modal
  header="Library"
  icon="folder"
  description="Linkedin library text"
  [saveDisabled]="!selectedImagesLength"
  (saveModal)="continueWithSelected()"
  [modal]="modal"
  modalClass="ayn-library"
  bodyClass="without-scroll"
>
  <section class="aayn-library-body">
    <ayn-tab tab-type="ayn-tab--default">
      <ayn-tab-item tabTitle="Images">
        <section class="aayn-library--images">
          <div class="m:auto" *ngIf="loader$ | async">
            <ayn-loader-lottie></ayn-loader-lottie>
          </div>

          <aayn-library-image
            *ngFor="let item of images?.edges; let index = index"
            [url]="item.node.url"
            [selected]="item.node.selected || false"
            [selectImageText]="item.node?.selected ? 'Unselect Image' : 'Select Image'"
            (onSelectImageClick)="toggleImage(item.node)"
          ></aayn-library-image>
        </section>
      </ayn-tab-item>

      <ayn-tab-item tabTitle="Videos" *ngIf="false">
        <section class="aayn-library--images">
          <aayn-library-image></aayn-library-image>
        </section>
      </ayn-tab-item>

      <section right>
        <b class="aayn-library--selected-files">{{ selectedImagesLength }} files selected</b>

        <ayn-form-field>
          <input type="text" ayn-input placeholder="Type name or id to filter" [(ngModel)]="query" />
          <ayn-icon name="search"></ayn-icon>
        </ayn-form-field>

        <div class="aayn-library--pagination">
          <a class="aayn-library--pagination-btn">
            <ayn-icon name="previous"></ayn-icon>
          </a>
          <a class="aayn-library--pagination-btn">
            <ayn-icon name="next"></ayn-icon>
          </a>
        </div>
      </section>
    </ayn-tab>
  </section>
</aayn-upload-image-modal>
