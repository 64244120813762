import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { GoogleService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { GoogleImageService } from '@pages/create-ad/services/google-image.service';

export type AccountImageItem = {
  url: string;
  selected: boolean;
  id: number;
};

@Component({
  selector: 'aayn-library--google',
  templateUrl: 'library.component.html'
})
export class LibraryGoogleComponent implements OnInit {
  query = '';

  @Select(LoaderState.getAny(['GetAccountImages'])) loader$?: Observable<boolean>;

  images?: AccountImageItem[] = [];

  maxImageSelectLength = 10;

  ratio: number = 1;

  constructor(
    public modal: NgbActiveModal,
    private googleService: GoogleService,
    private googleImageService: GoogleImageService
  ) {}

  ngOnInit() {
    this.getImages();
  }

  protected getImages() {
    this.googleService.getAccountImages$({ ratio: this.ratio }).subscribe((result) => {
      if (result.error) return;

      this.images = result.data.accountImages.edges.map((o) => ({
        url: this.googleImageService.getImageUrl(o.node.url),
        id: o.node.id,
        selected: false
      }));
    });
  }

  protected toggleImage(item: AccountImageItem) {
    if (this.selectedImagesLength! + 1 > this.maxImageSelectLength && !item.selected) return;
    item.selected = !item.selected;
  }

  protected get selectedImagesLength() {
    return this.images?.filter((o) => o.selected).length;
  }

  protected continueWithSelected() {
    this.modal.close({
      images: this.images?.filter((o) => o.selected).map((o) => ({ url: o.url, id: o.id }))
    });
  }
}
