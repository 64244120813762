import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { FacebookStateService } from './';

@Injectable({ providedIn: 'root' })
export class FacebookFormService extends FormListener {
  constructor(private facebookStateService: FacebookStateService) {
    super();

    this.subscribeForms(() => {
      this.facebookStateService.saveDraft$();
    });
  }
}
