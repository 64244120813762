import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IconModule } from '../icon';
import { InputModule } from '../input';

@Component({
  selector: 'ayn-accordion-input-wrapper',
  templateUrl: './accordion-input-wrapper.component.html',
  standalone: true,
  imports: [NgIf, InputModule, IconModule, NgClass]
})
export class AccordionInputWrapperComponent {
  @Input() collapsed = true;

  @ViewChild('contentTemp', { static: true }) contentTemp!: ElementRef<HTMLElement>;

  toggle(event: MouseEvent) {
    if (!this.collapsed && !this.contentTemp.nativeElement.contains(event.target as Node)) {
      this.collapsed = true;
    } else {
      this.collapsed = false;
    }
  }

  toggleState() {
    this.collapsed = !this.collapsed;
  }
}
