import { Bing } from '@core/models/platforms/bing';
import { QueryResult, makeQuery } from '@core/queries/types';

const queryField = 'isCreationPossible';

type Input = Bing.Business.IAdAccountRequest;
type Output = QueryResult<typeof queryField, boolean>;

export const isCreationPossibleBing = makeQuery<Output, Input>(`
query IsCreationPossibleBing($contextId: String!) {
  bing {
    isCreationPossible(contextId: $contextId)
  }
}
`);
