import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Facebook.Dashboard.IInsightRequest;
type Output = {
  insights: Facebook.Dashboard.IInsightResponse;
};

export const getInsights = makeQuery<Output, Input>(`
  query GetInsights(
    $datePreset: FacebookInsightDatePreset
    $timeIncrement: SafeInt
    $dateRange: DateRangeInput
    $interval: InsightInterval
  ){
    facebook {
      insights (datePreset: $datePreset, timeIncrement: $timeIncrement, dateRange: $dateRange, interval: $interval) {
        insights {
          costPerClick
          clicks
          averageCost1KImpressions
          averageCost1KReach
          spend
          impressions
          ctr
          reach
          startDate
          endDate
          actions {
            type
            value
            cost
          }
        },
        summary {
          costPerClick
          clicks
          averageCost1KImpressions
          averageCost1KReach
          spend
          impressions
          ctr
          reach
          actions {
            type
            value
            cost
          }
        }
      }
    }
  }
`);
