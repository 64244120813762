import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { Injectable } from '@angular/core';
import { DefaultOptions, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@environment';
import { Store } from '@ngxs/store';

import { UserState } from '../';
import { isDev } from '@shared/utils';

const GRAPHQL_URI = environment.apiUrl + environment.graphQl;
const GRAPHQL_WS_URI = environment.wsUrl + environment.graphQl;

@Injectable()
export class GQLClientService {
  private webSocketClient!: SubscriptionClient;

  constructor(private apollo: Apollo, private httpLink: HttpLink, private store: Store) {
    this.start();
  }

  /** configures Apollo client to access GraphQL Application Programming Interface */
  public start(): void {
    this.webSocketClient = new SubscriptionClient(GRAPHQL_WS_URI, {
      lazy: true,
      reconnect: true,
      connectionParams: () => {
        const { accessToken } = this.store.selectSnapshot(UserState.user);

        return { authorization: accessToken ? `Bearer ${accessToken}` : '' };
      }
    });

    const webSocketLink = new WebSocketLink(this.webSocketClient);

    const link = split(
      (op) => {
        const definition = getMainDefinition(op.query);
        const { kind } = definition;
        if (kind === 'OperationDefinition') {
          const { operation } = definition;
          return operation === 'subscription';
        }

        return false;
      },
      webSocketLink,
      this.httpLink.create({
        uri: GRAPHQL_URI
      })
    );

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    };
    if (this.apollo.client) {
      if (isDev()) {
        console.log('[GQLClientService.start]: Apollo client is already started.');
      }
      return;
    }

    // TODO: Cache konfigurasyonuna bakılacak. Şimdilik `no-cache` yapıldı.
    this.apollo.create({
      link,
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions
    });
  }

  public closeWebSocket(): void {
    this.webSocketClient.close(true);
  }
}
