import { Component, OnInit } from '@angular/core';
import { Facebook } from '@core/models';
import { FacebookStateService } from '@pages/create-ad/state';

export const AUDIENCE_LIST__FACEBOOK: Facebook.UI.SelectAudienceItem[] = [
  {
    id: 1,
    title: 'Manual Audience Setup',
    description: 'Manually setup your own target audience with interests and demographics.',
    icon: 'traffic',
    empty: false,
    active: true,
    type: Facebook.AudienceType.Manual
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: Facebook.AudienceType.AiAudienceFinder
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: Facebook.AudienceType.Retargeting
  }
];

@Component({
  selector: 'aayn-select-audience--facebook',
  templateUrl: 'select-audience-content.component.html'
})
export class SelectAudienceContentFacebookComponent implements OnInit {
  protected audiences = AUDIENCE_LIST__FACEBOOK;

  protected selectedAudience: Facebook.AudienceType = Facebook.AudienceType.Manual;

  protected AudienceTypes = Facebook.AudienceType;

  constructor(private facebookStateService: FacebookStateService) {}

  ngOnInit() {}

  audienceChangeStatus(item: Facebook.UI.SelectAudienceItem) {
    this.audiences.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAudience = item.type!;
      } else {
        x.active = false;
      }
    });

    this.facebookStateService.adCreationModel.selectedTypes.audience = item.type!;
  }
}
