import { Clickfraud } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = Clickfraud.IClickFraudProtection;

export const clickfraudProtectionDetailsLinkedin = makeQuery<Output>(`
query ClickfraudProtectionDetailsLinkedin {
  detailsLinkedInCFP {
    createdAt
    updatedAt
    scannedClicks
    fraudulentClicks
    blockedIps
    savedAmount
    lastTurnOnDate
  }
}
`);
