<section
  class="aayn-audience--detailed-targeting"
  [ngClass]="{ 'aayn-audience--detailed-targeting__active': overlay.render }"
>
  <aayn-detailed-targeting-added-item [addedItemType]="'inclusions'" *ngIf="_inclusions.length">
    <ayn-badge
      color="primary"
      *ngFor="let item of _inclusions; let index = index"
      [text]="item.fullName"
      icon="cross-mini-bold"
      (click)="onCloseClick(item, 'inclusions')"
    >
      {{ item.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <aayn-detailed-targeting-added-item [addedItemType]="'exclusions'" *ngIf="_exclusions.length">
    <ayn-badge
      color="orange"
      *ngFor="let item of _exclusions; let index = index"
      [text]="item.fullName"
      icon="cross-mini-bold"
      (click)="onCloseClick(item, 'exclusions')"
    >
      {{ item.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <ayn-form-field class="aayn-audience--detailed-targeting--input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Add audience, demographics, interest or behaviours' | translate"
      (click)="overlay.show($event)"
      [formControl]="detailedTargetingSearchControl"
    />
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--detailed-targeting--overlay"
    styleClass="aayn-audience--detailed-targeting--overlay-inner"
  >
    <ayn-tab tab-type="ayn-tab--default">
      <ayn-tab-item [tabTitle]="'In Market' | translate" (tabClick)="changeActiveTab('InMarket')">
        <ng-container *ngIf="InMarket?.length || (loader$ | async); else noResult">
          <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
            <aayn-detailed-targeting-overlay-item
              *ngFor="let item of InMarket"
              [type]="'In Market'"
              [value]="item.searchSize"
              (onIncludeClick)="!isActive(item)?.isActive ? onIncludeClick(item) : null"
              (onExcludeClick)="!isActive(item)?.isActive ? onExcludeClick(item) : null"
              (onIncludeRemove)="onIncludeRemove(item)"
              (onExcludeRemove)="onExcludeRemove(item)"
              [active]="isActive(item)"
            >
              {{ item.fullName }}
            </aayn-detailed-targeting-overlay-item>
          </aayn-detailed-targeting-overlay-list>
        </ng-container>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Remarketing List' | translate" (tabClick)="changeActiveTab('RemarketingList')">
        <ng-container *ngIf="RemarketingList?.length || (loader$ | async); else noResult">
          <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
            <aayn-detailed-targeting-overlay-item
              *ngFor="let item of RemarketingList"
              type="Remarketing List"
              [value]="item.searchSize"
              (onIncludeClick)="!isActive(item)?.isActive ? onIncludeClick(item) : null"
              (onExcludeClick)="!isActive(item)?.isActive ? onExcludeClick(item) : null"
              [active]="isActive(item)"
            >
              {{ item.fullName }}
            </aayn-detailed-targeting-overlay-item>
          </aayn-detailed-targeting-overlay-list>
        </ng-container>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="'Similar Remarketing List' | translate"
        (tabClick)="changeActiveTab('SimilarRemarketingList')"
      >
        <ng-container *ngIf="SimilarRemarketingList?.length || (loader$ | async); else noResult">
          <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
            <aayn-detailed-targeting-overlay-item
              *ngFor="let item of SimilarRemarketingList"
              [value]="item.searchSize"
              [type]="'Similar Remarketing List'"
              (onIncludeClick)="!isActive(item)?.isActive ? onIncludeClick(item) : null"
              (onExcludeClick)="!isActive(item)?.isActive ? onExcludeClick(item) : null"
              [active]="isActive(item)"
            >
              {{ item.fullName }}
            </aayn-detailed-targeting-overlay-item>
          </aayn-detailed-targeting-overlay-list>
        </ng-container>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Job Function' | translate" (tabClick)="changeActiveTab('JobFunction')">
        <ng-container *ngIf="JobFunction?.length || (loader$ | async); else noResult">
          <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
            <aayn-detailed-targeting-overlay-item
              *ngFor="let item of JobFunction"
              [type]="item.type"
              (onIncludeClick)="!isActive(item)?.isActive ? onIncludeClick(item) : null"
              (onExcludeClick)="!isActive(item)?.isActive ? onExcludeClick(item) : null"
              [active]="isActive(item)"
            >
              {{ item.fullName }}
            </aayn-detailed-targeting-overlay-item>
          </aayn-detailed-targeting-overlay-list>
        </ng-container>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Industry' | translate" (tabClick)="changeActiveTab('Industry')">
        <ng-container *ngIf="Industry?.length || (loader$ | async); else noResult">
          <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
            <aayn-detailed-targeting-overlay-item
              *ngFor="let item of Industry"
              [type]="item.type"
              (onIncludeClick)="!isActive(item)?.isActive ? onIncludeClick(item) : null"
              (onExcludeClick)="!isActive(item)?.isActive ? onExcludeClick(item) : null"
              [active]="isActive(item)"
            >
              {{ item.fullName }}
            </aayn-detailed-targeting-overlay-item>
          </aayn-detailed-targeting-overlay-list>
        </ng-container>
      </ayn-tab-item>
    </ayn-tab>
  </ayn-overlay>
</section>
<ng-template #noResult>
  <aayn-empty-content
    class="mt:3 mb:5 d-block"
    [showButton]="false"
    title="No Records Found"
    description="Please try again."
  ></aayn-empty-content>
</ng-template>
