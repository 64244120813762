import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/backend';
import { blobToFile } from '@core/utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AynService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super(http, '/ayn');
  }

  getImage(imageUrl: string) {
    return this.http.post(`${this.apiUrl}/fetch-image`, { imageUrl }, { responseType: 'blob' });
  }

  getImageAsFile(imageUrl: string, fileName: string) {
    return this.getImage(imageUrl).pipe(
      map((image) => {
        return blobToFile(image, fileName);
      })
    );
  }
}
