import { Component, Input } from '@angular/core';
import { AdPreviewComponent } from '../../../ad-preview.component';
import { CarouselModule, IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'aayn-ad-preview-bing-single',
  templateUrl: './single.component.html',
  imports: [
    AdPreviewComponent,
    PipesModule,
    TranslateModule,
    CarouselModule,
    NgForOf,
    NgTemplateOutlet,
    NgIf,
    IconModule
  ],
  standalone: true
})
export class AdPreviewBingSingleComponent {
  @Input() businessName = '';
  @Input() imageUrls: string[] = [];
  @Input() headline = '';
  @Input() description = '';

  headlinePlaceholder = 'Your Headline';
  businessNamePlaceholder = '[Business Name]';

  constructor() {}

  onImgError(event) {}
}
