import { fadeInOnEnterAnimation } from 'angular-animations';
import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdPlatforms, FacebookService, SharedAdAccountService } from '@core/index';
import { Facebook, IBusiness, PlatformContextMappedType, Platforms } from '@core/models';
import { BusinessState, GetBusinesses, SetSelectedBusiness } from '@core/state/business.state';
import { PlatformContextState } from '@core/state/platform-context.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  CreateAdState,
  SelectedAdType,
  SetInstantAdPlatform,
  SetPlatform,
  SetSelectedAdType,
  SetSmartCampaignPlatforms,
  SmartCampaignStateService
} from '@pages/create-ad/state';
import { AdAccountSelectFacebookComponent } from '@shared/components/account-select-modal/platforms/facebook';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';
import { AdModelService } from '@shared/services';
import { SetAdSteps } from '@pages/create-ad/state/create-ad-steps.state';
import { InstantAdSteps, TypedAdSteps } from '@pages/create-ad/state/constants/steps';

@UntilDestroy()
@Component({
  selector: 'aayn-select-platform',
  templateUrl: './select-platform.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectPlatformComponent implements OnInit {
  @Select(PlatformContextState.PlatformContextsMapped)
  platformContexts$!: Observable<PlatformContextMappedType>;

  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  @Select(BusinessState.SelectedBusiness)
  selectedBusiness$?: Observable<IBusiness | undefined>;

  @Output() next = new EventEmitter<{ draftOperation: 'new' | 'update' }>();

  protected Platforms = Platforms;

  protected platformClass = 'aayn-create-ad--item--container-item';

  private _selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);

  protected businessConnectedAccounts = {
    facebook: { connected: false, id: '' },
    google: { connected: false, id: '' },
    bing: { connected: false, id: '' },
    linkedin: { connected: false, id: '' },
    tiktok: { connected: false, id: '' }
  };

  protected selectedPlatforms = {
    facebook: false,
    google: false,
    bing: false,
    linkedin: false,
    tiktok: false
  };

  selectedAdType?: SelectedAdType;

  get stateService() {
    return this.adModelService.getStateService();
  }

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private facebookService: FacebookService,
    private sharedAdAccountService: SharedAdAccountService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    private smartCampaignStateService: SmartCampaignStateService,
    private instantAdStateService: InstantAdStateService,
    private adModelService: AdModelService
  ) {}

  ngOnInit(): void {
    this.store.select(CreateAdState.selectedAdType).subscribe((o) => {
      this.selectedAdType = o;

      this.updateSelectedPlatforms();

      if (o === 'smart-campaign') {
        this.store.dispatch([new SetPlatform(Platforms.SmartCampaign)]);
      } else if (o === 'instant-ad') {
        this.store.dispatch(new SetPlatform(Platforms.InstantAd));
      }
    });

    this.selectedBusiness$?.subscribe((selectedBusiness) => {
      this.selectedAdType = this.store.selectSnapshot(CreateAdState.selectedAdType)!;

      this._selectedBusiness = selectedBusiness!;

      this.businessConnectedAccounts = {
        facebook: {
          connected: this._selectedBusiness?.connectedAdAccounts?.some((o) => o.source === Platforms.Meta),
          id: this._selectedBusiness?.id ?? ''
        },
        google: {
          connected: this._selectedBusiness?.connectedAdAccounts?.some((o) => o.source === Platforms.Google),
          id: this._selectedBusiness?.id ?? ''
        },
        bing: {
          connected: this._selectedBusiness?.connectedAdAccounts?.some((o) => o.source === Platforms.Bing),
          id: this._selectedBusiness?.id ?? ''
        },
        linkedin: {
          connected: this._selectedBusiness?.connectedAdAccounts?.some((o) => o.source === Platforms.LinkedIn),
          id: this._selectedBusiness?.id ?? ''
        },
        tiktok: {
          connected: this._selectedBusiness?.connectedAdAccounts?.some((o) => o.source === Platforms.TikTok),
          id: this._selectedBusiness?.id ?? ''
        }
      };

      this.cdr.detectChanges();
    });
  }

  get headerTexts(): { title: string; description: string; badge?: string } {
    switch (this.selectedAdType) {
      case 'manual':
        return {
          title: 'Manual Single-Platform Campaign',
          description: 'Select the platform where you want your ads to go live.'
        };
      case 'smart-campaign':
        return {
          title: ' Smart Multi-Platform Campaign',
          description: 'Select the platforms where you want your ads to go live.'
        };
      case 'instant-ad':
        return {
          title: 'Instant AI-Driven Campaigns',
          description: `Select the platform where you want your ads to go live.`,
          badge: 'New'
        };
      default:
        return {
          title: '',
          description: ''
        };
    }
  }

  selectPlatform(platform: Platforms) {
    let stream: Observable<any> = of(platform);

    if (platform === Platforms.Meta) {
      stream = this.controlFacebookPageIdAndInstagram();
    }

    stream.pipe(take(1)).subscribe({
      next: () => {
        switch (this.selectedAdType) {
          case 'manual':
            this.selectManualPlatform(platform);
            break;
          case 'smart-campaign':
            this.selectSmartCampaignPlatforms(platform);
            break;
          case 'instant-ad':
            this.selectInstantAdPlatform(platform as Platforms.Google | Platforms.Meta);
        }
      },
      error: (err) => {
        if (!environment.production) {
          console.error(err);
        }
      }
    });
  }

  getDraftOperation() {
    let isNewDraft = false;

    switch (this.selectedAdType) {
      case 'manual':
        isNewDraft = this.isNewDraftForManual();
        break;
      case 'smart-campaign':
        isNewDraft = this.isNewDraftForSmartCampaign();
        break;
      case 'instant-ad':
        isNewDraft = this.isNewDraftForInstantAd();
        break;
    }

    return isNewDraft ? 'new' : 'update';
  }

  private selectManualPlatform(platform: Platforms) {
    this.store.dispatch(new SetPlatform(platform));
    const draftOperation = this.getDraftOperation();
    this.resetAdCreationModel(draftOperation);

    this.updateSelectedPlatforms();

    this.cdr.detectChanges();

    this.next.emit({ draftOperation });
  }

  private selectSmartCampaignPlatforms(platform: Platforms) {
    let selectedPlatforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatforms) || [];
    if (selectedPlatforms.some((o) => o == platform)) {
      selectedPlatforms = selectedPlatforms.filter((o) => o != platform);
      this.store.dispatch(new SetSmartCampaignPlatforms(selectedPlatforms));
    } else {
      selectedPlatforms = [...selectedPlatforms, platform as AdPlatforms].sort((a, b) => a - b);
      this.store.dispatch(new SetSmartCampaignPlatforms(selectedPlatforms));
    }
    this.updateSelectedPlatforms();
  }

  private selectInstantAdPlatform(platform: Platforms.Meta | Platforms.Google) {
    this.store.dispatch(new SetInstantAdPlatform(platform as Platforms.Meta | Platforms.Google));

    this.updateSelectedPlatforms();

    this.cdr.detectChanges();

    const draftOperation = this.getDraftOperation();

    this.resetAdCreationModel(draftOperation);

    this.instantAdStateService.adCreationModel.selectedPlatform = platform! as Platforms.Meta | Platforms.Google;

    this.next.emit({ draftOperation });
  }

  private updateSelectedPlatforms() {
    this.selectedPlatforms.facebook = this.getSelected(Platforms.Meta);
    this.selectedPlatforms.google = this.getSelected(Platforms.Google);
    this.selectedPlatforms.bing = this.getSelected(Platforms.Bing);
    this.selectedPlatforms.linkedin = this.getSelected(Platforms.LinkedIn);
    this.selectedPlatforms.tiktok = this.getSelected(Platforms.TikTok);

    this.cdr.detectChanges();
  }

  getSelected(platform: Platforms) {
    if (this.selectedAdType == 'manual') {
      const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);

      return selectedPlatform == platform;
    }

    if (this.selectedAdType == 'smart-campaign') {
      const selectedPlatforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatforms) || [];

      return selectedPlatforms?.some((o) => o == platform) || false;
    }

    if (this.selectedAdType == 'instant-ad') {
      const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedInstantPlatform);

      return selectedPlatform == platform;
    }

    return false;
  }

  /**
   * TODO:FIXME: İleride bu kontrol kaldırılacak. Şu an kullanıcılarda PageId
   * olmama durumuna karşı eklendi.
   */
  private controlFacebookPageIdAndInstagram() {
    const facebookForBusiness = this._selectedBusiness?.connectedAdAccounts.find((o) => o.source === Platforms.Meta);

    if (facebookForBusiness?.pageId) return of(null);

    const modalRef = this.modalService.open(AdAccountSelectFacebookComponent, {
      modalDialogClass: 'account-select-modal'
    });

    const platformContextsMapped = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped);

    modalRef.componentInstance.platformContext = platformContextsMapped.Meta;

    return modalRef.closed.pipe(
      switchMap((result: { adAccount: Facebook.IAdAccount; pageId?: string; instagramAccountId?: string }) => {
        if (!result) return throwError('Ad account not selected');

        return this.facebookService
          .connectAdAccount$({
            adAccountId: result.adAccount.id,
            contextId: platformContextsMapped.Meta.id,
            pageId: result.pageId!,
            instagramAccountId: result.instagramAccountId!
          })
          .pipe(
            switchMap(() => {
              return this.store.dispatch(new GetBusinesses()).pipe(
                tap(() => {
                  const businesses = this.store.selectSnapshot(BusinessState.Businesses);

                  const selectedBusiness = businesses.find((o) => o.id == this._selectedBusiness?.id);

                  this.store.dispatch(new SetSelectedBusiness(selectedBusiness!));

                  this._selectedBusiness = selectedBusiness!;
                })
              );
            })
          );
      })
    );
  }

  updateBusiness(platforms: Platforms) {
    this.sharedAdAccountService.updateBusiness(platforms);
  }

  onClickNext() {
    const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);
    if (!selectedPlatform) {
      this.toastr.error(this.translate.instant('Please select a platform'));
      return;
    }

    const draftOperation = this.getDraftOperation();
    this.resetAdCreationModel(draftOperation);

    if (selectedPlatform === Platforms.SmartCampaign) {
      const selectedPlatforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatforms);
      this.smartCampaignStateService.adCreationModel.selectedPlatforms = selectedPlatforms || [];
    }

    this.next.emit({ draftOperation });
  }

  setAdType(type: SelectedAdType) {
    if (type == this.selectedAdType) return;

    this.store.dispatch(new SetSelectedAdType(type));
    if (type !== 'instant-ad') {
      this.store.dispatch(new SetAdSteps(TypedAdSteps));
    } else {
      this.store.dispatch(new SetAdSteps(InstantAdSteps));
    }

    switch (type) {
      case 'manual':
        this.store.dispatch(new SetPlatform());
        break;

      case 'smart-campaign':
        this.store.dispatch(new SetPlatform(Platforms.SmartCampaign));
        break;

      case 'instant-ad':
        this.store.dispatch(new SetPlatform(Platforms.InstantAd));
        break;

      default:
        break;
    }

    this.store.dispatch(new SetSmartCampaignPlatforms([]));
    this.store.dispatch(new SetInstantAdPlatform());

    this.updateSelectedPlatforms();
  }

  nextButtonDisabled(selectedPlatform: Platforms | null | undefined) {
    switch (selectedPlatform) {
      case undefined:
      case null:
        return true;
      case Platforms.SmartCampaign:
        const selectedSmartCampaignPlatforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatforms);
        return !selectedSmartCampaignPlatforms?.length;
      case Platforms.InstantAd:
        const selectedInstantAdPlatform = this.store.selectSnapshot(CreateAdState.selectedInstantPlatform);
        return !selectedInstantAdPlatform;
      default:
        return true;
    }
  }

  private isNewDraftForManual() {
    const draftModel = this.stateService?.draftModel;
    const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);
    return !draftModel?.source || draftModel?.source == selectedPlatform;
  }

  private isNewDraftForSmartCampaign() {
    const { selectedPlatforms } = this.smartCampaignStateService.adCreationModel;
    const newSelectedPlatforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatforms);
    return (
      selectedPlatforms.length !== newSelectedPlatforms?.length ||
      selectedPlatforms?.some((o) => newSelectedPlatforms?.every((p) => p !== o))
    );
  }

  private isNewDraftForInstantAd() {
    const { selectedPlatform } = this.instantAdStateService.adCreationModel;
    const newSelectedPlatform = this.store.selectSnapshot(CreateAdState.selectedInstantPlatform);
    return newSelectedPlatform !== selectedPlatform;
  }

  onClickPlatform(platform: Platforms, connected: boolean) {
    if (this.selectedAdType === 'instant-ad' && platform !== Platforms.Meta) {
      return;
    }
    if (connected) {
      this.selectPlatform(platform);
    } else {
      this.updateBusiness(platform);
    }
  }

  private resetAdCreationModel(draftOperation: 'new' | 'update') {
    if (draftOperation === 'new') {
      this.stateService?.reset();
    }
  }
}
