import { INotification } from '../models/notification.model'
import { makeSubscription } from './types'

type Output = INotification

export const notification = makeSubscription<Output>(`
subscription {
  notification {
    categories
    content
    level
  }
}
`)
