import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TikTok } from '@core/models';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { TiktokService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--tiktok-form',
  templateUrl: './form.component.html'
})
export class ConversionMyWebsiteSMTikTokFormComponent {
  @Input() form?: FormGroup;
  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected pixels: TikTok.CreateAd.Backend.TikTokPixelSearchOutput[] = [];

  get selectedPixel() {
    return this.pixels.find((p) => p.id === this.adCreationModel.campaign?.platforms?.tiktok?.pixelId);
  }

  constructor(private smartCampaignStateService: SmartCampaignStateService, private tiktokService: TiktokService) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;
      this.form?.patchValue({
        pixelEventCode: this.adCreationModel.campaign.platforms?.tiktok?.pixelEventCode,
        pixelId: this.adCreationModel.campaign.platforms?.tiktok?.pixelId
      });
    });

    this.form?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.adCreationModel.campaign.platforms!.tiktok!.pixelEventCode = value.pixelEventCode || '';
      this.adCreationModel.campaign.platforms!.tiktok!.pixelId = value.pixelId || '';
    });

    this.tiktokService.pixelSearch$().subscribe((pixels) => {
      this.pixels = pixels;
    });
  }
}
