<!-- 
  TODO: Burası ileride birden fazla reklam tipi gelme durumu için bırakıldı.
  
  <section class="aayn-create-ad--item--container">
    <section class="aayn-create-ad--item--row">
      <aayn-select-ad-item
        *ngFor="let adType of adTypes$ | async"
        [title]="adType.title"
        [active]="adType.active"
        [empty]="adType.empty"
        [description]="adType.description"
        [adsType]="adType.icon"
        (btnClick)="adTypeChangeStatus(adType)"
      >
      </aayn-select-ad-item>
    </section>
  </section>
 -->

<div class="aayn-ad-type-container">
  <ayn-alert
    type="primary"
    icon="idea"
    title="Best Practices for Smart Multi-platform Campaigns"
    description="We recommend filling in all the text fields manually or using AI, and uploading 10 visuals for optimal results."
    [closeButton]="true"
    class="aayn-ad-type-container--alert"
  >
  </ayn-alert>

  <div class="aayn-ad-type-container--content mt:5">
    <div [ngSwitch]="selectedAdType">
      <div *ngSwitchCase="AdTypes.Default">
        <aayn-dynamic-creative-ads--smart-campaign></aayn-dynamic-creative-ads--smart-campaign>
      </div>
    </div>

    <aayn-ad-preview--smart-campaign
      class="aayn-ad-type-container--content--preview"
      [selectedAdType]="selectedAdType"
    ></aayn-ad-preview--smart-campaign>
  </div>
</div>
