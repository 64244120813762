import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';
import { Observable } from 'rxjs';
import { Platforms } from '@core/models';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';

@Component({
  selector: 'aayn-select-ai-generated-platforms--header',
  templateUrl: './select-ai-generated-platforms-header.component.html'
})
export class SelectAiGeneratedPlatformsHeaderComponent {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  get instantAdImages() {
    return this.instantAdStateService.adCreationModel.ad.images.slice(0, 3);
  }

  constructor(private instantAdStateService: InstantAdStateService) {}
}
