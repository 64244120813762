<section class="aayn-ad-preview aayn-ad-preview-bing-single">
  <section class="aayn-ad-preview-content">
    <ayn-carousel [itemsPerSlide]="1" [isDynamicHeight]="true">
      <ng-template *ngIf="!imageUrls?.length" ayn-carousel-item>
        <div class="aayn-ad-preview-placeholder">
          <ayn-icon name="null-image-icon"></ayn-icon>
        </div>
      </ng-template>
      <ng-template *ngFor="let imageUrl of imageUrls" ayn-carousel-item>
        <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: imageUrl }"></ng-container>
      </ng-template>
    </ayn-carousel>
    <div class="aayn-ad-preview-bing-single_content">
      <p class="aayn-ad-preview-bing-single_header">
        {{ headline || (headlinePlaceholder | translate) }}
      </p>
      <p class="aayn-ad-preview-bing-single_description">
        {{ description || (headlinePlaceholder | translate) }}
      </p>
      <p class="aayn-ad-preview-bing-single__business_name">
        <span class="aayn-ad-preview-bing-single__business_name-ad_info">Ad</span>
        {{ businessName || (businessNamePlaceholder | translate) }}
      </p>
    </div>
  </section>
</section>

<ng-template #imageTemplate let-image>
  <img
    [src]="image | aynSafe: 'url'"
    [alt]="'Ad Preview' | translate"
    class="aayn-ad-preview-bing-single_image full-width"
    (error)="onImgError($event)"
  />
</ng-template>
