import { Settings } from '@core/models';

import { makeQuery } from '../types';

type Output = Settings.BrandingOutput;

export const getCustomBranding = makeQuery<Output>(`
query GetCustomBranding {
  getCustomBranding {
    mainColor
    additionalColor
    paragraphColor
    backgroundColor
    companyName
  }
}

`);
