import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { BusinessState } from '@core/state/business.state';
import { AdAccount, GetPlatformAdAccounts, SetAdAccounts, SetSelectedAdAccounts } from '@core/state';
import { ConnectAdAccount, PlatformAdAccount } from '@core/models';
import { tap } from 'rxjs/operators';

export const AdAccountResolver: ResolveFn<null> = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const selectedBusiness = store.selectSnapshot(BusinessState.SelectedBusiness);
  let primaryOutlet = route.parent;
  while (primaryOutlet?.children.length) {
    primaryOutlet.children.forEach((child) => {
      if (child.outlet === 'primary') {
        primaryOutlet = child;
      }
    });
  }
  const selectedPlatform = +route.data.platform as AdAccount.AdAccountPlatforms;
  const getAdAccounts = (mapWithoutSelect?: boolean) =>
    store.dispatch(new GetPlatformAdAccounts({ platforms: selectedPlatform, mapWithoutSelect }));
  const isBusinessCreationPage =
    primaryOutlet?.parent?.url.some((segment) => segment.path === 'business') && !primaryOutlet?.params.id;

  return store.dispatch(new SetAdAccounts([])).pipe(
    tap(() => {
      //! The reason of not return adAccount request observable is the loader will be shown in ad account selection modal until the request is completed
      getAdAccounts(isBusinessCreationPage || !selectedBusiness);
    })
  );
};

export const BusinessConnectedAdAccountResolver: ResolveFn<PlatformAdAccount[]> = (route, state) => {
  const store = inject(Store);

  const isCreateBusinessPage = route.data.isCreateBusiness;
  const selectedBusiness = store.selectSnapshot(BusinessState.SelectedBusiness);
  if (!selectedBusiness || isCreateBusinessPage) {
    return store.dispatch(new SetSelectedAdAccounts([]));
  }
  return store.dispatch(
    new SetSelectedAdAccounts(
      selectedBusiness.connectedAdAccounts.map(
        (o) =>
          ({
            adAccountId: o.externalId,
            loginId: o.externalId,
            platform: o.source,
            instagramAccountId: o.instagramAccountId,
            pageId: o.pageId
          } as ConnectAdAccount)
      )
    )
  );
};
