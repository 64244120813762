import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = {
  id: string;
};
type Output = {
  adVideo: Facebook.AdVideoModel;
};

export const getAdVideo = makeQuery<Output, Input>(`
  query GetAdVideo($id: String!) {
    facebook {
      adVideo(id: $id) {
        id
        title
        embedHtml
        embeddable
        source
        thumbnails {
          id
          url
          isPreferred
          width
          height  
        }
      }
    }
  }
`);
