import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-location-selection-selected-item',
  template: `
    <li>
      <ng-content select="ayn-badge"></ng-content>
    </li>
  `
})
export class LocationSelectionSelectedItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
