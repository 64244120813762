import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-modal-for-payments',
  template: `
    <header>
      <ng-container *ngIf="icon">
        <ayn-icon [name]="icon"></ayn-icon>
      </ng-container>

      <h5>{{ title }}</h5>

      <p>{{ description }}</p>

      <ayn-modal-close *ngIf="showModalClose" [closeFn]="dismissFn"></ayn-modal-close>
    </header>

    <main>
      <ng-content></ng-content>
    </main>

    <footer>
      <ng-content select="[footer]"></ng-content>
    </footer>
  `
})
export class ModalForPaymentsComponent implements OnInit {
  @HostBinding('style.--color')
  @Input()
  color = 'var(--primary)';

  @Input() icon?: string | null;

  @Input() title = '';

  @Input() description = '';

  @Input() showModalClose = true;

  @Input() dismissFn?: () => void;

  constructor() {}

  ngOnInit() {}
}
