import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-update-payment-info',
  templateUrl: 'update-payment-info.component.html'
})
export class UpdatePaymentInfoComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {}
}
