import { Google } from '@core/models';
import { pipe } from 'fp-ts/lib/function';

export interface HasSitelinkExtension {
  sitelinkExtension?: Google.CreateAd.GooglePMSitelinkExtension;
}
export interface HasCallExtension {
  callExtension?: Google.CreateAd.GooglePMCallExtension;
}
export interface HasCalloutExtension {
  calloutExtension?: Google.CreateAd.GooglePMCalloutExtension;
}
export interface HasYoutubeExtension {
  youtubeExtension?: Google.CreateAd.GooglePMYoutubeExtension;
}
export interface HasStructuredSnippets {
  structuredSnippets?: Google.CreateAd.GoogleStructuredSnippets;
}

export type HasExtension = HasSitelinkExtension &
  HasCallExtension &
  HasCalloutExtension &
  HasYoutubeExtension &
  HasStructuredSnippets;

export function createDefaultSiteLinkExt() {
  return {
    sitelinkExtension: {
      create: {
        sitelinks: [
          {
            sitelinkText: '',
            firstDescription: '',
            secondDescription: '',
            finalUrl: ''
          }
        ],
        name: ''
      }
    } as Google.CreateAd.GooglePMSitelinkExtension
  };
}
export function createDefaultCallExt() {
  return {
    callExtension: {
      create: {
        countryCode: '',
        phoneNumber: '',
        name: ''
      }
    } as Google.CreateAd.GooglePMCallExtension
  };
}
export function createDefaultCalloutExt() {
  return {
    calloutExtension: {
      create: {
        texts: [] as string[],
        name: ''
      }
    } as Google.CreateAd.GooglePMCalloutExtension
  };
}
export function createDefaultYoutubeExt() {
  return {
    youtubeExtension: {
      create: {
        urls: [] as string[],
        name: '',
        youtubeLinks: [] as Google.CreateAd.IYoutubeVideoValidity[]
      }
    } as Google.CreateAd.GooglePMYoutubeExtension
  };
}

export function createDefaultStructedSnippetExt() {
  return {
    structuredSnippets: {
      create: {
        header: '',
        values: [''],
        name: ''
      }
    } as Google.CreateAd.GoogleStructuredSnippets
  };
}

function removeEmptyExtension<T extends { create?: { name: string } }>(extension?: T): T | undefined {
  return extension?.create?.name === '' ? undefined : extension;
}

export function removeEmptySitelinkExtension<T extends HasSitelinkExtension>(ad: T): T {
  return ad.sitelinkExtension?.create.sitelinks[0].sitelinkText == '' ? { ...ad, sitelinkExtension: undefined } : ad;
}
export function removeEmptyCallExtension<T extends HasCallExtension>(ad: T): T {
  return { ...ad, callExtension: removeEmptyExtension(ad.callExtension) };
}
export function removeEmptyCalloutExtension<T extends HasCalloutExtension>(ad: T): T {
  return { ...ad, calloutExtension: removeEmptyExtension(ad.calloutExtension) };
}
export function removeEmptyStructuredSnippets<T extends HasStructuredSnippets>(ad: T): T {
  return { ...ad, structuredSnippets: removeEmptyExtension(ad.structuredSnippets) };
}
export function removeEmptyYoutubeExtension<T extends HasYoutubeExtension>(ad: T): T {
  return { ...ad, youtubeExtension: removeEmptyExtension(ad.youtubeExtension) };
}

export function removeYoutubeLinks<T extends HasYoutubeExtension>(ad: T): T {
  if (ad.youtubeExtension?.create.youtubeLinks) {
    return {
      ...ad,
      youtubeExtension: {
        ...ad.youtubeExtension,
        create: {
          ...ad.youtubeExtension.create,
          // youtubeLinks property used for display info. Videos sent with `ad.youtubeExtension.create.urls`
          youtubeLinks: undefined
        }
      }
    };
  }
  return ad;
}

export function removeEmptyExtensions<T extends HasExtension>(ad: T): T {
  return pipe(
    ad,
    removeEmptySitelinkExtension,
    removeEmptyCallExtension,
    removeEmptyCalloutExtension,
    removeEmptyStructuredSnippets,
    removeEmptyYoutubeExtension,
    removeYoutubeLinks
  );
}
