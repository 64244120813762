import { Injectable } from '@angular/core';
import { TikTok } from '@core/models';

@Injectable()
export class TiktokAdPreviewService {
  dynamicCreativeAds: Partial<{
    adText: string;
    images: string[];
    url: string;
    callToAction: string;
    identity: TikTok.Business.TikTokIdentityOutputDto;
  }> = {
    images: [],
    adText: '',
    url: '',
    callToAction: '',
    identity: {} as TikTok.Business.TikTokIdentityOutputDto
  };
}
