/// <reference path="../../../node_modules/@types/google.maps/index.d.ts" />
// ! ^ Keep the lines above as they are as they provide typings for the
// ! global packages/libraries that are imported through/via the index.html
// ! as scripts instead of `import`s in the code.

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { SentryConfig } from './sentry-config';
import { isSentryEnabled } from '@core/utils/sentry.util';
import { environment } from '@environment';

if (isSentryEnabled) {
  Sentry.init(SentryConfig);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
