import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdPreviewService {
  dynamicCreative: Partial<{
    images: string[];
    shortHeadline: string;
    websiteUrl: string;
    headline: string;
    callToAction: string;
    description: string;
  }> = {
    images: [],
    shortHeadline: '',
    websiteUrl: '',
    headline: '',
    description: ''
  };

  carouselCreative: Partial<{
    cards: Array<{
      image: string[];
      title: string;
      body: string;
    }>;
    websiteUrl: string;
    primaryText: string;
    cta: string;
  }> & {
    activeIndex$: BehaviorSubject<number>;
  } = {
    cards: [],
    websiteUrl: '',
    primaryText: '',
    cta: '',
    activeIndex$: new BehaviorSubject<number>(0)
  };

  existingPost: Partial<{
    platform: number;
  }> = {
    platform: 0
  };
}
