<section class="connect-google-ads-account-modal">
  <section class="connect-google-ads-account-modal--content">
    <h5 class="connect-google-ads-account-modal--title">Connect your Google Ads account to proceed.</h5>
    <p class="connect-google-ads-account-modal--description">
      In order to import data from Google to Bing Ads, it is necessary to have your Google Ads account connected with
      your brand.
    </p>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>

    <section class="connect-google-ads-account-modal--buttons">
      <button ayn-button="primary" (click)="ConnectGoogleAdsAccount()">Connect Google Ads Account</button>
    </section>
  </section>
</section>
