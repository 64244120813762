<!-- TODO: `aayn-audience--location-selection__has-error` a koşul eklenecek. -->
<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__filled': inclusions.length || exclusions.length,
    'aayn-audience--location-selection__active': overlay?.render,
    'aayn-audience--location-selection__has-error': false
  }"
>
  <aayn-location-selection-selected-items type="inclusions" *ngIf="inclusions.length">
    <aayn-location-selection-selected-item *ngFor="let include of inclusions; let index = index">
      <ayn-badge
        color="primary"
        [text]="
          include.name.concat(', ') + (include.region ? include?.region?.concat(', ') : '') + include?.countryName
        "
        icon="cross-mini-bold"
        (click)="removeItem(index, 'geoLocations')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <aayn-location-selection-selected-items type="exclusions" *ngIf="exclusions.length">
    <aayn-location-selection-selected-item *ngFor="let exclude of exclusions; let index = index">
      <ayn-badge
        color="orange"
        [text]="
          exclude.name.concat(', ') + (exclude.region ? exclude?.region?.concat(', ') : '') + exclude?.countryName
        "
        icon="cross-mini-bold"
        (click)="removeItem(index, 'excludedGeoLocations')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      ayn-input
      [required]="!inclusions?.length"
      [placeholder]="'Type to search for location' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />

    <ayn-icon name="map-marker"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length || geoLocations?.length"
  >
    <aayn-location-selection-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
      <aayn-location-selection-overlay-item
        *ngFor="let geoLocation of geoLocations"
        [type]="geoLocation.node.type"
        (onIncludeClick)="onIncludeClick(geoLocation.node)"
        (onExcludeClick)="onExcludeClick(geoLocation.node)"
        (onIncludeRemove)="onIncludeRemove(geoLocation.node)"
        (onExcludeRemove)="onExcludeRemove(geoLocation.node)"
        [active]="isActive(geoLocation.node)"
      >
        {{ geoLocation.node.name.concat(', ') }}
        {{ geoLocation.node.region?.concat(', ') }}
        {{ geoLocation.node.countryName }}
      </aayn-location-selection-overlay-item>
    </aayn-location-selection-overlay-list>
  </ayn-overlay>
</section>
