<aayn-ad-preview
  [businessName]="businessName"
  [profileImage]="businessImage"
  className="aayn-ad-preview-linkedin-single"
>
  <p class="aayn-ad-preview-linkedin-single_header">
    {{ headline || (headlinePlaceholder | translate) }}
  </p>

  <ayn-carousel [itemsPerSlide]="1" [isDynamicHeight]="true">
    <ng-template ayn-carousel-item *ngIf="!imageUrls.length">
      <div class="aayn-ad-preview-placeholder">
        <ayn-icon name="null-image-icon"></ayn-icon>
      </div>
    </ng-template>
    <ng-template *ngFor="let imageUrl of imageUrls" ayn-carousel-item>
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: imageUrl }"></ng-container>
    </ng-template>
  </ayn-carousel>

  <ng-template #imageTemplate let-image>
    <img
      [src]="image | aynSafe: 'url'"
      [alt]="'Ad Preview' | translate"
      class="aayn-ad-preview--card-main__image full-width"
      (error)="onImgError($event)"
    />
  </ng-template>

  <section class="aayn-ad-preview-linkedin-single_content">
    <div class="aayn-ad-preview-linkedin-single_content__labels">
      <div class="aayn-ad-preview-linkedin-single_content__labels-left">
        <p class="aayn-ad-preview-linkedin-single_content__body">
          {{ description || (descriptionPlaceholder | translate) }}
        </p>
        <p class="aayn-ad-preview-linkedin-single_content__link">
          {{ websiteUrl || (urlPlaceholder | translate) }}
        </p>
      </div>

      <p class="aayn-ad-preview-linkedin-single_content__cta">
        {{ cta || (ctaPlaceholder | translate) }}
      </p>
    </div>
  </section>
</aayn-ad-preview>
