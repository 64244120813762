<section
  class="aayn-audience--detailed-targeting"
  [ngClass]="{ 'aayn-audience--detailed-targeting__active': overlay.render }"
>
  <aayn-detailed-targeting-added-item [addedItemType]="'inclusions'" *ngIf="_inclusions.length">
    <ayn-badge
      color="primary"
      *ngFor="let includeDetailedTargeting of _inclusions; let index = index"
      [text]="includeDetailedTargeting.name"
      icon="cross-mini-bold"
      (click)="onCloseClick(includeDetailedTargeting)"
    >
    </ayn-badge>
  </aayn-detailed-targeting-added-item>
  <aayn-detailed-targeting-added-item [addedItemType]="'exclusions'" *ngIf="_exclusions.length">
    <ayn-badge
      color="orange"
      *ngFor="let excludeDetailedTargeting of _exclusions; let index = index"
      [text]="excludeDetailedTargeting.name"
      icon="cross-mini-bold"
      (click)="onCloseExclusions(excludeDetailedTargeting)"
    >
      {{ excludeDetailedTargeting.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>
  <ayn-form-field
    class="aayn-audience--detailed-targeting--input"
    [requiredMessage]="'You must select 1 Interest at least' | translate"
  >
    <input
      type="text"
      ayn-input
      [placeholder]="'Add audience, demographics, interest or behaviours' | translate"
      (click)="showList(overlay, $event)"
      [formControl]="detailedTargetingSearchControl"
    />
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--detailed-targeting--overlay"
    styleClass="aayn-audience--detailed-targeting--overlay-inner"
  >
    <ayn-tab
      tab-type="ayn-tab--default"
      [aayn-tab-validate]="validateTabInterest"
      [markValid]="false"
      [validate$]="validateTrigger$"
    >
      <ayn-tab-item [tabTitle]="'Interest' | translate" (tabClick)="changeActiveTab('interests')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of interests"
            [active]="isActive('interests', detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            [isExcludeShow]="false"
            class="aayn-audience--detailed-targeting--overlay-tiktok"
          >
            {{ detailedTargeting.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>
      <ayn-tab-item [tabTitle]="'Keywords' | translate" (tabClick)="changeActiveTab('keywords')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let keyword of keywords"
            [active]="isActive('keywords', keyword)"
            (onIncludeClick)="onIncludeClick(keyword)"
            (onIncludeRemove)="onIncludeRemove(keyword)"
            [isExcludeShow]="false"
            class="aayn-audience--detailed-targeting--overlay-tiktok"
          >
            {{ keyword.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'CustomAudience' | translate" (tabClick)="changeActiveTab('customAudiences')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let audience of customAudiences | aynFilter: detailedTargetingSearchControl.value || ''"
            [active]="isActive('customAudiences', audience)"
            (onIncludeClick)="onIncludeClick(audience)"
            (onExcludeClick)="onCustomAudienceExclude(audience)"
            (onIncludeRemove)="onIncludeRemove(audience)"
            [isExcludeShow]="true"
          >
            {{ audience.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>
    </ayn-tab>
  </ayn-overlay>
</section>
