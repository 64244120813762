import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'locationSearch';

type Input = TikTok.CreateAd.Backend.LocationSearch;
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto[]>;

export const getLocationsQuery = makeQuery<Output, Input>(`
query LocationSearch($searchKey: String!, $objective: TikTokObjectiveType!) {
  tiktok {
    locationSearch(searchKey: $searchKey, objective: $objective){
      name
      level
      id
      parentId
      nextLevelIds
    }
  }
}
`);
