import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
    LinkedinFormService, LinkedinStateService, LinkedinValidationService
} from '@pages/create-ad/state/platforms/linkedin';

@UntilDestroy()
@Component({
  selector: 'aayn-traffic-to-website--linkedin',
  templateUrl: './traffic-to-website.component.html'
})
export class TraficToWebsiteLinkedinComponent implements OnInit {
  protected adCreationModel = this.linkedinStateService.adCreationModel;

  traficWebsiteForm = new FormGroup({
    name: new FormControl(this.adCreationModel.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private linkedinStateService: LinkedinStateService,
    private linkedinValidationService: LinkedinValidationService,
    private linkedinFormService: LinkedinFormService,
    private store: Store
  ) {}

  ngOnInit() {
    this.linkedinFormService.registerForm(this.traficWebsiteForm);

    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.linkedinValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.traficWebsiteForm.markAllAsTouched();
    });

    this.traficWebsiteForm.statusChanges.subscribe((status) => {
      this.linkedinValidationService.objectiveValid$.next(status === 'VALID');
    });
  }
}
