<section class="aayn-friend-invite">
  <ayn-badge
    class="ayn-layout--invite-friend"
    [color]="overlay.render ? 'accent' : 'primary'"
    [rounded]="true"
    (click)="overlay.toggle($event)"
  >
    <ayn-icon name="add-user"></ayn-icon>
    1 month free for every invitation!
  </ayn-badge>

  <ayn-overlay #overlay styleClass="aayn-friend-invite--body" [useOverlayBlur]="true">
    <button ayn-button [onlyIcon]="true" class="aayn-friend-invite--body-close" (click)="overlay.hide()">
      <ayn-icon name="exit"></ayn-icon>
    </button>
    <aayn-base-friend-invite></aayn-base-friend-invite>
  </ayn-overlay>
</section>
