import * as Query from '@core/queries';
import { environment } from '@environment';
import { map, scan } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bing } from '@core/models/platforms/bing';

import { Base, calculateState, GraphQLService, Upload } from '../';

@Injectable({ providedIn: 'root' })
export class BingService {
  constructor(private graphqlService: GraphQLService, private http: HttpClient) {}

  getOAuthUri$(contextId: string) {
    return this.graphqlService.post(Query.BingQ.oauthBing({ contextId })).pipe(map((result) => result.data?.oauth));
  }

  getAdAccounts$(contextId: string) {
    return this.graphqlService
      .post(Query.BingQ.adAccountsBing({ contextId }))
      .pipe(map((result) => result.data?.adAccounts));
  }

  connectAdAccount$(body: Bing.Business.IConnectAdAccountRequest) {
    return this.graphqlService
      .post(Query.BingQ.connectAdAccountBing(body))
      .pipe(map((result) => result.data?.connectAdAccount));
  }
  getBreakDown$<BT extends Bing.Dashboard.BreakdownType>(breakdownType: BT, payload: Bing.Dashboard.IBreakdownRequest) {
    return this.graphqlService.get(Query.BingQ.dashboard.breakdownsBing<BT>(breakdownType)(payload));
  }

  getCampaignsWithMetrics$(payload: Bing.Dashboard.ICampaignRequest) {
    return this.graphqlService
      .post(Query.BingQ.dashboard.campaignsWithMetricsBing(payload))
      .pipe(map((result) => result.data?.campaignsWithMetrics));
  }

  getCampaignsWithMetricsSmartSorting$(payload: Bing.Dashboard.ICampaignRequest) {
    return this.graphqlService
      .post(Query.BingQ.dashboard.campaignsWithMetricsSmartSortingBing(payload))
      .pipe(map((result) => result.data?.campaignsWithMetricsSmartSorting));
  }

  getCampaigns(payload: { ids: string[] }) {
    return this.graphqlService
      .get(Query.BingQ.dashboard.campaignsBing(payload))
      .pipe(map((result) => result.data?.campaigns));
  }

  getFunnelDashboard$(payload: Bing.Dashboard.IFunnelDashboardRequest) {
    return this.graphqlService
      .post(Query.BingQ.dashboard.funnelDashboardBing(payload))
      .pipe(map((result) => result.data?.funnelDashboard));
  }

  getAccountOverviewTotal$(payload: Bing.Dashboard.IAccountOverViewRequest) {
    return this.graphqlService
      .post(Query.BingQ.dashboard.accountOverviewTotalBing(payload))
      .pipe(map((result) => result.data?.accountOverview));
  }

  getAccountOverview$(payload: Bing.Dashboard.IAccountOverViewRequest) {
    return this.graphqlService
      .post(Query.BingQ.dashboard.accountOverviewBing(payload))
      .pipe(map((result) => result.data?.accountOverview));
  }

  createAdAccount$(body: Bing.Business.IBingCreatedAdAccountRequest) {
    return this.graphqlService
      .post(Query.BingQ.business.createAdAccountBing(body))
      .pipe(map((result) => result.data?.createAdAccount));
  }

  isCreationPossible$(body: Bing.Business.IAdAccountRequest) {
    return this.graphqlService
      .get(Query.BingQ.business.isCreationPossibleBing(body))
      .pipe(map((result) => result.data?.isCreationPossible));
  }

  createAudienceCampaign$({ audienceCampaign }: Bing.AudienceCampaign.AudienceCampaignRequest) {
    return this.graphqlService.post(Query.BingQ.createAd.createAudienceCampaign({ audienceCampaign }));
  }

  locationSearch$(body: Bing.AudienceCampaign.LocationSearchRequest) {
    return this.graphqlService
      .get(Query.BingQ.createAd.locationSearchBing(body))
      .pipe(map((result) => result.data?.locationSearch));
  }

  audienceCriteriaSearchBing$(body: Bing.AudienceCampaign.AudienceCriteriaSearchRequest) {
    return this.graphqlService
      .get(Query.BingQ.createAd.audienceCriteriaSearchBing(body))
      .pipe(map((result) => result.data?.audienceCriteriaSearch));
  }

  profileDataSearchBing$(body: Bing.AudienceCampaign.ProfileDataSearchRequest) {
    return this.graphqlService
      .get(Query.BingQ.createAd.profileDataSearchBing(body))
      .pipe(map((result) => result.data?.profileDataSearch));
  }

  uploadPerformanceMaxImages$(images: Partial<Bing.AudienceCampaign.IUploadPerformanceMaxImagesRequest>, type: string) {
    const formData = new FormData();
    Object.keys(images).forEach((key) => {
      const group = images[key] as Bing.AudienceCampaign.UploadGroupTuple;

      group.forEach((o) => {
        formData.append('image', o!);
      });
    });

    const initialState: Upload = { state: 'PENDING', progress: 0 };

    return this.http
      .post<Bing.AudienceCampaign.IUploadPerformanceMaxImagesResponse>(
        `${environment.apiUrl}/bing/upload-image/campaign/audience?type=${type}`,
        formData,
        {
          reportProgress: true,
          observe: 'events'
        }
      )
      .pipe(scan(calculateState, initialState));
  }

  getMinimumBudgetAmount$(body: Bing.AudienceCampaign.GetCurrencyConstantValueRequest) {
    return this.graphqlService.get(Query.BingQ.createAd.getCurrencyConstantValue(body));
  }

  getConversionGoals$() {
    return this.graphqlService.get(Query.BingQ.createAd.getConversionGoalsBing());
  }

  getGoogleCredentialIdDirectUrl$() {
    return this.graphqlService.get(Query.BingQ.createAd.getGoogleCredentialIdDirectUrl());
  }

  getGoogleCredentialId$() {
    return this.graphqlService.get(Query.BingQ.createAd.getGoogleCredentialId());
  }

  turnOnResource$(request: Bing.Dashboard.ITurnResourceRequest) {
    return this.graphqlService.post(Query.BingQ.dashboard.turnOnCampaignsBing(request));
  }

  turnOffResource$(request: Bing.Dashboard.ITurnResourceRequest) {
    return this.graphqlService.post(Query.BingQ.dashboard.turnOffCampaignsBing(request));
  }

  importGoogleCampaigns$(request: Bing.AudienceCampaign.BingGoogleCampaignImport) {
    return this.graphqlService.post(Query.BingQ.createAd.importGoogleCampaigns(request));
  }

  editCampaignBudgets$(request: Bing.Dashboard.IBingEditCampaignBudgetsRequest) {
    return this.graphqlService.post(Query.BingQ.dashboard.editCampaignBudgetsBing(request));
  }

  editCampaign$(request: Bing.Dashboard.IBingEditCampaignRequest) {
    return this.graphqlService.post(Query.BingQ.dashboard.editCampaignBing(request));
  }

  editAdGroup$(request: Bing.Dashboard.IBingEditAdGroupRequest) {
    return this.graphqlService.post(Query.BingQ.dashboard.editAdGroupBing(request));
  }

  adGroupsWithMetrics$(body: Bing.Dashboard.IAdGroupsWithMetricsRequest) {
    return this.graphqlService.get(Query.BingQ.dashboard.adGroupsWithMetricsBing(body));
  }

  adsWithMetrics$(body: Bing.Dashboard.IAdsWithMetricsRequest) {
    return this.graphqlService.get(Query.BingQ.dashboard.adsWithMetricsBing(body));
  }

  getAccountImages(filter: Base.CreateAd.ImageAssetFilter) {
    return this.graphqlService
      .get(Query.BingQ.createAd.getAccountImages({ filter }))
      .pipe(map((result) => result.data?.accountImages));
  }
}
