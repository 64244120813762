import { Settings } from '@core/models';

import { makeMutation } from '../types';

type Output = Settings.BrandingOutput;

export const deleteCustomBranding = makeMutation<Output>(`
mutation DeleteCustomBranding {
  deleteCustomBranding {
    mainColor
    additionalColor
    paragraphColor
    backgroundColor
  }
}
`);
