import { makeQuery } from '../types';
import { SubAccountsOutput } from '@core/models';

type Output = SubAccountsOutput[];

export const listSubAccounts = makeQuery<Output>(`
query ListSubAccounts {
  listSubAccounts {
   email
    name
    surname
    status 
    id
  }
}
`);
