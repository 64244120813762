import { environment } from '@environment';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { LazyAssetService } from './lazy-asset.service';

declare var CanvaApi: any;
declare var Canva: any;

export interface ICanvaOnDesignPublishOptions {
  designId: string;
  designTitle: string;
  exportUrl: string;
}

@Injectable({ providedIn: 'root' })
export class CanvaService {
  private _sdkScriptSource: string = 'https://sdk.canva.com/designbutton/v2/api.js';

  private _hasInitialized = false;

  constructor(private lazyAssetService: LazyAssetService) {}

  private _loadSdk(): Observable<void> {
    return this.lazyAssetService.loadScript(this._sdkScriptSource);
  }

  private async _initSdk(): Promise<void> {
    await this._initWidget();
  }

  // Init Widget
  private async _initWidget(): Promise<void> {
    const api = await Canva.DesignButton.initialize({
      apiKey: environment.canvaApiKey
    });

    window['CanvaApi'] = api;
  }

  getCanvaApi(): any {
    return CanvaApi;
  }

  // Setup SDK
  async setupSdk() {
    if (this._hasInitialized) return;

    await this._loadSdk().toPromise();

    await this._initSdk();

    this._hasInitialized = true;
  }
}
