import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  LoadedImage,
  OutputFormat
} from 'ngx-image-cropper';

import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { blobToFile } from '@core/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AspectRatio } from '@shared/models';
import { parseAspectRatio, runIsDev } from '@shared/utils';

import { MoveStart, MoveTypes } from './model';
import { Options } from '@angular-slider/ngx-slider';

type ResizePosition = 'top' | 'right' | 'bottom' | 'left' | 'topright' | 'bottomright' | 'bottomleft' | 'topleft';

function removeFileExtension(value: string) {
  return value.replace(/\.[^/.]+$/, '');
}

@Component({
  selector: 'aayn-image-crop',
  templateUrl: './image-crop.component.html'
})
export class ImageCropComponent implements OnInit {
  public aspectRatio: number | null = 1 / 1;
  public aspectRatioLabel = '1:1 Square Images';

  public format: OutputFormat = 'png';
  private _fileName = '';

  public imageUrl?: string;

  public maintainAspectRatio = true;

  public maxTotalPixels = 0;

  public set maxImageSize(value: { width: number; height?: number }) {
    this.maxImage = value;
    this.triggerCrop();
  }

  public set minImageSize(value: { width: number; height: number }) {
    this.minImage = value;
    this.triggerCrop();
  }

  public set fileName(value: string) {
    this._fileName = removeFileExtension(value);
  }

  public get fileName() {
    return this._fileName;
  }

  public ratios: AspectRatio.AspectRatioItem[] = [];

  public ratioSelect$ = new EventEmitter<AspectRatio.AspectRatioItem>();

  public isScalable = false;
  public sliderOptions: Options = {
    step: 0.1,
    ceil: 1,
    floor: 0.1,
    hidePointerLabels: true,
    showSelectionBar: true
  };

  @ViewChild(ImageCropperComponent, { static: false }) imageCropperComponent!: ImageCropperComponent;
  cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000
  };

  cropperOld?: CropperPosition;

  transform: ImageTransform = {
    scale: 1
  };

  protected maxImage: {
    width: number;
    height?: number;
  } = {
    width: 0
  };

  protected minImage = {
    width: 0,
    height: 0
  };

  get ratio() {
    return typeof this.selectedRatio?.ratio === 'number' ? this.selectedRatio.ratio : this.aspectRatio || 1;
  }
  get selectedRatio(): AspectRatio.AspectRatioItem<number> | undefined {
    const selected = this.ratios.find((x) => x.selected);
    if (selected) {
      return { ...selected, ratio: selected.ratio ? parseAspectRatio(selected.ratio) : selected.ratio };
    }
    return;
  }

  constructor(public modal: NgbActiveModal, private sanitizer: DomSanitizer, protected cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  imageCropped(event: ImageCroppedEvent) {
    const croppedImage = blobToFile(event.blob!, `${this._fileName}__croppedImage_${Date.now()}.${this.format}`);

    const croppedImageUrl = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || '');
    runIsDev(() => {
      console.log(
        `ImageCrop.CroppedImage: w: ${event.width}, h: ${event.height}, ratio: ${event.width / event.height}, size ${
          croppedImage.size
        } bytes`
      );
      if (event.width / event.height !== this.aspectRatio) {
        console.warn(`ImageCrop.CroppedImage: Aspect ratio is not equal to ${this.aspectRatio}`);
      }
    });
    return {
      croppedImage,
      croppedImageUrl
    };
  }

  imageLoaded(image: LoadedImage) {}

  cropperReady(dimensions: Dimensions) {
    const moveImg = this.imageCropperComponent.moveImg;

    if (this.maxTotalPixels || this.maintainAspectRatio) {
      const { width, height } = this.imageCropperComponent['loadedImage'].original.size;
      const totalPixels = width * height;
      if (totalPixels > this.maxTotalPixels || this.maintainAspectRatio) {
        this.imageCropperComponent.moveImg = (event) => {
          moveImg.call(this.imageCropperComponent, event);
          const moveStart: MoveStart = this.imageCropperComponent['moveStart'];
          if (moveStart.type === MoveTypes.Resize && moveStart.active) {
            this.calculateCropper(moveStart, width, height, dimensions);
          }

          this.cdr.detectChanges();
        };
      }

      this.triggerCrop();
    }
  }

  triggerCrop() {
    this.imageCropperComponent?.startMove({ clientX: 0, clientY: 0 }, MoveTypes.Resize, 'top');
    this.imageCropperComponent?.moveImg.call(this.imageCropperComponent, { clientX: 0, clientY: 0 });
    this.imageCropperComponent?.moveStop();
  }

  loadImageFailed() {
    runIsDev(() => {
      console.warn('ImageCrop.LoadImageFailed');
    });
  }

  calculateCropper(moveStart: MoveStart, width: number, height: number, dimensions: Dimensions) {
    const ratioW = width / dimensions.width;
    const ratioH = height / dimensions.height;

    const cropperWidth = this.cropper.x2 - this.cropper.x1;
    const cropperHeight = this.cropper.y2 - this.cropper.y1;
    const actualCropperWidth = cropperWidth * ratioW;
    const actualCropperHeight = cropperHeight * ratioH;
    const cropperArea = actualCropperWidth * actualCropperHeight;

    if (this.maxTotalPixels && cropperArea > this.maxTotalPixels) {
      this.decreaseCropperSize(moveStart, ratioW, ratioH);
    }
  }

  decreaseCropperSize(moveStart: MoveStart, ratioW: number, ratioH: number) {
    switch (moveStart.position as ResizePosition) {
      case 'top':
        this.cropper.y1 = this.cropper.y2 - Math.sqrt(this.maxTotalPixels) / ratioH;
        break;
      case 'right':
        this.cropper.x2 = this.cropper.x1 + Math.sqrt(this.maxTotalPixels) / ratioW;
        break;
      case 'bottom':
        this.cropper.y2 = this.cropper.y1 + Math.sqrt(this.maxTotalPixels) / ratioH;
        break;
      case 'left':
        this.cropper.x1 = this.cropper.x2 - Math.sqrt(this.maxTotalPixels) / ratioW;
        break;
      case 'topright':
        this.cropper.y1 = this.cropper.y2 - Math.sqrt(this.maxTotalPixels) / ratioH;
        this.cropper.x2 = this.cropper.x1 + Math.sqrt(this.maxTotalPixels) / ratioW;
        break;
      case 'bottomright':
        this.cropper.x2 = this.cropper.x1 + Math.sqrt(this.maxTotalPixels) / ratioW;
        this.cropper.y2 = this.cropper.y1 + Math.sqrt(this.maxTotalPixels) / ratioH;
        break;
      case 'bottomleft':
        this.cropper.y2 = this.cropper.y1 + Math.sqrt(this.maxTotalPixels) / ratioH;
        this.cropper.x1 = this.cropper.x2 - Math.sqrt(this.maxTotalPixels) / ratioW;
        break;
      case 'topleft':
        this.cropper.y1 = this.cropper.y2 - Math.sqrt(this.maxTotalPixels) / ratioH;
        this.cropper.x1 = this.cropper.x2 - Math.sqrt(this.maxTotalPixels) / ratioW;
        break;
    }
  }

  async cropAndSave() {
    const event = await this.imageCropperComponent.crop('blob');
    const { croppedImage, croppedImageUrl } = this.imageCropped(event!);
    if (!croppedImage) return;

    this.modal.close(croppedImage);
  }

  selectRatio(ratio: AspectRatio.AspectRatioItem) {
    this.ratios.forEach((x) => (x.selected = false));
    ratio.selected = true;
    if (!this.selectedRatio?.ratio) {
      const { width, height } = this.imageCropperComponent.maxSize;
      this.cropper = {
        x1: 0,
        x2: width,
        y1: 0,
        y2: height
      };
    }
    this.ratioSelect$.emit(ratio);
    this.cdr.detectChanges();
  }

  sliderValueChange(scale: number) {
    this.transform = {
      ...this.transform,
      scale
    };
  }
}
