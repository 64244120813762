import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.IBingEditCampaignRequest;
type Output = { editableCampaigns: boolean };

export const editCampaignBing = makeMutation<Output, Input>(`
mutation EditCampaign($editableCampaigns: [BingEditableCampaign!]!) {
  bing {
    editCampaign(editableCampaigns: $editableCampaigns)
  }
}
`);
