import { makeMutation, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Input = SmartCampaign.Backend.EditSmartCampaignStatusInput;

type Output = QueryResult<'editSmartSingleCampaignStatuses', SmartCampaign.Backend.SmartCampaignEditStatusOutputDto[]>;

export const editStatus = makeMutation<Output, Input>(`
mutation EditSmartCampaignStatus(
  $smartCampaignId: String!
  $campaignStatuses: [SmartSingleCampaignStatusDto!]!
) {
  ayn {
    editSmartSingleCampaignStatuses(
      smartCampaignId: $smartCampaignId
      campaignStatuses: $campaignStatuses
    ) {
      platform
      success
      reason
    }
  }
}

`);
