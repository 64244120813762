<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__active': overlay?.render,
    'aayn-audience--location-selection__has-error': false
  }"
>
  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      ayn-input
      [required]="isAnyPlatformNotSelected"
      [placeholder]="'Type to search for location' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />

    <span class="aayn-audience--location-selection--input-label">
      All Platforms

      <ayn-icon name="map-marker"></ayn-icon>
    </span>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay __for-smart-campaign"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length"
  >
    <aayn-location-selection-overlay-list *ayn-loader="(loading$ | async) || (loader$ | async); classname: 'mt-3 mb-3'">
      <aayn-location-selection-overlay-item
        *ngFor="let location of locationPredictions.predictions"
        [canExclude]="false"
        [includeTexts]="{ includeText: 'Select', includedText: 'Selected' }"
        (onIncludeClick)="onIncludeClick(location)"
        [active]="{ isActive: showLocationPredictionLoader(location), type: 'inclusions' }"
        [showLoader]="showLocationPredictionLoader(location)"
      >
        {{ location.description }}
      </aayn-location-selection-overlay-item>
    </aayn-location-selection-overlay-list>
  </ayn-overlay>
</section>

<section class="aayn-audience--location-selection--tabs">
  <ayn-tab
    tab-type="ayn-tab--primary"
    [aayn-smart-response-catcher]="adCreationModel.audience.geoLocations.inclusions"
    [selectedPlatforms]="adCreationModel.selectedPlatforms"
    [validateFn]="validateLocationSelection"
  >
    <ayn-tab-item
      *ngFor="let platform of adCreationModel.selectedPlatforms"
      [tabTitle]="PlatformKeyByValues[platform]"
      [icon]="PlatformIcons[platform]"
    >
      <aayn-location-selection--add-more--smart-campaign
        [platform]="platform"
        [form]="form"
      ></aayn-location-selection--add-more--smart-campaign>
    </ayn-tab-item>
  </ayn-tab>
</section>
