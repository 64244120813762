import { switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { PlatformContextService } from '@core/services/platform-context.service';
import { updateItemInArrayByIndex } from '@core/utils/array.utils';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';

import {
  ACTIVE_PLATFORMS,
  ContextStatus,
  IPlatformContext,
  PlatformConfig,
  PlatformContextMappedType,
  Platforms
} from '../';
import { of } from 'rxjs';

export namespace PlatformContext {
  export interface State {
    contexts: IPlatformContext[];
  }
}

export class GetPlatformContexts {
  static readonly type = '[PlatformContext] Get PlatformContexts';
  static readonly desc = 'Get Platform Contexts';
  constructor() {}
}

export class UpdatePlatformStatus {
  static readonly type = '[PlatformContext] Update PlatformContext';
  static readonly desc = 'Update Platform Context';
  constructor(public payload: Pick<IPlatformContext, 'source' | 'status'>) {}
}

export const PLATFORM_CONTEXTS_DEFAULT: PlatformContext.State = {
  contexts: []
};

@State<PlatformContext.State>({
  name: 'PlatformContextState',
  defaults: PLATFORM_CONTEXTS_DEFAULT
})
@Injectable()
export class PlatformContextState {
  constructor(private platformContextService: PlatformContextService) {}

  @Selector()
  static PlatformContextsMapped({ contexts }: PlatformContext.State): PlatformContextMappedType {
    return contexts
      .filter((c) => PlatformConfig.some((pc) => pc.value === c.source && pc.isActive))
      .reduce((platformContext, cur: IPlatformContext) => {
        return {
          ...platformContext,
          [Platforms[cur.source]]: cur
        };
      }, {} as PlatformContextMappedType);
  }

  @Selector()
  static PlatformContexts({ contexts }: PlatformContext.State) {
    return contexts;
  }

  static getPlatformContext(platform: Platforms) {
    return createSelector([PlatformContextState], ({ contexts }: PlatformContext.State) =>
      contexts.find((o) => o.source === platform)
    );
  }

  @Selector()
  static ConnectedPlatformContexts({ contexts }: PlatformContext.State) {
    return contexts.filter((o) => o.status === ContextStatus.Connected);
  }

  @Selector()
  static RequireReconnectionPlatforms({ contexts }: PlatformContext.State) {
    return contexts.filter((o) => o.status == ContextStatus.RequireReConnect);
  }

  @Action(GetPlatformContexts)
  get({ setState }: StateContext<PlatformContext.State>) {
    return this.platformContextService.get$().pipe(
      switchMap((response) => {
        if (
          ACTIVE_PLATFORMS.some((activePlatform) =>
            response.data.every((context) => context.source !== activePlatform.value)
          )
        ) {
          return this.platformContextService
            .createUnownedPlatformContexts$()
            .pipe(switchMap(() => this.platformContextService.get$()));
        }
        return of(response);
      }),
      tap(({ data: contexts }) => setState({ contexts }))
    );
  }

  @Action(UpdatePlatformStatus)
  updatePlatformStatus({ setState, getState }: StateContext<PlatformContext.State>, { payload }: UpdatePlatformStatus) {
    const { contexts } = getState();
    const platformIndex = contexts.findIndex((o) => o.source === payload.source);

    if (platformIndex > -1) {
      const newContexts = updateItemInArrayByIndex(contexts, platformIndex, (v) => ({ ...v, status: payload.status }));

      setState({
        contexts: newContexts
      });
    }
  }
}
