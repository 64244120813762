import { Google } from '@core/models';
import { QueryResult, makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.GooglePMCampaignRequest;
type Output = QueryResult<'createPMCampaign', boolean>;

export const createPMCampaign = makeMutation<Output, Input>(`
  mutation CreatePMCampaign($campaign: GooglePMCampaignDto!) {
    google {
      createPMCampaign(campaign: $campaign)
    }
  }
`);
