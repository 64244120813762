<div [ngSwitch]="selectedAdType">
  <div *ngSwitchCase="AdTypes.SingleImage">
    <aayn-single-ad-preview--linkedin
      [businessName]="businessName"
      [profileImage]="organizationImage"
    ></aayn-single-ad-preview--linkedin>
  </div>

  <div *ngSwitchCase="AdTypes.CarouselImage">
    <aayn-carousel-ad-preview--linkedin
      [businessName]="businessName"
      [profileImage]="organizationImage"
    ></aayn-carousel-ad-preview--linkedin>
  </div>
</div>
