<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  [adPreviewContainer]="adPreviewService.carouselAds"
  aaynTextAiContainer
>
  <aayn-text-generator-ai></aayn-text-generator-ai>

  <section class="aayn-ad-type--carousel-container" cdkDropList (aynDrag)="dropped($event)" dragControl>
    <ng-container *ngFor="let carouselCard of carouselCards.controls; index as i">
      <form [formGroup]="$any(carouselCard)" class="aayn-ad-type--carousel-container--form">
        <aayn-carousel-card
          [saveDisabled]="carouselCard.invalid"
          [removeDisabled]="i < 2"
          (remove)="deletePaginationItem(i)"
          [collapsed]="!carouselCard.invalid && !carouselCard.dirty"
          adPreview="cards"
        >
          <ayn-file-upload-area
            [imageCount]="1"
            (fileUploadClick)="openFileModal()"
            title="Click this area to UPLOAD"
            description="Linkedin maximum pixel count is 36152320"
            [imageUrls]="carouselCard.get('imageUrl')?.value ? [carouselCard.get('imageUrl')?.value] : []"
            [single]="true"
            [showRequiredAlert]="!carouselCard.get('imageUrl')?.value && _form.submitted"
            adPreview="image"
          >
          </ayn-file-upload-area>
          <aayn-create-ad-input
            [placeholder]="'Write a short headline' | translate"
            [requiredMessage]="'You must write at least 1 description.' | translate"
            formControlName="headline"
            [maxCharacterNumber]="70"
            adPreview="body"
            textAiSection="headlines"
          >
          </aayn-create-ad-input>
        </aayn-carousel-card>
      </form>
    </ng-container>
    <button
      class="aayn-carousel-add-more"
      type="button"
      (click)="addPaginationItem()"
      [disabled]="carouselCards.invalid"
    >
      {{ 'Add More Carousel Card +' | translate }}
    </button>
  </section>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Title for ad" description="Write title for ad">
      <aayn-create-ad-input
        [maxCharacterNumber]="100"
        [requiredMessage]="'You must write at least 1 description.' | translate"
        [placeholder]="'Tell people what your ad is about' | translate"
        formControlName="title"
        adPreview="title"
        [ngModel]="adCreationModel.ad.creative.commentary"
        textAiSection="descriptions"
        [standAlone]="true"
        [previewActive]="true"
      ></aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Destination URL" description="Add your destination URL.">
      <ayn-form-field class="aayn-ad-type__mt-spacing">
        <div class="ayn-prefix">
          {{ 'URL' | translate }}
        </div>

        <input type="text" placeholder="https://adyouneed.com" formControlName="landingPageUrl" ayn-input #urlInput />
      </ayn-form-field>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Ad name" description="Write your ad name">
      <ayn-form-field class="aayn-ad-type--control">
        <input type="text" ayn-input [placeholder]="'Ad Name' | translate" formControlName="name" />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
