import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'changeSentiment';
type Output = QueryResult<typeof queryField, string>;

export const changeSentimentQuery = makeQuery<Output, OpenAI.ChangeSentimentInput>(`
query ChangeSentiment($content: String!, $productName: String!, $productDescription: String!, $textLimit: Float!, $tone:String){
  openAI {
    ${queryField}(content: $content, productName: $productName, productDescription: $productDescription, textLimit: $textLimit, tone: $tone)
  }
}
`);
