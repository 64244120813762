import { createObjectURL, revokeObjectURL } from '@core/utils/url';
import { fromEvent, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';

function getExtension(name: string) {
  return name.split('.').pop();
}

/**
 * Get extension by file name or File.
 * @param fileOrName { File | string }
 * @returns {string}
 */
export function getFileExtension(fileOrName: File | string) {
  if (fileOrName instanceof File) {
    return getExtension(fileOrName.name);
  }

  return getExtension(fileOrName);
}

export function getFileWidthAndHeight(file: File) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      revokeObjectURL(img.src);
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = createObjectURL(file);
  });
}

export function scaleDownImage(file: HTMLImageElement | File | Blob, newWidth: number, newHeight: number) {
  const getCanvasOptions = (img: HTMLImageElement) => {
    const oldWidth = img.width;
    const oldHeight = img.height;

    if (oldWidth <= newWidth && oldHeight <= newHeight) {
      return { x: 0, y: 0, width: oldWidth, height: oldHeight };
    }

    const { offsetX, offsetY, scaledWidth, scaledHeight } = calculateScale(oldWidth, oldHeight, newWidth, newHeight);
    return {
      x: offsetX,
      y: offsetY,
      width: newWidth,
      height: newHeight,
      drawImageWidth: scaledWidth,
      drawImageHeight: scaledHeight
    };
  };
  return toCanvas(file, getCanvasOptions);
}

export function scaleImage(
  file: HTMLImageElement | File | Blob,
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number }
): Observable<Blob> {
  const getCanvasOptions = (img: HTMLImageElement) => {
    const oldWidth = img.width;
    const oldHeight = img.height;
    const { scaledWidth, scaledHeight } = calculateScale(oldWidth, oldHeight, maxWidth, maxHeight);
    return {
      x: 0,
      y: 0,
      width: scaledWidth,
      height: scaledHeight
    };
  };
  return toCanvas(file, getCanvasOptions);
}

export function isSvg(file: File | Blob) {
  return file.type === 'image/svg+xml';
}

export function scaleImageDown(
  file: HTMLImageElement | File | Blob,
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number }
) {
  const getCanvasOptions = (img: HTMLImageElement) => {
    const oldWidth = img.width;
    const oldHeight = img.height;
    if (oldWidth <= maxWidth && oldHeight <= maxHeight) {
      return { x: 0, y: 0, width: oldWidth, height: oldHeight };
    }
    const { scaledWidth, scaledHeight } = calculateScale(oldWidth, oldHeight, maxWidth, maxHeight);
    return {
      x: 0,
      y: 0,
      width: scaledWidth,
      height: scaledHeight
    };
  };
  return toCanvas(file, getCanvasOptions);
}

export function resizeImage(
  file: HTMLImageElement | File | Blob,
  newWidth: number,
  newHeight: number
): Observable<Blob> {
  const getCanvasOptions = (img: HTMLImageElement) => {
    const oldWidth = img.width;
    const oldHeight = img.height;
    const { offsetX, offsetY, scaledWidth, scaledHeight } = calculateScale(oldWidth, oldHeight, newWidth, newHeight);
    return {
      x: offsetX,
      y: offsetY,
      width: newWidth,
      height: newHeight,
      drawImageWidth: scaledWidth,
      drawImageHeight: scaledHeight
    };
  };
  return toCanvas(file, getCanvasOptions);
}

function calculateScale(oldWidth: number, oldHeight: number, newWidth: number, newHeight: number) {
  const oldRatio = oldWidth / oldHeight;
  const newRatio = newWidth / newHeight;

  let offsetX = 0;
  let offsetY = 0;
  let scaledWidth = newWidth;
  let scaledHeight = newHeight;
  if (newRatio > oldRatio) {
    scaledHeight = newHeight;
    scaledWidth = oldWidth * (newHeight / oldHeight);
    offsetX = (newWidth - scaledWidth) / 2;
  } else if (newRatio < oldRatio) {
    scaledWidth = newWidth;
    scaledHeight = oldHeight * (newWidth / oldWidth);
    offsetY = (newHeight - scaledHeight) / 2;
  }
  return { offsetX, offsetY, scaledWidth, scaledHeight };
}

export function toImageElement(image: File | Blob) {
  return new Observable<HTMLImageElement>((observer) => {
    const img = new Image();

    const reader = new FileReader();
    reader.onload = () => {
      img.src = reader.result as string;
      observer.next(img);
      observer.complete();
    };
    reader.readAsDataURL(image);
  });
}

export function imageLoad$(file: File | Blob | HTMLImageElement) {
  const stream = file instanceof HTMLImageElement ? of(file) : toImageElement(file);
  return stream.pipe(
    switchMap((img) => (img.complete ? of(img) : fromEvent(img, 'load').pipe(mapTo(img)))),
    take(1)
  );
}

export function toCanvas(
  image: File | Blob | HTMLImageElement,
  getCanvasOptions: (
    img: HTMLImageElement,
    canvas?: HTMLCanvasElement
  ) => {
    x: number;
    y: number;
    width: number;
    height: number;
    drawImageWidth?: number;
    drawImageHeight?: number;
    type?: string;
  }
) {
  return imageLoad$(image).pipe(
    map((img) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Canvas context not available');
      }

      const { x, y, width, height, drawImageWidth, drawImageHeight, type } = getCanvasOptions(img, canvas);
      canvas.width = width;
      canvas.height = height;
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'rgba(0, 0, 0, 0)';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img as HTMLImageElement, x, y, drawImageWidth || width, drawImageHeight || height);
      const imageType = type || (image instanceof File || image instanceof Blob ? image.type : 'image/png');

      return { canvas, type: imageType };
    }),
    switchMap(({ canvas, type }) => {
      return new Observable<Blob>((observer) => {
        canvas.toBlob((blob) => {
          if (blob) {
            observer.next(blob);
            observer.complete();
          } else {
            observer.error('Error generating blob');
          }
        }, type);
      });
    })
  );
}

export function toPng(image: Blob) {
  return imageLoad$(image).pipe(
    switchMap((img) => {
      const isPng = image.type === 'image/png';
      let stream = of(image);
      if (isPng) {
        return stream;
      }
      return stream.pipe(
        switchMap((blob) => {
          const getCanvasOptions = (img: HTMLImageElement) => ({
            x: 0,
            y: 0,
            width: img.width,
            height: img.height,
            type: 'image/png'
          });
          return toCanvas(blob, getCanvasOptions);
        })
      );
    })
  );
}

export function toIco(image: Blob) {
  return imageLoad$(image).pipe(
    switchMap((img) => {
      const isIco = image.type === 'image/x-icon';
      let stream = of(image);
      if (isIco) {
        return stream;
      }
      return stream.pipe(
        switchMap((blob) => {
          const getCanvasOptions = () => ({
            x: 0,
            y: 0,
            width: img.width,
            height: img.height,
            type: 'image/x-icon'
          });
          return toCanvas(blob, getCanvasOptions);
        })
      );
    })
  );
}
