import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'breakdowns';
type Input = TikTok.Dashboard.TikTokBreakdownsRequest;
type Output = QueryResult<typeof queryField, TikTok.Dashboard.TikTokBreakdownsOutputDto[]>;

export const breakdownsQuery = makeQuery<Output, Input>(`
query GetBreakdowns(
  $startDate: SafeInt!
  $endDate: SafeInt!
  $identifier: TikTokAudienceDimension!
) {
  tiktok {
    ${queryField}(
      startDate: $startDate
      endDate: $endDate
      identifier: $identifier
    ) {
      clickThroughRate
      costPerClick
      impressions
      spend
      result
      identifier
      costPerResult
    }
  }
}
`);
