import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleLocationConstantRequest;
type Output = Google.CreateAd.IGoogleLocationConstantResponse;

export const locationSearch = makeMutation<Output, Input>(`
query LocationSearch($limit: Float, $searchKey: String) {
  google {
    locationSearch(limit: $limit, searchKey: $searchKey) {
      fullName
      id
      code
      type
    }
  }
}

`);
