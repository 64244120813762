<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Conversions on my website' | translate }}</h4>
    <p class="aayn-select-objective--header__description">
      {{ 'For receiving conversions on your website, must have Facebook Pixel installed.' | translate }}
    </p>
  </header>
  <form [formGroup]="form">
    <aayn-ad-form-input label="Facebook" platform="meta">
      <div class="aayn-select-objective--row">
        <ayn-form-field>
          <ayn-select
            formControlName="pixelId"
            [placeholder]="'Select a pixel' | translate"
            [(ngModel)]="adCreationModel.adSet.promotedObject!.pixelId"
            [panelMaxHeight]="220"
          >
            <ayn-option *ngFor="let pixel of adPixels | aynOptionFilter | async" [value]="pixel.id">
              {{ pixel.name }}
            </ayn-option>

            <ayn-option [disabled]="true" value="-1" *ngIf="!adPixels.length">
              There is no Facebook pixel in your account.
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <ayn-select
            formControlName="conversionEvent"
            [placeholder]="'Select a conversion event' | translate"
            (ngModelChange)="onChangedSelectedConversionEvent($event)"
            [panelMaxHeight]="220"
          >
            <ayn-option
              *ngFor="let conversionEvent of conversionEvents | aynOptionFilter | async"
              [value]="conversionEvent"
            >
              {{ conversionEvent.name | aynTitleCase }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>
      </div>
    </aayn-ad-form-input>

    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="campaignName"
          aaynDraftName
          [placeholder]="'Campaign Name' | translate"
          [(ngModel)]="adCreationModel!.campaign.name"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
