import { Facebook } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = { payload: Facebook.IConnectAdAccountRequest };
type Output = Facebook.IConnectAdAccountResponse;

export const connectAdAccount = makeMutation<Output, Input>(`
  mutation FacebookConnectAdAccount($payload: ConnectFacebookAdAccountDto!) {
    facebook {
      connectAdAccount(payload: $payload){
        businessId
        connectedAdAccountId
        platform
      }
    }
  }
`);
