import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { delay, startWith } from 'rxjs/operators';
import { IconModule } from '../icon';

@Directive({
  selector: 'ng-template[ayn-carousel-item]'
})
export class CarouselItemDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Component({
  selector: 'ayn-carousel',
  templateUrl: './carousel.html'
})
export class CarouselComponent implements OnChanges, AfterViewInit {
  @ContentChildren(CarouselItemDirective, { descendants: true }) carouselItems!: QueryList<CarouselItemDirective>;
  @ViewChild('slideContainer') slideContainer!: ElementRef<HTMLDivElement>;
  @Input() itemsPerSlide = 2;
  @Input() previewType: 'full-image' | 'ordered' = 'full-image';
  @Input() isDynamicHeight = false;
  @Input() activeIndex = 0;
  @Output() activeIndexChange = new EventEmitter<number>();
  slideChunks: Array<CarouselItemDirective[]> = [];
  slideTranslatePercentage: number = 0;
  transform: string = 'translateX(0%)';

  constructor(private elRef: ElementRef<HTMLElement>, private cdr: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnInit(): void {}

  chunkArray<T>(array: T[], size: number): Array<T[]> {
    const chunks: any[] = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  prevSlide(): void {
    const index = this.activeIndex === 0 ? this.carouselItems.length - 1 : this.activeIndex - 1;
    this.goToSlide(index);
    this.updateSlideTransform();
    this.updateContainerHeight();
  }

  nextSlide(): void {
    const index = this.activeIndex === this.carouselItems.length - 1 ? 0 : this.activeIndex + 1;
    this.goToSlide(index);
  }

  goToSlide(index: number): void {
    this.activeIndex = index;
    this.activeIndexChange.emit(this.activeIndex);
    this.updateSlideTransform();
    this.updateContainerHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeIndex) {
      this.goToSlide(changes.activeIndex.currentValue);
    }
  }

  ngAfterViewInit() {
    this.carouselItems.changes
      .pipe(startWith<QueryList<CarouselItemDirective>>(this.carouselItems), delay(100))
      .subscribe((items) => {
        this.slideChunks = this.chunkArray(items.toArray(), this.itemsPerSlide);
        this.cdr.detectChanges();
        this.calculateSlideWidthOverflow();
        this.updateContainerHeight();
      });
  }

  updateSlideTransform(): void {
    const percentage = -1 * this.activeIndex * this.slideTranslatePercentage;
    this.transform = `translateX(${percentage}%)`;
  }

  updateContainerHeight() {
    if (this.isDynamicHeight) {
      const slideContainer = this.elRef.nativeElement.querySelector<HTMLDivElement>('.ayn-slides-container');
      slideContainer!.style.height = 'auto';
      const activeSlide = slideContainer!.querySelectorAll<HTMLElement>('.ayn-carousel-slide')![
        this.activeIndex
      ] as HTMLElement;
      if (activeSlide && activeSlide.offsetHeight) {
        slideContainer!.style.height = `${activeSlide.offsetHeight}px`;
      } else {
        setTimeout(() => this.updateContainerHeight(), 100);
      }
    }
  }

  calculateSlideWidthOverflow(): void {
    const containerWidth = this.slideContainer.nativeElement.scrollWidth;
    const carouselWidth = this.slideContainer.nativeElement.clientWidth;
    const firstChild = this.slideContainer.nativeElement.querySelector<HTMLElement>('.ayn-carousel-slide')!
      .children[0] as HTMLElement;
    if (!firstChild) {
      return;
    }
    const firstChildStyle = getComputedStyle(firstChild);
    const firstChildMarginLeft = parseInt(firstChildStyle.marginLeft);
    const firstChildMarginRight = parseInt(firstChildStyle.marginRight);
    const sliderWidth = firstChild.offsetWidth + firstChildMarginLeft + firstChildMarginRight;
    const ratio = containerWidth / carouselWidth;
    this.slideTranslatePercentage = ((sliderWidth * 100) / containerWidth) * ratio;
  }
}

@NgModule({
  declarations: [CarouselComponent, CarouselItemDirective],
  exports: [CarouselComponent, CarouselItemDirective],
  imports: [CommonModule, IconModule]
})
export class CarouselModule {}
