<ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjective?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
    <div *ngSwitchCase="Platforms.Google">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjectiveGoogle?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
    <div *ngSwitchCase="Platforms.LinkedIn">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjectiveLinkedin?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
    <div *ngSwitchCase="Platforms.Bing">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjectiveBing?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
    <div *ngSwitchCase="Platforms.TikTok">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjectiveTiktok?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
    <div *ngSwitchCase="Platforms.SmartCampaign">
      <aayn-select-an-objective--header
        [objectiveType]="selectedObjectiveSmartCampaign?.title || ''"
      ></aayn-select-an-objective--header>
    </div>
  </div>
</ng-container>
