import { switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  IBusiness,
  ICreateBusinessRequest,
  IDeleteBusinessRequest,
  IUpdateBusinessRequest,
  Platforms
} from '@core/models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';

import { BusinessService, SetClickfraoudPlatform, SetPlatform } from '../';
import { DashboardState } from './dashboard.state';
import { of } from 'rxjs';

export namespace Business {
  export interface State {
    businesses: IBusiness[];
    selectedBusiness: IBusiness | null;
  }
}

export class CreateBusiness {
  static readonly type = '[Business] Create Business';
  static readonly desc = 'Create Business';

  constructor(public payload: ICreateBusinessRequest) {}
}

export class GetBusinesses {
  static readonly type = '[Businesses] GET Businesses';
  static readonly desc = 'Get Businesses From GQL';

  constructor() {}
}

export class UpdateBusiness {
  static readonly type = '[Business] Update Business';
  static readonly desc = 'Update Business';

  constructor(public payload: IUpdateBusinessRequest) {}
}

export class UpdateBusinessFromServer {
  static readonly type = '[Business] Update Business From Server';
  static readonly desc = 'Update Business From Server';

  constructor() {}
}

export class DeleteBusiness {
  static readonly type = '[Business] Delete Business';
  static readonly desc = 'Delete Business';

  constructor(public payload: IDeleteBusinessRequest) {}
}

export class SetSelectedBusiness {
  static readonly type = '[Business] Set Selected Business';
  static readonly desc = 'Set Selected Business';

  constructor(public payload: IBusiness | null) {}
}

export const BUSINESSES_DEFAULTS: Business.State = {
  businesses: [],
  selectedBusiness: null
};

@State<Business.State>({
  name: 'BusinessState',
  defaults: BUSINESSES_DEFAULTS
})
@Injectable()
export class BusinessState {
  constructor(private businessService: BusinessService, private store: Store) {}

  @Selector()
  static Businesses({ businesses }: Business.State): IBusiness[] {
    return businesses;
  }

  @Selector()
  static HasAnyBusiness({ businesses }: Business.State): boolean {
    return !!businesses?.length;
  }

  @Selector()
  static SelectedBusiness({ selectedBusiness }: Business.State) {
    return selectedBusiness;
  }

  static SelectedBusinessAdAccountByPlatform(platform: Platforms) {
    return createSelector([BusinessState], (state: Business.State) => {
      return state?.selectedBusiness?.connectedAdAccounts.find((acc) => acc.source === platform);
    });
  }

  @Selector()
  static SelectedBusinessHasAdAccount({ selectedBusiness }: Business.State): boolean {
    return !!selectedBusiness?.connectedAdAccounts?.length;
  }

  @Selector([DashboardState.getPlatform])
  static selectedBusinessActivePlatformCurrency(ctx: Business.State, selectedPlatform: Platforms) {
    return ctx?.selectedBusiness?.connectedAdAccounts?.find((o) => o.source == selectedPlatform)?.currency || '-';
  }

  @Selector([DashboardState.getPlatform])
  static selectedBusinessActivePlatform(ctx: Business.State, selectedPlatform: Platforms) {
    return ctx?.selectedBusiness?.connectedAdAccounts?.find((o) => o.source == selectedPlatform);
  }

  @Action(CreateBusiness)
  create(business: IBusiness, { dispatch }: StateContext<Business.State>) {
    return this.businessService.create({ data: business }).pipe(
      tap(() => {
        dispatch(new GetBusinesses());
      })
    );
  }

  @Action(GetBusinesses)
  get({ getState, patchState }: StateContext<Business.State>) {
    return this.businessService.getBusiness().pipe(
      switchMap(({ data: result }) => {
        let { selectedBusiness } = getState() ?? {};
        let stream$ = of(null);
        if (!selectedBusiness) {
          selectedBusiness = result?.[0] || null;

          const connectedFirstPlatform = selectedBusiness?.connectedAdAccounts?.[0];

          if (connectedFirstPlatform) {
            stream$ = this.store.dispatch(new SetPlatform(connectedFirstPlatform.source));
          }
        } else {
          selectedBusiness = result.find((o) => o.id === selectedBusiness!.id) || result?.[0] || null;
        }

        patchState({ businesses: result.reverse() });
        return stream$.pipe(switchMap(() => this.store.dispatch(new SetSelectedBusiness(selectedBusiness))));
      })
    );
  }

  @Action(UpdateBusiness)
  update(business: IUpdateBusinessRequest) {
    return this.businessService.update(business);
  }

  @Action(UpdateBusinessFromServer)
  updateFromServer({ patchState, getState }: StateContext<Business.State>) {
    const id = getState().selectedBusiness?.id;

    if (!id) return;

    return this.businessService.getById(id).pipe(
      tap((business) => {
        patchState({ selectedBusiness: business.data });
      })
    );
  }

  @Action(DeleteBusiness)
  delete({ dispatch, getState }: StateContext<Business.State>, { payload }: DeleteBusiness) {
    return this.businessService.deleteBusiness(payload.id).pipe(
      switchMap(() => {
        const state = getState();
        return dispatch(new GetBusinesses()).pipe(
          tap(() => {
            if (payload.id === state.selectedBusiness?.id) {
              const selectedBusiness = getState().businesses.find((o) => o.id !== payload.id) || null;
              dispatch(new SetSelectedBusiness(selectedBusiness));
            }
          })
        );
      })
    );
  }

  @Action(SetSelectedBusiness)
  setSelectedBusiness({ patchState }: StateContext<Business.State>, { payload }: SetSelectedBusiness) {
    if (!payload) {
      patchState({ selectedBusiness: null });
      return;
    }

    const firstConnectedAdAccount = payload.connectedAdAccounts?.at(0);

    if (!firstConnectedAdAccount) {
      patchState({ selectedBusiness: payload });
      return;
    }

    const { source: platform } = firstConnectedAdAccount;

    return this.store
      .dispatch([
        new SetPlatform(platform),
        new SetClickfraoudPlatform(Platforms.Unset),
        new SetClickfraoudPlatform(platform)
      ])
      .pipe(tap(() => patchState({ selectedBusiness: payload })));
  }
}
