import { BehaviorSubject } from 'rxjs';

import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { ConnectedOverlayScrollHandler, DomHandler } from '../../../helpers';

@Component({
  selector: 'ayn-overlay',
  template: `
    <div
      #overlayContainerElement
      *ngIf="render"
      [class]="'ayn-overlay ayn-component ' + styleClass"
      [style]="containerStyle"
      (click)="onContainerClick()"
      [@animation]="{
        value: useAnimations && overlayVisible ? 'open' : 'close',
        params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
      }"
      (@animation.start)="onAnimationStart($event)"
      (@animation.done)="onAnimationEnd($event)"
    >
      <div class="ayn-overlay-content">
        <ng-content></ng-content>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'scaleY(0.8)',
          opacity: 0
        })
      ),
      state(
        'close',
        style({
          opacity: 0
        })
      ),
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => open', animate('{{showTransitionParams}}')),
      transition('open => close', animate('{{hideTransitionParams}}'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class Overlay implements OnDestroy {
  @Input() dismissable: boolean = true;

  @Input() style: any;

  @Input() containerStyle: any = {};

  @Input() styleClass!: string;

  @Input() appendTo: any;

  @Input() autoZIndex: boolean = true;

  @Input() ariaCloseLabel!: string;

  @Input() baseZIndex: number = 0;

  @Input() focusOnShow: boolean = true;

  @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  @Input() hideTransitionOptions: string = '.1s linear';

  @Input() useOverlayBlur = false;

  @Input() overlayBlurCallback = () => {
    this.overlayBlurService.setBlurLevel(BlurLevel.Content);
  };

  @Output()
  onShow: EventEmitter<any> = new EventEmitter();

  @Output() onHide: EventEmitter<any> = new EventEmitter();

  @ViewChild('overlayContainerElement', { static: false }) overlayContainerElement?: ElementRef<HTMLDivElement>;

  container!: HTMLDivElement;

  overlayVisible: boolean = false;

  render: boolean = false;

  isContainerClicked: boolean = true;

  documentClickListener: any;

  target: any;

  willHide!: boolean;

  scrollHandler: any;

  documentResizeListener: any;

  contentTemplate!: TemplateRef<any>;

  destroyCallback?: Function | null;

  useAnimations = true;

  isOverlayFlipped = false;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    private zone: NgZone,
    private overlayBlurService: OverlayBlurService
  ) {}

  onContainerClick() {
    this.isContainerClicked = true;
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener && this.dismissable) {
      this.zone.runOutsideAngular(() => {
        let documentEvent = DomHandler.isIOS() ? 'touchstart' : 'click';
        const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

        this.documentClickListener = this.renderer.listen(documentTarget, documentEvent, (event) => {
          if (
            !this.container.contains(event.target) &&
            this.target !== event.target &&
            !this.target?.contains(event.target) &&
            !this.isContainerClicked
          ) {
            this.zone.run(() => {
              this.hide();
            });
          }

          this.isContainerClicked = false;
          this.cd.markForCheck();
        });
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  public toggle(event: { currentTarget: any; target: any }, target?: any) {
    if (this.overlayVisible) {
      if (this.hasTargetChanged(event, target)) {
        this.destroyCallback = () => {
          this.show(null, target || event.currentTarget || event.target);
        };
      }

      this.hide();
    } else {
      this.show(event, target);
    }
  }

  show(event: { currentTarget: any; target: any } | null, target?: any) {
    if (this.useOverlayBlur) {
      setTimeout(() => {
        this.overlayBlurCallback();
      }, 50);
    }

    this.target = target || event?.currentTarget || event?.target;
    this.overlayVisible = true;
    this.render = true;
    this.cd.markForCheck();
  }

  hasTargetChanged(event: { currentTarget: any; target: any }, target: any) {
    return this.target != null && this.target !== (target || event.currentTarget || event.target);
  }

  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') document.body.appendChild(this.container);
      else DomHandler.appendChild(this.container, this.appendTo);
    }
  }

  restoreAppend() {
    if (this.container && this.appendTo) {
      this.el.nativeElement.appendChild(this.container);
    }
  }

  align() {
    if (this.autoZIndex) {
      this.container.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
    }
    DomHandler.absolutePosition(this.container, this.target);

    const containerOffset = DomHandler.getOffset(this.container);
    const targetOffset = DomHandler.getOffset(this.target);
    let arrowLeft = 0;

    if (containerOffset.left < targetOffset.left) {
      arrowLeft = targetOffset.left - containerOffset.left;
    }
    this.container.style.setProperty('--overlayArrowLeft', `${arrowLeft}px`);

    this.isOverlayFlipped = containerOffset.top < targetOffset.top;

    if (containerOffset.top < targetOffset.top) {
      DomHandler.addClass(this.container, 'ayn-overlaypanel-flipped');
    }
  }

  onAnimationStart(event: AnimationEvent) {
    if (event.toState === 'open') {
      this.container = event.element;
      this.onShow.emit(null);
      this.appendContainer();
      this.align();
      this.bindDocumentClickListener();
      this.bindDocumentResizeListener();
      this.bindScrollListener();

      if (this.focusOnShow) {
        this.focus();
      }
    }
  }

  onAnimationEnd(event: AnimationEvent) {
    switch (event.toState) {
      case 'void':
        if (this.destroyCallback) {
          this.destroyCallback();
          this.destroyCallback = null;
        }
        break;

      case 'close':
        this.onContainerDestroy();
        this.onHide.emit({});
        this.render = false;
        break;
    }
  }

  focus() {
    let focusable = DomHandler.findSingle(this.container, '[autofocus]');
    if (focusable) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => focusable.focus(), 5);
      });
    }
  }

  hide() {
    if (this.useOverlayBlur) this.overlayBlurService.setBlurLevel(BlurLevel.None);

    this.isOverlayFlipped = false;
    this.overlayVisible = false;
    this.cd.markForCheck();
  }

  onCloseClick(event: { preventDefault: () => void }) {
    this.hide();
    event.preventDefault();
  }

  onWindowResize(event: any) {
    this.hide();
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
        if (this.overlayVisible) {
          this.hide();
        }
      });
    }

    this.scrollHandler.bindScrollListener();
  }

  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }

  onContainerDestroy() {
    this.target = null;
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
  }

  ngOnDestroy() {
    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }

    this.target = null;
    this.destroyCallback = null;
    if (this.container) {
      this.restoreAppend();
      this.onContainerDestroy();
    }
  }
}

export enum BlurLevel {
  None,
  Content
}

@Injectable({ providedIn: 'root' })
export class OverlayBlurService {
  private blurLevel$$ = new BehaviorSubject(BlurLevel.None);

  public blurLevel$ = this.blurLevel$$.asObservable();

  constructor() {}

  setBlurLevel(level: BlurLevel | number) {
    this.blurLevel$$.next(level);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [Overlay],
  declarations: [Overlay],
  providers: []
})
export class OverlayModule {}
