import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { TiktokStateService } from './';

@Injectable({ providedIn: 'root' })
export class TiktokFormService extends FormListener {
  constructor(private tiktokStateService: TiktokStateService) {
    super();

    this.subscribeForms(() => {
      this.tiktokStateService.saveDraft$();
    });
  }
}
