import { Facebook } from '@core/models';
import { CursorPaginatedQueryResult, makeQuery } from '@core/queries/types';

const queryField = 'campaigns';

type Output = CursorPaginatedQueryResult<typeof queryField, Facebook.Dashboard.ICampaign>;

type Input = Facebook.Dashboard.GetCampaignsRequest;

export const getCampaigns = makeQuery<Output, Input>(`
query GetCampaigns(
  $dateRange: DateRangeInput
  $datePreset: FacebookInsightDatePreset
  $name: String
  $first: Int
  $after:String
) {
  facebook {
    ${queryField}(
      dateRange: $dateRange
      datePreset: $datePreset
      name: $name
      first: $first
      after: $after
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          name
          startTime
          stopTime
          dailyBudget
          lifeTimeBudget
          insights {
            impressions
            costPerClick
            clicks
            spend
            averageCost1KImpressions
            averageCost1KReach
            reach
            startDate
            endDate
            actions {
              value
              cost
            }
          }
        }
        cursor
      }
    }
  }
}
`);
