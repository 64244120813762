<div
  [ngStyle]="style"
  [ngClass]="{
    'ayn-radio': true,
    'ayn-radio--checked': checked,
    'ayn-radio--disabled': disabledState,
    'ayn-radio--focused': focused
  }"
  [class]="radioType || ''"
  (click)="select($event)"
>
  <div class="ayn-hidden-accessible">
    <input
      #rb
      type="radio"
      [attr.id]="inputId"
      [attr.name]="name"
      [readonly]="readonly"
      [value]="value"
      [checked]="checked"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)"
      (change)="onChange($event)"
      [disabled]="disabledState"
      [attr.tabindex]="tabindex"
      [attr.aria-labelledby]="ariaLabelledBy"
      [attr.aria-label]="ariaLabel"
      [attr.aria-checked]="checked"
      [attr.required]="required"
    />
  </div>
  <div
    class="ayn-radio-box"
    [ngClass]="{
      'ayn-highlight': checked,
      'ayn-disabled': disabled,
      'ayn-focus': focused
    }"
  ></div>
  <label *ngIf="labelShow">{{ label }} </label>
  <p *ngIf="description">{{description}}</p>

  <ng-content></ng-content>
</div>
