import { Linkedin } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { input: Linkedin.LinkedInCreateImageModel };
type Output = Linkedin.CreateAd.CreateImageAdResponse;

export const createImageAd = makeMutation<Output, Input>(`
mutation CreateImageAd($input: LinkedInCreateImageModel!) {
    linkedin{
        createImageAd(input: $input)
    }
}
`);
