import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { GoogleCreateAd } from '@core/ad-platforms';
import { Google, PlatformStep } from '@core/models';
import { CanvaService } from '@core/services/canva.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleFormService, GoogleStateService, GoogleValidationService } from '@pages/create-ad/state';

import { ExtensionsYoutubeModalComponent } from '../modal';
import { DisplayAdsImages, UploadAdsImagesGoogleDisplayComponent } from '../modal/upload-ads-images-display';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { FileUploadAreaEvent, FileUploadGroup } from '@ayn-ui/lib/modules/base/file-upload-area';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createRequiredValidator, HEADLINES } from '@core/utils';
import { isImagesValid } from '../utils';

@UntilDestroy()
@Component({
  selector: 'aayn-display-ads--google',
  templateUrl: 'display-ads.component.html'
})
export class DisplayAdsGoogleComponent implements OnInit {
  protected adCreationModel = this.googleStateService
    .adCreationModel as unknown as GoogleCreateAd.ICreateAdModel<Google.CreateAd.GoogleDisplayCampaign>;

  protected uploadedImageUrls: FileUploadGroup[] = [];

  protected callToActions = Google.CallToActionTypesForDisplayAds;

  private _images?: DisplayAdsImages;

  get isImagesValid(): boolean {
    return isImagesValid(this.uploadedImageUrls);
  }

  protected form = new FormGroup(
    {
      longHeadline: new FormControl(this.adCreationModel.ad.assets.longHeadline, [
        Validators.required,
        Validators.pattern(HEADLINES)
      ]),
      headlines: new FormArray(
        [
          new FormControl(this.adCreationModel.ad.assets?.headlines[0], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.headlines[1], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.headlines[2], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.headlines[3], [Validators.pattern(HEADLINES)]),
          new FormControl(this.adCreationModel.ad.assets?.headlines[4], [Validators.pattern(HEADLINES)])
        ],
        [AppValidations.uniqueValidator()]
      ),
      descriptions: new FormArray(
        [
          new FormControl(this.adCreationModel.ad.assets?.descriptions[0], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[1], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[2], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[3])
        ],
        [AppValidations.uniqueValidator()]
      ),
      businessName: new FormControl(this.adCreationModel.ad.assets.businessName, [Validators.required]),
      callToAction: new FormControl(this.adCreationModel.ad.assets.callToActionText, [Validators.required]),
      url: new FormControl(this.adCreationModel.campaign.url, [Validators.required])
    },
    { validators: [createRequiredValidator(() => this.isImagesValid)] }
  );

  constructor(
    private googleStateService: GoogleStateService,
    private canvaService: CanvaService,
    private googleFormService: GoogleFormService,
    private googleValidationService: GoogleValidationService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    public adPreviewService: GoogleAdPreviewService
  ) {}

  ngOnInit() {
    this.canvaService.setupSdk();

    this.googleFormService.registerForm(this.form);

    this.listenModel();

    this.googleValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.listenForm();
  }

  get headlinesArray() {
    return this.form.controls['headlines'] as FormArray;
  }

  get descriptionsArray() {
    return this.form.controls['descriptions'] as FormArray;
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesGoogleDisplayComponent, { backdrop: 'static' });

    if (this._images) modalRef.componentInstance.uploadedImages = this._images;

    modalRef.closed.subscribe((result: { images: DisplayAdsImages }) => {
      if (result?.images) {
        this.updateImages(result.images);

        this.cdr.detectChanges();
      }
    });
  }

  updateImages(images: DisplayAdsImages) {
    this._images = images;

    this.uploadedImageUrls = this.mapUploadedImageUrls(images);

    this.adCreationModel.ad.assets!.images.logoIds = images.logo || [];
    this.adCreationModel.ad.assets!.images.squareLogoIds = images.squareLogo || [];
    this.adCreationModel.ad.assets!.images.marketingIds = images.marketing || [];
    this.adCreationModel.ad.assets!.images.squareMarketingIds = images.squareMarketing || [];
    this.form.updateValueAndValidity();
    this.adPreviewService.displayAds.logoUrl = images.squareLogo?.[0]?.url || '';

    this.googleStateService.saveDraft$();
  }

  private mapUploadedImageUrls(images: DisplayAdsImages): FileUploadGroup[] {
    return [
      {
        label: '1:1 Square Images',
        imageUrls: images.squareMarketing?.map((o) => o.url) || [],
        maxImageCount: 5,
        groupName: 'squareMarketing',
        aspectRatio: 1,
        required: true,
        minRequired: 1
      },
      {
        label: '1.91:1 Landscape Images',
        imageUrls: images.marketing?.map((o) => o.url) || [],
        maxImageCount: 5,
        groupName: 'marketing',
        aspectRatio: 1.91,
        required: true,
        minRequired: 1
      }
    ];
  }

  openExtensionsYoutubeModalComponent() {
    const modalRef = this.modalService.open(ExtensionsYoutubeModalComponent, { backdrop: 'static' });

    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  listenModel() {
    this.googleStateService.adCreationModel$
      .pipe(
        filter((model) => model.selectedTypes.ad === Google.AdType.DisplayAds),
        untilDestroyed(this)
      )
      .subscribe((o) => {
        const model = o as unknown as GoogleCreateAd.ICreateAdModel<Google.CreateAd.GoogleDisplayCampaign>;

        this.adCreationModel = model;

        this.form.patchValue({
          businessName: model.ad.assets.businessName,
          callToAction: model.ad.assets.callToActionText,
          url: model.campaign.url,
          longHeadline: model.ad.assets.longHeadline,
          descriptions: model.ad.assets.descriptions,
          headlines: model.ad.assets.headlines
        });

        const { squareMarketingIds, marketingIds, logoIds, squareLogoIds } = model.ad.assets.images;

        this._images = {
          squareMarketing: squareMarketingIds,
          marketing: marketingIds,
          logo: logoIds,
          squareLogo: squareLogoIds
        };
        this.adPreviewService.displayAds.logoUrl = squareLogoIds?.[0]?.url || '';

        this.uploadedImageUrls = this.mapUploadedImageUrls(this._images);
      });
  }

  private listenForm() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((o) => {
      this.adCreationModel.ad.assets.businessName = o.businessName!;
      this.adCreationModel.ad.assets.callToActionText = o.callToAction!;
      this.adCreationModel.campaign.url = o.url!;
      this.adCreationModel.ad.assets.longHeadline = o?.longHeadline?.replace('!', '') || '';

      this.adCreationModel.ad.assets.descriptions = Google.Utils.replaceExclamationMark(o.descriptions);
      this.adCreationModel.ad.assets.headlines = Google.Utils.replaceExclamationMark(o.headlines);
    });
  }

  removeFile(event: FileUploadAreaEvent) {
    const group = this._images![event.group!]!.filter((_, i) => i !== event.index);
    this.updateImages({
      ...this._images!,
      [event.group!]: group
    });
  }
}
