<div [ngSwitch]="selectedAdType">
  <div *ngSwitchCase="AdTypes.DisplayAds">
    <aayn-display-ads-preview--google></aayn-display-ads-preview--google>
  </div>

  <div *ngSwitchCase="AdTypes.PerformanceMax">
    <aayn-performance-max-preview--google></aayn-performance-max-preview--google>
  </div>

  <div *ngSwitchCase="AdTypes.SearchAds">
    <aayn-search-ads-preview--google></aayn-search-ads-preview--google>
  </div>
</div>
