import { Component, Input } from '@angular/core';
import { CarouselModule, IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-tiktok-single',
  templateUrl: './single.component.html',
  imports: [CarouselModule, NgForOf, PipesModule, TranslateModule, NgTemplateOutlet, NgIf, IconModule],
  standalone: true
})
export class AdPreviewTiktokSingleComponent {
  @Input() businessName = '';
  @Input() businessLogo = '';
  @Input() imageUrls: string[] = [];
  @Input() websiteUrl = '';
  @Input() headline = '';
  @Input() cta = '';

  headlinePlaceholder = 'Your Headline';
  ctaPlaceholder = 'CTA';

  onImgError(event) {}
}
