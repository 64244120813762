import { makeQuery, OffsetPaginatedQueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'adsWithMetrics';
type Input = TikTok.Dashboard.TikTokAdsWithMetricsRequest;
type Output = OffsetPaginatedQueryResult<typeof queryField, TikTok.Dashboard.TikTokAdSummaryOutputDto>;

export const adsWithMetricsQuery = makeQuery<Output, Input>(`
query AdsWithMetricsTikTok(
  $skip: Int
  $take: Int
  $searchKey: String
  $startDate: SafeInt!
  $endDate: SafeInt!
  $adGroupId: String!
) {
  tiktok {
    ${queryField}(
      skip: $skip
      take: $take
      startDate: $startDate
      endDate: $endDate
      searchKey: $searchKey
      adGroupId: $adGroupId
    ) {
      edges {
        node {
          name
          id
          status
          budget
          startDate
          clicks
          costPerClick
          impressions
          conversion
          purchase
          result
          spend
          adFormat
          adName
          adText
          adTexts
          callToAction
          imageUrls
          landingPageUrl
          profileImageUrl
          isAco
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}
`);
