import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.IFunnelDashboardRequest;
type Output = Bing.Dashboard.IFunnelDashboardResponse;

export const funnelDashboardBing = makeMutation<Output, Input>(`
query GetFunnelDashboardBing(
  $startDate: SafeInt
  $endDate: SafeInt
  $datePreset: DatePreset
) {
  bing {
    funnelDashboard(
      datePreset: $datePreset
      startDate: $startDate
      endDate: $endDate
    ) {
      smartConversions
      customConversions
      destinationUrlConversions
      offlineConversions
      durationConversions
      appInstalls
      viewPerVisit
      inStoreTransactions
      storeVisits
      productConversions
      timePeriod
      conversions
      clicks
      ctr
      impressions
    }
  }
}
`);
