<div class="aayn-create-ad--item" *ngIf="selectedPlatform$ | async as selectedPlatform">
  <section class="aayn-create-ad--item--container">
    <ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
      <div [ngSwitch]="selectedPlatform">
        <div *ngSwitchCase="Platforms.InstantAd" [@fadeInOnEnter]>
          <aayn-describe-project--instant-ad></aayn-describe-project--instant-ad>
        </div>
      </div>
    </ng-container>

    <aayn-ad-item-footer>
      <button
        ayn-button="primary"
        class="aayn-create-ad--item--btn"
        ayn-button-block
        (click)="nextStep(selectedPlatform)"
        type="submit"
        [width]="260"
        [innerHTML]="'Next Step; AI Generated Ads' | translate"
      ></button>
    </aayn-ad-item-footer>
  </section>
</div>
