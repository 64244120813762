<ng-container *ngIf="false">
  <h5 class="aayn-friend-invite--body-title2">{{ 'For each friend signed-up,' | translate }}</h5>
  <h6 class="aayn-friend-invite--body-title">{{ 'you get 1 free month!' | translate }}</h6>

  <p class="aayn-friend-invite--body-description">
    {{
      'Invite your friends to sign-up to ADYOUNEED, as soon as they become a paid user you get 1 free month! And for each
    month they stay you get $10 discount!'
        | translate
    }}
  </p>
</ng-container>

<section class="aayn-friend-invite--body-inputs" *ngIf="false">
  <ayn-input-with-button>
    <input type="text" placeholder="cihan@adyouneed.com" />

    <button ayn-button icon="chevron-right">{{ 'Invite' | translate }}</button>
  </ayn-input-with-button>

  <ayn-input-with-button type="stretch">
    <input type="text" [(ngModel)]="inviteLink" readonly />

    <button ayn-button="primary" icon="link">{{ 'Copy' | translate }}</button>
  </ayn-input-with-button>
</section>

<p class="aayn-friend-invite--body-share-text">{{ 'Talk about us on your social media!' | translate }}</p>

<section class="aayn-friend-invite--body-share-items">
  <div class="aayn-friend-invite--body-share-item">
    <span>
      <img src="/assets/images/svgs/platforms/facebook.svg" alt="Facebook" />
      Facebook
    </span>

    <button ayn-button icon="chevron-right" (click)="routeTarget('facebook')">{{ 'Share' | translate }}</button>
  </div>

  <div class="aayn-friend-invite--body-share-item">
    <span>
      <img src="/assets/images/svgs/platforms/twitter.svg" alt="Twitter" />
      Twitter
    </span>

    <button ayn-button icon="chevron-right" (click)="routeTarget('twitter')">{{ 'Share' | translate }}</button>
  </div>

  <div class="aayn-friend-invite--body-share-item">
    <span>
      <img src="/assets/images/svgs/platforms/linkedin.svg" alt="Linkedin" />
      Linkedin
    </span>

    <button ayn-button icon="chevron-right" (click)="routeTarget('linkedin')">{{ 'Share' | translate }}</button>
  </div>
</section>
