import { of } from 'rxjs';
import { skipUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bing } from '@core/models';
import { DraftService } from '@core/services/draft.service';
import { BingFormService, BingStateService, BingValidationService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-manual-audience--bing',
  templateUrl: 'manual-audience.component.html'
})
export class ManualAudienceBingComponent implements OnInit {
  protected adCreationModel = this.bingStateService.adCreationModel;

  form = new FormGroup({
    audienceName: new FormControl(this.adCreationModel.campaign.audienceName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    minAge: new FormControl(this.adCreationModel.campaign.criteria.minAge, [Validators.required]),
    maxAge: new FormControl(this.adCreationModel.campaign.criteria.maxAge, [Validators.required]),
    male: new FormControl(this.adCreationModel.campaign.criteria.male, [Validators.required]),
    female: new FormControl(this.adCreationModel.campaign.criteria.female, [Validators.required]),
    narrowerTargetCriteria: new FormControl(this.adCreationModel.campaign.criteria.narrowerTargetCriteria),
    includedLocations: new FormControl(this.adCreationModel.campaign.criteria?.target?.locationCriterionIds || [], [
      Validators.required
    ]),
    excludedLocation: new FormControl(this.adCreationModel.campaign.criteria?.exclude?.locationCriterionIds || []),
    excludedAudienceCriterionIds: new FormControl(
      this.adCreationModel.campaign.criteria?.exclude?.audienceCriterionIds || []
    ),
    targetAudienceCriterionIds: new FormControl(
      this.adCreationModel.campaign.criteria?.target?.audienceCriterionIds || []
    ),
    targetProfileDataCriteria: new FormControl(
      this.adCreationModel.campaign.criteria?.target?.profileDataCriteria || []
    ),
    excludeProfileDataCriteria: new FormControl(
      this.adCreationModel.campaign.criteria?.target?.profileDataCriteria || []
    )
  });

  criterions = Bing.TARGET_CRITERION;

  constructor(
    private bingStateService: BingStateService,
    private bingValidationService: BingValidationService,
    private bingFormService: BingFormService,
    private draftService: DraftService
  ) {}

  ngOnInit() {
    this.bingFormService.registerForm(this.form);

    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        audienceName: o.campaign.audienceName,
        minAge: o.campaign.criteria.minAge,
        maxAge: o.campaign.criteria.maxAge,
        male: o.campaign.criteria.male,
        female: o.campaign.criteria.female,
        narrowerTargetCriteria: o.campaign.criteria.narrowerTargetCriteria,
        includedLocations: o.campaign.criteria.target.locationCriterionIds,
        excludedLocation: o.campaign.criteria.exclude.locationCriterionIds,
        excludedAudienceCriterionIds: o.campaign.criteria.exclude.audienceCriterionIds,
        targetAudienceCriterionIds: o.campaign.criteria.target.audienceCriterionIds,
        targetProfileDataCriteria: o.campaign.criteria.target.profileDataCriteria,
        excludeProfileDataCriteria: o.campaign.criteria.exclude.profileDataCriteria
      });

      this.bingValidationService.audienceValid$.next(this.form.status === 'VALID');
    });

    this.form.valueChanges.pipe(skipUntil(this.draftService.getDraftLoader$ || of())).subscribe((x) => {
      this.adCreationModel.campaign.audienceName = x.audienceName || '';
      this.adCreationModel!.campaign.criteria = {
        narrowerTargetCriteria: x.narrowerTargetCriteria!,
        male: x.male!,
        female: x.female!,
        maxAge: x.maxAge!,
        minAge: x.minAge!,
        target: {
          locationCriterionIds: x.includedLocations!,
          audienceCriterionIds: x.targetAudienceCriterionIds!,
          profileDataCriteria: x.targetProfileDataCriteria!
        },
        exclude: {
          locationCriterionIds: x.excludedLocation!,
          audienceCriterionIds: x.excludedAudienceCriterionIds!,
          profileDataCriteria: x.excludeProfileDataCriteria!
        }
      };

      this.bingValidationService.audienceValid$.next(this.form.status === 'VALID');
    });
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    this.form!.patchValue({ minAge: min });
    this.form!.patchValue({ maxAge: max });
  }

  protected valueChange(value: Bing.AudienceCampaign.ConversionGoalInput[]) {
    if (value) {
      // this.form!.patchValue({ conversionEvent: value! });
    }
  }
}
