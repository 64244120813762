import { makeQuery, QueryResult } from '@core/queries';
import { Facebook } from '@core/models';

type Input = { id: string };
type Output = QueryResult<'campaign', Omit<Facebook.Dashboard.ICampaign, 'insights'>>;

export const getCampaignQuery = makeQuery<Output, Input>(`
query GetCampaign($id: String) {
  facebook {
    campaign(id: $id) {
      id
      status
      name
      startTime
      stopTime
      dailyBudget
      lifeTimeBudget
    }
  }
}
`);
