import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../icon';

@Component({
  selector: 'ayn-modal-close',
  template: `
    <a class="modal-close" (click)="close()" [title]="title">
      <ayn-icon [name]="icon"></ayn-icon>
    </a>
  `
})
export class ModalClose {
  @Input() modal?: NgbActiveModal;

  @Input() icon = 'exit';

  @Input() title = 'Close';

  @Input() closeFn?: () => void;

  constructor(@Optional() public _modal: NgbActiveModal) {}

  protected close() {
    if (this.closeFn) {
      this.closeFn();

      return;
    }

    this._modal ? this._modal.close() : this.modal?.close();
  }
}

@NgModule({
  imports: [CommonModule, IconModule],
  exports: [ModalClose],
  declarations: [ModalClose],
  providers: []
})
export class ModalCloseModule {}
