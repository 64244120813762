import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerDirective, ColorPickerModule } from 'ngx-color-picker';

export class SliderDimension {
  constructor(public h: number, public s: number, public v: number, public a: number) {
  }
}

@Component({
  selector: 'ayn-color-picker',
  templateUrl: 'color-picker.component.html'
})
export class AynColorPicker {
  @Input() label = 'Brand Color 1';

  @Input() color!: string;

  @Input() showValue: boolean = true;

  @Input() cpOutputFormat: 'rgba' | 'hex' | 'auto' | 'hsla' = 'hex';

  @Output() colorChange = new EventEmitter<string>();

  @Output() closeColorPicker = new EventEmitter<boolean>();

  @ViewChild(ColorPickerDirective) colorPicker!: ColorPickerDirective;

  colorGroups = [
    '#000000',
    '#5C5A5A',
    '#737373',
    '#A6A6A6',
    '#D9D9D9',
    '#FFFFFF',
    '#FF0F00',
    '#E33B30',
    '#EC635E',
    '#ED70C0',
    '#BE71DF',
    '#8455F6',
    '#561DE2',
    '#21AED9',
    '#4295AF',
    '#83CEE4',
    '#5FB3F9',
    '#5870F6',
    '#1D48A7',
    '#0C3080',
    '#4BBF00',
    '#94D769',
    '#CEFD85',
    '#F9DF70',
    '#F5C06B',
    '#F0965B',
    '#FC7B27'
  ];

  public onChangeColor(color: string): void {
    this.colorChange.emit(color);
  }

  openCp() {
    const instance = this.colorPicker['cmpRef'].instance;
    const hueWidth = instance.hueSlider.nativeElement.offsetWidth || 140;
    const alphaWidth = instance.alphaSlider.nativeElement.offsetWidth || 140;
    instance.sliderDimMax = new SliderDimension(hueWidth, 298, 72, alphaWidth);
    instance.updateColorPicker(false);
    instance.cdRef.detectChanges();
  }

  closeCp() {
    this.closeColorPicker.emit(true);
  }
}

@NgModule({
  imports: [CommonModule, ColorPickerModule],
  exports: [AynColorPicker],
  declarations: [AynColorPicker],
  providers: []
})
export class AynColorPickerModule {
}
