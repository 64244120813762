import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aynSort'
})
export class SortPipe implements PipeTransform {
  transform<T>(values: T[], sortBy: string, direction = 'DESC', keyMap?: { [key: string]: keyof T }): T[] {
    const sortKey = (keyMap?.[sortBy] || sortBy) as keyof T;
    return values.sort((a, b) => {
      const valA = a[sortKey];
      const valB = b[sortKey];

      if (typeof valA == 'string' && typeof valB == 'string') {
        if (direction == 'DESC') {
          return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1;
        }
        return valA.toLowerCase() > valB.toLowerCase() ? 1 : -1;
      }
      if (direction == 'DESC') {
        return valA < valB ? 1 : -1;
      }
      return valA > valB ? 1 : -1;
    });
  }
}
