import { pipe } from 'fp-ts/function';
import { cloneDeep } from 'lodash';

import { TikTok } from '@core/models';

import {
  ApplyTypes,
  ApplyValidations,
  createAdBaseModel,
  CreateAdCommon,
  ICreateAdCommonValidate,
  UsesInitialization,
  WithMap
} from '../common';

export module TiktokCreateAd {
  export type AdRequest<
    CT extends TikTok.CreateAd.Backend.TikTokCampaignDto = TikTok.CreateAd.Backend.TikTokCampaignDto
  > = {
    campaign: CT;
  };

  function createEmptyModel<AT = TikTok.CreateAd.Client.TikTokDynamicCreativeAd>() {
    return {
      ...createAdBaseModel(
        TikTok.CreateAd.Backend.ObjectiveType.Traffic,
        TikTok.CreateAd.AudienceType.MANUAL_AUDIENCE,
        TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS
      ),
      campaign: {
        budgetAmount: 0,
        pixelEventCode: '',
        budgetType: TikTok.CreateAd.Backend.TikTokBudgetEnumType.DailyBudget,
        campaignName: '',
        endDate: undefined,
        startDate: new Date(),
        objectiveType: TikTok.CreateAd.Backend.ObjectiveType.Traffic,
        paused: true,
        pixelId: '',
        identity: {} as TikTok.Business.TikTokIdentityOutputDto
      } as TikTok.CreateAd.Client.TikTokCampaignClientDto,

      audience: {
        excludedCustomAudiences: [],
        includedCustomAudiences: [],
        female: true,
        male: true,
        interests: [],
        keywords: [],
        languages: [],
        locations: [],
        minAge: 13,
        maxAge: 55,
        zipCodes: [],
        name: ''
      } as TikTok.CreateAd.Client.TikTokCampaignAudienceDto,

      ad: {
        callToAction: '',
        adTexts: [],
        imageIds: [],
        videoIds: [],
        name: '',
        url: ''
      } as AT
    };
  }

  export type ICreateAdModel<AT = TikTok.CreateAd.Client.TikTokDynamicCreativeAd> = ReturnType<
    typeof createEmptyModel<AT>
  >;

  const deepClonedModel = structuredClone(createEmptyModel());

  @UsesInitialization(deepClonedModel)
  @WithMap({
    [TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS]: function (
      adCreationModel: ICreateAdModel<TikTok.CreateAd.Client.TikTokDynamicCreativeAd>
    ) {
      return pipe(
        adCreationModel,
        cloneDeep,
        TikTok.CreateAd.Utils.mapAudience,
        TikTok.CreateAd.Utils.mapDynamicCreativeAd,
        TikTok.CreateAd.Utils.mapDates,
        TikTok.CreateAd.Utils.mapIdentity
      );
    }
  })
  @ApplyTypes({
    ad: {
      [TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS]: {
        callToAction: '',
        adTexts: [],
        imageIds: [],
        videoIds: [],
        name: '',
        url: ''
      }
    }
  })
  @ApplyValidations({
    ad: {
      [TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS]: function (model: ICreateAdModel) {
        return !!(model.ad.images.length || model.ad.videos.length) && !!model.ad.adTexts.length;
      }
    }
  })
  export class CreateAd {
    get isValidImages() {
      return this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd extends CreateAdCommon<ICreateAdModel, AdRequest, TikTok.CreateAd.AdType> {
    mapToApiRequest(type?: string | number): AdRequest;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
