import { makeMutation } from '../types';

type Input = {
  packageId: string;
  appSumoCoupon?: string;
};
type Output = boolean;

export const subscribeToPlan = makeMutation<Output, Input>(`
  mutation SubscribeToPlan($packageId: String, $appSumoCoupon: String) {
    subscribeToPlan(
      packageId: $packageId
      appSumoCoupon: $appSumoCoupon
    )
  }
`);
