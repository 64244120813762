<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  [adPreviewContainer]="adPreviewService.displayAds"
  aaynTextAiContainer
>
  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    [description]="
      'Google is recommending this size to be in this pixel range to get the best results possible.' | translate
    "
    [imageGroups]="uploadedImageUrls"
    [showRequiredAlert]="_form.submitted && isImagesValid"
    (fileRemove)="removeFile($event)"
    adPreview="imageUrls"
    [standAlone]="true"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Long Headline" description="Write long headlines">
      <aayn-create-ad-input
        [requiredMessage]="'You must write at least 1 headline.' | translate"
        [maxCharacterNumber]="90"
        [placeholder]="'Tell people what your ad is about' | translate"
        formControlName="longHeadline"
        adPreview="longHeadline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Short Headlines for ads" description="Write short headlines">
      <aayn-create-ad-input
        *ngFor="let control of headlinesArray.controls; index as index"
        [maxCharacterNumber]="30"
        [formControl]="$any(headlinesArray.controls[index])"
        [requiredMessage]="'You must write at least 3 headline.' | translate"
        [placeholder]="'Write a short headline' | translate"
        adPreview="shortHeadline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Descriptions for ads" description="Write descriptions">
      <aayn-create-ad-input
        *ngFor="let control of descriptionsArray.controls; index as index"
        [maxCharacterNumber]="90"
        [formControl]="$any(descriptionsArray.controls[index])"
        [requiredMessage]="'You must write at least 3 descriptions.' | translate"
        [placeholder]="'Write a description here' | translate"
        adPreview="description"
        textAiSection="descriptions"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Business Name" description="Write your business name">
      <aayn-create-ad-input
        formControlName="businessName"
        [placeholder]="'Business Name' | translate"
        [maxCharacterNumber]="25"
        [requiredMessage]="'You must write a business name.' | translate"
        [textCraftUsable]="false"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            [placeholder]="'Select CTA' | translate"
            [showTick]="true"
            [showSearch]="false"
            formControlName="callToAction"
            adPreview="callToAction"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="text"
            placeholder="https://adyouneed.com"
            formControlName="url"
            adPreview="url"
            [standAlone]="true"
            ayn-input
            #urlInput
          />
        </ayn-form-field>
      </section>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-collapse-card
    title="Ad Extensions"
    [active]="false"
    description="Get up to 15% higher clickthrough rate by showing additional information on your ads"
    class="aayn-ad-type--collapse"
  >
    <div class="aayn-ad-type--collapse-extentions">
      <aayn-ad-type-collapse-extentions-item
        title="YouTube Video Extension"
        [isButtonShow]="this.adCreationModel.ad.youtubeExtension?.create?.name != ''"
        description="Add a custom YouTube Video to your ad"
        (buttonClick)="openExtensionsYoutubeModalComponent()"
        extentionType="youtubeExtension"
      ></aayn-ad-type-collapse-extentions-item>
    </div>
  </aayn-collapse-card>
</form>
