import { Component, Input } from '@angular/core';
import { SmartCampaign } from '@core/models';

@Component({
  selector: 'aayn-ad-preview--smart-campaign',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewSmartCampaignComponent {
  @Input() selectedAdType: SmartCampaign.Client.AdType = SmartCampaign.Client.AdType.Default;

  protected AdTypes = SmartCampaign.Client.AdType;

  constructor() {}
}
