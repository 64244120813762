import { MutationResult } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SmartCampaign } from '../';
import { SmartCampaignToastComponent } from '../components/smart-campaign-toast';

type CombinedSmartCampaignFailures = { error: string; platform: string }[];

export function combineSmartCampaignFailures(
  failures: SmartCampaign.Backend.SmartCampaignPlatformFailures
): CombinedSmartCampaignFailures {
  const _failures: { error: string; platform: string }[] = [];

  Object.entries(failures).forEach(([platform, errors]) => {
    errors.forEach((error: string) => {
      _failures.push({ error, platform });
    });
  });

  return _failures;
}

type NotifySmartCampaignErrorsPipeType<T> = Observable<
  MutationResult<T> & {
    data?: T | undefined;
  }
>;

export function notifySmartCampaignErrors(toastrService: ToastrService) {
  return function <T>(source: NotifySmartCampaignErrorsPipeType<T>): NotifySmartCampaignErrorsPipeType<T> {
    return source.pipe(
      tap((o) => {
        if (!o.data) return;

        const operationResult = o.data as unknown as SmartCampaign.Backend.SmartCampaignBaseResultDto;

        if (!operationResult || !operationResult.failures) return;

        const failures = combineSmartCampaignFailures(operationResult.failures);

        failures.forEach(({ error, platform }) => {
          toastrService.error(error, '', {
            toastComponent: SmartCampaignToastComponent,
            payload: {
              platform
            }
          });
        });
      })
    );
  };
}
