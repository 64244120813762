import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aynRounded'
})
export class RoundPipe implements PipeTransform {
  transform(value: number, dir: 'down' | 'up' = 'down'): any {
    const round = dir === 'down' ? Math.floor : Math.ceil;
    return round(value);
  }
}
