<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">Welcome to ADYOUNEED University!!</section>
  <section class="aayn-video-modal--description">
    <p>
      Discover the power of advertising with ADYOUNEED University! Master the art of ad creation, earn certificates, and
      elevate your campaigns. Ready to start learning?
    </p>
    <p class="aayn-video-modal--hit-line">Choose “Guide Me” or “Skip” to explore on your own.</p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary">Guide Me</button>
    <a class="aayn-video-modal--skip" (click)="videoModal.closeModal()">Skip</a>
  </section>
</aayn-video-modal>
