import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgModule,
  Output,
  QueryList
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { IconModule } from '../icon';

export type TabType = 'ayn-tab--default' | 'ayn-tab--unstyled' | 'ayn-tab--millennium' | 'ayn-tab--primary';

@Component({
  selector: 'ayn-tab-item',
  template: `
    <div class="ayn-tab-item" [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `
})
export class TabItem {
  @Input() tabTitle?: string;

  @Input() leftIcon?: string;

  @Input() icon?: string;

  @Input() active = false;

  @Input() disabled = false;

  @Input() fragment: string | undefined;

  @Output() tabClick: EventEmitter<Event> = new EventEmitter<Event>();

  tab = inject(Tab, { optional: true });

  get node() {
    if (this.index === -1) {
      return undefined;
    }

    return this.tab!.tabItemsNode.item(this.index);
  }

  get index() {
    if (!this.tab?.tabitems) {
      return -1;
    }
    const index = this.tab.tabitems.toArray().findIndex((tab) => tab === this);
    return index;
  }
}

// TODO: Tab komponenti router ile çalışma özelliği getirilecek.
@Component({
  selector: 'ayn-tab',
  template: `
    <ng-container *ngIf="tabLocation === 'outside'">
      <ng-container *ngTemplateOutlet="tabs"></ng-container>
    </ng-container>
    <div class="ayn-tab" [ngClass]="tabClass">
      <div [ngClass]="type">
        <ng-container *ngIf="tabLocation === 'inside'">
          <ng-container *ngTemplateOutlet="tabs"></ng-container>
        </ng-container>
        <ng-content select="[right]"></ng-content>
      </div>

      <ng-content></ng-content>
    </div>

    <ng-template #tabs>
      <ul class="ayn-tabs">
        <li
          *ngFor="let tab of tabitems"
          [ngClass]="{ active: tab.active, disabled: tab.disabled }"
          (click)="selectTab(tab); tab.disabled ? null : tab.tabClick.emit($event)"
        >
          <ayn-icon *ngIf="tab.leftIcon" [name]="tab.leftIcon"></ayn-icon>
          <span>
            {{ tab.tabTitle }}
          </span>
          <ayn-icon *ngIf="tab.icon" [name]="tab.icon"></ayn-icon>
        </li>
      </ul>
    </ng-template>
  `
})
export class Tab implements AfterContentInit {
  @ContentChildren(TabItem) tabitems?: QueryList<TabItem>;

  @Input('tab-type') type: TabType = 'ayn-tab--unstyled';

  @Input() tabClass: string = '';

  @Input() tabLocation: 'outside' | 'inside' = 'inside';

  @Input() useFragment = false;

  get tabItemsNode() {
    return this.el.nativeElement.querySelectorAll('.ayn-tabs li') as NodeListOf<HTMLLIElement>;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public el: ElementRef) {}

  ngAfterContentInit() {
    const activeTab = this.tabitems?.find((tab) => tab.active || tab.fragment == this.activatedRoute.snapshot.fragment);

    if (!activeTab) {
      this.selectTab(this.tabitems?.first || new TabItem());
    } else {
      this.selectTab(activeTab);
    }
  }

  selectTab(tab: TabItem): void {
    if (tab.disabled) return;
    this.tabitems?.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;

    if (this.useFragment) {
      const isSameUrl = this.router.url.includes(`#${tab.fragment}`);

      if (isSameUrl) return;

      if (tab.fragment) {
        const url = this.router.url.split('#').at(0);

        this.router.navigate([url], { fragment: tab.fragment });
      }
    }
  }

  public selectTabWithIndex(index: number) {
    if (!this.tabitems?.length) return;

    this.tabitems?.toArray().forEach((tab, i) => {
      tab.active = i == index;
    });
  }
}

@NgModule({
  imports: [CommonModule, IconModule, RouterModule],
  exports: [Tab, TabItem],
  declarations: [Tab, TabItem],
  providers: []
})
export class TabModule {}
