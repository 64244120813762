import { Platforms } from './'

export enum ContextStatus {
  NotConnected = 0,
  Connected = 1,
  RequireReConnect = 2
}

export interface IPlatformContext {
  id: string;
  source: Platforms;
  externalId: string;
  status?: ContextStatus;
}

export type IAllPlatformContextsResponse = IPlatformContext[]

export type PlatformContextMappedType = Omit<
  {
    [key in keyof typeof Platforms]: IPlatformContext;
  },
  'Unset'
>;
