<li class="existing-post--li" [ngClass]="{ 'existing-post--li__active': active }">
  <img [src]="imageUrl || '/assets/images/pngs/placeholder.png'" alt="Post Image" class="existing-post--li-img" />

  <div class="existing-post--li-content">
    <div class="existing-post--li-content--header">
      <p class="existing-post--li-content--title">{{ message }}</p>
      <span class="existing-post--li-content--type" *ngIf="type">{{ type }}</span>
    </div>

    <div class="existing-post--li-footer">
      <div class="existing-post--li-footer--icons">
        <span class="existing-post--li-footer--icon">
          <ayn-icon name="hearth"></ayn-icon>
          {{ likes }}
        </span>

        <span class="existing-post--li-footer--icon">
          <ayn-icon name="link-share"></ayn-icon>
          {{ shares }}
        </span>

        <span class="existing-post--li-footer--icon">
          <ayn-icon name="chat"></ayn-icon>
          {{ comments }}
        </span>
      </div>
      <b *ngIf="date"> {{ date | date: 'MMM dd, YYYY' }}</b>
    </div>
  </div>
</li>
