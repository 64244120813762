import { Component, Input, OnInit } from '@angular/core';

export interface INotification {
  platform: 'facebook' | 'instagram' | 'google' | 'linkedin' | 'bing' | 'pinterest' | 'display-networks';
  text: string;
  date: Date | string;
  icon?: 'check' | 'warning-red';
}

@Component({
  selector: 'aayn-notification-item',
  templateUrl: 'notification-item.component.html'
})
export class NotificationItemComponent implements OnInit {
  @Input() notification!: INotification;

  @Input() isNew = false;

  constructor() {}

  ngOnInit() {}
}
