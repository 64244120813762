import { Component, NgZone } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { IconModule } from '@ayn-ui/lib/modules';
import { ACTIVE_PLATFORMS, Platforms, SupportedSmartCampaignObject } from '@core/models';

// https://github.com/scttcper/ngx-toastr/blob/master/src/lib/toastr/toast.component.ts
const ANIMATIONS = [
  trigger('flyInOut', [
    state('inactive', style({ opacity: 0 })),
    state('active', style({ opacity: 1 })),
    state('removed', style({ opacity: 0 })),
    transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
    transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
  ])
];

@Component({
  selector: '[aayn-smart-campaign-toast]',
  template: `
    <button *ngIf="options.closeButton" (click)="remove()" type="button" class="toast-close-button" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
      <ayn-icon *ngIf="iconName" [name]="iconName"></ayn-icon>
      <span>{{ message }}</span>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  animations: ANIMATIONS,
  preserveWhitespaces: false,
  standalone: true,
  imports: [NgIf, IconModule]
})
export class SmartCampaignToastComponent extends Toast {
  iconName?: string;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, protected ngZone?: NgZone) {
    super(toastrService, toastPackage, ngZone);

    const platform = Object.entries(SupportedSmartCampaignObject).reduce((platform, [key, value]) => {
      return value.toUpperCase() === this.options.payload.platform.toUpperCase() ? +key : platform;
    }, Platforms.Unset);

    this.iconName = ACTIVE_PLATFORMS.find((activePlatform) => activePlatform.value === platform)?.icon;
  }
}
