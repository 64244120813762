import {
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY
} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  BingAdAccount,
  Facebook,
  FacebookAdAccount,
  Google,
  GoogleAdAccount,
  IAdAccountType,
  LinkedinAdAccount,
  Platforms,
  TikTokAdAccount
} from '@core/models';
import { Bing } from '@core/models/platforms/bing';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { Observable, of, ReplaySubject } from 'rxjs';
import { startWith, take } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CdkTableService } from '@shared/components/account-select-modal/services/cdk-table.service';
import { Table } from '@ayn-ui/lib/modules';

type PlatformMap = {
  [Platforms.Meta]: IAdAccountType<FacebookAdAccount>;
  [Platforms.Google]: IAdAccountType<GoogleAdAccount>;
  [Platforms.Bing]: IAdAccountType<BingAdAccount>;
  [Platforms.LinkedIn]: IAdAccountType<LinkedinAdAccount>;
  [Platforms.TikTok]: IAdAccountType<TikTokAdAccount>;
};

type Platform = Platforms.Meta | Platforms.Google | Platforms.Bing | Platforms.LinkedIn | Platforms.TikTok;
export interface HasPlatforms<P extends Platform = Platform> {
  platform: P;
  adAccounts: PlatformMap[P][];
}

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 300, 500);
  }
}
@UntilDestroy()
@Component({
  selector: 'aayn-account-ad-account-select',
  templateUrl: './account-ad-account-select.component.html',
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }, CdkTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountAdAccountSelectComponent<P extends Platform = Platform>
  implements AfterViewInit, OnChanges, HasPlatforms<P>
{
  @Input() adAccounts: PlatformMap[P][] = [];

  @Input() query = '';

  @Input() platform: P = Platforms.Meta as P;

  @Input() validateSelect: (account: PlatformMap[P]) => boolean | Observable<boolean> = () => true;

  @Input() buttonLoader$: Observable<boolean> = of(false);

  @Output() selectAdAccount = new EventEmitter<PlatformMap[P]>();

  @Select(LoaderState.getAny(['AdAccounts'])) loader$?: Observable<boolean>;

  @ViewChildren(CdkVirtualScrollViewport) viewport!: QueryList<CdkVirtualScrollViewport>;

  @ViewChildren('cdkFor') cdkFor!: QueryList<CdkVirtualForOf<PlatformMap[P][]>>;

  @ViewChild('scrollTable', { static: false }) scrollTable?: Table;

  itemClicked?: PlatformMap[P];

  calculateHeight$ = new ReplaySubject(1);

  constructor(private cdkTableService: CdkTableService, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connectedAdAccounts) {
      this.adAccounts = changes.connectedAdAccounts.currentValue?.map((o) => ({
        ...o,
        selected: !!o.selected
      }));
      if (!changes.connectedAdAccounts.firstChange) {
        this.calculateHeight$.next();
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdkTableService.registerViewport(this.viewport, this.calculateHeight$, () =>
      this.adAccounts.filter((o) => !o.selected)
    );
    this.cdkTableService.listen();
    this.cdkFor.changes.pipe(startWith(null)).subscribe(() => {
      this.calculateHeight$.next();
    });
  }

  changeSelected(account: PlatformMap[P], value: boolean) {
    this.itemClicked = account;
    if (this.adAccounts) {
      if (!value) {
        this.selectAccount(account, value);
      } else {
        const validate = this.validateSelect(account);
        if (validate instanceof Observable) {
          validate.pipe(take(1)).subscribe((v) => {
            if (v) {
              this.selectAccount(account, value);
            }
          });
        } else {
          if (validate) {
            this.selectAccount(account, value);
          }
        }
      }
    }
  }

  selectAccount(account: PlatformMap[P], value: boolean) {
    this.adAccounts = this.adAccounts.map((o) => ({
      ...o,
      selected: o.id == account.id ? value : false
    }));
    this.selectAdAccount.emit({ ...account, selected: value });
    this.cdr.detectChanges();
    this.calculateHeight$.next();
  }

  getStatusText(adAccount: IAdAccountType): string {
    if (this.platform === Platforms.Google) {
      adAccount = <Google.IGoogleAdAccountOutput>adAccount;

      switch (adAccount.accessRole) {
        case Number(Google.AccessRoleEnum.BILLING):
          return 'NO BILLING';
        case Number(Google.AccessRoleEnum.READONLY):
          return 'READONLY';
        default:
          switch (adAccount.status) {
            case Google.StatusEnum.CANCELED:
              return 'CANCELED';
            case Google.StatusEnum.CLOSED:
              return 'CLOSED';
            case Google.StatusEnum.ENABLED:
              return 'ACTIVE';
            case Google.StatusEnum.SUSPENDED:
              return 'SUSPENDED';
            case Google.StatusEnum.UNKNOWN:
              return 'UNKNOWN';
            case Google.StatusEnum.UNSPECIFIED:
              return 'UNSPECIFIED';
            default:
              return 'ACTIVE';
          }
      }
    } else if (this.platform === Platforms.Meta) {
      adAccount = <Facebook.IAdAccount>adAccount;

      switch (adAccount.accountStatus) {
        case Facebook.AdAccountStatus.Active:
          return 'ACTIVE';
        case Facebook.AdAccountStatus.AnyActive:
          return 'ANY ACTIVE';
        case Facebook.AdAccountStatus.AnyClosed:
          return 'ANY CLOSED';
        case Facebook.AdAccountStatus.Closed:
          return 'CLOSED';
        case Facebook.AdAccountStatus.Disabled:
          return 'DISABLED';
        case Facebook.AdAccountStatus.InGracePeriod:
          return 'IN GRACE PERIOD';
        case Facebook.AdAccountStatus.PendingClosure:
          return 'PENDING CLOSURE';
        case Facebook.AdAccountStatus.PendingRiskReview:
          return 'PENDING RISK VIEW';
        case Facebook.AdAccountStatus.PendingSettlement:
          return 'PENDING SETTLEMENT';
        case Facebook.AdAccountStatus.Unsettled:
          return 'UNSETTLED';

        default:
          return 'ACTIVE';
      }
    } else if (this.platform === Platforms.Bing) {
      adAccount = <Bing.Business.IBingAdAccountOutput>adAccount;
      const isStatus =
        adAccount.status == Bing.Business.BingAdAccountStatus.Active &&
        adAccount.accessRole == Bing.Business.BingAccessRole.Writable &&
        adAccount.userStatus == Bing.Business.BingUserAccountStatus.Active;
      if (!isStatus) {
        switch (adAccount.accessRole) {
          case Bing.Business.BingAccessRole.Readonly:
            return 'READONLY';
          default:
            switch (adAccount.status) {
              case Bing.Business.BingAdAccountStatus.Draft:
                return 'DRAFT';
              case Bing.Business.BingAdAccountStatus.Inactive:
                return 'INACTIVE';
              case Bing.Business.BingAdAccountStatus.Pause:
                return 'PAUSE';
              case Bing.Business.BingAdAccountStatus.Pending:
                return 'PENDING';
              case Bing.Business.BingAdAccountStatus.Suspended:
                return 'SUSPENDED';
              default:
                return 'INACTIVE';
            }
        }
      }
      return 'ACTIVE';
    } else if (this.platform === Platforms.LinkedIn) {
      return 'ACTIVE';
    } else if (this.platform === Platforms.TikTok) {
      adAccount = <TikTokAdAccount>adAccount;
      return adAccount.status.toUpperCase();
    }
    return 'ACTIVE';
  }

  getStatus(adAccount: IAdAccountType): boolean {
    if (this.platform === Platforms.Meta)
      return (<Facebook.IAdAccount>adAccount).accountStatus === Facebook.AdAccountStatus.Active;
    else if (this.platform === Platforms.Google) {
      adAccount = <Google.IGoogleAdAccountOutput>adAccount;

      return adAccount.status === Google.StatusEnum.ENABLED && adAccount.accessRole === Google.AccessRoleEnum.WRITABLE;
    } else if (this.platform === Platforms.Bing) {
      adAccount = <Bing.Business.IBingAdAccountOutput>adAccount;
      return (
        adAccount.status == Bing.Business.BingAdAccountStatus.Active ||
        adAccount.status == Bing.Business.BingAdAccountStatus.Pause ||
        (adAccount.status == Bing.Business.BingAdAccountStatus.Pending &&
          adAccount.accessRole == Bing.Business.BingAccessRole.Writable &&
          adAccount.userStatus == Bing.Business.BingUserAccountStatus.Active)
      );
    } else if (this.platform === Platforms.LinkedIn) {
      return true;
    } else if (this.platform === Platforms.TikTok) {
      return true;
    }
    return false;
  }
}
