import { Platforms, SmartCampaign, SupportedSmartCampaignObject } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { campaign: SmartCampaign.Backend.SmartCampaignCreateDto };
type Output = SmartCampaign.Backend.SmartCampaignBaseResultDto;

const platformsObject = {
  [Platforms.Meta]: `facebook { createdCampaignIds }`,
  [Platforms.LinkedIn]: `linkedIn { createdCampaignIds }`,
  [Platforms.Google]: `google { createdCampaignIds }`,
  [Platforms.Bing]: `bing { createdCampaignIds }`,
  [Platforms.TikTok]: `tiktok { createdCampaignIds }`
};

export const createSmartCampaign = (selectedPlatforms: Platforms[]) =>
  makeMutation<Output, Input>(
    `
mutation CreateSmartCampaign($campaign: SmartCampaignCreateDto!) {
  ayn {
    createSmartCampaign(campaign: $campaign) {
      failures {
       ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
      }

      statuses {
        ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
      }
      
      results {
        ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
      }
    }
  }
}
`,
    { resultPath: 'ayn.createSmartCampaign' }
  );
