import { toUpper } from 'lodash';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRIES, Country } from '@core/constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-call-extensions-modal',
  templateUrl: './call-extensions-modal.component.html'
})
export class CallExtensionsModalComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(public modal: NgbActiveModal, private googleStateService: GoogleStateService) {}

  callForm = new FormGroup({
    name: new FormControl<string>(this.adCreationModel.ad.callExtension!.create!.name, [
      Validators.required,
      Validators.min(2)
    ]),
    countryCode: new FormControl<string>(this.adCreationModel.ad.callExtension!.create!.countryCode, [
      Validators.required
    ]),
    phoneNumber: new FormControl<string>(this.adCreationModel.ad.callExtension!.create!.phoneNumber, [
      Validators.required,
      Validators.min(2)
    ])
  });

  countries: Country[] = COUNTRIES;

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.callForm.valid) {
      const { name, phoneNumber, countryCode } = this.callForm.value;
      this.adCreationModel.ad.callExtension!.create.countryCode = toUpper(countryCode!);
      this.adCreationModel.ad.callExtension!.create.name = name!;
      this.adCreationModel.ad.callExtension!.create.phoneNumber = phoneNumber!;
      this.modal.close();
    }
  }
}
