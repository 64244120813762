import { makeQuery, OffsetPaginatedQueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'accountImages';

type Input = Partial<TikTok.CreateAd.Backend.TikTokImageSearch>;
type Output = OffsetPaginatedQueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokAccountImage>;
export const accountImagesQuery = makeQuery<Output, Input>(`
query TiktokAccountImages($skip: Int, $take:Int, $page:Int) {
  tiktok {
    accountImages(skip:$skip, take:$take, page:$page) {
      edges {
        cursor
        node {
          materialId
          modifyTime
          isCarouselUsable
          imageId
          fileName
          width
          createTime
          size
          displayable
          format
          height
          signature
          imageUrl
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        to
        from
      }
    }
  }
}
`);
