import { Component } from '@angular/core';
import { Platforms } from '@core/models';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { AdPreviewGooglePlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-performance-max-preview--google',
  templateUrl: './performance-max.component.html'
})
export class PerformanceMaxPreviewGoogleComponent {
  Platforms = Platforms;

  platforms = [AdPreviewGooglePlatform];

  constructor(public googleAdPreviewService: GoogleAdPreviewService) {}
}
