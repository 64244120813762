import { environment } from '@environment';
import { map, scan, shareReplay } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphQLService } from '@core/backend';
import * as Query from '@core/queries';

import { Base, calculateState, Facebook, Upload } from '../';

@Injectable({ providedIn: 'root' })
export class FacebookService {
  constructor(private gqlService: GraphQLService, private httpClient: HttpClient) {}

  getOAuthUri$(contextId: string) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getOauthUri({ contextId }));
  }

  getAdAccounts$(contextId: string) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getAdAccounts({ contextId }));
  }

  connectAdAccount$(payload: Facebook.IConnectAdAccountRequest) {
    return this.gqlService.post(Query.FacebookQ.adAccounts.connectAdAccount({ payload }));
  }

  getAccountBreakdowns$<BT extends Facebook.Dashboard.BreakdownType>(
    breakdownType: BT,
    payload: Base.Dashboard.Global.IStartEndDateRequest
  ) {
    return this.gqlService.get(Query.FacebookQ.dashboard.getBreakdowns<BT>(breakdownType)(payload));
  }

  getCampaign(payload: { id: string }) {
    return this.gqlService.get(Query.FacebookQ.dashboard.getCampaignQuery(payload)).pipe(map((r) => r.data.campaign));
  }

  getCampaigns$(payload: Base.Dashboard.Global.IStartEndDateRequest) {
    const body = {
      dateRange: {
        start: payload.start,
        end: payload.end
      },
      after: payload.after,
      before: payload.before,
      first: payload.first,
      last: payload.last,
      name: payload.name
    } as Facebook.Dashboard.GetCampaignsRequest;

    return this.gqlService.get(Query.FacebookQ.dashboard.getCampaigns(body)).pipe(map((r) => r.data.campaigns));
  }

  getCampaignsSmartSorting$(payload: Base.Dashboard.Global.IStartEndDateRequest) {
    const body = {
      dateRange: {
        start: payload.start,
        end: payload.end
      },
      after: payload.after,
      before: payload.before,
      first: payload.first,
      last: payload.last,
      name: payload.name
    } as Facebook.Dashboard.GetCampaignsRequest;

    return this.gqlService
      .get(Query.FacebookQ.dashboard.getCampaignsSmartSortingQuery(body))
      .pipe(map((r) => r.data?.campaignsSmartSorting));
  }

  getInsights$(payload: Base.Dashboard.Global.IStartEndDateRequest & { timeIncrement?: number }) {
    const body = {
      ...(payload.start
        ? {
            dateRange: {
              start: payload.start,
              end: payload.end
            }
          }
        : {}),
      timeIncrement: payload.timeIncrement,
      datePreset: payload.datePreset,
      interval: payload.interval
    };
    return this.gqlService.get(Query.FacebookQ.dashboard.getInsights(body));
  }

  getAdPixels$() {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getAdPixels());
  }

  getCustomConversions$() {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getCustomConversions());
  }

  getInstagramAccounts$(payload: { pageId: string }) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getInstagramAccounts(payload));
  }

  getPages$(contextId: string) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getPages({ contextId }));
  }

  // CREATE AD REQUESTS

  createCampaign$(payload: Facebook.CreateAd.ICreateCampaignRequest) {
    return this.gqlService.post(Query.FacebookQ.adCreation.createCampaign({ payload }));
  }

  createAdSet$(payload: Facebook.CreateAd.ICreateAdSetRequest) {
    return this.gqlService.post(Query.FacebookQ.adCreation.createAdSet({ payload }));
  }

  createAd$(payload: Facebook.CreateAd.ICreateAdRequest) {
    return this.gqlService.post(Query.FacebookQ.adCreation.createAd({ payload }));
  }

  getAdImages$(imageHashes?: string[]) {
    return this.gqlService.get(Query.FacebookQ.adCreation.getAdImages({ imageHashes }));
  }

  getAdVideos$() {
    return this.gqlService.get(Query.FacebookQ.adCreation.getAdVideos({}));
  }

  getAdVideoDetail$(id: string) {
    return this.gqlService.get(Query.FacebookQ.adCreation.getAdVideo({ id }));
  }

  getEstimateReach$(payload: Facebook.CreateAd.IEstimateReachRequest) {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.estimateReach({ payload }));
  }

  getSavedAudiences$() {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.getSavedAudiences());
  }

  getCustomAudiences$() {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.getCustomAudiences());
  }

  getGeoLocations$(keyword: string) {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.targeting.getGeoLocations({ keyword }));
  }

  getLocales$(keyword: string) {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.targeting.getLocales({ keyword }));
  }

  getDetailedTargetings$(keyword: string) {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.targeting.getDetailedTargeting({ keyword }));
  }

  getDetailTargetingSuggesstions$(selections: Facebook.IdTypeModel[]) {
    return this.gqlService.get(
      Query.FacebookQ.adCreation.audience.targeting.getDetailedTargetingSuggesstions({ selections })
    );
  }

  getDetailedTargetingBrowse$() {
    return this.gqlService.get(Query.FacebookQ.adCreation.audience.targeting.getDetailedTargetingBrowse());
  }

  uploadImage$(formData: FormData) {
    const initialState: Upload<Facebook.CreateAd.IUploadImageResponse | null> = { state: 'PENDING', progress: 0 };

    return this.httpClient
      .post<Facebook.CreateAd.IUploadImageResponse>(`${environment.apiUrl}/facebook/uploadImage`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(scan(calculateState, initialState));
  }

  uploadVideo$(formData: FormData) {
    const initialState: Upload = { state: 'PENDING', progress: 0 };

    return this.httpClient
      .post<Facebook.CreateAd.IUploadVideoResponse>(`${environment.apiUrl}/facebook/uploadVideo`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(scan(calculateState, initialState));
  }

  deleteAdObject$(adObjectId: string) {
    return this.gqlService.post(Query.FacebookQ.adCreation.deleteAdObject({ adObjectId }));
  }

  /**
   * @returns Observable.ShareReplayed(1)
   */
  getBusinessPageDetail$() {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getBusinessPageDetail()).pipe(shareReplay());
  }

  /**
   * @returns Observable.ShareReplayed(1)
   */
  getBusinessAdAccountDetail$() {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getBusinessAdAccountDetail()).pipe(shareReplay());
  }

  getAdsets$(request: Facebook.Dashboard.IGetAdsetsOrAdRequest) {
    return this.gqlService.get(Query.FacebookQ.dashboard.getAdsets(request));
  }

  getAds$(request: Facebook.Dashboard.IGetAdsetsOrAdRequest) {
    return this.gqlService.get(Query.FacebookQ.dashboard.getAds(request));
  }

  updateAdObjectStatus$(request: Facebook.Dashboard.IUpdateAdObjectStatusRequest) {
    return this.gqlService.post(Query.FacebookQ.dashboard.updateAdObjectStatus(request));
  }

  updateCampaign$(request: Facebook.Dashboard.IUpdateCampaignRequest) {
    return this.gqlService.post(Query.FacebookQ.dashboard.updateCampaignFacebook(request));
  }

  getPagePosts$(request: Base.IPaginationRequest) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getPagePosts(request));
  }

  getInstagramMedias$(request: Base.IPaginationRequest) {
    return this.gqlService.get(Query.FacebookQ.adAccounts.getInstagramMedias(request));
  }
}
