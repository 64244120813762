import { clickfraudProtectionDetailsBing } from './clickfraud-protection-details-bing.query';
import { clickfraudProtectionDetailsGoogle } from './clickfraud-protection-details-google.query';
import { clickfraudProtectionDetailsLinkedin } from './clickfraud-protection-details-linkedin.query';
import { clickfraudProtectionDetailsFacebook } from './clickfraud-protection-details-facebook.query';
import { turnOffClickfraudProtection } from './turn-off-clickfraud-protection.query';
import { turnOnClickfraudProtection } from './turn-on-clickfraud-protection.query';
import { clickfraudProtectionStatus } from './clickfraud-protection-status.query';

export default {
  platforms: {
    clickfraudProtectionDetailsBing,
    clickfraudProtectionDetailsGoogle,
    clickfraudProtectionDetailsLinkedin,
    clickfraudProtectionDetailsFacebook
  },
  swich: { turnOffClickfraudProtection, turnOnClickfraudProtection },
  status: { clickfraudProtectionStatus }
};
