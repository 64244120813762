import { Component, ViewChild } from '@angular/core';
import { ModalPageComponent } from '@ayn-ui/public-api';
import { AdAccountState, LinkedinAdAccount, LinkedinService } from '@core/index';
import { IAdAccountType, IPlatformContext, Platforms } from '@core/models';
import { PlatformContextState } from '@core/state/platform-context.state';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoaderState } from '@core/state/loader.state';
import { ConnectAdAccountService } from '@shared/components/account-select-modal/services';

@Component({
  selector: 'aayn-ad-account-select--linkedin',
  templateUrl: 'ad-account-select.component.html',
  providers: [ConnectAdAccountService]
})
export class AdAccountSelectLinkedinComponent {
  platforms = Platforms;

  protected activeStep = 0;

  protected query = '';

  protected adAccounts$ = this.store.select(AdAccountState.adAccounts<Platforms.LinkedIn>);

  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .LinkedIn;

  private selectedAdAccount?: IAdAccountType<LinkedinAdAccount>;

  @Select(LoaderState.getAny(['GetOrganizations']))
  buttonLoader$!: Observable<boolean>;

  get completeDisabled() {
    return !this.selectedAdAccount?.selected;
  }

  hasOrganization = (account: IAdAccountType) => {
    if (account?.['reference']?.includes('organization')) {
      const organizationId = account?.['reference']?.split('urn:li:organization:')[1];
      return this.linkedinService.getOrganizations([+organizationId!]).pipe(
        map((result) => {
          if (result.results[+organizationId!]) {
            return true;
          } else if (result.errors[+organizationId!]) {
            let message = result.errors[+organizationId!];
            if (result.statuses[+organizationId] === 403) {
              message = 'Please contact the owner of your LinkedIn Page to obtain the necessary access.';
            }
            this.toastr.error(message);
          } else {
            this.toastr.error('Could not find organization.');
          }
          return false;
        })
      );
    }
    this.toastr.error(
      `
    This LinkedIn Ad Account does not have a LinkedIn Page connected. 
    Please <a class="color:white" href="https://www.linkedin.com/campaignmanager/accounts/${account.id}/edit" target="_blank"> click here </a> to connect one, then return to this page.
    `,
      '',
      {
        enableHtml: true
      }
    );
    return of(false);
  };

  @ViewChild(ModalPageComponent) modal!: ModalPageComponent;

  constructor(
    private linkedinService: LinkedinService,
    private store: Store,
    private toastr: ToastrService,
    private connectAdAccountService: ConnectAdAccountService
  ) {}

  complete() {
    if (this.completeDisabled) return;

    this.connectAdAccountService.connectAdAccount(
      Platforms.LinkedIn,
      { adAccountId: String(this.selectedAdAccount!.id) },
      this.modal.modalRef!
    );
  }

  showCreateNewAccountAccountModal() {
    // TODO: implement
    return;
    /*
    const modalRef = this.modalService.open(CreateAccountLinkedinComponent);

    modalRef.componentInstance.platformContext = this.platformContext!;

    modalRef.closed.subscribe(() => {
      this.getAdAccounts();
    });

     */
  }

  selectAdAccount(account: IAdAccountType<LinkedinAdAccount>) {
    this.selectedAdAccount = account;
  }
}
