import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-detailed-targeting-list',
  template: `
    <ul>
      <ng-content select="aayn-detailed-targeting-item"></ng-content>
    </ul>
  `
})
export class DetailedTargetingListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
