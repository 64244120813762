import { ElementRef } from '@angular/core';
import { defaultInvalidScroll } from './default-scroll.handler';

export function adTypeScrollHandler() {
  return (el: ElementRef<HTMLFormElement>) => {
    if (el.nativeElement.classList.contains('aayn-ad-type--carousel-container--form')) {
      return;
    }
    const invalidFileUploadElem = el.nativeElement.querySelector<HTMLElement>(
      '.ayn-file-upload--inner--description__required'
    );
    const invalidTextAiElem = el.nativeElement.querySelector<HTMLElement>('.aayn-input-group.invalid');
    const invalidControlEl = el.nativeElement.querySelector(
      'input[class="invalid"], input[class*="required"], .ayn-select.invalid, .ayn-input_invalid input'
    );
    let offsetTop = Infinity;
    if (invalidFileUploadElem) {
      offsetTop = invalidFileUploadElem.getBoundingClientRect().top + window.scrollY;
    }
    if (invalidControlEl) {
      const inputOffsetTop = invalidControlEl.getBoundingClientRect().top + window.scrollY;
      if (offsetTop > inputOffsetTop) {
        offsetTop = inputOffsetTop;
      }
    }
    if (invalidTextAiElem) {
      const inputOffsetTop = invalidTextAiElem.getBoundingClientRect().top + window.scrollY;
      if (offsetTop > inputOffsetTop) {
        offsetTop = inputOffsetTop;
      }
    }
    if (offsetTop) {
      window.scrollTo({ top: offsetTop - 200 || el.nativeElement.offsetTop });
      return;
    }

    defaultInvalidScroll(el);
  };
}
