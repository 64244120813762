import { Component, OnInit } from '@angular/core';
import { ContextStatus, IPlatformContext, Platforms } from '@core/models';
import { SharedAdAccountService } from '@core/shared';
import { PlatformContextState } from '@core/state/platform-context.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ayn-connect-google-ads-account-modal',
  templateUrl: './connect-google-ads-account-modal.component.html'
})
export class ConnectGoogleAdsAccountModalComponent implements OnInit {
  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .Google;

  constructor(
    public modal: NgbActiveModal,
    private sharedAdAccountService: SharedAdAccountService,
    private store: Store
  ) {}

  ngOnInit(): void {}

  ConnectGoogleAdsAccount() {
    if (this.platformContext.status === ContextStatus.Connected) {
      this.sharedAdAccountService.updateBusiness(Platforms.Google);
      this.modal.close();
    } else this.openAccountSelectModal();
  }

  openAccountSelectModal() {
    this.sharedAdAccountService.openAuthWindowByPlatform(this.platformContext);
    this.modal.close();
  }
}
