import { Facebook } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { payload: Facebook.CreateAd.ICreateAdSetRequest };
type Output = { createAdSet: string };

export const createAdSet = makeMutation<Output, Input>(`
  mutation CreateAdSet($payload: FacebookAdSetInputDto!) {
    facebook {
      createAdSet(payload:$payload)
    }
  }
`);
