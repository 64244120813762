import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.ICampaignRequest;
type Output = Google.Dashboard.ICampaignWithMetricsResponse;

export const campaignsWithMetricsGoogle = makeMutation<Output, Input>(`
query CampaignsWithMetrics(
  $startDate: SafeInt
  $endDate: SafeInt
  $first: Int
  $status: GoogleInsightStatus
  $after: String
  $before: String
  $searchKey: String
  $datePreset: DatePreset
) {
  google {
    campaignsWithMetricsSmartSorting(
      datePreset: $datePreset
      startDate: $startDate
      endDate: $endDate
      status: $status
      first: $first
      searchKey: $searchKey
      after: $after
      before: $before
    ) {
      edges {
        node {
          isSmartCampaign
          id
          status
          name
          type
          startDate
          endDate
          resourceName
          dailyBudget
          budgetId
          metrics {
            costPerClick
            clicks
            cost
            conversions
            impressions
            ctr
            interactions
            costPerConversion
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        hasPreviousPage
        endCursor
      }
    }
  }
}
`);
