import { ReplaySubject } from 'rxjs';

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

export type OptionValueType = string | number | Object;

export interface IOption<T extends OptionValueType = OptionValueType> {
  label: string;
  value: T;
}

@Component({
  selector: 'ayn-option',
  template: `
    <button
      class="ayn-option"
      type="button"
      (click)="handleClick($event)"
      [ngClass]="{ selected: selected, 'ayn-option__disabled': disabled }"
    >
      <div class="ayn-option--inner">
        <ng-content></ng-content>
      </div>
    </button>
  `
})
export class Option implements OnInit, AfterContentInit, AfterViewInit {
  @Input() value!: OptionValueType;

  @Input() selected = false;

  @Input() stopPropagation = false;

  @Input() disabled = false;

  @Output('selectOption')
  $click = new EventEmitter<Option>();

  @Output() contentInit = new ReplaySubject(1);

  @Output() viewInit = new ReplaySubject(1);

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInit.next();
    });
  }

  get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }

  handleClick(event: Event) {
    if (this.disabled) return;

    if (this.stopPropagation) {
      event.stopPropagation();
    }

    this.$click.emit(this);
  }

  ngOnInit() {}

  ngAfterContentInit(): void {
    this.nativeElement.setAttribute('option-label', this.nativeElement.innerText);
    this.contentInit.next();
  }
}
