<div class="existing-post--tab-row">
  <ayn-form-field class="existing-post--search-input">
    <input type="text" ayn-input [placeholder]="'Enter POST ID Here' | translate" [(ngModel)]="instagramIdTerm" />

    <ayn-icon name="search"></ayn-icon>
  </ayn-form-field>
</div>

<p class="existing-post--error-text mt:4" *ngIf="hasError">
  {{
    'There is no Instagram account linked to your Facebook account. First, connect your Instagram account to your account.'
      | translate
  }}
</p>

<ng-container *ngIf="instagramMedias.length; else noContent">
  <aayn-existing-post-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'; maxWidth: 50">
    <aayn-existing-post-list-item
      *ngFor="let igMedia of instagramMedias | aynFilterObj: { id: instagramIdTerm }"
      [imageUrl]="igMedia.imageUrl"
      [message]="igMedia.caption"
      [date]="igMedia.createdAt"
      [likes]="igMedia.likes"
      [type]="igMedia.type"
      [comments]="igMedia.comments"
      [active]="igMedia.id == adCreationModel.ad.creative.instagramMediaId"
      (click)="onClickMI(igMedia)"
    ></aayn-existing-post-list-item>
  </aayn-existing-post-list>
</ng-container>

<ng-template #noContent>
  <aayn-empty-content
    [title]="'There is no data yet.' | translate"
    [description]="'There are no posts in your Instagram account.' | translate"
    *ayn-loader="loader$ | async"
    class="d-block mb:5"
  ></aayn-empty-content>
</ng-template>
