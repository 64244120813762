import { IGetBusinessByIdResponse } from '@core/models';

import { makeQuery } from '../types';

type Input = { id: string };
type Output = IGetBusinessByIdResponse;

export const findById = makeQuery<Output, Input>(`
query FindById($id: String!) {
  findById(id: $id) {
    id
    name
    createdAt
    category
    description
    connectedAdAccounts {
      id
      pageId
      instagramAccountId
      externalId
      source
      currency
    }
  }
}

`);
