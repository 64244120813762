import { Bing } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Bing.Dashboard.IBreakdownRequest;
type Output<BT extends Bing.Dashboard.BreakdownType> = {
  breakdowns: {
    [key in BT]: Bing.Dashboard.IAccountBreakdownResponse[key];
  };
};

export const breakdownsBing = <BT extends Bing.Dashboard.BreakdownType>(breakdown: Bing.Dashboard.BreakdownType) =>
  makeQuery<Output<BT>, Input>(`
    query BreakdownsBing(
      $startDate: SafeInt
      $endDate: SafeInt
      $datePreset: DatePreset
    ) {
      bing {
        breakdowns(
          startDate: $startDate
          endDate: $endDate
          datePreset: $datePreset
        ) {
          ${breakdown} {
            identifier
            conversions
            costPerConversion
            clicks
            ctr
            impressions
            costPerClick
            cost
            revenue
          }
        }
      }
    }
`);
