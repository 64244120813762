import { Injectable } from '@angular/core';
import { ValidationService } from '../base';
import { createSubject } from '@core/utils';
import { PlatformStep } from '@core/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class InstantAdValidationService extends ValidationService {
  describeProjectValid$ = createSubject(false);
  selectAiGeneratedAdValid$ = createSubject(false);

  getStepValidation(step: PlatformStep): BehaviorSubject<boolean> {
    switch (step) {
      case PlatformStep.DescribeProject:
        return this.describeProjectValid$;
      case PlatformStep.AIGenerated:
      case PlatformStep.BudgetReview:
        return this.selectAiGeneratedAdValid$;
      default:
        return createSubject(false);
    }
  }

  registerForm(
    form: FormGroup,
    step: PlatformStep.DescribeProject | PlatformStep.AIGenerated,
    componentInstance: any
  ): Subscription {
    switch (step) {
      case PlatformStep.DescribeProject:
        return this.register(form, this.describeProjectValid$, componentInstance);
      case PlatformStep.AIGenerated:
        return this.register(form, this.selectAiGeneratedAdValid$, componentInstance);
    }
  }
}
