import { bounceOutDownOnLeaveAnimation, fadeInUpOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { Observable, of, Subscription } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  FormsModule,
  NgControl,
  NgModel,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AynUITranslateModule, AynUITranslateService } from '../../translate/translate.module';
import { ButtonModule } from '../button';
import { IconComponent, IconModule } from '../icon';
import { TooltipModule } from '../tooltip';

type EventTargetWithValue = EventTarget & { value: string };

export type LengthCalculator = (value: string) => number;
export const DEFAULT_INPUT_LENGTH_CALCULATOR: LengthCalculator = (value: string) => value.length;
export const INPUT_LENGTH_CALCULATOR = new InjectionToken<LengthCalculator>('INPUT_LENGTH_CALCULATOR', {
  factory: () => DEFAULT_INPUT_LENGTH_CALCULATOR
});

export function provideLengthCalculator(calculator: LengthCalculator) {
  return { provide: INPUT_LENGTH_CALCULATOR, useValue: calculator };
}

@Directive({ selector: '[aynValidationErrorDisplayStrategy]' })
export class ValidationErrorDisplayStrategyDirective {
  @Input('aynValidationErrorDisplayStrategy')
  errorDisplayStrategy: 'default' | 'wait-submit' = 'default';
}

@UntilDestroy()
@Directive({ selector: '[ayn-input]', exportAs: 'aynInput' })
export class InputDirective implements OnChanges, AfterViewInit {
  public inputLength = 0;

  @Input() set disabled(value: boolean | null | undefined) {
    if (value) this.formControl?.disable();
    else this.formControl?.enable();
  }

  @Input() lengthCalculatorFn?: LengthCalculator;

  get maxCharacterNumber(): number {
    return this.formFieldComponent?.maxCharacterNumber || 0;
  }

  subscription?: Subscription;

  get formControl(): FormControl | undefined {
    if (this.control instanceof FormControlName) {
      return this.control.control;
    } else if (this.control instanceof FormControlDirective) {
      return this.control.form;
    } else if (this.control instanceof NgModel) {
      return this.control.control;
    }
    return undefined;
  }

  constructor(
    @Optional() @Self() public control: NgControl,
    @Optional() private formFieldComponent: FormFieldComponent,
    public el: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private cdRef: ChangeDetectorRef,
    @Inject(INPUT_LENGTH_CALCULATOR) private inputLengthCalculator: LengthCalculator
  ) {}

  setValue(value: string) {
    if (this.formControl) {
      this.formControl.patchValue(value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lengthCalculatorFn) {
      this.calculateLength(this.formControl?.value);
    }
  }

  ngAfterViewInit() {
    this.subscription = this.formControl?.valueChanges?.pipe(untilDestroyed(this)).subscribe((value) => {
      this.calculateLength(value);
      this.cdRef.markForCheck();
    });
  }

  @HostListener('keyup', ['$event'])
  protected onKeyUp(e: KeyboardEvent) {
    this.calculateLength((e?.target as EventTargetWithValue)?.value);
  }

  private calculateLength(value?: string) {
    const calculator = this.lengthCalculatorFn || this.inputLengthCalculator;
    this.inputLength = value ? calculator(value) : 0;
  }
}

@Component({
  selector: 'ayn-form-field',
  template: `
    <div
      class="form-field"
      [class]="class"
      [ngClass]="{
        'ayn-input_invalid': error?.value || showGroupErrorMessage,
        'form-field__has-icon': !!icon || error?.value,
        'ayn-input__disabled': disabled
      }"
    >
      <ng-content select="label, [ayn-label]"></ng-content>

      <div class="form-field--inner">
        <ng-content select=".ayn-recommended"></ng-content>

        <ng-content select=".ayn-prefix"></ng-content>

        <ng-content select="[ayn-input], ayn-select, ayn-datepicker, ayn-input-with-select"></ng-content>

        <span *ngIf="maxCharacterNumber" class="form-field--inner__character-number">
          {{ maxCharacterNumber - length }}
        </span>

        <ng-container *ngIf="error?.value; else elseTemplate">
          <ayn-icon
            name="information"
            [ayn-tooltip]="(error?.message | async) || requiredMessage"
            position="right"
            [show]="true"
            zIndex="99999"
          ></ayn-icon>
        </ng-container>

        <ng-template #elseTemplate>
          <ng-content select="ayn-icon, span"></ng-content>
        </ng-template>

        <ng-content select="[ayn-button]"></ng-content>
      </div>

      <ayn-hint
        *ngIf="hintStyleType == 'default' && ((showErrorMessage && error) || showGroupErrorMessage)"
        [@enter]
        [@leave]
      >
        {{ (error?.message | async) || requiredMessage }}
      </ayn-hint>

      <ng-content select="ayn-hint" [@enter] [@leave]></ng-content>
    </div>
  `,
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100, translate: '30px' }),
    fadeOutUpOnLeaveAnimation({ anchor: 'enter', duration: 1000, delay: 100, translate: '30px' }),
    bounceOutDownOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 200, translate: '40px' })
  ]
})
export class FormFieldComponent implements OnInit, OnChanges {
  @ContentChild(InputDirective, { static: true }) input?: InputDirective;

  @ContentChild(IconComponent) icon?: IconComponent;

  @Input() class = '';

  @Input() maxCharacterNumber?: number;

  @Input() showErrorMessage = true;

  @Input() showGroupErrorMessage = false;

  @HostBinding('class')
  @Input()
  hintStyleType: 'default' | 'streched-for-ad-preview' | 'tooltip' = 'tooltip';

  @Input() requiredMessage = 'Required field.';

  @Input() disabled = false;

  constructor(
    private translateService: AynUITranslateService,
    @Optional() private formGroupDirective?: FormGroupDirective,
    @Optional() private validationErrorDisplayStragegyDirective?: ValidationErrorDisplayStrategyDirective
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const maxCharacterNumber = changes?.maxCharacterNumber?.currentValue;

    if (this.input && maxCharacterNumber) {
      this.input.el.nativeElement.maxLength = maxCharacterNumber as number;
    }
  }

  get error(): { message: Observable<string>; value: any } | null {
    if (this.validationErrorDisplayStragegyDirective?.errorDisplayStrategy === 'wait-submit') {
      if (!this.formGroupDirective) {
        throw new Error('wait-submit stragey only supported with formGroup');
      }
      if (!this.formGroupDirective.submitted) {
        // Input submit
        return null;
      }
    }

    const control = this.input?.control;

    if (!control) return null;

    const errors = Object.entries(control.control?.errors || {});

    if ((!this.formGroupDirective?.submitted && control.untouched) || !errors.length) {
      return null;
    }

    const [key, value] = errors[0];

    return {
      message: this.errorMessage(key, value),
      value
    };
  }

  get length() {
    return this.input?.inputLength || 0;
  }

  private errorMessage(error: string, value: any): Observable<string> {
    switch (error) {
      case 'required':
        return this.translateService.translate(this.requiredMessage);
      case 'minlength':
        return this.translateService.translate('Must be a minimum of {{requiredLength}} characters.', {
          requiredLength: value.requiredLength
        });
      case 'maxlength':
        return this.translateService.translate('Must be a maximum of {{requiredLength}} characters.', {
          requiredLength: value.requiredLength
        });
      case 'email':
        return this.translateService.translate('Email format must be correct.');
      case 'min':
        return this.translateService.translate('Must be at least {{min}}.', { min: value.min });
      case 'max':
        return this.translateService.translate('Must be at most {{max}}.', { max: value.max });
      case 'isUniqueControls':
        return this.translateService.translate('The texts must be different from each other.');
      case 'pattern':
        return this.translateService.translate('Please enter in the appropriate format.');
      case 'matching':
        return this.translateService.translate('Confirm Password does not match');
      case 'minWord':
        return this.translateService.translate('Please write a sentence of at least words.', {
          value: value.requiredLength
        });

      default:
        return of('');
    }
  }
}

@Component({
  selector: 'ayn-hint',
  template: `
    <p class="ayn-form-hint">
      <ng-content></ng-content>
    </p>
  `
})
export class HintComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'ayn-input-with-button',
  template: `
    <section class="ayn-input-with-button" [class]="class">
      <ng-content select="ayn-icon"></ng-content>

      <ng-content select="label"></ng-content>

      <ng-content select="input"></ng-content>

      <ng-content select="[ayn-button]"></ng-content>
    </section>
  `
})
export class InputWithButtonComponent implements OnInit {
  @Input('type') type: 'default' | 'stretch' = 'default';

  @Input() classes = '';

  get class() {
    return this.classes + ' ' + this.type;
  }

  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'ayn-input-with-select',
  template: `
    <section class="ayn-input-with-select" [class]="class">
      <ng-content select="ayn-icon"></ng-content>

      <ng-content select="label"></ng-content>

      <ng-content select="input"></ng-content>

      <ng-content select="ayn-select"></ng-content>
    </section>
  `
})
export class InputWithSelectComponent implements OnInit {
  @Input('type') class: 'default' | 'stretch' = 'default';

  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'ayn-input-with-overlay',
  template: `
    <section class="ayn-input-with-overlay">
      <ng-content select="ayn-form-field"></ng-content>

      <ng-content select="[ayn-button]"></ng-content>

      <ng-content select="ayn-overlay"></ng-content>
    </section>
  `
})
export class InputWithOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'ayn-input-with-label',
  template: `
    <section class="ayn-input-with-label">
      <ng-content select="label"></ng-content>

      <ng-content select="[ayn-input]"></ng-content>
    </section>
  `
})
export class InputWithLabelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

const COMPONENTS = [
  InputDirective,
  FormFieldComponent,
  HintComponent,
  InputWithButtonComponent,
  InputWithSelectComponent,
  InputWithOverlayComponent,
  InputWithLabelComponent,
  ValidationErrorDisplayStrategyDirective
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    ButtonModule,
    AynUITranslateModule,
    TooltipModule
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: []
})
export class InputModule {}
