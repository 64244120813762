<section class="aayn-ad-preview aayn-ad-preview-youtube">
  <section class="aayn-ad-preview-content">
    <div class="aayn-ad-preview-youtube--image">
      <div *ngIf="!image" class="aayn-ad-preview-placeholder"></div>
      <img *ngIf="image" [src]="image" />
      <div class="aayn-ad-preview-youtube--image_skip">
        <span>Skip Ad </span>
        <ayn-icon name="youtube-skip" srcPrefix="/assets/images/svgs/create-ads/"></ayn-icon>
      </div>
    </div>
    <div class="aayn-ad-preview-youtube--content">
      <div class="aayn-ad-preview-youtube--content_header">
        <div class="aayn-ad-preview-youtube--content_header__left">
          <div class="aayn-ad-preview-youtube--logo"><img [src]="logoUrl || logoPlaceholder" /></div>
          <div class="aayn-ad-preview-youtube--texts">
            <p class="aayn-ad-preview-youtube--texts__title">{{ title || titlePlaceholder }}</p>
            <p class="aayn-ad-preview-youtube--texts__url">
              <span class="aayn-ad-preview-youtube--texts__url-ad_info">Ad</span> {{ linkUrl || linkUrlPlaceholder }}
            </p>
          </div>
        </div>
        <div class="aayn-ad-preview-youtube--cta">
          <span>{{ cta || ctaPlaceholder }}</span>
        </div>
      </div>
      <div class="aayn-ad-preview-youtube--description-placeholder"></div>
    </div>
    <div class="aayn-ad-preview-youtube--footer"></div>
  </section>
</section>
