import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOption } from '@ayn-ui/public-api';

@Component({
  selector: 'aayn-audience-language-selection',
  templateUrl: 'language-selection.component.html'
})
export class AudienceLanguageSelectionComponent implements OnInit {
  @Input() languages: IOption[] = [];

  @Output() onLanguageSelect = new EventEmitter<IOption>();

  constructor() {}

  ngOnInit() {}
}
