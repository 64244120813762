import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeautyTextPipe, TitleCasePipe } from './beauty-text.pipe';
import { DurationPipe } from './duration.pipe';
import { FilterObjEqualPipe, FilterObjNotEqualPipe, FilterObjPipe, FilterPipe } from './filter.pipe';
import { DecimalPointPipe } from './number.pipe';
import { PaginationPipe } from './pagination.pipe';
import { SafePipe } from './safe.pipe';
import { FilterTextPipe } from './text.pipe';
import { RoundPipe } from './round.pipe';
import { CastPipe } from './cast.pipe';
import { SortPipe } from './sort.pipe';

const PIPES = [
  FilterPipe,
  FilterObjPipe,
  FilterObjEqualPipe,
  FilterTextPipe,
  PaginationPipe,
  BeautyTextPipe,
  SafePipe,
  TitleCasePipe,
  DurationPipe,
  DecimalPointPipe,
  RoundPipe,
  CastPipe,
  FilterObjNotEqualPipe,
  SortPipe
];

@NgModule({
  imports: [CommonModule],
  exports: PIPES,
  declarations: PIPES,
  providers: []
})
export class PipesModule {}
