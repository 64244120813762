<aayn-manuel-audience-setup>
  <form [formGroup]="form">
    <aayn-audience-group>
      <aayn-audience-item
        title="Location"
        description="Choose your target locations. Your ads will only be shown to people in these areas."
      >
        <aayn-location-selection--tiktok [form]="form"></aayn-location-selection--tiktok>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience--height:300">
      <aayn-audience-item
        [title]="'Age Range' | translate"
        description="Define your target age range. Your ads will only appear to individuals within this age bracket."
      >
        <aayn-audience-age-range
          [value]="this.adCreationModel.audience.minAge"
          [maxValue]="this.adCreationModel.audience.maxAge"
          [sliderOptions]="ageOptions"
          [labelFormatter]="formatLabel"
          [minSliderValue]="13"
          [maxSliderValue]="55"
          (ageRangeChanged)="ageRangeChanged($event)"
        ></aayn-audience-age-range>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Gender' | translate"
        description="Select the gender of your target audience. Your ads will be displayed exclusively to the selected gender."
      >
        <aayn-gender-selection--tiktok [form]="form"></aayn-gender-selection--tiktok>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Audience Language' | translate"
        description="Specify your target language(s). Your ads will only be shown to users who speak the selected language(s)."
      >
        <aayn-language-selection--tiktok [form]="form"></aayn-language-selection--tiktok>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item
        [title]="'Detailed Targeting' | translate"
        description="Determine your target audience to avoid ad display for uninterested individuals."
      >
        <aayn-detailed-targeting--tiktok [form]="form"></aayn-detailed-targeting--tiktok>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item [title]="'Audience Name' | translate">
        <ayn-form-field>
          <input type="text" formControlName="name" ayn-input [placeholder]="'Write a audience name' | translate" />
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>
  </form>
</aayn-manuel-audience-setup>
