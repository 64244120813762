import { Directive, inject, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { Platforms } from '@core/models';
import { SmartCampaignStateService } from '@pages/create-ad/state';

@Directive({
  selector: '[aynSmartHasPlatform]',
  hostDirectives: [
    {
      directive: NgIf,
      inputs: ['ngIfElse: ifHasRoleElse']
    }
  ],
  standalone: true
})
export class HasPlatformDirective {
  private ngIfDirective = inject(NgIf);
  private smartCampaignStateService = inject(SmartCampaignStateService);

  @Input('aynSmartHasPlatform') set platform(platform: Platforms | Platforms[]) {
    const platforms = Array.isArray(platform) ? platform : [platform];
    this.ngIfDirective.ngIf = this.smartCampaignStateService.adCreationModel.selectedPlatforms.some((p) =>
      platforms.includes(p)
    );
  }
}
