import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.IConnectAdAccountRequest;
type Output = Google.IConnectAdAccountResponse;

export const connectAdAccountGoogle = makeMutation<Output, Input>(`
mutation ConnectAdAccount(
  $contextId: String!
  $adAccountId: String!
  $loginId: String
) {
  google {
    connectAdAccount(
      payload: {
        contextId: $contextId
        adAccountId: $adAccountId
        loginId: $loginId
      }
    ) {
      businessId
      connectedAdAccountId
    }
  }
}
`);
