import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Platforms } from '@core/models';
import { Select } from '@ngxs/store';
import {
  BingStateService,
  CreateAdState,
  FacebookStateService,
  GoogleStateService,
  LinkedinStateService,
  SmartCampaignStateService,
  TiktokStateService
} from '@pages/create-ad/state';

@Component({
  selector: 'aayn-select-an-audience-platforms--header',
  templateUrl: './select-an-audience-platforms-header.component.html'
})
export class SelectAnAudiencePlatformsHeaderComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  protected googleAdCreationModel = this.googleStateService.adCreationModel;

  protected facebookAdCreationModel = this.facebookStateService.adCreationModel;

  protected bingAdCreationModel = this.bingStateService.adCreationModel;
  protected linkedinAdCreationModel = this.linkedInStateService.adCreationModel;
  protected tiktokAdCreationModel = this.tiktokStateService.adCreationModel;
  protected smartCampaignAdCreationModel = this.smartCampaignStateService.adCreationModel;

  constructor(
    private googleStateService: GoogleStateService,
    private facebookStateService: FacebookStateService,
    private bingStateService: BingStateService,
    private linkedInStateService: LinkedinStateService,
    private tiktokStateService: TiktokStateService,
    private smartCampaignStateService: SmartCampaignStateService
  ) {}

  get facebookLocations() {
    return this.facebookAdCreationModel.adSet.targeting.geoLocations.inclusions.map((o) => o.name);
  }

  get googleLocations() {
    return this.googleAdCreationModel.audience.create.includedLocations.map((o) => o.fullName.split(',')[0]);
  }

  get bingLocations() {
    return this.bingAdCreationModel.campaign.criteria.target.locationCriterionIds.map((o) => o.fullName.split(',')[0]);
  }

  get linkedinLocations() {
    return this.linkedinAdCreationModel.audience.geoLocations.include.map((o) => o.node.name.split(',')[0]);
  }

  get tiktokLocations() {
    return this.tiktokAdCreationModel.audience.locations.map((o) => o.name.split(',')[0]);
  }

  get smartCampaignLocations() {
    const firstSelectedPlatform = this.smartCampaignAdCreationModel.selectedPlatforms[0];
    return (
      this.smartCampaignAdCreationModel.audience.geoLocations.inclusions[firstSelectedPlatform]?.map(
        (o) => o?.platformSpecificName?.split(',')?.[0] || o.name?.split(',')?.[0]
      ) || []
    );
  }

  ngOnInit(): void {}
}
