import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BingFormService, BingStateService, BingValidationService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-traffic-to-website--bing',
  template: `
    <div class="aayn-select-objective">
      <header class="aayn-select-objective--header">
        <h4 class="aayn-select-objective--header__title">{{ 'Traffic to my website' | translate }}</h4>
        <p class="aayn-select-objective--header__description">
          {{ 'For receiving as many visitors as possible to your website.' | translate }}
        </p>
      </header>

      <form [formGroup]="traficWebsiteForm">
        <aayn-ad-form-input label="Campaign Name">
          <ayn-form-field [maxCharacterNumber]="100">
            <input
              type="text"
              ayn-input
              formControlName="name"
              aaynDraftName
              [placeholder]="'Campaign Name' | translate"
              id="campaignName"
              [(ngModel)]="adCreationModel!.campaign.name"
            />
          </ayn-form-field>
        </aayn-ad-form-input>
      </form>
    </div>
  `
})
export class TraficToWebsiteBingComponent implements OnInit {
  protected adCreationModel = this.bingStateService.adCreationModel;

  traficWebsiteForm = new FormGroup({
    name: new FormControl(this.adCreationModel.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private bingStateService: BingStateService,
    private bingValidationService: BingValidationService,
    private bingForm: BingFormService
  ) {}

  ngOnInit() {
    this.bingForm.registerForm(this.traficWebsiteForm);

    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.bingValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.traficWebsiteForm.markAllAsTouched();
    });

    this.traficWebsiteForm.statusChanges.subscribe((status) => {
      this.bingValidationService.objectiveValid$.next(status === 'VALID');
    });
  }
}
