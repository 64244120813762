import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { createUploadImages, Linkedin, PlatformStep, UploadImageItem } from '@core/models';
import { CanvaService } from '@core/services/canva.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LinkedinImageUploadService } from '@pages/create-ad/components/select-adtype/platforms/linkedin/services/linkedin-image-upload.service';
import {
  LinkedinFormService,
  LinkedinStateService,
  LinkedinValidationService
} from '@pages/create-ad/state/platforms/linkedin';

import { environment } from '@environment';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveArrayItem, moveFormArrayItem } from '@shared/carousel-drag.util';
import { LinkedinAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/ad-preview/ad-preview.service';
import { UploadAdsImagesLinkedinComponent } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/modal';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

@UntilDestroy()
@Component({
  selector: 'aayn-carousel-ads--linkedin',
  templateUrl: './carousel-ads.component.html',
  providers: [
    LinkedinImageUploadService,
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class CarouselAdsLinkedinComponent {
  subscription?: Subscription;

  protected businessPageDetail?: Linkedin.CreateAd.LinkedinOrganizationResponse;
  protected images: UploadImageItem[] = createUploadImages(10);

  protected callToActions = Linkedin.CTA_LABELS;

  adCreationModel = this.linkedinStateService.adCreationModel;
  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel.ad.creative.name, [Validators.required]),
    title: new FormControl(this.adCreationModel.ad.creative.commentary, [Validators.required]),
    landingPageUrl: new FormControl(this.adCreationModel.ad.creative.contentLandingPage, [Validators.required]),
    carouselCards: new FormArray([
      new FormGroup({
        headline: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(0)?.title, [Validators.required]),
        imageUrl: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(0)?.imageUrl),
        imageId: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(0)?.id)
      }),
      new FormGroup({
        headline: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(1)?.title, [Validators.required]),
        imageUrl: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(1)?.imageUrl),
        imageId: new FormControl(this.adCreationModel.ad.creative.carouselCards.at(1)?.id)
      })
    ])
  });

  private _images?: Linkedin.IdWithUrl[];

  constructor(
    private linkedinStateService: LinkedinStateService<Linkedin.AdModelCarouselImage>,
    private linkedinFormService: LinkedinFormService,
    private linkedinValidationService: LinkedinValidationService,
    private canvaService: CanvaService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    public adPreviewService: LinkedinAdPreviewService
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      if (!environment.production) {
        console.error(`CreateAd.Linkedin.CarouselAds.CanvaS ervice`, error);
      }
    }

    this.linkedinStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;
      this._images = this.adCreationModel.ad.creative.carouselCards
        .filter((o) => !!o.imageUrl)
        .map((o) => ({ id: o.id!, url: o.imageUrl! }));

      this.form.patchValue({
        carouselCards: o.ad.creative.carouselCards,
        landingPageUrl: o.ad.creative.contentLandingPage,
        name: o.ad.creative.name,
        title: o.ad.creative.commentary
      });
      if (this.carouselCards.length < this.adCreationModel.ad.creative.carouselCards.length) {
        new Array(this.adCreationModel.ad.creative.carouselCards.length - this.carouselCards.length)
          .fill(0)
          .forEach(() => {
            this.addPaginationItem();
          });
      }
      this.adCreationModel.ad.creative.carouselCards.forEach((card, index) => {
        const control = this.form.controls.carouselCards.controls[index] as FormGroup;
        control.controls.imageUrl.setValue(card.imageUrl!);
        control.controls.imageId.setValue(card.id!);
        control.controls.headline.setValue(card.title!);
      });
      this.form.controls.title.setValue(this.adCreationModel.ad.creative.commentary);
      this.form.controls.name.setValue(this.adCreationModel.ad.creative.name);
      this.form.controls.landingPageUrl.setValue(this.adCreationModel.ad.creative.contentLandingPage);
    });

    this.linkedinFormService.registerForm(this.form);

    this.listenForm();
  }

  addPaginationItem(defaultValue?: Partial<{ headline: string; imageUrl: string; imageId: string }>) {
    this.form.controls.carouselCards.push(
      new FormGroup({
        headline: new FormControl<string | null | undefined>(defaultValue?.headline, [Validators.required]),
        imageUrl: new FormControl<string | null | undefined>(defaultValue?.imageUrl),
        imageId: new FormControl<Linkedin.ImageUrn | null | undefined>(
          (defaultValue?.imageId || '') as Linkedin.ImageUrn
        )
      })
    );
  }

  listenForm() {
    if (!this.subscription?.closed) {
      this.subscription?.unsubscribe();
    }
    this.subscription = new Subscription();
    const formValueChange = this.form.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        this.adCreationModel.ad.creative.carouselCards = value.carouselCards!.map((o) => ({
          title: o.headline!,
          id: o.imageId! as Linkedin.ImageUrn,
          imageUrl: o.imageUrl!
        }));

        this.adCreationModel.ad.creative.name = value.name!;
        this.adCreationModel.ad.creative.contentLandingPage = value.landingPageUrl!;
        this.adCreationModel.ad.creative.commentary = value.title!;
        this.linkedinStateService.saveDraft$();
      });

    const formStatusChange = this.linkedinValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.subscription.add(formValueChange);
    this.subscription.add(formStatusChange);
  }

  deletePaginationItem(index: number) {
    this.form.controls.carouselCards.removeAt(index, { emitEvent: true });
    this._images = this._images?.filter((_, i) => i !== index);
  }

  get carouselCards() {
    return this.form.controls['carouselCards'] as FormArray;
  }

  dropped(event: CdkDragDrop<any>) {
    moveFormArrayItem(this.form.controls.carouselCards, event.previousIndex, event.currentIndex);
    this.images = moveArrayItem(this.images, event.previousIndex, event.currentIndex);
    if (this._images) {
      this._images = moveArrayItem(this._images, event.previousIndex, event.currentIndex);
    }
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesLinkedinComponent);
    (modalRef.componentInstance as UploadAdsImagesLinkedinComponent).setImages(10);
    if (this._images) modalRef.componentInstance.uploadedImages = this._images;
    modalRef.closed.subscribe((result: { images?: Linkedin.IdWithUrl[] }) => {
      if (!result?.images) return;

      this._images = result!.images;

      if (this.form.controls.carouselCards.length < this._images!.length) {
        this._images!.filter((_, index) => this.form.controls.carouselCards.length <= index).forEach(() => {
          this.addPaginationItem();
        });
      } else if (this.form.controls.carouselCards.length > this._images.length) {
        const length = this.form.controls.carouselCards.length - this._images.length;
        for (let i = 0; i < length; i++) {
          this.form.controls.carouselCards.at(this.form.controls.carouselCards.length - 1).patchValue({
            imageUrl: '',
            imageId: '' as Linkedin.ImageUrn
          });
        }
      }

      this.carouselCards.controls.forEach((card, index) => {
        const control = card as FormGroup;
        control.controls.imageUrl.setValue(this._images![index]?.url);
        control.controls.imageId.setValue(this._images![index]?.id);
      });
      this.linkedinStateService.saveDraft$();

      this.cdr.detectChanges();
    });
  }
}
