import { Bing } from '@core/models';
import { makeMutation, makeQuery } from '@core/queries';

type Input = Bing.AudienceCampaign.GetCurrencyConstantValueRequest;
type Output = Bing.AudienceCampaign.GetCurrencyConstantValueResponse;

export const getCurrencyConstantValue = makeQuery<Output, Input>(`
query GetCurrencyConstantValue(
  $currency: BingCurrency!
  $type: BingCurrencyConstantType!
) {
  bing {
    getCurrencyConstantValue(currency: $currency, type: $type)
  }
}
`);
