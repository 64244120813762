import { Observable } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import { IPackage, MySubscription, PeriodUnit } from '@core/models';
import { ChargebeeService } from '@core/services';
import { GetMySubscription } from '@core/state';
import { LoaderState } from '@core/state/loader.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { UpgradeSuccessComponent } from '../upgrade-success';

@Component({
  selector: 'aayn-upgrade-confirmation',
  templateUrl: 'upgrade-confirmation.component.html'
})
export class UpgradeConfirmationComponent implements OnInit {
  @Input() oldPackage!: Pick<MySubscription, 'package'>['package'];

  @Input() totalDiscountPercentage = 0;

  @Input() newPackage!: IPackage;

  @Input() title = 'You are upgrading!';

  @Input()
  set description(value) {
    this._description = value;
  }
  get description() {
    return (
      this._description ||
      `You are upgrading your plan. Your new 
      ${this.isMonthly ? 'monthly' : 'yearly'}
     amount is shown below.`
    );
  }

  get isMonthly() {
    return this.newPackage?.periodUnit === PeriodUnit.Month;
  }

  _description?: string;

  @Select(LoaderState.getAny('SubscribeToPlan')) loader$?: Observable<boolean>;

  constructor(private chargebeeService: ChargebeeService, private modalService: NgbModal, private store: Store) {}

  ngOnInit() {}

  upgradeNow() {
    if (!this.newPackage) return;

    this.chargebeeService.subscribeToPlan$(this.newPackage.id).subscribe((result) => {
      if (result.data) {
        this.modalService.open(UpgradeSuccessComponent, {
          modalDialogClass: 'aayn-modal-for-payments'
        });

        this.store.dispatch(new GetMySubscription());
      }
    });
  }
}
