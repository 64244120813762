import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.IAdAccountRequest;
type Output = Google.IAdAccountResponse;

export const adAccountsGoogle = makeMutation<Output, Input>(`
query AdAccounts($contextId:String!) {
  google {
    adAccounts(contextId: $contextId) {
      status
      accessRole
      adAccountId
      loginCustomerId
      currency
      name
    }
  }
}
`);
