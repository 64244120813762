import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Output = Bing.AudienceCampaign.IBingConversionGoalOutputReponse;

export const getConversionGoalsBing = makeQuery<Output>(`
query GetConversionGoals {
  bing {
    getConversionGoals {
      name
      id
    }
  }
}
`);
