import { Google } from '@core/models';
import { QueryResult, makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.GoogleSearchCampaignRequest;
type Output = QueryResult<'createSearchCampaign', boolean>;

export const createSearchCampaign = makeMutation<Output, Input>(`
  mutation CreateSearchCampaign($campaign: GoogleSearchCampaignDto!) {
    google {
      createSearchCampaign(campaign: $campaign)
    }
  }
`);
