import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aayn-alert-bar',
  templateUrl: 'alert-bar.component.html'
})
export class AlertBarComponent implements OnInit {
  @Input() title = '';

  @Input() text = '';

  @Input() buttonText = '';

  @Input() buttonIcon = 'rocket-fill';

  @Input() leftIcon: string | undefined;

  @Input() buttonLoading = false;

  @Output()
  onClickHide = new EventEmitter();

  @Output() onButtonClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
