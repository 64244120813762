<aayn-ad-preview-card [selectedPlatform]="Platforms.LinkedIn" [platforms]="platforms">
  <aayn-ad-preview-linkedin-single
    [businessName]="businessName"
    [businessImage]="profileImage"
    [websiteUrl]="adPreviewService.dynamicImage.websiteUrl || ''"
    [cta]="adPreviewService.dynamicImage.callToAction || ''"
    [headline]="adPreviewService.dynamicImage.title || ''"
    [description]="adPreviewService.dynamicImage.description || ''"
    [imageUrls]="adPreviewService.dynamicImage.images || []"
  ></aayn-ad-preview-linkedin-single>
</aayn-ad-preview-card>
