import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.IBingEditCampaignBudgetsRequest;
type Output = { editCampaignBudgets: boolean };

export const editCampaignBudgetsBing = makeMutation<Output, Input>(`
  mutation EditCampaignBudgets(
  $campaignIds: [String!]!
  $dailyBudgetAmount: Float!
) {
  bing {
    editCampaignBudgets(
      campaignIds: $campaignIds
      dailyBudgetAmount: $dailyBudgetAmount
    )
  }
}
`);
