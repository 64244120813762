import { environment } from '@environment';
import * as confetti from 'canvas-confetti';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfettiService {
  private _canvas = document.createElement('canvas');

  private count = 200;
  private defaults = {
    origin: { y: 0.7, x: 0.35 }
  };

  constructor() {
    this._canvas.classList.add('d-none');
    document.body.appendChild(this._canvas);
  }

  private _fire(particleRatio: number, opts: any) {
    if (!environment.production) return;
    const myConfetti = confetti.create(this._canvas, {
      resize: true,
      useWorker: true
    });

    myConfetti(
      Object.assign({}, this.defaults, opts, {
        particleCount: Math.floor(this.count * particleRatio)
      })
    );
    myConfetti(
      Object.assign({}, this.defaults, opts, {
        particleCount: Math.floor(this.count * particleRatio),
        origin: {
          y: 0.7,
          x: 0.65
        }
      })
    );
  }

  fireRealisticLook() {
    this._canvas.classList.add('aayn-confetti-canvas');
    this._canvas.classList.remove('d-none');

    this._fire(0.25, {
      spread: 26,
      startVelocity: 55
    });

    this._fire(0.2, {
      spread: 60
    });

    this._fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    this._fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    this._fire(0.1, {
      spread: 120,
      startVelocity: 45
    });

    setTimeout(() => {
      this._canvas.classList.remove('aayn-confetti-canvas');
      this._canvas.classList.add('d-none');
    }, 1000);
  }
}
