import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, NgModule, Output } from '@angular/core';
import { IconModule } from '../icon';

export interface Step {
  title: string;
  description: string | null;
  status: 'not-set' | 'passed' | 'active';
  className?: string;
  iconName?: string;
  showStatusIcon?: boolean;
}

@Component({
  selector: 'ayn-stepper',
  templateUrl: 'stepper.html'
})
export class Stepper {
  @Input() steps: Step[] = [];

  @Input() activeStep!: number;

  @Output() activeStepChange = new EventEmitter<number>();

  @Input() clickable = false;

  @Input()
  @HostBinding('class.stepper--block')
  isBlock = false;

  constructor() {}

  ngOnInit() {
    this.setActive(this.activeStep);
  }

  get activeStepIndex() {
    if (this.steps) {
      return this.steps.findIndex((o) => o.status === 'active');
    }
    return -1;
  }

  prev() {
    if (this.steps) {
      const activeIndex = this.activeStepIndex;

      if (activeIndex > 0) {
        this.steps[activeIndex].status = 'not-set';

        const prevIndex = activeIndex - 1;

        if (prevIndex !== -1) {
          this.steps[prevIndex].status = 'active';

          this.activeStepChange.emit(prevIndex);
        }
      }
    }
  }

  public next() {
    if (this.steps) {
      const activeItemIndex = this.steps.findIndex((o) => o.status === 'active');

      if (activeItemIndex > -1 && this.steps.length - 1 >= activeItemIndex) {
        this.steps[activeItemIndex].status = 'passed';

        const nextIndex = activeItemIndex + 1;

        if (this.steps[nextIndex]) {
          this.steps[nextIndex].status = 'active';

          this.activeStepChange.emit(nextIndex);
        }
      }
    }
  }

  setActive(index: number) {
    this.steps.forEach((o, i) => {
      o.status = 'not-set';

      if (index > i) {
        o.status = 'passed';
      }

      if (index == i) {
        o.status = 'active';

        this.activeStepChange.next(index);
      }
    });
  }
}

@NgModule({
  imports: [CommonModule, IconModule.forRoot()],
  exports: [Stepper],
  declarations: [Stepper],
  providers: []
})
export class StepperModule {}
