import { pipe } from 'fp-ts/lib/function';
import { modifyW, remove, rename, upsert } from 'spectacles-ts';

import { IOption } from '@ayn-ui/lib/modules';
import { TiktokCreateAd } from '@core/ad-platforms/tiktok';
import { IdWithUrl as IdWithUrlCore, TikTok } from '@core/models';

export module __CreateAd {
  export module Client {
    export interface TikTokCampaignClientDto {
      campaignName: string;
      objectiveType: Backend.ObjectiveType;
      pixelId?: string;
      pixelEventCode?: string;
      budgetType: Backend.TikTokBudgetEnumType;
      budgetAmount: number;
      startDate: Date;
      endDate?: Date | null;
      paused?: boolean;
      identity: TikTok.Business.TikTokIdentityOutputDto;
    }

    export interface TikTokCampaignAudienceDto {
      locations: Backend.TikTokLocationSearchOutputDto[];
      zipCodes?: string[];
      includedCustomAudiences?: Backend.TikTokCustomAudienceSearchOutputDto[];
      excludedCustomAudiences?: Backend.TikTokCustomAudienceSearchOutputDto[];
      languages?: Backend.TikTokLanguageSearchOutputDto[];
      male?: boolean;
      female?: boolean;
      minAge: number;
      maxAge: number;
      interests?: Backend.TikTokInterestSearchOutputDto[];
      keywords?: Backend.TikTokInterestSearchOutputDto[];
      name: string;
    }

    export interface TikTokDynamicCreativeAd {
      callToAction: IOption;
      adTexts: string[];
      images: IdWithUrl[];
      videos: IdWithUrl[];
      name: string;
      url: string;
    }

    export enum Gender {
      Men,
      Women
    }

    export type TikTokLibraryAsset<T extends Backend.TikTokAccountImage | Backend.TikTokAccountVideo> = {
      selected?: boolean;
      type?: 'image' | 'video';
    } & T;

    export interface IdWithUrl extends IdWithUrlCore<string> {
      type?: 'image' | 'video';
    }
  }

  export module Backend {
    export enum ObjectiveType {
      // AppPromotion = 'AppPromotion',
      WebConversions = 'WebConversions',
      // Reach = 'Reach',
      Traffic = 'Traffic'
      // VideoViews = 'VideoViews',
      // ProductSales = 'ProductSales',
      // Engagement = 'Engagement',
      // LeadGeneration = 'LeadGeneration',
      // RfReach = 'RfReach',
    }
    export enum TikTokBudgetEnumType {
      DailyBudget = 'DailyBudget',
      // DynamicDailyBudget = 'DynamicDailyBudget',
      TotalBudget = 'TotalBudget'
      // UnlimitedBudget = 'UnlimitedBudget',
    }

    export enum TikTokCallToAction {
      APPLY_NOW = 'APPLY_NOW',
      BOOK_NOW = 'BOOK_NOW',
      CALL_NOW = 'CALL_NOW',
      CONTACT_US = 'CONTACT_US',
      DOWNLOAD_NOW = 'DOWNLOAD_NOW',
      EXPERIENCE_NOW = 'EXPERIENCE_NOW',
      GET_QUOTE = 'GET_QUOTE',
      GET_SHOWTIMES = 'GET_SHOWTIMES',
      GET_TICKETS_NOW = 'GET_TICKETS_NOW',
      INSTALL_NOW = 'INSTALL_NOW',
      INTERESTED = 'INTERESTED',
      LEARN_MORE = 'LEARN_MORE',
      LISTEN_NOW = 'LISTEN_NOW',
      ORDER_NOW = 'ORDER_NOW',
      PLAY_GAME = 'PLAY_GAME',
      PREORDER_NOW = 'PREORDER_NOW',
      READ_MORE = 'READ_MORE',
      SEND_MESSAGE = 'SEND_MESSAGE',
      SHOP_NOW = 'SHOP_NOW',
      SIGN_UP = 'SIGN_UP',
      SUBSCRIBE = 'SUBSCRIBE',
      VIEW_NOW = 'VIEW_NOW',
      VIEW_PROFILE = 'VIEW_PROFILE',
      VISIT_STORE = 'VISIT_STORE',
      WATCH_LIVE = 'WATCH_LIVE',
      WATCH_NOW = 'WATCH_NOW',
      JOIN_THIS_HASHTAG = 'JOIN_THIS_HASHTAG',
      SHOOT_WITH_THIS_EFFECT = 'SHOOT_WITH_THIS_EFFECT',
      VIEW_VIDEO_WITH_THIS_EFFECT = 'VIEW_VIDEO_WITH_THIS_EFFECT'
    }

    export const CTA_OPTIONS: IOption<TikTokCallToAction>[] = [
      { label: 'Apply now', value: TikTokCallToAction.APPLY_NOW },
      { label: 'Book now', value: TikTokCallToAction.BOOK_NOW },
      { label: 'Call now', value: TikTokCallToAction.CALL_NOW },
      { label: 'Contact us', value: TikTokCallToAction.CONTACT_US },
      { label: 'Download', value: TikTokCallToAction.DOWNLOAD_NOW },
      { label: 'Experience now', value: TikTokCallToAction.EXPERIENCE_NOW },
      { label: 'Get quote', value: TikTokCallToAction.GET_QUOTE },
      { label: 'Get showtimes', value: TikTokCallToAction.GET_SHOWTIMES },
      { label: 'Get tickets now', value: TikTokCallToAction.GET_TICKETS_NOW },
      { label: 'Install now', value: TikTokCallToAction.INSTALL_NOW },
      { label: 'Interested', value: TikTokCallToAction.INTERESTED },
      { label: 'Learn more', value: TikTokCallToAction.LEARN_MORE },
      { label: 'Listen now', value: TikTokCallToAction.LISTEN_NOW },
      { label: 'Order now', value: TikTokCallToAction.ORDER_NOW },
      { label: 'Play game', value: TikTokCallToAction.PLAY_GAME },
      { label: 'Pre-order now', value: TikTokCallToAction.PREORDER_NOW },
      { label: 'Read more', value: TikTokCallToAction.READ_MORE },
      { label: 'Send message', value: TikTokCallToAction.SEND_MESSAGE },
      { label: 'Shop now', value: TikTokCallToAction.SHOP_NOW },
      { label: 'Sign up', value: TikTokCallToAction.SIGN_UP },
      { label: 'Subscribe', value: TikTokCallToAction.SUBSCRIBE },
      { label: 'View now', value: TikTokCallToAction.VIEW_NOW },
      { label: 'Visit store', value: TikTokCallToAction.VISIT_STORE },
      { label: 'Watch now', value: TikTokCallToAction.WATCH_NOW }
    ];

    export interface TikTokPixelDto {
      limit?: number;
    }
    export interface TikTokCampaignAssetsDto {
      callToAction: string;
      adTexts: string[];
      imageIds: string[];
      videoIds: string[];
    }

    export interface TikTokCampaignAudienceDto {
      locationIds: string[];
      zipCodes?: string[];
      includedCustomAudienceIds?: string[];
      excludedCustomAudienceIds?: string[];
      languages?: string[];
      male?: boolean;
      female?: boolean;
      minAge: number;
      maxAge: number;
      interestCategoryIds?: string[];
      keywordIds?: string[];
    }

    export interface TikTokCampaignDto {
      campaignName: string;
      objectiveType: ObjectiveType;
      pixelId?: string;
      pixelEventCode?: string;
      url: string;
      budgetType: TikTokBudgetEnumType;
      budgetAmount: number;
      startDate: number;
      endDate?: number;
      assets: TikTokCampaignAssetsDto;
      audience: TikTokCampaignAudienceDto;
      paused?: boolean;
      identityId: string;
    }

    export interface TikTokPixelSearchOutput {
      name?: string;
      id?: string;
      category?: string;
      status?: string;
      events?: TikTokPixelEventOutputDto[];
    }
    export interface TikTokPixelEventOutputDto {
      name?: string;
      code?: string;
    }

    export interface LocationSearch {
      searchKey: string;
      objective: ObjectiveType;
    }
    export interface TikTokLocationSearchOutputDto {
      name: string;
      level: string;
      id: string;
      parentId: string;
      nextLevelIds: string[];
    }

    export interface InterestSearch {
      searchKey: string;
      limit?: number;
    }

    export interface TikTokInterestSearchOutputDto {
      name: string;
      id: string;
    }

    export interface LanguageSearch {
      searchKey: string;
    }

    export interface CustomAudienceSearch {
      limit?: number;
    }

    export interface TikTokCustomAudienceSearchOutputDto {
      name: string;
      id: string;
      type: string;
    }

    export interface TikTokLanguageSearchOutputDto {
      name: string;
      code: string;
    }

    export type TikTokUploadImageResponse = {
      createTime: string | null;
      displayable: boolean;
      fileName: string;
      format: string;
      height: number;
      imageId: string;
      imageUrl: string;
      materialId: string;
      modifyTime: string | null;
      signature: string;
      size: number;
      width: number;
    };

    export interface TikTokAccountImage extends TikTokUploadImageResponse {
      isCarouselUsable: boolean;
    }

    type TikTokPageInfo = {
      page: number;
      totalNumber: number;
      totalPage: number;
      pageSize: number;
    };

    export interface TikTokImageSearch extends TikTok.TiktokPaginationRequest {
      imageIds?: string[];
    }

    export interface TikTokVideoSearch extends TikTok.TiktokPaginationRequest {
      videoIds?: string[];
    }

    export interface TikTokVideo {
      allowDownload: boolean;
      allowedPlacements: string[];
      bitRate: number;
      createTime: string;
      displayable: boolean;
      duration: number;
      fileName: string;
      fixTaskId: string;
      flawTypes: string[];
      format: string;
      height: number;
      materialId: string;
      modifyTime: string;
      previewUrl: string;
      signature: string;
      size: number;
      videoCoverUrl: string;
      videoId: string;
      width: number;
    }

    export interface TikTokExistVideoUpload extends Omit<TikTokVideo, 'fixTaskId' | 'flawTypes'> {
      previewUrlExpireTime: string;
    }

    export type TikTokVideoUploadResponse = Array<
      Required<Pick<TikTokVideo, 'videoId'>> & Partial<Omit<TikTokVideo, 'videoId'>>
    >;

    export type TikTokAccountVideo = Omit<TikTokVideo, 'fixTaskId' | 'flawTypes' | 'materialId'>;
  }

  export module Utils {
    export const mapAudience = (
      model: TiktokCreateAd.ICreateAdModel<TikTok.CreateAd.Client.TikTokDynamicCreativeAd>
    ) => {
      const audienceModel = pipe(
        { audience: model.audience },
        modifyW('audience.excludedCustomAudiences?.[]>', (audience) => audience.id),
        rename('audience.excludedCustomAudiences', 'excludedCustomAudienceIds'),

        modifyW('audience.includedCustomAudiences?.[]>', (audience) => audience.id),
        rename('audience.includedCustomAudiences', 'includedCustomAudienceIds'),

        modifyW('audience.keywords?.[]>', (o) => o.id),
        rename('audience.keywords', 'keywordIds'),

        modifyW('audience.interests?.[]>', (audience) => audience.id),
        rename('audience.interests', 'interestCategoryIds'),

        modifyW('audience.languages?.[]>', (audience) => audience.code),

        modifyW('audience.locations.[]>', (audience) => audience.id),
        rename('audience.locations', 'locationIds'),
        remove('audience.name')
      );

      return pipe(model, upsert('campaign', 'audience', audienceModel.audience));
    };

    export const mapDynamicCreativeAd = (
      model: Pick<TiktokCreateAd.ICreateAdModel<TikTok.CreateAd.Client.TikTokDynamicCreativeAd>, 'campaign' | 'ad'>
    ) => {
      const adModel = pipe(
        model,
        modifyW('ad.images.[]>', (image) => image.id),
        rename('ad.images', 'imageIds'),

        modifyW('ad.videos.[]>', (video) => video.id),
        rename('ad.videos', 'videoIds'),

        modifyW('ad.callToAction', (cta) => cta.value),
        upsert('campaign', 'url', model.ad.url),

        remove('ad.url'),
        remove('ad.name')
      );

      return pipe(adModel, upsert('campaign', 'assets', adModel.ad));
    };

    export const mapDates = (
      model: Pick<TiktokCreateAd.ICreateAdModel<TikTok.CreateAd.Client.TikTokDynamicCreativeAd>, 'campaign'>
    ) => {
      return pipe(
        model,
        modifyW('campaign.startDate', (o) => o.getTime() / 1000),
        modifyW('campaign.endDate?', (o) => o.getTime() / 1000)
      );
    };

    export const mapIdentity = (model: {
      campaign: Pick<
        TiktokCreateAd.ICreateAdModel<TikTok.CreateAd.Client.TikTokDynamicCreativeAd>['campaign'],
        'identity'
      >;
    }) => {
      const adModel = pipe(model, upsert('campaign', 'identityId', model.campaign.identity.identityId));
      return pipe(adModel, remove('campaign.identity'));
    };
  }

  export enum AdType {
    DYNAMIC_CREATIVE_ADS = 'DYNAMIC_CREATIVE_ADS'
  }

  export enum AudienceType {
    MANUAL_AUDIENCE = 'MANUAL_AUDIENCE'
  }
}
