import { Base, Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Base.Dashboard.Global.IStartEndDateRequest;
type Output<BT extends Facebook.Dashboard.BreakdownType> = {
  breakdowns: {
    [key in BT]: Facebook.Dashboard.IAccountBreakdownResponse[key];
  };
};

export const getBreakdowns = <BT extends Facebook.Dashboard.BreakdownType>(
  breakdown: Facebook.Dashboard.BreakdownType
) =>
  makeQuery<Output<BT>, Input>(`
query GetBreakdowns($datePreset: FacebookInsightDatePreset) {
  facebook {
    breakdowns(datePreset: $datePreset) {
       ${breakdown} {
        ${breakdown}
        impressions
        costPerClick
        clicks
        averageCost1KImpressions
        averageCost1KReach
        ctr
        reach
      }
    }
  }
}
`);
