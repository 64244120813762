import { Injectable, QueryList } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { startWith, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

@UntilDestroy()
@Injectable()
export class CdkTableService {
  viewports?: QueryList<CdkVirtualScrollViewport>;
  calculateHeight$?: ReplaySubject<any>;
  scrollElementCalculator?: () => any[];

  subscription = new Subscription();

  registerViewport(
    viewport: QueryList<CdkVirtualScrollViewport>,
    calculateHeight$: ReplaySubject<any>,
    scrollCalculator: () => any[]
  ) {
    this.viewports = viewport;
    this.calculateHeight$ = calculateHeight$;
    this.scrollElementCalculator = scrollCalculator;
  }

  listen() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = new Subscription();
    }
    let destroy$ = new Subject();
    const viewSubscription = this.viewports?.changes
      .pipe(startWith(this.viewports), untilDestroyed(this))
      .subscribe((V) => {
        this.calculateHeight$?.next();
        destroy$.next();
        const streamSubscription = this.calculateHeight$
          ?.pipe(untilDestroyed(this), takeUntil(destroy$))
          .subscribe(() => this.calculateHeight());

        this.subscription.add(streamSubscription);
      });
    this.subscription.add(viewSubscription);
  }

  calculateHeight() {
    if (this.viewports?.length) {
      const element = this.viewports.first.elementRef.nativeElement;
      const scrollElements = this.scrollElementCalculator ? this.scrollElementCalculator() : [];
      const tdElement = Array.from(element.querySelectorAll('td')).filter((element) => element.clientHeight)[0];
      const singleTdHeight = tdElement?.clientHeight || 0;
      const rowCount = (scrollElements?.length || 0) > 5 ? 5 : scrollElements?.length || 0;
      const totalRowHeight = rowCount * singleTdHeight;
      const headerHeight = element.querySelector('th')?.clientHeight || 0;
      const tableComponent = element.querySelector<HTMLDivElement>('ayn-table');
      const tableElement = tableComponent!.querySelector('table');
      const borderSpacingVertical = this.getPropertyHeight(tableElement, ['border-spacing']);
      const borderSpacingHeight = borderSpacingVertical * 2 * (rowCount + 1);
      const tablePadding = this.getPropertyHeight(tableComponent, ['padding', 'border']);

      this.viewports.first.getElementRef().nativeElement.style.height =
        totalRowHeight + headerHeight + tablePadding + borderSpacingHeight + 'px';
      this.viewports.first.checkViewportSize();
    }
  }

  getPropertyHeight(element: HTMLElement | null, calculate: Array<'padding' | 'border' | 'border-spacing'>) {
    if (!element) {
      return 0;
    }
    let calculatedHeight = 0;
    if (calculate.includes('padding')) {
      const { paddingTop, paddingBottom } = getComputedStyle(element);
      calculatedHeight += +(paddingTop.split('px')[0] || 0) + +(paddingBottom.split('px')[0] || 0);
    }
    if (calculate.includes('border')) {
      const { borderTopWidth, borderBottomWidth } = getComputedStyle(element);
      calculatedHeight += +(borderTopWidth.split('px')[0] || 0) + +(borderBottomWidth.split('px')[0] || 0);
    }

    if (calculate.includes('border-spacing')) {
      const { borderSpacing } = getComputedStyle(element);
      const [horizontalOrAll, vertical] = borderSpacing.replace(' ', '').split('px');
      calculatedHeight += +(vertical || horizontalOrAll || 0);
    }

    return calculatedHeight;
  }
}
