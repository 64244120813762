import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IOption, ModalPageComponent, Step } from '@ayn-ui/public-api';
import { AdAccountState, ConnectAdAccountMap, FacebookAdAccount, FacebookService, Platforms } from '@core/index';
import { Facebook, IAdAccountType, IPlatformContext } from '@core/models';
import { PlatformContextState } from '@core/state/platform-context.state';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { ConnectAdAccountService } from '@shared/components/account-select-modal/services';

@Component({
  selector: 'aayn-ad-account-select--facebook',
  templateUrl: 'ad-account-select.component.html',
  providers: [ConnectAdAccountService]
})
export class AdAccountSelectFacebookComponent implements OnInit {
  platforms = Platforms;

  protected steps: Step[] = [
    {
      title: 'Page Select',
      description: null,
      status: 'active',
      className: ''
    },
    {
      title: 'Ad Account Select',
      description: null,
      status: 'not-set',
      className: ''
    }
  ];

  protected activeStep = 0;

  protected query = '';

  protected adAccounts$ = this.store.select(AdAccountState.adAccounts<Platforms.Meta>);

  protected pages: Facebook.IPage[] = [];

  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .Meta;

  protected buttonText = 'Page Select';

  protected instagramAccounts: IOption[] = [];

  protected selectedPage?: Facebook.IPage;

  protected selectedInstagramAccountId?: string;

  private selectedAdAccount?: IAdAccountType<FacebookAdAccount>;

  @ViewChild(ModalPageComponent) modal!: ModalPageComponent;

  loader$ = new BehaviorSubject<boolean>(false);

  get metaPlatform() {
    return this.store.selectSnapshot(AdAccountState.selectedPlatformAdAccount(Platforms.Meta));
  }

  get disabled() {
    if (this.activeStep === 0) {
      return !this.selectedInstagramAccountId;
    }

    return !this.selectedAdAccount?.selected;
  }
  constructor(
    private facebookService: FacebookService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private connectAdAccountService: ConnectAdAccountService
  ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    if (!this.platformContext?.id) return;
    this.loader$.next(true);
    this.facebookService.getPages$(this.platformContext.id).subscribe(({ error, data }) => {
      if (error) {
        this.loader$.next(false);
        return;
      }
      this.pages = data.pages.edges.map((o) => o.node);
      const selectedAccount = this.store.selectSnapshot(AdAccountState.selectedPlatformAdAccount(Platforms.Meta));
      const pageId = selectedAccount?.pageId || this.metaPlatform?.pageId;
      this.selectedPage = this.pages?.find((x) => x.id == pageId);
      if (this.selectedPage) {
        this.getInstagramAccounts();
      } else {
        this.loader$.next(false);
      }
    });
  }

  queryChange(query: string) {
    this.query = query;

    this.cdr.detectChanges();
  }

  onButtonClick() {
    if (this.disabled) return;
    if (this.activeStep == 1) {
      const selectedAdAccount: ConnectAdAccountMap<Platforms.Meta> = {
        adAccountId: this.selectedAdAccount?.id!,
        pageId: this.selectedPage!.id,
        instagramAccountId: this.selectedInstagramAccountId!
      };

      if (!selectedAdAccount?.adAccountId) return;
      this.connectAdAccountService.connectAdAccount(Platforms.Meta, selectedAdAccount, this.modal.modalRef!);
    }

    this.activeStep = 1;
    this.query = '';

    this.steps[0].status = 'passed';
    this.steps[1].status = 'active';
  }

  onSelectPage(page: Facebook.IPage | undefined) {
    if (!page) {
      this.instagramAccounts = [];
      return;
    }

    this.selectedPage = page;
    this.getInstagramAccounts();
  }

  getInstagramAccounts(page: Facebook.IPage = this.selectedPage!) {
    this.facebookService
      .getInstagramAccounts$({
        pageId: page.id
      })
      .subscribe({
        next: ({ error, data }) => {
          if (error) {
            this.loader$.next(false);
            return;
          }
          this.instagramAccounts = data.instagramAccounts.map((o) => ({ label: o.name, value: o.id } as IOption));
          this.selectInstagramAccount(page);

          this.loader$.next(false);
        },
        error: () => {
          this.loader$.next(false);
          this.instagramAccounts = [];
          this.selectedInstagramAccountId = undefined;
        }
      });
  }

  onSocialAccountIdChange(instagramAccountId: string) {
    this.selectedInstagramAccountId = instagramAccountId;
  }

  selectAdAccount(account: IAdAccountType<FacebookAdAccount>) {
    this.selectedAdAccount = account;
  }

  private selectInstagramAccount(page: Facebook.IPage) {
    this.selectedInstagramAccountId = '';
    if (!page) return;

    if (this.metaPlatform?.pageId === page.id) {
      this.selectedInstagramAccountId =
        (this.instagramAccounts?.find((x) => x.value === this.metaPlatform?.instagramAccountId)?.value as string) || '';
    } else {
      this.selectedInstagramAccountId = this.instagramAccounts[0]?.value as string;
    }
    this.cdr.detectChanges();
  }
}
