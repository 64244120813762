import { Google, Linkedin } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Linkedin.Dashboard.IDashboardInsightsRequest;
type Output = Linkedin.Dashboard.IDashboardInsightsResponse;

export const accountOverviewTotalLinkedin = makeMutation<Output, Input>(`
 query AccountOverviewTotalLinkedin(
  $skip: Int
	$take: Int
  $datePreset: DatePreset
  $startDate: Float
  $endDate: Float
  $accounts: [String!] = []
  $campaigns: [String!] = []
  $timeGranularity: LinkedInTimeGranularity = All
  $type: LinkedInInsightType = Campaign
) {
  linkedin {
    insights(
      skip:$skip
      take:$take
      datePreset: $datePreset
      type: $type
      startDate: $startDate
      endDate: $endDate
      timeGranularity: $timeGranularity
      accounts: $accounts
      campaigns: $campaigns
    ) {
      edges {
        cursor
        node {
          clicks
          impressions
          oneClickLeads
          oneClickLeadFormOpens
          reactions
          registrations
          downloadClicks
          costInLocalCurrency
          comments
          companyPageClicks
          dateRange {
            start
            end
          }
          pivotValues
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}
`);
