import { Component } from '@angular/core';
import { Platforms } from '@core/models';

import { AdPreviewBingService } from './ad-preview.service';
import { AdPreviewBingPlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-ad-preview--bing',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewBingComponent {
  Platforms = Platforms;

  platforms = [AdPreviewBingPlatform];

  constructor(public adPreviewService: AdPreviewBingService) {}
}
