import { ToastrService } from 'ngx-toastr';

import { ChangeDetectorRef, Component, ElementRef, Input, isDevMode, OnInit } from '@angular/core';
import {
    CardFieldDirective, ChargebeeJsAngularWrapperModule
} from '@chargebee/chargebee-js-angular-wrapper';
import {
    CHARGEBEE_COMPONENT_CLASS, CHARGEBEE_COMPONENT_FONTS, CHARGEBEE_COMPONENT_STYLES
} from '@core/constants';
import { MySubscription, PaymentIntent } from '@core/models';
import { ChargebeeClientService, ChargebeeService, IChargebeeAdditionalData } from '@core/services';
import { GetMySubscription, UserState } from '@core/state';
import { Store } from '@ngxs/store';
import { runIsDev } from '@shared/utils';

import { BaseAsyncComponent } from '../base';

@Component({
  selector: 'aayn-credit-card',
  templateUrl: 'credit-card.component.html',
  standalone: true,
  imports: [ChargebeeJsAngularWrapperModule]
})
export class CreditCardComponent extends BaseAsyncComponent implements OnInit {
  @Input() subscription?: MySubscription;

  errorMessage = '';
  cardComponent?: CardFieldDirective;

  errors = {};

  classes = CHARGEBEE_COMPONENT_CLASS;

  fonts = CHARGEBEE_COMPONENT_FONTS;

  styles = CHARGEBEE_COMPONENT_STYLES;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private toastrService: ToastrService,
    private chargebeeClientService: ChargebeeClientService,
    private chargebeeService: ChargebeeService,
    private el: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {}

  onReady = (cardComponent) => {
    this.cardComponent = cardComponent;

    this.cdr.detectChanges();
  };

  setFocus(field) {
    field.focus();
  }

  onChange = (status) => {
    let errors = {
      ...this.errors,
      [status.field]: status.error
    };

    this.errors = errors;

    let messageObj =
      Object.values(errors)
        .filter((message) => !!message)
        .pop() || {};

    if (isDevMode()) {
      console.log(messageObj);
    }

    this.errorMessage = messageObj as any;

    this.cdr.detectChanges();
  };

  get isInvalid() {
    const cardField = this.el.nativeElement.querySelector('#card-number');
    const expiryField = this.el.nativeElement.querySelector('#card-expiry');
    const cvvField = this.el.nativeElement.querySelector('#card-cvv');

    return (
      cardField?.classList.contains('invalid') ||
      cardField?.classList.contains('empty') ||
      expiryField?.classList.contains('invalid') ||
      expiryField?.classList.contains('empty') ||
      cvvField?.classList.contains('invalid') ||
      cvvField?.classList.contains('empty')
    );
  }

  tokenize() {
    if (this.isInvalid) return;

    const user = this.store.selectSnapshot(UserState.user);

    const additionalData = {
      firstName: user.user.name,
      lastName: user.user.surname,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      stateCode: '',
      zip: ''
    } as IChargebeeAdditionalData;

    this.setLoading(true);

    (this.cardComponent?.tokenize({ additionalData }) as Promise<any>)
      .then(async () => {
        let paymentIntent: PaymentIntent;

        const authorizeWith3ds = () =>
          this.chargebeeClientService.chargebeeInstance
            ?.load3DSHandler()
            .then(async (handler) => {
              handler.setPaymentIntent(paymentIntent as unknown as IChargebeePaymentIntent);

              runIsDev(() => {
                console.log(paymentIntent);
              });

              this.cardComponent?.authorizeWith3ds(paymentIntent as any, additionalData as any, {
                success: (intent) => {
                  runIsDev(() => {
                    console.log('authorizeWith3ds:intent', intent);
                  });

                  this.chargebeeService
                    .createPaymentSourceFromPaymentIntent$({
                      id: intent.id
                    })
                    .subscribe((result) => {
                      if (result.data) {
                        this.store.dispatch(new GetMySubscription()).subscribe(() => {
                          this.setLoading(false);

                          this.toastrService.success('Card information has been updated successfully.');
                        });
                      }
                    });
                },
                error: (intent) => {
                  runIsDev(() => {
                    console.log('authorizeWith3ds:error', intent);
                  });

                  if (intent.active_payment_attempt?.error_text) {
                    const errorText = intent.active_payment_attempt?.error_text?.split(';').at(0);

                    if (errorText) {
                      this.toastrService.error(errorText);
                    }
                  }
                }
              });
            })
            .catch((err) => {
              if (err?.message) {
                this.toastrService.error(err?.message);
              }

              console.error(err);
            });

        const createPaymentIntent = this.chargebeeService.createPaymentIntent$(0, 'USD');

        createPaymentIntent.subscribe({
          next(result) {
            if (result.error) throw result.error;

            paymentIntent = result.data;

            authorizeWith3ds();
          },
          error: (err) => {
            runIsDev(() => {
              console.log(`Auth.PaymentComponent:CreatePaymentIntent`, err);
            });

            createPaymentIntent.subscribe({
              next(result) {
                if (result.error) throw result.error;

                paymentIntent = result.data;

                authorizeWith3ds();
              },
              error: (error) => {
                this.toastrService.error('Something went wrong.');

                this.setLoading(false);

                throw error;
              }
            });
          }
        });
      })
      .catch((err) => {
        if (err?.message) {
          this.toastrService.error(err?.message);
        }

        console.error(err);

        this.setLoading(false);
      });
  }
}
