export enum Platforms {
  /**
   * Indicates that the platform is not set.
   */
  Unset = 0,

  /**
   * The Facebook platform.
   */
  Meta,

  /**
   * The Google platform.
   */
  Google,

  /**
   * The LinkedIn platform.
   */
  LinkedIn,

  /**
   * The Bing platform.
   */
  Bing,

  /**
   * The TikTok platform.
   */
  TikTok,

  Instagram = 1023,

  /**
   * The Smart Campaign
   */
  SmartCampaign = 1024,

  /**
   * The Instant Ad
   */
  InstantAd = 1025
}

export enum PlatformsBackend {
  Facebook = 1,
  Google,
  LinkedIn,
  Bing,
  TikTok
}

export type AdPlatforms = Exclude<
  Platforms,
  Platforms.Unset | Platforms.SmartCampaign | Platforms.Instagram | Platforms.InstantAd
>;

export type PlatformsBackendNames = keyof typeof PlatformsBackend;

export const DRAFT_PLATFORMS = [
  Platforms.Meta,
  Platforms.Google,
  Platforms.LinkedIn,
  Platforms.Bing,
  Platforms.TikTok,
  Platforms.SmartCampaign
] as const;

export interface PlatformConfig {
  icon: string;
  label: string;
  value: Platforms;
  key: PlatformKeys;
  isActive: boolean;
  platform: PlatformType;
}

export type PlatformKey = Record<Exclude<Platforms, Platforms.Unset>, PlatformKeys>;

export const PlatformConfig: PlatformConfig[] = [
  {
    label: 'Meta Ads',
    icon: 'meta',
    value: Platforms.Meta,
    key: 'Meta',
    isActive: true
  } as PlatformConfig,
  { label: 'Google Ads', icon: 'google', value: Platforms.Google, key: 'Google', isActive: true } as PlatformConfig,
  {
    label: 'Linkedin Ads',
    icon: 'linkedin',
    value: Platforms.LinkedIn,
    key: 'LinkedIn',
    isActive: true
  } as PlatformConfig,
  { label: 'Bing Ads', icon: 'bing', value: Platforms.Bing, key: 'Bing', isActive: true } as PlatformConfig,
  { label: 'TikTok Ads', icon: 'tiktok', value: Platforms.TikTok, key: 'TikTok', isActive: true } as PlatformConfig,
  {
    label: 'Smart Campaign',
    icon: 'smart-campaign',
    value: Platforms.SmartCampaign,
    key: 'SmartCampaign',
    isActive: true
  } as PlatformConfig,
  {
    label: 'Instant Ads',
    icon: 'instant-ad',
    value: Platforms.InstantAd,
    key: 'InstantAd',
    isActive: true
  } as PlatformConfig
].map((platformConfig) => ({
  ...platformConfig,
  platform: platformConfig.platform || platformConfig.icon
}));

export const PlatformKeyByValues: PlatformKey = PlatformConfig.reduce(
  (acc, { value, key }) => ({ ...acc, [value]: key }),
  {} as PlatformKey
);

export enum PlatformStep {
  Platforms = 0,
  Objective,
  Audience,
  AdType,
  DescribeProject,
  AIGenerated,
  BudgetReview
}

export interface IPlatformModel {
  label: string;
  icon: string;
  value: Platforms;
}

export const ACTIVE_PLATFORMS: IPlatformModel[] = PlatformConfig.filter(({ isActive }) => isActive).map(
  ({ label, icon, value }) => ({ label, icon, value })
);

export const PLATFORMS: IPlatformModel[] = [
  ...ACTIVE_PLATFORMS
  // { label: 'Pinterest', imagePath: 'pinterest', value: Platforms.Facebook },
  // { label: 'Display Networks', imagePath: 'display-networks', value: Platforms.Facebook }
];

export const PlatformIcons: Record<Exclude<Platforms, Platforms.Unset>, string> = PlatformConfig.reduce(
  (iconMap, { value, icon }) => ({
    ...iconMap,
    [value]: icon
  }),
  {} as Record<Exclude<Platforms, Platforms.Unset>, string>
);

export type PlatformType =
  | 'facebook'
  | 'instagram'
  | 'meta'
  | 'google'
  | 'linkedin'
  | 'bing'
  | 'pinterest'
  | 'display-networks'
  | 'microsoft'
  | 'tiktok'
  | 'twitter'
  | 'spotify';

export type PlatformKeys =
  | 'Facebook'
  | 'Instagram'
  | 'Meta'
  | 'Google'
  | 'LinkedIn'
  | 'Bing'
  | 'Pinterest'
  | 'Display-networks'
  | 'Microsoft'
  | 'TikTok'
  | 'Twitter'
  | 'Spotify'
  | 'SmartCampaign'
  | 'InstantAd';

export type SmartCampaignPlatforms = 'facebook' | 'bing' | 'linkedIn' | 'google' | 'tiktok';

export enum SmartCampaignPlatform {
  Meta = 'facebook',
  LinkedIn = 'linkedIn',
  Bing = 'bing',
  Google = 'google',
  TikTok = 'tiktok'
}

export const SupportedSmartCampaignObject = {
  [Platforms.Meta]: SmartCampaignPlatform.Meta,
  [Platforms.LinkedIn]: SmartCampaignPlatform.LinkedIn,
  [Platforms.Bing]: SmartCampaignPlatform.Bing,
  [Platforms.Google]: SmartCampaignPlatform.Google,
  [Platforms.TikTok]: SmartCampaignPlatform.TikTok
};
