import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'pixelSearch';
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokPixelSearchOutput[]>;

export const pixelSearchTiktokQuery = makeQuery<Output, TikTok.CreateAd.Backend.TikTokPixelDto>(`
query PixelSearchTiktok($limit: SafeInt) {
  tiktok {
    ${queryField}(limit:$limit){
      name,
      id,
      category,
      status,
      events {
        name,
        code
      }
    }
  }
}
`);
