<div class="aayn-google-import-modal">
  <header class="aayn-google-import-modal--header">
    <ayn-icon name="arrow-import"></ayn-icon>

    <div class="aayn-google-import-modal--header__content">
      <h5 class="aayn-google-import-modal--header__title">Import specific campaigns and ad groups</h5>
      <p class="aayn-google-import-modal--header__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
      </p>
    </div>

    <ayn-form-field class="aayn-google-import-modal--header-search">
      <input type="text" ayn-input placeholder="Search campaign" [(ngModel)]="query" />
      <ayn-icon name="search"></ayn-icon>
    </ayn-form-field>
    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <main class="aayn-google-import-modal--main">
    <ayn-table [showPagination]="false" class="aayn-google-import-modal--table">
      <ng-template #header>
        <tr>
          <th>Campaign</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </ng-template>

      <ng-template #body>
        <ng-container *ngIf="pages?.length; else noContent">
          <tr *ngFor="let item of pages | aynFilterObj: { name: query }">
            <td>
              <div class="name">
                <ayn-checkbox [(ngModel)]="item.checked" [binary]="true"></ayn-checkbox>
                {{ item.name }}
              </div>
            </td>
            <td class="type">{{ item.type }}</td>
            <td class="status active">
              <span>
                <strong>&nbsp;</strong>
                {{ item.status }}
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-template>

      <ng-template #noContent>
        <tr>
          <td colspan="3">
            <aayn-empty-content
              [showButton]="false"
              title="No Records Found"
              description="Please try again."
            ></aayn-empty-content>
          </td>
        </tr>
      </ng-template>
    </ayn-table>
  </main>

  <footer class="aayn-google-import-modal--footer">
    <button
      class="aayn-google-import-modal--btn"
      ayn-button="primary"
      ayn-button-block
      type="submit"
      [disabled]="checkDisabled()"
      (click)="selectedCampaign()"
    >
      Import Selected Campaigns
    </button>
  </footer>
</div>
