import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  customAudiences: {
    edges: Facebook.CustomAudienceItem[];
  };
};

export const getCustomAudiences = makeQuery<Output>(`
  query GetCustomAudiences {
    facebook {
      customAudiences {
        edges {
          node {
            id
            name
            audienceSizeLowerBound
            audienceSizeUpperBound
          }
        }
      }
    }
  }
`);
