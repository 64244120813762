import { CreateAnAdSteps, IBaseAdCreationEmptyModel } from '@pages/create-ad/models';
import { OpenAI } from '@core/models';

export function createAdBaseModel<CampaignType = string, AudienceType = string, AdType = string>(
  campaign: CampaignType,
  audience: AudienceType,
  ad: AdType
): IBaseAdCreationEmptyModel<CampaignType, AudienceType, AdType> {
  return {
    selectedTypes: {
      campaign,
      audience,
      ad
    },
    activeStep: CreateAnAdSteps.Platform,
    textAi: {
      language: '',
      url: '',
      productDescription: '',
      productName: '',
      tone: '' as OpenAI.TextTone,
      targetAudience: ''
    }
  };
}
