<!-- TODO: `aayn-audience--location-selection__has-error` a koşul eklenecek. -->
<section
  class="aayn-audience--location-selection"
  [ngClass]="{
    'aayn-audience--location-selection__filled': geoLocationsInclude.length || geoLocationsExclude.length,
    'aayn-audience--location-selection__active': overlay?.render,
    'aayn-audience--location-selection__has-error': false
  }"
>
  <aayn-location-selection-selected-items type="inclusions" *ngIf="geoLocationsInclude.length">
    <aayn-location-selection-selected-item *ngFor="let include of geoLocationsInclude; let index = index">
      <ayn-badge
        color="primary"
        [text]="include?.node?.name || ''"
        icon="map-marker-dot"
        (click)="removeItem(index, 'include')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <aayn-location-selection-selected-items type="exclusions" *ngIf="geoLocationsExclude.length">
    <aayn-location-selection-selected-item *ngFor="let exclude of geoLocationsExclude; let index = index">
      <ayn-badge
        color="orange"
        [text]="exclude?.node?.name || ''"
        icon="map-marker-dot"
        (click)="removeItem(index, 'exclude')"
      ></ayn-badge>
    </aayn-location-selection-selected-item>
  </aayn-location-selection-selected-items>

  <ayn-form-field class="aayn-audience--location-selection--input">
    <input
      type="text"
      [required]="!geoLocationsInclude.length"
      ayn-input
      [placeholder]="'Type the search' | translate"
      (click)="overlay?.show($event)"
      [formControl]="locationSearchControl"
    />
    <ayn-icon name="map-marker"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--location-selection--overlay"
    styleClass="aayn-audience--location-selection--overlay-inner"
    *ngIf="locationSearchControl.value?.length || geoLocations?.length"
  >
    <aayn-location-selection-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
      <aayn-location-selection-overlay-item
        *ngFor="let geoLocation of geoLocations"
        (onIncludeClick)="onIncludeClick(geoLocation)"
        (onExcludeClick)="onExcludeClick(geoLocation)"
        (onIncludeRemove)="onIncludeRemove(geoLocation)"
        (onExcludeRemove)="onExcludeRemove(geoLocation)"
        [active]="isActive(geoLocation)"
      >
        {{ geoLocation.node.name }}
      </aayn-location-selection-overlay-item>
    </aayn-location-selection-overlay-list>
  </ayn-overlay>
</section>
