import { CommonModule } from '@angular/common';
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';

import { IconComponent } from '../icon';

export type ButtonType =
  | 'default'
  | 'primary'
  | 'primary-outline'
  | 'primary-sexy'
  | 'accent'
  | 'orange'
  | 'only-icon'
  | 'disabled'
  | 'link'
  | 'white'
  | 'white-primary'
  | 'white-shadow'
  | 'white-sexy'
  | 'dark'
  | 'light-dark'
  | 'light-success'
  | 'light-orange'
  | 'light-primary'
  | 'light-accent'
  | 'light-gray'
  | ''
  | string;

@Directive({ selector: '[ayn-button]' })
export class ButtonDirective implements OnChanges, OnDestroy {
  @Input('ayn-button') type: ButtonType = 'primary';

  @HostBinding('class.btn--only-icon')
  @Input()
  onlyIcon = false;

  @Input('icon') icon = '';

  @Input('icon-direction') iconDirection: 'left' | 'right' = 'right';

  @HostBinding('style.width.px')
  @Input()
  width?: number;

  @HostBinding('style.border-radius.px')
  @Input('border-radius')
  borderRadius?: number;

  @Input('icon-class') iconClass = 'btn--icon';

  @Input() loading: boolean | null = false;

  @Input() loadingColor: '' | 'dark' = '';

  @Input() disabled?: boolean | null;

  constructor(
    private el: ElementRef<HTMLButtonElement>,
    private componentResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {
    el.nativeElement.classList.add('btn');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { type, icon, loading, disabled } = changes;

    if (type?.currentValue) {
      this.setTypeChange(type);
    }

    if (icon) {
      this.setIconChange(icon);
    }

    if (loading) {
      this.setLoadingChange(loading);
    }

    if (disabled) {
      this.setDisabledChange(disabled);
    }
  }

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }

  private createIcon(name: string) {
    const componentRef = this.viewContainerRef.createComponent(IconComponent);

    componentRef.instance.name = name;
    componentRef.instance.className = this.iconClass;

    const hasAlreadyIcon = this.el.nativeElement.querySelector('ayn-icon');

    if (hasAlreadyIcon) {
      hasAlreadyIcon?.remove();
    }

    if (this.iconDirection === 'right') this.el.nativeElement.appendChild(componentRef.location.nativeElement);
    else this.el.nativeElement.prepend(componentRef.location.nativeElement);
  }

  private setTypeChange(type: SimpleChange) {
    if (!type.firstChange) this.el.nativeElement.classList.remove(`btn--${type.previousValue}`);

    this.el.nativeElement.classList.add(`btn--${type.currentValue}`);

    if (this.type.endsWith('-sexy')) {
      setTimeout(() => {
        const innerHtml = this.el.nativeElement.innerHTML.replace(/<\!--.*?-->/g, '');
        this.el.nativeElement.innerHTML = `
            <div class="button-bg">
              <div class="button-inside">${innerHtml}</div>
            </div>
            <div class="border-gradient"></div>
            <div class="animation-bg"></div>
          `;
      }, 1);
    }
  }

  private setLoadingChange(loading: SimpleChange) {
    if (loading.currentValue) {
      this.el.nativeElement.classList.add('btn__icon:hidden');
      this.el.nativeElement.classList.add('btn__icon:loading');

      const loading = document.createElement('img');

      loading.classList.add('btn--loading-img');

      const loadingColor = this.loadingColor === '' ? '' : `-${this.loadingColor}`;

      loading.src = `/assets/images/svgs/tools/button-loading${loadingColor}.svg`;

      if (this.type.endsWith('-sexy')) {
        const buttonInside = this.el.nativeElement.querySelector('.button-inside');

        const buttonInsideInnerHtml = buttonInside?.innerHTML;

        if (buttonInside) {
          buttonInside.innerHTML = '';

          buttonInside.append(buttonInsideInnerHtml!);
          buttonInside.appendChild(loading);
        }
      } else {
        this.el.nativeElement.appendChild(loading);
      }
      if (this.disabled === undefined) {
        this.el.nativeElement.disabled = true;
      }
    } else {
      this.el.nativeElement.classList.remove('btn__icon:hidden');
      this.el.nativeElement.classList.remove('btn__icon:loading');

      const loadingEl = this.el.nativeElement.querySelector('.btn--loading-img');

      loadingEl?.remove();
      if (this.disabled === undefined) {
        this.el.nativeElement.disabled = false;
      }
    }
  }

  private setIconChange(icon: SimpleChange) {
    this.createIcon(icon.currentValue);

    this.el.nativeElement.classList.add('has-icon');
  }

  private setDisabledChange(disabled: SimpleChange) {
    this.el.nativeElement.disabled = disabled.currentValue;
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [ButtonDirective],
  declarations: [ButtonDirective],
  providers: []
})
export class ButtonModule {}
