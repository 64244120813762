<section class="aayn-error-modal" [ngClass]="modalClass">
  <header class="aayn-error-modal--header" [ngClass]="headerClass">
    <h4 class="aayn-error-modal--header__title">{{ title }}</h4>
    <p class="aayn-error-modal--header__description">{{ description }}</p>
    <ayn-modal-close [modal]="activeModal"></ayn-modal-close>
  </header>

  <main class="aayn-error-modal--body" [ngClass]="bodyClass" *ngIf="content?.length; else subContentTemplate">
    <span class="aayn-error-modal--body__item">
      {{ content }}
    </span>

    <span *ngIf="subContent" class="aayn-error-modal--body__sub-item">
      {{ subContent }}
    </span>
  </main>

  <ng-template #subContentTemplate>
    <p class="aayn-error-modal--subcontent" *ngIf="subContent?.length">
      {{ subContent }}
    </p>
  </ng-template>

  <footer class="aayn-error-modal--footer" [ngClass]="footerClass">
    <button
      *ngIf="cancelButtonText"
      type="button"
      ayn-button="primary"
      icon="chevron-left"
      icon-direction="left"
      (click)="cancel($event)"
      class="mr-2"
    >
      Cancel
    </button>
    <button
      *ngIf="confirmButtonText"
      type="button"
      ayn-button="orange-gradient"
      icon="chevron-right"
      icon-direction="right"
      (click)="confirm($event)"
    >
      {{ confirmButtonText }}
    </button>
  </footer>
</section>
