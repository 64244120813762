<div class="ayn-table" [ngClass]="type">
  <table>
    <thead>
      <ng-container *ngIf="!config">
        <ng-container *ngTemplateOutlet="header"></ng-container>
      </ng-container>

      <ng-container *ngIf="config">
        <tr>
          <ng-container *ngFor="let col of config.columns">
            <th>
              <div class="d-flex align-items-center justify-content-between">
                <span class="header-title pr-4"> {{ col.displayText }}</span>
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-container>
    </thead>

    <tbody>
      <ng-container *ngIf="!config">
        <ng-container *ngTemplateOutlet="body"></ng-container>
      </ng-container>

      <ng-container *ngIf="config">
        <tr *ngFor="let row of config.rows">
          <ng-container *ngFor="let col of config.columns">
            <td>
              {{ row[getColumnNameByIndex(col)] }}
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="ayn-table--pagination" *ngIf="showPagination">
  <span>List</span>

  <ayn-form-field>
    <ayn-select
      [showTick]="true"
      [minWidth]="110"
      [placeholder]="'10'"
      [showSearch]="false"
      [panelMaxHeight]="246"
      [(ngModel)]="pageViewCount"
      (ngModelChange)="onPageViewCountChange($event)"
      appendTo="body"
    >
      <ayn-option *ngFor="let item of paginationOptions" [value]="item.value"> {{ item.displayText }} </ayn-option>
    </ayn-select>
  </ayn-form-field>
</div>
