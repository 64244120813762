import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/public-api';
import { BingService } from '@core/index';
import { Bing } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { LocationSelectionOverlayItemActiveType } from '@pages/create-ad/components/shared/manuel-audience-setup/components/location-selection/components/location-selection-overlay-item/location-selection-overlay-item.component';
import { BingStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-location-selection--bing',
  templateUrl: 'location-selection.component.html'
})
export class AudienceLocationSelectionBingComponent implements OnInit {
  @Input() form?: FormGroup;
  @ViewChild(Overlay) overlay?: Overlay;
  protected locationSearchControl = new FormControl('', [Validators.minLength(1)]);
  protected geoLocations: Bing.AudienceCampaign.BingLocationConstant[] = [];
  protected adCreationModel = this.bingStateService.adCreationModel;
  @Select(LoaderState.getAny(['LocationSearch'])) loader$?: Observable<boolean>;

  constructor(
    private bingService: BingService,
    private cdr: ChangeDetectorRef,
    private bingStateService: BingStateService
  ) {}
  ngOnInit() {
    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
    this.locationSearchControl.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((term) => {
      if (this.locationSearchControl.invalid) return;

      if (this.form?.value.excludedLocations?.length < 1 && this.form?.value.includedLocations?.length < 1) {
        this.locationSearchControl.setErrors({ inValid: true });
      }
      this.bingService
        .locationSearch$({ searchKey: term!, limit: 10 } as Bing.AudienceCampaign.LocationSearchRequest)
        .subscribe((result) => {
          this.geoLocations = this._mapBingLocationSearch(result) || [];
          if (!this.overlay?.render) this.overlay?.show(null);
          this.cdr.detectChanges();
        });
    });
  }

  updateForm() {
    this.form?.patchValue({
      includedLocations: [...this.adCreationModel.campaign.criteria.target.locationCriterionIds],
      excludedLocation: [...this.adCreationModel.campaign.criteria.exclude.locationCriterionIds]
    });
  }

  protected onIncludeClick(geoLocation: Bing.AudienceCampaign.BingLocationConstant) {
    this.adCreationModel.campaign.criteria.target.locationCriterionIds.push(geoLocation);
    this.updateForm();
  }
  protected onExcludeClick(geoLocation: Bing.AudienceCampaign.BingLocationConstant) {
    this.adCreationModel.campaign.criteria.exclude.locationCriterionIds.push(geoLocation);
    this.updateForm();
  }

  onIncludeRemove(geoLocation: Bing.AudienceCampaign.BingLocationConstant) {
    this.adCreationModel.campaign.criteria.target.locationCriterionIds =
      this.adCreationModel.campaign.criteria.target.locationCriterionIds.filter((o) => o.id != geoLocation.id);
    this.updateForm();
  }

  onExcludeRemove(geoLocation: Bing.AudienceCampaign.BingLocationConstant) {
    this.adCreationModel.campaign.criteria.exclude.locationCriterionIds =
      this.adCreationModel.campaign.criteria.exclude.locationCriterionIds.filter((o) => o.id != geoLocation.id);
    this.updateForm();
  }

  protected isActive(geoLocation: Bing.AudienceCampaign.BingLocationConstant) {
    const hasAddedInclusions = this.adCreationModel.campaign.criteria.target?.locationCriterionIds.some(
      (o) => o.id == geoLocation.id
    );
    const hasAddedExclusions = this.adCreationModel.campaign.criteria.exclude?.locationCriterionIds.some(
      (o) => o.id == geoLocation.id
    );
    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'inclusions' : hasAddedExclusions ? 'exclusions' : ''
    } as LocationSelectionOverlayItemActiveType;
  }
  protected removeItem(index: number, arrName: '_inclusions' | '_exclusions') {
    if (arrName == '_inclusions') {
      this.adCreationModel.campaign.criteria.target.locationCriterionIds.splice(index, 1);
      this.form!.patchValue({
        includedLocations: this.adCreationModel.campaign.criteria.target.locationCriterionIds
      });
    }
    if (arrName == '_exclusions') {
      this.adCreationModel.campaign.criteria.exclude.locationCriterionIds.splice(index, 1);
      this.form!.patchValue({
        excludedLocations: this.adCreationModel.campaign.criteria.exclude.locationCriterionIds
      });
    }
  }
  private _mapBingLocationSearch(locations: Bing.AudienceCampaign.BingLocationConstant[] | undefined) {
    return locations?.map((o) => ({
      ...o,
      fullName: o.fullName.split(',').join(', ')
    }));
  }
}
