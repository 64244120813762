import { Bing } from '@core/models/platforms/bing';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Business.IOauthRequest;
type Output = Bing.Business.IOauthResponse;

export const oauthTikTok = makeMutation<Output, Input>(`
query GetTiktokOAuthUrl($contextId: String!) {
  tiktok {
    oauth(contextId: $contextId) {
      oauthUrl
    }
  }
}
`);
