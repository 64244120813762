import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aynDuration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    let result = '';

    if (hours > 0) {
      result += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
      if (minutes > 0 || seconds > 0) {
        result += 'and ';
      }
    }

    if (minutes > 0) {
      result += minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ';
      if (seconds > 0) {
        result += 'and ';
      }
    }

    if (seconds > 0 || value === 0) {
      result += seconds + ' second' + (seconds > 1 ? 's' : '');
    }

    return result.trim();
  }
}
