import { Injectable } from '@angular/core';
import { TikTok } from '@core/models';

export type AdPreviewSmartCampaignDynamicCreative = Partial<{
  images: string[];
  headline: string;
  googleHeadlines: string[];
  primaryText: string;
  description: string;
  websiteUrl: string;
  shortHeadline: string;
  callToAction: string;
  tiktokBusiness: TikTok.Business.TikTokIdentityOutputDto;
  businessName: string;
  businessLogo: string;
}>;
@Injectable()
export class AdPreviewSmartCampaignService {
  dynamicCreative: AdPreviewSmartCampaignDynamicCreative = {
    images: [],
    headline: '',
    primaryText: '',
    websiteUrl: '',
    shortHeadline: '',
    callToAction: '',
    tiktokBusiness: {} as TikTok.Business.TikTokIdentityOutputDto,
    businessName: '',
    businessLogo: '',
    description: '',
    googleHeadlines: []
  };
}
