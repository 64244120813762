import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { LinkedinStateService } from './linkedin.state';

@Injectable({ providedIn: 'root' })
export class LinkedinFormService extends FormListener {
  constructor(private linkedinStateService: LinkedinStateService) {
    super();

    this.subscribeForms(() => {
      this.linkedinStateService.saveDraft$();
    });
  }
}
