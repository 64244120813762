import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.ICampaignRequest;
type Output = Google.Dashboard.ICampaignResponse;

export const campaignsGoogle = makeMutation<Output, Input>(`
query GetGoogleCampaigns(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $searchKey: String,
  $ids: [String!],
) {
  google {
    campaigns(
      first: $first
      after: $after
      last: $last
      before: $before
      searchKey: $searchKey,
      ids: $ids
    ) {
      edges {
        cursor
        node {
          id
          status
          name
          type
          startDate
          endDate
          resourceName
          targetGoogleSearch
          targetContentNetwork
          targetPartnerSearchNetwork
          targetSearchNetwork
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
`);
