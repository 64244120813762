import { makeMutation, QueryResult } from '@core/queries';
import { Google } from '@core/models/platforms/google';

const queryField = 'remove';
type Input = Google.Dashboard.GoogleCampaignRemove;
type Output = QueryResult<typeof queryField, boolean>;

export const campaignRemoveQuery = makeMutation<Output, Input>(`
mutation GoogleCampaignRemove($resourceIds:[Float!]!, $resourceType: GoogleIdType!) {
  google {
    ${queryField}(resourceIds: $resourceIds, resourceType:$resourceType)
  }
}
`);
