import { Component, Input } from '@angular/core';

@Component({
  selector: 'aayn-select-platform-campaign-item',
  templateUrl: './select-platform-campaign-item.component.html'
})
export class SelectPlatformCampaignItemComponent {
  @Input() title = 'Smart Multi-Platform Campaign';

  @Input() description =
    'Let our AI to publish campaigns on your selected platforms, optimize them, and allocate your budget in real time.';

  @Input() disabled = false;

  @Input() selected = false;

  @Input() type!: 'single-campaign' | 'smart-campaign' | 'instant-ads';

  @Input() selectedImage = '';

  @Input() unSelectedImage = '';

  @Input() isBeta = false;

  get image() {
    return this.selected ? `${this.type}-active` : this.type;
  }
}
