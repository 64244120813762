<div class="ayn-alert" [ngClass]="type" *ngIf="showAlertBox">
  <div class="d-flex">
    <ayn-icon class="ayn-alert-icon" *ngIf="icon" [name]="icon"></ayn-icon>
    <div class="ayn-alert--content">
      <h5 class="ayn-alert--content__title" *ngIf="title">
        {{ title }}
      </h5>
      <p class="ayn-alert--content__description" *ngIf="description">
        {{ description }}
      </p>
    </div>
  </div>

  <ayn-icon *ngIf="buttonText" name="chevron-right-double" class="ayn-alert-icon ml:auto"></ayn-icon>
  <button
    ayn-button="white-shadow"
    class="ayn-alert--button"
    *ngIf="buttonText"
    (click)="notificationButton.emit()"
    [icon]="buttonIcon || ''"
    icon-direction="right"
    icon-class="ayn-alert-btn--icon"
  >
    {{ buttonText }}
  </button>

  <ayn-icon *ngIf="closeButton" (click)="closeAlert()" class="ayn-alert--close" name="cross-mini"></ayn-icon>
</div>
