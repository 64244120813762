import { Injectable } from '@angular/core';

import {
  BingStateService,
  BingValidationService,
  FacebookStateService,
  FacebookValidationService,
  GoogleStateService,
  GoogleValidationService,
  LinkedinStateService,
  LinkedinValidationService,
  SmartCampaignStateService,
  SmartCampaignValidationService,
  TiktokStateService,
  TiktokValidationService
} from '../state';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';
import { InstantAdValidationService } from '@pages/create-ad/state/platforms/instant-ad/instant-ad.validation';

@Injectable({ providedIn: 'root' })
export class CreateAdAllPlatformsService {
  constructor(
    private bingStateService: BingStateService,
    private facebookStateService: FacebookStateService,
    private googleStateService: GoogleStateService,
    private linkedinStateService: LinkedinStateService,
    private smartCampaignStateService: SmartCampaignStateService,
    private tiktokStateService: TiktokStateService,
    private instantAdStateService: InstantAdStateService,
    private bingValidationService: BingValidationService,
    private facebookValidationService: FacebookValidationService,
    private googleValidationService: GoogleValidationService,
    private linkedinValidationService: LinkedinValidationService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private tiktokValidationService: TiktokValidationService,
    private instantAdValidationService: InstantAdValidationService
  ) {}

  resetCreateAdState() {
    this.facebookStateService.reset();
    this.googleStateService.reset();
    this.bingStateService.reset();
    this.linkedinStateService.reset();
    this.smartCampaignStateService.reset();
    this.tiktokStateService.reset();
    this.instantAdStateService.reset();
  }

  resetCreateAdValidationState() {
    this.facebookValidationService.reset();
    this.googleValidationService.reset();
    this.bingValidationService.reset();
    this.linkedinValidationService.reset();
    this.smartCampaignValidationService.reset();
    this.instantAdValidationService.reset();
  }
}
