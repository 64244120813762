import { makeQuery } from '@core/queries';

type Input = {
  input: {
    include: {
      [key: string]: string[];
    };
    exclude: {
      [key: string]: string[];
    };
  };
};

type Output = {
  getAudienceCounts: {
    active: number;
    total: number;
  };
};

export const getAudienceCounts = makeQuery<Output, Input>(
  `query GetAudienceCounts($input: AudienceTargetingValidation!){
  linkedin{
    getAudienceCounts(input: $input) {
      active
      total
    }
  }
}`
);
