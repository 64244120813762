<section class="aayn-create-ad--item--container">
  <section class="aayn-create-ad--item--row">
    <aayn-select-ad-item
      *ngFor="let adType of adTypes"
      [title]="adType.title"
      [active]="adType.active"
      [empty]="adType.empty"
      [description]="adType.description"
      [adsType]="adType.icon"
      (btnClick)="adTypeChangeStatus(adType)"
    >
    </aayn-select-ad-item>
  </section>
</section>

<div class="aayn-ad-type-container">
  <div class="aayn-ad-type-container--content mt:5">
    <div [ngSwitch]="selectedAdType">
      <div *ngSwitchCase="AdTypes.SingleImage">
        <aayn-dynamic-image-ads--linkedin></aayn-dynamic-image-ads--linkedin>
      </div>
      <div *ngSwitchCase="AdTypes.CarouselImage">
        <aayn-carousel-ads--linkedin></aayn-carousel-ads--linkedin>
      </div>
    </div>
    <aayn-ad-preview--linkedin
      [selectedAdType]="selectedAdType"
      class="aayn-ad-type-container--content--preview"
    ></aayn-ad-preview--linkedin>
  </div>
</div>
