<ul class="stepper">
  <li
    *ngFor="let step of steps; let index = index"
    [class]="step.status + ' ' + step?.className"
    (click)="clickable ? setActive(index) : null"
    [ngStyle]="{ 'z-index': steps.length - index}"
  >
    <div class="stepper--inner">
      <div class="stepper--index">
        <span class="stepper--index__text" *ngIf="activeStepIndex <= index"> {{index + 1}} </span>
        <ayn-icon *ngIf="activeStepIndex - 1 == index" name="check"></ayn-icon>
      </div>

      <span class="stepper--icon" *ngIf="step?.iconName">
        <ayn-icon [name]="step?.iconName || ''"></ayn-icon>
      </span>

      <span class="stepper--title">{{ step.title }}</span>

      <ayn-icon
        *ngIf="step.showStatusIcon"
        class="stepper--status-icon"
        [name]="activeStepIndex - 1 < index ? 'chevron-right' : 'check'   "
      ></ayn-icon>
    </div>

    <span class="stepper--description" *ngIf="step?.description">{{ step?.description }}</span>
  </li>
</ul>
