import { Injectable } from '@angular/core';
import { ConnectAdAccount, ConnectAdAccountMap, IAdAccountType, PlatformAdAccountMap, Platforms } from '@core/models';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { addOrReplace } from '@core/utils';
import { SharedAdAccountService } from '@core/shared';
import { tap } from 'rxjs/operators';

export namespace AdAccount {
  export interface State {
    selectedAdAccounts: ConnectAdAccount[];
    adAccounts: IAdAccountType[];
  }
  export type AdAccountPlatforms = Exclude<
    Platforms,
    Platforms.SmartCampaign | Platforms.Unset | Platforms.Instagram | Platforms.InstantAd
  >;
}

export class SetAdAccounts {
  static readonly type = '[SetAdAccounts] set AdAccounts';
  static readonly desc = 'SetAdAccounts set ad accounts';
  constructor(public payload: IAdAccountType[]) {}
}

export class SetSelectedAdAccounts {
  static readonly type = '[SetSelectedAdAccounts] set SetSelectedAdAccounts';
  static readonly desc = 'SetSelectedAdAccounts set selected ad account';
  constructor(public payload: ConnectAdAccount[]) {}
}

export class GetPlatformAdAccounts {
  static readonly type = '[GetPlatformAdAccounts] set GetPlatformAdAccounts';
  static readonly desc = 'GetPlatformAdAccounts get platform ad accounts';
  constructor(public payload: { platforms: AdAccount.AdAccountPlatforms; mapWithoutSelect?: boolean }) {}
}

export class SetAdAccountForPlatform {
  static readonly type = '[SetAdAccountForPlatform] set AdAccountForPlatform';
  static readonly desc = 'SetAdAccountForPlatform set selected ad account';

  static create<T extends AdAccount.AdAccountPlatforms>(platform: T, adAccount: ConnectAdAccountMap<T>) {
    return new SetAdAccountForPlatform({ platform, adAccount });
  }
  private constructor(
    public payload: {
      platform: AdAccount.AdAccountPlatforms;
      adAccount: ConnectAdAccountMap;
    }
  ) {}
}

export const AD_ACCOUNT_DEFAULTS: AdAccount.State = {
  selectedAdAccounts: [],
  adAccounts: []
};

@State<AdAccount.State>({
  name: 'AdAccountState',
  defaults: AD_ACCOUNT_DEFAULTS
})
@Injectable()
export class AdAccountState {
  @Selector()
  static selectedAdAccounts({ selectedAdAccounts }: AdAccount.State): ConnectAdAccount[] {
    return (selectedAdAccounts || []) as ConnectAdAccount[];
  }

  @Selector()
  static adAccounts<T extends AdAccount.AdAccountPlatforms>({
    adAccounts
  }: AdAccount.State): PlatformAdAccountMap<T>[] {
    return (adAccounts || []) as PlatformAdAccountMap<T>[];
  }

  static selectedPlatformAdAccount<T extends AdAccount.AdAccountPlatforms>(platform: T) {
    return createSelector([AdAccountState], ({ selectedAdAccounts }: AdAccount.State) => {
      return selectedAdAccounts.find((adAcc) => adAcc.platform === platform) as ConnectAdAccountMap<T> | undefined;
    });
  }

  constructor(private adAccountService: SharedAdAccountService) {}

  @Action(SetSelectedAdAccounts)
  setSelectedAdAccounts({ patchState }: StateContext<AdAccount.State>, { payload }: SetSelectedAdAccounts) {
    patchState({ selectedAdAccounts: payload });
  }

  @Action(SetAdAccountForPlatform)
  setPlatformAdAccount({ patchState, getState }: StateContext<AdAccount.State>, { payload }: SetAdAccountForPlatform) {
    const { selectedAdAccounts, adAccounts } = getState();
    patchState({
      selectedAdAccounts: addOrReplace(
        selectedAdAccounts,
        {
          platform: payload.platform,
          adAccountId: payload.adAccount.adAccountId,
          instagramAccountId: payload.adAccount['instagramAccountId'],
          loginId: payload.adAccount['loginId'],
          pageId: payload.adAccount['pageId']
        },
        ['platform']
      )
    });
  }

  @Action(GetPlatformAdAccounts)
  getPlatformAdAccounts({ patchState }: StateContext<AdAccount.State>, { payload }: GetPlatformAdAccounts) {
    return this.adAccountService.getPlatformAdAccounts(payload.platforms, payload.mapWithoutSelect).pipe(
      tap((adAccounts) =>
        patchState({
          adAccounts
        })
      )
    );
  }

  @Action(SetAdAccounts)
  setAdAccounts({ patchState }: StateContext<AdAccount.State>, { payload }: SetAdAccounts) {
    patchState({ adAccounts: payload });
  }
}
