import { AbstractControl, FormArray } from '@angular/forms';

export function uniqueValidator() {
  return (formArray: AbstractControl | FormArray) => {
    if (formArray instanceof FormArray) {
      if (!formArray.value?.filter(Boolean)?.length) {
        return null;
      }

      const equalControls = formArray.controls.filter((o, i, arr) =>
        arr.some((it, idx) => i > idx && it.value && it.value === o.value)
      );

      if (!equalControls.length) return null;

      equalControls.forEach((o) => o.setErrors({ isUniqueControls: true }));

      return {
        isUniqueControls: true
      };
    }

    return null;
  };
}
