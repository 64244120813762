import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PaymentModalComponent } from '@pages/components';
import {
  ClickFraudProtectionComponent,
  FirstBrandCreatedComponent,
  SorryToSeeYouGoComponent,
  TimeToPutYourCardComponent,
  UnpaidInvoicesComponent,
  WelcomeToAdYouNeedComponent,
  WelcomeUniveristyComponent
} from '@pages/video-modal';
import {
  AdAccountSelectBingComponent,
  AdAccountSelectFacebookComponent,
  AdAccountSelectGoogleComponent,
  AdAccountSelectLinkedinComponent,
  AdAccountSelectTikTokComponent
} from '@shared/components';

import { AuthGuard } from './auth/guards/auth.guard';
import { AdAccountResolver } from '@shared/resolvers';
import { Platforms } from '@core/models';

const modalRoutes: Routes = [
  {
    path: 'video-modals/welcome',
    component: WelcomeToAdYouNeedComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/first-brand-created',
    component: FirstBrandCreatedComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/sorry-to-see-you-go',
    component: SorryToSeeYouGoComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/unpaid-invoices',
    component: UnpaidInvoicesComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/welcome-university',
    component: WelcomeUniveristyComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/clickfraud',
    component: ClickFraudProtectionComponent,
    outlet: 'dialog'
  },
  {
    path: 'video-modals/time-to-put-your-card',
    component: TimeToPutYourCardComponent,
    outlet: 'dialog'
  },
  {
    path: 'ad-account-select-modals/Meta',
    component: AdAccountSelectFacebookComponent,
    outlet: 'dialog',
    resolve: { adAccounts: AdAccountResolver },
    runGuardsAndResolvers: 'always',
    data: {
      platform: Platforms.Meta
    }
  },
  {
    path: 'ad-account-select-modals/Google',
    component: AdAccountSelectGoogleComponent,
    outlet: 'dialog',
    resolve: { adAccounts: AdAccountResolver },
    runGuardsAndResolvers: 'always',
    data: {
      platform: Platforms.Google
    }
  },
  {
    path: 'ad-account-select-modals/Bing',
    component: AdAccountSelectBingComponent,
    outlet: 'dialog',
    resolve: { adAccounts: AdAccountResolver },
    runGuardsAndResolvers: 'always',
    data: {
      platform: Platforms.Bing
    }
  },
  {
    path: 'ad-account-select-modals/LinkedIn',
    component: AdAccountSelectLinkedinComponent,
    outlet: 'dialog',
    resolve: { adAccounts: AdAccountResolver },
    runGuardsAndResolvers: 'always',
    data: {
      platform: Platforms.LinkedIn
    }
  },
  {
    path: 'ad-account-select-modals/TikTok',
    component: AdAccountSelectTikTokComponent,
    outlet: 'dialog',
    resolve: { adAccounts: AdAccountResolver },
    runGuardsAndResolvers: 'always',
    data: {
      platform: Platforms.TikTok
    }
  },
  {
    path: 'payment-detail',
    component: PaymentModalComponent,
    outlet: 'dialog'
  }
];

const routes: Routes = [
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/layout.module').then((m) => m.LayoutModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./shared/pages/callback/callback.module').then((m) => m.CallbackModule)
  },
  ...modalRoutes,
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: '**', redirectTo: 'main' }
];

const config: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
