import { ApolloModule } from 'apollo-angular';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';

import { GraphQLService } from './backend';
import { GlobalErrorHandler } from './error-handler/global-error-hander';
import { GraphqlCancelRequestInterceptor, HeaderInterceptor } from './interceptors';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { AuthService, GQLClientService, I18NClientService } from './services';
import {
  AdAccountState,
  ChargebeeState,
  ClickfraudProtectionState,
  DashboardState,
  TextGeneratorAiState,
  UserState
} from './state';
import { BusinessState } from './state/business.state';
import { LoaderState } from './state/loader.state';
import { PlatformContextState } from './state/platform-context.state';
import { throwIfAlreadyLoaded } from './utils';
import { CreateAdStepsState } from '@pages/create-ad/state/create-ad-steps.state';
import { AdcImageGenerationState } from '@pages/create-ad/state/adc-image-generation/adc-image-generation.state';

const CREATE_AD_STATES = [CreateAdState, CreateAdStepsState, AdcImageGenerationState];

const SERVICES = [GraphQLService, AuthService, GQLClientService, I18NClientService];

const STATES = [
  UserState,
  DashboardState,
  ClickfraudProtectionState,
  AdAccountState,
  BusinessState,
  PlatformContextState,
  LoaderState,
  TextGeneratorAiState,
  ChargebeeState,
  ...CREATE_AD_STATES
];
const NGXS_MODULES = [
  NgxsModule.forRoot(STATES, {
    selectorOptions: {
      injectContainerState: true
    }
  }),
  NgxsStoragePluginModule.forRoot({
    key: [
      'UserState',
      'DashboardState',
      'ClickfraudProtectionState',
      'AdAccountState',
      'BusinessState',
      'PlatformContextState',
      'TextGeneratorAiState',
      'ChargebeeState',
      'CreateAdState',
      'AdcImageGenerationState'
    ]
  }),
  NgxsReduxDevtoolsPluginModule.forRoot(),
  NgxsResetPluginModule.forRoot()
];

@NgModule({
  imports: [CommonModule, HttpClientModule, ApolloModule, ...NGXS_MODULES],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LanguageInterceptor,
          multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GraphqlCancelRequestInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ...SERVICES
      ]
    };
  }
}
