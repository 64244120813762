<aayn-ad-preview-card [selectedPlatform]="Platforms.Meta">
  <aayn-ad-preview [showHeader]="false" className="aayn-ad-preview-meta-existing-post">
    <iframe
      *ngIf="adPreviewService.existingPost.platform == FacebookPlatforms.Facebook && selectedPagePostId?.length"
      [src]="selectedPostIFrameUrl | aynSafe: 'resourceUrl'"
      width="100%"
      height="700px"
      style="border: none; overflow: hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>

    <iframe
      *ngIf="adPreviewService.existingPost.platform == FacebookPlatforms.Instagram && selectedIgMediaId?.length"
      [src]="selectedIgMediaUrl | aynSafe: 'resourceUrl'"
      width="100%"
      height="470"
      frameborder="0"
      scrolling="no"
      allowtransparency="true"
      class="d-block mx-auto"
    ></iframe>

    <div *ngIf="!selectedPagePostId?.length && !selectedIgMediaId?.length">
      <div class="aayn-ad-preview-placeholder">
        <ayn-icon name="null-image-icon"></ayn-icon>
      </div>
    </div>
  </aayn-ad-preview>
</aayn-ad-preview-card>
