import { IOption } from '@ayn-ui/public-api';
import { BuildTuple } from '@core/utils/types';
import { ICreateAdSelectItem } from '@pages/create-ad/models';

import { KeyName } from '../base.model';
import { Base, IdWithUrl } from './base';
import { truthy } from '@core/utils/helpers';

export module Google {
  export interface IAdAccountResponse {
    adAccounts: IGoogleAdAccountOutput[];
  }

  export interface IGoogleCampaignTable {
    id: string;
    status: Google.Dashboard.ICampaignStatus;
    name: string;
    type: string;
    checked: boolean;
  }

  export interface IConnectAdAccountRequest {
    contextId: string;
    adAccountId: string;
    loginId?: string;
  }

  export interface IConnectAdAccountResponse {
    connectAdAccount: IGoogleConnectedAdAccountOutput;
  }

  export interface IGoogleAdAccountOutput {
    adAccountId: number;
    loginCustomerId: number;
    status: number;
    currency: string;
    accessRole: number;
    name: string;
  }

  export interface IGoogleConnectedAdAccountOutput {
    businessId: string;
    connectedAdAccountId: string;
  }

  export enum GoogleConversionGoalCategoryEnum {
    Unspecified = 'Unspecified',
    Unknown = 'Unknown',
    Default = 'Default',
    PageView = 'PageView',
    Purchase = 'Purchase',
    Signup = 'Signup',
    Download = 'Download',
    AddToCart = 'AddToCart',
    BeginCheckout = 'BeginCheckout',
    SubscribePaid = 'SubscribePaid',
    PhoneCallLead = 'PhoneCallLead',
    ImportedLead = 'ImportedLead',
    SubmitLeadForm = 'SubmitLeadForm',
    BookAppointment = 'BookAppointment',
    RequestQuote = 'RequestQuote',
    GetDirections = 'GetDirections',
    OutboundClick = 'OutboundClick',
    Contact = 'Contact',
    Engagement = 'Engagement',
    StoreVisit = 'StoreVisit',
    StoreSale = 'StoreSale',
    QualifiedLead = 'QualifiedLead',
    ConvertedLead = 'ConvertedLead',
    Custom = 'Custom'
  }

  export enum GoogleInsightStatus {
    Enabled = 'enabled',
    Paused = 'paused'
  }

  export enum GoogleAdType {
    ResponsiveSearchAd = 'ResponsiveSearchAd',
    ResponsiveDisplayAd = 'ResponsiveDisplayAd',
    VideoResponsiveAd = 'VideoResponsiveAd'
  }
  export enum GoogleAdTypeResult {
    ResponsiveSearchAd = 'Search',
    ResponsiveDisplayAd = 'Display',
    VideoResponsiveAd = 'Video'
  }

  export enum GoogleUserListType {
    UNSPECIFIED = 'UNSPECIFIED',
    UNKNOWN = 'UNKNOWN',
    REMARKETING = 'REMARKETING',
    LOGICAL = 'LOGICAL',
    EXTERNAL_REMARKETING = 'EXTERNAL_REMARKETING',
    RULE_BASED = 'RULE_BASED',
    SIMILAR = 'SIMILAR',
    CRM_BASED = 'CRM_BASED'
  }

  export enum GoogleInterestTypes {
    LifeEvent = 'LifeEvent',
    DetailedDemographics = 'DetailedDemographics',
    UserInterest = 'UserInterest'
  }

  export enum AccessRoleEnum {
    WRITABLE = 0,
    READONLY = 1,
    BILLING = 2
  }

  export enum StatusEnum {
    UNSPECIFIED = 0,
    UNKNOWN = 1,
    ENABLED = 2,
    CANCELED = 3,
    SUSPENDED = 4,
    CLOSED = 5
  }

  export enum AccountOverviewType {
    CostPerClick = 'costPerClick',
    Clicks = 'clicks',
    Cost = 'cost',
    Conversions = 'conversions',
    ClickRate = 'clickRate',
    Impressions = 'impressions',
    Cpm = 'cpm',
    Default = 'default',
    PageView = 'pageView',
    Purchase = 'purchase',
    Signup = 'signup',
    Download = 'download',
    AddToCart = 'addToCart',
    BeginCheckout = 'beginCheckout',
    SubscribePaid = 'subscribePaid',
    PhoneCallLead = 'phoneCallLead',
    ImportedLead = 'importedLead',
    SubmitLeadForm = 'submitLeadForm',
    BookAppointment = 'bookAppointment',
    RequestQuote = 'requestQuote',
    GetDirections = 'getDirections',
    OutboundClick = 'outboundClick',
    Contact = 'contact',
    Engagement = 'engagement',
    StoreVisit = 'storeVisit',
    StoreSale = 'storeSale',
    QualifiedLead = 'qualifiedLead',
    ConvertedLead = 'convertedLead'
  }

  export enum GoogleCallToAction {
    LearnMore = 'LearnMore',
    GetQuote = 'GetQuote',
    ApplyNow = 'ApplyNow',
    SignUp = 'SignUp',
    ContactUs = 'ContactUs',
    Subscribe = 'Subscribe',
    Download = 'Download',
    BookNow = 'BookNow',
    ShopNow = 'ShopNow'
  }

  export enum GoogleCallToActionForDisplayAds {
    ApplyNow = 'ApplyNow',
    BookNow = 'BookNow',
    ContactUs = 'ContactUs',
    Download = 'Download',
    LearnMore = 'LearnMore',
    Install = 'Install',
    VisitSite = 'VisitSite',
    ShopNow = 'ShopNow',
    SignUp = 'SignUp',
    GetQuote = 'GetQuote',
    Subscribe = 'Subscribe',
    SeeMore = 'SeeMore'
  }

  export const CallToActionTypes = Object.values(GoogleCallToAction);
  export const CallToActionTypesForDisplayAds = Object.values(GoogleCallToActionForDisplayAds);

  export enum GoogleKeywordMatchType {
    Broad = 'Broad',
    Phrase = 'Phrase',
    Exact = 'Exact'
  }

  export interface GoogleKeywordCriterion {
    keyword: string;
    matchType: GoogleKeywordMatchType;
  }

  export enum Gender {
    Men = 1,
    Women = 2
  }

  export interface IAdAccountRequest {
    contextId: string;
  }

  export module Dashboard {
    export interface IGoogleAdAccountsResponse {}
    export interface IGoogleAdAccountOutput {
      accountStatus: number;
      accessRole: number;
      id: string;
      loginCustomerId: string;
      currency: string;
      name: string;
    }
    export interface IAccountOverViewTotalRequest {
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
    }

    export enum ResouceType {
      Campaign = 'Campaign',
      AdGroup = 'AdGroup',
      AssetGroup = 'AssetGroup',
      AdGroupAd = 'AdGroupAd'
    }

    export interface ITurnCampaignsRequest {
      resourceNames: string[];
      resourceType: ResouceType;
    }

    export interface IGoogleEditCampaignBudgetsRequest {
      budgetIds: number[];
      dailyBudgetAmount: number;
    }

    export interface IAccountOverViewTotalResponse {
      accountOverviewTotal: IGoogleInsightOutputCommon;
    }

    export interface IAccountOverViewRequest {
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
      interval?: Base.Dashboard.Global.InsightInterval;
    }

    export interface IAccountOverViewResponse {
      accountOverviewWithMetrics: IGoogleAccountOverviewWithIntervalOutput[];
    }

    export interface IGoogleAccountOverviewWithIntervalOutput {
      costPerClick: number | null;
      clicks: number | null;
      cost: number | null;
      conversions: number | null;
      clickRate: number | null;
      impressions: number | null;
      cpm: number | null;
      default: number | null;
      pageView: number | null;
      purchase: number | null;
      signup: number | null;
      download: number | null;
      addToCart: number | null;
      beginCheckout: number | null;
      subscribePaid: number | null;
      phoneCallLead: number | null;
      importedLead: number | null;
      submitLeadForm: number | null;
      bookAppointment: number | null;
      requestQuote: number | null;
      getDirections: number | null;
      outboundClick: number | null;
      contact: number | null;
      engagement: number | null;
      storeVisit: number | null;
      storeSale: number | null;
      qualifiedLead: number | null;
      convertedLead: number | null;
      interval: IGoogleAccountOverviewWithInterval;
    }

    export interface IGoogleAccountOverviewWithInterval {
      type: Base.Dashboard.Global.InsightInterval;
      value: string;
    }

    export interface IOauthRequest {
      contextId: string;
    }

    export interface IOauthResponse {
      oauth: IGoogleOAuthOutput;
    }

    export interface IGoogleOAuthOutput {
      oauthUrl: string;
    }

    export interface IAllBreakdownRequest {
      maximum_date?: boolean;
      end_date?: string;
      start_date?: string;
      date_const?: number;
    }

    export type BreakdownType = keyof IAccountBreakdownResponse;

    export type IAccountBreakdownByBreakdownType<T extends BreakdownType> = IBaseBreakdown & {
      [K in T]: string;
    };

    export interface IAccountBreakdownResponse {
      age: Google.Dashboard.IAccountBreakdownByBreakdownType<'age'>[];
      gender: Google.Dashboard.IAccountBreakdownByBreakdownType<'gender'>[];
      country: Google.Dashboard.IAccountBreakdownByBreakdownType<'country'>[];
      region: Google.Dashboard.IAccountBreakdownByBreakdownType<'region'>[];
    }

    export interface IBaseBreakdown {
      ageRange: number;
      clicks: number;
      costPerClick: number;
      clickRate: number;
      cost: number;
      gender: string;
      country: string;
      region: string;
      regionName: string;
      countryName: string;
      conversionMetrics?: IConversionMetrics[];
    }

    export interface IConversionMetrics {
      conversionCategory: string;
      conversions?: number;
      costPerConversions?: number;
    }

    export interface IGetGoogleAdGroupAdsWithMetricsRequest {
      startDate?: number;
      endDate?: number;
      datePreset: Base.Dashboard.Global.InsightDatePreset;
      first: number;
      last: string;
      after?: string;
      before?: string;
      adGroupId: number;
      status: GoogleInsightStatus;
      searchKey?: string;
    }
    export interface IGetGoogleAdGroupAdsWithMetricsResponse {
      adGroupAdsWithMetrics: {
        edges: IGoogleAdGroupAdOutputDtoEdge[];
        totalCount: number;
        pageInfo: Base.IPageInfo;
      };
    }

    export interface IGoogleAdGroupAdOutputDtoEdge {
      node: IGoogleAdGroupAdOutput;
      cursor: string;
    }

    export interface IGoogleAdGroupAdOutput {
      name: string;
      id: number;
      status: string;
      finalUrls: string[];
      resourceName: string;
      type: GoogleAdType;
      displayAd: IGoogleDisplayAdOutputDto;
      videoAd: IGoogleVideoAdOutputDto;
      searchAd: IGoogleSearchAdOutputDto;
      metrics: IGoogleAdGroupAdMetricsOutputDto;
    }

    export interface IGoogleDisplayAdOutputDto {
      businessName: string;
      descriptions: string[];
      headlines: string[];
      logoImages: string[];
      longHeadline: string;
      mainColor: string;
      marketImages: IGoogleMediaAssetOutput[];
      pricePrefix: string;
      promoText: string;
      squareLogoImages: IGoogleMediaAssetOutput[];
      squareMarketingImages: IGoogleMediaAssetOutput[];
      youtubeVideos: IGoogleMediaAssetOutput[];
    }

    export interface IGoogleMediaAssetOutput {
      url: string;
      resourceName: string;
    }
    export interface IGoogleVideoAdOutputDto {
      breadcrumb1: string;
      breadcrumb2: string;
      callToActions: string[];
      companionBanners: string[];
      descriptions: string[];
      headlines: string[];
      longHeadlines: string[];
      videos: IGoogleMediaAssetOutput[];
    }
    export interface IGoogleSearchAdOutputDto {
      descriptions: string[];
      headlines: string[];
      path1: string;
      path2: string;
    }
    export interface IGoogleAdGroupAdMetricsOutputDto {
      impressions: number;
      clicks: number;
      costPerClick: number;
      cost: number;
      conversions: number;
    }

    export interface ICampaignRequest {
      startDate?: number;
      endDate?: number;
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      first?: number;
      after?: string;
      before?: string;
      searchKey?: string;
      ids?: string[];
      status?: GoogleInsightStatus;
    }

    export interface ICampaignWihMetricEdge {
      node: IGoogleCampaignOutput;
    }
    export interface ICampaignWithMetricsResponse {
      campaignsWithMetricsSmartSorting: {
        edges: ICampaignWihMetricEdge[];
        totalCount: number;
        pageInfo: Base.CursorPageInfo;
      };
    }

    export interface ICampaignResponse {
      campaigns: {
        edges: { node: IGoogleCampaignOutput }[];
        totalCount: number;
        pageInfo: Base.CursorPageInfo;
      };
    }

    export enum ICampaignStatus {
      ENABLED = 'ENABLED',
      REMOVED = 'REMOVED',
      PAUSED = 'PAUSED'
    }

    export interface IGoogleCampaignOutput {
      id: string;
      status: ICampaignStatus;
      name: string;
      type: string;
      startDate: string | Date;
      endDate: string | Date;
      resourceName: string;
      dailyBudget: number;
      budgetId: number | string;
      metrics: IGoogleInsightOutputCommon;
      isSmartCampaign: boolean;
    }

    export interface IGoogleEditableAdGroupRequest {
      editableAdGroups: IGoogleEditableAdGroupInput[];
    }

    export interface IGoogleEditableAdGroupInput {
      id: number;
      name: string;
    }
    export interface IAdGroupWithMetricsRequest {
      first?: number;
      after?: string;
      last?: number;
      before?: string;
      searchKey?: string;
      startDate?: number;
      endDate?: number;
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      status?: GoogleInsightStatus | string;
      campaignId: number;
    }

    export interface IGoogleEditCampaignRequest {
      editableCampaigns: IEditableCampaigns[];
    }
    export interface IEditableCampaigns {
      id: number;
      name: string;
    }

    export interface IAdGroupWithMetric {
      name: string;
      id: string;
      status: Capitalize<Lowercase<ICampaignStatus>>;
      type: string;
      resourceName: string;
      metrics: IGoogleInsightOutputCommon;
    }

    export interface IAssetsGroupRequest
      extends Omit<IAdGroupWithMetricsRequest, 'datePreset' | 'startDate' | 'endDate'> {}

    export interface IAssetGroup {
      name: string;
      id: number;
      adStrength: number;
      finalMobileUrls: string[];
      finalUrls: string[];
      resourceName: string;
      status: Capitalize<Lowercase<ICampaignStatus>>;
    }

    export interface IGoogleInsightOutputCommon {
      costPerClick: number;
      clicks: number;
      cost: number;
      conversions: number;
      clickRate: number;
      impressions: number;
      cpm: number;
      default: number;
      pageView: number;
      purchase: number;
      signup: number;
      download: number;
      addToCart: number;
      beginCheckout: number;
      subscribePaid: number;
      phoneCallLead: number;
      importedLead: number;
      submitLeadForm: number;
      bookAppointment: number;
      requestQuote: number;
      getDirections: number;
      outboundClick: number;
      contact: number;
      engagement: number;
      storeVisit: number;
      storeSale: number;
      qualifiedLead: number;
      convertedLead: number;
    }

    export interface IFunnelDashboardRequest {
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
    }
    export interface IFunnelDashboardResponse {
      funnelDashboard: IGoogleFunnelDashboardOutput;
    }

    export interface IGoogleFunnelDashboardOutput {
      clicks: number;
      impressions: number;
      default: number;
      pageView: number;
      purchase: number;
      signup: number;
      download: number;
      addToCard: number;
      beginCheckout: number;
      subscribePaid: number;
      phoneCallLead: number;
      importedLead: number;
      submitLeadForm: number;
      bookAppointment: number;
      requestQuote: number;
      getDirections: number;
      outboundClick: number;
      contact: number;
      engagement: number;
      storeVisit: number;
      storeSale: number;
      qualifiedLead: number;
      convertedLead: number;
    }

    export enum IConversionCategoryEnum {
      CLICKS = 0,
      IMPRESSIONS = 1,
      DEFAULT = 2,
      PAGE_VIEW = 3,
      PURCHASE = 4,
      SIGNUP = 5,
      DOWNLOAD = 7,
      ADD_TO_CART = 8,
      BEGIN_CHECKOUT = 9,
      SUBSCRIBE_PAID = 10,
      PHONE_CALL_LEAD = 11,
      IMPORTED_LEAD = 12,
      SUBMIT_LEAD_FORM = 13,
      BOOK_APPOINTMENT = 14,
      REQUEST_QUOTE = 15,
      GET_DIRECTIONS = 16,
      OUTBOUND_CLICK = 17,
      CONTACT = 18,
      ENGAGEMENT = 19,
      STORE_VISIT = 20,
      STORE_SALE = 21,
      QUALIFIED_LEAD = 22,
      CONVERTED_LEAD = 23
    }

    export const AccountOverviewTypes: IOption[] = [
      { label: 'Clicks', value: 'clicks' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Default', value: 'default' },
      { label: 'PageView', value: 'pageView' },
      { label: 'Purchase', value: 'purchase' },
      { label: 'Signup', value: 'signup' },
      { label: 'Download', value: 'download' },
      { label: 'Add to Card', value: 'addToCard' },
      { label: 'Begin Checkout', value: 'beginCheckout' },
      { label: 'Subscribe Paid', value: 'subscribePaid' },
      { label: 'Phone Call Lead', value: 'phoneCallLead' },
      { label: 'Imported Lead', value: 'importedLead' },
      { label: 'Submit LeadForm', value: 'submitLeadForm' },
      { label: 'Book Appointment', value: 'bookAppointment' },
      { label: 'Request Quote', value: 'requestQuote' },
      { label: 'Get Directions', value: 'getDirections' },
      { label: 'Outbound Click', value: 'outboundClick' },
      { label: 'Contact', value: 'contact' },
      { label: 'Engagement', value: 'engagement' },
      { label: 'Store Visit', value: 'storeVisit' },
      { label: 'Store Sale', value: 'storeSale' },
      { label: 'Qualified Lead', value: 'qualifiedLead' },
      { label: 'Converted Lead', value: 'convertedLead' }
    ];

    export enum AccountOverviewType {
      Clicks = 'clicks',
      Impressions = 'impressions',
      Default = 'default',
      PageView = 'pageView',
      Purchase = 'purchase',
      Signup = 'signup',
      Download = 'download',
      AddToCard = 'addToCard',
      BeginCheckout = 'beginCheckout',
      SubscribePaid = 'subscribePaid',
      PhoneCallLead = 'phoneCallLead',
      OmportedLead = 'importedLead',
      SubmitLeadForm = 'submitLeadForm',
      RequestQuote = 'requestQuote',
      GetDirections = 'getDirections',
      OutboundClick = 'outboundClick',
      Contact = 'contact',
      Engagement = 'engagement',
      StoreVisit = 'storeVisit',
      StoreSale = 'storeSale',
      QualifiedLead = 'qualifiedLead',
      ConvertedLead = 'convertedLead'
    }

    export interface GoogleCampaignRemove {
      resourceIds: number[];
      resourceType: ResouceType;
    }
  }

  export module UI {
    export type SelectAudienceItem = ICreateAdSelectItem<AudienceType>;
  }

  export enum CampaignObjective {
    ConversionsOnMyWebsite = 'ConversionsOnMyWebsite'
  }

  export enum AudienceType {
    Manual
  }

  export enum AdType {
    PerformanceMax,
    DisplayAds,
    SearchAds
  }

  export const AD_TYPES: Partial<Record<AdType, string>> = {
    [AdType.PerformanceMax]: 'Performance Max Ads',
    [AdType.DisplayAds]: 'Display Ads'
  };

  export module CreateAd {
    export interface GoogleSearchCampaignRequest {
      campaign: GoogleSearchCampaign;
    }

    export interface GoogleSearchCampaignAssets {
      headlines: string[];
      descriptions: string[];
      path1: string;
      path2: string;
      businessName: string;
    }

    export interface GoogleStructuredSnippetsCreate {
      header: string;
      values: string[];
      name: string;
    }

    export interface GoogleStructuredSnippets {
      create: GoogleStructuredSnippetsCreate;

      preparedId: string;
    }

    export interface GoogleSearchCampaign {
      campaignName: string;
      conversionGoal: GoogleConversionGoalInput[];
      conversionValueTargetRoas: number;
      url: string;
      budgetAmount: number;
      startDate: number;
      endDate?: number;
      assets: GoogleSearchCampaignAssets;
      audience: DisplayCampaignAudience;
      youtubeExtension?: GooglePMYoutubeExtension;
      sitelinkExtension?: GooglePMSitelinkExtension;
      callExtension?: GooglePMCallExtension;
      calloutExtension?: GooglePMCalloutExtension;
      structuredSnippets?: GoogleStructuredSnippets;
      paused: boolean;
    }

    export interface GooglePMCampaignRequest {
      campaign: GooglePMCampaign;
    }

    export interface GooglePMCampaign {
      campaignName: string;
      conversionGoal: GoogleConversionGoalInput[];
      conversionValueTargetRoas: number;
      url: string;
      callToAction: GoogleCallToAction;
      budgetAmount: number;
      startDate: Date; // Original Type: number!
      endDate?: Date; // Original Type: number!
      assets: IGooglePMAssets;
      audience: GooglePMAudience;
      sitelinkExtension?: GooglePMSitelinkExtension;
      callExtension?: GooglePMCallExtension;
      calloutExtension?: GooglePMCalloutExtension;
      youtubeExtension?: GooglePMYoutubeExtension;
      paused: boolean;
    }

    export interface DisplayCampaignAssets {
      longHeadline: string;
      headlines: string[];
      descriptions: string[];
      businessName: string;
      images: {
        squareMarketingIds: IdWithUrl[];
        marketingIds: IdWithUrl[];
        logoIds: IdWithUrl[];
        squareLogoIds: IdWithUrl[];
      };
      callToActionText: GoogleCallToActionForDisplayAds;

      allowFlexibleColor?: boolean;
      mainColor?: string;
      accentColor?: string;
      pricePrefix?: string;
      promoText?: string;

      /**
       * :Extra
       */
      campaignName?: string;
    }

    export type DisplayCampaignAudienceCreate = {
      audienceName: string;
      includedLocationIds: number[];
      excludedLocationIds: number[];
      minAge: number;
      maxAge: number;
      female: boolean;
      male: boolean;
      keywords: string[];
      topicIds: number[];
      userInterests: number[];
      languageIds: number[];
    };

    export interface DisplayCampaignAudience {
      create: DisplayCampaignAudienceCreate;

      preparedId?: string;
    }

    export interface GoogleDisplayCampaign {
      campaignName: string;
      conversionGoal: GoogleConversionGoalInput[];
      url: string;
      budgetAmount: number;
      startDate: number;
      assets: DisplayCampaignAssets;
      audience: DisplayCampaignAudienceCreate;
      youtubeExtension?: GooglePMYoutubeExtension;
    }

    export interface GoogleDisplayCampaignRequest {
      campaign: GoogleDisplayCampaign;
    }

    export interface GoogleConversionGoalInput {
      id: number;
      category: GoogleConversionGoalCategoryEnum;
      conversionActionIds: Number[];
    }

    export interface GooglePMYoutubeExtensionCreate {
      urls: string[];
      name: string;
      youtubeLinks?: Google.CreateAd.IYoutubeVideoValidity[];
    }
    export interface GooglePMYoutubeExtension {
      create: GooglePMYoutubeExtensionCreate;
      preparedId: string;
    }
    export interface GooglePMCalloutExtensionCreate {
      texts: string[];
      name: string;
    }

    export interface GooglePMCalloutExtension {
      create: GooglePMCalloutExtensionCreate;
      preparedId: String;
    }

    export interface GooglePMCallExtensionCreate {
      countryCode: string;
      phoneNumber: string;
      name: string;
    }

    export interface GooglePMCallExtension {
      create: GooglePMCallExtensionCreate;
      preparedId: String;
    }

    export interface GooglePMSitelinkDetails {
      sitelinkText: string;
      firstDescription: string;
      secondDescription: string;
      finalUrl: string;
    }

    export interface GooglePMSitelinkExtensionCreate {
      sitelinks: GooglePMSitelinkDetails[];
      name: string;
    }

    export interface GooglePMSitelinkExtension {
      create: GooglePMSitelinkExtensionCreate;
      preparedId: string;
    }

    export interface IGooglePMAssets {
      longHeadlines: string[];
      shortHeadlines: string[];
      shortDescription: string;
      descriptions: string[];
      businessName: string;
      images: GooglePMImages;
    }

    export interface GooglePMImages {
      squareMarketingIds: IdWithUrl[];
      landscapeMarketingIds: IdWithUrl[];
      portraitMarketingIds: IdWithUrl[];
      logoIds: IdWithUrl[];
      landscapeLogoIds: IdWithUrl[];
    }

    export interface GooglePMAudience {
      create: GooglePMAudienceCreate;
      preparedId?: string;
    }

    export interface IGoogleBaseAudienceCreate {
      audienceName: string;
      includedLocations: ILocationSearch[];
      excludedLocations: ILocationSearch[];
      minAge: number;
      maxAge: number;
      male: boolean;
      female: boolean;
      languageIds: KeyName[];
    }

    export interface GooglePMAudienceCreate extends IGoogleBaseAudienceCreate {
      keywords?: Google.GoogleKeywordCriterion[];
      customAudiences?: GoogleKeywordCriterion[];
      interests: IInterestsSearch[];
      yourDatas: IYourDataSearch[];
    }

    export interface GooglePMInterestDetails {
      id: number;
      type: GoogleInterestTypes;
    }

    export interface IGoogleYourDataRequest {
      number: number;
      searchKey: string;
    }

    export interface IGoogleYourDataResponse {
      yourDataSearch: IYourDataSearch[];
    }

    export interface IYourDataSearch {
      id: number;
      name: string;
      type: Google.GoogleUserListType;
    }

    export interface IGoogleAudienceRequest {
      number: number;
      searchKey: string;
    }

    export interface IGoogleAudienceResponse {
      audienceSearch: {
        id: number;
        name: string;
        description: string;
      };
    }

    export interface IGoogleInterestRequest {
      limit: number;
      searchKey: string;
    }

    export interface IGoogleInterestResponse {
      userInterestsSearch: IInterestsSearch[];
    }

    export interface IInterestsSearch {
      id: number;
      name: string;
      type: string;
      interestType: GoogleInterestTypes;
    }

    export interface IGoogleLocationConstantRequest {
      limit: number;
      searchKey?: string;
    }

    export interface IGoogleLocationConstantResponse {
      locationSearch: ILocationSearch[];
    }

    export interface ILocationSearch {
      fullName: string;
      id: number;
      code: string;
      type: string;
    }

    export interface IGoogleLanguageConstantRequest {
      number: number;
      searchKey: string;
    }

    export interface IGoogleConversionGoalOutputRequest {
      searchKey: string;
    }

    export interface IGoogleConversionGoalOutputResponse {
      conversionGoal: [
        {
          name: string;
          category: GoogleConversionGoalCategoryEnum;
          id: number;
          conversionActionIds: Number[];
        }
      ];
    }

    export interface IGoogleLanguageConstantResponse {
      languageSearch: [{ id: number; name: string; code: string }];
    }

    export interface IGoogleYoutubeVideoSearchResponse {
      youtubeSearch: {
        edges: {
          cursor: string;
          node: { id: string; title: string; description: string; channelId: string; channelTitle: string };
        };
        totalCount: number;
        pageInfo: Base.IPageInfo;
      };
    }

    export interface IGoogleYoutubeVideoValidationUrlRequest {
      youtubeUrl: string;
    }

    export interface IGoogleYoutubeVideoValidationUrlResponse {
      youtubeUrlValidation: IYoutubeVideoValidity;
    }

    export interface IYoutubeVideoValidity {
      id: string;
      url: string;
      validity: boolean;
      index: number;
      title: string;
      description: string;
      thumbnails: GoogleExtendedYoutubeThumbnails;
    }

    export interface GoogleExtendedYoutubeThumbnails {
      default: GoogleYoutubeThumbnailDetails;
      high: GoogleYoutubeThumbnailDetails;
      medium: GoogleYoutubeThumbnailDetails;
      maxres: GoogleYoutubeThumbnailDetails;
      standard: GoogleYoutubeThumbnailDetails;
    }

    export interface GoogleYoutubeThumbnailDetails {
      height: number;
      url: string;
      width: number;
    }

    export interface IEstimateReachRequest {
      targeting: any;
    }

    export interface IEstimateReachResponse {
      audienceSizeLowerBound: number;
      audienceSizeUpperBound: number;
      isValid: boolean;
    }

    export type UploadGroupTuple = [File, ...BuildTuple<4, File | null>];

    export interface IUploadPerformanceMaxImagesRequest {
      squareMarketing?: UploadGroupTuple;
      portraitMarketing?: UploadGroupTuple;
      landscapeMarketing?: UploadGroupTuple;
      logo?: File;
      landscapeLogo?: File;
    }

    export interface IUploadPerformanceMaxImagesResponse {
      squareMarketing?: { id: string; url: string }[];
      portraitMarketing?: { id: string; url: string }[];
      landscapeMarketing?: { id: string; url: string }[];
      logo?: { id: string; url: string }[];
      landscapeLogo?: { id: string; url: string }[];
    }
  }

  export module Utils {
    export const replaceExclamationMark = <T extends string>(values: Array<T | null | undefined> | null | undefined) =>
      values
        ?.filter(truthy)
        .map((v) => v.replaceAll('!', ''))
        .filter(truthy) || [];
  }
}
