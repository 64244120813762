export interface ImageGenerationTask {
  id: string;
  status: number;
  imageRenderProcessId: string;
}

export interface AdcImageGenerationStateModel {
  tasks: ImageGenerationTask[];
}

export class AddImageGenerationTasks {
  static readonly type = '[AdcImageGeneration] Add Image Generation Tasks';
  constructor(public payload: ImageGenerationTask[]) {}
}

export class UpdateImageGenerationTask {
  static readonly type = '[AdcImageGeneration] Update Image Generation Task';
  constructor(public payload: Omit<ImageGenerationTask, 'imageRenderProcessId'>) {}
}

export class SetImageGenerationTasks {
  static readonly type = '[AdcImageGeneration] Set Image Generation Tasks';
  constructor(public payload: ImageGenerationTask[]) {}
}
