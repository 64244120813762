import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { inject, Injectable, Provider } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from '@core/state';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TITLE_PLACEHOLDER } from '@core/utils/router';

@Injectable()
export class AynTitleStrategy extends TitleStrategy {
  store = inject(Store);
  title = inject(Title);

  subscription?: Subscription;

  updateTitle(snapshot: RouterStateSnapshot) {
    this.subscription?.unsubscribe();
    this.subscription = this.store.select(UserState.getUserTheme).subscribe((theme) => {
      const title = this.buildTitle(snapshot) || '';
      if (theme?.companyName) {
        this.title.setTitle(title.replace(TITLE_PLACEHOLDER, theme.companyName));
      } else {
        this.title.setTitle(title.replace(TITLE_PLACEHOLDER, 'ADYOUNEED'));
      }
    });
  }
}

export const AYN_TITLE_PROVIDER: Provider = {
  provide: TitleStrategy,
  useClass: AynTitleStrategy
};
