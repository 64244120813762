import { Component } from '@angular/core';
import { Facebook } from '@core/models';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { FacebookStateService } from '@pages/create-ad/state';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';
import { mapAdTypes, selectedItem } from '@pages/create-ad/components/select-objective/utils';
import { LengthCalculator, provideLengthCalculator } from '@ayn-ui/lib/modules';
import { byteSize, eachChar } from '@core/utils';

const AD_TYPES__FACEBOOK: ICreateAdSelectItem<Facebook.AdType>[] = [
  {
    id: 1,
    title: 'Dynamic Creative Ads',
    description: 'Perfect for advertising 1 product/service using a single image or video.',
    icon: 'dynamic',
    empty: false,
    active: true,
    type: Facebook.AdType.DynamicCreative
  },
  {
    id: 2,
    title: 'Carousel',
    description: 'Perfect for showing several product/services with different graphics/videos',
    icon: 'carousel',
    empty: false,
    active: false,
    type: Facebook.AdType.CarouselAds
  },
  {
    id: 3,
    title: 'Existing Post',
    description: '⚠️ We are updating this feature. Please check back later.',
    icon: 'existing-post',
    empty: false,
    active: false,
    disabled: true,
    type: Facebook.AdType.InstantExperienceAds
  }
];

export const FACEBOOK_INPUT_LENGTH_CALCULATOR: LengthCalculator = (val: string) => {
  if (!val.length) {
    return 0;
  }
  let count = 0;
  eachChar(val, (char) => {
    const size = byteSize(char);
    count += size > 1 ? Math.ceil(size) : 1;
  });

  return count;
};

@Component({
  selector: 'aayn-select-ad-type--facebook',
  templateUrl: 'select-ad-type-content.component.html',
  providers: [AdPreviewService, provideLengthCalculator(FACEBOOK_INPUT_LENGTH_CALCULATOR)]
})
export class SelectAdTypeContentFacebookComponent {
  adTypes$ = this.facebookStateService.adCreationModel$.pipe(
    mapAdTypes(AD_TYPES__FACEBOOK, 'ad'),
    selectedItem((ad) => {
      if (ad.type) {
        this.selectedAdType = ad.type;
      }
    })
  );

  protected AdTypes = Facebook.AdType;

  protected selectedAdType: Facebook.AdType = Facebook.AdType.DynamicCreative;

  constructor(private facebookStateService: FacebookStateService) {}

  adTypeChangeStatus(item: ICreateAdSelectItem<Facebook.AdType>) {
    if (this.facebookStateService.adCreationModel.selectedTypes.ad !== item.type) {
      this.facebookStateService.changeAdType(item.type!);
    }
  }
}
