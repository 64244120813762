import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { AynUITranslateService } from '@ayn-ui/lib/modules/translate/translate.module';
import { TranslateService } from '@ngx-translate/core';

class LocaleId extends String {
  constructor(private translateService: TranslateService) {
    super();
  }

  toString(): string {
    return this.translateService.currentLang || this.translateService.getDefaultLang();
  }

  valueOf(): string {
    return this.toString();
  }
}

export function translateChangeFactory(service: TranslateService, aynTranslate: AynUITranslateService, router: Router) {
  return () => {
    let init = false;
    service.onLangChange.subscribe((values) => {
      localStorage.setItem('lang', values.lang);
      const localePath = `/locales/${values.lang}`;
      import(
        /* webpackMode: "lazy-once" */
        /* webpackChunkName: "locales"*/
        /* webpackInclude: /[/\\](en|fr|es|pt|de|it|tr|pl|se|no|bg|lv|in|dk|fi|is)\.(mjs)$/ */
        /* webpackExclude: /[/\\]global|extra/ */
        `@angular/common${localePath}`
      ).then((lang) => {
        registerLocaleData(lang.default, values.lang);
        if (init) {
          const { shouldReuseRoute } = router.routeReuseStrategy;

          router.routeReuseStrategy.shouldReuseRoute = () => false;
          router.navigated = false;

          router.navigateByUrl(router.url).then(() => {
            router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
            aynTranslate.changeLanguage(values.translations);
          });
        } else {
          aynTranslate.changeLanguage(values.translations);
        }
        init = true;
      });
    });
  };
}

function setLocale(translateService: TranslateService) {
  return () => {
    const lang = localStorage.getItem('lang') || 'en';
    return translateService.use(lang);
  };
}

export const TRANSLATE_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [TranslateService],
    useFactory: setLocale
  },
  {
    provide: APP_INITIALIZER,
    useFactory: translateChangeFactory,
    deps: [TranslateService, AynUITranslateService, Router],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [TranslateService],
    useClass: LocaleId
  }
];
