import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/public-api';
import { Linkedin, LinkedinService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { LocationSelectionOverlayItemActiveType } from '@pages/create-ad/components/shared/manuel-audience-setup/components/location-selection/components/location-selection-overlay-item/location-selection-overlay-item.component';
import { LinkedinStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-location-selection--linkedin',
  templateUrl: './location-selection.component.html'
})
export class AudienceLocationSelectionLinkedinComponent implements OnInit {
  @Input() form?: FormGroup;

  @ViewChild(Overlay) overlay?: Overlay;

  protected locationSearchControl = new FormControl('', [Validators.minLength(1)]);

  @Select(LoaderState.getAny(['LinkedinSearchByFacet'])) loader$?: Observable<boolean>;

  protected geoLocations: Linkedin.CreateAd.SearchByFacetEdge[] = [];

  geoLocationsInclude: Linkedin.CreateAd.SearchByFacetEdge[] = [];

  geoLocationsExclude: Linkedin.CreateAd.SearchByFacetEdge[] = [];

  constructor(
    private linkedinStateService: LinkedinStateService,
    private linkedinService: LinkedinService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.geoLocationsInclude = [...(o.audience.geoLocations?.include || [])];
      this.geoLocationsExclude = [...(o.audience.geoLocations?.exclude || [])];
    });

    this.locationSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((term) => !!term)
      )
      .subscribe((term) => {
        if (this.locationSearchControl.invalid) return;
        if (this.form?.value.geoLocations?.length < 1 && this.form?.value.excludedGeoLocations?.length < 1) {
          this.locationSearchControl.setErrors({ inValid: true });
        }

        this.linkedinService.searchByFacet({ facet: 'Locations', query: term! }).subscribe((result) => {
          if (result?.edges) {
            this.geoLocations = result.edges;

            if (!this.overlay?.render) this.overlay?.show(null);

            this.cdr.detectChanges();
          }
        });
      });
  }

  private _mapGeoLocations() {
    this.form?.patchValue(
      {
        geoLocations: [...this.geoLocationsInclude],
        excludedGeoLocations: [...this.geoLocationsExclude]
      },
      { emitEvent: true }
    );
  }
  protected onIncludeClick(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    if (!this.checkHasGeoLocation(geoLocation)) {
      this.geoLocationsInclude.push(geoLocation);
      this._mapGeoLocations();
    }
  }
  protected onExcludeClick(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    if (!this.checkHasGeoLocation(geoLocation)) {
      this.geoLocationsExclude.push(geoLocation);
      this._mapGeoLocations();
    }
  }

  private checkHasGeoLocation(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    return [...this.geoLocationsInclude, ...this.geoLocationsExclude].some((o) => o.node.urn === geoLocation.node.urn);
  }

  protected isActive(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    const hasAddedInclusions = this.geoLocationsInclude.some((o) => o.node.urn === geoLocation.node.urn);
    const hasAddedExclusions = this.geoLocationsExclude.some((o) => o.node.urn === geoLocation.node.urn);

    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'inclusions' : hasAddedExclusions ? 'exclusions' : ''
    } as LocationSelectionOverlayItemActiveType;
  }

  protected removeItem(index: number, type: 'include' | 'exclude') {
    if (type === 'include') {
      this.geoLocationsInclude.splice(index, 1);
    } else {
      this.geoLocationsExclude.splice(index, 1);
    }
    this._mapGeoLocations();
  }

  onIncludeRemove(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    this.geoLocationsInclude = this.geoLocationsInclude.filter((o) => o.node.urn !== geoLocation.node.urn);
    this._mapGeoLocations();
  }

  onExcludeRemove(geoLocation: Linkedin.CreateAd.SearchByFacetEdge) {
    this.geoLocationsExclude = this.geoLocationsExclude.filter((o) => o.node.urn !== geoLocation.node.urn);
    this._mapGeoLocations();
  }
}
