import { Component, OnInit, ViewChild } from '@angular/core';
import { IAdAccountType, IPlatformContext, Platforms, TikTokAdAccount } from '@core/models';
import { PlatformContextState } from '@core/state/platform-context.state';
import { Store } from '@ngxs/store';
import { ModalPageComponent } from '@ayn-ui/lib/modules';
import { AdAccountState } from '@core/state';
import { ConnectAdAccountService } from '@shared/components/account-select-modal/services';

@Component({
  selector: 'aayn-ad-account-select--tiktok',
  templateUrl: 'ad-account-select.component.html',
  providers: [ConnectAdAccountService]
})
export class AdAccountSelectTikTokComponent implements OnInit {
  platforms = Platforms;

  protected activeStep = 0;

  protected query = '';
  protected adAccounts$ = this.store.select(AdAccountState.adAccounts<Platforms.TikTok>);

  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .TikTok;
  private selectedAccount?: IAdAccountType<TikTokAdAccount>;

  @ViewChild(ModalPageComponent) modal!: ModalPageComponent;

  get completeDisabled() {
    return !this.selectedAccount?.selected;
  }

  constructor(private store: Store, private connectAdAccountService: ConnectAdAccountService) {}

  ngOnInit() {}

  complete() {
    if (this.completeDisabled) return;

    this.connectAdAccountService.connectAdAccount(
      Platforms.TikTok,
      {
        adAccountId: this.selectedAccount!.id!,
        loginId: this.selectedAccount!.ownerBusinessCenterId
      },
      this.modal.modalRef!
    );
  }

  showCreateNewAccountAccountModal() {
    //TODO: Tiktok: implement ad account creation
  }

  selectAdAccount(account: IAdAccountType<TikTokAdAccount>) {
    this.selectedAccount = account;
  }
}
