import { Platforms } from '@core/models';

import { createAdBaseModel, CreateAdCommon, ICreateAdCommonValidate, UsesInitialization, WithMap } from '../common';
import { InstantAd } from '@core/models/platforms/instant-ad';
import { FacebookCreateAd, GoogleCreateAd } from '@core/ad-platforms';

export module InstantAdCreateAd {
  export type InstantAdPlatforms = Platforms.Meta | Platforms.Google;

  export type AdRequest = {
    [Platforms.Meta]: FacebookCreateAd.AdRequest;
    [Platforms.Google]: GoogleCreateAd.AdRequest;
  };

  export type AdType = any;

  function createEmptyModel() {
    const baseModel = createAdBaseModel(
      InstantAd.Client.CampaignObjective.WebsiteTraffic,
      InstantAd.Client.AudienceType.Manual,
      InstantAd.Client.AdType.Default
    );

    return {
      ...baseModel,
      textAi: {
        ...baseModel.textAi,
        customRequests: '',
        cta: ''
      } as InstantAd.Client.TextAi,
      selectedPlatform: Platforms.Unset as Platforms.Meta | Platforms.Google,

      campaign: {
        name: '',
        objective: InstantAd.Client.CampaignObjective.WebsiteTraffic,
        startDate: new Date(),
        endDate: null,
        budget: 0,
        budgetType: InstantAd.Client.CampaignBudgetType.Daily,
        appName: ''
      } as InstantAd.Client.Campaign,

      audience: {
        geoLocations: {
          inclusions: {}
        },
        detailedTargetings: {}
      } as InstantAd.Client.Audience,

      ad: {
        selectedDescriptions: [],
        selectedHeadlines: [],
        selectedPrimaryTexts: [],
        selectedShortHeadlines: [],
        descriptions: [],
        headlines: [],
        images: [],
        tasks: [],
        primaryTexts: [],
        shortHeadlines: [],
        uploadedImages: [],
        websiteUrl: ''
      } as InstantAd.Client.Ad
    };
  }

  export type ICreateAdModel = ReturnType<typeof createEmptyModel>;

  const deepClonedModel = structuredClone(createEmptyModel());

  @UsesInitialization(deepClonedModel)
  @WithMap({
    [Platforms.Meta]: function () {}
  })
  export class CreateAd {
    get isValidImages(): boolean {
      return true; // TODO: this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd<P extends InstantAdPlatforms = InstantAdPlatforms>
    extends CreateAdCommon<ICreateAdModel, AdRequest[P], any> {
    mapToApiRequest<T extends P>(platform?: T): AdRequest[T];
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
