<div class="aayn-create-ad--item" *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta" [@fadeInOnEnter]>
      <aayn-select-objective--facebook></aayn-select-objective--facebook>
    </div>

    <div *ngSwitchCase="Platforms.Google" [@fadeInOnEnter]>
      <aayn-select-objective--google></aayn-select-objective--google>
    </div>

    <div *ngSwitchCase="Platforms.Bing" [@fadeInOnEnter]>
      <aayn-select-objective--bing></aayn-select-objective--bing>
    </div>

    <div *ngSwitchCase="Platforms.LinkedIn" [@fadeInOnEnter]>
      <aayn-select-objective--linkedin></aayn-select-objective--linkedin>
    </div>

    <div *ngSwitchCase="Platforms.SmartCampaign" [@fadeInOnEnter]>
      <aayn-select-objective--smart-campaign></aayn-select-objective--smart-campaign>
    </div>

    <div *ngSwitchCase="Platforms.TikTok" [@fadeInOnEnter]>
      <aayn-select-objective--tiktok></aayn-select-objective--tiktok>
    </div>
  </div>

  <aayn-ad-item-footer>
    <button
      ayn-button="primary"
      class="aayn-create-ad--item--btn"
      ayn-button-block
      (click)="nextStep(selectedPlatform)"
      [width]="215"
      style="max-width: 215px"
      [innerHTML]="'Next Step; Select and Audience' | translate"
    ></button>
  </aayn-ad-item-footer>
</div>
