import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IOption } from '@ayn-ui/public-api';
import { BingService } from '@core/index';
import { Bing } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--bing-form',
  templateUrl: 'form.component.html'
})
export class ConversionMyWebsiteSMBingFormComponent implements OnInit {
  @Input() form?: FormGroup;

  adCreationModel = this.smartCampaignStateService.adCreationModel;

  conversionEvents: IOption[] = [];

  @Select(LoaderState.getAny(['ConversionGoal'])) loader$?: Observable<boolean>;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private cdr: ChangeDetectorRef,
    private bingService: BingService
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;
    });

    this.searchValueChange();
  }

  searchValueChange() {
    this.bingService.getConversionGoals$().subscribe((result) => {
      this.conversionEvents =
        result?.data.getConversionGoals.map(
          (o) =>
            ({
              label: o.name,
              value: o
            } as IOption)
        ) || [];

      this.cdr.detectChanges();
    });
  }

  protected valueChange(value: Bing.AudienceCampaign.IBingConversionGoalOutput[]) {
    if (value) {
      this.form!.patchValue({ conversionEvent: value! });
    }
  }
}
