<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">Sorry to see you go!</section>
  <section class="aayn-video-modal--description">
    <p>
      Your ADYOUNEED account cancellation is in process. You can still use your remaining benefits until the end of the
      billing cycle. We’re always here if you decide to return!
    </p>
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary" icon="refresh">Reactivate Subscription</button>
    <a class="aayn-video-modal--skip" (click)="videoModal.closeModal()">Continue</a>
  </section>
</aayn-video-modal>
