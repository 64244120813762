import { EventEmitter, Type } from '@angular/core';
import { SelectPlatformComponent } from '../../components/select-platform/select-platform.component';
import { SelectObjectiveComponent } from '../../components/select-objective/select-objective.component';
import { SelectAudienceComponent } from '../../components/select-audience/select-audience.component';
import { SelectAdtypeComponent } from '../../components/select-adtype/select-adtype.component';
import { SelectReviewAndPublishComponent } from '../../components/select-review-and-publish/select-review-and-publish.component';
import { SelectPlatformPlatformsHeaderComponent } from '../../components/platform-header/select-platform-platforms-header';
import { SelectAnObjectivePlatformsHeaderComponent } from '../../components/platform-header/select-an-objective-platforms-header';
import { SelectAnAudiencePlatformsHeaderComponent } from '../../components/platform-header/select-an-audience-platforms-header';
import { SelectAnAdTypePlatformsHeaderComponent } from '../../components/platform-header/select-an-ad-type-platforms-header';
import { Platforms } from '@core/models';
import { DescribeProjectComponent } from '@pages/create-ad/components/describe-project';
import { SelectAiGeneratedComponent } from '@pages/create-ad/components/select-ai-generated';
import { IntercomStateKeys } from '@core/services';
import { CreateAnAdSteps } from '@pages/create-ad/models';
import { DescribeProjectPlatformsHeaderComponent } from '@pages/create-ad/components/platform-header/describe-project-platforms-header';
import { SelectAiGeneratedPlatformsHeaderComponent } from '@pages/create-ad/components/platform-header/select-ai-generated-platforms-header/select-ai-generated-platforms-header.component';

export interface CreateAdStepComponent {
  next: EventEmitter<{
    draftOperation?: 'new' | 'update';
  }>;
}
export interface CreateAdStep {
  collapseDescription: string;
  collapseTitle: string;
  component: Type<CreateAdStepComponent | {}>;
  headerComponent?: Type<any>;
  tourName?: IntercomStateKeys | ((platform: Platforms) => IntercomStateKeys);
  stepName: CreateAnAdSteps;
  isStepShow?: (isCurrent: boolean) => boolean;
}

const PlatformStep: CreateAdStep = {
  collapseTitle: 'Select a platform!',
  collapseDescription: 'Choose the platform on which to advertise',
  component: SelectPlatformComponent,
  headerComponent: SelectPlatformPlatformsHeaderComponent,
  tourName: 'CREATE_AD_STEP_1',
  stepName: CreateAnAdSteps.Platform
};

const ObjectiveStep: CreateAdStep = {
  collapseTitle: 'Select an objective',
  collapseDescription: 'Select your ad objective based on your goals.',
  component: SelectObjectiveComponent,
  headerComponent: SelectAnObjectivePlatformsHeaderComponent,
  tourName: 'CREATE_AD_STEP_2',
  stepName: CreateAnAdSteps.Objective
};

const AudienceStep: CreateAdStep = {
  collapseTitle: 'Setup your Target Audience',
  collapseDescription: 'Setup the target audience for this ad.',
  component: SelectAudienceComponent,
  headerComponent: SelectAnAudiencePlatformsHeaderComponent,
  tourName: (platform) =>
    platform === Platforms.SmartCampaign ? 'CREATE_AD_STEP_3_FOR_SMART_CAMPAIGN' : 'CREATE_AD_STEP_2',
  stepName: CreateAnAdSteps.Audience
};

const AdTypeStep: CreateAdStep = {
  collapseTitle: 'Select an ad type',
  collapseDescription: 'Select the type of this ad.',
  component: SelectAdtypeComponent,
  headerComponent: SelectAnAdTypePlatformsHeaderComponent,
  tourName: (platform) =>
    platform === Platforms.SmartCampaign ? 'CREATE_AD_STEP_4_FOR_SMART_CAMPAIGN' : 'CREATE_AD_STEP_4',
  stepName: CreateAnAdSteps.AdType
};

const ReviewAndPublishStep: CreateAdStep = {
  collapseTitle: 'Set a Budget & Review',
  collapseDescription: 'Setup your ad budget and review your complete setup.',
  component: SelectReviewAndPublishComponent,
  stepName: CreateAnAdSteps.ReviewPublish
};

const DescribeStep: CreateAdStep = {
  collapseTitle: 'Describe your project',
  collapseDescription: 'Describe your project for our AI.',
  component: DescribeProjectComponent,
  headerComponent: DescribeProjectPlatformsHeaderComponent,
  //tourName: 'CREATE_AD_STEP_2_FOR_INSTANT_AD',
  stepName: CreateAnAdSteps.DescribeProject
};

const AIGeneratedAdStep: CreateAdStep = {
  collapseTitle: 'Select AI generated ads',
  collapseDescription: 'Select AI-generated ads by our partner AdCreative.ai.',
  component: SelectAiGeneratedComponent,
  headerComponent: SelectAiGeneratedPlatformsHeaderComponent,
  //tourName: 'CREATE_AD_STEP_3_FOR_INSTANT_AD',
  stepName: CreateAnAdSteps.AIGenerated,
  isStepShow: (isCurrent) => !isCurrent
};

export const CreateAdSteps = {
  PLATFORM: PlatformStep,
  OBJECTIVE: ObjectiveStep,
  AUDIENCE: AudienceStep,
  AD_TYPE: AdTypeStep,
  REVIEW_AND_PUBLISH: ReviewAndPublishStep,
  DESCRIBE: DescribeStep,
  AI_GENERATED_AD: AIGeneratedAdStep
};
export const TypedAdSteps = [
  CreateAdSteps.PLATFORM,
  CreateAdSteps.OBJECTIVE,
  CreateAdSteps.AUDIENCE,
  CreateAdSteps.AD_TYPE,
  CreateAdSteps.REVIEW_AND_PUBLISH
];

export const BingImportFromGoogleSteps = [
  CreateAdSteps.PLATFORM,
  CreateAdSteps.OBJECTIVE,
  CreateAdSteps.REVIEW_AND_PUBLISH
];

export const InstantAdSteps = [
  CreateAdSteps.PLATFORM,
  CreateAdSteps.DESCRIBE,
  CreateAdSteps.AI_GENERATED_AD,
  CreateAdSteps.REVIEW_AND_PUBLISH
];
