import { Injectable } from '@angular/core';
import { ACTIVE_PLATFORMS, AdPlatforms, IBusiness, Platforms } from '@core/models';
import { BusinessState } from '@core/state/business.state';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export type SelectedAdType = 'manual' | 'smart-campaign' | 'instant-ad' | null;

export module CreateAdStateModule {
  export interface State {
    selectedAdType?: SelectedAdType;

    platform?: Platforms;

    smartCampaignPlatforms?: AdPlatforms[];

    instantAdPlatform?: Platforms.Meta | Platforms.Google;
  }
}

export class SetSelectedAdType {
  static readonly type = '@@CREATE_AD_SET_SELECTED_AD_TYPE';
  static readonly desc = '[Create Ad.Set Selected Ad Type]';

  constructor(public payload?: SelectedAdType) {}
}

export class SetPlatform {
  static readonly type = '@@CREATE_AD_SET_PLATFORM';
  static readonly desc = '[Create Ad.Select Platform]';

  constructor(public payload?: Platforms) {}
}

export class SetSmartCampaignPlatforms {
  static readonly type = '@@CREATE_AD_SET_SMART_CAMPAIGN_PLATFORM';
  static readonly desc = '[Create Ad.Select Platform Campaign Platform]';

  constructor(public payload?: AdPlatforms[]) {}
}

export class SetInstantAdPlatform {
  static readonly type = '@@CREATE_AD_SET_INSTANT_AD_PLATFORM';
  static readonly desc = '[Create Ad.Select Platform Instant Ad Platform]';

  constructor(public payload?: Platforms.Meta | Platforms.Google) {}
}

const CREATE_AD_DEFAULTS: CreateAdStateModule.State = {};

@State<CreateAdStateModule.State>({
  name: 'CreateAdState',
  defaults: CREATE_AD_DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class CreateAdState {
  constructor() {}

  @Selector([BusinessState.SelectedBusiness])
  static selectedPlatformDetail({ platform: platform }: CreateAdStateModule.State, selectedBusiness: IBusiness) {
    const selected = selectedBusiness?.connectedAdAccounts.find((account) => account.source == platform);
    return selected;
  }

  @Selector()
  static selectedPlatform({ platform }: CreateAdStateModule.State) {
    return platform;
  }

  @Selector()
  static selectedSmartCampaignPlatforms({ smartCampaignPlatforms }: CreateAdStateModule.State) {
    return smartCampaignPlatforms;
  }

  @Selector()
  static selectedInstantPlatform({ instantAdPlatform }: CreateAdStateModule.State) {
    return instantAdPlatform;
  }

  @Selector([BusinessState.SelectedBusiness])
  static selectedSmartCampaignPlatformsDetails(
    { smartCampaignPlatforms }: CreateAdStateModule.State,
    selectedBusiness: IBusiness
  ) {
    return smartCampaignPlatforms?.map((platform) =>
      selectedBusiness.connectedAdAccounts.find((account) => account.source == platform)
    );
  }

  @Selector()
  static activeTypeValue({ platform, smartCampaignPlatforms }: CreateAdStateModule.State) {
    return CreateAdState.selectedAdType({ platform }) == 'smart-campaign' ? smartCampaignPlatforms : platform;
  }

  @Selector()
  static selectedAdType({ selectedAdType }: CreateAdStateModule.State) {
    return selectedAdType;
  }

  @Action(SetSelectedAdType)
  setSelectedAdtype({ patchState }: StateContext<CreateAdStateModule.State>, { payload }: SetSelectedAdType) {
    patchState({ selectedAdType: payload });
  }

  @Action(SetPlatform)
  setPlatform({ patchState }: StateContext<CreateAdStateModule.State>, { payload }: SetPlatform) {
    if (ACTIVE_PLATFORMS.some((platform) => payload === platform.value)) {
      patchState({ platform: payload });
    } else {
      patchState({ platform: undefined });
    }
  }

  @Action(SetSmartCampaignPlatforms)
  setSmartCampaignPlatforms(
    { patchState }: StateContext<CreateAdStateModule.State>,
    { payload }: SetSmartCampaignPlatforms
  ) {
    patchState({ smartCampaignPlatforms: payload });
  }

  @Action(SetInstantAdPlatform)
  setInstantAdPlatform({ patchState }: StateContext<CreateAdStateModule.State>, { payload }: SetInstantAdPlatform) {
    patchState({ instantAdPlatform: payload });
  }
}
