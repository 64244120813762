import { environment } from '@environment';
import { uniqueId } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { Component, Input, OnInit } from '@angular/core';
import { createObjectURL, createUploadImages, GoogleService, IdWithUrl, UploadImageItem } from '@core/index';
import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountImageItem, LibraryGoogleComponent } from '../image-library';
import { GoogleImageService } from '@pages/create-ad/services/google-image.service';
import { DisplayAdsImages } from '@pages/create-ad/components/select-adtype/platforms/google/components/modal/upload-ads-images-display';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

export type PerformanceMaxImageType =
  | 'squareMarketing'
  | 'portraitMarketing'
  | 'landscapeMarketing'
  | 'logo'
  | 'landscapeLogo';

export const PERFORMANCE_MAX_IMAGE_RATIOS: Record<PerformanceMaxImageType, number> = {
  squareMarketing: 1,
  portraitMarketing: 4 / 5,
  landscapeMarketing: 1.91,
  logo: 1,
  landscapeLogo: 4
};

export type PerformanceMaxImages = {
  [key in PerformanceMaxImageType]?: IdWithUrl[];
};
@Component({
  selector: 'aayn-upload-ads-images--google',
  templateUrl: 'upload-ads-images.component.html'
})
export class UploadAdsImagesGoogleComponent implements OnInit {
  @Input() protected uploadedImages: PerformanceMaxImages = {
    squareMarketing: [],
    portraitMarketing: [],
    landscapeMarketing: [],
    logo: [],
    landscapeLogo: []
  } as PerformanceMaxImages;

  @Input() protected images = {
    squareMarketing: createUploadImages(5),
    portraitMarketing: createUploadImages(5),
    landscapeMarketing: createUploadImages(5),
    logo: createUploadImages(1),
    landscapeLogo: createUploadImages(1)
  };

  originalImages: PerformanceMaxImages = {
    squareMarketing: [],
    portraitMarketing: [],
    landscapeMarketing: [],
    logo: [],
    landscapeLogo: []
  } as DisplayAdsImages;

  protected isValid: boolean = true;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private googleService: GoogleService,
    private toastrService: ToastrService,
    private googleImageService: GoogleImageService
  ) {}

  ngOnInit() {
    Object.keys(this.uploadedImages).forEach((key) => {
      const uploadedImageGroup = this.uploadedImages[key];

      if (!uploadedImageGroup.length) return;
      this.images[key].slice(0, uploadedImageGroup.length).forEach((o, i) => {
        o.startedUploading = true;
        o.progressValue = 100;
      });
    });
  }

  openLibrary(imageType: PerformanceMaxImageType, maxImageSelectLength: number) {
    const modalRef = this.modalService.open(LibraryGoogleComponent);

    modalRef.componentInstance.maxImageSelectLength = maxImageSelectLength;
    modalRef.componentInstance.ratio = PERFORMANCE_MAX_IMAGE_RATIOS[imageType];

    modalRef.closed.subscribe((result) => {
      const images = result?.images as AccountImageItem[];

      if (images?.length) {
        this.images[imageType] = createUploadImages(maxImageSelectLength);
        this.uploadedImages[imageType] = [];
        images
          .filter((image) => image.id)
          .forEach((image) => {
            const imageIndex = this.images[imageType].findIndex((o) => !o.completed && !o.startedUploading);
            const url = this.googleImageService.getImageUrl(image.url);
            this.uploadedImages[imageType]![imageIndex] = { id: +image.id, url };
            this.originalImages[imageType]![imageIndex] = {
              id: +image.id,
              url
            };
            this.images[imageType]![imageIndex].complete();
          });
        // TODO:QUESTION: Image Library'den gelecek olan resimler daha önce yüklenmiş olan resimlere override etmeli mi? Şimdilik edecek!
        images
          .filter((image) => !image.id)
          .forEach(async (o) => {
            const downloadedImage = await fetch(o.url)
              .then((response) => response.blob())
              .then(
                (blob) =>
                  new File([blob], `${uniqueId()}$.png`, {
                    type: blob.type
                  })
              );

            this.uploadImage(imageType, { file: downloadedImage, fileUrl: o.url });
          });
      }
    });
  }

  uploadImage(type: PerformanceMaxImageType, { file, fileUrl }: { file: File; fileUrl?: string }) {
    const image = this.images[type].find((o) => !o.completed && !o.startedUploading);

    this.googleService
      .uploadPerformanceMaxImages({
        [type]: [file]
      })
      .pipe(
        switchMap((uploadResult) => {
          if (uploadResult.state != 'DONE') return of({ uploadResult, imageUrl: null });

          return this.googleImageService.getImage(uploadResult.result[type][0].url).pipe(
            map((image) => ({
              uploadResult,
              imageUrl: createObjectURL(image)
            }))
          );
        })
      )
      .subscribe({
        next: ({ uploadResult, imageUrl }) => {
          image!.startedUploading = true;
          image!.progressValue = uploadResult.progress;

          if (!environment.production) console.log('[CreateAd.Google.UploadResult]: ', uploadResult);

          if (uploadResult.state != 'DONE') return;

          image!.progressValue = 100;

          uploadResult.result[type]?.forEach((o) => {
            const index = this.images[type].findIndex((o) => o === image);
            let url = imageUrl || this.googleImageService.getImageUrl(o.url) || fileUrl!;
            this.uploadedImages[type]![index] = { id: +o.id, url: url };
            this.originalImages[type]![index] = {
              id: +o.id,
              url: this.googleImageService.getImageUrl(o.url) || fileUrl!
            };
          });

          this.toastrService.success('Image upload successful.', 'Successful');
        },
        error: ({ error }: { error: IRestError }) => {
          if (!(error.message instanceof Array)) {
            this.toastrService.warning(error.message);

            image!.startedUploading = false;
            image!.progressValue = 0;
          }
        }
      });
  }

  remove({ fileUrl }: { fileUrl: string }, type: PerformanceMaxImageType) {
    const index = (this.uploadedImages[type] || []).findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages[type] = this.uploadedImages[type]!.filter((_, i) => i !== index);
      this.images[type].forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images[type].length - 1 && this.images[type][i + 1].startedUploading) {
            this.images[type][i] = this.images[type][i + 1];
            this.images[type][i + 1] = new UploadImageItem();
          } else {
            this.images[type][i] = new UploadImageItem();
          }
        }
      });
    }
  }

  saveModal() {
    if (
      this.isUploadValid('squareMarketing') &&
      this.isUploadValid('portraitMarketing') &&
      this.isUploadValid('landscapeMarketing') &&
      this.isUploadValid('logo') &&
      this.isUploadValid('landscapeLogo')
    ) {
      const images = Object.fromEntries(
        Object.entries<IdWithUrl[]>(this.uploadedImages).map(([key, value]) => [
          key,
          value
            .filter((o) => o.id)
            .map((o, index) => ({
              id: o.id,
              url: this.originalImages[key as PerformanceMaxImageType]?.[index]?.url || o.url
            }))
        ])
      );

      this.modal.close({
        images
      });
    } else this.isValid = false;
  }

  isUploadValid(type: PerformanceMaxImageType): boolean {
    return this.images[type]?.at(0)?.completed || this.uploadedImages[type]!.length > 0;
  }

  get imageInfo() {
    return {
      squareMarketing: {
        valid: this.isUploadValid('squareMarketing')
      },
      portraitMarketing: {
        valid: this.isUploadValid('portraitMarketing')
      },
      landscapeMarketing: {
        valid: this.isUploadValid('landscapeMarketing')
      },
      logo: {
        valid: this.isUploadValid('logo')
      },
      landscapeLogo: {
        valid: this.isUploadValid('landscapeLogo')
      }
    };
  }

  closeModal() {
    this.modal.close({});
  }

  selectedFileChange(index: number, type: PerformanceMaxImageType) {
    this.uploadedImages[type]![index] = {
      id: 0,
      url: ''
    };
  }
}
