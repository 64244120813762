import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { SmartCampaignCreateAd } from '@core/ad-platforms/smart-campaign';
import { AdPlatforms, Draft, SmartCampaign } from '@core/models';
import { CreateAnAdSteps } from '@pages/create-ad/models';

import { SaveDraft } from '../base';
import { createRequiredValidator } from '@core/utils';

@Injectable({ providedIn: 'root' })
export class SmartCampaignStateService extends SaveDraft<SmartCampaignCreateAd.ICreateAdModel> {
  createAd = new SmartCampaignCreateAd.CreateAd();

  adCreationModel$ = new BehaviorSubject(this.createAd.createAdModel);

  get adCreationModel() {
    return this.adCreationModel$.value;
  }

  constructor() {
    super();
  }

  setAdModel(value: SmartCampaignCreateAd.ICreateAdModel) {
    this.adCreationModel$.next(value);
    this.createAd.createAdModel = value;
  }

  reset() {
    this.createAd = new SmartCampaignCreateAd.CreateAd();
    this.setAdModel(this.createAd.createAdModel);
    this.draftModel = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;
  }

  updateActiveStep(step: CreateAnAdSteps) {
    this.adCreationModel.activeStep = step;
  }

  mapToApiRequest() {
    const selectedAdType = this.adCreationModel.selectedTypes.ad;

    if (!selectedAdType) return;

    return this.createAd.mapToApiRequest(selectedAdType);
  }

  changeAdType(adType: SmartCampaign.Client.AdType) {
    const changedModel = this.createAd.changeType!('ad', adType);

    this.adCreationModel.selectedTypes.ad = adType;

    this.setAdModel(changedModel);
  }

  hasPlatform(...platforms: AdPlatforms[]) {
    return platforms.some((platform) => this.adCreationModel.selectedPlatforms.includes(platform));
  }

  getValidator(...platforms: AdPlatforms[]) {
    return createRequiredValidator((control) => {
      const hasPlatform = this.hasPlatform(...platforms);
      return !hasPlatform || (hasPlatform && !!control.value);
    });
  }
}
