import { MainStep, New } from '../';

export type ApplyValidationsParams = {
  [K in MainStep]?: {
    [key: string | number]: (model: any) => boolean;
  };
};

/**
 * This decorator helps us to prepare type-based validation functions for validation
 * checks on the model within the `${Platform}CreateAd` class.
 *
 * @example
 * @ApplyValidations({
 *  ad: {
 *    [AdType.DynamicCreative]: function(model: ICreateAdModel) {
 *      return model.name.length > 3 ...
 *   }
 *  }
 * })
 * @param validations {ApplyValidationsParams}
 * @returns {Function}
 */
export function ApplyValidations(validations: ApplyValidationsParams) {
  return function <C extends New>(constructor: C) {
    constructor.prototype.validate = function <T>(step: MainStep, childKey: string | number, model: T) {
      const mainStep = validations[step];

      if (typeof mainStep != 'object') {
        throw new Error(`[@ApplyValidations]: mainStep is must be object.`);
      }

      const validateFn = mainStep[childKey];

      if (typeof validateFn == 'undefined') {
        throw new Error(`[@ApplyValidations]: validateFn is undefined.`);
      }

      return validateFn.call(this, model);
    };

    return class extends constructor {};
  };
}
