<header class="aayn-cancel-subscription-modal--header">
  <ayn-modal-close [modal]="modal" icon="cross-mini"></ayn-modal-close>

  <ayn-icon name="avatar-cancel"></ayn-icon>

  <h3 class="aayn-cancel-subscription-modal--header--title">You are about to delete your account!</h3>

  <p class="aayn-cancel-subscription-modal--header--description">
    This process will delete all of your businesses, uploaded assets, and it may affect your ads.
  </p>
</header>

<main class="aayn-cancel-subscription-modal--body">
  <div class="aayn-cancel-subscription-modal--body--card">
    <h4 class="aayn-cancel-subscription-modal--body--card-title">Was it the price?</h4>
    <p class="aayn-cancel-subscription-modal--body--card-description">
      Let us offer you a <b>50% discount</b> forever, starting from now. This is the highest discount we have ever
      offered.
    </p>
  </div>

  <form class="aayn-cancel-subscription-modal--body--form" [formGroup]="form">
    <ayn-form-field>
      <textarea
        ayn-input
        formControlName="reason"
        rows="3"
        placeholder="Please share your cancellation reason to help us improve."
      ></textarea>
    </ayn-form-field>

    <footer class="aayn-cancel-subscription-modal--body--form-footer">
      <button
        ayn-button="orange"
        icon="circle-filled-cross"
        type="submit"
        (click)="cancelSubscription()"
        [loading]="cancelSubscriptionLoader$ | async"
        [disabled]="cancelSubscriptionLoader$ | async"
      >
        Delete Account
      </button>

      <button ayn-button="primary" type="button" icon="circle-filled-check" (click)="applyDiscount()">
        Accept %50 Discount
      </button>
    </footer>
  </form>
</main>
