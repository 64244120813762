import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { NgIf } from '@angular/common';

@Component({
  selector: 'aayn-ad-preview-display-ads',
  templateUrl: './display-ads.component.html',
  imports: [TranslateModule, PipesModule, IconModule, NgIf],
  standalone: true
})
export class DisplayAdsComponent {
  @Input() image = '';

  @Input() businessLogo: any;
  @Input() linkUrl: any;
  @Input() title = '';
  @Input() description = '';

  titlePlaceholder = 'Your headline';
  descriptionPlaceholder = 'Your description';
  linkUrlPlaceholder = 'https://your-url.com';

  imagePlaceholder = '/assets/images/pngs/placeholder.png';
  logoPlaceholder = '/assets/images/svgs/create-ads/youtube-logo-placeholder.svg';

  onImgError($event: ErrorEvent) {}
}
