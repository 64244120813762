import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from '@ayn-ui/public-api';
import { Bing } from '@core/models';
import { BingService } from '@core/services';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { BingFormService, BingStateService, BingValidationService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-conversion-my-website-bing',
  templateUrl: 'conversion-my-website.component.html'
})
export class ConversionMyWebsiteBingComponent implements OnInit {
  protected adCreationModel = this.bingStateService.adCreationModel;

  form = new FormGroup({
    campaignName: new FormControl(this.adCreationModel?.campaign.name, [
      (Validators.required, Validators.minLength(3), Validators.maxLength(100))
    ]),
    conversionEvent: new FormControl(this.adCreationModel?.campaign?.goals)
  });

  conversionEvents: IOption[] = [];

  @Select(LoaderState.getAny(['ConversionGoal'])) loader$?: Observable<boolean>;

  constructor(
    private bingValidationService: BingValidationService,
    private bingStateService: BingStateService,
    private bingService: BingService,
    private cdr: ChangeDetectorRef,
    private bingFormService: BingFormService
  ) {}

  ngOnInit() {
    this.bingFormService.registerForm(this.form);

    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.form.statusChanges.subscribe((status) => {
      this.bingValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.form.valueChanges.subscribe((x) => {
      this.adCreationModel!.campaign.name = x.campaignName!;
      this.adCreationModel!.campaign.goals = x.conversionEvent!;
      this.adCreationModel!.selectedTypes.campaign = Bing.CampaignObjective.ConversionsOnMyWebsite;
    });

    this.searchValueChange();
  }

  searchValueChange() {
    this.bingService.getConversionGoals$().subscribe((result) => {
      this.conversionEvents =
        result?.data.getConversionGoals.map(
          (o) =>
            ({
              label: o.name,
              value: o
            } as IOption)
        ) || [];
      this.cdr.detectChanges();
    });
  }

  protected valueChange(value: Bing.AudienceCampaign.IBingConversionGoalOutput[]) {
    if (value) {
      this.form!.patchValue({ conversionEvent: value! });
    }
  }
}
