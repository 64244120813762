<aayn-upload-image-modal
  header="Crop your Image"
  description="Crop your image for the best results."
  [modal]="modal"
  icon="crop"
  modalClass="aayn-image-crop"
  [saveButtonHidden]="true"
>
  <section class="aayn-image-crop--body">
    <div class="aayn-image-crop--container">
      <image-cropper
        [imageURL]="imageUrl"
        [maintainAspectRatio]="!!(aspectRatio || selectedRatio?.ratio) && maintainAspectRatio"
        [cropper]="cropper"
        [aspectRatio]="ratio"
        [cropperMinWidth]="minImage.width"
        [resizeToWidth]="maxImage.width"
        [onlyScaleDown]="true"
        [format]="format"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
        [autoCrop]="false"
        [(transform)]="transform"
      ></image-cropper>
    </div>
    <div class="aayn-image-crop--multiple-ratios" *ngIf="ratios?.length">
      <ng-container *ngFor="let ratio of ratios">
        <button
          ayn-button
          type="button"
          (click)="selectRatio(ratio)"
          [class.btn--accent]="ratio.selected"
          [class.btn--white]="!ratio.selected"
        >
          <span> {{ ratio.label }}</span>
          <div
            *ngIf="ratio.ratio"
            class="aspect-ratio-{{ ratio.label }}"
            [class.border-color:white]="ratio.selected"
          ></div>
        </button>
      </ng-container>
    </div>
  </section>
  <section class="aayn-image-crop--footer" *ngIf="!ratios?.length">
    <strong *ngIf="!isScalable">{{ aspectRatioLabel }}</strong>
    <div class="w-100" *ngIf="isScalable">
      <ngx-slider
        class="aayn-image-crop--ratio-slider"
        [value]="transform!.scale!"
        (valueChange)="sliderValueChange($event)"
        [options]="sliderOptions"
      ></ngx-slider>
    </div>
    <button ayn-button="primary" icon="chevron-right" (click)="cropAndSave()">
      {{ 'Crop And Save' | translate }}
    </button>
  </section>
  <section class="aayn-image-crop--end" *ngIf="ratios.length" after-container>
    <button ayn-button="primary" icon="chevron-right" (click)="cropAndSave()">
      {{ 'Crop And Save' | translate }}
    </button>
  </section>
</aayn-upload-image-modal>
