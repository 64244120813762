import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient, HttpContext } from '@angular/common/http';
import { delayWhen, retryWhen, take } from 'rxjs/operators';
import { timer } from 'rxjs';
import { PURE_REQUEST } from '@core/interceptors';

@Injectable({
  providedIn: 'root'
})
export class GoogleImageService {
  constructor(private http: HttpClient) {}
  getImageUrl(googleUrl: string) {
    const host = environment.production ? environment.apiUrl : '';
    return googleUrl.replace('https://tpc.googlesyndication.com', host + '/google-image');
  }

  getImage(url: string, retry = 3, delayTime = 800) {
    return this.http
      .get(this.getImageUrl(url), { responseType: 'blob', context: new HttpContext().set(PURE_REQUEST, true) })
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(
            delayWhen(() => timer(delayTime)),
            take(retry)
          );
        })
      );
  }
}
