import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BadgeList, IOption } from '@ayn-ui/lib/modules';
import { Google, GoogleService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { GoogleStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-language-selection--google',
  templateUrl: 'language-selection.component.html'
})
export class LanguageSelectionGoogleComponent implements OnInit {
  @Input() form?: FormGroup;

  protected languages: IOption[] = [];

  @Select(LoaderState.getAny(['LanguageSearch'])) loader$?: Observable<boolean>;

  adCreationModel = this.googleStateService.adCreationModel;

  @ViewChild(BadgeList) badgeList?: BadgeList;

  constructor(
    private googleService: GoogleService,
    private cdr: ChangeDetectorRef,
    private googleStateService: GoogleStateService
  ) {}

  ngOnInit() {
    this.googleStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  searchValueChange(term: string) {
    this.googleService
      .languageSearch({ searchKey: term, number: 10 } as Google.CreateAd.IGoogleLanguageConstantRequest)
      .subscribe((result) => {
        this.languages = result?.map((o) => ({ label: o.name, value: o.id } as IOption)) || [];
        this.cdr.detectChanges();
      });
  }

  protected valueChange(option: IOption) {
    const existLocale = this.adCreationModel!.audience.create.languageIds.some((o) => o.key == option.value);

    if (existLocale) return;

    this.adCreationModel!.audience.create.languageIds.push({
      name: option.label,
      key: option.value as string
    });

    this.form?.patchValue({
      locales: this.adCreationModel.audience.create.languageIds
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }

  optionDisabled(option: IOption) {
    return this.adCreationModel?.audience?.create?.languageIds?.some((o) => o.key == option.value);
  }

  removeLocale(index: number) {
    this.adCreationModel.audience.create.languageIds.splice(index, 1);

    this.form?.patchValue({
      locales: this.adCreationModel.audience.create.languageIds
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }
}
