<div class="aayn-create-ad--item" *ngIf="selectedPlatform$ | async as selectedPlatform">
  <section class="aayn-create-ad--item--container">
    <div [ngSwitch]="selectedPlatform">
      <div *ngSwitchCase="Platforms.InstantAd" [@fadeInOnEnter]>
        <aayn-select-ai-generated--instant-ad></aayn-select-ai-generated--instant-ad>
      </div>
    </div>

    <aayn-ad-item-footer>
      <button
        ayn-button="primary"
        class="aayn-create-ad--item--btn"
        ayn-button-block
        (click)="nextStep(selectedPlatform)"
        type="submit"
        [width]="260"
        [innerHTML]="'Next Step; Review And Publish' | translate"
      ></button>
    </aayn-ad-item-footer>
  </section>
</div>
