import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Output = { getGoogleCredentialIdDirectUrl: string };

export const getGoogleCredentialIdDirectUrl = makeQuery<Output>(`
query GetGoogleCredentialIdDirectUrl {
  bing {
    getGoogleCredentialIdDirectUrl
  }
}
`);
