import { makeMutation, QueryResult } from '@core/queries/types';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'generateTexts';
type Input = OpenAI.IGenerateTextsRequest;
type Output = QueryResult<typeof queryField, OpenAI.GeneratedTextsOutputDto>;

export const generateTextsQuery = makeMutation<Output, Input>(`
query GenerateTexts(
  $outputLanguage: String!
  $description: String!,
  $targetAudience: String!,
  $headlines: [GenerateTextSectionInputDto!]
  $descriptions: [GenerateTextSectionInputDto!]
  $punchlines: [GenerateTextSectionInputDto!]
  $ctas: [GenerateTextSectionInputDto!]
) {
   openAI {
    ${queryField}(
      outputLanguage: $outputLanguage
      description: $description
      targetAudience: $targetAudience
      headlines: $headlines
      ctas: $ctas
      punchlines: $punchlines
      descriptions: $descriptions
    ) {
      ctas
      descriptions
      punchlines
      headlines
    }
  }
}

`);
