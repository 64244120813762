import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from './base-component';

export abstract class BaseAsyncComponent extends BaseComponent {
  private _loading = new BehaviorSubject(false);

  loading$ = this._loading.asObservable();

  constructor() {
    super();
  }

  protected setLoading(value: boolean) {
    this._loading.next(value);
  }

  get loading() {
    return this._loading.getValue();
  }
}
