import { Base } from '@core/models/platforms/base';

/**
 * Updates request and prevPage object based on event
 *
 * @param {page: string; size: number; isNext: boolean} event The page event
 * @param {Record<string, string>} prevPage The object which previous page cursors stored
 * @param {Base.Dashboard.Global.IStartEndDateRequest} request The request object
 * @param {number} pageViewCount The current pageViewCount
 */
export function processPagination(
  event: { page: string; size: number; isNext: boolean },
  prevPage: Record<string, string>,
  request: Base.Dashboard.Global.IStartEndDateRequest,
  pageViewCount: number
) {
  if (event.page && pageViewCount === event.size) {
    if (event.isNext) {
      if (request.after) {
        prevPage[event.page] = request.after;
      }
      request.after = event.page;
    } else {
      request.after = request.after ? prevPage[request.after] : undefined;
    }
  } else {
    request.after = undefined;
  }

  request.first = event.size;
}
