import { makeMutation } from '../types';

type Input = { reason: string };
type Output = number;

export const cancelSubscription = makeMutation<Output, Input>(`
  mutation CancelSubscription($reason: String!) {
    cancelSubscription(reason: $reason)
  }
`);
