import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.ITurnResourceRequest;
type Output = { turnOff: boolean };

export const turnOffCampaignsBing = makeMutation<Output, Input>(`
  mutation BingTurnOff(
    $resourceIds: [String!]!
    $resourceType: BingIdResourceType!
  ) {
    bing {
      turnOff(
        resourceIds: $resourceIds
        resourceType: $resourceType
      )
    }
  }
`);
