import { Bing } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = Bing.AudienceCampaign.AudienceCampaignRequest;
type Output = boolean;

export const createAudienceCampaign = makeMutation<Output, Input>(`
mutation CreateAudienceCampaign($audienceCampaign: BingAudienceCampaignDto!) {
  bing {
    createAudienceCampaign(audienceCampaign: $audienceCampaign)
  }
}
`);
