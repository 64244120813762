<aayn-upload-image-modal
  header="Select Images"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
  icon="folder"
>
  <aayn-upload-image-modal--header
    headerLeft="TikTok Images"
    headerLeftDescription="Add up to 30 images"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary()"
  ></aayn-upload-image-modal--header>

  <section class="upload-ads-images--body">
    <ng-container *ngFor="let file of images; let index = index">
      <aayn-file-upload-item
        className="aayn-tiktok-file-upload-item"
        [maxImageSize]="{ width: maxWidth }"
        [isPrimary]="index === 0"
        [isError]="index === 0 && !isValid"
        (fileAdded)="onFileAdded($event)"
        (remove)="remove($event)"
        [selectedFileUrl]="uploadedImages![index]?.url"
        (selectedFileUrlChange)="selectedFileChange(index)"
        [progressItem]="images[index]"
        [aspectRatios]="aspectRatios"
        (ratioSelect)="ratioSelect($event)"
        [minImageSize]="minImageSize"
        [allowVideo]="true"
        canvaDesignType="InstagramPost"
      ></aayn-file-upload-item>
    </ng-container>
  </section>
</aayn-upload-image-modal>
