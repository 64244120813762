<ayn-form-field>
  <ayn-select
    [class]="'aayn-language-selection'"
    (searchControlChange)="searchValueChange($event)"
    (valueChange)="valueChange($event)"
    [placeholder]="selectedLanguages.length ? selectedLanguages.length + ' Language Selected' : 'Choose language'"
    [dontSelect]="true"
    [hideOnSelectOverlay]="false"
  >
    <ayn-option *ngFor="let option of languages" [value]="option" [disabled]="optionDisabled(option)">
      {{ option.label }}

      <button type="button" ayn-button icon="plus">Select Language</button>
    </ayn-option>
  </ayn-select>
</ayn-form-field>

<ayn-badge-list [list]="badges" [hideRemove]="true"></ayn-badge-list>

<footer class="aayn-language-selection--footer" *ngIf="!selectedLanguages.length">
  Selected Language

  <b>
    All Languages
    <ayn-icon name="globe"></ayn-icon>
  </b>
</footer>
