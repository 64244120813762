import { Bing } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Bing.Dashboard.IAdGroupsWithMetricsRequest;
type Output = Bing.Dashboard.IAdGroupsWithMetricsResponse;

export const adGroupsWithMetricsBing = makeQuery<Output, Input>(`
query AdGroupsWithMetricsBing(
  $skip: Int
  $take: Int
  $campaignId: String!
  $searchKey: String
  $status: BingInsightStatus
  $startDate: SafeInt
  $endDate: SafeInt
  $datePreset: DatePreset
) {
  bing {
    adGroupsWithMetrics(
      skip: $skip
      take: $take
      campaignId: $campaignId
      searchKey: $searchKey
      status: $status
      startDate: $startDate
      endDate: $endDate
      datePreset: $datePreset
    ) {
      edges {
        cursor
        node {
          name
          id
          resourceName
          status
          startDate
          endDate
          clicks
          impressions
          costPerClick
          conversions
          costPerConversion
          revenue
          spend
          ctr
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}
`);
