<div class="aayn-text-generator-ai">
  <header class="aayn-text-generator-ai--header">
    <div class="aayn-text-generator-ai--header__left">
      <ayn-icon name="ai-generate"> </ayn-icon>
      <div class="aayn-text-generator-ai--header__content">
        <h5>{{ 'Generate ad texts with AI' | translate }}</h5>
        <p>{{ 'Let our AI to instantaneously generate your texts from your landing page link.' | translate }}</p>
      </div>
    </div>

    <button
      *ngIf="!expanded"
      ayn-button="primary"
      class="aayn-text-generator-ai--header__button"
      (click)="toggleButton()"
      type="button"
    >
      {{ 'Fill it with AI' | translate }}
    </button>

    <a *ngIf="expanded" class="aayn-text-generator-ai--header__close" (click)="toggleButton()">
      {{ 'Close' | translate }}
    </a>
  </header>

  <main *ngIf="expanded">
    <ng-container *ngIf="{ loader: loader$ | async, fillLoader: fillLoader$ | async } as data">
      <div class="aayn-text-generator-ai--form" style="--skeleton-border-radius: 20px">
        <form standAloneForm [formGroup]="urlForm" (ngSubmit)="scan()">
          <div class="aayn-text-generator-ai--row">
            <ayn-form-field>
              <div class="ayn-prefix">
                {{ 'URL' | translate }}
              </div>

              <input
                type="text"
                formControlName="url"
                placeholder="Scan your landing page for our AI to fill in the details"
                ayn-input
                [readOnly]="data.loader"
                aaynControlTriggerSubmit
                id="url"
                [disabled]="data.loader || false"
                [ayn-skeleton]="data.loader"
              />

              <ayn-icon
                name="cross-mini"
                class="aayn-text-generator-ai--cancel-btn"
                *ngIf="data.loader"
                (click)="cancelGetProductDescriptionCall()"
              ></ayn-icon>
            </ayn-form-field>

            <button
              type="submit"
              ayn-button="white"
              class="aayn-text-generator-ai--form__scan"
              [width]="180"
              ayn-button-block
              [disabled]="data.loader || data.fillLoader"
              [loading]="data.loader"
              [ayn-skeleton]="data.loader"
              style="--skeleton-border-radius: 28px"
            >
              <span *ngIf="data.loader">{{ 'Scanning...' | translate }}</span>
              <span *ngIf="!data.loader"> {{ 'Scan' | translate }}</span>
            </button>
          </div>
        </form>

        <form
          standAloneForm
          [formGroup]="textGeneratorForm"
          (ngSubmit)="generatorSubmit()"
          [aynValidationErrorDisplayStrategy]="'wait-submit'"
        >
          <div class="aayn-text-generator-ai--row">
            <ayn-form-field
              class="aayn-text-generator-ai--form__text-area"
              [ayn-skeleton]="data.loader || data.fillLoader || false"
            >
              <textarea
                type="text"
                ayn-input
                class="text-area"
                formControlName="description"
                [placeholder]="
                  'Product/Service Description (Example: AdCreative.ai is Artificial Intelligence powered ad creative and social media post generator. With AdCreative.ai you can generate creatives in a matter of seconds.)'
                    | translate
                "
                [disabled]="data.loader || data.fillLoader || false"
                id="productDescription"
                rows="3"
              ></textarea>
            </ayn-form-field>
          </div>

          <div class="aayn-text-generator-ai--row">
            <ayn-form-field [ayn-skeleton]="data.loader || data.fillLoader">
              <input
                type="text"
                ayn-input
                formControlName="productName"
                [disabled]="data.loader || data.fillLoader || false"
                [placeholder]="'Product Name (Example: ADYOUNEED)' | translate"
                id="productName"
              />
            </ayn-form-field>

            <ayn-form-field [ayn-skeleton]="data.loader || data.fillLoader">
              <input
                type="text"
                ayn-input
                formControlName="targetAudience"
                [disabled]="data.loader || data.fillLoader || false"
                [placeholder]="'Your Target Audience (Example: Small business owners)' | translate"
                id="targetAudience"
              />
            </ayn-form-field>
          </div>

          <div class="aayn-text-generator-ai--row">
            <ayn-form-field [ayn-skeleton]="data.loader || data.fillLoader">
              <ayn-select
                formControlName="tone"
                [disabled]="data.loader || data.fillLoader || false"
                [minWidth]="120"
                [showTick]="true"
                [placeholder]="'Choose a tone' | translate"
                [setValueHtml]="true"
              >
                <ayn-option *ngFor="let chooseTone of chooseTones | aynOptionFilter | async" [value]="chooseTone.value">
                  <ayn-icon [name]="chooseTone.value.toLowerCase()"></ayn-icon>
                  {{ chooseTone.label | translate }}
                </ayn-option>

                <ayn-icon
                  *ngIf="textGeneratorForm.controls['chooseTone']?.value"
                  [name]="textGeneratorForm.controls['chooseTone'].value || ''"
                  class="ayn-icon-left"
                ></ayn-icon>
              </ayn-select>
            </ayn-form-field>

            <ayn-form-field [ayn-skeleton]="data.loader || data.fillLoader">
              <ayn-select
                formControlName="language"
                [disabled]="data.loader || data.fillLoader || false"
                [minWidth]="120"
                [showTick]="true"
                [placeholder]="'Output Language' | translate"
                [setValueHtml]="true"
              >
                <ayn-option *ngFor="let item of outputLanguages | aynOptionFilter | async" [value]="item.value">
                  <img [src]="'/assets/images/svgs/languages/' + item.flag + '.svg'" />
                  {{ item.label | translate }}
                </ayn-option>
              </ayn-select>
            </ayn-form-field>
          </div>

          <div class="aayn-text-generator-ai--form__button">
            <button
              ayn-button="primary"
              ayn-button-block
              type="submit"
              [disabled]="data.loader"
              [loading]="data.fillLoader"
            >
              {{ 'Save and Fill Below' | translate }}
            </button>
          </div>
        </form>
      </div>
    </ng-container>
  </main>
</div>
