import { CreateAdCommon, New } from '../';

export type WithMapParams = {
  [key: string]: (model: any) => any;
};

/**
 * @description It is used in map operation for more than one type instead of `mapToApiRequest` method in `CreateAdCommon` class.
 * @example @WithMap({
 *  [Facebook.AdType.DynamicCreative]: function(model) {
 *    ...
 *  },
 *  [Facebook.AdType.CarouselAds]: function() {
 *    ...
 *  }
 * })
 * @param mapObj {WithMapParams}
 * @returns {Function}
 */
export function WithMap(mapObj: WithMapParams) {
  return function <C extends New>(constructor: C) {
    constructor.prototype.mapToApiRequest = function _mapToApiRequest<T>(adType: number | string) {
      const vm = this as unknown as CreateAdCommon<T, unknown, unknown>;

      const mapFn = mapObj[adType] as (model: T) => any;

      return mapFn.call(this, vm.createAdModel);
    };

    return class extends constructor {};
  };
}
