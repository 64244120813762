import { Bing, SmartCampaign } from '@core/models';
import { makeMutation, OffsetPaginatedQueryResult } from '@core/queries/types';

const queryField = 'bing';

type Input = Bing.Dashboard.ICampaignRequest;
type Output = OffsetPaginatedQueryResult<
  typeof queryField,
  SmartCampaign.Client.SmartCampaignResult<Bing.Dashboard.IBingCampaignOutput>
>;

export const getCampaigns = makeMutation<Output, Input>(`
query CampaignsWithMetricsBing(
  $skip: Int
  $take: Int
  $searchKey: String
  $startDate: SafeInt
  $endDate: SafeInt
  $status: BingInsightStatus
  $datePreset: DatePreset
) {
  ayn {
    campaign {
      ${queryField}(
        skip: $skip
        take: $take
        startDate: $startDate
        endDate: $endDate
        searchKey: $searchKey
        status: $status
        datePreset: $datePreset
      ) {
        edges {
          node {
            isSmartCampaign
            name
            id
            isSmartCampaign
            status
            campaignType
            startDate
            endDate
            dailyBudget
            clicks
            impressions
            costPerClick
            conversions
            costPerConversion
            revenue
            spend
            ctr
          }
        }
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          from
          to
        }
      }
    }
  }
}
`);
