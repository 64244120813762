import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { LinkedinCreateAd } from '@core/ad-platforms/linkedin/create-ad';
import { Draft, Linkedin, Platforms } from '@core/models';
import { CreateAnAdSteps } from '@pages/create-ad/models';

import { SaveDraft } from '../base';
import { Store } from '@ngxs/store';
import { BusinessState } from '@core/state/business.state';

@Injectable({ providedIn: 'root' })
export class LinkedinStateService<
  T extends Linkedin.AdModelCarouselImage | Linkedin.AdModelSingleImage = Linkedin.AdModelSingleImage
> extends SaveDraft<LinkedinCreateAd.ICreateAdModel<T>> {
  createAd = new LinkedinCreateAd.CreateAd();

  adCreationModel$: BehaviorSubject<LinkedinCreateAd.ICreateAdModel<T>> = new BehaviorSubject(
    this.createAd.createAdModel as LinkedinCreateAd.ICreateAdModel<T>
  );

  get adCreationModel(): LinkedinCreateAd.ICreateAdModel<T> {
    return this.adCreationModel$.value;
  }

  draftModel: Draft.IDraftItem | Draft.IUpsertAdDraft = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;

  constructor(private store: Store) {
    super();
  }

  setAdModel(value: LinkedinCreateAd.ICreateAdModel<T>) {
    this.adCreationModel$.next(value);
    this.createAd.createAdModel = value;
  }

  reset() {
    this.createAd = new LinkedinCreateAd.CreateAd();
    this.setAdModel(this.createAd.createAdModel as LinkedinCreateAd.ICreateAdModel<T>);
    this.draftModel = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;
  }

  changeAdType(adType: Linkedin.AdType) {
    const changedModel = this.createAd.changeType!('ad', adType);

    this.adCreationModel.selectedTypes.ad = adType;

    this.setAdModel(changedModel as LinkedinCreateAd.ICreateAdModel<T>);
  }

  updateActiveStep(step: CreateAnAdSteps) {
    this.adCreationModel.activeStep = step;
  }

  mapToApiRequest() {
    const selectedAdType = this.adCreationModel.selectedTypes.ad;

    if (!selectedAdType) return;
    const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    const adAcc = selectedBusiness!.connectedAdAccounts.find((o) => o.source === Platforms.LinkedIn);
    const model = this.createAd.mapToApiRequest(selectedAdType);
    if (adAcc) {
      model.campaign.account = adAcc.externalId;
    }
    return model;
  }
}
