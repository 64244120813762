import { Google, Linkedin } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Linkedin.Business.IConnectAdAccountRequest;
type Output = Linkedin.Business.IConnectAdAccountResponse;

export const connectAdAccountLinkedin = makeMutation<Output, Input>(`
mutation ConnectAdAccount(
  $contextId: String!
  $adAccountId: String!
  $loginId: String
) {
  linkedin {
    connectAdAccount(
      payload: {
        contextId: $contextId
        adAccountId: $adAccountId
        loginId: $loginId
      }
    ) {
      businessId
      connectedAdAccountId
      platform
    }
  }
}
`);
