<div class="aayn-alert-bar" [ngClass]="{ 'aayn-alert-bar__with-title': title?.length }">
  <ayn-icon *ngIf="leftIcon" [name]="leftIcon" class="aayn-alert-bar--left-icon"></ayn-icon>

  <ayn-icon *ngIf="!leftIcon" name="cross-mini" (click)="onClickHide.emit()"></ayn-icon>

  <div class="aayn-alert-bar--texts">
    <span class="aayn-alert-bar--texts-title" *ngIf="title?.length">{{ title }}</span>
    <span class="aayn-alert-bar--texts-message">{{ text }}</span>
  </div>

  <button
    ayn-button="orange-outline"
    [icon]="buttonIcon"
    (click)="onButtonClick.emit()"
    [loading]="buttonLoading"
    [disabled]="buttonLoading"
    loadingColor="dark"
    *ngIf="buttonText.length"
  >
    {{ buttonText }}
  </button>
</div>
