import {
  endOfMonth,
  endOfYear,
  previousMonday,
  previousSaturday,
  previousSunday,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';

/**
 * Returns a ISO formatted date string.
 * @param date {string | Date}
 * @returns {string}
 */
export function toValidDateFormat(date: string | Date) {
  if (date instanceof Date) {
    return date.toISOString();
  }

  return new Date(date).toISOString();
}

/**
 * An enum describing a date preset that could be used for analytics.
 *
 * @export
 * @enum {number}
 */
export enum DatePreset {
  /**
   * Today, since 12:00 AM in your account's time zone.
   */
  Today = 'today',

  /**
   * From the start
   */
  Lifetime = 'lifetime',

  /**
   * The complete previous year, starting on January 1 at 12:00 AM and ending on December 31 at 11:59 PM.
   */
  LastYear = 'last_year',

  /**
   * The current year, beginning on January 1 and including today.
   */
  ThisYear = 'this_year',

  /**
   * Yesterday, the 24-hour period between 12:00 AM and 11:59 PM in your account's time zone.
   */
  Yesterday = 'yesterday',

  /**
   * The current month, beginning on the first day and including today.
   */
  ThisMonth = 'this_month',

  /**
   * The complete previous month, starting on the first day and ending on the last of the month.,
   */
  LastMonth = 'last_month',

  /**
   * The complete previous 3 days, ending at 11:59 PM last night and not including today.
   */
  LastThreeDays = 'last_3d',

  /**
   * The complete previous 7 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
   */
  LastSevenDays = 'last_7d',

  /**
   * The complete previous 30 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
   */
  LastThirtyDays = 'last_30d',

  /**
   * The complete previous 90 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
   */
  LastNinetyDays = 'last_90d',

  /**
   * The complete previous 14 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
   */
  LastFourteenDays = 'last_14d',

  /**
   * The current quarter, beginning on the first day of the first month of the calendar-year quarter and including today.
   */
  ThisQuarter = 'this_quarter',

  /**
   * The complete previous 28 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
   */
  LastTwentyEightDays = 'last_28d',

  /**
   * The complete 7-day period starting on the previous Monday and ending on the most recent Sunday.
   */
  LastWeekMonSun = 'last_week_mon_sun',

  /**
   * The complete 7-day period starting on the previous Sunday and ending on the most recent Saturday.
   */
  LastWeekSunSat = 'last_week_sun_sat',

  /**
   * The current week, beginning on the most recent Sunday and including today.
   */
  ThisWeekSunToday = 'this_week_sun_today',

  /**
   * The current week, beginning on the most recent Monday and including today.
   */
  ThisWeekMonToday = 'this_week_mon_today',

  LastOneHundredEightyDays = 'last_180d',

  LastTwelveMonths = 'last_12m'
}
export interface DatePresetValue {
  fromDate: Date;
  toDate: Date;
}
export module DatePreset {
  /**
   * Converts a given preset to a {@link Date}.
   *
   * @export
   * @param {DatePreset} preset The date preset.
   * @return {Date} A corresponding {@link Date} instance.
   */
  export function toDate(preset: DatePreset): DatePresetValue {
    const now = new Date();
    const todayStart = startOfToday();
    const thisMonthStart = startOfMonth(todayStart);
    const thisWeekStart = startOfWeek(todayStart);
    const lastWeek = subWeeks(thisWeekStart, 1);
    const thisYear = startOfYear(todayStart);

    switch (preset) {
      case DatePreset.Today:
        return toValue(todayStart, now);
      case DatePreset.Lifetime:
        return toValue(now, now);
      case DatePreset.LastYear:
        const yearStartDate = startOfYear(subYears(thisYear, 1));
        const yearEndDate = endOfYear(subYears(thisYear, 1));
        return toValue(yearStartDate, yearEndDate);
      case DatePreset.ThisYear:
        return toValue(thisYear, todayStart);
      case DatePreset.Yesterday:
        return toValue(startOfYesterday(), todayStart);
      case DatePreset.ThisMonth:
        return toValue(thisMonthStart, todayStart);
      case DatePreset.LastMonth:
        const lastMonth = subMonths(thisMonthStart, 1);
        return toValue(startOfMonth(lastMonth), endOfMonth(lastMonth));
      case DatePreset.LastThreeDays:
        return toValue(subDays(todayStart, 3), todayStart);
      case DatePreset.LastSevenDays:
        return toValue(subDays(todayStart, 7), todayStart);
      case DatePreset.LastThirtyDays:
        return toValue(subDays(todayStart, 30), todayStart);
      case DatePreset.LastNinetyDays:
        return toValue(subDays(todayStart, 90), todayStart);
      case DatePreset.LastFourteenDays:
        return toValue(subDays(todayStart, 14), todayStart);
      case DatePreset.ThisQuarter:
        return toValue(startOfQuarter(todayStart), todayStart);
      case DatePreset.LastTwentyEightDays:
        return toValue(subDays(todayStart, 28), todayStart);
      case DatePreset.LastWeekMonSun:
        return toValue(previousMonday(lastWeek), previousSunday(lastWeek));
      case DatePreset.LastWeekSunSat:
        return toValue(previousSunday(lastWeek), previousSaturday(lastWeek));
      case DatePreset.ThisWeekSunToday:
        return toValue(previousSunday(todayStart), todayStart);
      case DatePreset.ThisWeekMonToday:
        return toValue(previousMonday(todayStart), todayStart);
      case DatePreset.LastOneHundredEightyDays:
        return toValue(subDays(todayStart, 180), todayStart);
      case DatePreset.LastTwelveMonths:
        return toValue(subMonths(todayStart, 12), todayStart);
      default: {
        return toValue(now, now);
      }
    }
  }

  export function toValue(fromDate: Date, toDate: Date): DatePresetValue {
    return { fromDate, toDate };
  }
}

/**
 * Get difference two dates as time and days.
 * @param firstDate {Date}
 * @param lastDate {Date}
 * @returns {diffTime: number, diffDays: number}
 */
export function getDiffenceTwoDates(firstDate: Date, lastDate: Date) {
  const diffTime = Math.abs(lastDate.getTime() - firstDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

  return {
    diffTime,
    diffDays
  };
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

/**
 * Get month name by month number.
 * @param monthNumber {Number}
 * @returns {string}
 */
export function getMonth(monthNumber: number) {
  if (monthNumber <= -1 || monthNumber >= 12) {
    throw new Error(`[DateHelper.getMonth]: Month number is not valid.`);
  }

  return MONTHS[monthNumber];
}
