export enum EventHubEventName {
  AccountConnected = 'AccountConnected',
  AccountStatusUpdated = 'AccountStatusUpdated',
  EmailConfirmed = 'EmailConfirmed',
  CreativesCreationStarted = 'CreativesCreationStarted',
  SingleCreativeCreationFinished = 'SingleCreativeCreationFinished'
}

export interface IEventResponse<T extends any = any> {
  eventName: EventHubEventName;
  data: {
    value?: T;
  };
}

export interface IEventResponseByEvent<N extends EventHubEventName, T extends any = any> {
  eventName: N;
  data: {
    value?: T;
  };
}

export interface CreativesCreationStartedEvent {
  /**
   * The message type of the event.
   */
  messageType: string;

  /**
   * The request render state.
   */
  requestRenderState: number;

  /**
   * The image render process ID.
   */
  imageRenderProcessId: string;

  /**
   * The total task count.
   */
  totalTaskCount: number;

  /**
   * The completed task count.
   */
  completedTaskCount: number;

  /**
   * The progress.
   */
  progress: number;

  /**
   * The render kind.
   */
  renderKind: number;

  /**
   * Illustrates the process is started successfully.
   */
  isSuccessful: boolean;

  /**
   * The error message if the process is not successful.
   */
  errorMessage: string;
}

export interface SingleCreativeCreationFinishedEvent {
  /**
   * The message type of the event.
   */
  messageType: string;

  /**
   * The image render process ID.
   */
  imageRenderProcessId: string;

  /**
   * The task id of creative generation task.
   */
  taskId: string;

  /**
   * Illustrates the process is started successfully.
   */
  isSuccessful: boolean;

  /**
   * The error message if the process is not successful.
   */
  errorMessage: string;
}
