<li [ngClass]="{ active: active?.isActive }" class="__{{ active?.type }}" (click)="includeClick()">
  <span class="aayn-audience--location-selection--content">
    <ng-content></ng-content>
  </span>

  <span class="aayn-audience--location-selection--type">{{ type || '' | translate }}</span>

  <span class="aayn-audience--item-loader" *ngIf="showLoader"></span>

  <div class="aayn-audience--location-selection--li-actions" *ngIf="!showLoader">
    <button
      ayn-button
      class=":inclusion-btn"
      type="button"
      [disabled]="active?.isActive && active?.type == 'exclusions'"
      icon="plus"
      (click)="includeClick($event)"
    >
      {{
        active?.isActive && active?.type == 'inclusions'
          ? includeTexts.includedText
          : (includeTexts.includeText | translate)
      }}
    </button>

    <button
      ayn-button
      type="button"
      *ngIf="canExclude"
      class=":exclusion-btn"
      icon="minus"
      (click)="excludeClick($event)"
      [disabled]="active?.isActive && active?.type == 'inclusions'"
    >
      {{ active?.type == 'exclusions' ? 'Added' : ('Exclude' | translate) }}
    </button>
  </div>
</li>
