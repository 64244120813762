import { IDeleteBusinessRequest, IDeleteBusinessResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = IDeleteBusinessRequest;
type Output = IDeleteBusinessResponse;

export const deleteBusiness = makeMutation<Output, Input>(`
mutation delete($id: String!) {
  deleteBusiness(id: $id)
}
`);
