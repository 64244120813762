import * as Query from '@core/queries';
import { tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, GraphQLService } from '@core/backend';
import { Settings, UserLoginResponse } from '@core/models';
import { Store } from '@ngxs/store';

import { SetUser, UserState } from '../';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseHttpService {
  constructor(private graphqlService: GraphQLService, private store: Store, private http: HttpClient) {
    super(http);
  }

  changeUserPassword(password: string, passwordAgain: string) {
    const request = {
      password,
      passwordAgain
    };

    return this.graphqlService.post(Query.changeUserPassword(request)).pipe(
      tap(({ data }) => {
        const { accessToken, refreshToken } = data!;

        let user = this.store.selectSnapshot(UserState.user);
        if (user) {
          const newUser = { ...user, accessToken, refreshToken } as UserLoginResponse;
          this.store.dispatch(new SetUser(newUser));
        }
      })
    );
  }

  changeUserInfo(fullName: string, email: string) {
    const input = {
      fullName,
      email
    };

    return this.graphqlService.post(Query.changeUserInfo({ input })).pipe(
      tap((result) => {
        if (result.data?.changed) {
          let oldUser = this.store.selectSnapshot(UserState.user);
          if (oldUser) {
            const user = result.data.user;
            const newUser = {
              ...oldUser,
              name: `${user.name} ${user.surname}`,
              user,
              accessToken: result.data.accessToken,
              refreshToken: result.data.refreshToken
            } as UserLoginResponse;
            this.store.dispatch(new SetUser(newUser));
          }
        }
      })
    );
  }

  updateProfilePicture(file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.post('/user/profile-picture', formData);
  }

  getProfilePicture() {
    return this.get<Blob>(`/user/profile-picture`, undefined, undefined, { responseType: 'blob' });
  }

  deleteProfilePicture() {
    return this.delete(`/user/profile-picture`);
  }

  updateCompanyLogo(file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.post('/branding/logo', formData);
  }

  getCompanyLogo() {
    return this.get<Blob>(`/branding/logo`, undefined, undefined, { responseType: 'blob' });
  }

  deleteCompanyLogo() {
    return this.delete(`/branding/logo`);
  }

  createCustomBranding(brandingInput: Settings.BrandingInput) {
    return this.graphqlService.post(Query.createCustomBranding({ brandingInput }));
  }

  updateCustomBranding(brandingInput: Partial<Settings.BrandingInput>) {
    return this.graphqlService.post(Query.updateCustomBranding({ brandingInput }));
  }

  deleteCustomBranding() {
    return this.graphqlService.post(Query.deleteCustomBranding());
  }

  getCustomBranding() {
    return this.graphqlService.get(Query.getCustomBranding());
  }
}
