import { CursorPaginatedQueryResult, makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';

const queryField = 'searchCreatives';
type Input = { input: Linkedin.Dashboard.LinkedInSearchCreativeInput };
type Output = CursorPaginatedQueryResult<typeof queryField, Linkedin.Dashboard.LinkedInSearchCreativeEdge>;

export const searchCreativesQueryLinkedin = makeQuery<Output, Input>(`
query Search($input:LinkedInSearchCreativeInputDto!) {
  linkedin {
    ${queryField}(input: $input){
      edges {
        cursor
        node {
          account
          campaign
          content {
            reference
          }
          createdAt
          createdBy
          intendedStatus
          isServing
          lastModifiedAt
          lastModifiedBy
          servingHoldReasons
          id
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
`);
