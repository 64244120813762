import { Injectable } from '@angular/core';
import { Clickfraud, GraphQLService } from '..';
import * as Query from '@core/queries';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClickfraudProtectionService {
  constructor(private graphqlService: GraphQLService) {}

  getClickfraudProtectionDetailsBing$() {
    return this.graphqlService
      .get(Query.ClickfraudProtectionQ.platforms.clickfraudProtectionDetailsBing())
      .pipe(map((result) => result.data));
  }

  getClickfraudProtectionDetailsGoogle$() {
    return this.graphqlService
      .get(Query.ClickfraudProtectionQ.platforms.clickfraudProtectionDetailsGoogle())
      .pipe(map((result) => result.data));
  }

  getClickfraudProtectionDetailsFacebook$() {
    return this.graphqlService
      .get(Query.ClickfraudProtectionQ.platforms.clickfraudProtectionDetailsFacebook())
      .pipe(map((result) => result.data));
  }

  getClickfraudProtectionDetailsLinkedin$() {
    return this.graphqlService
      .get(Query.ClickfraudProtectionQ.platforms.clickfraudProtectionDetailsLinkedin())
      .pipe(map((result) => result.data));
  }

  turnOffClickfraudProtection$(payload: Clickfraud.ITurnOffClickfraudRequest) {
    return this.graphqlService
      .post(Query.ClickfraudProtectionQ.swich.turnOffClickfraudProtection(payload))
      .pipe(map((result) => result.data));
  }

  turnOnClickfraudProtection$(payload: Clickfraud.ITurnOnClickfraudRequest) {
    return this.graphqlService
      .post(Query.ClickfraudProtectionQ.swich.turnOnClickfraudProtection(payload))
      .pipe(map((result) => result.data));
  }

  clickfraudProtectionStatus$(payload: Clickfraud.IClickfraudProtectionStatusRequest) {
    return this.graphqlService
      .get(Query.ClickfraudProtectionQ.status.clickfraudProtectionStatus(payload))
      .pipe(map((result) => result.data));
  }
}
