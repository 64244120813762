<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Conversions on my website' | translate }}</h4>

    <p class="aayn-select-objective--header__description">
      {{ 'For receiving conversions on your website, must have Tiktok Pixel installed.' | translate }}
    </p>
  </header>

  <form [formGroup]="conversionForm">
    <aayn-ad-form-input label="Conversion Event">
      <div class="aayn-select-objective--row">
        <ayn-form-field>
          <ayn-select formControlName="pixelId" placeholder="Select a pixel">
            <ayn-option
              *ngFor="let pixel of pixels | aynOptionFilter | async"
              [value]="pixel.id!"
              [disabled]="!pixel.events?.length"
              class="aayn-select-objective--has-disabled-info"
            >
              <span>{{ pixel.name }}</span>
              <ayn-icon
                *ngIf="!pixel.events?.length"
                name="info"
                ayn-tooltip="To select this pixel, you must create a conversion event in TikTok Ads Manager."
              >
              </ayn-icon>
            </ayn-option>
            <ayn-option [disabled]="true" value="-1" *ngIf="!pixels.length">
              {{ 'There is no TikTok pixel in your account.' | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <ayn-select
            formControlName="pixelEventCode"
            [placeholder]="'Select a conversion event' | translate"
            [panelMaxHeight]="220"
            [disabled]="!selectedPixel"
          >
            <ayn-option
              *ngFor="let event of selectedPixel?.events || [] | aynOptionFilter | async"
              [value]="event.code!"
            >
              {{ event.name | aynTitleCase }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>
      </div>
    </aayn-ad-form-input>

    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="campaignName"
          aaynDraftName
          [placeholder]="'Campaign Name' | translate"
          [(ngModel)]="adCreationModel!.campaign.campaignName"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
