import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.ITurnResourceRequest;
type Output = { turnOn: boolean };

export const turnOnCampaignsBing = makeMutation<Output, Input>(`
  mutation BingTurnOn(
    $resourceIds: [String!]!
    $resourceType: BingIdResourceType!
  ) {
    bing {
      turnOn(
        resourceIds: $resourceIds
        resourceType: $resourceType
      )
    }
  }
`);
