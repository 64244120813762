import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TikTok } from '@core/models';
import { TiktokStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-gender-selection--tiktok',
  templateUrl: './gender-selection.component.html'
})
export class GenderSelectionTiktokComponent implements OnInit {
  @Input() form?: FormGroup;

  protected Gender = TikTok.CreateAd.Client.Gender;

  protected adCreationModel = this.tiktokStateService.adCreationModel;

  constructor(private tiktokStateService: TiktokStateService) {}

  ngOnInit() {
    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  protected get isSelectedMale() {
    return this.form?.value?.male;
  }

  protected get isSelectedFemale() {
    return this.form?.value?.female;
  }

  protected get isSelectedAllGenders() {
    return this.adCreationModel?.audience.female && this.adCreationModel.audience.male;
  }

  protected selectGender(gender: TikTok.CreateAd.Client.Gender) {
    if (gender == TikTok.CreateAd.Client.Gender.Men) {
      this.form!.patchValue({ male: true, female: false });
    } else {
      this.form!.patchValue({ female: true, male: false });
    }
  }

  protected selectAllGender() {
    this.adCreationModel!.audience.female = true;
    this.adCreationModel!.audience.male = true;

    this.form?.patchValue({
      male: true,
      female: true
    });
  }
}
