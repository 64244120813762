import { Overlay } from 'apps/ayn-ui/src/public-api';

import { Component, OnInit, ViewChild } from '@angular/core';
import { SUPPORTED_LANGUAGES, SupportedLanguages } from '@core/constants';
import { I18NClientService } from '@core/index';

@Component({
  selector: 'aayn-language-selection',
  templateUrl: 'language-selection.component.html'
})
export class LanguageSelectionComponent implements OnInit {
  selectedLanguage = this.i18nClientService.activeLanguage || SUPPORTED_LANGUAGES[0];

  languages = SUPPORTED_LANGUAGES;

  @ViewChild(Overlay) overlay!: Overlay;

  constructor(private i18nClientService: I18NClientService) {}

  ngOnInit() {}

  setSelectedLanguage(language: SupportedLanguages) {
    this.selectedLanguage = language;

    this.i18nClientService.changeLanguage(language);

    if (this.overlay) {
      this.overlay.hide();
    }
  }
}
