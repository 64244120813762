import * as Query from '@core/queries';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { OpenAI } from '@core/models/open-ai.model';

import { GraphQLService, OpenAiQ } from '../';

@Injectable({ providedIn: 'root' })
export class OpenAiService {
  constructor(private graphqlService: GraphQLService) {}

  getOpenAiGenerateTextProject(payload: OpenAI.IGenerateTextProjectRequest) {
    return this.graphqlService
      .post(Query.OpenAiQ.generateTextProjectQuery(payload))
      .pipe(map((result) => result.data?.generateTextProject));
  }

  getOpenAiGenerateTexts(payload: OpenAI.IGenerateTextsRequest) {
    return this.graphqlService
      .post(Query.OpenAiQ.generateTextsQuery(payload))
      .pipe(map((result) => result.data?.generateTexts));
  }

  changeSentiment(input: OpenAI.ChangeSentimentInput) {
    return this.graphqlService
      .get(OpenAiQ.changeSentimentQuery(input))
      .pipe(map((result) => result.data.changeSentiment));
  }

  createVariations(input: OpenAI.TextCraftCommonInput) {
    return this.graphqlService
      .get(OpenAiQ.createVariationsQuery(input))
      .pipe(map((result) => result.data.createVariations));
  }

  getBrandDescription(input: OpenAI.UrlInput) {
    return this.graphqlService
      .get(OpenAiQ.getBrandDescriptionQuery(input))
      .pipe(map((result) => result.data.getBrandDescription));
  }

  getLogos(input: OpenAI.UrlInput) {
    return this.graphqlService.get(OpenAiQ.searchLogosQuery(input)).pipe(map((result) => result.data.getLogos));
  }

  searchImages(input: OpenAI.UrlInput) {
    return this.graphqlService.get(OpenAiQ.searchImagesQuery(input)).pipe(map((result) => result.data.getImages));
  }

  getProductDescription(input: OpenAI.UrlInput) {
    return this.graphqlService
      .get(OpenAiQ.getProductDescriptionQuery(input))
      .pipe(map((result) => result?.data?.getProductDescription));
  }

  getFrameworkTexts(input: OpenAI.GenerateTextsWithFrameworkInputDto) {
    return this.graphqlService
      .get(OpenAiQ.getFrameworkTexts(input))
      .pipe(map((result) => result.data.generateTextsWithFramework));
  }

  makeShorten(input: OpenAI.TextCraftCommonInput) {
    return this.graphqlService.get(OpenAiQ.makeShortenQuery(input)).pipe(map((result) => result.data.makeShorten));
  }

  translate(input: OpenAI.TranslateInput) {
    return this.graphqlService.get(OpenAiQ.translateQuery(input)).pipe(map((result) => result.data.translate));
  }
}
