<div class="ayn-color-picker">
  <span class="ayn-color-picker-text">
    {{ label }}
  </span>
  <input
    class="ayn-color-picker-input"
    [ngClass]="{ onlyColor: !showValue }"
    [style.background]="color"
    [cpPresetColors]="colorGroups"
    [(colorPicker)]="color"
    [value]="showValue ? color : ''"
    [cpPosition]="'top'"
    [cpWidth]="'330px'"
    [cpPresetLabel]="'Document Colors'"
    (colorPickerChange)="onChangeColor($event)"
    [cpColorMode]="'color'"
    [cpDisableInput]="false"
    [cpOutputFormat]="cpOutputFormat"
    [readonly]="true"
    (colorPickerOpen)="openCp()"
    (colorPickerClose)="closeCp()"
  />
</div>
