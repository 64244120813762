import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdPreviewComponent } from '../../../ad-preview.component';
import { CarouselModule, PipesModule } from '@ayn-ui/lib/modules';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-linkedin-carousel',
  templateUrl: './carousel.component.html',
  imports: [NgIf, NgTemplateOutlet, AdPreviewComponent, CarouselModule, NgForOf, PipesModule, TranslateModule],
  standalone: true
})
export class AdPreviewLinkedinCarouselComponent {
  @Input() businessName = '';
  @Input() businessImage = '';
  @Input() headline = '';

  @Input() cards: Array<{
    title?: string;
    image?: string[];
    body?: string;
  }> = [];

  @Input() activeIndex: number = 0;
  @Output() activeIndexChange = new EventEmitter<number>();

  headlinePlaceholder = 'Your Headline Goes Here';
  placeholder = '/assets/images/pngs/placeholder.png';

  onImgError(event) {
    event.target.src = '/assets/images/pngs/placeholder.png';
  }
}
