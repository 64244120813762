import { ILecture } from '..';

export const UNIVERSITY_LECTURES: ILecture[] = [
  {
    id: '1',
    description: 'Lorem ipsum dolar si amet',
    isCompleted: true,
    name: 'ADYOUNEED & Advertising Basics',
    platform: {
      name: 'Add You Need',
      icon: null
    },
    videos: [
      {
        id: '1',
        lectureId: '1',
        name: 'Video Name 1',
        description: 'Video Description 1',
        videoDurationSeconds: 120,
        level: 'Beginner',
        videoSource: {
          placeholderImageUrl: 'https://via.placeholder.com/350x210',
          provider: 'Vimeo',
          videoIdentity: '601700833'
        }
      },
      {
        id: '2',
        lectureId: '1',
        name: 'Video Name 2',
        description: 'Video Description 2',
        videoDurationSeconds: 100,
        level: 'Beginner',
        videoSource: {
          placeholderImageUrl: 'https://via.placeholder.com/350x210',
          provider: 'Vimeo',
          videoIdentity: '766958595'
        }
      }
    ]
  },
  {
    id: '2',
    description: 'Lorem ipsum dolar si amet',
    isCompleted: false,
    name: 'Meta Ads',
    platform: {
      name: 'Meta',
      icon: 'platforms:meta:flat'
    },
    videos: [1, 2, 3, 4, 5].map((mockVideo) => ({
      id: (2 * mockVideo).toString(),
      lectureId: '2',
      description: `Video description ${mockVideo}`,
      name: `Video name ${mockVideo}`,
      videoDurationSeconds: mockVideo * 100,
      level: 'Beginner',
      videoSource: {
        placeholderImageUrl: 'https://via.placeholder.com/350x210',
        provider: 'Vimeo',
        videoIdentity: '766958595'
      }
    }))
  },
  {
    id: '3',
    name: 'Google Ads',
    description: 'Lorem ipsum',
    isCompleted: false,
    platform: { name: 'Google', icon: 'google' },
    videos: []
  },
  {
    id: '4',
    name: 'LinkedIn Ads',
    description: 'Lorem ipsum',
    isCompleted: false,
    platform: {
      name: 'LinkedIn',
      icon: 'linkedin'
    },
    videos: []
  },
  {
    id: '5',
    name: 'Microsoft Ads',
    description: 'Lorem ipsum dolar si amet',
    isCompleted: false,
    platform: {
      name: 'Microsoft',
      icon: 'microsoft'
    },
    videos: []
  }
];
