import { Directive, ElementRef, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[aaynControlTriggerSubmit]'
})
export class ControlTriggerSubmitDirective implements OnInit, OnChanges {
  @Input() delay = 2000;

  @Input() submitEvent: 'ngSubmit' | `keydown:enter` = 'ngSubmit';

  private subscription?: Subscription;

  constructor(
    @Self() public control: NgControl,
    public formGroup: FormGroupDirective,
    private elRef: ElementRef<HTMLInputElement>
  ) {}

  ngOnInit(): void {
    this.listenControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.delay?.currentValue || changes.submitEvent?.currentValue) {
      this.listenControl();
    }
  }

  listenControl() {
    this.subscription?.unsubscribe();

    if (this.control.valueChanges) {
      let submitValue = this.control.value;
      this.subscription = this.control.valueChanges
        .pipe(
          debounceTime(this.delay),
          distinctUntilChanged(),
          filter(
            () =>
              !!this.control.valid && !!this.control.dirty && this.control.value && this.control.value !== submitValue
          )
        )
        .subscribe((val) => {
          if (this.submitEvent === 'ngSubmit') {
            this.formGroup.ngSubmit.emit();
          } else {
            this.elRef.nativeElement.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }));
          }
          submitValue = val;
        });
    }
  }
}
