import { Facebook } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { payload: Facebook.CreateAd.ICreateCampaignRequest };
type Output = { createCampaign: string };

export const createCampaign = makeMutation<Output, Input>(`
  mutation FacebookCreateCampaign($payload: FacebookCreateCampaignInputDto!) {
    facebook {
      createCampaign(payload: $payload)
    }
  }
`);
