import { PaymentIntent } from '@core/models';

import { makeQuery } from '../types';

type Input = {
  amount: number;
  currencyCode: string;
};
type Output = PaymentIntent;

export const createPaymentIntent = makeQuery<Output, Input>(`  
  query CreatePaymentIntent($amount: Int!, $currencyCode: String!) {
    createPaymentIntent(amount: $amount, currencyCode: $currencyCode) {
      id
      status
      currency_code
      amount
      gateway_account_id
      expires_at
      reference_id
      payment_method_type
      success_url
      failure_url
      created_at
      modified_at
      resource_version
      updated_at
      gateway
      business_entity_id
    }
  }
`);
