import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-roadmap',
  templateUrl: './roadmap.component.html'
})
export class RoadmapComponent {
  constructor(private modalService: NgbModal) {}

  @ViewChild('roadmapModal') roadmapModal: any;

  roadmapModalOptions: NgbModalOptions = {
    backdrop: 'static',
    backdropClass: 'customBackdrop',
    windowClass: 'roadmap-modal',
    size: 'xl'
  };

  openModal(): void {
    this.modalService.open(this.roadmapModal, this.roadmapModalOptions);
  }
}
