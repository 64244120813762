import { UserLoginResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  email: string;
  password: string;
};

type Output = UserLoginResponse;

export const login = makeMutation<Output, Input>(`
mutation Login($email: String!, $password: String!) {
  login(data: { email: $email, password: $password }) {
    accessToken
    refreshToken
    user {
      sub
      name
      email
      surname
      emailConfirmed
      inviter {
        inviterId
      }
    }
  }
}
`);
