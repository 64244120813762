import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, skipUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DraftService } from '@core/services/draft.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartCampaignFormService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.form';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';
import { Platforms } from '@core/models';

@UntilDestroy()
@Component({
  selector: 'aayn-manual-audience--smart-campaign',
  templateUrl: 'manual-audience.component.html'
})
export class ManualAudienceSmartCampaignComponent implements OnInit {
  adCreationModel = this.smartCampaignStateService.adCreationModel;
  platforms = Platforms;

  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel.audience.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    minAge: new FormControl(this.adCreationModel.audience.age.min, [Validators.required]),
    maxAge: new FormControl(this.adCreationModel.audience.age.max, [Validators.required]),
    male: new FormControl(this.adCreationModel.audience.gender.male, [Validators.required]),
    female: new FormControl(this.adCreationModel.audience.gender.female, [Validators.required]),
    geoLocations: new FormControl(this.adCreationModel.audience.geoLocations.inclusions, [Validators.required]),
    detailedTargetings: new FormControl(this.adCreationModel.audience.detailedTargetings, () => {
      const hasTiktok = this.smartCampaignStateService.hasPlatform(Platforms.TikTok);
      if (!hasTiktok || this.adCreationModel.audience.detailedTargetings.tiktok?.length) {
        return null;
      }
      return { required: true };
    }),
    languages: new FormControl(this.adCreationModel.audience.languages || [])
  });

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private smartCampaignFormService: SmartCampaignFormService,
    private draftService: DraftService
  ) {}

  ngOnInit() {
    this.smartCampaignFormService.registerForm(this.form);

    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        name: o.audience.name,
        geoLocations: o.audience.geoLocations.inclusions,
        detailedTargetings: o.audience.detailedTargetings,
        male: o.audience.gender.male,
        female: o.audience.gender.female,
        languages: o.audience.languages,
        maxAge: o.audience.age.max,
        minAge: o.audience.age.min
      });
    });

    this.form.valueChanges.pipe(skipUntil(this.draftService.getDraftLoader$ || of())).subscribe((o) => {
      this.adCreationModel.audience.name = o.name!;
      this.adCreationModel.audience.geoLocations.inclusions = o.geoLocations!;
      this.adCreationModel.audience.detailedTargetings = o.detailedTargetings!;
      this.adCreationModel.audience.gender.male = o.male!;
      this.adCreationModel.audience.gender.female = o.female!;
      this.adCreationModel.audience.languages = o.languages!!;
      this.adCreationModel.audience.age.min = o.minAge!;
      this.adCreationModel.audience.age.max = o.maxAge!;
    });

    this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(750)).subscribe(() => {
      this.smartCampaignValidationService.audienceValid$.next(this.form.status === 'VALID');
    });
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    if (!this.adCreationModel?.audience) return;

    this.adCreationModel.audience.age.min = min;
    this.adCreationModel.audience.age.max = max;

    this.form.patchValue({
      minAge: min,
      maxAge: max
    });
  }
}
