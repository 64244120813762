import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WindowService } from '@core/services/window.service';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@environment';
import { ButtonModule, IconModule, InputModule } from 'apps/ayn-ui/src/public-api';

@Component({
  selector: 'aayn-base-friend-invite',
  templateUrl: './base-friend-invite.component.html'
})
export class BaseFriendInviteComponent implements OnInit {
  inviteLink = 'https://adyouneed.com/you/cihancelen';

  constructor(private windowService: WindowService) {}

  ngOnInit(): void {}

  routeTarget(targetPlatform: 'facebook' | 'twitter' | 'linkedin') {
    let targetUrl = ``;
    let targetPlatformTitle = ``;

    if (targetPlatform === 'facebook') {
      targetUrl = `https://www.facebook.com/sharer/sharer.php?u=${environment.applicationUrl}`;
      targetPlatformTitle = `Share Facebook`;
    } else if (targetPlatform === 'twitter') {
      targetUrl = `https://twitter.com/intent/tweet?url=${environment.applicationUrl}`;
      targetPlatformTitle = `Share Twitter`;
    } else if (targetPlatform === 'linkedin') {
      targetUrl = `https://www.linkedin.com/sharing/share-offsite/?url='=${environment.applicationUrl}`;
      targetPlatformTitle = `Share LinkedIn`;
    }

    this.windowService.openCenteredWindow(targetUrl, targetPlatformTitle, 500, 600);
  }
}

@NgModule({
  imports: [CommonModule, IconModule, ButtonModule, InputModule, FormsModule, TranslateModule],
  exports: [BaseFriendInviteComponent],
  declarations: [BaseFriendInviteComponent],
  providers: []
})
export class BaseFriendInviteModule {}
