import { Clickfraud } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Clickfraud.ITurnOnClickfraudRequest;
type Output = Clickfraud.ITurnOnClickfraudResponse;

export const turnOnClickfraudProtection = makeMutation<Output, Input>(`
mutation TurnOnClickfraudProtection($source: Int!) {
  turnOnCFP(source: $source)
}
`);
