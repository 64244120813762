import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'createVariations';
type Output = QueryResult<typeof queryField, string[]>;

export const createVariationsQuery = makeQuery<Output, OpenAI.TextCraftCommonInput>(`
query CreateVariations($content: String!, $productName: String!, $productDescription: String!, $textLimit: Float!){
  openAI {
    ${queryField}(content: $content, productName: $productName, productDescription: $productDescription, textLimit: $textLimit)
  }
}
`);
