import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleConversionGoalOutputRequest;
type Output = Google.CreateAd.IGoogleConversionGoalOutputResponse;

export const conversionGoal = makeMutation<Output, Input>(`
query ConversionGoal($searchKey: String) {
  google {
    conversionGoal(searchKey: $searchKey) {
      name
      category
      id
      conversionActionIds
    }
  }
}
`);
