import { Injectable } from '@angular/core';
import { BaseHttpService, GraphQLService } from '@core/backend';
import * as Query from '@core/queries';

import {
  GqlFilterErrorService,
  ICreateBusinessBrandRequest,
  ICreateBusinessRequest,
  IUpdateBusinessBrandRequest,
  IUpdateBusinessRequest
} from '../';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BusinessService extends BaseHttpService {
  constructor(private gqlService: GraphQLService, http: HttpClient, private filterErrorService: GqlFilterErrorService) {
    super(http);
  }

  create(business: ICreateBusinessRequest) {
    return this.gqlService.post(Query.createBusiness(business));
  }

  createBrand(business: ICreateBusinessBrandRequest) {
    return this.gqlService.post(Query.createBusinessBrand(business));
  }

  getBusiness() {
    return this.gqlService.get(Query.allBusinesses());
  }

  getById(id: string) {
    return this.gqlService.get(Query.findById({ id }));
  }

  getBrandById(businessId: string) {
    this.filterErrorService.registerFilterError('GetBusinessBrand', (error) => {
      if (error instanceof HttpErrorResponse) {
        return error.status === 404;
      }
      return !!error.errors?.some((e) => e.extensions.response.statusCode === 404);
    });
    return this.gqlService.get(Query.getBusinessBrand({ businessId }));
  }

  update(business: IUpdateBusinessRequest) {
    return this.gqlService.post(Query.updateBusiness(business));
  }

  updateBrand(business: IUpdateBusinessBrandRequest) {
    return this.gqlService.post(Query.updateBusinessBrand(business));
  }

  deleteBusiness(id: string) {
    return this.gqlService.post(Query.deleteBusiness({ id }));
  }

  deleteBrand(id: string) {
    return this.gqlService.post(Query.deleteBusinessBrand({ id }));
  }

  updateBusinessLogo(file: File, businessId: string) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.post('/business/logo', formData, { 'x-business-id': businessId });
  }

  getBusinessLogo(businessId: string) {
    return this.get<Blob>(`/business/logo`, undefined, { 'x-business-id': businessId }, { responseType: 'blob' });
  }

  deleteBusinessLogo(businessId: string) {
    return this.delete(`/business/logo`, { 'x-business-id': businessId });
  }

  getBusinessAndBrand(businessId: string) {
    return forkJoin([this.getById(businessId), this.getBrandById(businessId)]).pipe(
      map(([businessResponse, brandResponse]) => ({
        ...businessResponse.data,
        brandId: brandResponse?.data?.id,
        color1: brandResponse?.data?.color1,
        color2: brandResponse?.data?.color2,
        url: brandResponse?.data?.brandUrl
      }))
    );
  }
}
