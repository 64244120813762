import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Facebook, FacebookService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';

@Component({
  selector: 'aayn-library--facebook',
  templateUrl: 'library.component.html'
})
export class LibraryFacebookComponent implements OnInit {
  query = '';

  @Select(LoaderState.getAny(['GetAdImages'])) loader$?: Observable<boolean>;

  images?: Facebook.IGetAdImagesResponse;

  videos?: Facebook.IGetAdVideosResponse;

  maxImages = 10;

  constructor(public modal: NgbActiveModal, private facebookService: FacebookService) {}

  ngOnInit() {
    this.facebookService.getAdImages$().subscribe((result) => {
      if (result.error) return;

      this.images = result.data.adImages;
    });

    this.facebookService.getAdVideos$().subscribe((o) => {
      if (o.errors) return;

      this.videos = o.data.adVideos;
    });
  }

  protected toggleImage(item: Facebook.AdImageModel) {
    if (this.selectedImagesLength! >= this.maxImages && !item.selected) return;

    item.selected = !item.selected;
  }

  protected toggleVideo(item: Facebook.AdVideoModel) {
    if (this.selectedImagesLength! >= this.maxImages && !item.selected) return;

    item.selected = !item.selected;
  }

  protected get selectedImagesLength() {
    return this.images?.edges.filter((o) => o.node.selected).map((o) => o.node.id).length || 0;
  }

  protected get selectedVideosLength() {
    return this.videos?.edges.filter((o) => o.node.selected).map((o) => o.node.id).length || 0;
  }

  protected continueWithSelected() {
    const images =
      this.images?.edges
        .filter((o) => o.node.selected)
        .map((o) => ({ id: o.node.hash, url: o.node.url, type: 'image' } as Facebook.IdWithUrl)) || [];

    const videos =
      this.videos?.edges
        .filter((o) => o.node.selected)
        .map((o) => ({ id: o.node.id, url: this.getUrlFromVideo(o.node), type: 'video' } as Facebook.IdWithUrl)) || [];

    this.modal.close({
      images: images.concat(videos)
    });
  }

  getUrlFromVideo(video: Facebook.AdVideoModel) {
    return video.thumbnails?.filter((o) => o.isPreferred).at(0)?.url;
  }
}
