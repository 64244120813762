import { UserLoginResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  data: {
    name: string;
    surname: string;
    email: string;
    password: string;
    passwordAgain: string;
    companyType: string;
  };
};

type Output = UserLoginResponse;

export const signup = makeMutation<Output, Input>(`
mutation CreateUser($data: CreateUserDto!) {
  signupUser(data: $data) {
    accessToken
    refreshToken
    user {
      email
      name
      surname
      language
      emailConfirmed
    }
  }
}
`);
