<section class="aayn-ad-preview aayn-ad-preview-google-display">
  <section class="aayn-ad-preview-content">
    <div class="aayn-ad-preview-google-display-header">
      <ayn-icon srcPrefix="/assets/images/svgs/create-ads/" name="google-display-header"></ayn-icon>
    </div>
    <div *ngIf="!image" class="aayn-ad-preview-placeholder aayn-ad-preview-google-display__image">
      <ayn-icon name="null-image-icon"></ayn-icon>
    </div>
    <img
      *ngIf="image"
      [src]="image | aynSafe: 'url'"
      [alt]="'Ad Preview' | translate"
      class="aayn-ad-preview-google-display__image full-width"
      (error)="onImgError($event)"
    />

    <div class="aayn-ad-preview-google-display--header">
      <img
        alt="Ad Preview Logo"
        class="aayn-ad-preview-google-display--header__logo"
        (error)="onImgError($event)"
        [src]="businessLogo || logoPlaceholder"
      />

      <div>
        <p class="aayn-ad-preview-google-display--header__sub-title">{{ linkUrl || linkUrlPlaceholder }}</p>
        <p class="aayn-ad-preview-google-display--header__title">{{ title || titlePlaceholder }}</p>
        <p class="aayn-ad-preview-google-display--header__description">{{ description || descriptionPlaceholder }}</p>
      </div>
    </div>
  </section>
</section>
