import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { SmartCampaignStateService } from './smart-campaign-state';

@Injectable({ providedIn: 'root' })
export class SmartCampaignFormService extends FormListener {
  constructor(private smartCampaignStateService: SmartCampaignStateService) {
    super();

    this.subscribeForms(() => {
      this.smartCampaignStateService.saveDraft$();
    });
  }
}
