import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/lib/modules';
import { Base, Linkedin, LinkedinService, pascalCase } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { IDetailedTargetingOverlayItemActive } from '@pages/create-ad/components/shared/manuel-audience-setup/components/detailed-targeting/components/detailed-targeting-overlay-item/detailed-targeting-overlay-item.component';
import { LinkedinStateService } from '@pages/create-ad/state';

type DetailedTargeting = Linkedin.CreateAd.SearchByFacetEdge;
@Component({
  selector: 'aayn-detailed-targeting--linkedin',
  templateUrl: './detailed-targeting.component.html'
})
export class DetailedTargetingLinkedinComponent implements OnInit {
  @Input() form?: FormGroup;

  activeTab$ = new BehaviorSubject<'company' | 'education' | 'job' | 'interest'>('company');

  @ViewChild(Overlay) overlay!: Overlay;

  protected detailedTargetingSearchControl = new FormControl('', [Validators.minLength(1)]);

  @Select(LoaderState.getAny(['LinkedinSearchByFacet']))
  loader$?: Observable<boolean>;

  protected detailedTargetings: DetailedTargeting[] = [];

  detailedTargetingInclude: Linkedin.CreateAd.SearchByFacetEdge[] = [];

  detailedTargetingExclude: Linkedin.CreateAd.SearchByFacetEdge[] = [];

  constructor(
    private linkedinStateService: LinkedinStateService,
    private linkedinService: LinkedinService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.detailedTargetingInclude = [...(o.audience.detailedTargetings?.include || [])];
      this.detailedTargetingExclude = [...(o.audience.detailedTargetings?.exclude || [])];
    });

    combineLatest([
      this.detailedTargetingSearchControl.valueChanges.pipe(
        filter((o: string | null) => (o || '').length > 0),
        debounceTime(350),
        distinctUntilChanged()
      ),
      this.activeTab$.asObservable()
    ]).subscribe(([term, activeTab]) => {
      if (this.detailedTargetingSearchControl.invalid || term === null) return;
      const facetMap = {
        company: pascalCase(Linkedin.FacetName.Employers),
        education: pascalCase(Linkedin.FacetName.Degrees),
        job: pascalCase(Linkedin.FacetName.TitlesAll),
        interest: pascalCase(Linkedin.FacetName.Interests)
      };
      this.linkedinService
        .searchByFacet({ query: term!, facet: facetMap[activeTab], omitLocale: activeTab === 'interest' })
        .subscribe((result) => {
          if (result?.edges) {
            this.detailedTargetings = result.edges;

            if (!this.overlay?.render) this.overlay.show(null);

            this.cdr.detectChanges();
          }
        });
    });
  }

  private _mapDetailedTargetings() {
    this.form?.patchValue(
      {
        flexibleDetailedTargetings: [...this.detailedTargetingInclude],
        excludedDetailedTargetings: [...this.detailedTargetingExclude]
      },
      { onlySelf: true }
    );
  }
  protected onIncludeClick(detailedTargeting: DetailedTargeting) {
    if (!this.checkHasAdded(detailedTargeting)) {
      this.detailedTargetingInclude.push(detailedTargeting);
      this._mapDetailedTargetings();
    }
  }
  protected onExcludeClick(detailedTargeting: DetailedTargeting) {
    if (!this.checkHasAdded(detailedTargeting)) {
      this.detailedTargetingExclude.push(detailedTargeting);
      this._mapDetailedTargetings();
    }
  }

  private checkHasAdded(detailedTargeting: DetailedTargeting) {
    return [...this.detailedTargetingInclude, ...this.detailedTargetingExclude].some(
      (o) => o.node.urn === detailedTargeting.node.urn
    );
  }

  protected onCloseClick(type: Base.IncludeOrExcludeType, index: number) {
    if (type === 'inclusions') {
      this.detailedTargetingInclude.splice(index, 1);
    } else {
      this.detailedTargetingExclude.splice(index, 1);
    }
    this._mapDetailedTargetings();
  }
  protected isActive(detailedTargeting: DetailedTargeting) {
    const hasAddedInclusions = this.detailedTargetingInclude.some((o) => o.node.urn === detailedTargeting.node.urn);
    const hasAddedExclusions = this.detailedTargetingExclude.some((o) => o.node.urn === detailedTargeting.node.urn);
    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'includes' : hasAddedExclusions ? 'excludes' : ''
    } as IDetailedTargetingOverlayItemActive;
  }

  onTabClick(tabName: 'company' | 'education' | 'job' | 'interest') {
    this.activeTab$.next(tabName);
  }

  onIncludeRemove(detailedTargeting: Linkedin.CreateAd.SearchByFacetEdge) {
    this.detailedTargetingInclude = this.detailedTargetingInclude.filter(
      (o) => o.node.urn !== detailedTargeting.node.urn
    );
    this._mapDetailedTargetings();
  }

  onExcludeRemove(detailedTargeting: Linkedin.CreateAd.SearchByFacetEdge) {
    this.detailedTargetingExclude = this.detailedTargetingExclude.filter(
      (o) => o.node.urn !== detailedTargeting.node.urn
    );
    this._mapDetailedTargetings;
  }
}
