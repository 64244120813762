import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { payload: Facebook.CreateAd.IEstimateReachRequest };
type Output = {
  estimateReach: Facebook.CreateAd.IEstimateReachResponse;
};

export const estimateReach = makeQuery<Output, Input>(`
  query EstimateReach($payload: FacebookTargetingInputDto!) {
    facebook {
      estimateReach(targeting: $payload) {
        audienceSizeLowerBound
        audienceSizeUpperBound
        isValid
      }
    }
  }
`);
