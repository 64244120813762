import { ElementRef } from '@angular/core';
import { getTopBarHeight } from '@core/utils/dom';

export function defaultInvalidScroll(el: ElementRef) {
  const invalidControlElements = el.nativeElement.querySelectorAll(
    '[class*="invalid"], [class*="required"]'
  ) as NodeListOf<HTMLElement>;
  let top = 0;
  if (invalidControlElements.length) {
    const invalidControlElement = Array.from(invalidControlElements || []).find((el) => el.tagName !== 'form');
    if (invalidControlElement) {
      top = invalidControlElement.getBoundingClientRect().top + window.scrollY;
    }
  }
  const topBarHeight = getTopBarHeight();
  window.scrollTo({ top: (top || el.nativeElement.offsetTop) - topBarHeight });
}
