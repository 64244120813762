import { makeQuery, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Output = QueryResult<'fetchSmartCampaignId', string>;

export const fetchSmartCampaignIdQuery = makeQuery<Output, SmartCampaign.Backend.SmartCampaignIdInput>(`
query FetchSmartCampaignId($platform: Platform!, $platformCampaignId: String!) {
  ayn {
    fetchSmartCampaignId(
      platform: $platform
      platformCampaignId: $platformCampaignId
    )
  }
}
`);
