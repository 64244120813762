import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { AynNgbModalRef, BingService, capitalize, createUploadImages, UploadImageItem } from '@core/index';
import { LibraryBingComponent } from '@pages/create-ad/components/select-adtype/platforms/bing';

import { IRestError } from '@core/models/base.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BingImageService } from '@pages/create-ad/services/bing-image.service';

interface IdWithUrl {
  id: number;
  url: string;
}

export type PerformanceMaxImageType =
  | 'landscapeImageMedia'
  | 'squareImageMedia'
  | 'imageMedia15X10'
  | 'imageMedia178X100'
  | 'imageMedia133X100';
export const AUDIENCE_ADS_IMAGE_RATIOS: Record<PerformanceMaxImageType, number> = {
  landscapeImageMedia: 1.91,
  squareImageMedia: 1,
  imageMedia15X10: 1.5,
  imageMedia178X100: 1.78,
  imageMedia133X100: 1.33
};

export type PerformanceMaxImages = {
  [key in PerformanceMaxImageType]?: IdWithUrl[];
};

@Component({
  selector: 'aayn-upload-ads-images--bing',
  templateUrl: 'upload-ads-images.component.html'
})
export class UploadAdsImagesBingComponent implements OnInit {
  @Input() protected uploadedImages: PerformanceMaxImages = {
    landscapeImageMedia: [],
    squareImageMedia: [],
    imageMedia15X10: [],
    imageMedia178X100: [],
    imageMedia133X100: []
  } as PerformanceMaxImages;

  @Input() protected images = {
    landscapeImageMedia: createUploadImages(3),
    squareImageMedia: createUploadImages(3),
    imageMedia15X10: createUploadImages(3),
    imageMedia178X100: createUploadImages(3),
    imageMedia133X100: createUploadImages(3)
  };

  protected isValid: boolean = true;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private bingService: BingService,
    private toastrService: ToastrService,
    private bingImageService: BingImageService
  ) {}

  ngOnInit() {
    Object.keys(this.uploadedImages).forEach((key) => {
      const uploadedImageGroup = this.uploadedImages[key];

      if (!uploadedImageGroup.length) return;
      this.images[key].slice(0, uploadedImageGroup.length).forEach((o, i) => {
        o.startedUploading = true;
        o.progressValue = 100;
      });
    });
  }

  openLibrary(type: PerformanceMaxImageType, maxImages: number) {
    const modalRef = this.modalService.open(LibraryBingComponent) as AynNgbModalRef<
      LibraryBingComponent,
      { images: IdWithUrl[] }
    >;
    modalRef.componentInstance.ratio = AUDIENCE_ADS_IMAGE_RATIOS[type];
    modalRef.componentInstance.maxImages = maxImages - (this.uploadedImages[type]?.length || 0);
    modalRef.result.then((result) => {
      if (result?.images?.length) {
        const index = this.images[type].findIndex((o) => !o.completed && !o.startedUploading);
        if (index !== -1) {
          for (let i = index; i < result.images.length + index; i++) {
            this.images[type][i] = new UploadImageItem(true);
            this.uploadedImages![type]![i] = result.images[i - index];
          }
        }
      }
    });
  }

  uploadImage(type: PerformanceMaxImageType, { file, fileUrl }: { file: File; fileUrl?: string }) {
    const image = this.images[type].find((o) => !o.completed && !o.startedUploading);

    this.bingService
      .uploadPerformanceMaxImages$(
        {
          [type]: [file]
        },
        capitalize(type)
      )
      .pipe(
        switchMap((uploadResult) => {
          if (uploadResult.state !== 'DONE') {
            return of({ uploadResult, imageBlob: null });
          }
          return this.bingImageService
            .getImage(uploadResult.result.url)
            .pipe(map((imageBlob) => ({ uploadResult, imageBlob })));
        })
      )
      .subscribe({
        next: ({ uploadResult }) => {
          image!.startedUploading = true;
          image!.progressValue = uploadResult.progress;

          if (!environment.production) console.log('[CreateAd.Bing.UploadResult]: ', uploadResult);

          if (uploadResult.state != 'DONE') return;

          image!.progressValue = 100;
          const index = this.images[type].findIndex((o) => o === image);

          this.uploadedImages[type]![index] = { ...uploadResult.result };
          this.toastrService.success('Image upload successful.', 'Successful');
        },
        error: ({ error }: { error: IRestError }) => {
          if (!(error.message instanceof Array)) {
            this.toastrService.warning(error.message);

            image!.startedUploading = false;
            image!.progressValue = 0;
          }
        }
      });
  }

  remove({ fileUrl }: { fileUrl: string }, type: PerformanceMaxImageType) {
    const index = (this.uploadedImages[type] || []).findIndex((o) => o.url === fileUrl);
    if (index > -1) {
      this.uploadedImages[type] = this.uploadedImages[type]!.filter((_, i) => i !== index);
      this.images[type].forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images[type].length - 1 && this.images[type][i + 1].startedUploading) {
            this.images[type][i] = this.images[type][i + 1];
            this.images[type][i + 1] = new UploadImageItem();
          } else {
            this.images[type][i] = new UploadImageItem();
          }
        }
      });
    }
  }

  saveModal() {
    if (this.isUploadValid('landscapeImageMedia')) {
      this.modal.close({
        images: Object.fromEntries(
          Object.entries(this.uploadedImages).map(([key, value]) => [key, value!.filter((o) => o.id)])
        )
      });
    } else this.isValid = false;
  }

  isUploadValid(type: PerformanceMaxImageType): boolean {
    return this.images[type]?.at(0)?.completed || this.uploadedImages[type]!.length > 0;
  }

  get imageInfo() {
    return {
      landscapeImageMedia: {
        valid: this.isUploadValid('landscapeImageMedia')
      },
      squareImageMedia: {
        valid: this.isUploadValid('squareImageMedia')
      },
      imageMedia15x10: {
        valid: this.isUploadValid('imageMedia15X10')
      },
      imageMedia178X100: {
        valid: this.isUploadValid('imageMedia178X100')
      },
      imageMedia133X100: {
        valid: this.isUploadValid('imageMedia133X100')
      }
    };
  }

  closeModal() {
    this.modal.close({});
  }

  selectedFileChange(index: number, type: PerformanceMaxImageType) {
    this.uploadedImages[type]![index] = {
      id: 0,
      url: ''
    };
  }
}
