import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-header-with-progressbar',
  templateUrl: './header-with-progressbar.component.html'
})
export class HeaderWithProgressbarComponent implements OnInit {
  @Input() title: string = 'Onboarding';

  @Input() description: string = 'Let’s get you started!';

  @Input() icon: string = 'confirmation';

  @Input() progress?: number | null = 0;

  @Input() totalSteps: number = 5;

  constructor() {}

  ngOnInit(): void {}
}
