import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-ad-item-footer',
  template: `
    <section class="aayn-create-ad--item-footer" [style.padding-bottom.px]="noPB ? 0 : 28">
      <ng-content></ng-content>

      <aayn-create-ad--draft-loader></aayn-create-ad--draft-loader>
    </section>
  `
})
export class AdItemFooterComponent implements OnInit {
  /**
   * @description No Padding Bottom
   */
  @Input() noPB = false;

  constructor() {}

  ngOnInit() {}
}
