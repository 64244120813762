import { Platforms } from '@core/models';

import { AdsType } from '../../../shared/modules/select-ad-item';

export enum CreateAnAdSteps {
  Platform = 1,
  Objective,
  Audience,
  AdType,
  ReviewPublish,
  DescribeProject,
  AIGenerated
}

export const CreateAdSteps: Record<CreateAnAdSteps, string> = {
  [CreateAnAdSteps.Platform]: 'Platform',
  [CreateAnAdSteps.Objective]: 'Objective',
  [CreateAnAdSteps.Audience]: 'Audience',
  [CreateAnAdSteps.AdType]: 'Ad Type',
  [CreateAnAdSteps.ReviewPublish]: 'Review and Publish',
  [CreateAnAdSteps.DescribeProject]: 'Describe Project',
  [CreateAnAdSteps.AIGenerated]: 'AI Generated'
};

export enum ObjectiveTypes {
  Empty,
  TrafficToMyWebsite,
  ConversionsOnMyWebsite
}

export enum AudienceTypeGoogle {
  Empty,
  ManualAudienceType
}

export enum AdType {
  Empty,
  DynamicCreativeAds
}

export interface AdCreate {
  selectedPlatform: Platforms;
}

export interface IObjective extends ICreateAdSelectItem<ObjectiveTypes> {}
export interface IAudience extends ICreateAdSelectItem<AudienceTypeGoogle> {}
export interface IAdType extends ICreateAdSelectItem<AdType> {}

export interface ICreateAdSelectItem<T> {
  id: number;
  title: string;
  description: string;
  icon: AdsType;
  empty: boolean;
  active: boolean;
  type?: T;
  disabled?: boolean;
  comingSoonText?: string;
}
