import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Input = Bing.AudienceCampaign.AudienceCriteriaSearchRequest;
type Output = Bing.AudienceCampaign.AudienceCriteriaSearchResponse;

export const audienceCriteriaSearchBing = makeQuery<Output, Input>(`
query AudienceCriteriaSearch(
  $type: BingAudienceType!
  $campaignType: BingSupportedCampaignType!
  $limit: Float
  $searchKey: String
) {
  bing {
    audienceCriteriaSearch(
      limit: $limit
      searchKey: $searchKey
      campaignType: $campaignType
      type: $type
    ) {
      fullName
      id
      networkSize
      searchSize
    }
  }
}

`);
