import { AnimationLoader, LottieModule, LottieComponent as NgxLottieComponent, provideLottieOptions } from 'ngx-lottie';

import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';

function player() {
  return import('lottie-web');
}

@Component({
  selector: 'ayn-lottie',
  templateUrl: 'lottie.component.html',
  standalone: true,
  imports: [CommonModule, LottieModule],
  providers: [
    provideLottieOptions({
      player
    }),
    AnimationLoader
  ]
})
export class LottieComponent extends NgxLottieComponent implements OnInit, OnChanges {
  @Input() name?: string;

  @HostBinding('style')
  @Input()
  style?: string = '';

  @HostBinding('style.max-width.px')
  @Input()
  maxWidth?: number;

  constructor(ngZone: NgZone, @Inject(PLATFORM_ID) platformId: string, animationLoader: AnimationLoader) {
    super(ngZone, platformId, animationLoader);
  }

  ngOnInit() {
    if (this.name) this.setPath(this.name);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.container) super.loadAnimation(changes, this.container.nativeElement);

    if (changes.name) this.setPath(changes.name.currentValue);
  }

  private setPath(name: string) {
    this.options = this.options ?? { path: `/assets/lotties/${name}.json` };
  }
}
