import { Draft } from '@core/models';

import { makeMutation } from '../types';

type Input = { data: Draft.IUpsertAdDraft };
type Output = string;

export const upsertDraft = makeMutation<Output, Input>(`
  mutation UpsertDraft($data: UpsertAdDraftDto!) {
    upsertDraft(data: $data)
  }
`);
