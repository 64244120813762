import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  detailedTargetings: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
};

export const getDetailedTargetingBrowse = makeQuery<Output>(`
  query GetDetailedTargetingBrowse {
    facebook {
      detailedTargetingBrowse {
        id
        name
        type
        path
        audienceSizeLowerBound
        audienceSizeUpperBound
      }
    }
  }
`);
