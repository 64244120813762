<div
  class="aayn-file-upload-item aspect-ratio-{{ aspectRatio }} {{ className }}"
  [ngClass]="{
    'aayn-file-upload-item__selected': selectedFile && progressItem?.completed,
    'aayn-file-upload-item__has-error': isError,
    'aayn-file-upload-item__disabled': disabled,
    'aayn-file-upload-item__aspect-dynamic': isDynamicRatio
  }"
  ayn-drag-drop
  (fileDropped)="inputChange($event)"
  [style.--error-color]="disabled ? 'var(--gray-400)' : isError ? 'var(--orange)' : 'var(--primary)'"
  *ngIf="!isAddMore"
  #imageContainer
>
  <aayn-file-upload-item--border
    [parentElement]="imageContainer"
    *ngIf="!(selectedFileUrl && progressItem?.completed)"
  ></aayn-file-upload-item--border>
  <input
    type="file"
    [disabled]="disabled"
    accept="image/png, image/jpg, image/jpeg{{
      allowVideo ? ', video/mp4, video/quicktime, video/avi, video/mpeg' : ''
    }}"
    (change)="inputChange($event)"
    [id]="id"
    [(ngModel)]="fileModel"
  />

  <img
    [src]="selectedFileUrl | aynSafe: 'url'"
    [alt]="selectedFile?.name"
    *ngIf="selectedFileUrl && progressItem?.completed"
    class="aayn-file-upload-item--selected_image"
    ayn-skeleton-img
  />
  <ng-content *ngIf="selectedFileUrl" select=".aayn-file-upload-item--selected-custom-content"></ng-content>

  <label [for]="id">
    <img
      class="-upload-icon"
      src="/assets/images/svgs/file.svg"
      alt="Upload File"
      *ngIf="!progressItem?.startedUploading"
    />
    <ayn-lottie name="upload" *ngIf="progressItem?.startedUploading"></ayn-lottie>

    <ng-content select=".aayn-file-upload-item--custom-content"></ng-content>

    <span
      class="-upload-text"
      [ngClass]="{
        disabled: disabled
      }"
      >{{ progressItem?.startedUploading ? 'Uploading file' : 'Upload a file' }}</span
    >

    <p class="-or-text" [ngClass]="{ disabled }">or</p>

    <button
      ayn-button="primary"
      icon="canva"
      [icon-direction]="canvaIconDirection"
      [disabled]="disabled"
      ayn-mini-button
      class="-canva-btn"
      (click)="openCanva()"
      *ngIf="canvaVisible && !progressItem?.startedUploading"
      type="button"
    >
      {{ 'Design with Canva' | translate }}
    </button>

    <ng-content select=".aayn-file-upload-item--custom-content-footer"></ng-content>

    <ng-container *ngIf="progressItem?.startedUploading">
      <span class="aayn-file-upload-item--upload-progress">{{ progressItem?.progressValue }}%</span>

      <ayn-progressbar [value]="progressItem?.progressValue || 0" [size]="'millennium'"></ayn-progressbar>
    </ng-container>
  </label>

  <ng-container [ngTemplateOutlet]="selectedFile && canRemoveImage ? hoverTemplate : null"></ng-container>
</div>
<div
  class="aayn-file-upload-item aayn-file-upload-item--add-more aspect-ratio-{{ aspectRatio }} {{ className }}"
  (click)="addMoreClick.emit()"
  *ngIf="isAddMore"
>
  <ayn-icon name="plus" class="aayn-file-upload-item--add-more-icon"></ayn-icon>
</div>
<ayn-icon
  name="circle-star"
  [style.--error-color]="disabled ? 'var(--gray-400)' : isError ? 'var(--orange)' : 'var(--primary)'"
  class="aayn-file-upload-item--primary-icon"
  *ngIf="isPrimary"
></ayn-icon>

<div
  class="aayn-file-upload-item--trash-icon"
  *ngIf="canRemove && !progressItem?.startedUploading"
  (click)="removeEmptyClick.emit()"
>
  <ayn-icon name="trash-flat"></ayn-icon>
</div>

<ng-template #hoverTemplate>
  <div class="aayn-file-upload-item:hover" (click)="removeSelectedFile()">
    <button
      ayn-button="btn--only-icon"
      class="btn--only-icon ayn-file-upload--image--hover_remove-btn"
      icon="trash-flat"
    ></button>
  </div>
</ng-template>
