/**
 * Finds a form element and request submit in the given element.
 * @param el {HTMLElement}
 */
// TODO: move this logic to a directive
export function findAndRequestSubmit(el: HTMLElement) {
  const forms = el.querySelectorAll<HTMLFormElement>('form');

  Array.from(forms || [])
    .filter((form) => {
      const attr = form.attributes.getNamedItem('standAloneForm');
      return !attr || attr.value === 'false';
    })
    .forEach((form) => {
      form?.requestSubmit();
    });
}
