import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-existing-post-list-item',
  templateUrl: 'post-item.component.html'
})
export class ExistingPostListItemComponent implements OnInit {
  @Input() message?: string;
  @Input() imageUrl?: string;
  @Input() shares?: number;
  @Input() likes?: number;
  @Input() comments?: number;
  @Input() date?: Date;
  @Input() type?: string;

  @Input() active?: boolean;

  constructor() {}

  ngOnInit() {}
}
