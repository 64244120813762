import { ICreateBusinessBrandRequest, ICreateBusinessBrandResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = ICreateBusinessBrandRequest;
type Output = ICreateBusinessBrandResponse;

export const createBusinessBrand = makeMutation<Output, Input>(`
mutation CreateBusinessBrand(
  $brand: BusinessBrandInput!
  $businessId: String!
) {
  createBusinessBrand(brand: $brand, businessId: $businessId) {
    brandUrl
    color1
    color2
    id
  }
}

`);
