import { HttpContext, HttpContextToken } from '@angular/common/http';

export const SUSPEND_ERRORS = new HttpContextToken(() => false);

export function getHttpContexts<T>(value: T, injectionToken: HttpContextToken<T>) {
  return new HttpContext().set(injectionToken, value);
}

export const SUSPEND_ERROS_CONTEXT = getHttpContexts(false, SUSPEND_ERRORS);

export const PURE_REQUEST = new HttpContextToken(() => false);
