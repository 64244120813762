<div class="aayn-ad-preview--card" [@fadeInOnEnter] [ngClass]="className">
  <header class="aayn-ad-preview--card-header">
    <div class="aayn-ad-preview--card-header__container">
      <h5 class="aayn-ad-preview--card-header__title">{{ 'Preview your ads' | translate }}</h5>
      <p class="aayn-ad-preview--card-header__description">
        {{ 'See a preview of your ads on selected platform(s).' | translate }}
      </p>
    </div>

    <ayn-form-field class="d-inline-block w-auto">
      <ayn-select
        [setValueHtml]="true"
        [showTick]="false"
        [minWidth]="202"
        [placeholder]="'Search platform' | translate"
        [(ngModel)]="selectedPlatform"
        [readonly]="platforms.length < 2"
        (ngModelChange)="selectedPlatformChanged($event)"
        *ngIf="platforms"
      >
        <ayn-option
          *ngFor="let item of platforms | aynOptionFilter | async"
          [value]="item.platform"
          [title]="item.label"
        >
          <ayn-icon [name]="item.icon"></ayn-icon>
          {{ item.label }}
        </ayn-option>
      </ayn-select>
    </ayn-form-field>
  </header>

  <main class="aayn-ad-preview--card-main">
    <ng-content></ng-content>
  </main>

  <footer class="aayn-ad-preview--card-footer">
    <button
      (click)="toPng()"
      ayn-button="only-icon"
      icon="download"
      type="button"
      class="aayn-ad-preview--card-footer-button"
    ></button>
  </footer>
</div>
