import { Component, OnInit } from '@angular/core';
import { Bing } from '@core/models';
import { ICreateAdSelectItem } from '@pages/create-ad/models';

import { AdPreviewBingService } from './components';
import { LengthCalculator, provideLengthCalculator } from '@ayn-ui/lib/modules';
import { eachChar, Unicode } from '@core/utils';

export const BING_INPUT_LENGTH_CALCULATOR: LengthCalculator = (val: string) => {
  if (!val.length) {
    return 0;
  }
  let count = 0;
  eachChar(val, (char) => {
    count += Unicode.isCJK(char) ? 2 : 1;
  });

  return count;
};

@Component({
  selector: 'aayn-select-ad-type--bing',
  templateUrl: 'select-ad-type-content.component.html',
  providers: [AdPreviewBingService, provideLengthCalculator(BING_INPUT_LENGTH_CALCULATOR)]
})
export class SelectAdTypeContentBingComponent implements OnInit {
  protected adTypes: ICreateAdSelectItem<Bing.AdType>[] = [
    {
      id: 1,
      title: 'Microsoft Audience Ads',
      description: 'The newest and the best Bing Ads format that includes Search  Ads.',
      icon: 'dynamic',
      empty: false,
      active: true,
      type: Bing.AdType.PerformanceMax
    },
    {
      id: 2,
      title: '',
      description: '',
      icon: 'carousel',
      empty: true,
      active: false,
      type: undefined
    },
    {
      id: 3,
      title: '',
      description: '',
      icon: 'existing-post',
      empty: true,
      active: false,
      type: undefined
    }
  ];

  protected AdTypes = Bing.AdType;

  protected selectedAdType: Bing.AdType = Bing.AdType.PerformanceMax;

  constructor() {}

  ngOnInit() {}

  adTypeChangeStatus(item: ICreateAdSelectItem<Bing.AdType>) {
    this.adTypes.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedAdType = item.type!;
      } else {
        x.active = false;
      }
    });
  }
}
