import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import * as Query from '@core/queries';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';

import { Draft, GraphQLService } from '../';

@Injectable({ providedIn: 'root' })
export class DraftService {
  @Select(LoaderState.getAny('GetDraft')) getDraftLoader$?: Observable<boolean>;

  cachedDrafts: Draft.IDraftItem[] = [];

  constructor(private gqlService: GraphQLService) {}

  getDrafts$(request: Draft.IGetDraftsRequest) {
    return this.gqlService.get(Query.getDrafts(request)).pipe(tap((o) => (this.cachedDrafts = o.data)));
  }

  getDraftById$(id: string) {
    return this.gqlService.get(Query.getDraft({ id }));
  }

  upsertDraft$(data: Draft.IUpsertAdDraft | Draft.IDraftItemForUpdate) {
    return this.gqlService.post(Query.upsertDraft({ data }));
  }

  deleteDraft$(id: string) {
    return this.gqlService.post(Query.deleteDraft({ id }));
  }
}
