<section class="aayn-extensions-youtube-modal">
  <header class="aayn-extensions-youtube-modal--header">
    <ayn-icon name="circle-check-list"></ayn-icon>

    <div class="aayn-extensions-youtube-modal--header__content">
      <h4>{{ 'YouTube Video Extension' | translate }}</h4>
      <p>{{ 'Add a custom YouTube Video to your ad' | translate }}</p>
    </div>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <section>
    <form [formGroup]="youtubeExtensionForm" (ngSubmit)="onSubmit()">
      <div class="aayn-extensions-youtube-modal--form">
        <section class="aayn-ad-type--control">
          <ayn-input-with-button>
            <label>YouTube URL </label>
            <input type="text" formControlName="youtubeUrl" placeholder="https://www.youtube.com/c/ADYOUNEED" />
            <button ayn-button="primary" [disabled]="youtubeLinks?.length == 5" type="button" (click)="youtubeSearch()">
              {{ 'Add' | translate }}
            </button>
          </ayn-input-with-button>
        </section>

        <div class="aayn-extensions-youtube-modal--form__item" *ngFor="let item of youtubeLinks; let index = index">
          <img [src]="item.thumbnails.default.url" onerror="this.src='/assets/images/svgs/placeholder.svg';" alt="" />
          <span>{{ item.description }}</span>
          <button ayn-button [onlyIcon]="true" (click)="deleteItem(index)" icon="exit"></button>
        </div>

        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="70">
          <input type="text" ayn-input formControlName="name" [placeholder]="'Extension name' | translate" />
        </ayn-form-field>
      </div>

      <button
        ayn-button="primary"
        class="aayn-extensions-youtube-modal--button"
        ayn-button-flex
        type="submit"
        [disabled]="youtubeExtensionForm.invalid"
        (click)="onSubmit()"
      >
        {{ 'Save YouTube Video Extension' | translate }}
      </button>
    </form>
  </section>
</section>
