import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.ICampaignRequest;
type Output = Bing.Dashboard.ICampaignResponse;

export const campaignsWithMetricsBing = makeMutation<Output, Input>(`
query CampaignsWithMetricsBing(
  $skip: Int
  $take: Int
  $searchKey: String
  $startDate: SafeInt
  $endDate: SafeInt
  $status: BingInsightStatus
  $datePreset: DatePreset
) {
  bing {
    campaignsWithMetrics(
      skip: $skip
      take: $take
      startDate: $startDate
      endDate: $endDate
      searchKey: $searchKey
      status: $status
      datePreset: $datePreset
    ) {
      edges {
        node {
          isSmartCampaign
          name
          id
          status
          campaignType
          startDate
          endDate
          dailyBudget
          clicks
          impressions
          costPerClick
          conversions
          costPerConversion
          revenue
          spend
          ctr
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}
`);
