import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, EventEmitter, Input, isDevMode, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CardFieldDirective } from '@chargebee/chargebee-js-angular-wrapper';
import {
  CHARGEBEE_COMPONENT_CLASS,
  CHARGEBEE_COMPONENT_FONTS,
  CHARGEBEE_COMPONENT_STYLES,
  COUNTRIES,
  Country
} from '@core/constants';
import {
  ChargebeeClientService,
  ChargebeeService,
  ChargebeeState,
  GetMySubscription,
  IChargebeeAdditionalData
} from '@core/index';
import { ExternalPackage, IPackage, MySubscription, PaymentIntent } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { environment } from '@environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { runIsDev } from '@shared/utils';

import { BaseAsyncComponent } from '../base';
import { IpApiService } from '@core/services/ip-api.service';

@Component({
  selector: 'aayn-payment-form',
  templateUrl: 'payment-form.component.html'
})
export class PaymentFormComponent extends BaseAsyncComponent implements OnInit {
  @Input() buttonType: 'white-sexy' | 'primary' = 'white-sexy';

  @Input() isRouteAfterSuccess = true;

  @Input() submitButtonProps = {
    text: 'Complete Registration',
    icon: undefined as string | undefined
  };

  @Input() showAppsumoInput = true;

  @Output() appliedAppsumoCode = new EventEmitter();

  @Output() onSuccess = new EventEmitter<void>();

  showCreditCardForm = true;

  countries: Country[] = COUNTRIES;

  paymentForm = new FormGroup({
    appsumoCode: new FormControl(''),

    companyName: new FormControl<string>(''),
    cardHolderName: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(99)
    ]),
    country: new FormControl<string>('')
  });

  errorMessage = '';
  cardComponent?: CardFieldDirective;

  errors = {};

  classes = CHARGEBEE_COMPONENT_CLASS;

  fonts = CHARGEBEE_COMPONENT_FONTS;

  styles = CHARGEBEE_COMPONENT_STYLES;

  appliedCodes: string[] = [];

  get isAppliedAppsumoCode() {
    return !!this.appliedCodes.length;
  }

  subscription?: MySubscription | null;

  @Select(LoaderState.getAny(['ApplyAppsumoCoupon', 'SubscribeToPlan'])) applyAppsumoCodeLoader$?: Observable<boolean>;

  cbLoader = true;

  private _packageName = ExternalPackage.PremiumUSDMonthly;

  private _packages?: IPackage[];

  constructor(
    private cdr: ChangeDetectorRef,
    private chargebeeClientService: ChargebeeClientService,
    private router: Router,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService,
    private store: Store,
    private route: ActivatedRoute,
    private ipApiService: IpApiService,
    @Optional() private activeModal: NgbActiveModal
  ) {
    super();

    this.route.queryParams.subscribe((params) => {
      if (Object.values(ExternalPackage).includes(params['package'])) {
        this._packageName = params['package'];
      }
    });
  }

  ngOnInit() {
    this.subscription = this.store.selectSnapshot(ChargebeeState.subscription);

    this.setCountryCode();

    this.chargebeeService.getAllPackages$().subscribe((result) => {
      this._packages = result.data;
    });
  }

  onReady = (cardComponent) => {
    this.cardComponent = cardComponent;

    this.cbLoader = false;

    this.cdr.detectChanges();
  };

  setFocus(field) {
    field.focus();
  }

  onChange = (status) => {
    let errors = {
      ...this.errors,
      [status.field]: status.error
    };

    this.errors = errors;

    let messageObj =
      Object.values(errors)
        .filter((message) => !!message)
        .pop() || {};

    if (isDevMode()) {
      console.log(messageObj);
    }

    this.errorMessage = messageObj as any;

    this.cdr.detectChanges();
  };

  onSubmitChargebee(event: Event) {
    if (this.isAppliedAppsumoCode) {
      event.preventDefault();

      this.onSuccess.emit();

      if (this.isRouteAfterSuccess) {
        this.router.navigateByUrl('/main/business/create');
      } else {
        this.activeModal?.close();
      }

      return;
    }

    if (!this.cardComponent || this.paymentForm.invalid) return;

    event.preventDefault();

    this.setLoading(true);

    const { cardHolderName, companyName, country } = this.paymentForm.value;

    const splipttedHolderName = cardHolderName?.split(' ');

    const firstName = splipttedHolderName?.slice(0, splipttedHolderName?.length - 1).join(' ');
    const lastName = splipttedHolderName?.at(-1);

    const additionalData = {
      firstName,
      lastName,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      stateCode: '',
      zip: '',
      countryCode: country?.toUpperCase() || ''
    } as IChargebeeAdditionalData;

    (this.cardComponent.tokenize(additionalData) as Promise<any>)
      .then(async () => {
        let paymentIntent: PaymentIntent;

        const authorizeWith3ds = () =>
          this.chargebeeClientService.chargebeeInstance
            ?.load3DSHandler()
            .then(async (handler) => {
              handler.setPaymentIntent(paymentIntent as unknown as IChargebeePaymentIntent);

              runIsDev(() => {
                console.log(paymentIntent);
              });

              this.cardComponent?.authorizeWith3ds(paymentIntent as any, additionalData as any, {
                success: (intent) => {
                  runIsDev(() => {
                    console.log('authorizeWith3ds:intent', intent);
                  });

                  this.chargebeeService
                    .createPaymentSourceFromPaymentIntent$({
                      id: intent.id
                    })
                    .subscribe((result) => {
                      if (result.data) {
                        let selectedPackage = this._packages?.find((o) => o.externalId === this._packageName);

                        if (environment.name == 'dev' || environment.name == 'testing') {
                          selectedPackage = this._packages?.at(0);
                        }

                        if (!selectedPackage) {
                          console.log(
                            `❌ Selected Package is not found, please check the package name: ${this._packageName}`
                          );

                          return;
                        }

                        this.chargebeeService.subscribeToPlan$(selectedPackage.id).subscribe(() => {
                          if (companyName?.length) {
                            this.chargebeeService.updateBillingInfo$({ companyName }).subscribe();
                          }

                          this.store.dispatch(new GetMySubscription()).subscribe(() => {
                            this.onSuccess.emit();

                            if (this.isRouteAfterSuccess) {
                              this.router.navigateByUrl('/main/business/create');
                            } else {
                              this.activeModal?.close();
                              this.router.navigateByUrl('/main/dashboard');
                            }
                            this.setLoading(false);
                          });
                        });
                      }
                    });
                },
                error: (intent) => {
                  runIsDev(() => {
                    console.log('authorizeWith3ds:error', intent);
                  });

                  if (intent.active_payment_attempt?.error_text) {
                    const errorText = intent.active_payment_attempt?.error_text?.split(';').at(0);

                    if (errorText) {
                      this.toastrService.error(errorText);
                    }
                  }

                  this.setLoading(false);
                }
              });
            })
            .catch((err) => {
              if (err?.message) {
                this.toastrService.error(err?.message);
              }

              console.error(err);
            });

        const createPaymentIntent = this.chargebeeService.createPaymentIntent$(0, 'USD');

        createPaymentIntent.subscribe({
          next(result) {
            if (result.error) throw result.error;

            paymentIntent = result.data;

            authorizeWith3ds();
          },
          error(err) {
            runIsDev(() => {
              console.log(`Auth.PaymentComponent:CreatePaymentIntent`, err);
            });

            createPaymentIntent.subscribe({
              next(result) {
                if (result.error) throw result.error;

                paymentIntent = result.data;

                authorizeWith3ds();
              },
              error(error) {
                throw error;
              }
            });
          }
        });
      })
      .catch((err) => {
        if (err?.message) {
          this.toastrService.error(err?.message);
        }

        console.error(err);

        this.setLoading(false);
      });
  }

  applyAppSumoCode() {
    if (this.appliedCodes.length > 7) return;

    const code = this.paymentForm.value.appsumoCode;

    if (!code?.length) return;

    const updateAppsumoStateAndForm = () => {
      this.appliedCodes.push(this.paymentForm.value.appsumoCode!);

      this.paymentForm.controls.cardHolderName.clearValidators();
      this.paymentForm.controls.cardHolderName.updateValueAndValidity();

      this.paymentForm.controls.companyName.clearValidators();
      this.paymentForm.controls.companyName.updateValueAndValidity();

      this.paymentForm.controls.country.clearValidators();
      this.paymentForm.controls.country.updateValueAndValidity();

      this.paymentForm.controls.appsumoCode.setValue('');

      this.paymentForm.controls.appsumoCode.updateValueAndValidity();
    };

    const successFn = () => {
      this.toastrService.success('The code you entered has been successfully applied.', 'Successfully applied.');

      updateAppsumoStateAndForm();

      this.appliedAppsumoCode.emit();

      this.showCreditCardForm = false;
    };

    if (!this.subscription) {
      let selectedPackage = this._packages?.find((o) => o.externalId == this._packageName);
      
      runIsDev(() => {
        selectedPackage = this._packages?.find((o) => o.externalId == 'ayn-package-2-USD-Monthly');
      });

      if (!selectedPackage) return;

      this.chargebeeService.subscribeToPlan$(selectedPackage.id, code).subscribe({
        next: () => {
          this.chargebeeService.applyAppsumoCoupon$(this.paymentForm.value.appsumoCode!).subscribe((result) => {
            if (result.data) {
              successFn();

              this.store.dispatch(new GetMySubscription());
            } else {
              this.toastrService.error('The code you entered could not be applied successfully. Please check.');
            }
          });
        },

        error: () => {
          this.toastrService.error('The code you entered could not be applied successfully. Please check.');
        }
      });
    } else {
      this.chargebeeService.applyAppsumoCoupon$(this.paymentForm.value.appsumoCode!).subscribe((result) => {
        if (result.data) {
          successFn();
        } else {
          this.toastrService.error('The code you entered could not be applied successfully. Please check.');
        }
      });
    }
  }

  private setCountryCode() {
    this.ipApiService.getCountryCode().subscribe((countryCode) => {
      this.paymentForm.patchValue({ country: countryCode.toLowerCase() });
    });
  }
}
