import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-detailed-targeting-overlay-list',
  template: `
    <ul class="aayn-audience--detailed-targeting--options">
      <ng-content select="aayn-detailed-targeting-overlay-item"></ng-content>
    </ul>
  `
})
export class DetailedTargetingOverlayListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
