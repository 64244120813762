import { IOption } from '@ayn-ui/public-api';
import { Platforms, TikTok } from '@core/models';

import { Bing } from './bing';
import { Facebook } from './facebook';
import { Google } from './google';
import { Linkedin } from './linkedin';
import { AdAccount } from '@core/state';

type HasOwner<T = number> = { owner?: T };
export type FacebookAdAccount = Facebook.IAdAccount & { source?: Platforms.Meta };
export type GoogleAdAccount = Google.IGoogleAdAccountOutput & HasOwner & { source?: Platforms.Google };
export type BingAdAccount = Bing.Business.IBingAdAccountOutput & HasOwner & { source?: Platforms.Bing };
export type LinkedinAdAccount = Omit<Linkedin.Business.LinkedInAdAccountOutput, 'id'> & { source?: Platforms.LinkedIn };
export type TikTokAdAccount = TikTok.Business.TikTokAdAccountOutputDto &
  HasOwner<string> & { source?: Platforms.TikTok };

export type PlatformAdAccount =
  | FacebookAdAccount
  | GoogleAdAccount
  | BingAdAccount
  | LinkedinAdAccount
  | TikTokAdAccount;

export type PlatformAdAccountMap<T extends AdAccount.AdAccountPlatforms> = {
  [Platforms.Meta]: FacebookAdAccount;
  [Platforms.Google]: GoogleAdAccount;
  [Platforms.Bing]: BingAdAccount;
  [Platforms.LinkedIn]: LinkedinAdAccount;
  [Platforms.TikTok]: TikTokAdAccount;
}[T];

/**
 * Can add platforms ad accounts model.
 */
export type IAdAccountType<T extends PlatformAdAccount = PlatformAdAccount> = T & {
  id?: string;
  selected?: boolean;
};

export type ConnectAdAccount = {
  adAccountId: string;
} & (
  | { platform: Platforms.Meta; pageId: string; instagramAccountId: string }
  | { platform: Platforms.TikTok; pageId: string; loginId: string }
  | { platform: Platforms.Google | Platforms.Bing | Platforms.LinkedIn; loginId: string }
);

export type ConnectAdAccountMap<T extends AdAccount.AdAccountPlatforms = AdAccount.AdAccountPlatforms> = {
  adAccountId: string;
} & {
  [Platforms.Meta]: { pageId: string; instagramAccountId: string };
  [Platforms.TikTok]: { pageId?: string; loginId?: string };
  [Platforms.Google]: { loginId: string };
  [Platforms.Bing]: { loginId: string };
  [Platforms.LinkedIn]: { loginId?: string };
}[T];

export module Base {
  export module Business {
    export interface ConnectedAdAccountOutputDto {
      businessId: string;
      connectedAdAccountId: string;
      platform: Platforms;
    }

    export interface ConnectAdAccountDto {
      contextId: string;
      adAccountId: string;
      loginId?: string;
      pageId?: string;
    }
  }

  export enum BreakdownEnums {
    Age,
    Gender,
    Region,
    Country
  }

  export type AspectRatio =
    | '1:1'
    | '4:5'
    | '1.91:1'
    | '4:1'
    | '1.5:1'
    | '1.78:1'
    | '1.33:1'
    | '1:1.91'
    | '1:3'
    | '9:16';

  export interface IBreakdownOption<T extends any> {
    label: string;
    value: T;
  }

  export interface IPageInfo {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  }

  export interface CursorPageInfo extends IPageInfo {
    startCursor: string;
    endCursor: string;
  }

  export interface OffsetPageInfo extends IPageInfo {
    from: number;
    to: number;
    size: number;
  }

  export interface IPaginationRequest {
    first?: number;
    after?: number;
    last?: number;
    before?: string;
    searchKey?: string;
  }

  export module Dashboard {
    export module Global {
      export enum InsightDatePreset {
        Today = 'Today', // Today, since 12:00 AM in your account's time zone.
        Maximum = 'Maximum', // From the start
        LastYear = 'LastYear', // The complete previous year, starting on January 1 at 12:00 AM and ending on December 31 at 11:59 PM.
        ThisYear = 'ThisYear', // The current year, beginning on January 1 and including today.
        Yesterday = 'Yesterday', // Yesterday, the 24-hour period between 12:00 AM and 11:59 PM in your account's time zone.
        ThisMonth = 'ThisMonth', // The current month, beginning on the first day and including today.
        LastMonth = 'LastMonth', // The complete previous month, starting on the first day and ending on the last of the month.,
        LastThreeDays = 'LastThreeDays', // The complete previous 3 days, ending at 11:59 PM last night and not including today.
        LastSevenDays = 'LastSevenDays', // The complete previous 7 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
        LastThirtyDays = 'LastThirtyDays', // The complete previous 30 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
        LastNinetyDays = 'LastNinetyDays', // The complete previous 90 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
        LastFourteenDays = 'LastFourteenDays', // The complete previous 14 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
        ThisQuarter = 'ThisQuarter', // The current quarter, beginning on the first day of the first month of the calendar-year quarter and including today.
        LastTwentyEightDays = 'LastTwentyEightDays', // The complete previous 28 days, ending at 11:59 PM last night (in your account's time zone) and not including today.
        LastWeekMonSun = 'LastWeekMonSun', // The complete 7-day period starting on the previous Monday and ending on the most recent Sunday.
        LastWeekSunSat = 'LastWeekSunSat', // The complete 7-day period starting on the previous Sunday and ending on the most recent Saturday.
        ThisWeekSunToday = 'ThisWeekSunToday', // The current week, beginning on the most recent Sunday and including today.
        ThisWeekMonToday = 'ThisWeekMonToday', // The current week, beginning on the most recent Monday and including today.
        ThisWeek = 'ThisWeek', // for google
        LastWeek = 'LastWeek', // for google
        LifeTime = 'LifeTime', // Ayn ui DatePreset
        LastOneHundredEightyDays = 'LastOneHundredEightyDays', // Ayn ui DatePreset
        LastTwelveMonths = 'LastOneHundredEightyDays' // Ayn ui DatePreset
      }

      export enum InsightInterval {
        Date = 'Date',
        Week = 'Week',
        Month = 'Month'
      }

      export interface IStartEndDateRequest {
        start?: Date;
        end?: Date;
        datePreset?: InsightDatePreset;
        interval?: InsightInterval;
        first?: number;
        after?: string;
        last?: number;
        before?: string;
        name?: string;
      }

      export interface IDateRangeInput {
        start: Date;
        end: Date;
      }

      export interface ICampaignTable<T = ICampaignTableData, P extends IPageInfo = IPageInfo> {
        pageInfo: P;
        totalCount: number;
        size?: number;
        edges: T[];
      }

      export interface ICampaignTableData {
        type: string;
        status: string;
        statusDisabled?: boolean;
        name: string;
        details: string;
        budget: number | string;
        budgetId?: number | string;
        budgetType: string;
        startDate: string | Date;
        endDate: string | Date;
        result: string | number;
        impressions: string | number;
        clicks: string | number;
        cpc: string | number;
        spend: string | number;
        resourceName?: string;

        id: string;
        checked?: boolean;
        checkedStatus?: boolean;
        resourceType?: string;
        isSmartCampaign?: boolean;
      }

      type IAdGroupTableData = ICampaignTableData & {
        displayAd: Google.Dashboard.IGoogleDisplayAdOutputDto;
        videoAd: Google.Dashboard.IGoogleVideoAdOutputDto;
        searchAd: Google.Dashboard.IGoogleSearchAdOutputDto;
        adType: Google.GoogleAdType | Bing.BingAdTypeResult;
        productAd: Bing.Dashboard.BingPromotionRelatedAd;
        hotelAd: Bing.Dashboard.BingPromotionRelatedAd;
        appInstallAd: Bing.Dashboard.BingAppInstallAd;
        expandedTextAd: Bing.Dashboard.BingExpandedTextAd;
        dynamicSearchAd: Bing.Dashboard.BingDynamicSearchAd;
        responsiveAd: Bing.Dashboard.BingResponsiveAd;
        responsiveSearchAd: Bing.Dashboard.BingResponsiveSearchAd;
        finalUrls: string[];
      };

      export interface IConversion {
        label: string;
        value: string;
      }

      export interface IAccountBreakdownViewTable {
        breakdownKey: string;
        impressions: number;
        clicks: number;
        costPerClick: number;
        clickRate: number;
        totalSpend: number;
        reach: number;
        costPerReach: number;
      }

      export enum AccountOverviewShowType {
        Number,
        Currency,
        Percentage
      }

      export enum AccountOverviewType {
        Clicks = 'clicks',
        ClickRate = 'click_rate',
        CPC = 'costPerClick',
        CPM = 'averageCost1KImpressions',
        CPP = 'averageCost1KReach',
        CTR = 'ctr',
        Impressions = 'impressions',
        Reach = 'reach',
        Spend = 'spend'
      }

      export const AccountOverviewTypes: IOption[] = [
        { label: 'Clicks', value: 'clicks' },
        { label: 'Click Rate', value: 'clicks_rate' },
        { label: 'CPC', value: 'cpc' },
        { label: 'CPM', value: 'cpm' },
        { label: 'CPP', value: 'cpp' },
        { label: 'CTR', value: 'ctr' },
        { label: 'Impressions', value: 'impressions' },
        { label: 'Reach', value: 'reach' },
        { label: 'Total Spend', value: 'spend' }
      ];

      export interface IAccountOverviewType {
        label: string;
        type: string;
        color?: string;
        showType: AccountOverviewShowType;
        value: number;
      }
    }
  }

  export module CreateAd {
    export enum ImageType {
      Square = 'Square',
      Landscape = 'Landscape',
      Portrait = 'Portrait',
      Vertical = 'Vertical',
      Horizontal = 'Horizontal'
    }
    export interface ImageDimension {
      width: number;
      height: number;
    }

    export interface ImageAssetFilter {
      dimension?: ImageDimension;
      ratio?: number;
      type?: ImageType;
    }
  }

  export type IncludeOrExcludeType = 'inclusions' | 'exclusions';
}

export interface IdWithUrl<T extends string | number = number> {
  id: T;
  url: string;
}
export interface IdWithUrlGlobal<T extends string | number = number> extends IdWithUrl<T> {
  globalId: string;
}
