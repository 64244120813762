import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { PURE_REQUEST } from '@core/interceptors';

@Injectable({ providedIn: 'root' })
export class IpApiService {
  constructor(private httpClient: HttpClient) {}

  getCountryCode() {
    const context = new HttpContext().set(PURE_REQUEST, true);
    return this.httpClient.get('https://ipapi.co/country_code/', { responseType: 'text', context });
  }
}
