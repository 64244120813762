import { of } from 'rxjs';
import { skipUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TikTok } from '@core/models';
import { DraftService } from '@core/services/draft.service';
import {
  TiktokFormService,
  TiktokStateService,
  TiktokValidationService
} from '@pages/create-ad/state/platforms/tiktok';
import { LabelType, Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'aayn-manual-audience--tiktok',
  templateUrl: './manual-audience.component.html'
})
export class ManualAudienceTiktokComponent implements OnInit {
  protected adCreationModel = this.tiktokStateService.adCreationModel;

  form = new FormGroup(
    {
      name: new FormControl(this.adCreationModel.audience?.name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]),
      minAge: new FormControl(this.adCreationModel.audience?.minAge, [Validators.required]),
      maxAge: new FormControl(this.adCreationModel.audience?.maxAge, [Validators.required]),
      male: new FormControl(this.adCreationModel.audience?.male, [Validators.required]),
      female: new FormControl(this.adCreationModel.audience?.female, [Validators.required]),
      languages: new FormControl(this.adCreationModel.audience?.languages),
      locations: new FormControl(this.adCreationModel.audience?.locations, [Validators.required]),
      includedCustomAudiences: new FormControl(this.adCreationModel.audience?.includedCustomAudiences),
      excludedCustomAudiences: new FormControl(this.adCreationModel.audience?.excludedCustomAudiences),
      interests: new FormControl(this.adCreationModel.audience?.interests),
      keywords: new FormControl(this.adCreationModel.audience?.keywords)
    },
    (control) => {
      const { interests } = control.value;
      return interests.length ? null : { required: true };
    }
  );

  ageOptions: Options = {
    translate: (value: number, label: LabelType) => {
      switch (label) {
        case LabelType.Low:
          return `<span>Age</span>&nbsp; ${value}`;
        case LabelType.High:
          return `&nbsp;<span>Age</span>&nbsp; ${value === 55 ? '55+' : value}`;
        default:
          return `+${value}`;
      }
    }
  };
  formatLabel(value: number, label: LabelType.Low | LabelType.High) {
    return value === 55 && label === LabelType.High ? '55+' : value.toString();
  }

  constructor(
    private tiktokStateService: TiktokStateService,
    private tiktokValidationService: TiktokValidationService,
    private tiktokFormService: TiktokFormService,
    private draftService: DraftService
  ) {}

  ngOnInit() {
    this.tiktokFormService.registerForm(this.form);

    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        languages: o.audience.languages,
        male: o.audience.male,
        female: o.audience.female,
        maxAge: o.audience.maxAge,
        minAge: o.audience.minAge,
        locations: o.audience.locations,
        includedCustomAudiences: o.audience.includedCustomAudiences,
        excludedCustomAudiences: o.audience.excludedCustomAudiences,
        interests: o.audience.interests
      });

      this.tiktokValidationService.audienceValid$.next(this.form.status === 'VALID');
    });

    this.form.valueChanges.pipe(skipUntil(this.draftService.getDraftLoader$ || of())).subscribe((o) => {
      this.adCreationModel!.audience = {
        languages: o.languages,
        male: o.male,
        female: o.female,
        maxAge: o.maxAge,
        minAge: o.minAge,
        locations: o.locations,
        includedCustomAudiences: o.includedCustomAudiences,
        excludedCustomAudiences: o.excludedCustomAudiences,
        interests: o.interests,
        keywords: o.keywords,
        name: o.name
      } as TikTok.CreateAd.Client.TikTokCampaignAudienceDto;
      this.tiktokValidationService.audienceValid$.next(this.form.status === 'VALID');
    });
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    this.form!.patchValue({ minAge: min, maxAge: max });
  }
}
