import { Directive, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { EMPTY } from 'rxjs';

@Directive({
  selector: '[formGroup]'
})
export class FormRegisterDirective implements OnDestroy {
  constructor(private formGroupDirective: FormGroupDirective, private formRegisterService: FormRegisterService) {
    this.formRegisterService.registerForm(this.formGroupDirective);
  }

  ngOnDestroy() {
    this.formRegisterService.unregisterForm(this.formGroupDirective);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormRegisterService {
  forms: FormGroupDirective[] = [];

  registerForm(formGroupDirective: FormGroupDirective) {
    this.forms.push(formGroupDirective);
  }

  unregisterForm(formGroupDirective: FormGroupDirective) {
    this.forms = this.forms.filter((f) => f !== formGroupDirective);
  }

  isFormSubmitted(form?: FormGroup) {
    if (!form) {
      return false;
    }
    const formGroupDirective = this.forms.find((f) => f.form === form);
    return formGroupDirective?.submitted;
  }

  onSubmit$(form?: FormGroup) {
    if (!form) {
      return EMPTY;
    }
    const formGroupDirective = this.forms.find((f) => f.form === form);
    return formGroupDirective?.ngSubmit.asObservable() || EMPTY;
  }
}
