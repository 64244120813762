import { makeMutation } from '../types';

type Input = {
  email: string;
  name: string;
  surname: string;
};

type Output = boolean;

export const inviteUser = makeMutation<Output, Input>(`
 mutation InviteUser($email: String!, $name: String!, $surname: String!) {
  inviteUser(email: $email, name: $name, surname: $surname)
}
`);
