import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Platforms } from '@core/models';
import { IntercomService } from '@core/services';
import { findAndRequestSubmit } from '@core/utils';
import { Select } from '@ngxs/store';
import {
  BingValidationService,
  CreateAdState,
  FacebookValidationService,
  GoogleValidationService,
  SelectedAdType
} from '@pages/create-ad/state';
import { LinkedinValidationService } from '@pages/create-ad/state/platforms/linkedin';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';
import { TiktokValidationService } from '@pages/create-ad/state/platforms/tiktok';

@Component({
  selector: 'aayn-select-objective',
  templateUrl: './select-objective.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectObjectiveComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  @Select(CreateAdState.selectedAdType) selectedAdType$?: Observable<SelectedAdType>;

  @Output() next = new EventEmitter();

  protected Platforms = Platforms;

  constructor(
    private facebookValidationService: FacebookValidationService,
    private gooleValidationService: GoogleValidationService,
    private bingValidationService: BingValidationService,
    private linkedinValidationService: LinkedinValidationService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private el: ElementRef<HTMLElement>,
    private intercomService: IntercomService,
    private tiktokValidationService: TiktokValidationService
  ) {}

  ngOnInit(): void {
    this.intercomService.startTour('CREATE_AD_STEP_2');
  }

  nextStep(selectedPlatform: Platforms) {
    switch (selectedPlatform) {
      case Platforms.Meta:
        if (!this.facebookValidationService.objectiveValid$.value) {
          this.facebookValidationService.objectiveMarkAsTouched$.next(true);

          return;
        }
        break;
    }

    findAndRequestSubmit(this.el.nativeElement);

    if (this.nextStepValidator(selectedPlatform)) this.next.emit();
  }

  nextStepValidator(selectedPlatform: Platforms): boolean {
    switch (selectedPlatform) {
      case Platforms.Meta:
        return this.facebookValidationService.objectiveValid$.value;

      case Platforms.Google:
        return this.gooleValidationService.objectiveValid$.value;

      case Platforms.Bing:
        return this.bingValidationService.objectiveValid$.value;

      case Platforms.LinkedIn:
        return this.linkedinValidationService.objectiveValid$.value;
      case Platforms.TikTok:
        return this.tiktokValidationService.objectiveValid$.value;

      case Platforms.SmartCampaign:
        return this.smartCampaignValidationService.objectiveValid$.value;

      default:
        return false;
    }
  }
}
