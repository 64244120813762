import { makeMutation, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'createCampaign';
type Input = { campaign: TikTok.CreateAd.Backend.TikTokCampaignDto };
type Output = QueryResult<typeof queryField, boolean>;

export const createCampaignQuery = makeMutation<Output, Input>(`
mutation TiktokCreateCampaign($campaign:TikTokCampaignDto!) {
  tiktok {
    ${queryField}(campaign:$campaign)
  }
}
`);
