import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'translate';
type Output = QueryResult<typeof queryField, string>;

export const translateQuery = makeQuery<Output, OpenAI.TranslateInput>(`
query Translate($content: String!, $language: String!, $productName: String!, $productDescription: String!, $textLimit: Float!){
  openAI {
    ${queryField}(content: $content, language: $language, productName: $productName, productDescription: $productDescription, textLimit:$textLimit)
  }
}
`);
