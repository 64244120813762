import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import {
    CurrenyKeys, GetMySubscription, GraphQLService, ISubmitCustomOfferRequest, PackageFeatures,
    PaymentSourceFromPaymentIntentInput, UpdateBillingInfoRequest
} from '../';
import * as Query from '../queries';

@Injectable({ providedIn: 'root' })
export class ChargebeeService {
  constructor(private gqlService: GraphQLService, private store: Store) {}

  getAllPackages$() {
    return this.gqlService.get(Query.allPackages()).pipe(
      map((o) => {
        const packages = o.data
          .filter((o) => !!o.visibility)
          .map((pkg) => {
            pkg.featuresJson && typeof pkg.featuresJson == 'string'
              ? (JSON.parse(pkg.featuresJson! as unknown as string) as PackageFeatures)
              : ({} as PackageFeatures);

            return pkg;
          });

        return {
          ...o,
          data: packages
        };
      })
    );
  }

  getMySubscription$() {
    return this.gqlService.get(Query.mySubscription());
  }

  applyAppsumoCoupon$(coupon: string) {
    return this.gqlService.post(Query.applyAppsumoCoupon({ coupon }));
  }

  applyCouponCode$(coupon: string) {
    return this.gqlService.post(Query.applyCoupon({ coupon }));
  }

  createPaymentIntent$(amount: number, currencyCode: CurrenyKeys) {
    return this.gqlService.get(Query.createPaymentIntent({ amount, currencyCode }));
  }

  createPaymentSourceFromPaymentIntent$(intent: PaymentSourceFromPaymentIntentInput, makePrimary = true) {
    return this.gqlService.post(
      Query.createPaymentSourceFromPaymentIntent({
        intent,
        makePrimary
      })
    );
  }

  subscribeToPlan$(packageId: string, appSumoCoupon?: string) {
    return this.gqlService.post(Query.subscribeToPlan({ packageId, appSumoCoupon }));
  }

  cancelSubscription$(reason: string) {
    return this.gqlService.post(Query.cancelSubscription({ reason }));
  }

  abortCancellationSubscription$() {
    return this.gqlService
      .post(Query.abortSubscriptionCancellation())
      .pipe(tap(() => this.store.dispatch(new GetMySubscription())));
  }

  updateBillingInfo$(input: UpdateBillingInfoRequest) {
    return this.gqlService.post(Query.updateBillingInfo(input));
  }

  invoices$() {
    return this.gqlService.get(Query.invoices());
  }

  coupons$() {
    return this.gqlService.get(Query.coupons());
  }

  invoiceDownloadInfo$(invoiceId: string) {
    return this.gqlService.get(Query.invoiceDownloadInfo({ invoiceId }));
  }

  getCurrentTermSpends$() {
    return this.gqlService.get(Query.getCurrentTermSpends());
  }

  submitCustomOffer$(payload: ISubmitCustomOfferRequest) {
    return this.gqlService.post(Query.submitCustomOffer({ payload }));
  }

  applyCancellationDiscount$() {
    return this.gqlService.post(Query.applyCancellationDiscount());
  }

  collectUnpaidInvoices$() {
    return this.gqlService.post(Query.collectUnpaidInvoices());
  }
}
