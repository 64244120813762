import { makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';
type Input = { ids: number[] };
type Output = Linkedin.CreateAd.GetOrganizationsResponse;
export const getOrganizations = makeQuery<Output, Input>(`
query GetOrganizations($ids: [Int!]!) {
  linkedin {
    getOrganizations(organizationIds: $ids){
      results
      statuses
      errors
    }
  }
}
`);
