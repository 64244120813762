import { Component } from '@angular/core';
import { Platforms } from '@core/models';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { AdPreviewGooglePlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-search-ads-preview--google',
  templateUrl: './search-ads.component.html'
})
export class SearchAdsPreviewGoogleComponent {
  Platforms = Platforms;

  platforms = [AdPreviewGooglePlatform];

  constructor(public adPreviewService: GoogleAdPreviewService) {}
}
