import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'accountOverview';

type Input = TikTok.Dashboard.TikTokAccountOverviewRequest;
type Output = QueryResult<typeof queryField, TikTok.Dashboard.TikTokAccountOverviewOutputDto[]>;

export const accountOverviewQuery = makeQuery<Output, Input>(`
query GetAccountOverview($startDate: SafeInt!, $endDate: SafeInt!, $interval: TikTokInsightInterval) {
  tiktok {
    ${queryField}(startDate: $startDate, endDate: $endDate, interval:$interval){
      reach
      appInstall
      clicks
      clickThroughRate
      comments
      conversion
      follows
      frequency
      impressions
      inAppAddToCart
      inAppAddToWishlist
      inAppPurchase
      inAppSubscribe
      likes
      offlineAddPaymentInfo
      offlineAddToCart
      offlineAddToWishlist
      offlineCompletePayment
      offlineCompleteRegistration
      offlineDownload
      offlineSubscribe
      onsiteAddBilling
      onsiteAddToCart
      onsiteAddToWishList
      onsiteCompletePayment
      profileVisits
      result
      shares
      videoViews
      websiteAddPaymentInfo
      websiteAddToCart
      websiteAddToWishlist
      websiteCompletePayment
      websiteCompleteRegistration
      websiteDownload
      websitePageView
      websiteSubscribe
      timePeriod
      costPer1KImpressions
      costPerConversion
      costPerClick
      costPerReach
      spend
      costPer1KReach
    }
  }
}
`);
