<section
  class="aayn-audience--detailed-targeting"
  [ngClass]="{ 'aayn-audience--detailed-targeting__active': overlay.render }"
>
  <aayn-detailed-targeting-added-item
    [addedItemType]="'inclusions'"
    *ngIf="adCreationModel.adSet.targeting.detailedTargetings.inclusions.length"
    (onClickRemove)="onCloseClickGroup('inclusions')"
  >
    <ayn-badge
      color="primary"
      *ngFor="
        let includeDetailedTargeting of adCreationModel.adSet.targeting.detailedTargetings.inclusions;
        let index = index
      "
      [text]="includeDetailedTargeting.name"
      icon="cross-mini-bold"
      (click)="onCloseClick('inclusions', index)"
    >
      {{ includeDetailedTargeting.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <aayn-detailed-targeting-added-item
    [addedItemType]="'exclusions'"
    *ngIf="adCreationModel.adSet.targeting.detailedTargetings.exclusions.length"
    (onClickRemove)="onCloseClickGroup('exclusions')"
  >
    <ayn-badge
      color="orange"
      *ngFor="
        let excludeDetailedTargeting of adCreationModel.adSet.targeting.detailedTargetings.exclusions;
        let index = index
      "
      [text]="excludeDetailedTargeting.name"
      icon="cross-mini-bold"
      (click)="onCloseClick('exclusions', index)"
    >
      {{ excludeDetailedTargeting.type | titlecase }}
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <ayn-form-field class="aayn-audience--detailed-targeting--input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Add audience, demographics, interest or behaviours' | translate"
      (click)="detailedTargetings.length ? overlay.show($event) : null"
      [formControl]="detailedTargetingSearchControl"
    />

    <ayn-icon name="tick"></ayn-icon>
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--detailed-targeting--overlay"
    styleClass="aayn-audience--detailed-targeting--overlay-inner"
  >
    <ayn-tab tab-type="ayn-tab--default">
      <ayn-tab-item [tabTitle]="'Interest' | translate">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of detailedTargetings"
            [type]="detailedTargeting.type"
            [value]="detailedTargeting.audienceSizeLowerBound"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onExcludeClick)="onExcludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            (onExcludeRemove)="onExcludeRemove(detailedTargeting)"
          >
            {{ detailedTargeting.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Custom' | translate" *ngIf="false"></ayn-tab-item>
    </ayn-tab>
  </ayn-overlay>
</section>
