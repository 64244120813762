<section class="aayn-call-extensions-modal">
  <header class="aayn-call-extensions-modal--header">
    <ayn-icon name="circle-check-list"></ayn-icon>
    <div class="aayn-call-extensions-modal--header__content">
      <h4>{{ 'Call extensions' | translate }}</h4>
      <p>{{ 'Add a phone number to your ad' | translate }}</p>
    </div>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <section>
    <form [formGroup]="callForm" (ngSubmit)="onSubmit()">
      <div class="aayn-call-extensions-modal--form">
        <h5>{{ 'Add new call extension' | translate }}</h5>
        <div class="aayn-call-extensions-modal--row">
          <div class="aayn-call-extensions-modal--row__item">
            <div class="aayn-call-extensions-modal--form--country">
              <label for="country">{{ 'Country' | translate }} </label>
              <ayn-form-field>
                <ayn-select
                  [setValueHtml]="true"
                  [minWidth]="120"
                  formControlName="countryCode"
                  id="country"
                  [placeholder]="'Search country' | translate"
                >
                  <ayn-option
                    *ngFor="let item of countries | aynOptionFilter | async"
                    [value]="item.code"
                    [title]="item.name"
                  >
                    <span class="flag-icon" [ngClass]="'flag-icon-' + item.code"></span>
                    {{ item.name }}
                  </ayn-option>
                </ayn-select>

                <ayn-icon name="location" class="ayn-icon-left"></ayn-icon>
              </ayn-form-field>
            </div>
          </div>

          <div class="aayn-call-extensions-modal--row__item">
            <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
              <input type="text" ayn-input formControlName="phoneNumber" [placeholder]="'Phone number' | translate" />
            </ayn-form-field>
          </div>
        </div>

        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="70">
          <input type="text" ayn-input formControlName="name" [placeholder]="'Extension name' | translate" />
        </ayn-form-field>
      </div>

      <button
        ayn-button="primary"
        class="aayn-call-extensions-modal--button"
        ayn-button-flex
        type="submit"
        [disabled]="callForm.invalid"
      >
        {{ 'Save Call Extensions' | translate }}
      </button>
    </form>
  </section>
</section>
