<section
  class="aayn-select-ad-item"
  [ngClass]="{
    'aayn-select-ad-item__active': active,
    'aayn-select-ad-item__empty': empty,
    'aayn-select-ad-item__disabled': disabled
  }"
  (click)="!empty ? onClickBtn($event) : null"
>
  <ng-container *ngIf="!empty">
    <ayn-icon [name]="adsType!" srcPrefix="/assets/images/svgs/create-ads/"></ayn-icon>
    <h4 class="aayn-select-ad-item--title">{{ title | translate }}</h4>
    <p class="aayn-select-ad-item--description">{{ description | translate }}</p>
    <span class="aayn-select-ad-item--coming-soon-text" *ngIf="comingSoonText?.length">{{
      comingSoonText || '' | translate
    }}</span>
  </ng-container>
</section>
