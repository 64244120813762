import { ApplyAppsumoCouponRequest } from '@core/models';

import { makeMutation } from '../types';

type Input = ApplyAppsumoCouponRequest;
type Output = boolean;

export const applyAppsumoCoupon = makeMutation<Output, Input>(`
  mutation ApplyAppsumoCoupon($coupon: String!) {
    applyAppSumoCoupon(coupon: $coupon)
  }
`);
