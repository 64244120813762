import { environment } from '@environment';
import { pipe } from 'fp-ts/lib/function';
import { cloneDeep } from 'lodash';
import { modifyW } from 'spectacles-ts';

import { Facebook } from '@core/models';

import { ApplyTypes, ApplyValidations, createAdBaseModel, UsesInitialization, WithMap } from '../common';
import { CreateAdCommon } from '../common/common';
import { ICreateAdCommonValidate } from '../common/types';

export type AdTypeConfig = {
  [Facebook.AdType.DynamicCreative]: Facebook.AdDynamicCreativeInput;
  [Facebook.AdType.CarouselAds]: Facebook.AdCarouselCreativeInput;
  [Facebook.AdType.InstantExperienceAds]: Facebook.AdExistingPostPage;
  [Facebook.AdType.InstagramExperienceAds]: Facebook.AdExistingPostInstagram;
};

export module FacebookCreateAd {
  export type AdRequest<AT = Facebook.AdDynamicCreativeInput, AdSetType = Facebook.CreateAd.ICreateAdSetRequest> = {
    campaign: Facebook.CreateAd.ICreateCampaignRequest;
    adSet: AdSetType;
    ad: Facebook.CreateAd.ICreateAdRequest<AT>;
  };

  export type AdType = Facebook.CreateAd.ICreateAdRequest<Facebook.CreateAd.AdType>;

  function createEmptyModel<AT = Facebook.AdDynamicCreativeInput>() {
    return {
      ...createAdBaseModel(
        Facebook.CampaignObjective.OUTCOME_TRAFFIC,
        Facebook.AudienceType.Manual,
        Facebook.AdType.DynamicCreative
      ),

      campaign: {
        name: '',
        objective: Facebook.CampaignObjective.OUTCOME_TRAFFIC,
        status: environment.production ? Facebook.FacebookEntityStatus.Active : Facebook.FacebookEntityStatus.Paused,
        bidStrategy: Facebook.BidStrategy.LOWEST_COST_WITHOUT_CAP
      } as Facebook.CreateAd.ICreateCampaignRequest,

      adSet: {
        name: '',
        status: environment.production ? Facebook.FacebookEntityStatus.Active : Facebook.FacebookEntityStatus.Paused,
        billingEvent: Facebook.BillingEvent.IMPRESSIONS,
        isDynamicCreative: true,
        dateRange: {
          start: new Date()
        } as Facebook.StartEndDate,
        targeting: {
          geoLocations: {
            inclusions: [],
            exclusions: []
          } as Facebook.CreateAd.Draft.AdSet.Targeting.Location,
          detailedTargetings: {
            inclusions: [],
            exclusions: []
          } as Facebook.CreateAd.Draft.AdSet.Targeting.DetailedTargeting,
          genders: [Facebook.Targeting.Gender.Men, Facebook.Targeting.Gender.Women],
          minAge: 18,
          maxAge: 65,
          locales: [] as Facebook.KeyNameModel[]
        },
        optimizationGoal: Facebook.OptimizationGoal.LINK_CLICKS,
        promotedObject: {} as Facebook.PromotedObject
      },

      ad: {
        name: '',
        creative: {
          name: '',
          optimizeCreativeForEachPerson: false,
          dynamicAssets: {
            bodies: ['', ''],
            descriptions: [''],
            titles: ['', ''],
            callToActionTypes: [Facebook.CallToActionType.LearnMore],
            formats: [Facebook.AdFormat.Automatic],
            linkUrls: [
              {
                websiteUrl: ''
              }
            ],
            images: [] as { id: string; url: string; type: Facebook.AssetType }[],
            videos: [] as { id: string; url: string; type: Facebook.AssetType }[]
          },
          identity: {}
        } as unknown as AT,
        status: environment.production ? Facebook.FacebookEntityStatus.Active : Facebook.FacebookEntityStatus.Paused,
        isDynamicCreative: true
      }
    };
  }

  export type ICreateAdModel<AT = Facebook.AdDynamicCreativeInput> = ReturnType<typeof createEmptyModel<AT>>;

  export type AdSetModel = ICreateAdModel['adSet'];

  const deepClonedModel = cloneDeep({ ...createEmptyModel() });

  @UsesInitialization(deepClonedModel)
  @ApplyTypes<ICreateAdModel, AdRequest, Facebook.AdType>({
    ad: {
      [Facebook.AdType.DynamicCreative]: {
        ad: {
          creative: {
            name: '',
            optimizeCreativeForEachPerson: false,
            dynamicAssets: {
              bodies: ['', ''],
              descriptions: [''],
              titles: ['', ''],
              callToActionTypes: [Facebook.CallToActionType.LearnMore],
              formats: [Facebook.AdFormat.Automatic],
              linkUrls: [
                {
                  websiteUrl: ''
                }
              ],
              images: [],
              videos: []
            },
            identity: undefined
          },
          isDynamicCreative: true
        }
      },

      [Facebook.AdType.CarouselAds]: {
        ad: {
          creative: {
            name: '',
            optimizeCreativeForEachPerson: false,
            callToAction: Facebook.CallToActionType.LearnMore,
            carouselCards: [
              { description: '', headline: '', imageHash: '', imageUrl: '' },
              { description: '', headline: '', imageHash: '', imageUrl: '' }
            ],
            destination: {
              displayUrl: '',
              url: ''
            },
            identity: {
              pageId: '',
              instagramAccountId: ''
            }
          },
          isDynamicCreative: false
        }
      },

      [Facebook.AdType.InstantExperienceAds]: {
        ad: {
          creative: {
            name: '',
            callToAction: Facebook.CallToActionType.LearnMore,
            destination: {
              displayUrl: '',
              url: ''
            },
            pagePostId: ''
          },
          isDynamicCreative: false
        }
      },

      [Facebook.AdType.InstagramExperienceAds]: {
        ad: {
          creative: {
            name: '',
            callToAction: Facebook.CallToActionType.LearnMore,
            destination: {
              displayUrl: '',
              url: ''
            },
            instagramMediaId: ''
          },
          isDynamicCreative: false
        }
      }
    }
  })
  @WithMap({
    [Facebook.AdType.DynamicCreative]: function (adCreationModel: ICreateAdModel<Facebook.AdDynamicCreativeInput>) {
      return pipe(
        adCreationModel,
        cloneDeep,
        modifyW('adSet', (o) => Facebook.CreateAd.Draft.AdSet.MapAdSetDraftToOriginalModel(o)),
        modifyW('ad.creative.dynamicAssets.images', (o) => o.map((img) => img.id)),
        modifyW('ad.creative.dynamicAssets.videos', (o) => o.map((vid) => vid.id))
      );
    },

    [Facebook.AdType.CarouselAds]: function (adCreationModel: ICreateAdModel<Facebook.AdCarouselCreativeInput>) {
      return pipe(
        adCreationModel,
        cloneDeep,
        modifyW('adSet', (o) => Facebook.CreateAd.Draft.AdSet.MapAdSetDraftToOriginalModel(o)),
        modifyW('ad.creative.carouselCards.[]>.imageUrl', (o) => (o = undefined))
      );
    },

    [Facebook.AdType.InstantExperienceAds]: function (adCreationModel: ICreateAdModel<Facebook.AdExistingPostPage>) {
      return pipe(
        adCreationModel,
        cloneDeep,
        modifyW('adSet', (o) => Facebook.CreateAd.Draft.AdSet.MapAdSetDraftToOriginalModel(o))
      );
    },

    [Facebook.AdType.InstagramExperienceAds]: function (
      adCreationModel: ICreateAdModel<Facebook.AdExistingPostInstagram>
    ) {
      return pipe(
        adCreationModel,
        cloneDeep,
        modifyW('adSet', (o) => Facebook.CreateAd.Draft.AdSet.MapAdSetDraftToOriginalModel(o))
      );
    }
  })
  @ApplyValidations({
    ad: {
      [Facebook.AdType.DynamicCreative]: function (model: ICreateAdModel) {
        return !!model.ad.creative.dynamicAssets.images.length || !!model.ad.creative.dynamicAssets.videos.length;
      },
      [Facebook.AdType.CarouselAds]: function (model: ICreateAdModel<Facebook.AdCarouselCreativeInput>) {
        return (
          model.ad.creative.carouselCards.filter((o) => !!o.imageHash).length === model.ad.creative.carouselCards.length
        );
      },
      [Facebook.AdType.InstantExperienceAds]: function (model: ICreateAdModel<Facebook.AdExistingPostPage>) {
        return !!model.ad.creative.pagePostId;
      },
      [Facebook.AdType.InstagramExperienceAds]: function (model: ICreateAdModel<Facebook.AdExistingPostInstagram>) {
        return !!model.ad.creative.instagramMediaId;
      }
    }
  })
  export class CreateAd {
    get isValidImages(): boolean {
      return this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd extends CreateAdCommon<ICreateAdModel, AdRequest, Facebook.AdType> {
    mapToApiRequest(type?: string | number): AdRequest;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
