<section class="upload-ads-images" [ngClass]="modalClass">
  <section class="upload-ads-images--header">
    <div class="upload-ads-images--header__left">
      <ayn-icon [name]="icon"></ayn-icon>
      <div class="upload-ads-images--header__left--title">
        <h4>
          {{ header | translate }}
        </h4>

        <p>{{ description | translate }}</p>
      </div>
    </div>

    <button
      ayn-button="white"
      *ngIf="!saveButtonHidden"
      class="upload-ads-images--header-button"
      (click)="saveModal.emit()"
      tabindex="-1"
      [disabled]="saveDisabled"
    >
      {{ saveButtonLabel | translate }}
    </button>

    <ayn-modal-close *ngIf="!modal" (click)="closeModal.emit()"></ayn-modal-close>
    <ayn-modal-close *ngIf="modal" [modal]="modal"></ayn-modal-close>
  </section>
  <div class="upload-ads-images--container">
    <section class="upload-ads-images-main-body" [ngClass]="bodyClass">
      <ng-content></ng-content>
    </section>
  </div>
  <ng-content select="[after-container]"></ng-content>
</section>
