import { Component, OnInit } from '@angular/core';
import { PlatformIcons, Platforms } from '@core/models';
import { Select } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { Observable } from 'rxjs';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';

@Component({
  selector: 'aayn-select-platform--header',
  templateUrl: './select-platform-header.component.html'
})
export class SelectPlatformHeaderComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  protected adCreationModel$ = this.smartCampaignStateService.adCreationModel$;

  PlatformIcons = PlatformIcons;

  get instantAdPlatform() {
    return this.instantAdStateService.adCreationModel.selectedPlatform;
  }

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private instantAdStateService: InstantAdStateService
  ) {}

  ngOnInit(): void {}
}
