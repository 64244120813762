<ayn-tab tab-type="ayn-tab--primary" tabClass="ayn-tab--ad-preview-google">
  <ayn-tab-item
    tabTitle="Youtube Ads"
    [disabled]="!availableTabs.includes('youtube')"
    leftIcon="youtube"
    [active]="availableTabs.includes('youtube')"
  >
    <aayn-ad-preview-youtube-ads
      [title]="title"
      [cta]="cta"
      [linkUrl]="linkUrl"
      [image]="image"
      [logoUrl]="logoUrl"
    ></aayn-ad-preview-youtube-ads>
  </ayn-tab-item>

  <ayn-tab-item
    tabTitle="Search Ads"
    [disabled]="!availableTabs.includes('search')"
    [active]="!availableTabs.includes('youtube') && availableTabs.includes('search')"
    leftIcon="google"
  >
    <aayn-ad-preview-search-ads
      [linkUrl]="linkUrl"
      [description]="description"
      [headlines]="titles"
    ></aayn-ad-preview-search-ads>
  </ayn-tab-item>

  <ayn-tab-item
    tabTitle="Display Ads"
    [disabled]="!availableTabs.includes('display')"
    [active]="!availableTabs.includes('youtube') && !availableTabs.includes('search')"
    leftIcon="display-google"
  >
    <aayn-ad-preview-display-ads
      [description]="description"
      [linkUrl]="linkUrl"
      [image]="image"
      [businessLogo]="logoUrl"
      [title]="title"
    >
    </aayn-ad-preview-display-ads>
  </ayn-tab-item>
</ayn-tab>
