<section class="aayn-callout-extensions-modal">
  <header class="aayn-callout-extensions-modal--header">
    <ayn-icon name="circle-check-list"></ayn-icon>
    <div class="aayn-callout-extensions-modal--header__content">
      <h4>{{ 'Callout extensions' | translate }}</h4>
      <p>{{ 'Add more business information to your ad' | translate }}</p>
    </div>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <section>
    <form [formGroup]="calloutForm" (ngSubmit)="onSubmit()">
      <div class="aayn-callout-extensions-modal--form">
        <h5>{{ 'Add new callout extension' | translate }}</h5>

        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
          <input type="text" ayn-input formControlName="text1" [placeholder]="'Callout text 1' | translate" />
        </ayn-form-field>
        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="35">
          <input type="text" ayn-input formControlName="text2" [placeholder]="'Callout text 2' | translate" />
        </ayn-form-field>
        <ayn-form-field class="aayn-ad-type--control" [maxCharacterNumber]="70">
          <input type="text" ayn-input formControlName="name" [placeholder]="'Extension name' | translate" />
        </ayn-form-field>
      </div>
      <button
        ayn-button="primary"
        class="aayn-callout-extensions-modal--button"
        ayn-button-flex
        type="submit"
        [disabled]="calloutForm.invalid"
      >
        {{ 'Save Callout Extensions' | translate }}
      </button>
    </form>
  </section>
</section>
