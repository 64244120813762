import { makeMutation } from '../types';

type Input = { id: string };
type Output = boolean;

export const deleteDraft = makeMutation<Output, Input>(`
  mutation DeleteDraft($id: String!) {
    deleteDraft(id: $id)
  }
`);
