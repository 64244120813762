import {
  adAccountsBing,
  connectAdAccountBing,
  createAdAccountBing,
  isCreationPossibleBing,
  oauthBing
} from './business';
import {
  accountOverviewBing,
  accountOverviewTotalBing,
  adGroupsWithMetricsBing,
  adsWithMetricsBing,
  breakdownsBing,
  campaignsBing,
  campaignsWithMetricsBing,
  campaignsWithMetricsSmartSortingBing,
  editAdGroupBing,
  editCampaignBing,
  editCampaignBudgetsBing,
  funnelDashboardBing,
  turnOffCampaignsBing,
  turnOnCampaignsBing
} from './dashboard';
import {
  audienceCriteriaSearchBing,
  createAudienceCampaign,
  getAccountImages,
  getConversionGoalsBing,
  getCurrencyConstantValue,
  getGoogleCredentialId,
  getGoogleCredentialIdDirectUrl,
  importGoogleCampaigns,
  locationSearchBing,
  profileDataSearchBing
} from './create-ad';

export default {
  business: { createAdAccountBing, isCreationPossibleBing },
  oauthBing,
  adAccountsBing,
  connectAdAccountBing,
  dashboard: {
    breakdownsBing,
    campaignsBing,
    campaignsWithMetricsBing,
    campaignsWithMetricsSmartSortingBing,
    funnelDashboardBing,
    accountOverviewBing,
    accountOverviewTotalBing,
    turnOnCampaignsBing,
    turnOffCampaignsBing,
    editCampaignBudgetsBing,
    editCampaignBing,
    editAdGroupBing,
    adGroupsWithMetricsBing,
    adsWithMetricsBing
  },
  createAd: {
    createAudienceCampaign,
    locationSearchBing,
    audienceCriteriaSearchBing,
    profileDataSearchBing,
    getAccountImages,
    getCurrencyConstantValue,
    getConversionGoalsBing,
    importGoogleCampaigns,
    getGoogleCredentialId,
    getGoogleCredentialIdDirectUrl
  }
};
