import { OpenAI } from '@core/models';

export const TEXT_GENERATE_LANGUAGES = [
  { label: 'English', value: 'en', flag: 'en' },
  { label: 'French', value: 'fr', flag: 'fr' },
  { label: 'Spanish', value: 'es', flag: 'es' },
  { label: 'Portuguese', value: 'portuguese', flag: 'pt' },
  { label: 'German', value: 'german', flag: 'de' },
  { label: 'Dutch', value: 'dutch', flag: 'nl' },
  { label: 'Italian', value: 'italian', flag: 'it' },
  { label: 'Croatian', value: 'croatian', flag: 'hr' },
  { label: 'Slovenian', value: 'slovenian', flag: 'sl' },
  { label: 'Turkish', value: 'turkish', flag: 'tr' },
  { label: 'Polish', value: 'polish', flag: 'pl' },
  { label: 'Swedish', value: 'swedish', flag: 'sv' },
  { label: 'Norwegian', value: 'norwegian', flag: 'no' },
  { label: 'Bulgarian', value: 'bulgarian', flag: 'bg' },
  { label: 'Latvian', value: 'latvian', flag: 'lv' },
  { label: 'Hindi', value: 'hindi', flag: 'hi' },
  { label: 'Bengali', value: 'bengali', flag: 'bn' },
  { label: 'Indonesian', value: 'indonesian', flag: 'id' },
  { label: 'Danish', value: 'danish', flag: 'da' },
  { label: 'Finnish', value: 'finnish', flag: 'fi' },
  { label: 'Icelandic', value: 'icelandic', flag: 'is' },
  { label: 'Tamil', value: 'tamil', flag: 'ta' },
  { label: 'Malay', value: 'malay', flag: 'ms' },
  { label: 'Japanese', value: 'japanese', flag: 'ja' },
  { label: 'Korean', value: 'korean', flag: 'ko' },
  { label: 'Russian', value: 'russian', flag: 'ru' },
  { label: 'Arabic', value: 'arabic', flag: 'ar' },
  { label: 'Hebrew', value: 'hebrew', flag: 'he' },
  { label: 'Thai', value: 'thai', flag: 'th' },
  { label: 'Greek', value: 'greek', flag: 'el' },
  { label: 'Hungarian', value: 'hungarian', flag: 'hu' },
  { label: 'Czech', value: 'czech', flag: 'cs' },
  { label: 'Romanian', value: 'romanian', flag: 'ro' },
  { label: 'Ukrainian', value: 'ukrainian', flag: 'uk' }
];

export const TEXT_GENERATE_TONES = [
  { label: 'Friendly', value: OpenAI.TextTone.Friendly },
  { label: 'Luxury', value: OpenAI.TextTone.Luxury },
  { label: 'Relaxed', value: OpenAI.TextTone.Relaxed },
  { label: 'Professional', value: OpenAI.TextTone.Professional },
  { label: 'Bold', value: OpenAI.TextTone.Bold },
  { label: 'Adventures', value: OpenAI.TextTone.Adventures },
  { label: 'Witty', value: OpenAI.TextTone.Witty },
  { label: 'Persuasive', value: OpenAI.TextTone.Persuasive }
].map((item) => ({ ...item, icon: item.value.toLowerCase() }));
