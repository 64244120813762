import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { keyword: string };
type Output = {
  geoLocations: {
    edges: {
      node: Facebook.GeoLocationItem;
    }[];
  };
};

export const getGeoLocations = makeQuery<Output, Input>(`
  query GetGeoLocations($keyword: String!) {
    facebook {
      geoLocations(keyword: $keyword) {
        edges {
          node {
            key
            name
            type
            countryCode
            countryCodes
            countryName
            region
            regionId
            primaryCity
            primaryCityId
          }
        }
      }
    }
  }
`);
