<section class="aayn-ad-preview" [ngClass]="className">
  <div class="aayn-ad-preview-content">
    <aayn-ad-preview-header
      *ngIf="showHeader"
      [profileImage]="profileImage"
      [businessName]="businessName"
      [description]="description"
      [displayImage]="showImage"
    ></aayn-ad-preview-header>
    <section class="aayn-ad-preview-main">
      <ng-content></ng-content>
    </section>
  </div>
</section>
