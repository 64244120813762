<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Select Your Tracking Event' | translate }}</h4>
    <p class="aayn-select-objective--header__description">
      {{ 'Choose your tracking pixels to optimize your campaigns for conversions.' | translate }}
    </p>
  </header>

  <form [formGroup]="form" *ngIf="form">
    <form formGroupName="platforms">
      <aayn-conversion-my-website--facebook-form
        *ngIf="hasSelectedFacebook"
        [form]="facebookForm"
      ></aayn-conversion-my-website--facebook-form>

      <aayn-conversion-my-website--google-form
        *ngIf="hasSelectedGoogle"
        [form]="googleForm"
      ></aayn-conversion-my-website--google-form>

      <aayn-conversion-my-website--bing-form
        *ngIf="hasSelectedBing"
        [form]="bingForm"
      ></aayn-conversion-my-website--bing-form>

      <aayn-conversion-my-website--linkedin-form
        *ngIf="hasSelectedLinkedin"
        [form]="linkedinForm"
      ></aayn-conversion-my-website--linkedin-form>

      <aayn-conversion-my-website--tiktok-form
        *ngIf="hasSelectedTiktok"
        [form]="tiktokForm"
      ></aayn-conversion-my-website--tiktok-form>
    </form>

    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="name"
          aaynDraftName
          [placeholder]="'Campaign Name' | translate"
          [(ngModel)]="adCreationModel!.campaign.name"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
