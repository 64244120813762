export interface Language {
  name: string;
  label: string;
  code: string;
}

export const LANGUAGES: Language[] = [
  { name: 'Danish', label: 'Danish', code: 'dk' },
  { name: 'Dutch', label: 'Dutch', code: 'nl' },
  { name: 'English', label: 'English', code: 'gb' },
  { name: 'Finnish', label: 'Finnish', code: 'fi' },
  { name: 'French', label: 'French', code: 'fr' },
  { name: 'German', label: 'German', code: 'de' },
  { name: 'Italian', label: 'Italian', code: 'it' },
  { name: 'Japanese', label: 'Japanese', code: 'jp' },
  { name: 'Norwegian', label: 'Norwegian', code: 'no' },
  { name: 'Portuguese', label: 'Portuguese', code: 'pt' },
  { name: 'Swedish', label: 'Swedish', code: 'sv' },
  { name: 'Spanish', label: 'Spanish', code: 'es' },
  { name: 'Arabic', label: 'Arabic', code: 'sa' },
  { name: 'Hebrew', label: 'Hebrew', code: 'il' },
  { name: 'Korean', label: 'Korean', code: 'kr' },
  { name: 'Russian', label: 'Russian', code: 'ru' },
  { name: 'TraditionalChinese', label: 'TraditionalChinese', code: 'cl' },
  { name: 'SimplifiedChinese', label: 'SimplifiedChinese', code: 'cn' }
];
