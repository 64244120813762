import { URL_REGEX_WITHOUT_PROTOCOL } from './regex';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { captureSentryException } from '@core/error-handler';

const urlCreator = window.URL || window.webkitURL;

export function createObjectURL(arg: Blob | MediaSource) {
  return urlCreator.createObjectURL(arg);
}

export function revokeObjectURL(url: string) {
  urlCreator.revokeObjectURL(url);
}

export function dataURLtoFile(dataUrl: string | null | undefined, fileName: string) {
  if (!dataUrl) return;

  const arr = dataUrl.split(',');

  if (!arr.length) return;

  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export function blobToFile(blob: Blob, fileName: string): File {
  const extensions = ['jpg', 'png', 'gif', 'svg', 'jpeg', 'bmp', 'webp', 'ico'];
  if (extensions.some((ext) => fileName.endsWith(ext))) {
    return new File([blob], fileName);
  }
  const type = blob.type.split('/').at(1);
  return new File([blob as any], `${fileName}.${type}`, {
    type: blob.type
  });
}

export type ImageFetchReturn<T extends 'file' | 'blob'> = { file: File; blob: Blob }[T];

export function imageUrlToFle<T extends 'file' | 'blob'>(url: string, output: T): Observable<ImageFetchReturn<T>> {
  const fetchImage = fetch(url).then((response) => response.blob());
  return from(fetchImage).pipe(
    map((blob) => {
      if (output === 'file') {
        return blobToFile(blob, 'logo') as ImageFetchReturn<T>;
      }
      return blob as ImageFetchReturn<T>;
    })
  );
}

export function addHttpsPrefix(url: string) {
  if (URL_REGEX_WITHOUT_PROTOCOL.test(url || '')) {
    return 'https://' + url;
  }
  return url;
}

/**
 * Simplify url by removing http, https, www and path from url
 * @param url the url to simplify
 */
export function simplifyUrl(url: string) {
  try {
    return new URL(url).hostname.replace('www.', '');
  } catch (err) {
    if (environment.name == 'dev') {
      console.error('Error in simplifyUrl', err);
    } else {
      captureSentryException(err, {
        captureContext: {
          extra: {
            url
          }
        }
      });
    }
    return url;
  }
}
