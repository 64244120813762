import { FileUploadGroup } from '@ayn-ui/lib/modules/base/file-upload-area';

export function isImagesValid(uploadedImageUrls: FileUploadGroup[]): boolean {
  return uploadedImageUrls
    .filter((imageGroup) => imageGroup.required)
    .reduce(
      (isValid, imageGroup) => isValid && (imageGroup?.imageUrls.length || 0) >= (imageGroup.minRequired || 0),
      !!uploadedImageUrls.length
    );
}
