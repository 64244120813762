export const CHARGEBEE_COMPONENT_CLASS = {
  focus: 'focus',
  invalid: 'invalid',
  empty: 'empty'
};

export const CHARGEBEE_COMPONENT_FONTS = [];

export const CHARGEBEE_COMPONENT_STYLES = {
  base: {
    fontFamily:
      '"SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    color: '#212936',
    letterSpacing: '-0.04em',
    fontSize: '16px',
    fontWeight: '400',

    ':focus': {
      color: '#212936'
    },

    '::placeholder': {
      color: '#828ead'
    },

    '::focus:placeholder': {
      color: '#828ead'
    }
  },

  invalid: {
    color: '#ff5902',

    ':focus': {
      color: '#FA755A'
    },

    '::placeholder': {
      color: '#FFCCA5'
    }
  }
};
