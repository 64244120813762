import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = {
  pageId: string;
};

type Output = {
  instagramAccounts: Facebook.IInstagramAccount[];
};

export const getInstagramAccounts = makeQuery<Output, Input>(`
  query GetInstagramAccounts($pageId: String!) {
    facebook{
      instagramAccounts(pageId: $pageId) {
        id
        name
        profilePictureUrl
        followCount
        followedByCount
      }
    }
  }
`);
