import { UserLoginResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  token: string;
  password: string;
  passwordAgain: string;
};

type Output = UserLoginResponse;

export const resetPassword = makeMutation<Output, Input>(`
mutation ResetPassword($token: String!, $password: String!,$passwordAgain:String!) {
  resetPassword(data: {token: $token, password: $password, passwordAgain: $passwordAgain}) {
    accessToken
    refreshToken
    user {
      email
      name
      surname
      inviter {
        inviterId
      }
    }
  }
}
`);
