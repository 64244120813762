import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environment';

export class FormListener {
  private _registeredForms$ = new Subject<Observable<any>>();

  private _debounceTime!: number;

  constructor(debounce = 1000) {
    if (debounce >= 1000) {
      this._debounceTime = debounce;
    } else {
      if (isDevMode()) {
        throw new Error(`[FormListener.constructor.debounce]: 'debounce' should not be less than 1000ms`);
      }
    }
  }

  registerForm<T extends FormGroup>(form: T) {
    this._registeredForms$.next(form.valueChanges);
  }

  protected subscribeForms(cb: (changes: any) => void) {
    this._registeredForms$.subscribe((valueChanges) => {
      valueChanges.pipe(distinctUntilChanged(), debounceTime(this._debounceTime)).subscribe((result) => {
        if (environment.draftLog) {
          console.log('[FormListener.constructor]: valueChanges', result);
        }

        cb(result);
      });
    });
  }
}
