export function getTopBarHeight() {
  const topBar = document.querySelector<HTMLElement>('.ayn-layout--topbar');
  if (topBar) {
    const topBarStyles = window.getComputedStyle(topBar);
    const { marginTop, marginBottom, paddingTop, paddingBottom } = parseComputedStyle(topBarStyles, [
      'marginTop',
      'marginBottom',
      'paddingTop',
      'paddingBottom'
    ]);
    return topBar.clientHeight + +(marginTop || 0) + +(marginBottom || 0) + +(paddingTop || 0) + +(paddingBottom || 0);
  }
  return 0;
}

export function parseComputedStyle(
  style: CSSStyleDeclaration,
  keys: Array<
    Exclude<
      keyof CSSStyleDeclaration,
      | 'length'
      | 'index'
      | 'getPropertyPriority'
      | 'getPropertyValue'
      | 'item'
      | 'removeProperty'
      | 'setProperty'
      | 'parentRule'
    >
  >
) {
  return keys.reduce((acc, key) => {
    acc[key] = style[key].split('px')[0];
    return acc;
  }, {} as Partial<CSSStyleDeclaration>);
}
