import { makeMutation, QueryResult } from '@core/queries';
import { SmartCampaign } from '@core/models';

type Output = QueryResult<'editSmartTotalBudget', boolean>;

export const editSmartTotalBudgetQuery = makeMutation<Output, SmartCampaign.Backend.EditSmartBudgetDto>(`
mutation EditSmartTotalBudget(
  $smartCampaignId: String!
  $totalBudget: Float!
  $preferredCurrency: String!
) {
  ayn {
    editSmartTotalBudget(
      smartCampaignId: $smartCampaignId
      totalBudget: $totalBudget
      preferredCurrency: $preferredCurrency
    )
  }
}
`);
