<div class="aayn-precision-audience-creation-ai">
  <form
    [formGroup]="textGeneratorForm"
    standAloneForm
    class="aayn-precision-audience-creation-ai-form"
    (ngSubmit)="getInterests()"
  >
    <div class="aayn-row">
      <div class="aayn-precision-audience-creation-ai--input-container">
        <div class="ayn-recommended">
          <span class="ayn-recommended-text">{{ 'Recommended' | translate }}</span>
        </div>

        <ayn-form-field [ayn-skeleton]="getProductDescriptionLoader$ | async">
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="text"
            [placeholder]="
              'Scan your landing page for our AI to fill in the details  (Example:  www.YourCompany.com)' | translate
            "
            ayn-input
            aaynControlTriggerSubmit
            submitEvent="keydown:enter"
            id="url"
            [formControl]="urlControl"
            [disabled]="(getProductDescriptionLoader$ | async)!"
            (keydown.enter)="scan()"
          />

          <ayn-icon
            name="cross-mini"
            class="aayn-text-generator-ai--cancel-btn"
            *ngIf="getProductDescriptionLoader$ | async"
            (click)="cancelGetProductDescriptionCall()"
          ></ayn-icon>
        </ayn-form-field>
      </div>

      <button
        ayn-button="white"
        loadingColor="dark"
        [width]="185"
        class="aayn-precision-audience-creation-ai--scan-button"
        ayn-button-block
        type="button"
        [loading]="getProductDescriptionLoader$ | async"
        [disabled]="getProductDescriptionLoader$ | async"
        (click)="scan()"
        [ayn-skeleton]="getProductDescriptionLoader$ | async"
        [style.--skeleton-border-radius]="'28px'"
      >
        {{ (getProductDescriptionLoader$ | async) ? 'Scanning...' : ('Scan My Website' | translate) }}
      </button>
    </div>

    <div class="aayn-row">
      <ayn-form-field
        class="aayn-precision-audience-creation-ai-form__text-area"
        [ayn-skeleton]="getProductDescriptionLoader$ | async"
      >
        <textarea
          type="text"
          ayn-input
          class="text-area"
          [placeholder]="
            'Product/Service Description (Example: AdCreative.ai is Artificial Intelligence powered ad creative and social media post generator. With AdCreative.ai you can generate creatives in a matter of seconds.)'
              | translate
          "
          formControlName="description"
          rows="3"
          [disabled]="(getProductDescriptionLoader$ | async)!"
        ></textarea>
      </ayn-form-field>
    </div>

    <div class="aayn-row">
      <ayn-form-field [ayn-skeleton]="getProductDescriptionLoader$ | async">
        <input
          type="text"
          ayn-input
          [placeholder]="'Your Target Audience (Example: Small business owners)' | translate"
          formControlName="targetAudience"
          [disabled]="(getProductDescriptionLoader$ | async)!"
        />
      </ayn-form-field>
    </div>

    <div class="aayn-row">
      <button
        ayn-button="primary"
        [width]="200"
        class="mx:auto mt:4"
        ayn-button-block
        type="submit"
        [disabled]="getInterestLoader$ | async"
        [loading]="getInterestLoader$ | async"
        loadingColor="dark"
      >
        {{ 'Get Interests' | translate }}
      </button>
    </div>
  </form>

  <section class="aayn-audience--location-selection--tabs">
    <ayn-tab
      tab-type="ayn-tab--primary"
      #tab
      [aayn-smart-response-catcher]="smartCampaignResponseCatcherParams"
      [selectedPlatforms]="adCreationModel.selectedPlatforms"
    >
      <ayn-tab-item
        [tabTitle]="PlatformKeyByValues[Platforms.Meta]"
        [icon]="PlatformIcons[Platforms.Meta]"
        *ngIf="selectedPlatforms.facebook"
      >
        <aayn-facebook-targeting-list [form]="form"></aayn-facebook-targeting-list>
        <aayn-detailed-targeting--add-more--smart-campaign
          [platform]="Platforms.Meta"
          [form]="form"
        ></aayn-detailed-targeting--add-more--smart-campaign>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="PlatformKeyByValues[Platforms.Google]"
        [icon]="PlatformIcons[Platforms.Google]"
        *ngIf="selectedPlatforms.google"
      >
        <aayn-google-targeting-list [form]="form"></aayn-google-targeting-list>
        <aayn-detailed-targeting--add-more--smart-campaign
          [platform]="Platforms.Google"
          [form]="form"
        ></aayn-detailed-targeting--add-more--smart-campaign>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="PlatformKeyByValues[Platforms.LinkedIn]"
        [icon]="PlatformIcons[Platforms.LinkedIn]"
        *ngIf="selectedPlatforms.linkedIn"
      >
        <aayn-linkedin-targeting-list [form]="form"></aayn-linkedin-targeting-list>
        <aayn-detailed-targeting--add-more--smart-campaign
          [platform]="Platforms.LinkedIn"
          [form]="form"
        ></aayn-detailed-targeting--add-more--smart-campaign>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="PlatformKeyByValues[Platforms.Bing]"
        [icon]="PlatformIcons[Platforms.Bing]"
        *ngIf="selectedPlatforms.bing"
      >
        <aayn-bing-targeting-list [form]="form"></aayn-bing-targeting-list>
        <aayn-detailed-targeting--add-more--smart-campaign
          [platform]="Platforms.Bing"
          [form]="form"
        ></aayn-detailed-targeting--add-more--smart-campaign>
      </ayn-tab-item>

      <ayn-tab-item
        [tabTitle]="PlatformKeyByValues[Platforms.TikTok]"
        [icon]="PlatformIcons[Platforms.TikTok]"
        [aayn-tab-validate]="validateTiktokInterest"
        *ngIf="selectedPlatforms.tiktok"
      >
        <aayn-tiktok-targeting-list [form]="form"></aayn-tiktok-targeting-list>
        <aayn-detailed-targeting--add-more--smart-campaign
          [platform]="Platforms.TikTok"
          [form]="form"
        ></aayn-detailed-targeting--add-more--smart-campaign>
      </ayn-tab-item>
    </ayn-tab>
  </section>
</div>
