import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'languageSearch';
type Input = TikTok.CreateAd.Backend.LanguageSearch;
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokLanguageSearchOutputDto[]>;

export const languageSearchQuery = makeQuery<Output, Input>(`
query LanguageSearch($searchKey:String!) {
  tiktok {
    languageSearch(searchKey: $searchKey) {
      name
      code
    }
  }
}
`);
