import { merge } from 'lodash';

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LS_INTERCOM_TOUR_KEY } from '../';

type IntercomMethodType = 'boot' | 'update' | 'startTour';

declare var window: {
  Intercom(methodName: IntercomMethodType, tourId: number): void;
};

export enum IntercomTours {
  FIRST_BRAND_CREATED = 482464,
  WELCOME_TO_ADYOUNEED = 436326,
  CREATE_AD_STEP_1 = 482819,
  CREATE_AD_STEP_2 = 482823,
  CREATE_AD_STEP_3 = 482824,
  CREATE_AD_STEP_3_FOR_SMART_CAMPAIGN = 499968,
  CREATE_AD_STEP_4 = 482825,
  CREATE_AD_STEP_4_FOR_SMART_CAMPAIGN = 499951,
  CREATE_AD_STEP_5 = 482827,
  DASHBOARD = 482815,

  // Add new tours here
  CREATE_AD_STEP_2_FOR_INSTANT_AD = 0,
  CREATE_AD_STEP_3_FOR_INSTANT_AD = 0
}

export type IntercomStateKeys = keyof typeof IntercomTours;

export type IntercomState = Record<IntercomStateKeys, boolean>;

@Injectable({ providedIn: 'root' })
export class IntercomService {
  constructor(private modalService: NgbModal) {}

  startTour(tour: IntercomStateKeys, alwaysOpen = false) {
    if (!alwaysOpen && this._isOpened(tour)) return;

    setTimeout(() => {
      if (this.modalService.hasOpenModals()) {
        return;
      }

      window.Intercom('startTour', IntercomTours[tour]);

      this.updateIntercomState({
        [tour]: true
      });
    }, 200);
  }

  private _isOpened(tour: IntercomStateKeys) {
    return this._intercomStates?.[tour];
  }

  private get _intercomStates(): IntercomState | null {
    return JSON.parse(localStorage.getItem(LS_INTERCOM_TOUR_KEY)!);
  }

  updateIntercomState(state: Partial<IntercomState>) {
    const updatedState = merge(this._intercomStates, state);

    localStorage.setItem(LS_INTERCOM_TOUR_KEY, JSON.stringify(updatedState));
  }
}
