import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular-ivy';
import { ApolloError } from '@apollo/client/errors';
import { omit } from 'lodash';
import { BaseResponse } from '@core/models/base.model';

/**
 * The original Error handler placed in https://github.com/getsentry/sentry-javascript/blob/develop/packages/angular/src/errorhandler.ts
 */

/**
 * runOutSideAngular not exported by @sentry/angular-ivy package
 */
declare const Zone: any;
const isNgZoneEnabled = typeof Zone !== 'undefined' && !!Zone.current;
export function runOutsideAngular<T>(callback: () => T): T {
  return isNgZoneEnabled ? Zone.root.run(callback) : callback();
}

export class AynSentryErrorHandler extends SentryErrorHandler {
  public handleError(error: unknown): void {
    const extractedError = this._extractError(error) || 'Handled unknown error';
    if (extractedError instanceof ApolloError && extractedError.networkError) {
      this.handleAynError(extractedError);

      if (this._options.logErrors) {
        console.error(extractedError);
      }
    } else {
      super.handleError(error);
    }
  }

  private handleAynError(extractedError: ApolloError) {
    if (
      extractedError.networkError instanceof HttpErrorResponse ||
      // can be thrown by ErrorInterceptor
      extractedError.networkError instanceof BaseResponse
    ) {
      const { body, operationName } = this.getErrorDetails(extractedError);
      extractedError.name = extractedError.name + ' - ' + operationName;
      captureSentryException(extractedError, {
        captureContext: {
          extra: {
            operationName,
            body
          }
        }
      });
    }
  }

  getErrorDetails(extractedError: ApolloError): { operationName: string; body: any } {
    const body = extractedError.networkError!['__aayn_requestBody'];

    const operationName = body?.operationName;
    let response: any = extractedError.networkError;
    if (response instanceof HttpErrorResponse) {
      extractedError.networkError = omit(extractedError.networkError, '__aayn_requestBody');
    }
    return { operationName, body };
  }
}
export function createAynSentryErrorHandler(config?: ErrorHandlerOptions): SentryErrorHandler {
  return new AynSentryErrorHandler(config);
}

export function captureSentryException(exception: unknown, hint?: Sentry.EventHint) {
  runOutsideAngular(() =>
    Sentry.captureException(exception, {
      mechanism: {
        type: 'angular',
        handled: false
      },
      ...hint
    })
  );
}

function modifyErrorByOperation(operationName: string, error: HttpErrorResponse) {
  switch (operationName) {
    case 'Login':
      return modifyLoginError(error);
    default:
      return error;
  }
}
function modifyLoginError(error: HttpErrorResponse) {
  // TODO: Sentry automatically hides complete object when has credentials. Remove credentials from error
  return error.error;
}
