<ng-container *ngIf="businesses$ | async as businesses">
  <ng-container *ngIf="businesses.length; else createBusiness">
    <section
      class="aayn-business-selection"
      [ngClass]="{ active: overlay.render, disabled: businessSelectionDisabled }"
      *ngIf="businesses.length"
    >
      <button
        class="aayn-business-selection--inner"
        (click)="overlay.toggle($event)"
        [disabled]="businessSelectionDisabled"
      >
        <p class="aayn-business-selection--name">{{ (selectedBusiness$ | async)?.name }}</p>

        <div class="aayn-business-selection_right">
          <span class="aayn-business-selection--type" *ngIf="selectedBusiness$ | async as selectedBusiness">
            {{ BusinessCategoryMap[selectedBusiness.category] | translate }}
          </span>

          <ayn-icon name="business"></ayn-icon>
          <ayn-icon name="tick"></ayn-icon>
        </div>
      </button>

      <ayn-overlay #overlay styleClass="aayn-business-selection--body" [useOverlayBlur]="true">
        <ul class="aayn-business-selection--body__list">
          <li
            class="aayn-business-selection--body__list_item"
            *ngFor="let business of businesses"
            [ngClass]="{ active: (selectedBusiness$ | async)?.id === business.id }"
            (click)="selectBusiness(business)"
          >
            <p class="aayn-business-selection--body__list_item--name">{{ business.name }}</p>

            <span class="aayn-business-selection--body__list_item--type">
              {{ BusinessCategoryMap[business.category] | translate }}
            </span>
          </li>
        </ul>
      </ayn-overlay>
    </section>
  </ng-container>
</ng-container>

<ng-template #createBusiness>
  <button class="aayn-create-business--btn" routerLink="/main/business/create">
    {{ 'Create a New Business' | translate }}

    <ayn-icon name="add-square"></ayn-icon>
  </button>
</ng-template>
