import { Options } from './types';
import { toArray } from './util';
import { getCSSRules, getWebFontRules } from './embed-webfonts';
import { embedResources } from './embed-resources';

export interface AynOptions {
  styleFilter?: (item: CSSStyleSheet, index: number, array: CSSStyleSheet[]) => boolean;
}

export async function aynParseWebFontRules<T extends HTMLElement>(node: T, options: Options, aynOptions: AynOptions) {
  if (node.ownerDocument == null) {
    throw new Error('Provided element is not within a Document');
  }

  let styleSheets = toArray<CSSStyleSheet>(node.ownerDocument.styleSheets);
  if (aynOptions.styleFilter) {
    styleSheets = styleSheets.filter(aynOptions.styleFilter);
  }

  const cssRules = await getCSSRules(styleSheets, options);

  return getWebFontRules(cssRules);
}

export async function aynGetWebFontCSS<T extends HTMLElement>(
  node: T,
  options: Options,
  aynOptions: AynOptions
): Promise<string> {
  const rules = await aynParseWebFontRules(node, options, aynOptions);
  const cssTexts = await Promise.all(
    rules.map((rule) => {
      const baseUrl = rule.parentStyleSheet ? rule.parentStyleSheet.href : null;
      return embedResources(rule.cssText, baseUrl, options);
    })
  );

  return cssTexts.join('\n');
}
