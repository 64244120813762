<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Conversions on my website' | translate }}</h4>
    <p class="aayn-select-objective--header__description">
      {{
        'To optimize your campaigns for conversions, you must install Google Tracking and/or Google Analytics Goals.'
          | translate
      }}
    </p>
  </header>

  <form [formGroup]="form">
    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="campaignName"
          aaynDraftName
          [placeholder]="'Give your ad campaign a name' | translate"
        />
      </ayn-form-field>
    </aayn-ad-form-input>

    <aayn-ad-form-input label="Conversion Event">
      <ayn-form-field>
        <ayn-select
          formControlName="conversionEvent"
          [placeholder]="'Use Default Account Conversion' | translate"
          (searchControlChange)="searchValueChange($event)"
          [loading]="!!(loader$ | async)"
          [options]="conversionEvents"
          [multiple]="true"
          class="aayn-create-ad--select-v1"
        >
        </ayn-select>
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
