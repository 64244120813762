import { distinctUntilChanged } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FacebookCreateAd } from '@core/ad-platforms';
import { Facebook, PlatformStep } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';
import { FacebookFormService } from '@pages/create-ad/state/platforms/facebook/facebook.form';

enum FacebookPlatforms {
  Facebook,
  Instagram
}

@UntilDestroy()
@Component({
  selector: 'aayn-existing-post-ads--facebook',
  templateUrl: 'existing-post.component.html'
})
export class ExistingPostFacebookComponent implements OnInit {
  protected activeFBPlatform = FacebookPlatforms.Facebook;

  protected FacebookPlatforms = FacebookPlatforms;

  protected callToActions = Facebook.CallToActionTypes;

  protected adCreationModel = this.facebookStateService.adCreationModel as unknown as FacebookCreateAd.ICreateAdModel<
    Facebook.AdExistingPostPage | Facebook.AdExistingPostInstagram
  >;

  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel?.ad?.creative?.name, [Validators.required, Validators.minLength(3)]),
    callToAction: new FormControl(this.adCreationModel?.ad?.creative?.callToAction, [Validators.required]),
    url: new FormControl(this.adCreationModel?.ad?.creative?.destination?.url, [Validators.required])
  });

  constructor(
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService,
    private facebookForm: FacebookFormService,
    private adPreviewService: AdPreviewService
  ) {}

  ngOnInit() {
    this.facebookForm.registerForm(this.form);

    this.facebookStateService.adCreationModel$.subscribe((o) => {
      const model = o as unknown as FacebookCreateAd.ICreateAdModel<
        Facebook.AdExistingPostPage | Facebook.AdExistingPostInstagram
      >;

      this.form.patchValue({
        callToAction: model.ad.creative.callToAction,
        name: model.ad.name,
        url: model.ad.creative?.destination?.url
      });

      this.adCreationModel.adSet.isDynamicCreative = false;
    });
    this.facebookValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.adCreationModel.ad.name = value.name!;
      this.adCreationModel.ad.creative.name = value.name!;
      this.adCreationModel.ad.creative.callToAction = value.callToAction!;

      this.adCreationModel.ad.creative.destination = {
        displayUrl: value.url!,
        url: value.url!
      };
    });
  }

  onFacebookClick() {
    this.activeFBPlatform = FacebookPlatforms.Facebook;
    this.adPreviewService.existingPost.platform = FacebookPlatforms.Facebook;

    this.adCreationModel.selectedTypes.ad = Facebook.AdType.InstantExperienceAds;
  }

  onInstagramClick() {
    this.activeFBPlatform = FacebookPlatforms.Instagram;
    this.adPreviewService.existingPost.platform = FacebookPlatforms.Instagram;

    this.adCreationModel.selectedTypes.ad = Facebook.AdType.InstagramExperienceAds;
  }
}
