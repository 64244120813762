<ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta">
      <aayn-select-an-audience--header [locations]="facebookLocations"></aayn-select-an-audience--header>
    </div>

    <div *ngSwitchCase="Platforms.Google">
      <aayn-select-an-audience--header [locations]="googleLocations"></aayn-select-an-audience--header>
    </div>

    <div *ngSwitchCase="Platforms.Bing">
      <aayn-select-an-audience--header [locations]="bingLocations"></aayn-select-an-audience--header>
    </div>

    <div *ngSwitchCase="Platforms.LinkedIn">
      <aayn-select-an-audience--header [locations]="linkedinLocations"></aayn-select-an-audience--header>
    </div>

    <div *ngSwitchCase="Platforms.TikTok">
      <aayn-select-an-audience--header [locations]="tiktokLocations"></aayn-select-an-audience--header>
    </div>

    <div *ngSwitchCase="Platforms.SmartCampaign">
      <aayn-select-an-audience--header [locations]="smartCampaignLocations"></aayn-select-an-audience--header>
    </div>
  </div>
</ng-container>
