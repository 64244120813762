import { Bing } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Dashboard.IAccountOverViewRequest;
type Output = Bing.Dashboard.IAccountOverViewResponse;

export const accountOverviewTotalBing = makeMutation<Output, Input>(`
  query GetAccountOverviewTotalBing(
    $startDate: SafeInt
    $endDate: SafeInt
    $datePreset: DatePreset
    $interval: InsightInterval
  ) {
    bing {
      accountOverview(
        datePreset: $datePreset
        startDate: $startDate
        endDate: $endDate
        interval: $interval
      ) {
        smartConversions
        customConversions
        destinationUrlConversions
        offlineConversions
        durationConversions
        appInstalls
        viewPerVisit
        inStoreTransactions
        storeVisits
        productConversions
        timePeriod
        conversions
        costPerConversion
        clicks
        ctr
        impressions
        costPerClick
        cost
        revenue
      }
    }
  }
`);
