import { isString, startCase } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

function toCamelCase(value: string) {
  return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
}

@Pipe({
  name: 'aynBeautyText'
})
export class BeautyTextPipe implements PipeTransform {
  transform(value?: string | null, delimiters: string = '_'): string {
    if (!value) return '';

    return value.split(delimiters).map(toCamelCase).join(' ');
  }
}

// TODO: Typescript ES hatası olduğu için yazıldı. Silinecek.
function getLastValue(text: string) {
  const splittedText = text?.split('.');

  return splittedText[splittedText.length - 1] || '';
}

@Pipe({
  name: 'aynTitleCase'
})
export class TitleCasePipe implements PipeTransform {
  /**
   * Transforms from given camel case value string to title case.
   * @param value Camel Case Text
   * @return {string} Title Case Text
   */
  transform(value: string | any): string {
    if (!isString(value)) return value;

    const text = startCase(getLastValue(value).replace(/_/, ' '));

    return text;
  }
}
