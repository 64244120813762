<section class="ayn-datepicker-extend" [class.ayn-datepicker-extend__active]="overlay.render">
  <button class="ayn-datepicker-extend--button" (click)="overlay.toggle($event)" #datePickerBtn>
    {{ label | aynTranslate | async}}

    <ayn-icon name="tick" [class.ml:1]="isCustomWidth"></ayn-icon>
  </button>

  <ayn-overlay #overlay styleClass="ayn-datepicker-extend--body" [containerStyle]="overlayWidth">
    <section class="ayn-datepicker-extend--body-inner">
      <section class="ayn-datepicker-extend--left" #calendarContainer>
        <ngb-datepicker
          #dp
          [(ngModel)]="model"
          (dateSelect)="onDateSelection($event)"
          [dayTemplate]="t"
          (navigate)="changeActiveDate($event)"
          [markDisabled]="markDisabled"
        ></ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
          <span
            class="ayn-datepicker-extend--day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            [class.in-other-month]="(!isInThisMonth(date) && !isInside(date)) || isAfter(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>

        <footer class="ayn-datepicker-extend--footer">
          <div class="ayn-datepicker-extend--footer-left">
            <strong>{{ formattedValue }}</strong>
            <p *ngIf="false">Istanbul Time</p>
          </div>

          <div class="ayn-datepicker-extend--footer-actions">
            <button ayn-button="white" class="ayn-datepicker-extend--footer-actions--cancel" (click)="overlay.hide()">
              {{"Cancel" | aynTranslate | async}}
            </button>
            <button ayn-button="primary" class="ayn-datepicker-extend--footer-actions--confirm" (click)="updateValue()">
              {{"Update" | aynTranslate | async}}
            </button>
          </div>
        </footer>
      </section>

      <div class="ayn-datepicker-extend--time-sets-container">
        <ul
          class="ayn-datepicker-extend--time-sets"
          #timeSetContainer
          [style.width]="isCustomWidth ? timeSetAreaWidth + 'px' : null "
        >
          <li
            *ngFor="let time of timeSets"
            [class.active]="selectedTimeSet?.preset === time.preset"
            (click)="selectTimeSet(time)"
          >
            {{time.label | aynTranslate | async}}

            <ayn-icon name="check" *ngIf="selectedTimeSet === time.preset"></ayn-icon>
          </li>
        </ul>
      </div>
    </section>
  </ayn-overlay>
</section>
