<ayn-modal-page class="aayn-video-modal">
  <ng-template #body>
    <section class="aayn-video-modal--body">
      <div class="aayn-video-modal--video">
        <ayn-vimeo-player [vimeoVideoId]="videoId"></ayn-vimeo-player>
      </div>
      <ng-content select=".aayn-video-modal--title"></ng-content>
      <ng-content select=".aayn-video-modal--description"></ng-content>
      <ng-content select=".aayn-video-modal--actions"></ng-content>
    </section>
  </ng-template>
</ayn-modal-page>
