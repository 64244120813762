import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/lib/modules';
import { Base, Facebook, FacebookService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { IDetailedTargetingOverlayItemActive } from '@pages/create-ad/components/shared/manuel-audience-setup/components/detailed-targeting/components/detailed-targeting-overlay-item/detailed-targeting-overlay-item.component';
import { FacebookStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-detailed-targeting--facebook',
  templateUrl: 'detailed-targeting.component.html'
})
export class DetailedTargetingFacebookComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.facebookStateService.adCreationModel;

  @ViewChild(Overlay) overlay!: Overlay;

  protected detailedTargetingSearchControl = new FormControl('', [Validators.minLength(1)]);

  @Select(LoaderState.getAny(['GetDetailedTargetings']))
  loader$?: Observable<boolean>;

  protected detailedTargetings: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[] = [];

  constructor(
    private facebookStateService: FacebookStateService,
    private facebookService: FacebookService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.facebookStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.detailedTargetingSearchControl.valueChanges
      .pipe(
        filter((o: string | null) => (o || '').length > 0),
        debounceTime(350),
        distinctUntilChanged()
      )
      .subscribe((term) => {
        if (this.detailedTargetingSearchControl.invalid) return;

        this.facebookService
          .getDetailedTargetings$(term!)
          .pipe(map((o) => o.data))
          .subscribe((result) => {
            this.detailedTargetings = result.detailedTargetings;

            if (!this.overlay?.render) this.overlay.show(null);

            this.cdr.detectChanges();
          });
      });
  }

  private _mapDetailedTargetings() {
    this.form?.patchValue(
      {
        flexibleDetailedTargetings: this.adCreationModel.adSet.targeting.detailedTargetings.inclusions,
        excludedDetailedTargetings: this.adCreationModel.adSet.targeting.detailedTargetings.exclusions
      },
      { onlySelf: true }
    );
  }

  protected onIncludeClick(detailedTargeting: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem) {
    this.adCreationModel.adSet.targeting.detailedTargetings.inclusions.push(detailedTargeting);

    this._mapDetailedTargetings();
  }

  protected onExcludeClick(detailedTargeting: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem) {
    this.adCreationModel.adSet.targeting.detailedTargetings.exclusions.push(detailedTargeting);

    this._mapDetailedTargetings();
  }

  protected onIncludeRemove(detailedTargeting: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem) {
    const index = this.adCreationModel.adSet.targeting.detailedTargetings.inclusions.findIndex(
      (o) => o.id === detailedTargeting.id
    );
    this.adCreationModel.adSet.targeting.detailedTargetings.inclusions.splice(index, 1);

    this._mapDetailedTargetings();
  }

  protected onExcludeRemove(detailedTargeting: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem) {
    const index = this.adCreationModel.adSet.targeting.detailedTargetings.exclusions.findIndex(
      (o) => o.id === detailedTargeting.id
    );
    this.adCreationModel.adSet.targeting.detailedTargetings.exclusions.splice(index, 1);

    this._mapDetailedTargetings();
  }

  private getArrayNameByType(type: Base.IncludeOrExcludeType) {
    return `${type}`;
  }

  protected onCloseClickGroup(type: Base.IncludeOrExcludeType) {
    this.adCreationModel.adSet.targeting.detailedTargetings[this.getArrayNameByType(type)] = [];

    this._mapDetailedTargetings();
  }

  protected onCloseClick(type: Base.IncludeOrExcludeType, index: number) {
    this.adCreationModel.adSet.targeting.detailedTargetings[this.getArrayNameByType(type)].splice(index, 1);

    this._mapDetailedTargetings();
  }

  protected isActive(detailedTargeting: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem) {
    const hasAddedInclusions = this.adCreationModel.adSet.targeting.detailedTargetings.inclusions.some(
      (o) => o.id === detailedTargeting.id
    );
    const hasAddedExclusions = this.adCreationModel.adSet.targeting.detailedTargetings.exclusions.some(
      (o) => o.id === detailedTargeting.id
    );

    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'includes' : hasAddedExclusions ? 'excludes' : ''
    } as IDetailedTargetingOverlayItemActive;
  }
}
