import { Store } from '@ngxs/store';
import { BusinessState } from '@core/state/business.state';
import { Platforms } from '@core/models';

export const getLinkedinAdAccountId = (store: Store) => {
  const business = store.selectSnapshot(BusinessState.SelectedBusiness);
  const { externalId } =
    business?.connectedAdAccounts.find((adAccount) => adAccount.source === Platforms.LinkedIn) || {};
  if (externalId) {
    return +externalId.split(':')[3] || +externalId;
  }
  return null;
};
