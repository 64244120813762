import { Platforms } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  contextId: string;
  platform: Platforms;
};
type Output = boolean;

export const disconnectPlatform = makeMutation<Output, Input>(`
  mutation DisconnectPlatform(
    $contextId: String!
    $platform: Float!
  ) {
    disconnectPlatform(
      contextId: $contextId
      platform: $platform
    )
  }
`);
