import { Component, Input } from '@angular/core';
import { LinkedinAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/ad-preview/ad-preview.service';
import { Platforms } from '@core/models';
import { AdPreviewLinkedInPlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-carousel-ad-preview--linkedin',
  templateUrl: './carousel-ad-preview.component.html'
})
export class CarouselAdPreviewLinkedinComponent {
  @Input() businessName: string = '';
  @Input() profileImage: string = '';

  protected readonly Platforms = Platforms;

  platforms = [AdPreviewLinkedInPlatform];

  constructor(public adPreviewService: LinkedinAdPreviewService) {}
}
