import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aynDecimalPoint'
})
export class DecimalPointPipe implements PipeTransform {
  transform(value: number, lastDecimal = true): any {
    if (typeof value == 'undefined') return '';

    const arr = value.toFixed(2)?.split('.');

    if (arr.length <= 1) return '';

    return lastDecimal ? arr[arr.length - 1] : arr[0];
  }
}
