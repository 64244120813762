import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models/open-ai.model';

const queryField = 'getBrandDescription';
type Output = QueryResult<typeof queryField, { name: string; description: string; type: any }>;

export const getBrandDescriptionQuery = makeQuery<Output, OpenAI.UrlInput>(`
query GetBrandDescription($url: String!) {
  openAI {
    ${queryField}(url: $url) {
      name
      description
      type
    }
  }
}
`);
