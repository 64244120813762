import { MySubscription } from '@core/models';

import { makeQuery } from '../types';

type Output = MySubscription;

export const mySubscription = makeQuery<Output>(`  
query GetMySubscription {
  mySubscription {
    id
    packageId
    cancelledAt
    currentTermEnd
    package {
      id
      name
      displayName
      currencyCode
      degree
      period
      periodUnit
      price
      adSpendLimit
    }
    packageId
    status
    billingCompanyName
    billingEmail
    billingVatNumber
    billingFullName
    cardLast4
    totalDues
  }
}
`);
