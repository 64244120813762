import { filter } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { LoaderRemoveAll } from '@core/actions';
import { PwaService } from '@core/services/pwa.service';
import { Store } from '@ngxs/store';

import { environment } from '@environment';
import { ChargebeeClientService, I18NClientService } from './core';

@Component({
  selector: 'aayn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: Store,
    private chargebeeClientService: ChargebeeClientService,
    private i18nClientService: I18NClientService,
    private swUpdate: SwUpdate,
    private pwaService: PwaService
  ) {
    if (environment.production) {
      swUpdate.checkForUpdate().then((result) => {
        console.log('swUpdate.checkForUpdate:', result);
      });
    }
  }

  async ngOnInit() {
    if (environment.production) {
      try {
        const result = await this.swUpdate.activateUpdate();
        console.log('swUpdate.activateUpdate: ', result);
      } catch (error) {
        console.error('ayn:swUpdate.activeUpdate', error);
      }

      this.pwaService.init();

      (window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'ch8kplrr'
      });

      this.router.events.pipe(filter((o) => o instanceof NavigationEnd)).subscribe((_) => {
        (window as any).Intercom('update');
      });
    }

    this.chargebeeClientService.init();

    this.i18nClientService.changeLanguage();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new LoaderRemoveAll());
  }
}
