import { environment } from '@environment';

export function runIsDev(cb: () => void) {
  if (isDev()) {
    cb();
  }
}

export function isDev(): boolean {
  return environment.name == 'dev' || environment.name == 'testing';
}

export const draftLog = environment.draftLog ? console.log : () => {};
