<section class="aayn-audience--gender-selection">
  <button
    ayn-button="primary"
    icon="male"
    type="button"
    [class.active]="!isSelectedAllGenders && isSelectedMale"
    (click)="selectGender(Gender.Men)"
  >
    {{ 'Male' | translate }}
  </button>

  <button
    ayn-button="primary"
    icon="female"
    type="button"
    [class.active]="!isSelectedAllGenders && isSelectedFemale"
    (click)="selectGender(Gender.Women)"
  >
    {{ 'Female' | translate }}
  </button>

  <button
    ayn-button="primary"
    type="button"
    icon="all-genders"
    [class.active]="isSelectedAllGenders"
    (click)="selectAllGender()"
  >
    {{ 'All Genders' | translate }}
  </button>
</section>
