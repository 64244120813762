import { Component, Input } from '@angular/core';
import { TabModule } from '@ayn-ui/lib/modules';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { YoutubeAdsComponent } from './youtube-ads/youtube-ads.component';
import { SearchAdsComponent } from './search-ads/search-ads.component';
import { DisplayAdsComponent } from './display-ads/display-ads.component';

@Component({
  selector: 'aayn-ad-preview-google-tabs',
  templateUrl: './ad-preview-google-tabs.component.html',
  imports: [TabModule, NgTemplateOutlet, NgForOf, NgIf, YoutubeAdsComponent, SearchAdsComponent, DisplayAdsComponent],
  standalone: true
})
export class AdPreviewGoogleTabsComponent {
  @Input() availableTabs: Array<'search' | 'display' | 'youtube'> = ['search', 'display', 'youtube'];

  @Input() title = '';
  @Input() titles?: Array<string> = [];
  @Input() linkUrl = '';
  @Input() image = '';
  @Input() logoUrl = '';
  @Input() description = '';
  @Input() cta = 'CTA';
}
