<li class="aayn-audience--detailed-targeting--added-item">
  <ayn-icon name="dots-group"></ayn-icon>
  <span class="aayn-audience--detailed-targeting--added-item-name">{{ name }}</span>

  <span class="aayn-audience--detailed-targeting--added-item-category">{{ category | aynBeautyText }}</span>

  <button ayn-button [onlyIcon]="true" icon="cross" (click)="onCloseClick.emit()">
    <div class="aayn-audience--detailed-targeting--added-item-popup" *ngIf="tooltipOnHover">
      <ul>
        <li *ngIf="closeTooltipData?.size">
          <strong>{{ 'Size' | translate }}:</strong>&nbsp;
          <span>{{ closeTooltipData?.size }}</span>
        </li>
        <li *ngIf="closeTooltipData?.interesets">
          <strong>{{ 'Interests' | translate }}:</strong>
          {{ closeTooltipData?.interesets }}
        </li>
        <li *ngIf="closeTooltipData?.description">
          <strong>{{ 'Description' | translate }}:</strong>
          {{ closeTooltipData?.description }}
        </li>
      </ul>
    </div>
  </button>
</li>
