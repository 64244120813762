import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  businessPageDetail: Facebook.IBusinessPageDetail;
};

export const getBusinessPageDetail = makeQuery<Output>(`
  query GetBusinessPageDetail{
    facebook {
      businessPageDetail {
        id
        name
        category
        imageUrl
      }
    }
  }
`);
