import { Google } from '@core/models';
import { EdgeResult, makeQuery } from '@core/queries/types';

const queryField = 'adGroupsWithMetrics';

type Input = Google.Dashboard.IAdGroupWithMetricsRequest;
type Output = EdgeResult<typeof queryField, Google.Dashboard.IAdGroupWithMetric>;

export const adGroupsWithMetrics = makeQuery<Output, Input>(`
query GetAdGroupsWithMetrics(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $searchKey: String
  $startDate: SafeInt
  $endDate: SafeInt
  $status: GoogleInsightStatus
  $datePreset: DatePreset
  $campaignId: Float!
) {
  google {
    adGroupsWithMetrics(
      first: $first
      after: $after
      last: $last
      before: $before
      searchKey: $searchKey
      status: $status
      startDate: $startDate
      endDate: $endDate
      datePreset: $datePreset
      campaignId: $campaignId
    ) {
      edges {
        node {
          name
          id
          status
          type
          resourceName
          metrics {
            costPerClick
            clicks
            cost
            conversions
            impressions
            ctr
            interactions
            costPerConversion
          }
        }
      }
    }
  }
}
`);
