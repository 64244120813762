<section class="aayn-collapse-card" [ngClass]="{ active, disabled }">
  <header
    class="aayn-collapse-card--header"
    [ngClass]="{ 'aayn-collapse-card--header-right': collapseIconDirection === 'right' }"
    (click)="clickable ? this.changeStatus() : null"
  >
    <div class="aayn-collapse-card--header__collapse" *ngIf="isCollapseIcon">
      <span
        [ngClass]="
          'aayn-collapse-card--header__collapse-icon aayn-collapse-card--header__collapse-icon--' +
          collapseIconDirection
        "
      ></span>
    </div>

    <ng-content select="[leftHeader]"></ng-content>

    <div class="aayn-collapse-card--header__title" *ngIf="isShowHeader">
      <div class="aayn-collapse-card--header__title-icon" *ngIf="headerIcon">
        <ayn-icon [name]="headerIcon"></ayn-icon>
      </div>
      <div>
        <h4 class="aayn-collapse-card--header__title-bold">
          <ng-container *ngIf="isTitleTemplate; else elseTitle">
            <ng-container *ngTemplateOutlet="$any(title)"></ng-container>
          </ng-container>

          <ng-template #elseTitle>
            {{ $any(title) | translate }}
          </ng-template>

          <span class="aayn-collapse-card--header__title-sub-title" *ngIf="subTitle">{{ subTitle | translate }}</span>
        </h4>

        <p *ngIf="description">{{ description | translate }}</p>
      </div>
    </div>

    <div class="aayn-collapse-card--header__right">
      <ng-content select="[header]"></ng-content>

      <ng-container *ngIf="{ error: error$ | async } as data">
        <div class="aayn-collapse-card--header__right--error" *ngIf="data.error">
          <ayn-icon [name]="errorIcon"></ayn-icon>
          <span>{{ data.error }} </span>
          <button ayn-button="orange-outline" (click)="retryButton.emit($event)" icon="refresh">
            {{ errorButtonName }}
          </button>
        </div>
      </ng-container>
      <div *ngIf="comingSoon" class="aayn-collapse-card--header__right--coming-soon">
        <ayn-icon name="clock"></ayn-icon>
        <p class="aayn-collapse-card--header__right--coming-soon__text">{{ comingSoon }}</p>
      </div>
    </div>
  </header>

  <main class="aayn-collapse-card--main">
    <ng-content></ng-content>
  </main>
</section>
