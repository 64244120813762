import { pipe } from 'fp-ts/lib/function';
import { cloneDeep } from 'lodash';

import { Linkedin } from '@core/models';
import { KeyName } from '@core/models/base.model';

import { ApplyTypes, ApplyValidations, createAdBaseModel, UsesInitialization, WithMap } from '../common';
import { CreateAdCommon } from '../common/common';
import { ICreateAdCommonValidate } from '../common/types';

export module LinkedinCreateAd {
  export type AdRequest<AT = Linkedin.AdModelSingleImage> = {
    campaign: Linkedin.LinkedInCampaignCreationModel;
    ad: { creative: AT };
    conversionType: Linkedin.LinkedInConversion;
  };

  function createEmptyModel<AT extends Linkedin.AdTypeModels = Linkedin.AdModelSingleImage>() {
    return {
      ...createAdBaseModel(
        Linkedin.LinkedInCampaignObjectiveType.WEBSITE_VISIT,
        Linkedin.AudienceType.Manual,
        Linkedin.AdType.SingleImage
      ),

      campaign: {
        account: '',
        status: Linkedin.LinkedInCampaignStatus.Draft,
        name: '',
        unitCost: {
          amount: 0
        },
        type: Linkedin.LinkedInCampaignType.SPONSORED_UPDATES,
        costType: Linkedin.LinkedInCostType.CPM,
        creativeSelection: Linkedin.LinkedInCreativeSelection.Optimized,
        locale: {
          country: '',
          language: ''
        },
        dailyBudget: {
          amount: '0',
          currencyCode: ''
        },
        objectiveType: Linkedin.LinkedInCampaignObjectiveType.WEBSITE_VISIT,
        targetingCriteria: {
          exclude: {},
          include: {
            locales: ['en_US']
          }
        },
        totalBudget: {
          amount: '0',
          currencyCode: ''
        },
        offsiteDeliveryEnabled: false,
        audienceExpansionEnabled: false,
        runSchedule: {
          start: new Date().getTime(),
          end: null
        },
        /*
         linkedin objective optimizationTargetType mapping
         https://learn.microsoft.com/en-us/linkedin/marketing/integrations/ads/account-structure/campaign-objectives?view=li-lms-2023-04
         */
        optimizationTargetType: Linkedin.OptimizationTargetType.MAX_CLICK,
        budgetType: 'daily'
      } as Linkedin.LinkedInCampaignCreationModel,

      ad: {
        creative: {
          name: '',
          altText: '',
          campaign: '',
          commentary: '',
          ctaLabel: 'SEE_MORE',
          imageIds: [] as string[],
          images: [] as Linkedin.IdWithUrl[],
          title: '',
          carouselCards: [
            { title: '', altText: '', imageUrl: '', id: '' },
            { title: '', altText: '', imageUrl: '', id: '' }
          ],
          contentLandingPage: ''
        } as unknown as AT
      },

      audience: {
        name: '',
        geoLocations: {
          include: [] as Linkedin.CreateAd.SearchByFacetEdge[],
          exclude: [] as Linkedin.CreateAd.SearchByFacetEdge[]
        },
        ageRange: {
          include: [
            'urn:li:ageRange:(18,24)',
            'urn:li:ageRange:(25,34)',
            'urn:li:ageRange:(35,54)',
            'urn:li:ageRange:(55,2147483647)'
          ] as Linkedin.AgeRangeURN[]
        },
        gender: {
          include: [Linkedin.Targeting.Gender.Men, Linkedin.Targeting.Gender.Women] as Linkedin.Targeting.Gender[]
        },
        detailedTargetings: {
          include: [] as Linkedin.CreateAd.SearchByFacetEdge[],
          exclude: [] as Linkedin.CreateAd.SearchByFacetEdge[]
        },
        locales: {
          include: [] as KeyName[]
        }
      },

      conversionType: {} as Linkedin.LinkedInConversion
    };
  }

  export type ICreateAdModel<AT extends Linkedin.AdTypeModels = Linkedin.AdModelSingleImage> = ReturnType<
    typeof createEmptyModel<AT>
  >;

  const deepClonedModel = cloneDeep({ ...createEmptyModel() });

  @UsesInitialization(deepClonedModel)
  @ApplyTypes<ICreateAdModel, AdRequest, Linkedin.AdType>({
    ad: {
      [Linkedin.AdType.SingleImage]: {
        ad: {
          creative: {
            altText: '',
            campaign: '',
            commentary: '',
            ctaLabel: '',
            imageId: '',
            title: ''
          }
        }
      },
      [Linkedin.AdType.CarouselImage]: {
        ad: {
          creative: {
            carouselCards: [
              { title: '', altText: '', imageUrl: '', id: '' },
              { title: '', altText: '', imageUrl: '', id: '' }
            ],
            name: '',
            ctaLabel: Linkedin.CtaLabelEnum.SEE_MORE
          }
        }
      }
    }
  })
  @WithMap({
    [Linkedin.AdType.SingleImage]: function (adCreationModel: ICreateAdModel) {
      return pipe(adCreationModel, cloneDeep, Linkedin.mapDraftCampaignToOriginalModel, Linkedin.mapToAudience);
    },
    [Linkedin.AdType.CarouselImage]: function (adCreationModel: ICreateAdModel<Linkedin.AdModelCarouselImage>) {
      return pipe(
        adCreationModel,
        cloneDeep,
        Linkedin.mapDraftCampaignToOriginalModel,
        Linkedin.mapImagesToLinkedinImageModel,
        Linkedin.mapToAudience
      );
    }
  })
  @ApplyValidations({
    ad: {
      [Linkedin.AdType.SingleImage]: function (model: ICreateAdModel) {
        return !!model.ad.creative?.images?.length;
      },
      [Linkedin.AdType.CarouselImage]: function (model: ICreateAdModel<Linkedin.AdModelCarouselImage>) {
        return model.ad.creative.carouselCards.every((card) => !!card.imageUrl && !!card.id);
      }
    }
  })
  export class CreateAd {
    get isValidImages(): boolean {
      return this.validate('ad', this.createAdModel.selectedTypes.ad, this.createAdModel);
    }
  }

  export interface CreateAd
    extends CreateAdCommon<
      ICreateAdModel<Linkedin.AdModelSingleImage | Linkedin.AdModelCarouselImage>,
      AdRequest<Linkedin.AdModelSingleImage | Linkedin.AdModelCarouselImage>,
      Linkedin.AdType
    > {
    mapToApiRequest(type?: string | number): AdRequest<Linkedin.AdModelSingleImage | Linkedin.AdModelCarouselImage>;
  }

  export interface CreateAd extends ICreateAdCommonValidate {}
}
