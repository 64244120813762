<ayn-form-field>
  <ayn-select
    [class]="'aayn-language-selection'"
    (searchControlChange)="searchValueChange($event)"
    (valueChange)="valueChange($event)"
    [loading]="!!(loader$ | async)"
    [placeholder]="
      adCreationModel.audience.create.languageIds.length
        ? adCreationModel.audience.create.languageIds.length + ' Language Selected'
        : 'Choose language'
    "
    [dontSelect]="true"
    [hideOnSelectOverlay]="false"
  >
    <ayn-option *ngFor="let option of languages" [value]="option" [disabled]="optionDisabled(option)">
      {{ option.label }}

      <button ayn-button icon="plus">Add Language</button>
    </ayn-option>
  </ayn-select>
</ayn-form-field>

<ayn-badge-list
  [list]="adCreationModel.audience.create.languageIds"
  (onRemoveItem)="removeLocale($event)"
></ayn-badge-list>

<footer class="aayn-language-selection--footer" *ngIf="!adCreationModel.audience.create.languageIds.length">
  Selected Language

  <b>
    All Languages
    <ayn-icon name="globe"></ayn-icon>
  </b>
</footer>
