import { Observable, ReplaySubject } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LazyAssetService {
  private _loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  /**
   * Load a 3rd party integrations script
   * @param url Target Asset Url
   * @returns {Observable}
   */
  loadScript(url: string) {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    // script.async = true;
    script.src = url;

    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    this.document.head.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  /**
   * Load a 3rd party integrations styles
   * @param url Target Asset Url
   * @returns {Observable<void>}
   */
  loadStyle(url: string) {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }
    this._loadedLibraries[url] = new ReplaySubject();
    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };
    const head = this.document.getElementsByTagName('head')[0];
    head.appendChild(style);
    return this._loadedLibraries[url].asObservable();
  }
}
