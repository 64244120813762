import { Component, OnInit } from '@angular/core';
import { GoogleService, IPlatformContext, WindowService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aayn-create-account--google',
  templateUrl: 'create-account.component.html'
})
export class CreateAccountGoogleComponent implements OnInit {
  protected platformContext?: IPlatformContext;

  protected accountCreationLink?: string;

  @Select(LoaderState.getAny(['GetAdAccountCreationLink'])) loader$?: Observable<boolean>;

  constructor(
    public modal: NgbActiveModal,
    private googleService: GoogleService,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.googleService.getAdAccountCreationLink(this.platformContext?.id!).subscribe((result) => {
      if (result.error) return;

      this.accountCreationLink = result.data.adAccountCreationLink;
    });
  }

  openAccountCreationWindow() {
    if (!this.accountCreationLink) return;

    this.windowService.openCenteredWindow(this.accountCreationLink, 'Create a New Ad Account', 450, 600);
  }
}
