import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ayn-modal-page',
  templateUrl: './modal-page.html'
})
export class ModalPageComponent implements AfterViewInit, OnDestroy {
  @ContentChild('header', { static: true })
  headerTemplate?: TemplateRef<ElementRef<HTMLElement[]>>;

  @ContentChild('body', { static: true })
  bodyTemplate?: TemplateRef<ElementRef<HTMLElement[]>>;

  @ContentChild('footer', { static: true })
  footerTemplate?: TemplateRef<ElementRef<HTMLElement[]>>;

  @ViewChild('modalPage')
  modalPage!: TemplateRef<any>;

  @Input()
  options: NgbModalOptions = {};

  @Output()
  tntModalClose = new EventEmitter<{ reason: string }>();

  modalRef?: NgbModalRef;

  constructor(
    private readonly modalService: NgbModal,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modalPage, this.options);
    this.modalRef.dismissed.pipe(untilDestroyed(this)).subscribe((reason: string) => {
      if (reason !== 'destroy') {
        this.closeModalRoute();
      }
      this.tntModalClose.emit({ reason });
    });
    this.modalRef.closed.pipe(untilDestroyed(this)).subscribe(() => {
      this.closeModalRoute();
    });
  }

  closeModal() {
    this.modalRef?.dismiss('requested');
  }

  private closeModalRoute() {
    const outlet: any = {};
    outlet[this.activatedRoute.outlet] = null;
    return this.router.navigate([{ outlets: outlet }], {
      replaceUrl: true,
      relativeTo: this.activatedRoute.parent,
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy(): void {
    this.modalRef?.dismiss('destroy');
    this.tntModalClose.complete();
  }
}

@NgModule({
  declarations: [ModalPageComponent],
  exports: [ModalPageComponent],
  imports: [CommonModule]
})
export class ModalPageModule {}
