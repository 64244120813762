import { makeQuery, OffsetPaginatedQueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'adGroupsWithMetrics';
type Input = TikTok.Dashboard.TikTokAdGroupsWithMetricsRequest;
type Output = OffsetPaginatedQueryResult<
  typeof queryField,
  TikTok.Dashboard.TikTokCampaignEntityCommonSummaryOutputDto
>;
export const adGroupsWithMetricsQuery = makeQuery<Output, Input>(`
query AdGroupsWithMetricsTikTok(
  $skip: Int
  $take: Int
  $searchKey: String
  $startDate: SafeInt!
  $endDate: SafeInt!
  $campaignId: String!
) {
  tiktok {
    ${queryField}(
      skip: $skip
      take: $take
      startDate: $startDate
      endDate: $endDate
      searchKey: $searchKey
      campaignId: $campaignId
    ) {
      edges {
        node {
          name
          id
          status
          budget
          budgetMode
          startDate
          clicks
          costPerClick
          impressions
          conversion
          purchase
          result
          spend
          endDate
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        from
        to
      }
    }
  }
}
`);
