<div class="aayn-tiktok-identity-select">
  <ng-container *ngIf="!!identities.length; else createIdentity">
    <ayn-form-field>
      <ayn-select
        [setValueHtml]="true"
        [(ngModel)]="selectedIdentity"
        (ngModelChange)="onChange($event)"
        searchPlaceholder="Type to search business names"
        #aynSelect="aynSelect"
        containerClassName="aayn-tiktok-identity-select--element"
      >
        <ng-container ayn-select-header>
          <button
            class="ayn-option aayn-tiktok-identity-select--element-create-option"
            type="button"
            *ngIf="!showCreate"
            (click)="showCreate = true"
          >
            <div class="ayn-option--inner">
              {{ 'New Business Logo & Name +' | translate }}
            </div>
          </button>
          <ng-container
            *ngIf="showCreate"
            [ngTemplateOutlet]="createIdentity"
            [ngTemplateOutletContext]="{ select: aynSelect }"
          >
          </ng-container>
        </ng-container>
        <ayn-option *ngFor="let identity of identities | aynOptionFilter | async" [value]="identity">
          <img class="aayn-tiktok-identity-select--element--image" [src]="identity.profileImage" />
          {{ identity.displayName }}
        </ayn-option>
      </ayn-select>
    </ayn-form-field>
  </ng-container>
</div>

<ng-template #createIdentity let-select="select">
  <form
    [formGroup]="identityForm"
    #formElement="ngForm"
    (ngSubmit)="saveIdentity(select)"
    [attr.standAloneForm]="!!select"
  >
    <div class="aayn-tiktok-identity-select--create">
      <aayn-file-upload-item
        className="aayn-tiktok-file-upload-item"
        [maxImageSize]="{ width: 1024 }"
        [maintainAspectRatio]="true"
        [aspectRatio]="ratio"
        [isPrimary]="false"
        [isError]="formElement.submitted && !identityImage.id"
        (fileAdded)="uploadFile($event, select)"
        (remove)="removeIdentityImage()"
        [selectedFileUrl]="identityImage.url"
        [progressItem]="identityProgressItem"
        (cropperOpen)="cropperOpen(select)"
        [cropperScalable]="true"
        canvaDesignType="InstagramPost"
      >
        <div class="aayn-file-upload-item--custom-content">
          <ng-container *ngIf="!identityProgressItem.startedUploading">
            <ayn-icon name="upload-icon-outline"></ayn-icon>
            <span>Logo</span>
          </ng-container>
        </div>
      </aayn-file-upload-item>

      <ayn-form-field>
        <input type="text" formControlName="name" ayn-input [placeholder]="'Business Name *' | translate" />
      </ayn-form-field>

      <button
        ayn-button="white"
        type="submit"
        class="aayn-tiktok-identity-select--create-button"
        icon="save-filled"
        [loading]="identityLoader$ | async"
        loadingColor="dark"
        [disabled]="!identityForm.valid || identityProgressItem.loading || (identityLoader$ | async)"
      ></button>
    </div>
  </form>
</ng-template>
