import { Bing } from '@core/models/platforms/bing';
import { makeMutation } from '@core/queries/types';

type Input = Bing.Business.IAdAccountRequest;
type Output = Bing.Business.IAdAccountResponse;

export const adAccountsBing = makeMutation<Output, Input>(`
query AdAccounts($contextId:String!) {
  bing {
    adAccounts(contextId: $contextId) {
      accessRole
      adAccountId
      name
      loginCustomerId
      status
      userStatus
    }
  }
}
`);
