<li
  [ngClass]="getActiveClassNames"
  (click)="includeClick()"
  [class.active]="active?.isActive"
  [class.inactive]="inactive"
>
  <p class="aayn-audience--detailed-targeting-text">
    <ng-content></ng-content>
  </p>

  <div class="aayn-audience--detailed-targeting--info">
    <span class="aayn-audience--detailed-targeting--type" *ngIf="type">{{ type | aynBeautyText }}</span>
    <span class="aayn-audience--detailed-targeting--value" *ngIf="value">{{ value | number }}</span>
  </div>

  <div class="aayn-audience--detailed-targeting--li-actions">
    <button
      ayn-button
      type="button"
      class=":inclusion-btn"
      icon="plus"
      (click)="includeClick($event)"
      [disabled]="active?.isActive && active?.type == 'excludes'"
      *ngIf="isIncludeShow"
    >
      {{ active?.isActive && active?.type == 'includes' ? 'Added' : ('Include' | translate) }}
    </button>

    <button
      ayn-button
      type="button"
      class=":exclusion-btn"
      icon="minus"
      (click)="excludeClick($event)"
      [disabled]="active?.isActive && active?.type == 'includes'"
      *ngIf="isExcludeShow"
    >
      {{ active?.type == 'excludes' ? 'Added' : ('Exclude' | translate) }}
    </button>

    <ng-content select="[actions]"></ng-content>
  </div>
</li>
