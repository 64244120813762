<ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.InstantAd">
      <div
        class="aayn-instant-ad-platform-header"
        [ngClass]="{
          'aayn-instant-ad-platform-header-one': instantAdImages?.length === 1,
          'aayn-instant-ad-platform-header-two': instantAdImages?.length == 2,
          'aayn-instant-ad-platform-header-three': (instantAdImages?.length || 0) > 2
        }"
      >
        <ng-container *ngFor="let image of instantAdImages; index as i">
          <img [ngClass]="'aayn-instant-ad-platform-header-image image-' + (i + 1)" [ayn-adc-image-tracker]="image" />
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
