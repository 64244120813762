<aayn-ad-preview-card [selectedPlatform]="Platforms.TikTok" [platforms]="platforms">
  <aayn-ad-preview-tiktok-single
    [imageUrls]="adPreviewService.dynamicCreativeAds.images || []"
    [headline]="adPreviewService.dynamicCreativeAds.adText || ''"
    [cta]="adPreviewService.dynamicCreativeAds.callToAction || ''"
    [websiteUrl]="adPreviewService.dynamicCreativeAds.url || ''"
    [businessName]="adPreviewService.dynamicCreativeAds.identity?.displayName || ''"
    [businessLogo]="adPreviewService.dynamicCreativeAds.identity?.profileImage || ''"
  >
  </aayn-ad-preview-tiktok-single>
</aayn-ad-preview-card>
