<div
  [ngStyle]="style"
  [ngClass]="{
    'ayn-checkbox': true,
    'ayn-checkbox--checked': checked,
    'ayn-checkbox--disabled': disabled,
    'ayn-checkbox--focused': focused
  }"
  (click)="onClick($event, cb, true)"
  [class]="checkBoxType || ''"
>
  <div class="ayn-hidden-accessible">
    <input
      #cb
      type="checkbox"
      [attr.id]="inputId"
      [attr.name]="name"
      [readonly]="readonly"
      [value]="value"
      [checked]="checked"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (change)="handleChange($event)"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
      [attr.aria-labelledby]="ariaLabelledBy"
      [attr.aria-label]="ariaLabel"
      [attr.aria-checked]="checked"
      [attr.required]="required"
    />
  </div>
  <label *ngIf="labelShow">{{ label }} </label>
  <div
    class="ayn-checkbox-box"
    [ngClass]="{
      'ayn-highlight': checked,
      'ayn-disabled': disabled,
      'ayn-focus': focused
    }"
  ></div>
  <ng-content></ng-content>
</div>
