import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'customAudienceSearch';

type Input = TikTok.CreateAd.Backend.CustomAudienceSearch;
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokCustomAudienceSearchOutputDto[]>;

export const customAudienceSearchQuery = makeQuery<Output, Input>(`
query TikTokCustomAudienceSearch($limit:SafeInt) {
  tiktok {
    customAudienceSearch(limit: $limit){
      name
      id
      type
    }
  }
}
`);
