import { IAllBusinessesResponse } from '@core/models';

import { makeQuery } from '../types';

type Output = IAllBusinessesResponse;

export const allBusinesses = makeQuery<Output>(`
query {
  allBusinesses {
    id
    name
    connectedAdAccounts {
        contextId
        currency
        externalId
        id
        source
        status
        pageId
        instagramAccountId
    }
    category
  }
}
`);
