import { IOption } from '@ayn-ui/public-api';

import { IPlatformContext } from './platform-context.model';

export type IAllBusinessesResponse = IBusiness[];
export type IGetBusinessByIdResponse = IBusiness;

export type IGetBusinessBrandResponse = IUpdateBusinessBrandResponse;

export type ICreateBusinessRequest = {
  data: Pick<IBusiness, 'name' | 'category' | 'description'>;
};

export interface ICreateBusinessBrandRequest {
  brand: IBusinessBrandInput;
  businessId: string;
}

export interface ICreateBusinessBrandResponse {
  brandUrl: string;
  color1: string;
  color2: string;
}

export interface IUpdateBusinessBrandRequest {
  brand: IBusinessBrandInput;
  id: string;
}

export interface IUpdateBusinessBrandResponse {
  brandUrl: string;
  color1: string;
  color2: string;
  id: string;
}

export type ICreateBusinessResponse = string;

export interface IUpdateBusinessRequest {
  data: Omit<IBusiness, 'id' | 'url' | 'color1' | 'color2' | 'createdAt' | 'connectedAdAccounts' | 'brandId'>;
  id: string;
}

export interface IUpdateBusinessResponse {
  data: {
    update: string;
  };
}

export interface IDeleteBusinessRequest {
  id: string;
}

export interface IDeleteBusinessResponse {
  data: {
    delete: string;
  };
}

export interface IBusinessBrandInput {
  brandUrl?: string;
  color1: string;
  color2: string;
}

export interface IConnectedAdAccount<CT = string> extends IPlatformContext {
  currency: CT; // TODO: CurrencyType yapılabilir!
  contextId: string;

  pageId?: string;
  instagramAccountId?: string;
}

export interface IBusiness {
  id: string;
  name: string;
  connectedAdAccounts: IConnectedAdAccount[];
  category: BusinessCategory;
  createdAt: string | Date;
  description?: string;
  url?: string;
  color1?: string;
  color2?: string;
  brandId?: string;
}

export enum BusinessCategory {
  LocalBusiness = 1,
  Startup,
  Freelancer,
  Agency,
  RealEstate,
  Enterprise,
  Other
}

export const BusinessCategoryMap: Record<BusinessCategory, string> = {
  [BusinessCategory.LocalBusiness]: 'Local Business',
  [BusinessCategory.Startup]: 'Startup',
  [BusinessCategory.Freelancer]: 'Freelancer',
  [BusinessCategory.Agency]: 'Agency',
  [BusinessCategory.RealEstate]: 'Real Estate',
  [BusinessCategory.Enterprise]: 'Enterprise',
  [BusinessCategory.Other]: 'Other'
};

export const BusinessCategories = Object.keys(BusinessCategoryMap).map(
  (key) => ({ label: BusinessCategoryMap[key as unknown as BusinessCategory], value: +key } as IOption)
);
