import { makeMutation, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'turnOn';
type Input = TikTok.Dashboard.TikTokTurnOnOffRequest;
type Output = QueryResult<typeof queryField, boolean>;

export const turnOnQuery = makeMutation<Output, Input>(`
mutation TikTokTurnOn($resourceIds: [String!]!, $resourceType: TikTokIdResourceType!) {
    tiktok {
        ${queryField}(resourceIds: $resourceIds, resourceType: $resourceType)   
    }
}
`);
