import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ChargebeeService } from '@core/services';
import { LoaderState } from '@core/state/loader.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { CancelSubscriptionModalComponent } from '@shared/components/cancel-subscription-modal';

import { UpdatePaymentInfoComponent } from '../update-payment-info';

@Component({
  selector: 'aayn-unpaid-invoices-alert',
  templateUrl: 'unpaid-invoices-alert.component.html'
})
export class UnpaidInvoicesAlertComponent implements OnInit {
  @Select(LoaderState.getAny('CollectUnpaidInvoices')) loader$?: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {}

  tryAgain() {
    this.chargebeeService.collectUnpaidInvoices$().subscribe((result) => {
      if (result.data) {
        this.toastrService.success('Unpaid invoices have been collected successfully.', 'Successful Collected');

        this.modalService.dismissAll();
      } else {
        this.toastrService.error(
          'Unpaid invoices could not be collected successfully. Please update your credit card information.'
        );

        this.openUpdatePaymentInfoModal();
      }
    });
  }

  openCancelModal() {
    this.modalService.open(CancelSubscriptionModalComponent, {
      modalDialogClass: 'aayn-cancel-subscription-modal'
    });
  }

  openUpdatePaymentInfoModal() {
    this.modalService.open(UpdatePaymentInfoComponent, {
      modalDialogClass: 'aayn-modal-for-payments',
      backdrop: 'static'
    });
  }

  logout() {
    this.authService.logoutWithOperations();
  }
}
