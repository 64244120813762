import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FacebookCreateAd } from '@core/ad-platforms';
import { FacebookService } from '@core/index';
import { Facebook } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-existing-post--facebook-tab',
  templateUrl: 'facebook-tab.component.html'
})
export class ExistingPostFacebookTabComponent implements OnInit {
  protected pagePosts: Facebook.IPagePost[] = [];

  protected adCreationModel = this.facebookStateService
    .adCreationModel as unknown as FacebookCreateAd.AdRequest<Facebook.AdExistingPostPage>;

  @Select(LoaderState.getAny(['GetPagePosts'])) loader$?: Observable<boolean>;

  protected callToActions = Facebook.CallToActionTypes;

  protected term = '';
  protected postIdTerm = '';

  constructor(
    private facebookService: FacebookService,
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService
  ) {}

  ngOnInit() {
    this.facebookService.getPagePosts$({}).subscribe((result) => {
      this.pagePosts = result.data.pagePosts.edges.map((o) => o.node);
    });
  }

  onClickPagePost(pagePost: Facebook.IPagePost) {
    this.adCreationModel.ad.creative.pagePostId = pagePost.id;
  }
}
