<section class="aayn-notifications">
  <button
    ayn-button
    class="aayn-notifications-btn"
    [ngClass]="{ unread: true, active: overlay.render }"
    (click)="overlay.toggle($event)"
  >
    <ayn-icon name="bell"></ayn-icon>
  </button>

  <ayn-overlay #overlay styleClass="aayn-notifications--body" [useOverlayBlur]="true">
    <header class="aayn-notifications--body-header">
      <ayn-icon name="bell"></ayn-icon>
      Notification

      <button
        ayn-button
        [onlyIcon]="true"
        class="aayn-notifications--body-header-close"
        (click)="overlay.hide()"
        title="Close"
      >
        <ayn-icon name="cross"></ayn-icon>
      </button>
    </header>

    <main class="aayn-notifications--body-main">
      <ng-container
        *ngIf="
          { newNotifications: newNotifications$ | async, notifications: notifications$ | async } as data;
          else noContent
        "
      >
        <aayn-notification-list title="News">
          <aayn-notification-item
            *ngFor="let notification of data.newNotifications"
            [notification]="notification"
            [isNew]="true"
          ></aayn-notification-item>
        </aayn-notification-list>

        <aayn-notification-list title="Earlier">
          <aayn-notification-item
            *ngFor="let notification of data.notifications"
            [notification]="notification"
          ></aayn-notification-item>
        </aayn-notification-list>
      </ng-container>
      <ng-template #noContent><aayn-empty-content></aayn-empty-content> </ng-template>
    </main>
  </ayn-overlay>
</section>
