import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FacebookService } from '@core/index';
import { Facebook } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-ad-preview--facebook',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewFacebookComponent implements OnInit {
  @Input() selectedAdType: Facebook.AdType = Facebook.AdType.DynamicCreative;

  protected AdTypes = Facebook.AdType;

  protected businessPageDetail?: Facebook.IBusinessPageDetail;

  constructor(private facebookService: FacebookService, private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.facebookService
      .getBusinessPageDetail$()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.businessPageDetail = result.data.businessPageDetail;
        this.cdr.detectChanges();
      });
  }
}
