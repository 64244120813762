import { Base, Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Base.IPaginationRequest;
type Output = Google.CreateAd.IGoogleYoutubeVideoSearchResponse;

export const youtubeSearch = makeMutation<Output, Input>(`
query YoutubeSearch(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $searchKey: String!
) {
  google {
    youtubeSearch(
      first: $first
      after: $after
      last: $last
      before: $before
      searchKey: $searchKey
    ) {
      edges {
        cursor
        node {
          id
          title
          description
          channelId
          channelTitle
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}

`);
