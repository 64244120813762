<aayn-modal-for-payments color="var(--orange)" [showModalClose]="false" class="aayn-unpaid-invoices-alert">
  <ayn-vimeo-player vimeoVideoId="817578443"></ayn-vimeo-player>

  <h5 class="aayn-unpaid-invoices-alert--title">You have unpaid invoices!</h5>

  <h6 class="aayn-unpaid-invoices-alert--subtitle">Your account is blocked due to unpaid invoices.</h6>

  <h5 class="aayn-unpaid-invoices-alert--title-2">What to do now?</h5>

  <p class="aayn-unpaid-invoices-alert--description">
    You can try to charge your saved payment method again or you can add a new payment method to continue.
  </p>

  <div class="aayn-unpaid-invoices-alert--buttons">
    <button ayn-button="accent" icon="credit-card" (click)="openUpdatePaymentInfoModal()">Update Payment Info</button>

    <button
      ayn-button="primary"
      icon="circle-chevron-right"
      (click)="tryAgain()"
      [disabled]="loader$ | async"
      [loading]="loader$ | async"
    >
      Try Again
    </button>
  </div>

  <p class="aayn-unpaid-invoices-alert--footer-text">
    You can cancel your account <a (click)="openCancelModal()">here</a>, and download your invoices
    <a href="#">here</a>.
  </p>

  <div footer>
    <a (click)="logout()">Logout</a>
  </div>
</aayn-modal-for-payments>
