import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Linkedin } from '@core/models';
import { LinkedinStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-gender-selection--linkedin',
  template: `
    <section class="aayn-audience--gender-selection">
      <button
        ayn-button="primary"
        type="button"
        icon="male"
        [class.active]="!isSelectedAllGenders && isSelectedMale"
        (click)="selectGender(Gender.Men)"
      >
        {{ 'Male' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="female"
        [class.active]="!isSelectedAllGenders && isSelectedFemale"
        (click)="selectGender(Gender.Women)"
      >
        {{ 'Female' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="all-genders"
        [class.active]="isSelectedAllGenders"
        (click)="selectAllGender()"
      >
        {{ 'All Genders' | translate }}
      </button>
    </section>
  `
})
export class GenderSelectionLinkedinComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.linkedinStateService.adCreationModel;

  protected Gender = Linkedin.Targeting.Gender;

  get genderInclude() {
    return this.adCreationModel?.audience?.gender?.include;
  }

  constructor(private linkedinStateService: LinkedinStateService) {}

  ngOnInit() {
    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  protected get isSelectedMale() {
    return this.genderInclude.some((o) => o == Linkedin.Targeting.Gender.Men);
  }

  protected get isSelectedFemale() {
    return this.genderInclude.some((o) => o == Linkedin.Targeting.Gender.Women);
  }

  protected get isSelectedAllGenders() {
    return this.genderInclude.length == 2;
  }

  protected selectGender(gender: Linkedin.Targeting.Gender) {
    this.adCreationModel.audience.gender.include = [gender];

    this.form?.patchValue({
      genders: this.genderInclude
    });
  }

  protected selectAllGender() {
    this.adCreationModel!.audience.gender.include = [Linkedin.Targeting.Gender.Men, Linkedin.Targeting.Gender.Women];

    this.form?.patchValue({
      genders: this.adCreationModel?.audience.gender.include
    });
  }
}
