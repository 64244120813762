<aayn-ad-preview-card [selectedPlatform]="Platforms.LinkedIn" [platforms]="platforms">
  <aayn-ad-preview-linkedin-carousel
    [headline]="adPreviewService.carouselAds.title || ''"
    [cards]="adPreviewService.carouselAds.cards || []"
    [businessName]="businessName"
    [businessImage]="profileImage"
    [activeIndex]="(adPreviewService.carouselAds.activeIndex$ | async) || 0"
    (activeIndexChange)="adPreviewService.carouselAds.activeIndex$.next($event)"
  >
  </aayn-ad-preview-linkedin-carousel>
</aayn-ad-preview-card>
