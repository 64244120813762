import { Subject } from 'rxjs';

interface IError {
  message: string;
}

export abstract class BaseComponent {
  private error = new Subject<IError>();

  protected error$ = this.error.asObservable();

  constructor() {}

  protected setError(error: IError) {
    this.error.next(error);
  }
}
