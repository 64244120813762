<section class="create-account-modal">
  <section class="create-account-modal--content">
    <h5 class="create-account-modal--title">{{ 'Create a New Ad Account' | translate }}</h5>
    <p class="create-account-modal--description">
      {{
        'After setting up your time zone and billing details, close the Google popup, click “My Account is Ready!” button
      below to start using your ad account.'
          | translate
      }}
    </p>

    <ayn-modal-close [modal]="modal"></ayn-modal-close>

    <section class="create-account-modal--buttons">
      <button
        ayn-button="accent"
        icon="chevron-right"
        [disabled]="!accountCreationLink?.length"
        [loading]="!!(loader$ | async)"
      >
        {{ 'Start Creating' | translate }}
      </button>
      <button ayn-button="primary" icon="chevron-right" (click)="modal.close()">My Account Is Ready</button>
    </section>
  </section>
</section>
