import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { minWordValidator } from '@auth/utils/form-validators/min-word';
import { ChargebeeService } from '@core/services';
import { GetMySubscription } from '@core/state';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { CanceledSubscriptionModalComponent } from '../canceled-subscription-modal';
import { WelcomeBackModalComponent } from '../welcome-back-modal';

@Component({
  selector: 'aayn-cancel-subscription-modal',
  templateUrl: 'cancel-subscription-modal.component.html'
})
export class CancelSubscriptionModalComponent implements OnInit {
  form = new FormGroup({
    reason: new FormControl('', [Validators.required, Validators.minLength(10), minWordValidator(3)])
  });

  @Select(LoaderState.getAny('CancelSubscription')) cancelSubscriptionLoader$?: Observable<boolean>;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService,
    private store: Store
  ) {}

  ngOnInit() {}

  cancelSubscription() {
    if (this.form.invalid || !this.form.value.reason?.trim().length) return;

    this.chargebeeService.cancelSubscription$(this.form.controls.reason.value!).subscribe(() => {
      this.store.dispatch(new GetMySubscription());

      this.modalService.open(CanceledSubscriptionModalComponent, {
        backdropClass: 'aayn-cancel-subscription-modal--backdrop',
        modalDialogClass: 'aayn-cancel-subscription-modal',
        backdrop: 'static'
      });

      this.modal.close();
    });
  }

  applyDiscount() {
    this.chargebeeService.applyCancellationDiscount$().subscribe((result) => {
      if (result.data) {
        this.modalService.open(WelcomeBackModalComponent, {
          modalDialogClass: 'aayn-cancel-subscription-modal aayn-welcome-back-modal',
          backdrop: 'static'
        });

        this.modal.close();
      } else {
        this.toastrService.error('There was a problem applying the cancellation discount. Please try again later.');
      }
    });
  }
}
