<aayn-modal-for-payments
  title="Update Payment Info"
  description="Update your payment details to maintain your access to ADYOUNEED."
>
  <aayn-payment-form
    buttonType="primary"
    [isRouteAfterSuccess]="false"
    [showAppsumoInput]="false"
    [submitButtonProps]="{ text: 'Update Payment Info', icon: 'credit-card' }"
    (onSuccess)="modal.close()"
  ></aayn-payment-form>
</aayn-modal-for-payments>
