import { Component, Input } from '@angular/core';

@Component({
  selector: 'aayn-ad-type-section',
  templateUrl: './ad-type-section.component.html'
})
export class AdTypeSectionComponent {
  @Input() header = '';
  @Input() description = '';
  @Input() icon?: string;
  @Input() containerClass = '';
  @Input() iconDirection: 'left' | 'right' = 'left';
}
