import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetPlatformContexts, UpdatePlatformStatus } from '@core/state/platform-context.state';
import { environment } from '@environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { PlatformReconnectionModalComponent } from '@shared/components';

import { ContextStatus } from '../models/platform-context.model';
import { EventHubEventName } from '../models/event.model';
import { Platforms } from '../models/platforms.model';
import { GraphQLService } from '../backend/graphql/graphql-service';
import * as Query from '../queries';
import { ConfettiService } from './confetti.service';
import { AdcImageEventService } from '@core/services/adc-image-event.service';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(
    private gqlService: GraphQLService,
    private store: Store,
    private router: Router,
    private confettiService: ConfettiService,
    private modalService: NgbModal,
    private adcImageEventService: AdcImageEventService
  ) {}

  subscribe$() {
    return this.gqlService.subscription(Query.event()).subscribe(({ data: result }) => {
      if (!environment.production) {
        console.log(`[Layout.EventSubscription]: `, result);
      }

      const eventName = result!.eventName;

      switch (eventName) {
        case EventHubEventName.AccountConnected:
          this.store.dispatch(new GetPlatformContexts());

          this.confettiService.fireRealisticLook();

          const hasValidRoute =
            this.router.url.includes('/main/create-ad') ||
            this.router.url.includes('/main/business/create') ||
            this.router.url.includes('/main/business/update');

          if (hasValidRoute && result?.data.value) {
            const platform = result?.data.value?.source || Platforms.Unset;

            this.router.navigate([
              {
                outlets: {
                  dialog: `ad-account-select-modals/${Platforms[platform]}`
                }
              }
            ]);
          }
          break;

        case EventHubEventName.AccountStatusUpdated:
          this.store.dispatch(
            new UpdatePlatformStatus({ source: result?.data.value.platform, status: result?.data.value.newStatus })
          );

          if (result?.data.value.newStatus == ContextStatus.RequireReConnect) {
            this.modalService.open(PlatformReconnectionModalComponent, {
              modalDialogClass: 'modal-v3',
              backdrop: 'static',
              keyboard: false
            });
          }

          break;

        case EventHubEventName.EmailConfirmed:
          this.router.navigate(['/main/dashboard']);
          break;

        default:
          break;
      }
    });
  }
}
