<section
  class="aayn-select-platform-campaign-item"
  [ngClass]="{
    'aayn-select-platform-campaign-item__disabled': disabled,
    'aayn-select-platform-campaign-item__selected': selected,
  }"
>
  <div class="aayn-select-platform-campaign-item--status" *ngIf="disabled">
    <ayn-icon class="aayn-select-platform-campaign-item--status__icon" name="coming-soon"></ayn-icon>
    <span class="aayn-select-platform-campaign-item--status__text">Coming Soon</span>
  </div>

  <div class="aayn-select-platform-campaign-item-content">
    <h4 class="aayn-select-platform-campaign-item-content--title">
      {{ title }}
      <span class="aayn-select-platform-campaign-item-content--beta" *ngIf="isBeta"> {{ 'BETA' | translate }}</span>
    </h4>

    <p class="aayn-select-platform-campaign-item-content--description">{{ description }}</p>
  </div>

  <div class="aayn-select-platform-campaign-item--buttons">
    <img
      *ngIf="type === 'instant-ads'"
      src="/assets/images/svgs/create-ads/select-platform/instant-ads-image--min.png"
      class="aayn-select-platform-campaign-item--buttons__bg-image"
    />
    <ayn-icon
      [name]="image!"
      srcPrefix="/assets/images/svgs/create-ads/select-platform/"
      class="aayn-select-platform-campaign-item--buttons__icon"
    ></ayn-icon>
  </div>
</section>
