import { Injectable } from '@angular/core';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { AdcService } from '@core/services/adc.service';
import { merge } from 'rxjs';
import { AdcImageEventService } from '@core/services/adc-image-event.service';
import {
  AdcImageGenerationStateModel,
  AddImageGenerationTasks,
  ImageGenerationTask,
  SetImageGenerationTasks,
  UpdateImageGenerationTask
} from './actions';
import { filter, reduce } from 'fp-ts/Array';
import { flow, pipe } from 'fp-ts/lib/function';
import { AdCreative } from '@core/models/platforms/adcreative';
import { groupBy } from 'fp-ts/NonEmptyArray';
import { fromEntries, map, toArray } from 'fp-ts/Record';
import { array } from 'fp-ts';

@Injectable()
@State<AdcImageGenerationStateModel>({
  name: 'AdcImageGenerationState',
  defaults: {
    tasks: []
  }
})
export class AdcImageGenerationState implements NgxsOnInit {
  @Selector()
  static tasks(state: AdcImageGenerationStateModel) {
    return state.tasks;
  }

  static getTask(id: string, status?: number) {
    return createSelector([AdcImageGenerationState], (state: AdcImageGenerationStateModel) => {
      return state.tasks.find((task) => task.id === id && (status === undefined || task.status === status));
    });
  }

  static getTasks(taskIds: string[], status?: number) {
    return createSelector([AdcImageGenerationState], (state: AdcImageGenerationStateModel) => {
      return state.tasks.filter(
        (task) => taskIds.includes(task.id) && (status === undefined || task.status === status)
      );
    });
  }

  constructor(private adcService: AdcService, private adcImageEventService: AdcImageEventService) {}

  ngxsOnInit({ getState, patchState }: StateContext<AdcImageGenerationStateModel>) {
    const imageProgressIds = new Set<string>(
      getState()
        .tasks.filter((task) => task.status !== 5 && task.status !== 4)
        .map((task) => task.imageRenderProcessId)
    );
    const requests = merge(
      ...Array.from(imageProgressIds).map((imageProgressId) => this.adcService.getProgress(imageProgressId))
    );
    requests.subscribe((response) => {
      const tasks = getState().tasks;

      const newTasks = response.$values.reduce((acc, event) => {
        const tasks = Object.entries(event.tasks)
          .filter(([key]) => key !== '$id')
          .map(([id, status]) => {
            return {
              id,
              status: +status,
              imageRenderProcessId: event.imageRenderProcessId
            };
          });
        return [...acc, ...tasks];
      }, [] as ImageGenerationTask[]);
      const otherTasks = tasks
        .filter((task) =>
          response.$values.every((progress) => task.imageRenderProcessId !== progress.imageRenderProcessId)
        )
        .filter((task) => task.id !== '$id');
      patchState({
        tasks: [...otherTasks, ...newTasks]
      });

      const imageRenderProgresses = pipe(
        newTasks,
        filter((task) => task.status !== 5 && task.status !== 4),
        groupBy((task) => task.imageRenderProcessId),
        map(
          flow(
            array.map((task) => [task.id, task.status] as [string, number]),
            fromEntries
          )
        ),
        toArray,
        reduce(
          [] as Pick<AdCreative.Client.ImageGenerationProgress, 'imageRenderProcessId' | 'tasks'>[],
          (value, [imageRenderProcessId, tasks]) => [
            ...value,
            {
              imageRenderProcessId,
              tasks
            }
          ]
        )
      );

      imageRenderProgresses.forEach(({ imageRenderProcessId, tasks }) => {
        this.adcImageEventService.subscribeToEvent({ imageRenderProcessId, tasks }).subscribe();
      });
    });
  }

  @Action(UpdateImageGenerationTask)
  updateImageGenerationTask(
    { getState, patchState }: StateContext<AdcImageGenerationStateModel>,
    { payload }: UpdateImageGenerationTask
  ) {
    const tasks = getState().tasks;
    const index = tasks.findIndex((task) => task.id === payload.id);
    if (index > -1) {
      tasks[index] = { ...payload, imageRenderProcessId: tasks[index].imageRenderProcessId };
      patchState({
        tasks: [...tasks]
      });
    }
  }

  @Action(AddImageGenerationTasks)
  addImageGenerationTasks(
    { getState, patchState }: StateContext<AdcImageGenerationStateModel>,
    { payload }: AddImageGenerationTasks
  ) {
    const imageRenderProcessIds = new Set<string>(payload.map((task) => task.imageRenderProcessId));
    const tasks = getState().tasks.filter((task) => !imageRenderProcessIds.has(task.imageRenderProcessId));

    patchState({
      tasks: [...tasks, ...payload]
    });
  }

  @Action(SetImageGenerationTasks)
  setImageGenerationTasks(
    { patchState }: StateContext<AdcImageGenerationStateModel>,
    { payload }: SetImageGenerationTasks
  ) {
    patchState({
      tasks: payload
    });
  }
}
