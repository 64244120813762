import { Google } from '@core/models';
import { QueryResult, makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.GoogleDisplayCampaignRequest;
type Output = QueryResult<'createDisplayCampaign', boolean>;

export const createDisplayCampaign = makeMutation<Output, Input>(`
  mutation CreateDisplayCampaign($campaign: GoogleDisplayCampaignDto!) {
    google {
      createDisplayCampaign(campaign: $campaign)
    }
  }
`);
