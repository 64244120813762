import { makeQuery } from '../types';
import { RemainingInvitationRightsOutput } from '@core/models';

type Output = RemainingInvitationRightsOutput;

export const remainingInvitationRights = makeQuery<Output>(`
query RemainingInvitationRights {
  remainingInvitationRights {
    total
    remaining
  }
}
`);
