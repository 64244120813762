import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { UserLoginResponse } from '@core/models';
import { UserState } from '@core/state';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';

@Component({
  selector: 'aayn-welcome-back-modal',
  templateUrl: 'welcome-back-modal.component.html'
})
export class WelcomeBackModalComponent implements OnInit {
  @Select(UserState.user) user$?: Observable<UserLoginResponse>;

  constructor(public modal: NgbActiveModal, private modalService: NgbModal) {}

  ngOnInit() {}
}
