import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderAdd, LoaderRemove } from '@core/actions';
import { BusinessState } from '@core/state/business.state';
import { Store } from '@ngxs/store';

import { PURE_REQUEST, UserState } from '../';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isPureRequest = request.context.get(PURE_REQUEST);
    if (isPureRequest) return next.handle(request);
    const user = this.store.selectSnapshot(UserState.user);
    const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);

    const operationName = request.body?.operationName as string;

    if (operationName) {
      this.store.dispatch(new LoaderAdd(operationName));
    }
    if (user?.accessToken) {
      const cloned = request.clone({
        headers: request.headers
          .set('Authorization', 'Bearer ' + user.accessToken)
          .set('x-business-id', (request.headers.get('x-business-id') || selectedBusiness?.id) ?? '')
          .set('ngsw-bypass', 'true')
      });

      return next.handle(cloned).pipe(finalize(() => this.store.dispatch(new LoaderRemove(operationName))));
    } else {
      return next.handle(request).pipe(finalize(() => this.store.dispatch(new LoaderRemove(operationName))));
    }
  }
}
