import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { detectWrap } from '@ayn-ui/lib/helpers';

import { BadgeModule } from '../badge/badge';
import { Overlay, OverlayModule } from '../overlay';
import { ButtonModule } from '../button';

export type BadgeListInput = {
  name: string;
}[];

@Component({
  selector: 'ayn-badge-list',
  templateUrl: 'badge-list.html'
})
export class BadgeList implements OnInit, AfterViewInit, OnDestroy {
  @Input() list: BadgeListInput = [];

  @Input() hideRemove = false;

  @Output() onRemoveItem = new EventEmitter<number>();

  private _observer?: MutationObserver;

  protected badgeItemClass = 'ayn-badge-list_item';

  protected wrappedItemsLength = 0;

  @ViewChild(Overlay) overlay?: Overlay;

  constructor(private el: ElementRef) {}

  ngOnInit() {}

  @HostListener('window:resize')
  ngAfterViewInit(): void {
    this._observer = new MutationObserver(() => {
      this.calculateLanguageInnerWidth();
    });

    this._observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true
    });
  }

  calculateLanguageInnerWidth() {
    this.wrappedItemsLength = detectWrap(this.badgeItemClass, this.el.nativeElement).length;

    if (this.overlay?.show) {
      if (!this.wrappedItemsLength) this.overlay.hide();
    }
  }

  ngOnDestroy(): void {
    this._observer?.disconnect();
  }

  removeItem(index: number) {
    if (!this.hideRemove) {
      this.onRemoveItem.emit(index);
    }
  }
}

@NgModule({
  imports: [CommonModule, ButtonModule, BadgeModule, OverlayModule],
  exports: [BadgeList],
  declarations: [BadgeList],
  providers: []
})
export class BadgeListModule {}
