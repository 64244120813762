import { Component } from '@angular/core';
import { TikTok } from '@core/models';
import { ICreateAdSelectItem } from '../../../../models/ad-create.model';
import { TiktokStateService } from '@pages/create-ad/state/platforms/tiktok';
import { mapObjectives } from '@pages/create-ad/components/select-objective/utils';
import { selectedItem } from '@pages/create-ad/components/select-objective/utils/selected-item';

export const OBJECTIVE_LIST__TIKTOK: ICreateAdSelectItem<TikTok.CreateAd.Backend.ObjectiveType>[] = [
  {
    id: NaN,
    title: 'Traffic',
    description: 'For receiving as many visitors as possible to your website.',
    icon: 'traffic',
    empty: false,
    active: false,
    type: TikTok.CreateAd.Backend.ObjectiveType.Traffic
  },

  {
    id: NaN,
    title: 'Conversions',
    description: 'For receiving conversions on your website, must have TikTok Pixel installed.',
    icon: 'conversions',
    empty: false,
    active: false,
    type: TikTok.CreateAd.Backend.ObjectiveType.WebConversions
  },

  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  }
];
@Component({
  selector: 'aayn-select-objective--tiktok',
  templateUrl: './select-objective-content.component.html'
})
export class SelectObjectiveTiktokComponent {
  selectedObjective: TikTok.CreateAd.Backend.ObjectiveType = TikTok.CreateAd.Backend.ObjectiveType.Traffic;

  ObjectiveTypes = TikTok.CreateAd.Backend.ObjectiveType;

  objectives$ = this.tiktokStateService.adCreationModel$.pipe(
    mapObjectives(OBJECTIVE_LIST__TIKTOK, 'objectiveType'),
    selectedItem((objective) => (this.selectedObjective = objective.type!))
  );

  protected adCreationModel = this.tiktokStateService.adCreationModel;

  constructor(private tiktokStateService: TiktokStateService) {}

  objectiveChangeStatus(item: ICreateAdSelectItem<TikTok.CreateAd.Backend.ObjectiveType>) {
    if (item.type !== this.tiktokStateService.adCreationModel.campaign.objectiveType) {
      this.tiktokStateService.setAdModel({
        ...this.tiktokStateService.adCreationModel,
        campaign: {
          ...this.tiktokStateService.adCreationModel.campaign,
          objectiveType: item.type!
        }
      });
    }
  }
}
