import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-select-an-objective--header',
  templateUrl: './select-an-objective-header.component.html'
})
export class SelectAnObjectiveHeaderComponent implements OnInit {
  @Input() objectiveType = 'Traffic to my website';

  constructor() {}

  ngOnInit(): void {}
}
