<div class="aayn-select-objective">
  <header class="aayn-select-objective--header">
    <h4 class="aayn-select-objective--header__title">{{ 'Conversions on my website' | translate }}</h4>
    <!-- TODO: Linkedin: Add desc -->
    <p class="aayn-select-objective--header__description">Desc</p>
  </header>

  <form [formGroup]="conversionForm">
    <aayn-ad-form-input label="Conversion Event">
      <ayn-form-field>
        <!-- TODO: Linkedin: Linkedin allowed selecting multiple events. Consider make multiple event select -->
        <ayn-select
          formControlName="conversionEvent"
          placeholder="Select a conversion event"
          [(ngModel)]="selectedConversionEvent"
          (ngModelChange)="onChangedSelectedConversionEvent($event)"
          class="aayn-select-objective--has-disabled-info"
        >
          <ayn-option
            *ngFor="let conversionEvent of conversionEvents | aynOptionFilter | async"
            [value]="conversionEvent"
          >
            {{ conversionEvent.name | aynTitleCase }}
          </ayn-option>
        </ayn-select>
      </ayn-form-field>
    </aayn-ad-form-input>

    <aayn-ad-form-input label="Campaign Name">
      <ayn-form-field>
        <input
          type="text"
          ayn-input
          formControlName="campaignName"
          aaynDraftName
          [placeholder]="'Campaign Name' | translate"
          [(ngModel)]="adCreationModel!.campaign.name"
        />
      </ayn-form-field>
    </aayn-ad-form-input>
  </form>
</div>
