<div
  class="ayn-tooltip"
  [ngClass]="['ayn-tooltip--' + position].concat(classList)"
  [class.ayn-tooltip--visible]="visible"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
  [style.z-index]="zIndex"
  [style]="style"
>
  <ng-container *ngIf="isTooltipString"> {{tooltip}} </ng-container>

  <ng-container *ngIf="isTooltipTemplateRef">
    <ng-container *ngTemplateOutlet="$any(tooltip)"></ng-container>
  </ng-container>

  <span *ngIf="isTooltipHtmlElement" [innerHTML]="tooltipHtml"></span>
</div>
