import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
};
type Output = {
  adVideos: Facebook.IGetAdVideosResponse;
};

export const getAdVideos = makeQuery<Output, Input>(`
  query GetAdVideos($first: Int, $after: String, $last: Int, $before:String) {
    facebook {
      adVideos(first: $first, after: $after, last: $last, before: $before) {
          edges {
            node {
              id
              title
              embedHtml
              embeddable
              source
              thumbnails {
                id
                url
                isPreferred
                width
                height
              }
            }
          }
        }
    }
  }
`);
