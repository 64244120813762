import { Observable, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Returns an OperatorFunction that maps each source value to an Observable
 * @param streams {Array<Observable<any>>} The array of observables.
 *
 * @example
 * const streams = [of(4, 5, 6), of(7, 8, 9)];
 * const example = of([1,2,3]).pipe(switchMapMultiple(streams));
 * example.subscribe(val => console.log(val));
 * // 7, 8, 9
 */
export function switchMapMultiple<T>(streams: Array<Observable<any>>): OperatorFunction<T, T> {
  return (source) => {
    let newSource = source;
    (streams || []).forEach((stream) => {
      newSource = newSource.pipe(switchMap(() => stream));
    });
    return newSource;
  };
}
