import { ButtonModule, IconModule } from 'apps/ayn-ui/src/public-api';

import { CommonModule } from '@angular/common';
import {
    Component, EventEmitter, HostBinding, Input, NgModule, OnInit, Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export type AdsType =
  | 'audience'
  | 'carousel'
  | 'catalog'
  | 'conversions'
  | 'dynamic'
  | 'existing-post'
  | 'generate'
  | 'likes'
  | 'manual'
  | 'retargeting'
  | 'traffic'
  | 'import-from-google'
  | string;

@Component({
  selector: 'aayn-select-ad-item',
  templateUrl: './select-ad-item.component.html'
})
export class SelectAdItemComponent implements OnInit {
  @Input() active = false;

  @HostBinding('class.__empty')
  @Input()
  empty = false;

  @Input() adsType?: string;

  @Input() title = 'Traffic to my website';

  @Input() description = 'For receiving as many visitors as possibleto your website.';

  @Input() disabled? = false;

  @Input() comingSoonText? = '';

  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}

  onClickBtn($event: MouseEvent) {
    if (this.disabled) return;

    this.btnClick.emit($event);
  }
}

@NgModule({
  imports: [CommonModule, IconModule, ButtonModule, TranslateModule],
  exports: [SelectAdItemComponent],
  declarations: [SelectAdItemComponent],
  providers: []
})
export class SelectAdItemModule {}
