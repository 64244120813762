<div class="aayn-precision-audience-creation-ai--add-more">
  <ayn-badge
    [color]="addInterestOverlay.render ? 'gray-500' : 'accent'"
    icon="plus"
    [text]="'Add interest to ' + (PlatformKeyByValues[platform!] | titlecase)"
    (click)="addInterestOverlay.toggle($event)"
  ></ayn-badge>

  <ayn-overlay #addInterestOverlay>
    <div
      class="aayn-precision-audience-creation-ai--add-more--overlay-inner"
      [ngClass]="{ '__has-interest': interests?.length }"
    >
      <ayn-form-field>
        <input type="text" ayn-input placeholder="Type to search for interest" [formControl]="searchControl" />

        <ayn-icon name="map-marker"></ayn-icon>
      </ayn-form-field>
      
      <ul *ayn-loader="loading">
        <li
          *ngFor="let interest of interests"
          (click)="selectInterest(interest)"
          [ngClass]="{ __selected: _hasExistsInterest(interest) }"
        >
          {{ interest.name }}

          <button type="button">
            Select
            <ayn-icon name="check"></ayn-icon>
          </button>
        </li>
      </ul>
    </div>
  </ayn-overlay>
</div>
