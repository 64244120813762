import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Facebook, FacebookService } from '@core/index';
import { DEFAULT_CUSTOM_EVENT_TYPE_KEY } from '@pages/create-ad/components/select-objective/platforms/facebook';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--facebook-form',
  templateUrl: 'form.component.html'
})
export class ConversionMyWebsiteSMFacebookFormComponent implements OnInit {
  @Input() form?: FormGroup;

  adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected adPixels: Facebook.IAdPixel[] = [];

  protected conversionEvents: Facebook.ICustomConversion[] = [];

  conversion$ = new ReplaySubject<Partial<Facebook.ICustomConversion>>(1);

  constructor(private smartCampaignStateService: SmartCampaignStateService, private facebookService: FacebookService) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      if (!this.adCreationModel.campaign.platforms?.facebook) this.adCreationModel.campaign.platforms!.facebook = {};

      const { facebook } = this.adCreationModel.campaign.platforms!;

      this.conversion$.next({
        customEventType: facebook?.customEventType,
        id: facebook?.customConversionId || ''
      });
    });

    this.facebookService.getAdPixels$().subscribe((result) => {
      this.adPixels = result.data.adPixels.edges.map((o) => o.node);
    });

    this.facebookService.getCustomConversions$().subscribe((result) => {
      this.conversionEvents = [
        ...result.data.customConversions.edges.map((o) => o.node),
        ...Facebook.CUSTOM_EVENT_TYPES.map(this.createOption)
      ];

      this.conversion$.pipe(take(1)).subscribe((conversion) => {
        let conversionEvent: Facebook.ICustomConversion | undefined;
        if (conversion.id) {
          conversionEvent = this.conversionEvents.find((o) => o.id === conversion.id);
        } else if (conversion.customEventType) {
          conversionEvent = this.conversionEvents.find((o) => o.name === conversion.customEventType);
        }
        if (conversionEvent) {
          this.form!.patchValue({
            conversionEvent
          });
        }
      });
    });
  }

  onChangedSelectedConversionEvent($event?: Facebook.ICustomConversion) {
    if (!$event) return;

    if ($event?.customEventType == DEFAULT_CUSTOM_EVENT_TYPE_KEY) {
      this.adCreationModel.campaign.platforms!.facebook!.customEventType = $event.name;
      this.adCreationModel.campaign.platforms!.facebook!.customConversionId = undefined;
      this.adCreationModel.campaign.platforms!.facebook!.pixelRule = undefined;
      return;
    }

    this.adCreationModel.campaign.platforms!.facebook!.customConversionId = $event.id;
    this.adCreationModel.campaign.platforms!.facebook!.customEventType = Facebook.CustomEventType.Other;
    this.adCreationModel.campaign.platforms!.facebook!.pixelRule = $event.rule;
  }

  createOption(customEvent: Facebook.CustomEventType) {
    return {
      id: '',
      name: customEvent,
      customEventType: DEFAULT_CUSTOM_EVENT_TYPE_KEY,
      rule: ''
    } as Facebook.ICustomConversion;
  }
}
