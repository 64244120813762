import _ClickfraudProtectionQ from '../clickfraud-protection';
import _BingQ from './bing';
import _FacebookQ from './facebook';
import _GoogleQ from './google';
import _LinkedinQ from './linkedin';
import _TikTokQ from './tiktok';
import _SmartCampaignQ from './smart-campaign';

export const FacebookQ = _FacebookQ;

export const GoogleQ = _GoogleQ;

export const BingQ = _BingQ;

export const LinkedinQ = _LinkedinQ;

export const TikTokQ = _TikTokQ;

export const ClickfraudProtectionQ = _ClickfraudProtectionQ;

export const SmartCampaignQ = _SmartCampaignQ;
