import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-upload-image-modal',
  templateUrl: './upload-image-modal.component.html'
})
export class UploadImageModalComponent {
  @Input() header = '';
  @Input() description = '';
  @Input() icon = 'folder';
  @Input() saveButtonLabel = 'Save & Continue';
  @Input() saveButtonHidden = false;
  @Input() saveDisabled = false;
  @Input() modal?: NgbActiveModal;
  @Input() modalClass = '';
  @Input() bodyClass = '';

  @Output() saveModal = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
}

@Component({
  selector: 'aayn-upload-image-modal--header',
  template: `
    <section class="upload-ads-images--group-header" [ngClass]="headerClass">
      <h5>
        <span class="fw-semibold" (click)="headerLeftClick.emit()"
          >{{ headerLeft | translate }}
          <span class="color:gray fw-normal ml-3" *ngIf="headerLeftDescription">
            {{ headerLeftDescription | translate }}</span
          >
        </span>
        <div class="divider"></div>
        <span (click)="headerRightClick.emit()" *ngIf="headerRightIcon?.length">
          {{ headerRight | translate }}
          <ayn-icon *ngIf="headerRightIcon" [name]="headerRightIcon"></ayn-icon>
        </span>
      </h5>
    </section>
  `
})
export class UploadImageModalHeaderComponent {
  @Input() headerLeft = '';
  @Input() headerLeftDescription = '';
  @Input() headerRight = '';
  @Input() headerRightIcon = '';
  @Input() headerClass = '';

  @Output() headerLeftClick = new EventEmitter<void>();
  @Output() headerRightClick = new EventEmitter<void>();
}
