<form *ngIf="form" [formGroup]="form">
  <aayn-ad-form-input label="TikTok" platform="tiktok">
    <div class="aayn-select-objective--row">
      <ayn-form-field>
        <ayn-select formControlName="pixelId" placeholder="Select a pixel">
          <ayn-option
            *ngFor="let pixel of pixels | aynOptionFilter | async"
            [value]="pixel.id!"
            [disabled]="!pixel.events?.length"
            class="aayn-select-objective--tiktok-conversion-option"
          >
            <span>{{ pixel.name }}</span>
            <ayn-icon *ngIf="!pixel.events?.length" name="info"> </ayn-icon>
          </ayn-option>
          <ayn-option [disabled]="true" value="-1" *ngIf="!pixels.length">
            {{ 'There is no TikTok pixel in your account.' | translate }}
          </ayn-option>
        </ayn-select>
      </ayn-form-field>

      <ayn-form-field>
        <ayn-select
          formControlName="pixelEventCode"
          [placeholder]="'Select a conversion event' | translate"
          [panelMaxHeight]="220"
          [disabled]="!selectedPixel"
        >
          <ayn-option *ngFor="let event of selectedPixel?.events || [] | aynOptionFilter | async" [value]="event.code!">
            {{ event.name | aynTitleCase }}
          </ayn-option>
        </ayn-select>
      </ayn-form-field>
    </div>
  </aayn-ad-form-input>
</form>
