import { CommonModule } from '@angular/common';
import { Directive, Output, Input, EventEmitter, HostBinding, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[ayn-drag-drop]'
})
export class DragDropDirective {
  @HostBinding('class.__ayn-file-over') fileOver!: boolean;

  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;

    let files = event.dataTransfer.files;

    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}

const DIRECTIVES = [DragDropDirective];

@NgModule({
  imports: [CommonModule],
  exports: DIRECTIVES,
  declarations: DIRECTIVES,
  providers: []
})
export class DragDropModule {}
