import { cloneDeep } from 'lodash';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { FileUploadAreaEvent, FileUploadGroup } from '@ayn-ui/lib/modules/base/file-upload-area';
import { Bing, PlatformStep } from '@core/models';
import { CanvaService } from '@core/services/canva.service';
import { URL_REGEX } from '@core/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BingFormService, BingStateService, BingValidationService } from '@pages/create-ad/state';

import { AdPreviewBingService } from '../ad-preview';
import { PerformanceMaxImages, UploadAdsImagesBingComponent } from '../modal';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

@UntilDestroy()
@Component({
  selector: 'aayn-performance-max-ads--bing',
  templateUrl: 'performance-max-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class PerformanceMaxAdsBingComponent implements OnInit {
  protected adCreationModel = this.bingStateService.adCreationModel;

  protected longHeadlines = cloneDeep(this.adCreationModel!.campaign.assets?.longHeadlines);
  protected descriptions = cloneDeep(this.adCreationModel!.campaign.assets?.descriptions);

  protected uploadedImageUrls: FileUploadGroup[] = [];

  private _images?: PerformanceMaxImages;

  form = this.fb.group({
    longHeadlines: this.fb.array(
      [
        new FormControl(this.adCreationModel.campaign.assets?.longHeadlines[0], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.longHeadlines[1], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.longHeadlines[2], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.longHeadlines[3]),
        new FormControl(this.adCreationModel.campaign.assets?.longHeadlines[4])
      ],
      [AppValidations.uniqueValidator()]
    ),

    descriptions: this.fb.array(
      [
        new FormControl(this.adCreationModel.campaign.assets?.descriptions[0], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.descriptions[1], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.descriptions[2], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.descriptions[3]),
        new FormControl(this.adCreationModel.campaign.assets?.descriptions[4])
      ],
      [AppValidations.uniqueValidator()]
    ),

    headlines: this.fb.array(
      [
        new FormControl(this.adCreationModel.campaign.assets?.headlines[0], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.headlines[1], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.headlines[2], [Validators.required]),
        new FormControl(this.adCreationModel.campaign.assets?.headlines[3]),
        new FormControl(this.adCreationModel.campaign.assets?.headlines[4])
      ],
      [AppValidations.uniqueValidator()]
    ),

    businessName: new FormControl(this.adCreationModel.campaign.assets?.businessName, [Validators.required]),
    url: new FormControl(this.adCreationModel.campaign.assets.finalUrl, [
      Validators.pattern(URL_REGEX),
      Validators.required
    ])
  });

  constructor(
    private bingStateService: BingStateService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private bingValidationService: BingValidationService,
    private fb: FormBuilder,
    private canvaService: CanvaService,
    private bingFormService: BingFormService,
    public adPreviewService: AdPreviewBingService
  ) {}

  ngOnInit() {
    this.canvaService.setupSdk();

    this.bingFormService.registerForm(this.form);

    this.listenModel();

    this.bingValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.listenForm();
  }

  private mapUploadedImageUrls(images: PerformanceMaxImages): FileUploadGroup[] {
    return [
      {
        label: '1.91:1 Landscape Images',
        imageUrls: images.landscapeImageMedia?.map((o) => o.url) || [],
        maxImageCount: 3,
        groupName: 'landscapeImageMedia',
        required: true,
        minRequired: 1,
        aspectRatio: 1.91
      },
      {
        label: '1:1 Square Images',
        imageUrls: images.squareImageMedia?.map((o) => o.url) || [],
        maxImageCount: 3,
        groupName: 'squareImageMedia',
        required: true,
        minRequired: 1,
        aspectRatio: 1
      },
      {
        label: '1.5:1 Image Media',
        imageUrls: images.imageMedia15X10?.map((o) => o.url) || [],
        maxImageCount: 3,
        groupName: 'imageMedia15X10',
        aspectRatio: 1.5
      },
      {
        label: '1.78:1 Image Media',
        imageUrls: images.imageMedia178X100?.map((o) => o.url) || [],
        maxImageCount: 3,
        groupName: 'imageMedia178X100',
        aspectRatio: 1.78
      },
      {
        label: '1.33:1 Image Media',
        imageUrls: images.imageMedia133X100?.map((o) => o.url) || [],
        maxImageCount: 3,
        groupName: 'imageMedia133X100',
        aspectRatio: 1.33
      }
    ];
  }

  updateImages(images: PerformanceMaxImages, saveDraft = true) {
    this._images = images;

    this.uploadedImageUrls = this.mapUploadedImageUrls(images);

    if (saveDraft) {
      this.adCreationModel.campaign.assets!.images.landscapeImageMedia = images.landscapeImageMedia || [];
      this.adCreationModel.campaign.assets!.images.imageMedia133X100 = images.imageMedia133X100 || [];
      this.adCreationModel.campaign.assets!.images.imageMedia15X10 = images.imageMedia15X10 || [];
      this.adCreationModel.campaign.assets!.images.imageMedia178X100 = images.imageMedia178X100 || [];
      this.adCreationModel.campaign.assets!.images.squareImageMedia = images.squareImageMedia;
      this.bingStateService.saveDraft$();
    }
  }

  get longHeadlinesArray() {
    return this.form.controls['longHeadlines'] as FormArray;
  }

  get headlinesArray() {
    return this.form.controls['headlines'] as FormArray;
  }

  get descriptionsArray() {
    return this.form.controls['descriptions'] as FormArray;
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesBingComponent, { backdrop: 'static' });

    if (this._images) modalRef.componentInstance.uploadedImages = this._images;

    modalRef.closed.subscribe((result: { images: PerformanceMaxImages }) => {
      if (result?.images) {
        this.updateImages(result.images);

        this.cdr.detectChanges();
      }
    });
  }

  removeFile(event: FileUploadAreaEvent) {
    const group = this._images![event.group!]!.filter((_, i) => i !== event.index);
    this.updateImages({
      ...this._images!,
      [event.group!]: group
    });
  }

  private listenModel() {
    this.bingStateService.adCreationModel$
      .pipe(
        filter((o) => o.selectedTypes.ad === Bing.AdType.PerformanceMax),
        untilDestroyed(this)
      )
      .subscribe((o) => {
        this.adCreationModel = o;

        this.form.patchValue({
          businessName: o.campaign.assets.businessName,
          descriptions: o.campaign.assets.descriptions,
          headlines: o.campaign.assets.headlines,
          longHeadlines: o.campaign.assets.longHeadlines,
          url: o.campaign.assets.finalUrl
        });

        this.updateImages(this.adCreationModel.campaign.assets.images, false);
      });
  }

  private listenForm() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((x) => {
      this.adCreationModel.campaign.assets.businessName = x.businessName!;
      this.adCreationModel.campaign.assets.longHeadlines = (x.longHeadlines as string[]) || [];
      this.adCreationModel.campaign.assets.headlines = (x.headlines as string[]) || [];
      this.adCreationModel.campaign.assets.descriptions = (x.descriptions as string[]) || [];

      this.adCreationModel.campaign.assets.finalUrl = x.url!;
    });
  }
}
