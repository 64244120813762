export module AdCreative {
  export module Client {
    export type ImageGenerationProgress = Omit<Backend.ImageGenerationProgressResponse, 'tasks'> & {
      tasks: Omit<Backend.ImageGenerationTask, '$id'>;
    };
  }

  export module Backend {
    export type ImageTemplate = {
      type: number;
      kind: RenderKind;
      index: number;
    };

    export enum RenderKind {
      Post = 1,
      Story = 2,
      Landscape = 4,
      Vertical = 8,
      PinSize = 16,
      MediumBanner = 32,
      LeaderBoard = 64,
      WideSkyScraper = 128,
      HalfPage = 256,
      LargeLeaderboard = 512
    }
    export enum RenderKindRatio {
      Post = 1,
      Story = 9 / 16,
      Landscape = 1.91 / 1,
      Vertical = 4 / 5,
      PinSize = 2 / 3,
      MediumBanner = 1.2 / 1,
      LeaderBoard = 8.09 / 1,
      WideSkyScraper = 2.67 / 1,
      HalfPage = 1 / 2,
      LargeLeaderboard = 10.78 / 1
    }
    export const RenderKindSize = {
      Post: { width: 1080, height: 1080 },
      Story: { width: 1080, height: 1920 },
      Landscape: { width: 1200, height: 628 },
      Vertical: { width: 1080, height: 1350 },
      PinSize: { width: 1000, height: 1500 },
      MediumBanner: { width: 300, height: 250 },
      LeaderBoard: { width: 728, height: 90 },
      WideSkyScraper: { width: 160, height: 600 },
      HalfPage: { width: 300, height: 600 },
      LargeLeaderboard: { width: 970, height: 90 }
    };

    export interface CreateImageGenerationTask {
      brandImage: File;
      backgroundImage: File;
      actionText: string;
      color1Hex: string;
      color2Hex: string;
      mainHeadline: string;
      punchline: string;
      description: string;
      buttonIconClass: string;
      fontIdentifier?: string;
      templates: ImageTemplate[];
      color3Hex?: string;
    }

    export type ImageGenerationTask = {
      $id: string;
    } & Record<string, number>;

    export type ImageGenerationProgressResponse = {
      $id: string;
      messageType: string;
      requestRenderState: number;
      applicationId: string;
      userId: string;
      imageRenderProcessId: string;
      totalTaskCount: number;
      completedTaskCount: number;
      progress: number;
      tasks: ImageGenerationTask;
      renderKind: number;
      renderType: number;
      isSuccessful: boolean;
      errorMessage: string;
    };
  }
}
