<section class="aayn-level">
  <button ayn-button class="aayn-level-btn" (click)="overlay.toggle($event)" [ngClass]="{ active: overlay.render }">
    Level: <b>{{ level }}</b>
    <ayn-icon name="level"></ayn-icon>
  </button>

  <ayn-overlay #overlay styleClass="aayn-level--body" [useOverlayBlur]="true">
    <section class="aayn-level--body__inner">
      <ayn-modal-close (click)="overlay.hide()"></ayn-modal-close>

      <header class="aayn-level--body__inner-header">
        <div class="aayn-level--body__inner-header__left">
          <strong>Welcome back, Yusuf!</strong>

          <p>
            Complete your perfect week by coming back to ADYOUNEED 7 days straight.
            <b>Earn 100 XP</b>
          </p>
        </div>

        <div class="aayn-level--body__inner-header__levels">
          <span class="aayn-level-item" *ngFor="let level of levels" [class.aayn-level-item__active]="level.selected">
            <span class="aayn-level-item--text">{{ level.name.charAt(0) | uppercase }}</span>

            <img src="/assets/images/svgs/level--active.svg" alt="Level" />
          </span>
        </div>

        <div class="aayn-level--body__inner-header__total">
          Total
          <b>900 XP</b>
        </div>
      </header>

      <main class="aayn-level--body__inner-body">
        <div class="aayn-level--body__inner-body--content">
          <h3 class="aayn-level--body__inner-body--content-title">Reach to the highest level!</h3>
          <p class="aayn-level--body__inner-body--content-description">
            Complete tasks and level up to master ADYOUNEED while receving gifts every 5 levels.
          </p>

          <section class="aayn-level--body__inner-body--content-levels">
            <section class="aayn-level--body__inner-body--content-levels--inner">
              <aayn-level-item *ngFor="let task of tasks" [task]="task"></aayn-level-item>
            </section>
          </section>
        </div>
      </main>
    </section>
  </ayn-overlay>
</section>
