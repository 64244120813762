import { makeMutation } from '../types';

type Input = { email: string };
type Output = boolean;

export const deleteSubAccount = makeMutation<Output, Input>(`
  mutation DeleteSubAccount($email: String!) {
    deleteSubAccount(email: $email)
  }
`);
