import { makeMutation, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'createIdentity';
type Input = {
  payload: TikTok.Business.TikTokCreateIdentityDto;
};
type Output = QueryResult<typeof queryField, TikTok.Business.TikTokCreateIdentityOutputDto>;

export const createIdentityQuery = makeMutation<Output, Input>(`
mutation CreateIdentity($payload: TikTokCreateIdentityDto!) {
  tiktok {
    ${queryField}(payload: $payload) {
      identityId
    }
  }
}
`);
