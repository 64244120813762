import { SmartCampaign, SmartCampaignPlatform } from '@core/models';
import { makeQuery, QueryResult } from '@core/queries';

type Output = QueryResult<'imageConfig', SmartCampaign.Backend.ImageConfigDto[]>;

const commonFields = `
quantity {
  min
  max
}
aspectRatio
size {
  min
  max
}
dimensions {
  min {
    width
    height
  }
  max {
    width
    height
  }
}
`;

const platformsObject = {
  [SmartCampaignPlatform.Meta]: `    
    facebook {
      ${commonFields}
    }`,
  [SmartCampaignPlatform.LinkedIn]: `
    linkedin {
      ${commonFields}
    }
  `,
  [SmartCampaignPlatform.Google]: `
    google {
      ${commonFields}
      typeName
    }
  `,
  [SmartCampaignPlatform.Bing]: `
    bing {
      ${commonFields}
      typeName
    }
  `,
  [SmartCampaignPlatform.TikTok]: `
    tiktok {
      ${commonFields}
    }`
};

export const getImageConfig = (selectedPlatforms: SmartCampaignPlatform[]) =>
  makeQuery<Output>(`
 query GetImageConfig {
  ayn {
    imageConfig {
      groupName
      platforms {
       ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
      }
      includedPlatforms
      common {
        quantity {
          min
          max
        }
        aspectRatio
        size {
          min
          max
        }
        dimensions {
          min {
            width
            height
          }
          max {
            width
            height
          }
        }
      }
      required
    }
  }
}
`);
