import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BadgeList, BadgeListInput, IOption } from '@ayn-ui/lib/modules';
import { Linkedin } from '@core/index';
import { LinkedinStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-language-selection--linkedin',
  templateUrl: './language-selection.component.html'
})
export class LanguageSelectionLinkedinComponent implements OnInit {
  @Input() form?: FormGroup;

  protected languages: IOption<string>[] = [];

  badges: BadgeListInput = [];

  @ViewChild(BadgeList) badgeList?: BadgeList;

  selectedLanguages: string[] = [];

  constructor(private linkedinStateService: LinkedinStateService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.languages = Linkedin.Targeting.Languages.map((o) => ({ label: o.label, value: o.code } as IOption<string>));
    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.selectedLanguages = [...(o.campaign.targetingCriteria?.include?.locales || [])];
      this.badges = this.selectedLanguages.map((o) => ({
        name: this.languages.find((l) => l.value == o)?.label || ''
      }));
    });
  }

  searchValueChange(term: string) {
    this.languages = Linkedin.Targeting.Languages.filter((l) => l.label.includes(term) || l.code.includes(term)).map(
      (o) => ({ label: o.label, value: o.code } as IOption<string>)
    );
    this.cdr.detectChanges();
  }

  protected valueChange(option: IOption<string>) {
    this.selectedLanguages = [option.value];
    this.badges = this.selectedLanguages.map((o) => ({ name: this.languages.find((l) => l.value == o)?.label || '' }));

    this.form?.patchValue({
      locales: [...this.selectedLanguages]
    });
  }

  optionDisabled(option: IOption) {
    return this.selectedLanguages.some((o) => o == option.value);
  }
}
