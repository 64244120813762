import { ChangeDetectorRef, Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class TypewriteService {
  constructor(private cdr: ChangeDetectorRef) {}

  typeWrite$(text: string, valueUpdater: (value: string) => void, speed: number = 1) {
    return interval(speed).pipe(
      map((interval) => text.substring(0, interval + 1)),
      take(text.length),
      tap((value) => {
        valueUpdater(value);
        this.cdr.detectChanges();
      })
    );
  }
}
