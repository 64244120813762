import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';
import { arraySearch } from '@ayn-ui/lib/helpers';

import { Select } from './select';

@Pipe({
  name: 'aynOptionFilter'
})
export class OptionFilterPipe implements PipeTransform {
  constructor(private select: Select) {}

  transform<T extends any>(options: Array<T>): Observable<Array<T>> {
    return this.select.searchControlChange.pipe(
      startWith(''),
      map((value: string) => arraySearch<T>(options, value))
    );
  }
}
