import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IDetailedTargetingItemCloseTooltip {
  size?: number | string;
  interesets?: string;
  description?: string;
}

@Component({
  selector: 'aayn-detailed-targeting-item',
  templateUrl: 'detailed-targeting-item.component.html'
})
export class DetailedTargetingItemComponent implements OnInit {
  @Input() name?: string;

  @Input() category?: string | null;

  @Input() closeTooltipData?: IDetailedTargetingItemCloseTooltip;

  @Input() tooltipOnHover = true;

  @Output() onCloseClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
