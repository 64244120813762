import { Apollo } from 'apollo-angular';
import * as Query from '@core/queries';
import { StateClear, StateOverwrite } from 'ngxs-reset-plugin';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetMySubscription, SetCompanyLogo, SetPlatform, SetUserTheme } from '@core/state';
import { GetBusinesses } from '@core/state/business.state';
import { GetPlatformContexts } from '@core/state/platform-context.state';
import { SetUser, UserState } from '@core/state/user.state';
import { setSentryUser } from '@core/utils/sentry.util';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

import { decodeToken } from '../';
import { GraphQLService } from '../backend';
import { JwtTokenResponse, Platforms, SignupRequest, UserLoginResponse } from '../models';
import { GQLClientService } from './gql-client.service';
import { ThemeService } from '@core/services/theme.service';
import { SetImageGenerationTasks } from '@pages/create-ad/state/adc-image-generation/actions';

function createPasswordChangeActions(user: UserLoginResponse) {
  return [
    new SetUser(user),
    new GetBusinesses(),
    new GetPlatformContexts(),
    new GetMySubscription(),
    new SetImageGenerationTasks([])
  ];
}

@UntilDestroy()
@Injectable()
export class AuthService {
  constructor(
    private graphqlService: GraphQLService,
    private store: Store,
    private apollo: Apollo,
    private gqlClientService: GQLClientService,
    private router: Router,
    private themeService: ThemeService
  ) {}

  login(email: string, password: string) {
    return this.graphqlService.post(Query.login({ email, password })).pipe(
      delayWhen((data) => {
        setSentryUser({
          email: email
        });

        this.controlAndRouteEmailVerification(data.data);

        return this.store.dispatch([
          new SetUser(data!.data as UserLoginResponse),
          new GetPlatformContexts(),
          new GetBusinesses(),
          new GetMySubscription(),
          new SetUserTheme(),
          new SetCompanyLogo(),
          new SetImageGenerationTasks([])
        ]);
      }),

      map((data) => data.data)
    );
  }

  getUserInfo() {
    return this.graphqlService.get(Query.userInfo());
  }

  sendConfirmationEmail() {
    return this.graphqlService.post(Query.sendConfirmationEmail());
  }

  private controlAndRouteEmailVerification(user?: UserLoginResponse) {
    if (user?.user?.emailConfirmed) return;

    this.router.navigateByUrl('/authentication/email-verification');
  }

  doLogoutOperations() {
    this.store.dispatch(new StateOverwrite([UserState, { user: {} }]));
    this.gqlClientService.closeWebSocket();
    this.apollo.client.cache.reset();
    this.apollo.client.resetStore();
    this.apollo.removeClient();
    this.gqlClientService.start();
    setSentryUser(null);
  }

  clearState() {
    this.store.dispatch(new StateClear());
  }

  logout() {
    return this.graphqlService.post(Query.logout()).pipe(tap(() => this.themeService.resetAllThemeColors()));
  }

  logoutWithOperations(cb?: () => void) {
    const fn = () => {
      this.doLogoutOperations();
      this.router.navigateByUrl('/authentication/login').then(() => {
        if (cb && typeof cb === 'function') {
          cb();
        }
      });
    };

    this.logout().subscribe({
      next: fn,
      error: fn,
      complete: fn
    });
  }

  signup(request: SignupRequest) {
    return this.graphqlService.post(Query.signup({ data: request })).pipe(
      tap((data) => {
        this.controlAndRouteEmailVerification(data.data);

        this.store.dispatch([
          new SetUser(data.data as UserLoginResponse),
          new GetBusinesses(),
          new GetPlatformContexts(),
          new SetPlatform(Platforms.Meta),
          new GetMySubscription(),
          new SetImageGenerationTasks([])
        ]);

        setSentryUser({ email: request.email });
      })
    );
  }

  forgotPassword(username: string) {
    return this.graphqlService.post(Query.forgotPassword({ username }));
  }

  inviteUser(email: string, name: string, surname: string) {
    return this.graphqlService.post(Query.inviteUser({ email, name, surname }));
  }

  deleteSubAccount(email: string) {
    return this.graphqlService.post(Query.deleteSubAccount({ email }));
  }

  isSubAccountSystemAvailable() {
    return this.graphqlService.get(Query.isSubAccountSystemAvailable());
  }

  listSubAccounts() {
    return this.graphqlService.get(Query.listSubAccounts());
  }

  remainingInvitationRights() {
    return this.graphqlService.get(Query.remainingInvitationRights());
  }

  resetPassword(token: string, password: string, passwordAgain: string) {
    return this.graphqlService.post(Query.resetPassword({ token, password, passwordAgain })).pipe(
      switchMap((data) => {
        return this.store.dispatch(createPasswordChangeActions(data.data as UserLoginResponse));
      })
    );
  }

  setPassword(token: string, password: string, passwordAgain: string, invitationId: string) {
    return this.graphqlService.post(Query.setPassword({ token, password, passwordAgain, invitationId })).pipe(
      switchMap((data) => {
        return this.store.dispatch(createPasswordChangeActions(data.data as UserLoginResponse));
      })
    );
  }

  refreshToken() {
    const user = this.store.selectSnapshot(UserState.user);

    return this.graphqlService.post(Query.refreshToken({ oldToken: user.refreshToken })).pipe(
      tap((result) => {
        if (!result.data?.accessToken || !result.data?.refreshToken) {
          this.logout().subscribe(() => {
            this.doLogoutOperations();
          });

          throw new Error(`[AuthService.refreshToken]: AccessToken or RefreshToken is null!`);
        }

        let user = Object.assign({}, this.store.selectSnapshot(UserState.user));
        user.accessToken = result.data.accessToken;
        user.refreshToken = result.data.refreshToken;

        this.store.dispatch(new SetUser(user));
      }),
      catchError((err) => {
        this.logout().subscribe(() => {
          this.doLogoutOperations();
        });

        throw err;
      })
    );
  }

  get user() {
    return this.store.selectSnapshot(UserState.user);
  }

  get hasAccessTokenExpired() {
    const token = this.user?.accessToken;

    if (!token) return;

    const decodedToken = decodeToken(token) as JwtTokenResponse;

    const expireDate = new Date(+decodedToken.exp * 1000);

    return new Date() >= expireDate;
  }
}
