import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformIcons } from '@core/models';
import { Platform } from '@shared/modules/platform-item';

@Component({
  selector: 'aayn-select-platform-item',
  templateUrl: './select-platform-item.component.html'
})
export class SelectPlatformItemComponent {
  @Input() status?: 'select' | 'selected' | 'coming-soon' | 'connect-first' | '';

  @Input() platform!: Platform;

  @Input() selected = false;

  PlatformIcons = PlatformIcons;

  @Input() description: string = '';

  @Output() btnClick: EventEmitter<Event> = new EventEmitter<Event>();
}
