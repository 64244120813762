<section class="ayn-layout--package" *ngIf="subscription$ | async as subscription">
  <h5 class="ayn-layout--package-title">{{ subscription.package.displayName }}</h5>

  <ul>
    <li>
      <span>{{ 'Monthly Adspend' | translate }}</span>

      <b>∞</b>
    </li>
    <li *ngIf="{ isAppSumoUser: isAppSumoUser$ | async } as data">
      <span>{{ 'Current Usage' | translate }}</span>

      <span *ngIf="!data.isAppSumoUser">
        {{ spend | currency: subscription.package.currencyCode }}

        <span class="ayn-layout--package-limit-text">
          /
          {{ CURRENCIES[subscription.package.currencyCode]?.symbol
          }}{{ subscription.package.adSpendLimit | number: '1.0-0' }}
        </span>
      </span>
      <span *ngIf="data.isAppSumoUser"> <b>∞</b> / <b>∞</b> </span>
    </li>
  </ul>

  <ayn-progressbar [value]="(spend / subscription.package.adSpendLimit) * 100"></ayn-progressbar>
</section>

<!-- <aayn-friend-invite></aayn-friend-invite> -->
