import { Component, Input, OnInit } from '@angular/core';

export interface ILevelItem {
  level: number;
  xp: number;
  task: string;

  isPassed?: boolean;
  isActive?: boolean;
  isGift?: boolean;

  callback?(): void;
}

@Component({
  selector: 'aayn-level-item',
  templateUrl: 'level-item.component.html'
})
export class LevelItemComponent implements OnInit {
  @Input() task!: ILevelItem;

  constructor() {}

  ngOnInit() {}
}
