import { Linkedin } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = { input: Linkedin.LinkedInCampaignCreationModel };
type Output = Linkedin.CreateAd.CreateCampaignResponse;

export const createCampaignLinkedin = makeMutation<Output, Input>(`
mutation CreateCampaign($input: LinkedInCampaignCreationModel!) {
    linkedin{
        createCampaign(input: $input)
    }
}
`);
