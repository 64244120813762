<ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.InstantAd">
      <aayn-select-an-ad-type--header
        classes="fw-bold"
        *ngIf="instantAdStateService.adCreationModel.textAi.productName"
        [url]="instantAdStateService.adCreationModel.textAi.productName"
      ></aayn-select-an-ad-type--header>
    </div>
  </div>
</ng-container>
