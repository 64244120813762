import * as Query from '@core/queries';
import { environment } from '@environment';
import { map, scan } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Base, calculateState, Google, GraphQLService, Upload } from '../';

@Injectable({ providedIn: 'root' })
export class GoogleService {
  constructor(private graphqlService: GraphQLService, private http: HttpClient) {}

  getOAuthUri(contextId: string) {
    return this.graphqlService.post(Query.GoogleQ.oauthGoogle({ contextId })).pipe(map((result) => result.data?.oauth));
  }

  getAdAccounts(contextId: string) {
    return this.graphqlService
      .post(Query.GoogleQ.adAccountsGoogle({ contextId }))
      .pipe(map((result) => result.data?.adAccounts));
  }

  getAccountOverviewTotal(payload: Google.Dashboard.IAccountOverViewRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.dashboard.accountOverviewTotalGoogle(payload))
      .pipe(map((result) => result.data?.accountOverviewTotal));
  }

  getAccountOverview(payload: Google.Dashboard.IAccountOverViewRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.dashboard.accountOverviewWithMetricsGoogle(payload))
      .pipe(map((result) => result.data?.accountOverviewWithMetrics));
  }

  getBreakDown<BT extends Google.Dashboard.BreakdownType>(
    breakdownType: BT,
    payload: Base.Dashboard.Global.IStartEndDateRequest
  ) {
    return this.graphqlService.get(Query.GoogleQ.dashboard.breakdownsGoogle<BT>(breakdownType)(payload));
  }

  getCampaignsWithMetrics$(payload: Google.Dashboard.ICampaignRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.dashboard.campaignsWithMetricsGoogle(payload))
      .pipe(map((result) => result.data));
  }

  getCampaigns$(payload: Google.Dashboard.ICampaignRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.dashboard.campaignsGoogle(payload))
      .pipe(map((result) => result.data));
  }

  getFunnelDashboard(payload: Google.Dashboard.IFunnelDashboardRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.dashboard.funnelDashboardGoogle(payload))
      .pipe(map((result) => result.data?.funnelDashboard));
  }

  connectAdAccount$(body: Google.IConnectAdAccountRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.connectAdAccountGoogle(body))
      .pipe(map((result) => result.data?.connectAdAccount));
  }

  isCreationPossible$(body: Google.IAdAccountRequest) {
    return this.graphqlService
      .get(Query.GoogleQ.isCreationPossibleGoogle(body))
      .pipe(map((result) => result.data?.isCreationPossible));
  }

  audienceSearch(body: Google.CreateAd.IGoogleAudienceRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.audienceSearch(body))
      .pipe(map((result) => result.data?.audienceSearch));
  }

  interestsSearch(body: Google.CreateAd.IGoogleInterestRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.interestsSearch(body))
      .pipe(map((result) => result.data?.userInterestsSearch));
  }

  languageSearch(body: Google.CreateAd.IGoogleLanguageConstantRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.languageSearch(body))
      .pipe(map((result) => result.data?.languageSearch));
  }

  conversionGoal(body: Google.CreateAd.IGoogleConversionGoalOutputRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.conversionGoal(body))
      .pipe(map((result) => result.data?.conversionGoal));
  }

  locationSearch(body: Google.CreateAd.IGoogleLocationConstantRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.locationSearch(body))
      .pipe(map((result) => result.data?.locationSearch));
  }

  yourDataSearch(body: Google.CreateAd.IGoogleYourDataRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.yourDataSearch(body))
      .pipe(map((result) => result.data?.yourDataSearch));
  }

  youtubeSearch(body: Base.IPaginationRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.youtubeSearch(body))
      .pipe(map((result) => result.data?.youtubeSearch));
  }

  youtubeUrlValidation(body: Google.CreateAd.IGoogleYoutubeVideoValidationUrlRequest) {
    return this.graphqlService
      .post(Query.GoogleQ.adCreation.youtubeUrlValidation(body))
      .pipe(map((result) => result.data?.youtubeUrlValidation));
  }

  createPMCampaign(body: Google.CreateAd.GooglePMCampaignRequest) {
    return this.graphqlService.post(Query.GoogleQ.adCreation.createPMCampaign(body));
  }

  createDisplayCampaign(body: Google.CreateAd.GoogleDisplayCampaignRequest) {
    return this.graphqlService.post(Query.GoogleQ.adCreation.createDisplayCampaign(body));
  }

  uploadPerformanceMaxImages(images: Partial<Google.CreateAd.IUploadPerformanceMaxImagesRequest>) {
    const formData = new FormData();

    Object.keys(images).forEach((key) => {
      const group = images[key] as Google.CreateAd.UploadGroupTuple;

      group.forEach((o) => {
        formData.append(key, o!);
      });
    });

    const initialState: Upload = { state: 'PENDING', progress: 0 };

    return this.http
      .post<Google.CreateAd.IUploadPerformanceMaxImagesResponse>(
        `${environment.apiUrl}/google/upload-image/campaign/performance-max`,
        formData,
        {
          reportProgress: true,
          observe: 'events'
        }
      )
      .pipe(scan(calculateState, initialState));
  }

  uploadDisplayCampaignImages(images: Partial<Google.CreateAd.IUploadPerformanceMaxImagesRequest>) {
    const formData = new FormData();

    Object.keys(images).forEach((key) => {
      const group = images[key] as Google.CreateAd.UploadGroupTuple;

      group.forEach((o) => {
        formData.append(key, o!);
      });
    });

    const initialState: Upload = { state: 'PENDING', progress: 0 };

    return this.http
      .post<Google.CreateAd.IUploadPerformanceMaxImagesResponse>(
        `${environment.apiUrl}/google/upload-image/campaign/display`,
        formData,
        {
          reportProgress: true,
          observe: 'events'
        }
      )
      .pipe(scan(calculateState, initialState));
  }

  getAdAccountCreationLink(contextId: string) {
    return this.graphqlService.get(Query.GoogleQ.getAdAccountCreationLink({ contextId }));
  }

  /**
   *  Retrieves an ad account minimum budget as cent.
   * @returns {Number}
   */
  getMinimumBudgetAmount() {
    return this.graphqlService.get(Query.GoogleQ.adCreation.minimumBudgetAmount());
  }

  getAdGroups$(request: Google.Dashboard.IAdGroupWithMetricsRequest) {
    return this.graphqlService.get(Query.GoogleQ.dashboard.adGroupsWithMetrics(request));
  }

  getAssetGroups$(request: Google.Dashboard.IAssetsGroupRequest) {
    return this.graphqlService.get(Query.GoogleQ.dashboard.assetGroups(request));
  }

  turnOnCampaings$(request: Google.Dashboard.ITurnCampaignsRequest) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.turnOnCampaignsGoogle(request));
  }

  turnOffCampaings$(request: Google.Dashboard.ITurnCampaignsRequest) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.turnOffCampaignsGoogle(request));
  }

  getAccountImages$(filter: Base.CreateAd.ImageAssetFilter) {
    return this.graphqlService.get(Query.GoogleQ.adCreation.accountImages({ filter }));
  }

  getGoogleAdGroupAdsWithMetrics$(request: Google.Dashboard.IGetGoogleAdGroupAdsWithMetricsRequest) {
    return this.graphqlService.get(Query.GoogleQ.dashboard.getGoogleAdGroupAdsWithMetrics(request));
  }

  editCampaignBudgets$(request: Google.Dashboard.IGoogleEditCampaignBudgetsRequest) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.editCampaignBudgets(request));
  }

  editCampaign$(request: Google.Dashboard.IGoogleEditCampaignRequest) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.editCampaignGoogle(request));
  }

  editAdGroup$(request: Google.Dashboard.IGoogleEditableAdGroupRequest) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.editAdGroupGoogle(request));
  }

  createSearchCampaign$(request: Google.CreateAd.GoogleSearchCampaignRequest) {
    return this.graphqlService.post(Query.GoogleQ.adCreation.createSearchCampaign(request));
  }

  removeCampaign(input: Google.Dashboard.GoogleCampaignRemove) {
    return this.graphqlService.post(Query.GoogleQ.dashboard.campaignRemoveQuery(input));
  }
}
