import { ChangeDetectorRef, Injectable } from '@angular/core';
import { CreateAdState } from '@pages/create-ad/state';
import { delay, delayWhen, filter, map, retryWhen, switchMap } from 'rxjs/operators';
import { environment } from '@environment';
import { Upload } from '@core/utils';
import { Linkedin, UploadImageItem } from '@core/models';
import { throwError, timer } from 'rxjs';
import { IRestError } from '@core/models/base.model';
import { LinkedInSingleImageStatusEnum } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/modal';
import { Store } from '@ngxs/store';
import { LinkedinService } from '@core/services';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LinkedinImageUploadService {
  constructor(
    private store: Store,
    private linkedinService: LinkedinService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  uploadImage({
    image,
    images,
    index,
    uploadedImages,
    subscribeCb
  }: {
    image: File;
    images: UploadImageItem[];
    index: number;
    uploadedImages?: Linkedin.IdWithUrl[];
    subscribeCb?: (uploadResult: {
      uploadResponse: PromiseSettledResult<Linkedin.CreateAd.UploadResponse>[];
      singleImageDetail: Linkedin.CreateAd.LinkedinImageDetail;
    }) => void;
  }) {
    const formData = new FormData();
    formData.append('files', image);
    const platform = this.store.selectSnapshot(CreateAdState.selectedPlatformDetail);

    if (platform?.pageId) {
      this.linkedinService
        .uploadImage$(platform.pageId, formData)
        .pipe(
          map((uploadResult) => {
            images[index].startedUploading = true;
            if (uploadResult.progress !== 100) {
              images[index].progressValue = uploadResult.progress;
            }
            this.cdr.detectChanges();
            if (!environment.production) console.log('[CreateAd.Linkedin.UploadResult]: ', uploadResult);
            return uploadResult;
          }),
          filter<Upload>((uploadResult) => uploadResult.state === 'DONE'),
          delay(3000),
          map((val) => val?.result),
          switchMap((uploadResponse: PromiseSettledResult<Linkedin.CreateAd.UploadResponse>[]) =>
            uploadResponse[0].status == 'fulfilled'
              ? this.linkedinService.getSingleImageDetail(uploadResponse[0].value.image).pipe(
                  map((singleImageDetail) => {
                    if (
                      [LinkedInSingleImageStatusEnum.PROCESSING, LinkedInSingleImageStatusEnum.WAITING_UPLOAD].includes(
                        singleImageDetail?.status as LinkedInSingleImageStatusEnum
                      )
                    ) {
                      throw { error: { message: ['WAIT_AVAILABLE'] } };
                    } else if (singleImageDetail?.status === LinkedInSingleImageStatusEnum.PROCESSING_FAILED) {
                      throw { error: { message: ['PROCESSING_FAILED'] } };
                    }
                    return { uploadResponse, singleImageDetail };
                  }),
                  retryWhen((errors) => {
                    return errors.pipe(
                      delayWhen((error) =>
                        error?.error?.message?.includes('WAIT_AVAILABLE') ? timer(3000) : throwError(error)
                      )
                    );
                  })
                )
              : throwError(uploadResponse[0].reason)
          )
        )
        .subscribe({
          next: (response) => {
            const { uploadResponse, singleImageDetail } = response;
            if (singleImageDetail) {
              images[index].progressValue = 100;

              uploadedImages?.push({
                id: (uploadResponse[0] as PromiseFulfilledResult<Linkedin.CreateAd.UploadResponse>).value
                  .image as Linkedin.ImageUrn,
                url: singleImageDetail?.downloadUrl || ''
              });
              subscribeCb && subscribeCb({ uploadResponse, singleImageDetail });
              this.cdr.detectChanges();
              this.toastrService.success('Image upload successful.', 'Successful');
            }
          },
          error: (err: { error: IRestError }) => {
            console.log({ err });
            if (err.error?.message) {
              err.error.message.forEach((o) => {
                this.toastrService.warning(o);
              });

              images[index].startedUploading = false;
              images[index].progressValue = 0;
            }
          }
        });
    }
  }
}
