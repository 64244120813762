import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Google, GoogleService } from '@core/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-extensions-youtube-modal',
  templateUrl: './extensions-youtube-modal.component.html'
})
export class ExtensionsYoutubeModalComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  youtubeLinks: Google.CreateAd.IYoutubeVideoValidity[] =
    this.adCreationModel.ad.youtubeExtension?.create?.youtubeLinks || [];

  youtubeExtensionForm = new FormGroup({
    name: new FormControl(this.adCreationModel.ad.youtubeExtension?.create?.name, [
      Validators.required,
      Validators.min(2)
    ]),
    youtubeUrl: new FormControl('', [Validators.required, Validators.min(2)]),
    youtubeUrls: new FormControl(this.adCreationModel.ad.youtubeExtension?.create?.urls, [Validators.required])
  });

  constructor(
    public modal: NgbActiveModal,
    private googleService: GoogleService,
    private googleStateService: GoogleStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  youtubeSearch() {
    if (this.youtubeExtensionForm.value?.youtubeUrl?.trim().length)
      this.googleService.youtubeUrlValidation({ youtubeUrl: this.youtubeExtensionForm.value.youtubeUrl }).subscribe({
        next: (result) => {
          if (result) {
            if (this.youtubeLinks.filter((x) => x.id === result.id).length > 0) return;
            this.youtubeLinks?.push(result!);

            this.youtubeExtensionForm.patchValue({
              youtubeUrls: this.youtubeLinks.map((x) => x.url)
            });
          }
        }
      });
  }

  deleteItem(index) {
    this.youtubeLinks?.splice(index, 1);
    this.adCreationModel!.ad!.youtubeExtension?.create.urls.splice(index, 1);
  }

  closeModal() {
    this.modal.close();
  }

  onSubmit(): void {
    if (this.youtubeExtensionForm.valid) {
      const { name, youtubeUrls } = this.youtubeExtensionForm.value;
      this.adCreationModel.ad.youtubeExtension!.create!.name = name!;
      this.adCreationModel.ad.youtubeExtension!.create.youtubeLinks! = this.youtubeLinks;
      this.adCreationModel.ad.youtubeExtension!.create!.urls = youtubeUrls || [];
      this.cdr.detectChanges();
      this.modal.close();
    }
  }
}
