<div
  class="aayn-header-with-progressbar"
  [ngClass]="{ 'aayn-header-width-progressbar__has-progress': progress }"
  [style.--progress-percent]="((progress || 0) * 100) / totalSteps + '%'"
>
  <div class="aayn-header-with-progressbar--left">
    <ayn-icon [name]="icon"></ayn-icon>
    <div class="aayn-header-with-progressbar--left-content">
      <h4 class="aayn-header-with-progressbar--left-content__title">{{ title }}</h4>
      <span class="aayn-header-with-progressbar--left-content__description">{{ description }}</span>
    </div>
  </div>
</div>
