<section class="aayn-create-ad--item--container">
  <section class="aayn-create-ad--item--row">
    <aayn-select-ad-item
      *ngFor="let adType of adTypes$ | async"
      [title]="adType.title"
      [active]="adType.active"
      [empty]="adType.empty"
      [description]="adType.description"
      [adsType]="adType.icon"
      (btnClick)="adTypeChangeStatus(adType)"
    >
    </aayn-select-ad-item>
  </section>
</section>

<div class="aayn-ad-type-container">
  <div class="aayn-ad-type-container--content mt:5">
    <div [ngSwitch]="selectedAdType">
      <div *ngSwitchCase="AdTypes.PerformanceMax">
        <aayn-performance-max-ads--google></aayn-performance-max-ads--google>
      </div>

      <div *ngSwitchCase="AdTypes.DisplayAds">
        <aayn-display-ads--google></aayn-display-ads--google>
      </div>

      <div *ngSwitchCase="AdTypes.SearchAds">
        <aayn-search-ads--google></aayn-search-ads--google>
      </div>
    </div>
    <aayn-ad-preview--google
      class="aayn-ad-type-container--content--preview"
      [selectedAdType]="selectedAdType"
    ></aayn-ad-preview--google>
  </div>
</div>
