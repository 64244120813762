<aayn-modal-for-payments icon="circle-arrow-up" [title]="title" [description]="description">
  <div class="aayn-payment-price-table">
    <div class="aayn-payment-price-table--row">
      <div class="aayn-payment-price-table--col">Description</div>
      <div class="aayn-payment-price-table--col">Amount</div>
    </div>

    <div class="aayn-payment-price-table--inner">
      <div class="aayn-payment-price-table--row" *ngIf="oldPackage">
        <div class="aayn-payment-price-table--col">
          <span class=":from">
            {{ oldPackage.displayName }}
            <ayn-icon name="chevron-right"></ayn-icon>
          </span>

          <span class=":to">{{ newPackage.displayName }}</span>
        </div>
        <div class="aayn-payment-price-table--col">
          <span class=":from">
            {{ oldPackage.price | currency: oldPackage.currencyCode }}
            <ayn-icon name="chevron-right"></ayn-icon>
          </span>

          <span class=":to">{{ newPackage.price | currency: newPackage.currencyCode }}</span>
        </div>
        <ng-container *ngIf="!oldPackage">
          <div class="aayn-payment-price-table--col">{{ newPackage.displayName }}</div>
          <div class="aayn-payment-price-table--col">{{ newPackage.price | currency: newPackage.currencyCode }}</div>
        </ng-container>
      </div>

      <div class="aayn-payment-price-table--row" *ngIf="newPackage.discountPrice">
        <div class="aayn-payment-price-table--col">Total Discount</div>
        <div class="aayn-payment-price-table--col">
          {{ newPackage.discountPrice | currency: newPackage.currencyCode }}
        </div>
      </div>

      <div class="aayn-payment-price-table--row color:primary">
        <div class="aayn-payment-price-table--col">Final Amount</div>
        <div class="aayn-payment-price-table--col">
          {{ newPackage.discountAppliedPrice | currency: newPackage.currencyCode }}
        </div>
      </div>
    </div>
  </div>

  <button
    ayn-button="primary"
    icon="circle-chevron-right"
    (click)="upgradeNow()"
    [disabled]="loader$ | async"
    [loading]="loader$ | async"
  >
    Upgrade Now
  </button>
</aayn-modal-for-payments>
