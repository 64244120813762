import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Step, Stepper } from '@ayn-ui/lib/modules';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-account-select-modal-content',
  templateUrl: 'account-select-modal-content.component.html'
})
export class AccountSelectModalContentComponent implements OnInit, OnChanges {
  @Input() title = 'Select Ad Account';

  @Input() icon?: string;

  @Input() description = 'Select the ad account of this business.';

  @Input() onboardingSteps: Step[] = [
    {
      title: 'Ad Account Select',
      description: null,
      status: 'not-set',
      className: ''
    }
  ];

  @Input() activeStep = 0;

  @Output() activeStepChange = new EventEmitter<number>();

  @Input() modal: NgbActiveModal | undefined;

  @Input() buttonText = 'Complete';

  @Input() buttonTemplate?: TemplateRef<ElementRef>;

  @Input() buttonDisabled = false;

  @Input() query: string | null = '';

  @Output() queryChange = new EventEmitter<string>();

  @Output() buttonClick = new EventEmitter();

  @Input() headerLeftTemplate?: TemplateRef<HTMLElement>;

  protected queryControl = new FormControl(this.query);

  constructor() {}

  ngOnInit() {
    this.queryControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe((query) => {
      this.queryChange.emit(query!);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      this.queryControl.patchValue(changes.query?.currentValue || '');
    }
  }

  protected changeStepperNext(stepper: Stepper) {
    stepper.next();
    this.activeStep = 1;

    this.activeStepChange.emit(this.activeStep);
  }

  protected changeStepPrev(stepper: Stepper) {
    stepper.prev();
    this.activeStep = 0;
    this.onboardingSteps[1].status = 'not-set';

    this.activeStepChange.emit(this.activeStep);
  }
}
