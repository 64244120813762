import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleYoutubeVideoValidationUrlRequest;
type Output = Google.CreateAd.IGoogleYoutubeVideoValidationUrlResponse;

export const youtubeUrlValidation = makeMutation<Output, Input>(`
query YoutubeUrlValidation($youtubeUrl: String!) {
  google {
    youtubeUrlValidation(youtubeUrl: $youtubeUrl) {
      id
      url
      validity
      index
      title
      description
      thumbnails {
        default {
          height
          width
          url
        }
        high {
          height
          width
          url
        }
        medium {
          height
          width
          url
        }
        maxres {
          height
          width
          url
        }
        standard {
          height
          width
          url
        }
      }
    }
  }
}
`);
