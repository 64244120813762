import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleStateService } from '@pages/create-ad/state';
import {
  createDefaultCallExt,
  createDefaultCalloutExt,
  createDefaultSiteLinkExt,
  createDefaultYoutubeExt
} from '@core/ad-platforms/google/utils';

export type ExtentionType = 'calloutExtension' | 'youtubeExtension' | 'callExtension' | 'sitelinkExtension' | string;

@Component({
  selector: 'aayn-ad-type-collapse-extentions-item',
  templateUrl: './ad-type-collapse-extentions-item.component.html'
})
export class AdTypeCollapseExtentionsItemComponent implements OnInit {
  @Output() buttonClick = new EventEmitter();

  @Input() title = 'YouTube Video Extension';

  @Input() extentionType?: ExtentionType;

  @Input() isButtonShow = false;

  @Input() description = 'Add a custom YouTube Video to your ad';

  isClearHover: boolean = false;

  isEditHover: boolean = false;

  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(private googleStateService: GoogleStateService) {}

  ngOnInit(): void {}

  clearExtentions() {
    switch (this.extentionType) {
      case 'youtubeExtension':
        this.adCreationModel.ad.youtubeExtension = createDefaultYoutubeExt().youtubeExtension;
        break;
      case 'calloutExtension':
        this.adCreationModel.ad.calloutExtension = createDefaultCalloutExt().calloutExtension;
        break;
      case 'callExtension':
        this.adCreationModel.ad.callExtension = createDefaultCallExt().callExtension;
        break;
      case 'sitelinkExtension':
        this.adCreationModel.ad.sitelinkExtension = createDefaultSiteLinkExt().sitelinkExtension;
        break;
    }
  }
}
