<section class="ayn-datepicker" [ngClass]="{ disabled: disabled }" (click)="selectOrClearDate($event,d)">
  <label class="ayn-datepicker--label" [for]="'datepicker-' + uuid">{{ inputText | aynTranslate | async }}</label>

  <input
    class="ayn-datepicker--input"
    ayn-input
    [placeholder]="placeholder"
    [(ngModel)]="model"
    ngbDatepicker
    [datepickerClass]="inModal ? 'ayn-datepicker--calendar--in-modal' : 'ayn-datepicker--calendar'"
    [footerTemplate]="timePickerShow ?  footerTemplate : withFooter"
    [firstDayOfWeek]="7"
    #d="ngbDatepicker"
    [id]="'datepicker-' + uuid"
    (click)="!readonly ? d.toggle() : null"
    [container]="container"
    readonly
    (dateSelect)="dateChange$($event)"
    [minDate]="_minDate"
    [maxDate]="_maxDate"
  />

  <button
    class="datepicker-button"
    ayn-button
    type="button"
    [class.active]="icon === 'exit'"
    [icon]="icon"
    (mouseover)="changeIconIfOptional()"
    (mouseleave)="icon = 'datepicker'"
    [disabled]="disabled"
  ></button>
</section>

<ng-template #footerTemplate>
  <footer class="ayn-datepicker--footer" *ngIf="false">
    <header>
      <span>{{'Clock Selection' | aynTranslate | async}}</span>
    </header>

    <main>
      <ngb-timepicker [ngModel]="time" (ngModelChange)="timeChange($event)"></ngb-timepicker>
    </main>
  </footer>
</ng-template>
