<div class="aayn-create-ad--item" *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta" [@fadeInOnEnter]>
      <aayn-select-audience--facebook></aayn-select-audience--facebook>
    </div>

    <div *ngSwitchCase="Platforms.Google" [@fadeInOnEnter]>
      <aayn-select-audience--google></aayn-select-audience--google>
    </div>

    <div *ngSwitchCase="Platforms.Bing" [@fadeInOnEnter]>
      <aayn-select-audience--bing></aayn-select-audience--bing>
    </div>

    <div *ngSwitchCase="Platforms.LinkedIn" [@fadeInOnEnter]>
      <aayn-select-audience--linkedin></aayn-select-audience--linkedin>
    </div>

    <div *ngSwitchCase="Platforms.SmartCampaign" [@fadeInOnEnter]>
      <aayn-select-audience--smart-campaign></aayn-select-audience--smart-campaign>
    </div>

    <div *ngSwitchCase="Platforms.TikTok" [@fadeInOnEnter]>
      <aayn-select-audience--tiktok></aayn-select-audience--tiktok>
    </div>
  </div>

  <aayn-ad-item-footer>
    <button
      ayn-button="primary"
      class="aayn-create-ad--item--btn"
      (click)="nextStep(selectedPlatform)"
      type="submit"
      [width]="215"
      [innerHTML]="'Next Step; Ad Type' | translate"
    ></button>
  </aayn-ad-item-footer>
</div>
