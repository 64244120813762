import { UserLoginResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  username: string;
};

type Output = UserLoginResponse;

export const forgotPassword = makeMutation<Output, Input>(`
    query ForgotPassword($username: String!) {
      forgotPassword(username: $username)
    }
`);
