import { makeQuery, QueryResult } from '@core/queries';
import { OpenAI } from '@core/models';

const queryName = 'getLogos';

type Output = QueryResult<typeof queryName, OpenAI.LogosOutput>;

export const searchLogosQuery = makeQuery<Output, OpenAI.UrlInput>(`
query GetLogos($url:String!) {
  openAI {
    getLogos(url:$url) {
      logos
    }
  }
}
`);
