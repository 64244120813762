<ayn-modal-page #modalPage>
  <ng-template #body>
    <aayn-account-select-modal-content
      [modal]="modalPage.modalRef"
      [onboardingSteps]="steps"
      [(activeStep)]="activeStep"
      [query]="query"
      (queryChange)="queryChange($event)"
      [buttonText]="buttonText"
      [buttonTemplate]="activeStep === 0 ? buttonTemplateAdSelect : buttonTemplateComplete"
      [buttonDisabled]="disabled"
      (buttonClick)="onButtonClick()"
      icon="meta"
    >
      <aayn-account-page-select
        *ngIf="activeStep === 0"
        [loader$]="loader$"
        [pages]="pages | aynFilterObj: { name: query }"
        [selectedPageAccount]="selectedPage"
        [socialAccounts]="instagramAccounts"
        [socialAccountId]="selectedInstagramAccountId || ''"
        (onSelectPage)="onSelectPage($event)"
        (onSocialAccountChange)="onSocialAccountIdChange($event)"
      ></aayn-account-page-select>

      <aayn-account-ad-account-select
        *ngIf="activeStep === 1 && { adAccounts: adAccounts$ | async } as data"
        [adAccounts]="data.adAccounts || [] | aynFilterObj: { name: query }"
        (selectAdAccount)="selectAdAccount($event)"
        [platform]="platforms.Meta"
        [query]="query"
      ></aayn-account-ad-account-select>
    </aayn-account-select-modal-content>

    <ng-template #buttonTemplateAdSelect
      >{{ 'Next Step;' | translate }}&nbsp;<b>{{ 'Ad Account Select' | translate }}</b></ng-template
    >
    <ng-template #buttonTemplateComplete>{{ 'Complete' | translate }}</ng-template>
  </ng-template>
</ayn-modal-page>
