import { FacebookCreateAd } from '@core/ad-platforms';
import { toLinkedInAdCreationModel } from '@core/ad-platforms/smart-campaign/mappers';
import {
  Platforms,
  PlatformsBackend,
  PlatformsBackendNames,
  SmartCampaignPlatforms
} from '@core/models/platforms.model';
import { AspectRatio } from '@shared/models';

import { IdWithUrl, IdWithUrlGlobal } from '../base';
import { Bing } from '../bing';
import { Facebook } from '../facebook';
import { Google } from '../google';
import { Linkedin } from '../linkedin';
import { TikTok } from '../tiktok';
import { MergeArrayElement, NotUndefined } from '@core/models/base.model';
import { divideArray } from '@ayn-ui/lib/helpers';

export module SmartCampaign {
  export module Client {
    export enum CampaignObjective {
      WebsiteTraffic = 'website-traffic',
      ConversionWebsite = 'conversion-website'
    }

    export enum AudienceType {
      Manual = 'manual'
    }

    export enum AdType {
      Default = 'default'
    }

    type PlatformsObject<ValueType = any> = Partial<Record<Lowercase<SmartCampaignPlatforms>, ValueType>>;

    export type TikTokSmartCampaignPlatform = {
      pixelId?: string;
      pixelEventCode?: string;
      identity: TikTok.Business.TikTokIdentityOutputDto;
    };

    export type LinkedinSmartCampaignPlatform = {
      conversionType?: Linkedin.LinkedInConversion;
      costType: Linkedin.LinkedInCostType;
      account: string;
    };

    export type BingSmartCampaignPlatform = {
      goals: Bing.AudienceCampaign.IBingConversionGoalOutput[];
    };

    export type GoogleSmartCampaignPlatform = {
      conversionGoal: Google.CreateAd.GoogleConversionGoalInput[];
    };

    export type SmartCampaignPlatform = {
      bing?: BingSmartCampaignPlatform;
      facebook?: Facebook.PromotedObject;
      google?: GoogleSmartCampaignPlatform;
      linkedin?: LinkedinSmartCampaignPlatform;
      tiktok?: TikTokSmartCampaignPlatform;
    };

    export interface Campaign {
      objective: CampaignObjective;
      name: string;

      platforms?: SmartCampaignPlatform;

      budget: {
        useSmartBudget: boolean;
        preferredCurrency: string;
        amount: number;
        type: SmartCampaignBudgetType;

        platforms?: Record<Platforms, SmartCampaignBudget>;
      };

      startDate: Date;
      endDate?: Date | null;
    }

    export interface SmartCampaignBudget {
      amount: number;
      type: SmartCampaignBudgetType;
    }

    export enum SmartCampaignBudgetType {
      Daily = 'Daily',
      Total = 'Total'
    }

    /**
     * #AUDIENCE#
     */
    export interface Audience {
      name: string;

      age: {
        min: number;
        max: number;
      };

      gender: {
        male: boolean;
        female: boolean;
      };

      geoLocations: {
        inclusions: {
          [Platforms.Meta]?: GeoLocationItem<Facebook.GeoLocationItem>[];
          [Platforms.Google]?: GeoLocationItem<Google.CreateAd.ILocationSearch>[];
          [Platforms.LinkedIn]?: GeoLocationItem<Linkedin.CreateAd.SearchByFacetEdgeNode>[];
          [Platforms.Bing]?: GeoLocationItem<Bing.AudienceCampaign.BingLocationConstant>[];
          [Platforms.TikTok]?: GeoLocationItem<TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto>[];
        };
      };

      detailedTargetings: SmartCampaignInterestResults;
      detailedTargetingResults: SmartCampaignInterestResults;

      languages: SmartCampaign.Backend.SmartCampaignLanguageDto[];

      precisionAudience: PrecisionAudience;
    }

    export interface PrecisionAudience {
      url: string;
      description: string;
      targetAudience: string;
    }

    export type GeoLocationItem<T> = {
      platformSpecificName: string;
    } & T;

    export enum Gender {
      Male,
      Female,
      All
    }

    export type RecommendedInterest = { isRecommend: boolean };

    export type SmartCampaignInterestResults = {
      [key in keyof Backend.SmartCampaignInterestResults]:
        | MergeArrayElement<NotUndefined<Backend.SmartCampaignInterestResults[key]>, RecommendedInterest>
        | undefined;
    };

    export function getInterestPlatform(key: Backend.SmartCampaignInterestPlatforms): PlatformsBackend {
      const platformKey = key;
      const platformNames = Object.keys(PlatformsBackend).filter((o) => isNaN(+o));
      const platform = platformNames.find((o) => o.toUpperCase() === platformKey.toUpperCase());
      return PlatformsBackend[platform!];
    }

    export function divideInterestResults(
      interests: Backend.SmartCampaignInterestResults
    ): [SmartCampaignInterestResults, SmartCampaignInterestResults] {
      const entries = Object.entries(interests) as [
        keyof Backend.SmartCampaignInterestResults,
        Backend.SmartCampaignInterestPlatformResult
      ][];
      return entries.reduce(
        ([detailedTargetings, detailedTargetingResults], [key, interests]) => {
          const _interests =
            interests?.map((o) => ({
              ...o,
              isRecommend: true
            })) || [];

          const [take, rest] = divideArray(_interests, 5);
          detailedTargetingResults[key] = rest;
          detailedTargetings[key] = take;
          return [detailedTargetings, detailedTargetingResults];
        },
        [{}, {}]
      );
    }

    export function changeRecommendedInterest(
      key: keyof Backend.SmartCampaignInterestResults,
      detailedTargetings: SmartCampaignInterestResults,
      detailedTargetingResults: SmartCampaignInterestResults,
      index: number
    ) {
      const targeting = detailedTargetings[key];
      const isRecommend = targeting?.[index].isRecommend;

      targeting?.splice(index, 1);

      if (isRecommend) {
        const nextItem = detailedTargetingResults?.[key]?.splice(index, 1);
        if (nextItem?.length) {
          targeting.push(nextItem[0] as any);
        }
      }
    }

    /**
     * #END:AUDIENCE#
     */

    /**
     *  #AD#
     */

    export interface Ad {
      name: string;

      shortHeadlines: string[];
      headlines: string[];
      descriptions: string[];
      primaryTexts: string[];

      callToAction: SmartCampaign.Backend.SmartCampaignCallToActionItemDto<Backend.SmartCampaignCallToActionPlatformDetailDto>;
      websiteUrl: string;

      images: ImageAssets;

      businessName: string;
    }

    export interface ImageItem {
      typeName?: string;
      images: IdWithUrlGlobal<string>[];
      groupName: string;
    }
    export type ImageAssetPlatforms = 'facebook' | 'linkedin' | 'google' | 'tiktok' | 'bing';
    export type ImageAssets = Partial<Record<ImageAssetPlatforms, ImageItem[]>>;

    /**
     * #END:AD#
     */

    export type SmartCampaignResult<T> = { isSmartCampaign: boolean } & T;
  }

  export module Backend {
    export type CanvaDesignType = 'InstagramPost' | 'InstagramPostPortrait' | 'FacebookAd' | 'Logo';

    export enum SmartCampaignOperationStatus {
      Failed = 'Failed',
      NotSupported = 'NotSupported',
      Successful = 'Successful'
    }

    enum SmartCampaignBudgetType {
      Daily = 'Daily',
      Total = 'Total'
    }

    export enum BingMsanImage {
      LandscapeImageMedia = 'LandscapeImageMedia',
      SquareImageMedia = 'SquareImageMedia',
      ImageMedia15X10 = 'ImageMedia15X10',
      ImageMedia133X100 = 'ImageMedia133X100',
      ImageMedia178X100 = 'ImageMedia178X100'
    }

    export function filterPlatforms(selectedPlatforms: Platforms[], excludePlatforms: Platforms[]) {
      return selectedPlatforms.filter((o) => !excludePlatforms.some((ep) => ep == o));
    }

    export function isAllSuccess(statuses: SmartCampaignPlatformStatusesDto) {
      return (
        Object.values(statuses).filter((o) => o == SmartCampaignOperationStatus.Successful).length ==
        Object.values(statuses).length
      );
    }

    export interface SmartCampaignPlatformStatusesDto {
      facebook?: SmartCampaignOperationStatus;
      google?: SmartCampaignOperationStatus;
      linkedIn?: SmartCampaignOperationStatus;
      bing?: SmartCampaignOperationStatus;
    }

    export interface SmartCampaignPlatformFailures {
      facebook?: string[];
      google?: string[];
      linkedIn?: string[];
      bing?: string[];
      tiktok?: string[];
    }

    export interface SmartCampaignLanguageResultDto {
      statuses: SmartCampaignPlatformStatusesDto;
      errors: Backend.SmartCampaignPlatformFailures;
      results: SmartCampaignLanguageDto[];
    }

    export interface SmartCampaignLanguagePlatformDetailDto {
      id: string;
      code: string;
      platformSpecificName: string;
    }

    export interface SmartCampaignLanguageDto
      extends Partial<Record<SmartCampaignInterestPlatforms, SmartCampaignLanguagePlatformDetailDto>> {
      name: string;
    }

    export interface SmartCampaignCallToActionResultDto {
      statuses: SmartCampaignPlatformStatusesDto;
      failures: SmartCampaignPlatformFailures;
      results: SmartCampaignCallToActionItemDto[];
    }

    export interface SmartCampaignCallToActionItemDto<T = SmartCampaignCallToActionPlatformDetailDto[]>
      extends Partial<Record<SmartCampaignInterestPlatforms, T>> {
      name: string;
    }

    export interface SmartCampaignCallToActionPlatformDetailDto {
      id: string;
      platformSpecificName: string;
    }

    export interface SmartCampaignInterestGenerateRequestDto {
      description: string;
      targetAudience: string;
      location: string;
    }

    export interface SmartCampaignInteresetGenerateResponseDto {
      results: SmartCampaignInterestResults;
      errors: SmartCampaignPlatformFailures;
      statuses: SmartCampaignPlatformStatusesDto;
    }

    export type SmartCampaignInterestPlatforms = 'facebook' | 'google' | 'linkedIn' | 'bing' | 'tiktok';

    export type SmartCampaignInterestPlatformResult = Exclude<
      SmartCampaignInterestResults[SmartCampaignInterestPlatforms],
      undefined
    >;

    export interface SmartCampaignInterestResults {
      facebook?: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
      google?: Google.CreateAd.IInterestsSearch[];
      bing?: BingInterestResult[];
      linkedIn?: SmartCampaignInterestType[];
      tiktok?: TikTokInterestResult[];
    }

    interface BingInterestResult {
      id: string;
      name: string;
      type: string;
      audienceNetworkSize: number;
    }

    interface TikTokInterestResult {
      id: string;
      name: string;
      type: string;
    }

    type SmartCampaignInterestType = Linkedin.CreateAd.SearchByFacetEdgeNode & {
      id: string;
      type: string;
      facetUrn: string;
    };

    export interface AddressComponentItem {
      longName: string;
      shortName: string;
      types: string[];
    }

    export interface GetSmartLocationRequest {
      addressComponents: AddressComponentItem[];
    }

    export interface GetSmartLocationResponse {
      statuses: SmartCampaignPlatformStatusesDto;
      errors: SmartCampaignPlatformFailures;
      results: SmartLocationResults;
    }

    export interface SmartLocationResults {
      facebook: SmartCampaignLocationPlatformFacebookDto;
      linkedIn: SmartCampaignLocationPlatformLinkedInDto;
      bing: SmartCampaignLocationPlatformBing;
    }

    export interface SmartCampaignSeperatedLocationResultDto {
      statuses: SmartCampaignPlatformStatusesDto;
      failures: SmartCampaignPlatformFailures;
      results: SmartCampaignSeperatedLocationsDto;
    }

    export interface SmartCampaignSeperatedLocationsDto {
      facebook: SmartCampaignLocationPlatformFacebookDto[];
      linkedIn: SmartCampaignLocationPlatformLinkedInDto[];
      bing: SmartCampaignLocationPlatformBing[];
    }

    export interface SmartCampaignLocationPlatformFacebookDto {
      platformSpecificName: string;
      key: string;
      type: string;
      countryCode: string;
      countryCodes: String[];
      countryName: string;
      region: string;
      regionId: string;
      primaryCity: string;
      primaryCityId: string;
    }

    export interface SmartCampaignLocationPlatformLinkedInDto {
      platformSpecificName: string;
      urn: string;
      facetUrn: string;
    }

    export interface SmartCampaignLocationPlatformBing {
      platformSpecificName: string;
      id: string;
      type: string;
    }

    export interface ImageConfigRequest {
      facebook: boolean;
      google: boolean;
      bing: boolean;
      linkedin: boolean;
      tiktok: boolean;
    }

    export interface UploadImageDetails {
      facebook?: boolean;
      google?: boolean;
      linkedin?: {
        owner: string;
      };
      bing?: {
        type: BingMsanImage;
      };
      tiktok?: boolean;
    }
    export enum ImageGroupName {
      Square = '1:1 Square Images',
      Portrait4x5 = '4:5 Portrait Images',
      Landscape191x100 = '1.91:1 Landscape Images',
      LogoLandscape = 'Logo Landscape Images',
      Logo = 'Logo Images',
      Landscape178x100 = '1.78:1 Landscape Images',
      Landscape133x100 = '1.33:1 Landscape Images',
      Landscape15x10 = '1.5:1 Landscape Images',
      Portrait9x16 = '9:16 Portrait Images',
      Portrait100x191 = '1:1.91 Portrait Images',
      Portrait2x3 = '2:3 Portrait Images'
    }

    export interface ImageConfigDto {
      groupName: ImageGroupName;
      includedPlatforms: string[];
      platforms: PlatformsConfigDto;
      common: ImageBasicConfigDto;
      required: boolean;
      //prop for ui
      unValid?: boolean;
    }

    export interface PlatformsConfigDto {
      facebook?: Omit<ImageBasicConfigDto, 'typeName'>;
      google?: ImageBasicConfigWithTypeDto;
      bing?: ImageBasicConfigWithTypeDto;
      linkedin?: ImageBasicConfigDto;
      tiktok?: ImageBasicConfigDto;
    }

    export interface ImageBasicConfigDto {
      quantity: MinMaxDto;
      size: MinMaxDto;
      dimensions: AllDimensionsDto;
      aspectRatio?: AspectRatio.Ratios;
      typeName?: string;
    }

    export interface ImageBasicConfigWithTypeDto {
      quantity: MinMaxDto;
      size: MinMaxDto;
      dimensions: AllDimensionsDto;
      aspectRatio?: AspectRatio.Ratios;
      typeName: string;
    }

    export interface MinMaxDto {
      min: number;
      max: number;
    }

    export interface AllDimensionsDto {
      min: DimensionsDto;
      max: DimensionsDto;
    }

    export interface DimensionsDto {
      width: number;
      height: number;
    }

    export interface FileUploadResponse {
      statuses: SmartCampaignPlatformStatusesDto;
      failures: SmartCampaignPlatformFailures;
      results: {
        facebook: Facebook.CreateAd.IUploadImageResponse;
        bing: Bing.AudienceCampaign.IUploadPerformanceMaxImagesResponse;
        google: IdWithUrl;
        linkedin: Linkedin.CreateAd.UploadResponse;
        tiktok: TikTok.CreateAd.Backend.TikTokUploadImageResponse;
      };
    }

    export interface SmartCampaignCreateDto {
      useSmartBudgetAllocation: boolean;
      smartBudget?: SmartCampaignBudgetDto;
      platforms: SmartCampaignPlatformDto;
    }

    export interface SmartCampaignBudgetDto {
      amount: number;
      type: SmartCampaignBudgetType;
      preferredCurrency: string;
    }

    export type FacebookAdRequestForSmartCampaign = FacebookCreateAd.AdRequest<
      Facebook.AdDynamicCreativeInput,
      Omit<Facebook.CreateAd.ICreateAdSetRequest, 'campaignId'>
    >;

    export type LinkedinCreateAdRequestForSmartCampaign = ReturnType<typeof toLinkedInAdCreationModel>;

    export interface SmartCampaignPlatformDto {
      facebook?: FacebookAdRequestForSmartCampaign;
      bing?: Bing.AudienceCampaign.AudienceCampaignRequestContent;
      google?: Google.CreateAd.GooglePMCampaignRequest;
      linkedIn?: LinkedinCreateAdRequestForSmartCampaign;
      tiktok?: TikTok.CreateAd.Backend.TikTokCampaignDto;
    }

    export interface SmartCampaignValidationResultDto {
      isValid: boolean;
      errorMessages: string[];
    }

    export interface SmartCampaignCreateResultDto {
      statuses: SmartCampaignPlatformStatusesDto;
      failures: SmartCampaignPlatformFailures;
      results: SmartCampaignCreateResultPlatformsDto;
    }

    export interface SmartCampaignBaseResultDto {
      statuses: SmartCampaignPlatformStatusesDto;
      failures: SmartCampaignPlatformFailures;
      results: SmartCampaignCreateResultPlatformsDto;
    }

    export interface SmartCampaignCreateResultPlatformsDto {
      platforms: Partial<Record<SmartCampaignInterestPlatforms, SmartCampaignCreateResultPlatformDto>>;
    }

    export interface SmartCampaignCreateResultPlatformDto {
      createdCampaignIds: string[];
    }

    export interface SmartCampaignPlatformBudgetDto {
      platformCampaignId: string;
      budget: number;
      platform: PlatformsBackendNames;
      currency: string;
    }

    export interface EditSmartBudgetDto {
      smartCampaignId: string;
      preferredCurrency: string;
      totalBudget: number;
    }

    export interface SmartTotalBudgetDto {
      budget: number;
      smartCampaignId: string;
      preferredCurrency?: string;
    }

    export interface SmartCampaignPlatformBudgetOutputDto {
      budget: number;
      platform: PlatformsBackendNames;
      success: boolean;
    }

    export interface SmartCampaignEditStatusOutputDto {
      platform: PlatformsBackendNames;
      success: boolean;
      reason: string;
    }

    export enum SmartCampaignStatus {
      Enabled = 'Enabled',
      Disabled = 'Disabled'
    }

    export module SmartCampaignStatus {
      export const from = (status: boolean) => (status ? SmartCampaignStatus.Enabled : SmartCampaignStatus.Disabled);
    }

    export interface EditSmartCampaignStatusInput {
      smartCampaignId: string;
      campaignStatuses: Array<SmartSingleCampaignStatusDto>;
    }

    export interface SmartSingleCampaignStatusDto {
      platform: keyof typeof PlatformsBackend;
      status: SmartCampaignStatus;
    }

    export interface SmartCampaignId {
      smartCampaignId: string;
    }

    export interface SmartCampaignIdInput {
      platform: string;
      platformCampaignId: string;
    }

    export interface EditPlatformCampaignBudgetsInput {
      smartCampaignId: string;
      budgets: SmartCampaignPlatformBudgetInputDto[];
    }

    export interface SmartCampaignPlatformBudgetInputDto {
      budget: number;
      platform: PlatformsBackendNames;
    }
  }
}
