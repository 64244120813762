import { makeMutation } from '../types';
import { IUpdateBusinessBrandRequest, IUpdateBusinessBrandResponse } from '@core/models';

type Input = IUpdateBusinessBrandRequest;
type Output = IUpdateBusinessBrandResponse;

export const updateBusinessBrand = makeMutation<Output, Input>(`
  mutation UpdateBusinessBrand(
    $brand: BusinessBrandUpdateInput!
    $id: String!
  ) {
    updateBusinessBrand(brand: $brand, id: $id) {
      brandUrl
      color1
      color2
      id
    }
  }
`);
