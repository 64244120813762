import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Clickfraud, Platforms } from '@core/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ClickfraudProtectionService } from '../';

/**
 * Models
 */
export namespace ClickfraudProtection {
  export interface State {
    getPlatform: Platforms;
    getPlatformStatus: Clickfraud.IPlatformStatus;
  }
}

/**
 * Actions
 */
export class SetClickfraoudPlatform {
  static readonly type = '[SetClickfraoudPlatform] set Platform';
  static readonly desc = 'SetClickfraoudPlatform setting platform';
  constructor(public payload: Platforms) {}
}

export class SetClickfraoudPlatformStatus {
  static readonly type = '[SetClickfraoudPlatformStatus] set Platform status';
  static readonly desc = 'SetClickfraoudPlatformStatus setting platform status';
  constructor(public payload: Clickfraud.IClickfraudProtectionStatusRequest) {}
}

/**
 * Defaults
 */
export const CLICKFRAUD_PROTECTION_DEFAULTS: ClickfraudProtection.State = {
  getPlatform: Platforms.Meta,
  getPlatformStatus: {} as Clickfraud.IPlatformStatus
};

@State<ClickfraudProtection.State>({
  name: 'ClickfraudProtectionState',
  defaults: CLICKFRAUD_PROTECTION_DEFAULTS
})
@Injectable()
export class ClickfraudProtectionState {
  constructor(private clickfraudProtectionService: ClickfraudProtectionService) {}

  @Selector()
  static getPlatform({ getPlatform }: ClickfraudProtection.State): Platforms {
    return getPlatform;
  }

  @Selector()
  static getPlatformStatus({ getPlatformStatus }: ClickfraudProtection.State): Clickfraud.IPlatformStatus | null {
    return getPlatformStatus;
  }

  @Action(SetClickfraoudPlatform)
  setPlatform({ patchState }: StateContext<ClickfraudProtection.State>, { payload }: SetClickfraoudPlatform) {
    patchState({ getPlatform: payload });
  }

  @Action(SetClickfraoudPlatformStatus)
  setPlatformStatus(
    { patchState }: StateContext<ClickfraudProtection.State>,
    { payload }: SetClickfraoudPlatformStatus
  ) {
    return this.clickfraudProtectionService.clickfraudProtectionStatus$(payload).pipe(
      tap((data) =>
        patchState({
          getPlatformStatus: { status: data == 'Active', platform: payload.source }
        })
      )
    );
  }
}
