import { makeMutation } from '@core/queries';
import { Linkedin } from '@core/models';

type Input = { input: Linkedin.LinkedInCarouselCreativeInputModel };
type Output = { createCarouselAd: string };
export const createCarouselAd = makeMutation<Output, Input>(`
mutation CreateCarouselAd($input: LinkedInCarouselCreativeInputModel!){
  linkedin {
    createCarouselAd(input: $input)
  }
}
`);
