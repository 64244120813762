import { Overlay } from 'apps/ayn-ui/src/public-api';
import { Observable } from 'rxjs';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserLoginResponse } from '@core/models';
import { AuthService } from '@core/services';
import { UserState } from '@core/state';
import { Select } from '@ngxs/store';

import { RoadmapComponent } from '../roadmap/roadmap.component';

@Component({
  selector: 'aayn-user-menu',
  templateUrl: 'user-menu.component.html'
})
export class UserMenuComponent implements OnInit {
  @ViewChild(Overlay) overlay!: Overlay;

  @ViewChild(RoadmapComponent) roadMap!: RoadmapComponent;

  @Select(UserState.user) user$!: Observable<UserLoginResponse>;

  @Input() role: string = 'Business Admin';

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  logout() {
    this.authService.logoutWithOperations();
  }

  openHelpCenter() {
    window.open('https://help-articles.adyouneed.com/', '_blank');

    this.overlay.hide();
  }

  openRoadmap() {
    this.roadMap.openModal();

    this.overlay.hide();
  }
}
