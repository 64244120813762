<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  [adPreviewContainer]="googleAdPreviewService.performanceMax"
  aaynTextAiContainer
>
  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    [description]="
      'Google is recommending this size to be in this pixel range to get the best results possible.' | translate
    "
    [imageGroups]="uploadedImageUrls"
    [showRequiredAlert]="_form.submitted && !isImagesValid"
    (fileRemove)="removeFile($event)"
    adPreview="imageUrls"
    [standAlone]="true"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Headlines" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of longHeadlinesArray.controls; index as index"
        [formControl]="$any(longHeadlinesArray.controls[index])"
        [maxCharacterNumber]="90"
        [requiredMessage]="'You must write at least 3 headline.' | translate"
        placeholder="Tell people what your ad is about"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
    <aayn-ad-type-section header="Short Headline(s) for ads" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of shortHeadlinesArray.controls; index as index"
        [formControl]="$any(shortHeadlinesArray.controls[index])"
        [maxCharacterNumber]="30"
        [requiredMessage]="'You must write at least 3 headline.' | translate"
        [placeholder]="'Write a short headline' | translate"
        adPreview="headline"
        [previewActive]="index === 0"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Description(s) for ads" description="Tell people what your ad is about">
      <aayn-create-ad-input
        *ngFor="let control of descriptionsArray.controls; index as index"
        [formControl]="$any(descriptionsArray.controls[index])"
        [maxCharacterNumber]="90"
        [requiredMessage]="'You must write at least 1 description.' | translate"
        [placeholder]="'Write descriptions' | translate"
        adPreview="description"
        textAiSection="descriptions"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Short Description" description="Write a short description">
      <aayn-create-ad-input
        formControlName="shortDescription"
        [maxCharacterNumber]="60"
        [placeholder]="'Write a short description here' | translate"
        adPreview="shortDescription"
        textAiSection="descriptions"
        [previewVisible]="false"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Business Name" description="Write your business name">
      <aayn-create-ad-input
        formControlName="businessName"
        [placeholder]="'Business Name' | translate"
        [maxCharacterNumber]="25"
        [requiredMessage]="'You must write a business name.' | translate"
        [textCraftUsable]="false"
        adPreview="businessName"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            [placeholder]="'Select CTA' | translate"
            [showTick]="true"
            [showSearch]="false"
            adPreview="callToAction"
            [standAlone]="true"
            formControlName="callToAction"
            [ngModel]="adCreationModel.campaign.callToAction"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="text"
            placeholder="https://adyouneed.com"
            formControlName="url"
            adPreview="url"
            [standAlone]="true"
            ayn-input
            #urlInput
          />
        </ayn-form-field>
      </section>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>

<aayn-collapse-card
  title="Ad Extensions"
  [active]="false"
  [description]="'Get up to 15% higher clickthrough rate by showing additional information on your ads' | translate"
  class="aayn-ad-type--collapse"
>
  <div class="aayn-ad-type--collapse-extentions">
    <aayn-ad-type-collapse-extentions-item
      title="YouTube Video Extension"
      [isButtonShow]="adCreationModel.ad.youtubeExtension?.create?.name != ''"
      description="Add a custom YouTube Video to your ad"
      (buttonClick)="openExtensionsYoutubeModalComponent()"
      extentionType="youtubeExtension"
    ></aayn-ad-type-collapse-extentions-item>

    <aayn-ad-type-collapse-extentions-item
      title="Sitelink extensions"
      description="Add additional links to your ad"
      [isButtonShow]="adCreationModel.ad.sitelinkExtension?.create?.name != ''"
      (buttonClick)="openSitelinkExtensionsModal()"
      extentionType="sitelinkExtension"
    ></aayn-ad-type-collapse-extentions-item>

    <aayn-ad-type-collapse-extentions-item
      title="Callout extensions"
      description="Add more business information to your ad"
      [isButtonShow]="adCreationModel.ad.calloutExtension?.create?.name != ''"
      (buttonClick)="openCalloutExtensionsModalComponent()"
      extentionType="calloutExtension"
    ></aayn-ad-type-collapse-extentions-item>

    <aayn-ad-type-collapse-extentions-item
      title="Call extensions"
      description="Add a phone number to your ad"
      [isButtonShow]="adCreationModel.ad.callExtension?.create?.name != ''"
      (buttonClick)="openCallExtensionsModalComponent()"
      extentionType="callExtension"
    ></aayn-ad-type-collapse-extentions-item>
  </div>
</aayn-collapse-card>
