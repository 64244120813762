import { IUpdateBusinessRequest, IUpdateBusinessResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = IUpdateBusinessRequest;
type Output = IUpdateBusinessResponse;

export const updateBusiness = makeMutation<Output, Input>(`
mutation UpdateBusiness($data: UpdateBusinessDto!, $id: String!) {
  updateBusiness(data: $data, id: $id)
}
`);
