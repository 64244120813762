import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BingService } from '@core/index';
import { Bing, Platforms } from '@core/models';
import { BusinessState } from '@core/state/business.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BingFormService, BingStateService, BingValidationService } from '@pages/create-ad/state';

import { ConnectGoogleAdsAccountModalComponent } from '../connect-google-ads-account-modal';
import { GoogleCampaignImportModalComponent } from '../google-campaign-import-modal';

@Component({
  selector: 'aayn-import-from-bing',
  templateUrl: 'import-from-bing.component.html'
})
export class ImportFromBingComponent implements OnInit {
  protected adCreationModel = this.bingStateService.adCreationModel;

  private _selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);

  googleImportForm = new FormGroup({
    importType: new FormControl(this.adCreationModel!.googleImport.importType || 'all', [Validators.required]),
    credentialId: new FormControl(this.adCreationModel!.googleImport.credentialId, [Validators.required]),
    bingSingleCampaignOption: new FormControl([])
  });

  directionUrl = '';

  constructor(
    private bingValidationService: BingValidationService,
    private bingStateService: BingStateService,
    private bingService: BingService,
    private modalService: NgbModal,
    private store: Store,
    private bingFormService: BingFormService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.adCreationModel!.googleImport.googleAdAccountId = Number(
      this._selectedBusiness?.connectedAdAccounts.find((x) => x.source === Platforms.Google)?.externalId || 0
    );
    this.bingFormService.registerForm(this.googleImportForm);

    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.googleImportForm.statusChanges.subscribe((status) => {
      this.bingValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.bingService.getGoogleCredentialId$().subscribe((result) => {
      if (result.data.getGoogleCredentialId)
        this.googleImportForm.patchValue({
          credentialId: result.data.getGoogleCredentialId
        });
    });

    if (!this._selectedBusiness?.connectedAdAccounts.some((o) => o.source === Platforms.Google)) {
      this.connectGoogleAdsAccount();
    }

    this.googleImportForm.statusChanges.subscribe((status) => {
      this.bingValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.googleImportForm.valueChanges.subscribe((x) => {
      this.adCreationModel!.googleImport.credentialId = x.credentialId!;
      this.adCreationModel!.googleImport.importType = x.importType!;
      this.adCreationModel!.googleImport.selectedCampaigns = x.bingSingleCampaignOption || [];
      this.adCreationModel!.selectedTypes.campaign = Bing.CampaignObjective.ImportFromGoogle;
      this.adCreationModel!.googleImport.googleAdAccountId = Number(
        this._selectedBusiness?.connectedAdAccounts.find((x) => x.source === Platforms.Google)?.externalId || 0
      );
    });

    this.bingService.getGoogleCredentialIdDirectUrl$().subscribe((result) => {
      if (result.data.getGoogleCredentialIdDirectUrl) {
        this.directionUrl = result.data.getGoogleCredentialIdDirectUrl;
        this.cdr.detectChanges();
      }
    });
  }

  clearCampaignIds() {
    this.googleImportForm.patchValue({
      bingSingleCampaignOption: []
    });
    this.cdr.detectChanges();
  }

  googleImportModal() {
    const modalRef = this.modalService.open(GoogleCampaignImportModalComponent, { backdrop: 'static' });

    modalRef.closed.subscribe((data) => {
      this.googleImportForm.patchValue({
        bingSingleCampaignOption: data
      });
      this.cdr.detectChanges();
    });
  }

  private connectGoogleAdsAccount() {
    this.modalService.open(ConnectGoogleAdsAccountModalComponent, { backdrop: 'static' });
  }
}
