import { Component, Input } from '@angular/core';

@Component({
  selector: 'aayn-empty-record',
  template: `
    <div class="aayn-empty-record">
      {{ title | translate }}
    </div>
  `
})
export class EmptyRecordComponent {
  @Input() title: string = '';
}
