import { bounceOutDownOnLeaveAnimation, fadeInUpOnEnterAnimation } from 'angular-animations';

import { Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { defaultInvalidScroll } from '@shared/handlers/default-scroll.handler';

export function manualAudienceScrollHandler(injector: Injector) {
  return (el: ElementRef<HTMLFormElement>) => {
    const audienceComponent = injector.get(ManuelAudienceSetupComponent);
    if (audienceComponent.showPotentialDailyReachWarning && audienceComponent.potentialDailyReach === 0) {
      window.scrollTo({ top: audienceComponent.elRef.nativeElement?.offsetTop || el.nativeElement.offsetTop });
      return;
    }
    defaultInvalidScroll(el);
  };
}

@Component({
  selector: 'aayn-manuel-audience-setup',
  templateUrl: 'manuel-audience-setup.component.html',
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 300, delay: 100, translate: '30px' }),
    bounceOutDownOnLeaveAnimation({ anchor: 'leave', duration: 300, delay: 200, translate: '40px' })
  ],
  providers: [
    {
      deps: [Injector],
      provide: INVALID_SCROLL_HANDLER,
      useFactory: manualAudienceScrollHandler
    }
  ]
})
export class ManuelAudienceSetupComponent implements OnInit {
  @Input() potentialDailyReach = 0;

  @Input() showPotentialDailyReach = false;

  @Input() showPotentialDailyReachWarning = false;

  @Input() potentialDailyReachWarningMessage = '';

  constructor(public elRef: ElementRef) {}

  ngOnInit() {}
}
