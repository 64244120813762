import { Injectable } from '@angular/core';
import { FacebookService } from '@core/services/facebook.service';
import { BingService } from '@core/services/bing.service';
import { GoogleService } from '@core/services/google.service';
import { LinkedinService } from '@core/services/linkedin.service';
import { TiktokService } from '@core/services/tiktok.service';
import { AdPlatforms, Bing, IConnectedAdAccount, Linkedin, Platforms, SmartCampaign, TikTok } from '@core/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BusinessState } from '@core/state/business.state';

export type LinkedinBudgetCampaign = Pick<
  Linkedin.Dashboard.ILinkedInCampaignOutput,
  'type' | 'objectiveType' | 'optimizationTargetType' | 'targetingCriteria' | 'costType' | 'externalId'
>;

@Injectable({ providedIn: 'root' })
export class PlatformBudgetService {
  constructor(
    private facebookService: FacebookService,
    private bingService: BingService,
    private googleService: GoogleService,
    private linkedinService: LinkedinService,
    private tiktokService: TiktokService,
    private store: Store
  ) {}

  getMinimumBudget(
    platform: AdPlatforms,
    type: SmartCampaign.Client.SmartCampaignBudgetType,
    campaign?: LinkedinBudgetCampaign
  ): Observable<{ currency?: string; budget: number } | null> {
    switch (platform) {
      case Platforms.Meta:
        return this.facebookService.getBusinessAdAccountDetail$().pipe(
          map((result) => ({
            currency: result.data.businessAdAccountDetail.currency,
            budget: result.data.businessAdAccountDetail.minimumBudget
          }))
        );
      case Platforms.Google:
        const googleAdAcc = this.store.selectSnapshot(
          BusinessState.SelectedBusinessAdAccountByPlatform(Platforms.Google)
        );
        return this.googleService.getMinimumBudgetAmount().pipe(
          map(({ data }) => ({
            budget: data.minimumBudgetAmount,
            currency: googleAdAcc?.currency
          }))
        );
      case Platforms.Bing:
        const bingAdAcc = this.store.selectSnapshot(BusinessState.SelectedBusinessAdAccountByPlatform(Platforms.Bing));

        return this.bingService
          .getMinimumBudgetAmount$({
            type:
              type === SmartCampaign.Client.SmartCampaignBudgetType.Daily
                ? Bing.BingCurrencyConstantType.MinimumDailyBudget
                : Bing.BingCurrencyConstantType.MinimumMonthlyBudget,
            currency: bingAdAcc!.currency as Bing.BingCurrency
          })
          .pipe(map((result) => ({ budget: result.data.getCurrencyConstantValue, currency: bingAdAcc?.currency })));
      case Platforms.LinkedIn:
        if (!campaign) {
          return of(null);
        }

        const linkedinAcc = this.store.selectSnapshot(
          BusinessState.SelectedBusinessAdAccountByPlatform(Platforms.LinkedIn)
        );
        const model: Linkedin.LinkedInFindPricingInsightModel = {
          account: linkedinAcc!.externalId,
          bidType: campaign.costType,
          campaignType: campaign.type,
          objectiveType: campaign.objectiveType,
          matchType: Linkedin.AdBudgetingMatchType.Exact,
          optimizationTargetType: campaign.optimizationTargetType,
          targetingCriteria: campaign.targetingCriteria as Linkedin.LinkedInCampaignCreationModelTargetingCriteria
        };
        return this.linkedinService.getSuggestedBudget(model).pipe(
          map((res) => ({
            budget: +res.edges[0].node.bidLimits.min.amount,
            currency: res.edges[0].node.suggestedBid.min.currencyCode
          }))
        );
      case Platforms.TikTok:
        const tiktokAdAcc = this.store.selectSnapshot(
          BusinessState.SelectedBusinessAdAccountByPlatform(Platforms.TikTok)
        ) as IConnectedAdAccount<TikTok.Business.TikTokCurrency>;
        return this.tiktokService
          .getMinimumCurrencyValue(tiktokAdAcc.currency)
          .pipe(map((result) => ({ budget: result, currency: tiktokAdAcc.currency })));
    }
  }

  getCurrency(platform: AdPlatforms) {
    return this.store
      .selectSnapshot(BusinessState.SelectedBusiness)
      ?.connectedAdAccounts.find((o) => o.source == platform)?.currency;
  }
}
