<div class="aayn-account-select-modal">
  <header class="aayn-account-select-modal--header">
    <div class="aayn-account-select-modal--header__icon">
      <ayn-icon *ngIf="icon" [name]="icon"></ayn-icon>
    </div>

    <div class="aayn-account-select-modal--header__content">
      <h4 class="aayn-account-select-modal--header__title">{{ title | translate }}</h4>
      <p class="aayn-account-select-modal--header__description">{{ description | translate }}</p>
    </div>

    <ayn-form-field>
      <input
        type="text"
        ayn-input
        [placeholder]="'Type name or id to filter' | translate"
        [formControl]="queryControl"
        autocomplete="off"
      />
      <ayn-icon name="search"></ayn-icon>
    </ayn-form-field>
    <ayn-modal-close [modal]="modal"></ayn-modal-close>
  </header>

  <main class="aayn-account-select-modal--main">
    <div class="aayn-account-select-modal--stepper">
      <ayn-stepper
        #stepper
        [activeStep]="activeStep"
        [steps]="onboardingSteps"
        [clickable]="false"
        [hidden]="headerLeftTemplate"
      ></ayn-stepper>

      <ng-container *ngIf="headerLeftTemplate" [ngTemplateOutlet]="headerLeftTemplate!"></ng-container>

      <div class="aayn-account-select-modal--stepper__body">
        <ng-content></ng-content>
      </div>
    </div>

    <footer class="aayn-account-select-modal--footer">
      <a *ngIf="activeStep == 1" (click)="changeStepPrev(stepper)">
        {{ 'Page Select' | translate }}
      </a>

      <button
        class="aayn-account-select-modal--btn"
        ayn-button="primary"
        ayn-button-block
        type="submit"
        (click)="buttonClick.emit()"
        [disabled]="buttonDisabled"
      >
        <ng-container *ngIf="buttonTemplate; else textTemplate">
          <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
        </ng-container>

        <ng-template #textTemplate>{{ buttonText | translate }}</ng-template>
      </button>
    </footer>
  </main>
</div>
