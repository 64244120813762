import { Facebook } from '@core/models';
import { makeMutation } from '@core/queries';

type Input = Facebook.Dashboard.IUpdateAdObjectStatusRequest;
type Output = { updateAdObjectStatus: boolean };

export const updateAdObjectStatus = makeMutation<Output, Input>(`
  mutation UpdateFacebookAdObjectStatus($adObjectId: String!, $status: FacebookEntityStatus!) {
    facebook {
      updateAdObjectStatus(adObjectId: $adObjectId, status: $status)
    }
  }
`);
