import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartResponseCatcherDirective } from '@shared/directives';
import { SmartCampaign } from '@core/models';

@Component({
  selector: 'aayn-linkedin-targeting-list',
  template: `
    <ayn-badge
      *ngFor="let item of adCreationModel.audience.detailedTargetings?.linkedIn; let index = index"
      color="primary"
      [text]="item.name"
      icon="cross-mini-bold"
      (click)="remove(index)"
    ></ayn-badge>
  `
})
export class LinkedInTargetingListComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    @Optional() private smartResponseCatcherDirective?: SmartResponseCatcherDirective
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  remove(index: number) {
    const audience = this.adCreationModel.audience;
    SmartCampaign.Client.changeRecommendedInterest(
      'linkedIn',
      audience.detailedTargetings,
      audience.detailedTargetingResults,
      index
    );

    this.form?.patchValue({
      detailedTargetings: this.adCreationModel.audience.detailedTargetings
    });

    this.smartResponseCatcherDirective?.processTabs();
  }
}
