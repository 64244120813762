import { flow } from 'fp-ts/lib/function';

import { Func } from './types';

export const createEl = (tag: keyof HTMLElementTagNameMap, className = '', initialHtml = '') => {
  const node = document.createElement(tag);

  node.className = className;
  node.innerHTML = initialHtml;

  return node;
};

const scoped =
  <F extends Func>(fn: F) =>
  <_T>(...args: Parameters<F>) =>
  <T = _T>(context: T): T => (fn.apply(context, args), context);

export const appendChild = scoped(HTMLElement.prototype.appendChild)<HTMLElement>;

export const appendElementNextSiblings = flow(createEl, appendChild);
