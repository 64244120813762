import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skipUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { Facebook } from '@core/models/platforms/facebook';
import { DraftService } from '@core/services/draft.service';
import { FacebookService } from '@core/services/facebook.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';
import { FacebookFormService } from '@pages/create-ad/state/platforms/facebook/facebook.form';

@UntilDestroy()
@Component({
  selector: 'aayn-manual-audience--facebook',
  templateUrl: 'manual-audience.component.html'
})
export class ManualAudienceFacebookComponent implements OnInit {
  adCreationModel = this.facebookStateService.adCreationModel;

  protected _savedAudiences: { node: Facebook.SavedAudienceItem }[] = [];

  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel.adSet.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    minAge: new FormControl(this.adCreationModel.adSet.targeting.minAge, [Validators.required]),
    maxAge: new FormControl(this.adCreationModel.adSet.targeting.maxAge, [Validators.required]),
    genders: new FormControl(this.adCreationModel.adSet.targeting.genders, [AppValidations.minLengthArray(1)]),
    geoLocations: new FormControl(this.adCreationModel.adSet.targeting.geoLocations.inclusions, [Validators.required]),
    excludedGeoLocations: new FormControl(this.adCreationModel.adSet.targeting.geoLocations.exclusions),
    flexibleDetailedTargetings: new FormControl(this.adCreationModel.adSet.targeting.detailedTargetings.inclusions),
    excludedDetailedTargetings: new FormControl(this.adCreationModel.adSet.targeting.detailedTargetings.exclusions),
    locales: new FormControl(this.adCreationModel.adSet.targeting.locales || [])
  });

  protected estimatedReach = 0;

  private isValidByReach = false;

  constructor(
    private facebookService: FacebookService,
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService,
    private cdr: ChangeDetectorRef,
    private facebookForm: FacebookFormService,
    private draftService: DraftService
  ) {}

  ngOnInit() {
    this.facebookForm.registerForm(this.form);

    this.facebookStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        name: o.adSet.name,
        excludedDetailedTargetings: o.adSet.targeting.detailedTargetings.exclusions,
        flexibleDetailedTargetings: o.adSet.targeting.detailedTargetings.inclusions,
        excludedGeoLocations: o.adSet.targeting.geoLocations.exclusions,
        geoLocations: o.adSet.targeting.geoLocations.inclusions,
        genders: o.adSet.targeting.genders,
        locales: o.adSet.targeting.locales,
        maxAge: o.adSet.targeting.maxAge,
        minAge: o.adSet.targeting.minAge
      });

      this.getEstimateReach();
    });

    this.facebookService
      .getSavedAudiences$()
      .pipe(map((o) => o.data))
      .subscribe((result) => {
        this._savedAudiences = result.savedAudiences.edges;
      });

    this.form.valueChanges.pipe(skipUntil(this.draftService.getDraftLoader$ || of())).subscribe((o) => {
      this.adCreationModel.adSet.name = o.name!;

      this.adCreationModel.adSet.targeting = {
        minAge: o.minAge!,
        maxAge: o.maxAge!,
        genders: o.genders!,
        geoLocations: {
          inclusions: o.geoLocations!,
          exclusions: o.excludedGeoLocations!
        },
        detailedTargetings: {
          inclusions: o.flexibleDetailedTargetings!,
          exclusions: o.excludedDetailedTargetings!
        },
        locales: o.locales || []
      };
    });

    this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(750)).subscribe(() => {
      this.getEstimateReach();

      this.facebookValidationService.audienceValid$.next(this.form.status === 'VALID' && this.isValidByReach);
    });
  }

  private getEstimateReach() {
    if (!this.hasAddedGeoLocations) {
      this.estimatedReach = 0;

      this.cdr.detectChanges();

      return;
    }

    const mappedTargetingModel = Facebook.CreateAd.Draft.AdSet.MapAdSetDraftToOriginalModel(this.adCreationModel.adSet);

    this.facebookService
      .getEstimateReach$(mappedTargetingModel.targeting as Facebook.TargetingModel)
      .subscribe((result) => {
        this.estimatedReach = result.data.estimateReach.audienceSizeUpperBound;
        this.isValidByReach = result.data.estimateReach.isValid;

        this.cdr.detectChanges();

        this.facebookValidationService.audienceValid$.next(this.form.status === 'VALID' && this.isValidByReach);
      });
  }

  private get hasAddedGeoLocations() {
    const geoLocations = this.adCreationModel.adSet.targeting.geoLocations;

    return Object.keys(geoLocations!)?.some((key) => geoLocations![key]?.length);
  }

  protected ageRangeChanged([min, max]: [number, number]) {
    if (!this.adCreationModel?.adSet) return;

    this.adCreationModel.adSet.targeting!.minAge = min;
    this.adCreationModel.adSet.targeting!.maxAge = max;

    this.form.patchValue({
      minAge: min,
      maxAge: max
    });
  }
}
