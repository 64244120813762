import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IGoogleEditCampaignBudgetsRequest;
type Output = { editCampaignBudgets: boolean };

export const editCampaignBudgets = makeMutation<Output, Input>(`
  mutation EditCampaignBudgets(
    $budgetIds: [Float!]!
    $dailyBudgetAmount: Float!
  ) {
    google {
      editCampaignBudgets(
        budgetIds: $budgetIds
        dailyBudgetAmount: $dailyBudgetAmount
      )
    }
  }
`);
