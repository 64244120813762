import { cloneDeep } from 'lodash';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { FileUploadAreaEvent, FileUploadGroup } from '@ayn-ui/lib/modules/base/file-upload-area';
import { Google, PlatformStep } from '@core/models';
import { CanvaService } from '@core/services/canva.service';
import { createRequiredValidator, HEADLINES, URL_REGEX, URL_REGEX_WITHOUT_PROTOCOL } from '@core/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { GoogleFormService, GoogleStateService, GoogleValidationService } from '@pages/create-ad/state';
import { isImagesValid } from '../utils';
import {
  CallExtensionsModalComponent,
  CalloutExtensionsModalComponent,
  ExtensionsYoutubeModalComponent,
  PerformanceMaxImages,
  SitelinkExtensionsModalComponent,
  UploadAdsImagesGoogleComponent
} from '../modal';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

@UntilDestroy()
@Component({
  selector: 'aayn-performance-max-ads--google',
  templateUrl: 'performance-max-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class PerformanceMaxAdsGoogleComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  protected longHeadlines = cloneDeep(this.adCreationModel!.ad.assets?.longHeadlines);
  protected shortHeadlines = cloneDeep(this.adCreationModel!.ad.assets?.shortHeadlines);
  protected descriptions = cloneDeep(this.adCreationModel!.ad.assets?.descriptions);

  protected uploadedImageUrls: FileUploadGroup[] = [];

  protected callToActions = Google.CallToActionTypes;

  private _images?: PerformanceMaxImages;

  get isImagesValid(): boolean {
    return isImagesValid(this.uploadedImageUrls);
  }

  form = this.fb.group(
    {
      longHeadlines: this.fb.array(
        [
          new FormControl(this.adCreationModel.ad.assets?.longHeadlines[0], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.longHeadlines[1], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.longHeadlines[2], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.longHeadlines[3], [Validators.pattern(HEADLINES)]),
          new FormControl(this.adCreationModel.ad.assets?.longHeadlines[4], [Validators.pattern(HEADLINES)])
        ],
        [AppValidations.uniqueValidator()]
      ),
      shortHeadlines: this.fb.array(
        [
          new FormControl(this.adCreationModel.ad.assets?.shortHeadlines[0], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.shortHeadlines[1], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.shortHeadlines[2], [
            Validators.required,
            Validators.pattern(HEADLINES)
          ]),
          new FormControl(this.adCreationModel.ad.assets?.shortHeadlines[3], [Validators.pattern(HEADLINES)]),
          new FormControl(this.adCreationModel.ad.assets?.shortHeadlines[4], [Validators.pattern(HEADLINES)])
        ],
        [AppValidations.uniqueValidator()]
      ),
      shortDescription: new FormControl(this.adCreationModel.ad.assets?.shortDescription, [Validators.required]),
      descriptions: this.fb.array(
        [
          new FormControl(this.adCreationModel.ad.assets?.descriptions[0], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[1], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[2], [Validators.required]),
          new FormControl(this.adCreationModel.ad.assets?.descriptions[3])
        ],
        [AppValidations.uniqueValidator()]
      ),
      businessName: new FormControl(this.adCreationModel.ad.assets?.businessName, [Validators.required]),
      url: new FormControl(this.adCreationModel.campaign.url, [Validators.required, Validators.pattern(URL_REGEX)]),
      callToAction: new FormControl(this.adCreationModel.campaign.callToAction, [Validators.required])
    },
    { validators: [createRequiredValidator(() => this.isImagesValid)] }
  );

  constructor(
    private googleStateService: GoogleStateService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private googleValidationService: GoogleValidationService,
    private fb: FormBuilder,
    private canvaService: CanvaService,
    private googleFormService: GoogleFormService,
    public googleAdPreviewService: GoogleAdPreviewService
  ) {}

  ngOnInit() {
    this.canvaService.setupSdk();

    this.googleFormService.registerForm(this.form);

    this.listenModel();

    this.googleValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((x) => {
      this.adCreationModel.ad.assets.businessName = x.businessName!;
      this.adCreationModel.ad.assets.shortDescription = x.shortDescription!;
      this.adCreationModel.ad.assets.longHeadlines = Google.Utils.replaceExclamationMark(x.longHeadlines);
      this.adCreationModel.ad.assets.shortHeadlines = Google.Utils.replaceExclamationMark(x.shortHeadlines);
      this.adCreationModel.ad.assets.descriptions = Google.Utils.replaceExclamationMark(x.descriptions);

      this.adCreationModel.campaign.url = x.url!;
      this.adCreationModel.campaign.callToAction = x.callToAction!;
      this.googleAdPreviewService.performanceMax.headlines = this.adCreationModel.ad.assets.shortHeadlines || [];
    });
    const urlControl = this.form.get('url');
    urlControl?.valueChanges.pipe(filter(() => urlControl?.invalid)).subscribe((url) => {
      if (URL_REGEX_WITHOUT_PROTOCOL.test(url || '')) {
        urlControl?.patchValue('https://' + url, { emitEvent: true });
      }
    });
  }

  get longHeadlinesArray() {
    return this.form.controls['longHeadlines'] as FormArray;
  }

  get shortHeadlinesArray() {
    return this.form.controls['shortHeadlines'] as FormArray;
  }

  get descriptionsArray() {
    return this.form.controls.descriptions;
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesGoogleComponent, { backdrop: 'static' });

    if (this._images) modalRef.componentInstance.uploadedImages = this._images;

    modalRef.closed.subscribe((result: { images: PerformanceMaxImages }) => {
      if (result?.images) {
        this.updateImages(result.images);
        this.cdr.detectChanges();
      }
    });
  }

  private mapUploadedImageUrls(images: PerformanceMaxImages): FileUploadGroup[] {
    return [
      {
        label: '1:1 Square Images',
        imageUrls: images.squareMarketing?.map((o) => o.url) || [],
        maxImageCount: 5,
        groupName: 'squareMarketing',
        aspectRatio: 1,
        required: true,
        minRequired: 1
      },
      {
        label: '4:5 Vertical Images',
        imageUrls: images.portraitMarketing?.map((o) => o.url) || [],
        maxImageCount: 5,
        groupName: 'portraitMarketing',
        aspectRatio: 0.8,
        required: true,
        minRequired: 1
      },
      {
        label: '1.91:1 Landscape Images',
        imageUrls: images.landscapeMarketing?.map((o) => o.url) || [],
        maxImageCount: 5,
        groupName: 'landscapeMarketing',
        aspectRatio: 1.91,
        required: true,
        minRequired: 1
      }
    ];
  }

  updateImages(images: PerformanceMaxImages) {
    this._images = images;

    this.uploadedImageUrls = this.mapUploadedImageUrls(images);
    this.googleAdPreviewService.performanceMax.logoUrl = images?.logo?.[0]?.url;

    this.adCreationModel.ad.assets!.images.landscapeLogoIds = images.landscapeLogo || [];
    this.adCreationModel.ad.assets!.images.landscapeMarketingIds = images.landscapeMarketing || [];
    this.adCreationModel.ad.assets!.images.logoIds = images.logo || [];
    this.adCreationModel.ad.assets!.images.portraitMarketingIds = images.portraitMarketing || [];
    this.adCreationModel.ad.assets!.images.squareMarketingIds = images.squareMarketing || [];
    this.form.updateValueAndValidity();

    this.googleStateService.saveDraft$();
  }

  openSitelinkExtensionsModal() {
    const modalRef = this.modalService.open(SitelinkExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openExtensionsYoutubeModalComponent() {
    const modalRef = this.modalService.open(ExtensionsYoutubeModalComponent, { backdrop: 'static' });

    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openCalloutExtensionsModalComponent() {
    const modalRef = this.modalService.open(CalloutExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  openCallExtensionsModalComponent() {
    const modalRef = this.modalService.open(CallExtensionsModalComponent, { backdrop: 'static' });
    modalRef.result.then(() => {
      this.cdr.detectChanges();
    });
  }

  listenModel() {
    this.googleStateService.adCreationModel$
      .pipe(
        filter((model) => model.selectedTypes.ad === Google.AdType.PerformanceMax),
        untilDestroyed(this)
      )
      .subscribe((o) => {
        this.adCreationModel = o;

        this.form.patchValue({
          businessName: o.ad.assets.businessName,
          descriptions: o.ad.assets.descriptions,
          longHeadlines: o.ad.assets.longHeadlines,
          shortDescription: o.ad.assets.shortDescription,
          shortHeadlines: o.ad.assets.shortHeadlines,
          callToAction: o.campaign.callToAction,
          url: o.campaign.url
        });
        const { landscapeLogoIds, logoIds, landscapeMarketingIds, portraitMarketingIds, squareMarketingIds } =
          o.ad.assets.images;
        this._images = {
          landscapeLogo: landscapeLogoIds,
          logo: logoIds,
          landscapeMarketing: landscapeMarketingIds,
          portraitMarketing: portraitMarketingIds,
          squareMarketing: squareMarketingIds
        };
        this.googleAdPreviewService.performanceMax.logoUrl = logoIds?.[0]?.url;
        this.googleAdPreviewService.performanceMax.headlines = o.ad.assets.shortHeadlines;

        this.uploadedImageUrls = this.mapUploadedImageUrls(this._images);
        this.form.updateValueAndValidity();
      });
  }

  removeFile(event: FileUploadAreaEvent) {
    const group = this._images![event.group!]!.filter((_, i) => i !== event.index);
    this.updateImages({
      ...this._images!,
      [event.group!]: group
    });
  }
}
