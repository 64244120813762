import { Component, Input } from '@angular/core';
import { Platforms } from '@core/models';
import { TiktokStateService } from '@pages/create-ad/state';
import { TiktokAdPreviewService } from '../../ad-preview.service';
import { AdPreviewTikTokPlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-dynamic-creative-ads-preview--tiktok',
  templateUrl: './dynamic-creative-ads-preview.component.html'
})
export class DynamicCreativeAdsPreviewTiktokComponent {
  protected adCreationModel = this.tiktokStateService.adCreationModel;

  Platforms = Platforms;

  platforms = [AdPreviewTikTokPlatform];

  @Input() title =
    ' Unleash the full potential of your brand with ADYOUNEED, the leading web application designed to revolutionize your social media advertising game on Tiktok. 💪✨🌟';

  constructor(private tiktokStateService: TiktokStateService, public adPreviewService: TiktokAdPreviewService) {}
}
