import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { keyword: string };
type Output = {
  locales: Facebook.KeyNameModel[];
};

export const getLocales = makeQuery<Output, Input>(`
  query GetLocales($keyword: String!) {
    facebook {
      locales(keyword: $keyword) {
        key
        name
      }
    }
  }
`);
