import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IFunnelDashboardRequest;
type Output = Google.Dashboard.IFunnelDashboardResponse;

export const funnelDashboardGoogle = makeMutation<Output, Input>(`
query GetFunnelDashboard(
  $startDate: SafeInt
  $endDate: SafeInt
  $datePreset: DatePreset
) {
  google {
    funnelDashboard(
      datePreset: $datePreset
      startDate: $startDate
      endDate: $endDate
    ) {
      clicks
      impressions
      default
      pageView
      purchase
      signup
      download
      addToCard
      beginCheckout
      subscribePaid
      phoneCallLead
      importedLead
      submitLeadForm
      bookAppointment
      requestQuote
      getDirections
      outboundClick
      contact
      engagement
      storeVisit
      storeSale
      qualifiedLead
      convertedLead
    }
  }
}

`);
