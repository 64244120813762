import { PaymentSourceFromPaymentIntentInput } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  intent: PaymentSourceFromPaymentIntentInput;
  makePrimary: boolean;
};
type Output = boolean;

export const createPaymentSourceFromPaymentIntent = makeMutation<Output, Input>(`
  mutation CreatePaymentSourceFromPaymentIntent($intent: PaymentIntentInput!, $makePrimary: Boolean) {
    createPaymentSourceFromPaymentIntent(intent: $intent, makePrimary: $makePrimary)
  }
`);
