import { EqSet } from '@core/utils/eq-set';
import { isEqual } from 'lodash';

export function updateItemInArrayByIndex<T>(array: T[], index: number, item: T | ((item: T) => T)): T[] {
  return array.map((v, i) => (i === index ? (isFunction(item) ? item(v) : item) : v));
}

export function removeItem<T>(array: T[], value: T) {
  const eqSet = new EqSet<T>(
    {
      equals: isEqual
    },
    array
  );

  eqSet.delete(value);
  return eqSet.toArray();
}

export function removeItemByIndex<T>(array: T[], index: number) {
  return array.filter((_, i) => i !== index);
}

export function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

export function addOrReplace<T extends object>(array: T[], item: T, properties: Array<keyof T>): T[] {
  const eqSet = EqSet.forProps(properties[0] as string, ...((properties.slice(1) || []) as string[])) as EqSet<T>;

  eqSet.union(array);

  if (eqSet.has(item)) {
    return eqSet.replace(item).toArray();
  }
  return eqSet.add(item).toArray();
}

export function deriveTuple<F, S>(fn: (first: F) => S) {
  return function _deriveTuple(first: F): [F, S] {
    return [first, fn(first)];
  };
}
