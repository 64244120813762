import { environment } from '@environment';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/lib/modules';
import { Bing, BingService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { BingStateService } from '@pages/create-ad/state';
import { IDetailedTargetingOverlayItemActive } from '@pages/create-ad/components/shared';

@Component({
  selector: 'aayn-detailed-targeting--bing',
  templateUrl: 'detailed-targeting.component.html'
})
export class DetailedTargetingBingComponent implements OnInit {
  @Input() form?: FormGroup;

  @ViewChild(Overlay) overlay!: Overlay;

  protected activeTab:
    | Bing.BingAudienceType.RemarketingList
    | Bing.BingAudienceType.InMarket
    | Bing.BingAudienceType.SimilarRemarketingList
    | Bing.BingProfileType.JobFunction
    | Bing.BingProfileType.Industry = Bing.BingAudienceType.InMarket;

  protected detailedTargetingSearchControl = new FormControl('', [Validators.minLength(1)]);

  protected RemarketingList: Bing.AudienceCampaign.BingDetailTargetingCriterion[] = [];
  protected InMarket: Bing.AudienceCampaign.BingDetailTargetingCriterion[] = [];
  protected SimilarRemarketingList: Bing.AudienceCampaign.BingDetailTargetingCriterion[] = [];
  protected Industry: Bing.AudienceCampaign.BingDetailTargetingCriterion[] = [];
  protected JobFunction: Bing.AudienceCampaign.BingDetailTargetingCriterion[] = [];

  @Select(LoaderState.getAny(['AudienceCriteriaSearch', 'ProfileDataSearch'])) loader$?: Observable<boolean>;

  protected adCreationModel = this.bingStateService.adCreationModel;

  constructor(
    private bingService: BingService,
    private cdr: ChangeDetectorRef,
    private bingStateService: BingStateService
  ) {}

  get _inclusions() {
    return [
      ...this.adCreationModel.campaign.criteria.target.audienceCriterionIds,
      ...this.adCreationModel.campaign.criteria.target.profileDataCriteria
    ];
  }

  get _exclusions() {
    return [
      ...this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds,
      ...this.adCreationModel.campaign.criteria.exclude.profileDataCriteria
    ];
  }

  get activeInclusions() {
    switch (this.activeTab) {
      case Bing.BingProfileType.Industry:
      case Bing.BingProfileType.JobFunction:
        return {
          model: this.adCreationModel.campaign.criteria.target.profileDataCriteria,
          control: this.form?.controls.targetProfileDataCriteria
        };
      case Bing.BingAudienceType.InMarket:
      case Bing.BingAudienceType.RemarketingList:
      case Bing.BingAudienceType.SimilarRemarketingList:
        return {
          model: this.adCreationModel.campaign.criteria.target.audienceCriterionIds,
          control: this.form?.controls.targetAudienceCriterionIds
        };
    }
  }

  get activeExclusions() {
    switch (this.activeTab) {
      case Bing.BingProfileType.Industry:
      case Bing.BingProfileType.JobFunction:
        return {
          model: this.adCreationModel.campaign.criteria.exclude.profileDataCriteria,
          control: this.form?.controls.excludeProfileDataCriteria
        };
      case Bing.BingAudienceType.InMarket:
      case Bing.BingAudienceType.RemarketingList:
      case Bing.BingAudienceType.SimilarRemarketingList:
        return {
          model: this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds,
          control: this.form?.controls.excludedAudienceCriterionIds
        };
    }
  }

  ngOnInit() {
    this.bingStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.detailedTargetingSearchControl.valueChanges
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe((term) => {
        if (this.detailedTargetingSearchControl.invalid && !term?.length) return;

        if (
          [
            Bing.BingAudienceType.RemarketingList,
            Bing.BingAudienceType.SimilarRemarketingList,
            Bing.BingAudienceType.InMarket
          ].includes(this.activeTab as Bing.BingAudienceType)
        ) {
          this.bingService
            .audienceCriteriaSearchBing$({
              searchKey: term,
              type: this.activeTab,
              campaignType: Bing.BingSupportedCampaignType.Audience,
              limit: 10
            } as Bing.AudienceCampaign.AudienceCriteriaSearchRequest)
            .subscribe((result) => {
              try {
                result?.forEach((e) => (e.type = this.activeTab));
                this[this.activeTab as Bing.BingAudienceType] = result || [];
                if (!this.overlay?.render) this.overlay.show(null);

                this.cdr.detectChanges();
              } catch (error) {
                !environment.production && console.log(`[CreateAd.Bing.DetailTargeting]: `, error);
              }
            });
        } else if (
          [Bing.BingProfileType.JobFunction, Bing.BingProfileType.Industry].includes(
            this.activeTab as Bing.BingProfileType
          )
        ) {
          this.bingService
            .profileDataSearchBing$({
              searchKey: term,
              type: this.activeTab,
              limit: 10
            } as Bing.AudienceCampaign.ProfileDataSearchRequest)
            .subscribe((result) => {
              try {
                result?.forEach((e) => {
                  e.type = this.activeTab;
                });
                this[this.activeTab as Bing.BingProfileType] = result || [];
                if (!this.overlay?.render) this.overlay.show(null);

                this.cdr.detectChanges();
              } catch (error) {
                !environment.production && console.log(`[CreateAd.Bing.DetailTargeting]: `, error);
              }
            });
        }
      });
  }

  protected onIncludeClick(detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion) {
    this.activeInclusions.control?.patchValue([...this.activeInclusions.model, detailedTargeting]);
  }

  protected onExcludeClick(detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion) {
    this.activeExclusions.control?.patchValue([...this.activeExclusions.model, detailedTargeting]);
  }

  onIncludeRemove(detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion) {
    this.activeInclusions.control?.patchValue(this.activeInclusions.model.filter((o) => o.id !== detailedTargeting.id));
  }

  onExcludeRemove(detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion) {
    this.activeExclusions.control?.patchValue(this.activeExclusions.model.filter((o) => o.id !== detailedTargeting.id));
  }

  protected onCloseClickGroup(type: 'inclusions' | 'exclusions') {
    if (type === 'exclusions') {
      this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds = [];
      this.adCreationModel.campaign.criteria.exclude.profileDataCriteria = [];

      this.form!.patchValue({
        excludedAudienceCriterionIds: [],
        excludeProfileDataCriteria: []
      });
    } else if (type === 'inclusions') {
      this.adCreationModel.campaign.criteria.target.audienceCriterionIds = [];

      this.adCreationModel.campaign.criteria.target.profileDataCriteria = [];

      this.form!.patchValue({
        targetAudienceCriterionIds: [],
        targetProfileDataCriteria: []
      });
    }
  }

  protected onCloseClick(
    detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion,
    type: 'inclusions' | 'exclusions'
  ) {
    if (
      detailedTargeting.type === Bing.BingProfileType.Industry ||
      detailedTargeting.type == Bing.BingProfileType.JobFunction
    ) {
      if (type === 'exclusions') {
        const index = this.adCreationModel.campaign.criteria.exclude.profileDataCriteria.findIndex(
          (x) => x.id === detailedTargeting.id
        );
        this.adCreationModel.campaign.criteria.exclude.profileDataCriteria.splice(index, 1);
        this._mapDetailedTargetings();
      } else if (type === 'inclusions') {
        const index = this.adCreationModel.campaign.criteria.target.profileDataCriteria.findIndex(
          (x) => x.id === detailedTargeting.id
        );
        this.adCreationModel.campaign.criteria.target.profileDataCriteria.splice(index, 1);
        this._mapDetailedTargetings();
      }
    } else if (
      detailedTargeting.type == Bing.BingAudienceType.InMarket ||
      detailedTargeting.type == Bing.BingAudienceType.RemarketingList ||
      detailedTargeting.type == Bing.BingAudienceType.SimilarRemarketingList
    ) {
      if (type === 'exclusions') {
        const index = this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds.findIndex(
          (x) => x.id === detailedTargeting.id
        );
        this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds.splice(index, 1);
        this._mapDetailedTargetings();
      } else if (type === 'inclusions') {
        const index = this.adCreationModel.campaign.criteria.target.audienceCriterionIds.findIndex(
          (x) => x.id === detailedTargeting.id
        );
        this.adCreationModel.campaign.criteria.target.audienceCriterionIds.splice(index, 1);
        this._mapDetailedTargetings();
      }
    }
  }

  private _mapDetailedTargetings() {
    this.form?.patchValue(
      {
        targetAudienceCriterionIds: this.adCreationModel.campaign.criteria.target.audienceCriterionIds,
        targetProfileDataCriteria: this.adCreationModel.campaign.criteria.target.profileDataCriteria,
        excludedAudienceCriterionIds: this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds,
        excludeProfileDataCriteria: this.adCreationModel.campaign.criteria.exclude.profileDataCriteria
      },
      { onlySelf: true }
    );
  }

  protected isActive(detailedTargeting: Bing.AudienceCampaign.BingDetailTargetingCriterion) {
    const hasAddedInclusions =
      this.adCreationModel.campaign.criteria.target.audienceCriterionIds.some((o) => o.id === detailedTargeting.id) ||
      this.adCreationModel.campaign.criteria.target.profileDataCriteria.some((o) => o.id === detailedTargeting.id);

    const hasAddedExclusions =
      this.adCreationModel.campaign.criteria.exclude.audienceCriterionIds.some((o) => o.id === detailedTargeting.id) ||
      this.adCreationModel.campaign.criteria.exclude.profileDataCriteria.some((o) => o.id === detailedTargeting.id);
    return {
      isActive: hasAddedInclusions || hasAddedExclusions,
      type: hasAddedInclusions ? 'includes' : hasAddedExclusions ? 'excludes' : ''
    } as IDetailedTargetingOverlayItemActive;
  }

  changeActiveTab(tab) {
    this.activeTab = tab;
    this.detailedTargetingSearchControl.setValue(' ');
  }
}
