import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minWordValidator(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const words = control.value.split(' ').filter(Boolean);

    if (words.length < min) {
      return { minWord: { requiredLength: min } };
    }

    return null;
  };
}
