<aayn-video-modal [videoId]="videoId" #videoModal>
  <section class="aayn-video-modal--title">Welcome to ClickFraud Protection!</section>
  <section class="aayn-video-modal--description">
    <p>
      Discover ADYOUNEED's ClickFraud feature, designed to safeguard your ad budget from malicious clicks. Enhance your
      advertising experience with peace of mind.
    </p>
    <!-- <p class="aayn-video-modal--hit-line">Choose "Guide Me" or "Skip" to explore on your own.</p> -->
  </section>
  <section class="aayn-video-modal--actions">
    <button ayn-button="primary" (click)="videoModal.closeModal()">Skip</button>
    <!-- <a class="aayn-video-modal--skip" (click)="videoModal.closeModal()">Skip</a> -->
  </section>
</aayn-video-modal>
