<form [formGroup]="form!" *ngIf="form">
  <aayn-ad-form-input label="Facebook" platform="meta">
    <div class="aayn-select-objective--row">
      <ayn-form-field>
        <ayn-select
          formControlName="pixel"
          [placeholder]="'Select a pixel' | translate"
          [(ngModel)]="adCreationModel.campaign.platforms!.facebook!.pixelId"
          class="aayn-create-ad--select-v1"
        >
          <ayn-option *ngFor="let pixel of adPixels | aynOptionFilter | async" [value]="pixel.id">
            {{ pixel.name }}
          </ayn-option>

          <ayn-option [disabled]="true" value="-1" *ngIf="!adPixels.length">
            There is no Facebook pixel in your account.
          </ayn-option>
        </ayn-select>
      </ayn-form-field>

      <ayn-form-field>
        <ayn-select
          formControlName="conversionEvent"
          [placeholder]="'Select a conversion event' | translate"
          (ngModelChange)="onChangedSelectedConversionEvent($event)"
          class="aayn-create-ad--select-v1"
        >
          <ayn-option
            *ngFor="let conversionEvent of conversionEvents | aynOptionFilter | async"
            [value]="conversionEvent"
          >
            {{ conversionEvent.name | aynTitleCase }}
          </ayn-option>
        </ayn-select>
      </ayn-form-field>
    </div>
  </aayn-ad-form-input>
</form>
