import { makeQuery, QueryResult } from '@core/queries';
import { Bing } from '@core/models';

type Output = QueryResult<'campaigns', Bing.Dashboard.SingleCampaign[]>;

export const campaignsBing = makeQuery<Output, { ids: string[] }>(`
query BingCampaign($ids: [String!]!) {
  bing {
    campaigns(ids: $ids){
      dailyBudget
      id
      name
      budgetId
      status
      timezone
      goalIds
      languages
      campaignType
      budgetType
      startDate
      endDate
    }
  }
}`);
