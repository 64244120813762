<aayn-upload-image-modal
  header="Select Images"
  description="Upload your images or select them form your library."
  (saveModal)="saveModal()"
  (closeModal)="closeModal()"
  icon="folder"
>
  <aayn-upload-image-modal--header
    headerLeft="Add up to 10 images or videos"
    headerRight="Choose from your library"
    headerRightIcon="file"
    (headerRightClick)="openLibrary()"
  ></aayn-upload-image-modal--header>
  <div class="upload-ads-images--body">
    <aayn-file-upload-item
      *ngFor="let file of images; let index = index"
      [isPrimary]="index === 0"
      [isError]="index === 0 && !isValid"
      (fileAdded)="onFileAdded($event)"
      (remove)="remove($event)"
      [selectedFileUrl]="uploadedImages[index]?.url"
      (selectedFileUrlChange)="selectedFileChange(index)"
      [progressItem]="file"
      canvaDesignType="InstagramPost"
      [aspectRatios]="aspectRatios"
      [maxImageSize]="{ width: 2048 }"
      [minImageSize]="{ width: 600, height: 315 }"
      [allowVideo]="true"
    ></aayn-file-upload-item>
  </div>
</aayn-upload-image-modal>
