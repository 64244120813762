import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';

//! It is important to keep the import from `@apollo/client/core`
//! otherwise, the apollo will also try to load the React typings which is
//! not possible for angular project.
import type { MutationOptions, QueryOptions, SubscriptionOptions } from '@apollo/client/core';

import type { WithMeta, WithRequestId } from '@core/queries';
import { GQLHttpCancelService } from '@core/services/gql-http-cancel.service';
import { get, uniqueId } from 'lodash';
import { isSentryEnabled } from '@core/utils/sentry.util';

/**
 * Returns a {@link map} pipe that will extract the GraphQL value from the
 * returned response by the action name found in the input.
 *
 * @template T The type of the passed query type.
 * @param {WithMeta<T>} input The input.
 * @return {*} A {@link map} pipe.
 */
function valueSelector<T extends object>(input: WithMeta<T>) {
  const { _actionName, _resultPath } = input;
  return map(<V extends { data?: any }>(value: V): V & { data?: T } => {
    if (typeof value !== 'object') {
      if (!environment.production) {
        throw new Error(`[GraphQLService valueSelector] - Value was not an object`);
      }

      return value;
    }

    if (typeof _actionName !== 'string') {
      if (!environment.production) {
        console.warn(`[GraphQLService valueSelector] - Action name was not set`);
      }

      return value;
    }

    const { data } = value;
    if (typeof data !== 'object') {
      if (!environment.production) {
        console.warn(`[GraphQLService valueSelector] - "data" was not found in returned value`, value);
      }

      return value;
    }

    if (!(_actionName in data)) {
      if (!environment.production) {
        console.warn(`[GraphQLService valueSelector] - Key(${_actionName}) was not found in returned value`, value);
      }

      return value;
    }

    let innerValue;

    if (typeof _resultPath === 'string') {
      innerValue = get(data, _resultPath, undefined);
    } else {
      innerValue = data[_actionName];
    }
    value.data = innerValue;

    return value;
  });
}

@Injectable()
export class GraphQLService {
  constructor(protected readonly apollo: Apollo, private gqlHttpCancelService: GQLHttpCancelService) {}

  post<R, V>(operation: WithMeta<MutationOptions<R, V>>) {
    const operationWithRequestId = this.assignAynVariables(operation);
    const id = operationWithRequestId.variables.__aayn_gql_req_id!;
    return this.apollo
      .mutate(operationWithRequestId as WithMeta<MutationOptions<R, V>>)
      .pipe(valueSelector(operation), this.gqlHttpCancelService.finalize(id));
  }

  get<R, V>(operation: WithMeta<QueryOptions<V, R>>) {
    const operationWithRequestId = this.assignAynVariables(operation);
    const id = operationWithRequestId.variables.__aayn_gql_req_id!;
    return this.apollo
      .query(operationWithRequestId as WithMeta<QueryOptions<V, R>>)
      .pipe(valueSelector(operation), this.gqlHttpCancelService.finalize(id));
  }

  subscription<R, V>(operation: WithMeta<SubscriptionOptions<V, R>>) {
    return this.apollo.subscribe(operation).pipe(valueSelector(operation));
  }

  assignAynVariables<T extends { variables?: any; _resultPath?: string }>(operation: T) {
    const id = uniqueId('aayn_gql_req_id_');
    return {
      ...operation,
      variables: {
        ...operation.variables,
        __aayn_gql_req_id: id,
        ...(isSentryEnabled && { __aayn_result_path: operation._resultPath })
      }
    } as WithRequestId<T>;
  }
}
