<section
  class="ayn-expandable-table"
  [style.--grid-columns]="gridColumns"
  [style.--first-grid-column]="firstGridColumn"
  [style.--last-grid-column]="lastGridColumn"
>
  <div class="ayn-expandable-table--group-content">
    <div class="ayn-expandable-table--columns" *ngIf="rows?.length">
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="column.visible">
          <div
            class="ayn-expandable-table--cell"
            [style.width.px]="column.width"
            [class]="'ayn-expandable-table--cell_align__' + column.align"
            [style]="column.style"
          >
            <ng-container
              *ngTemplateOutlet="column.columnTemplate ? customColumnTemplate: defaultColumnTemplate; context: { column }"
            >
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #defaultColumnTemplate let-column="column">
        <ng-container *ngIf="column.changeable">
          <ng-container *ngTemplateOutlet="changeableTmp; context:{ $implicit: column }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!column.changeable">
          <span>{{ column.displayName }}</span>
        </ng-container>
      </ng-template>

      <ng-template #customColumnTemplate let-column="column">
        <ng-container *ngTemplateOutlet="column.columnTemplate; context:{ column, changeableTmp }"></ng-container>
      </ng-template>

      <ng-template #changeableTmp let-column>
        <ayn-form-field class="ayn-expandable-table--changeable-form-field">
          <ayn-select
            #columnSelect
            [showTick]="true"
            [setValueHtml]="true"
            [showSearch]="false"
            appendTo="body"
            [panelMaxHeight]="246"
            [minWidth]="190"
            [ngModel]="column.key"
            overlayContainerClassName="ayn-expandable-table--columns__overlay"
            (ngModelChange)="changeVisibleColumn(column, $event)"
          >
            <ng-container ayn-select-header>
              <div class="ayn-select--search" (click)=" $event.stopPropagation()">
                <input type="text" [(ngModel)]="searchTerm" [placeholder]="'Search' | aynTranslate | async" autofocus />
                <ayn-icon name="search"></ayn-icon>
              </div>
            </ng-container>
            <ayn-option
              *ngFor="let item of changeableColumns | aynFilterObj: {displayName: searchTerm}"
              [value]="item.key!"
              [disabled]="!!item.visible"
            >
              {{ item.displayName}}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>
      </ng-template>
    </div>

    <ng-container *ngTemplateOutlet="rowsTemplate; context: { $implicit: paginatedRows }"></ng-container>
  </div>

  <div
    class="ayn-expandable-table--pagination"
    *ngIf="rows.length && showPagination && (pagination || cursorPagination)"
  >
    <span class="ayn-expandable-table--pagination__label">List</span>
    <ayn-form-field>
      <ayn-select
        [showTick]="true"
        [minWidth]="105"
        [placeholder]="'5'"
        [showSearch]="false"
        appendTo="body"
        containerClassName="ayn-expandable-table--pagination__select"
        overlayContainerClassName="ayn-expandable-table--pagination__overlay"
        overlayClassName="ayn-expandable-table--pagination__list"
        [panelMaxHeight]="246"
        [(ngModel)]="size"
        (ngModelChange)="pageSizeChange($event)"
      >
        <ayn-option *ngFor="let item of pageSizes" [value]="item.value"> {{ item.label }} </ayn-option>
      </ayn-select>
    </ayn-form-field>

    <div class="ayn-expandable-table--pagination__slider">
      <button
        class="ayn-expandable-table--pagination__slider-left"
        ayn-button
        type="button"
        [disabled]="!hasPrevPage"
        (click)="prevPage()"
      >
        <ayn-icon name="previous"></ayn-icon>
      </button>
      <button
        class="ayn-expandable-table--pagination__slider-right"
        ayn-button
        type="button"
        [disabled]="!hasNextPage"
        (click)="nextPage()"
      >
        <ayn-icon name="next"></ayn-icon>
      </button>
    </div>
  </div>
</section>

<ng-template #rowsTemplate let-rows let-parent="parent">
  <div class="ayn-expandable-table--rows" *ngIf="rows.length">
    <div
      class="ayn-expandable-table--row-wrapper"
      *ngFor="let row of rows; trackBy: trackByFn; index as rowIndex"
      (click)="toggle($event, row)"
      [class.active]="!row.collapsed"
      [ngClass]="'active-'+row.activeType"
      [class.border-opacity]="lastActiveRowIdentifierValue && config.rowIdentifier && !isRowActive(row)"
    >
      <div
        class="ayn-expandable-table--row"
        [class.active]="!row.collapsed"
        [ngClass]="'active-' + row.activeType"
        [class.opacity-50]="lastActiveRowIdentifierValue && config.rowIdentifier && !isRowActive(row)"
        [class]="row.class"
        [style.--child-level]="row.childLevel"
      >
        <ng-container *ngIf="row.template">
          <ng-container *ngTemplateOutlet="row.template; context: { $implicit: row, index: rowIndex }"> </ng-container>
        </ng-container>
        <ng-container *ngIf="!row.template">
          <ng-container *ngFor="let column of columns; index as columnIndex">
            <ng-container *ngIf="column.visible">
              <div
                class="ayn-expandable-table--cell"
                [class]="'ayn-expandable-table--cell_align__' + column.align"
                [style]="column.style"
              >
                <ng-container *ngIf="column.rowTemplate; else defaultTemplate">
                  <ng-container
                    *ngTemplateOutlet="column.rowTemplate; context: { $implicit: row, rowIndex: rowIndex, columnIndex: columnIndex, defaultTemplate }"
                  >
                  </ng-container>
                </ng-container>

                <ng-template #defaultTemplate>
                  <ng-container *ngIf="columnIndex === 0">
                    <span (click)="collapse(row, $event)" [class.ayn-expandable-table--cell__type]="row.collapsed"
                      >{{ row.collapsed ? row[column.key!] : 'Hide' }}</span
                    >
                    <ayn-icon
                      [name]="row.collapsed ? 'chevron-right' : 'chevron-down'"
                      class="ml:1"
                      [class.ayn-expandable-table--cell__type]="row.collapsed"
                    ></ayn-icon>
                  </ng-container>
                  <ng-container *ngIf="columnIndex !== 0">
                    <span>{{ column.valueTransform ? column.valueTransform(row, column) : row[column.key!] }}</span>
                  </ng-container>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ayn-loader="row.loading">
        <div class="ayn-expandable-table--row_children" *ngIf="!row.collapsed">
          <ng-container *ngTemplateOutlet="rowsTemplate; context: { $implicit: row.children || [], parent: row }">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!rows?.length && emptyContent">
    <ng-container *ngTemplateOutlet="emptyContent;  context: {$implicit: parent}"></ng-container>
  </ng-container>
</ng-template>
