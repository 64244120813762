import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/services';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate() {
    if (!this.authService?.user?.user) {
      this.router.navigateByUrl(`/authentication/login${this._getReturnUrl()}`);

      return false;
    }

    if (!environment.production) console.log(`[AuthGuard.canActivate]: Session is active, Session is being checked.`);

    if (this.authService.hasAccessTokenExpired) {
      try {
        await this.authService.refreshToken().toPromise();

        return true;
      } catch {
        this.router.navigateByUrl(`/authentication/login${this._getReturnUrl()}`);

        return false;
      }
    }

    return true;
  }

  private _getReturnUrl() {
    const returnUrl = location.href.replace(location.origin, '');

    if (!returnUrl) return;

    return `?returnUrl=${returnUrl}`;
  }
}
