import { Apollo } from 'apollo-angular';
import { GraphQLService } from '@core/backend/graphql/graphql-service';
import { notification } from '@core/queries/notification.query';
import { BehaviorSubject } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { INotification } from './components/notification-item/notification-item.component';

@Component({
  selector: 'aayn-notifications',
  templateUrl: 'notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  newNotifications$ = new BehaviorSubject<INotification[]>([
    {
      platform: 'facebook',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.'
    }
  ]);

  notifications$ = new BehaviorSubject<INotification[]>([
    {
      platform: 'google',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.',
      icon: 'warning-red'
    },
    {
      platform: 'facebook',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.'
    },
    {
      platform: 'instagram',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.'
    },
    {
      platform: 'instagram',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.'
    },
    {
      platform: 'linkedin',
      date: '08 August 2021 - 9:24',
      text: 'Your ad duplication is successful. You can refresh dashboard to see it.'
    }
  ]);

  constructor(protected graphQlService: GraphQLService, protected apollo: Apollo) {}

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    return this.graphQlService.subscription(notification()).subscribe((it) => {
      console.log('Data received', it);
    });
  }
}
