import { Platforms } from './platforms.model';

export module Draft {
  export interface IGetDraftsRequest {
    platforms?: ReadonlyArray<number>;
    name?: string;
    sort?: IDraftSort;
  }

  export enum SortOption {
    Ascending = 'Ascending',
    Descending = 'Descending'
  }

  export interface IDraftSort {
    name?: SortOption;
    id?: SortOption;
    createdAt?: SortOption;
    updatedAt?: SortOption;
    source?: SortOption;
  }

  export interface IDraftItem {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    content: string;
    source: Platforms;
    adAccountId: string;
  }

  export type IDraftItemForUpdate = Omit<IDraftItem, 'createdAt' | 'updatedAt'>;

  export interface IUpsertAdDraft extends Omit<IDraftItem, 'businessId' | 'createdAt' | 'updatedAt' | 'id'> {
    id?: string;
  }
}
