<section class="aayn-audience--age-range">
  <main class="aayn-audience--age-range-main">
    <span class="aayn-audience--age-range-label">{{ options.floor }}</span>

    <ayn-slider
      [options]="options"
      sliderType="range"
      [minValue]="value"
      [maxValue]="maxValue"
      (sliderRangeValueChange)="ageRangeChanged.emit($event)"
    ></ayn-slider>

    <span class="aayn-audience--age-range-label">{{ options.maxRange }}+</span>
  </main>

  <footer class="aayn-audience--age-range-footer">
    Selected Range

    <b>{{ labelFormatter(value, 0) }} - {{ labelFormatter(maxValue, 1) }}</b>
  </footer>
</section>
