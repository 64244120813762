import { AbstractControl, ValidatorFn } from '@angular/forms';
import { addHttpsPrefix } from './url';
import { URL_REGEX } from '@core/utils/regex';
import { gt, gte } from 'lodash';

export function patchWithHttps(control: AbstractControl, value: string) {
  const url = addHttpsPrefix(value);
  if (URL_REGEX.test(url)) {
    control?.patchValue(url, { emitEvent: true });
  }
}

export type AbstractControlPredicate = (control: AbstractControl) => boolean;

/**
 * Creates custom required validator with given predicate function
 *
 * @param {AbstractControlPredicate} predicate The predicate function
 *
 * @remarks
 * When the predicate result is false, required flag applied
 */
export function createRequiredValidator(predicate: AbstractControlPredicate): ValidatorFn {
  return (control: AbstractControl) => {
    return predicate(control) ? null : { required: true };
  };
}

/**
 * A validator function for control empty object
 */
export const requiredObjectValueValidator: ValidatorFn = createRequiredValidator(
  (control) => !!Object.values(control?.value || {}).length
);

/**
 * Wraps validator with predicate. If the predicate result is true validator applied to form
 *
 * @param {AbstractControlPredicate} predicate The predicate function
 */
export function createConditionalValidator(predicate: AbstractControlPredicate) {
  return function _createConditionalValidator(validator: ValidatorFn) {
    return function _validator(control: AbstractControl) {
      return predicate(control) ? validator(control) : null;
    };
  };
}

/**
 * A validator function for control min date
 * @param date The min date
 * @param canEqual The flag for can equal
 *
 */
export function minDateValidator(date: Date | (() => Date), canEqual = true) {
  return function _minDateValidator(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    const minDate = typeof date === 'function' ? date() : date;

    const comparer = canEqual ? gte : gt;

    return comparer(controlDate, minDate) ? null : { minDate: true };
  };
}

/**
 * A validator function for control max date
 * @param date The max date
 * @param canEqual The flag for can equal
 *
 */
export function maxDateValidator(date: Date | (() => Date), canEqual = true) {
  return function _maxDateValidator(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    const maxDate = typeof date === 'function' ? date() : date;

    const comparer = canEqual ? gte : gt;

    return comparer(maxDate, controlDate) ? null : { maxDate: true };
  };
}
