<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  aaynTextAiContainer
  [adPreviewContainer]="adPreviewService.dynamicCreative"
>
  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    (fileRemove)="removeFile($event)"
    [description]="
      'Facebook is recommending this size to be in this pixel range to get the best results possible.' | translate
    "
    [imageUrls]="uploadedImageUrls"
    [showRequiredAlert]="!uploadedImageUrls.length && _form.submitted"
    (imageError)="getSource($event.index!)"
    adPreview="images"
    [standAlone]="true"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Headline(s) for ads" description="Compose the headlines to be used in your ads">
      <aayn-create-ad-input
        *ngFor="let control of bodiesFormArray.controls; index as index"
        [maxCharacterNumber]="100"
        [formControl]="$any(bodiesFormArray.controls[index])"
        [placeholder]="'Tell people what your ad is about'"
        [requiredMessage]="'You must write at least 1 headline.' | translate"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section
      header="Short Headline(s) for ads"
      description="Compose the short headlines to be used in your ads."
    >
      <aayn-create-ad-input
        *ngFor="let control of titlesFormArray.controls; index as index"
        [maxCharacterNumber]="70"
        [formControl]="$any(titlesFormArray.controls[index])"
        [placeholder]="'Write a short headline'"
        [requiredMessage]="'You must write at least 1 short headline.' | translate"
        adPreview="shortHeadline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section
      header="Description for ads"
      description="Compose the short headlines to be used in your ads."
    >
      <aayn-create-ad-input
        [maxCharacterNumber]="70"
        [placeholder]="'Write a description here' | translate"
        formControlName="description"
        [previewActive]="true"
        [requiredMessage]="'You must write at least 1 description.' | translate"
        adPreview="description"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToActionType"
            adPreview="callToAction"
            [standAlone]="true"
            [placeholder]="'Call To Action' | translate"
            [showTick]="true"
            [showSearch]="false"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="url"
            placeholder="https://adyouneed.com"
            formControlName="websiteUrl"
            ayn-input
            [standAlone]="true"
            adPreview="websiteUrl"
            #urlInput
          />
        </ayn-form-field>
      </section>

      <ayn-form-field class="aayn-ad-type--control aayn-ad-type__mt-spacing">
        <input type="text" ayn-input [placeholder]="'Ad Name' | translate" formControlName="name" />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
