import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.Dashboard.IGoogleEditCampaignRequest;
type Output = { editableCampaigns: boolean };

export const editCampaignGoogle = makeMutation<Output, Input>(`
mutation EditCampaignGoogle($editableCampaigns: [GoogleEditableCampaignInputDto!]!) {
  google {
    editCampaign(editableCampaigns: $editableCampaigns)
  }
}
`);
