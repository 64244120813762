import { IDeleteBusinessRequest, IDeleteBusinessResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = IDeleteBusinessRequest;
type Output = IDeleteBusinessResponse;

export const deleteBusinessBrand = makeMutation<Output, Input>(`
mutation DeleteBusinessBrand($id: String!) {
  deleteBusinessBrand(id: $id)
}
`);
