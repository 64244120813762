import { Component, Input } from '@angular/core';
import { Platforms } from '@core/models';
import { LinkedinAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/ad-preview/ad-preview.service';
import { AdPreviewLinkedInPlatform } from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-single-ad-preview--linkedin',
  templateUrl: './single-image-ad-preview.component.html'
})
export class SingleImageAdPreviewLinkedinComponent {
  protected readonly Platforms = Platforms;

  @Input() businessName: string = '';
  @Input() profileImage: string = '';

  platforms = [AdPreviewLinkedInPlatform];

  constructor(public adPreviewService: LinkedinAdPreviewService) {}
}
