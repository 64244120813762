import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getLinkedinAdAccountId, LinkedinService, mapDefined, switchMapDefined } from '@core/index';
import { Linkedin } from '@core/models/platforms/linkedin';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  LinkedinFormService,
  LinkedinStateService,
  LinkedinValidationService
} from '@pages/create-ad/state/platforms/linkedin';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { filter, mergeAll, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--linkedin',
  templateUrl: 'conversion-my-website.component.html'
})
export class ConversionMyWebsiteLinkedinComponent implements OnInit {
  protected adCreationModel = this.linkedinStateService.adCreationModel;

  protected conversionEvents: Linkedin.LinkedInConversion[] = [];
  protected selectedConversionEvent?: Linkedin.LinkedInConversion;

  protected conversionForm = new FormGroup({
    conversionEvent: new FormControl<Linkedin.LinkedInConversion | null>(null, [Validators.required]),
    campaignName: new FormControl(this.adCreationModel?.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private linkedinStateService: LinkedinStateService,
    private linkedinService: LinkedinService,
    private linkedinValidationService: LinkedinValidationService,
    private linkedinFormService: LinkedinFormService,
    private store: Store
  ) {}

  ngOnInit() {
    this.linkedinFormService.registerForm(this.conversionForm);

    this.conversionForm.statusChanges.subscribe((status) => {
      this.linkedinValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.linkedinStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.getConversionEvents();
  }

  onChangedSelectedConversionEvent($event?: Linkedin.LinkedInConversion) {
    if (!$event) return;

    this.adCreationModel.conversionType = $event;
  }

  getConversionEvents() {
    of(getLinkedinAdAccountId(this.store))
      .pipe(
        switchMapDefined((accountId) => this.linkedinService.findConversionsByAccount(accountId)),
        mapDefined((result) => result.edges.map((it) => it.node)),
        tap((it) => (this.conversionEvents = it)),
        mergeAll(),
        filter((it) => it.id === this.adCreationModel.conversionType?.id),
        take(1),
        tap((conversionEvent) => this.conversionForm.patchValue({ conversionEvent }))
      )
      .subscribe();
  }
}
