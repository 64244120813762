import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Google } from '@core/models';
import { URL_REGEX, URL_REGEX_WITHOUT_PROTOCOL } from '@core/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleStateService } from '@pages/create-ad/state';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'aayn-sitelink-extensions-modal',
  templateUrl: './sitelink-extensions-modal.component.html'
})
export class SitelinkExtensionsModalComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(
    public modal: NgbActiveModal,
    private googleStateService: GoogleStateService,
    private cdr: ChangeDetectorRef
  ) {}

  sitelinkForm = new FormGroup({
    sitelinkText: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[0]?.sitelinkText, [
      Validators.required,
      Validators.min(2)
    ]),
    firstDescription: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[0]?.firstDescription),
    secondDescription: new FormControl(
      this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[0]?.secondDescription
    ),
    finalUrl: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[0]?.finalUrl, [
      Validators.required,
      Validators.min(2),
      Validators.pattern(URL_REGEX)
    ]),

    sitelinkText2: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[1]?.sitelinkText, [
      Validators.required,
      Validators.min(2)
    ]),
    firstDescription2: new FormControl(
      this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[1]?.firstDescription
    ),
    secondDescription2: new FormControl(
      this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[1]?.secondDescription
    ),
    finalUrl2: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.sitelinks[1]?.finalUrl, [
      Validators.required,
      Validators.min(2),
      Validators.pattern(URL_REGEX)
    ]),
    name: new FormControl(this.adCreationModel.ad.sitelinkExtension?.create.name, [
      Validators.required,
      Validators.min(2)
    ])
  });

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.sitelinkForm.valid) {
      const {
        sitelinkText,
        firstDescription,
        finalUrl,
        secondDescription,
        name,
        sitelinkText2,
        firstDescription2,
        finalUrl2,
        secondDescription2
      } = this.sitelinkForm.value;
      this.adCreationModel.ad.sitelinkExtension!.create.sitelinks = [
        {
          sitelinkText,
          finalUrl,
          firstDescription,
          secondDescription
        },
        {
          sitelinkText: sitelinkText2,
          finalUrl: finalUrl2,
          firstDescription: firstDescription2,
          secondDescription: secondDescription2
        }
      ] as Google.CreateAd.GooglePMSitelinkDetails[];
      this.adCreationModel.ad.sitelinkExtension!.create!.name = name!;
      this.cdr.detectChanges();
      this.modal.close();
    }
  }
}
