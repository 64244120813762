import { Component, OnInit } from '@angular/core';
import { BingService } from '@core/services';
import { Bing } from '@core/models';
import { Select } from '@ngxs/store';
import { LoaderState } from '@core/state/loader.state';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type BingLibraryImage = { selected: boolean } & Bing.AudienceCampaign.BingAccountImageAssetOutputDto;

@Component({
  selector: 'ayn-library-bing',
  templateUrl: './library.component.html'
})
export class LibraryBingComponent implements OnInit {
  @Select(LoaderState.getAny(['GetAccountImages'])) loader$?: Observable<boolean>;

  images: Array<BingLibraryImage> = [];
  query = '';

  ratio: number = 1;
  maxImages = 3;

  constructor(public modal: NgbActiveModal, private bingService: BingService) {}

  ngOnInit() {
    this.getImages();
  }

  getImages() {
    this.bingService.getAccountImages({ ratio: this.ratio }).subscribe((images) => {
      this.images = images.edges.map((o) => ({ ...o.node, selected: false }));
    });
  }
  protected toggleImage(item: BingLibraryImage) {
    if (item.selected) {
      item.selected = false;
      return;
    }

    if (this.selectedImagesLength! < this.maxImages) {
      item.selected = true;
    }
  }

  protected get selectedImagesLength() {
    return this.images?.filter((o) => o?.selected).length;
  }

  protected continueWithSelected() {
    this.modal.close({
      images: this.images?.filter((o) => o?.selected).map((o) => ({ id: o?.id, url: o?.url }))
    });
  }
}
