import { Injectable } from '@angular/core';

@Injectable()
export class GoogleAdPreviewService {
  performanceMax: Partial<{
    headline: string;
    logoUrl: string;
    description: string;
    callToAction: string;
    url: string;
    shortDescription: string;
    businessName: string;
    imageUrls: string[];
    headlines: string[];
  }> = {
    headline: '',
    description: '',
    businessName: '',
    callToAction: '',
    shortDescription: '',
    url: '',
    logoUrl: '',
    imageUrls: [],
    headlines: []
  };

  displayAds: Partial<{
    longHeadline: string;
    shortHeadline: string;
    description: string;
    businessName: string;
    campaignName: string;
    callToAction: string;
    url: string;
    imageUrls: string[];
    logoUrl: string;
  }> = {
    longHeadline: '',
    shortHeadline: '',
    description: '',
    businessName: '',
    campaignName: '',
    callToAction: '',
    url: '',
    imageUrls: [],
    logoUrl: ''
  };

  searchAds: Partial<{
    headline: string;
    description: string;
    businessName: string;
    url: string;
    headlines: Array<string>;
  }> = {
    headline: '',
    description: '',
    businessName: '',
    url: '',
    headlines: []
  };
}
