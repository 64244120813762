<section
  class="aayn-audience--detailed-targeting"
  [ngClass]="{ 'aayn-audience--detailed-targeting__active': overlay.render }"
>
  <aayn-detailed-targeting-added-item [addedItemType]="'inclusions'" *ngIf="detailedTargetingInclude.length">
    <ayn-badge
      color="primary"
      *ngFor="let includeDetailedTargeting of detailedTargetingInclude; let index = index"
      [text]="includeDetailedTargeting.node.name"
      icon="cross-mini-bold"
      (click)="onCloseClick('inclusions', index)"
    >
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <aayn-detailed-targeting-added-item [addedItemType]="'exclusions'" *ngIf="detailedTargetingExclude.length">
    <ayn-badge
      color="orange"
      *ngFor="let excludeDetailedTargeting of detailedTargetingExclude; let index = index"
      [text]="excludeDetailedTargeting.node.name"
      icon="cross-mini-bold"
      (click)="onCloseClick('exclusions', index)"
    >
    </ayn-badge>
  </aayn-detailed-targeting-added-item>

  <ayn-form-field class="aayn-audience--detailed-targeting--input">
    <input
      type="text"
      ayn-input
      [placeholder]="'Add audience, demographics, interest or behaviours' | translate"
      (click)="overlay.show($event)"
      [formControl]="detailedTargetingSearchControl"
    />
  </ayn-form-field>

  <ayn-overlay
    #overlay
    class="aayn-audience--detailed-targeting--overlay"
    styleClass="aayn-audience--detailed-targeting--overlay-inner"
  >
    <ayn-tab tab-type="ayn-tab--default">
      <ayn-tab-item [tabTitle]="'Company' | translate" (tabClick)="onTabClick('company')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of detailedTargetings"
            [value]="+detailedTargeting.node.urn"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onExcludeClick)="onExcludeClick(detailedTargeting)"
          >
            {{ detailedTargeting.node.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Interests' | translate" (tabClick)="onTabClick('interest')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of detailedTargetings"
            [value]="+detailedTargeting.node.urn"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onExcludeClick)="onExcludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            (onExcludeRemove)="onExcludeRemove(detailedTargeting)"
          >
            {{ detailedTargeting.node.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Job Experience' | translate" (tabClick)="onTabClick('job')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of detailedTargetings"
            [value]="+detailedTargeting.node.urn"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onExcludeClick)="onExcludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            (onExcludeRemove)="onExcludeRemove(detailedTargeting)"
          >
            {{ detailedTargeting.node.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Education' | translate" (tabClick)="onTabClick('education')">
        <aayn-detailed-targeting-overlay-list *ayn-loader="loader$ | async; classname: 'mt-3 mb-3'">
          <aayn-detailed-targeting-overlay-item
            *ngFor="let detailedTargeting of detailedTargetings"
            [value]="+detailedTargeting.node.urn"
            [active]="isActive(detailedTargeting)"
            (onIncludeClick)="onIncludeClick(detailedTargeting)"
            (onExcludeClick)="onExcludeClick(detailedTargeting)"
            (onIncludeRemove)="onIncludeRemove(detailedTargeting)"
            (onExcludeRemove)="onExcludeRemove(detailedTargeting)"
          >
            {{ detailedTargeting.node.name }}
          </aayn-detailed-targeting-overlay-item>
        </aayn-detailed-targeting-overlay-list>
      </ayn-tab-item>

      <ayn-tab-item [tabTitle]="'Custom' | translate" *ngIf="false"></ayn-tab-item>
    </ayn-tab>
  </ayn-overlay>
</section>
