import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalPageComponent } from '@ayn-ui/public-api';
import {
  AdAccountState,
  GetPlatformAdAccounts,
  GoogleAdAccount,
  GoogleService,
  IAdAccountType,
  Platforms
} from '@core/index';
import { IPlatformContext } from '@core/models';
import { PlatformContextState } from '@core/state/platform-context.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { CreateAccountGoogleComponent } from '../create-account/create-account.component';
import { take } from 'rxjs/operators';
import { ConnectAdAccountService } from '@shared/components/account-select-modal/services';

@Component({
  selector: 'aayn-ad-account-select--google',
  templateUrl: './ad-account-select.component.html',
  providers: [ConnectAdAccountService]
})
export class AdAccountSelectGoogleComponent implements OnInit {
  platforms = Platforms;

  protected activeStep = 0;

  protected query = '';

  protected adAccounts$ = this.store.select(AdAccountState.adAccounts<Platforms.Google>);

  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .Google;

  private selectedAdAccount?: IAdAccountType<GoogleAdAccount>;

  protected isCreationPossible = false;

  constructor(
    private googleService: GoogleService,
    private modalService: NgbModal,
    private store: Store,
    private connectAdAccountService: ConnectAdAccountService
  ) {}

  @ViewChild(ModalPageComponent) modal!: ModalPageComponent;

  ngOnInit() {
    this.fetchIsCreationPossible();
  }

  fetchIsCreationPossible() {
    this.googleService
      .isCreationPossible$({ contextId: this.platformContext.id })
      .pipe(take(1))
      .subscribe((isPossible: boolean) => {
        this.isCreationPossible = isPossible;
      });
  }

  get completeDisabled() {
    return !this.selectedAdAccount?.selected;
  }

  complete() {
    if (this.completeDisabled) return;
    this.connectAdAccountService.connectAdAccount(
      Platforms.Google,
      {
        adAccountId: String(this.selectedAdAccount!.adAccountId),
        loginId: String(this.selectedAdAccount!.loginCustomerId)
      },
      this.modal.modalRef!
    );
  }

  showCreateNewAccountAccountModal() {
    const modalRef = this.modalService.open(CreateAccountGoogleComponent);

    modalRef.componentInstance.platformContext = this.platformContext!;

    modalRef.closed.subscribe(() => {
      this.store.dispatch(new GetPlatformAdAccounts({ platforms: Platforms.Google }));
    });
  }

  selectAdAccount(account: IAdAccountType<GoogleAdAccount>) {
    this.selectedAdAccount = account;
  }
}
