<div class="aayn-ad-preview aayn-ad-preview-tiktok-single">
  <ayn-carousel [itemsPerSlide]="1">
    <ng-template ayn-carousel-item *ngIf="!imageUrls.length">
      <div class="aayn-ad-preview-placeholder">
        <ayn-icon name="null-image-icon"></ayn-icon>
      </div>
    </ng-template>
    <ng-template *ngFor="let imageUrl of imageUrls" ayn-carousel-item>
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: imageUrl }"></ng-container>
    </ng-template>
  </ayn-carousel>
  <div class="aayn-ad-preview-tiktok-single_content">
    <div class="d-flex justify-content-between">
      <div class="aayn-ad-preview-tiktok-single_content__left">
        <div class="aayn-ad-preview-tiktok-single_content__business_name">
          <span>@{{ businessName }}</span>
        </div>
        <div class="aayn-ad-preview-tiktok-single_content__headline">
          <span>{{ headline || (headlinePlaceholder | translate) }}</span>
        </div>
        <div class="aayn-ad-preview-tiktok-single_content__cta">
          <span>{{ cta || (ctaPlaceholder | translate) }}</span>
        </div>
      </div>
      <div class="aayn-ad-preview-tiktok-single_content__right">
        <ayn-icon srcPrefix="/assets/images/svgs/create-ads/" name="tiktok-buttons"></ayn-icon>
        <div class="aayn-ad-preview-tiktok-single_content__logo">
          <img [src]="businessLogo | aynSafe: 'url'" [alt]="'Ad Preview' | translate" (error)="onImgError($event)" />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #imageTemplate let-image>
  <div class="aayn-ad-preview-tiktok-single_image">
    <img [src]="image | aynSafe: 'url'" [alt]="'Ad Preview' | translate" (error)="onImgError($event)" />
  </div>
</ng-template>
