import { Component, Input, OnInit } from '@angular/core';
import { Google } from '@core/models';
import { GoogleStateService } from '@pages/create-ad/state';
import { ICreateAdSelectItem } from '../../../../models/ad-create.model';

export const OBJECTIVE_LIST__GOOGLE: ICreateAdSelectItem<Google.CampaignObjective>[] = [
  {
    id: 1,
    title: 'Conversions on my website',
    description: 'To receive as many conversions on your website as possible.',
    icon: 'conversions',
    empty: false,
    active: true,
    type: Google.CampaignObjective.ConversionsOnMyWebsite
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false
  }
];

@Component({
  selector: 'aayn-select-objective--google',
  templateUrl: 'select-objective-content.component.html'
})
export class SelectObjectiveGoogleComponent implements OnInit {
  selectedObjective: Google.CampaignObjective = Google.CampaignObjective.ConversionsOnMyWebsite;

  ObjectiveTypes = Google.CampaignObjective;

  objectives = OBJECTIVE_LIST__GOOGLE;

  protected adCreationModel = this.googleStateService.adCreationModel;

  constructor(private googleStateService: GoogleStateService) {}

  ngOnInit() {}

  objectiveChangeStatus(item: ICreateAdSelectItem<Google.CampaignObjective>) {
    this.objectives.forEach((x) => {
      if (x.id === item.id) {
        x.active = true;
        this.selectedObjective = item.type!;
      } else {
        x.active = false;
      }
    });
  }
}
