import { Base, Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Base.IPaginationRequest;

type Output = {
  pagePosts: {
    edges: {
      node: Facebook.IPagePost;
    }[];
  };
};

export const getPagePosts = makeQuery<Output, Input>(`
  query GetPagePosts(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    facebook {
      pagePosts(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        edges {
          node {
            id
            message
            imageUrl
            shares
            likes
            comments
            createdAt
          }
        }
      }
    }
  }
`);
