<section class="aayn-create-ad--item--container">
  <section class="aayn-create-ad--item--row">
    <aayn-select-ad-item
      *ngFor="let adType of adTypes"
      [title]="adType.title"
      [active]="adType.active"
      [empty]="adType.empty"
      [description]="adType.description"
      [adsType]="adType.icon"
      (btnClick)="adTypeChangeStatus(adType)"
    >
    </aayn-select-ad-item>
  </section>
</section>

<div class="aayn-ad-type-container">
  <div class="aayn-ad-type-container--content mt:5">
    <div [ngSwitch]="selectedAdType">
      <div *ngSwitchCase="AdTypes.PerformanceMax">
        <aayn-performance-max-ads--bing></aayn-performance-max-ads--bing>
      </div>
    </div>

    <aayn-ad-preview--bing class="aayn-ad-type-container--content--preview"></aayn-ad-preview--bing>
  </div>
</div>
