import { Component, Input } from '@angular/core';

@Component({
  selector: 'aayn-ad-form-input',
  templateUrl: './ad-form-input.component.html'
})
export class AdFormInputComponent {
  @Input() label?: string;
  @Input() platform?: string;
}
