import { format } from 'date-fns';

import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatePreset, DatePresetValue, getDiffenceTwoDates, getMonth } from '@ayn-ui/lib/helpers';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerI18nDefault,
  NgbDatepickerModule,
  NgbDatepickerNavigateEvent,
  NgbDateStruct,
  NgbModule,
  NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap';

import { AynUITranslateModule } from '../../translate/translate.module';
import { ButtonModule } from '../button';
import { DatePicker } from '../datepicker';
import { IconModule } from '../icon';
import { Overlay, OverlayModule } from '../overlay';

export interface ITimeSet {
  label: string;
  value: DatePresetValue;
  isOneDate?: boolean;
  preset?: DatePreset;
}

export interface DatePickerExtendValue {
  value: [Date, Date];
  preset?: DatePreset;
}

export const TIME_SETS: ITimeSet[] = [
  { preset: DatePreset.Today, label: 'Today', isOneDate: true },
  { preset: DatePreset.Yesterday, label: 'Yesterday', isOneDate: true },
  { preset: DatePreset.LastSevenDays, label: 'Last 7 days' },
  { preset: DatePreset.LastFourteenDays, label: 'Last 14 days' },
  { preset: DatePreset.LastThirtyDays, label: 'Last 30 days' },
  { preset: DatePreset.ThisWeekMonToday, label: 'This Week' },
  { preset: DatePreset.ThisMonth, label: 'This Month' },
  { preset: DatePreset.LastMonth, label: 'Last Month' },
  { preset: DatePreset.LastNinetyDays, label: 'Last 90 days' },
  { preset: DatePreset.LastOneHundredEightyDays, label: 'Last 180 Days' },
  { preset: DatePreset.ThisYear, label: 'This Year' },
  { preset: DatePreset.LastTwelveMonths, label: 'Last 12 Months' }
].map((item) => ({
  ...item,
  value: DatePreset.toDate(item.preset)
}));

@Component({
  selector: 'ayn-datepicker-extend',
  templateUrl: 'datepicker-extend.html',
  // `NgbDatepickerI18nDefault` provide reason is, re-construct `NgbDatepickerI18nDefault` with the changed locale.
  providers: [{ provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nDefault }]
})
export class DatepickerExtendComponent extends DatePicker implements OnInit, OnChanges {
  @Input() value?: [Date, Date];

  @Output() valueChange = new EventEmitter<DatePickerExtendValue>();

  @ViewChild(Overlay) overlay!: Overlay;

  @Input() timeSets: ITimeSet[] = TIME_SETS;

  @Input() selectedPreset?: DatePreset | null;

  @ViewChild('datePickerBtn') datePickerBtn!: ElementRef<HTMLButtonElement>;
  @ViewChildren('calendarContainer') calendarContainer!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('timeSetContainer') timeSetContainer!: QueryList<ElementRef<HTMLDivElement>>;

  hoveredDate: NgbDate | null = null;
  fromDate?: NgbDateStruct;
  toDate: NgbDateStruct | null = null;

  fromTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  toTime: NgbTimeStruct = { hour: 23, minute: 59, second: 59 };
  isCustomDate = false;
  isCustomWidth = false;
  activeDate?: {
    year: number;
    month: number;
  };

  get selectedTimeSet() {
    return this.timeSets.find((item) => item.preset === this.selectedPreset);
  }

  get timeSetAreaWidth() {
    const computed = this.getComputedWidth(this.datePickerBtn.nativeElement, ['padding', 'margin', 'border']);
    const parentComputed = this.getComputedWidth(this.timeSetContainer.first.nativeElement.parentElement!, ['width']);
    return computed - parentComputed;
  }

  get overlayWidth() {
    if (this.isCustomWidth) {
      const spacer = 32;
      const computed = this.getComputedWidth(this.datePickerBtn.nativeElement, ['padding', 'margin', 'border']);
      const calendarComputed = this.getComputedWidth(this.calendarContainer.first.nativeElement, [
        'padding',
        'margin',
        'border'
      ]);
      return `width: ${computed + calendarComputed + spacer}px`;
    }
    return '';
  }

  get label() {
    return this.selectedTimeSet?.label || this.formattedValue || this.placeholder;
  }

  get formattedValue() {
    if (!this.fromDate || !this.toDate) return;
    const firstDate = `${this.fromDate.day} ${getMonth(this.fromDate.month - 1).substring(0, 3)} ${this.fromDate.year}`;
    const lastDate = `${this.toDate?.day} ${getMonth(this.toDate!.month - 1).substring(0, 3)} ${this.toDate?.year}`;

    if (firstDate === lastDate) return firstDate;

    return `${firstDate} - ${lastDate}`;
  }

  constructor(private _calendar: NgbCalendar, private dateParser: NgbDateParserFormatter) {
    super(_calendar);
  }

  markDisabled = (date: NgbDate) => this.isAfter(date);

  ngOnInit() {
    this.activeDate = {
      year: this._calendar.getToday().year,
      month: this._calendar.getToday().month
    };
    if (this.selectedTimeSet) {
      this.selectTimeSet(this.selectedTimeSet);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value = changes.value;

    if (value?.currentValue) {
      const [startDate, endDate] = value.currentValue;

      const { diffDays } = getDiffenceTwoDates(startDate, endDate);

      this.fromDate = this._calendar.getPrev(this._calendar.getToday(), 'd', diffDays);
      this.toDate = this._calendar.getPrev(this._calendar.getToday(), 'd', diffDays);
      this.fromTime = { hour: 0, minute: 0, second: 0 };
      this.toTime = { hour: 23, minute: 59, second: 59 };
    }
  }

  selectTimeSet({ preset, value: { fromDate, toDate } }: ITimeSet) {
    this.selectedPreset = preset;
    this.isCustomDate = false;

    const parsedFromDate = this.dateParser.parse(format(fromDate, 'yyyy-MM-dd'));
    const parsedToDate = this.dateParser.parse(format(toDate, 'yyyy-MM-dd'));
    if (parsedFromDate) {
      this.fromDate = parsedFromDate;
      this.fromTime = { hour: fromDate.getHours(), minute: fromDate.getMinutes(), second: fromDate.getSeconds() };
    }
    if (parsedToDate) {
      this.toDate = parsedToDate;
      this.toTime = { hour: toDate.getHours(), minute: toDate.getMinutes(), second: toDate.getSeconds() };
    }

    this.updateValue(this.selectedTimeSet!.preset);
  }

  onDateSelection(date: NgbDate) {
    if (this.isAfter(date)) {
      return;
    }
    this.isCustomDate = true;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.fromTime = { hour: 0, minute: 0, second: 0 };
    this.toTime = { hour: 23, minute: 59, second: 59 };
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isAfter(date: NgbDate) {
    return date.after(this._calendar.getToday());
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isInThisMonth(date: NgbDate) {
    return date.year === this.activeDate?.year && date.month == this.activeDate.month;
  }

  updateValue(datePreset?: DatePreset) {
    if (!this.fromDate || !this.toDate) return;

    const [startDate, endDate] = [
      new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day,
        this.fromTime.hour,
        this.fromTime.minute,
        this.fromTime.second
      ),
      new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day,
        this.toTime.hour,
        this.toTime.minute,
        this.toTime.second
      )
    ];
    this.resizeDatepickerTimeSet();
    this.valueChange.emit({ value: [startDate, endDate], preset: datePreset });
    if (this.isCustomDate) {
      this.selectedPreset = null;
    }
    if (this.datePickerBtn?.nativeElement) {
      const { style } = this.datePickerBtn.nativeElement;
      const transition = style.transition;
      style.transition = 'none';
      setTimeout(() => {
        style.transition = transition;
      });
    }
    this.isCustomWidth = this.isCustomDate;
    this.overlay?.hide();
  }

  resizeDatepickerTimeSet() {
    if (this.datePickerBtn?.nativeElement) {
      const { style } = this.datePickerBtn.nativeElement;
      if (this.isCustomDate) {
        style.width = '100%';
      } else {
        style.width = '';
      }
    }
  }

  changeActiveDate(navigateEvent: NgbDatepickerNavigateEvent) {
    this.activeDate = navigateEvent.next;
  }

  getComputedWidth(element: Element, exclude: Array<'padding' | 'margin' | 'border' | 'width'> = []) {
    const computed = getComputedStyle(element);
    let width = 0;
    if (!exclude.includes('width')) {
      width += parseInt(computed.width);
    }
    if (!exclude.includes('padding')) {
      width += parseInt(computed.paddingLeft) + parseInt(computed.paddingRight);
    }
    if (!exclude.includes('border')) {
      width += parseInt(computed.borderLeftWidth) + parseInt(computed.borderRightWidth);
    }
    if (!exclude.includes('margin')) {
      width += parseInt(computed.marginLeft) + parseInt(computed.marginRight);
    }
    return width;
  }
}

const COMPONENTS = [DatepickerExtendComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AynUITranslateModule,
    NgbModule,
    ButtonModule,
    OverlayModule,
    IconModule,
    NgbDatepickerModule
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: []
})
export class DatepickerExtendModule {}
