import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(private swUpdate: SwUpdate, private toastrService: ToastrService) {}

  /**
   * Initiates a PWA(service worker) in app.
   * When a new version comes to the application, it listens for the version
   * information to refresh the page.
   */
  public init() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        map((event) => ({
          type: 'UPDATE_AVAILABLE',
          current: event.currentVersion,
          available: event.latestVersion
        }))
      )
      .subscribe(() => {
        console.warn(`[service-worker]: New version detected!. Reloading...`);

        this.toastrService
          .warning(
            'A new update has arrived for Adyouneed. The page will refresh in 5 seconds.',
            'Adyouneed Updated!',
            {
              timeOut: 5000,
              progressBar: true,
              progressAnimation: 'increasing',
              closeButton: false
            }
          )
          .onHidden.subscribe(() => {
            window.location.reload();
          });
      });
  }
}
