import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-audience-item',
  template: `
    <div
      class="aayn-audience--item"
      [style.max-width.px]="maxWidth"
      [ngClass]="{ 'aayn-audience--item__flexed': flexed }"
    >
      <div class="aayn-audience--item--header" [style]="headerStyle">
        <ayn-icon *ngIf="icon" [name]="icon" class="aayn-audience--item-icon"></ayn-icon>

        <div>
          <h5 class="aayn-audience--item-title">{{ title }}</h5>
          <p class="aayn-audience--item-description">{{ description }}</p>
        </div>
      </div>

      <ng-content></ng-content>
    </div>
  `
})
export class AudienceItemComponent implements OnInit {
  @Input() title?: string;

  @Input() description?: string;

  @Input() icon?: string;

  @Input() maxWidth?: number | string;

  @Input() flexed?: boolean;

  @Input() headerStyle?: string;

  constructor() {}

  ngOnInit() {}
}
