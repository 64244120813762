<aayn-manuel-audience-setup>
  <form [formGroup]="form">
    <aayn-audience-group>
      <aayn-audience-item
        title="Location"
        description="Choose your target locations. Your ads will only be shown to people in these areas."
      >
        <aayn-location-selection--google [form]="form"></aayn-location-selection--google>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience--height:300">
      <aayn-audience-item
        [title]="'Age Range' | translate"
        description="Define your target age range. Your ads will only appear to individuals within this age bracket."
      >
        <aayn-audience-age-range
          [value]="adCreationModel.audience.create.minAge"
          [maxValue]="adCreationModel.audience.create.maxAge"
          (ageRangeChanged)="ageRangeChanged($event)"
          [texts]="{ age: 'Age' | translate }"
        ></aayn-audience-age-range>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Gender' | translate"
        description="Select the gender of your target audience. Your ads will be displayed exclusively to the selected gender."
      >
        <aayn-gender-selection--google [form]="form"></aayn-gender-selection--google>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Audience Language' | translate"
        description="Specify your target language(s). Your ads will only be shown to users who speak the selected language(s)."
      >
        <aayn-language-selection--google [form]="form"></aayn-language-selection--google>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item
        [title]="'Detailed Targeting' | translate"
        description="Determine your target audience to avoid ad display for uninterested individuals."
      >
        <aayn-detailed-targeting--google [form]="form"></aayn-detailed-targeting--google>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item [title]="'Audience Name' | translate">
        <ayn-form-field>
          <input
            type="text"
            formControlName="audienceName"
            ayn-input
            [placeholder]="'Write a audience name' | translate"
          />
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>
  </form>
</aayn-manuel-audience-setup>
