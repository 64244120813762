import { gql } from 'apollo-angular';
import { Kind } from 'graphql';

import type { MutationOptions, QueryOptions, SubscriptionOptions, TypedDocumentNode } from '@apollo/client/core';
import { Base } from '@core/models';

type QueryTypes = 'query' | 'mutation' | 'subscription';

type AynQueryOptions = Partial<{
  resultPath: string;
}>;

type OptionType<T extends QueryTypes, Input, Output> = {
  query: QueryOptions<Input, Output>;
  mutation: MutationOptions<Output, Input>;
  subscription: SubscriptionOptions<Input, Output>;
}[T] &
  AynQueryOptions;

type ExtraOptionsType<T extends QueryTypes, Input, Output> = Omit<
  OptionType<T, Input, Output>,
  'query' | 'mutation' | 'variables'
>;

/**
 * An object that provides metadata about a GraphQL Query options object.
 */
export type WithMeta<T extends object> = T & {
  readonly _actionName: string;
  readonly _resultPath?: string;
  readonly _type: QueryTypes;
};

export type WithRequestId<T extends { variables?: any }> = T & {
  variables: T['variables'] & {
    __aayn_gql_req_id: string | undefined;
  };
};

type QueryFn<T extends QueryTypes> =
  /**
   * Creates a GraphQL query function with its given definition string.
   *
   * @param {string} definitionString The definition string of the GraphQL
   * query.
   *
   * @param {ExtraOptionsType<T, Input, Output>} [options] The optional options
   * that will be applied to the request.
   *
   * @return {*} A function that will create the GraphQL request object input.
   */
  <Output, Input extends Record<string, any> | unknown = unknown>(
    definitionString: string,
    options?: ExtraOptionsType<T, Input, Output>
  ) => unknown extends Input
    ? /**
       * Returns an object that will be used as for provided query type without
       * variables to execute it.
       *
       * @param {ExtraOptionsType<T, Input, Output>} [localOptions] The options
       * that will be affecting the current request.
       *
       * @return {WithMeta<OptionType<T, Input, Output>>} An options for
       * selected query type including metadata.
       */
      (localOptions?: ExtraOptionsType<T, unknown, Output>) => WithMeta<OptionType<T, unknown, Output>>
    : /**
       * Returns an object that will be used as for provided query type with
       * variables to execute it.
       *
       * @param {Input} variables The variables that will be passed to the
       * GraphQL query.
       *
       * @param {ExtraOptionsType<T, Input, Output>} [localOptions] The options
       * that will be affecting the current request.
       *
       * @return {WithMeta<OptionType<T, Input, Output>>} An options for
       * selected query type including metadata.
       */
      (variables: Input, localOptions?: ExtraOptionsType<T, Input, Output>) => WithMeta<OptionType<T, Input, Output>>;

/**
 * A pure function that returns the actual make function.
 *
 * This function is intended to be used for typing purposes.
 *
 * @export
 * @template T The type of the query.
 * @param {T} _ The query type string.
 * @return {QueryFn<T>} The actual query function.
 *
 * @__PURE__
 */
export function make<T extends QueryTypes>(type: T): QueryFn<T> {
  return function _make<Output, Input extends Record<string, any> = any>(
    queryString: string,
    options?: Omit<QueryOptions | MutationOptions | SubscriptionOptions, 'query' | 'mutation' | 'variables'>
  ) {
    const query = gql<Output, Input>(queryString);
    const actionName = extractFunctionName(query);

    type ActualInput = unknown extends Input ? undefined : Input;

    /**
     * Returns an object that will be used as for provided query type with
     * variables to execute it.
     *
     * @param {Input} variables The variables that will be passed to the
     * GraphQL query.
     *
     * @param {ExtraOptionsType<T, Input, Output>} [localOptions] The options
     * that will be affecting the current request.
     *
     * @return {WithMeta<OptionType<T, Input, Output>>} An options for
     * selected query type including metadata.
     */
    function _makeQuery(
      variables: Input,
      localOptions?: ExtraOptionsType<T, Input, Output>
    ): WithMeta<OptionType<T, Input, Output>>;

    /**
     * Returns an object that will be used as for provided query type without
     * variables to execute it.
     *
     * @param {undefined} variables No variables is accepted.
     *
     * @param {ExtraOptionsType<T, Input, Output>} [localOptions] The options
     * that will be affecting the current request.
     *
     * @return {WithMeta<OptionType<T, Input, Output>>} An options for
     * selected query type including metadata.
     */
    function _makeQuery(
      variables?: undefined,
      localOptions?: ExtraOptionsType<T, undefined, Output>
    ): WithMeta<OptionType<T, undefined, Output>>;

    /**
     * Returns an object that will be used as for provided query type without
     * variables to execute it.
     *
     * @param {ExtraOptionsType<T, Input, Output>} [localOptions] The options
     * that will be affecting the current request.
     *
     * @return {WithMeta<OptionType<T, Input, Output>>} An options for
     * selected query type including metadata.
     */
    function _makeQuery(
      localOptions?: ExtraOptionsType<T, undefined, Output>
    ): WithMeta<OptionType<T, undefined, Output>>;

    function _makeQuery<I extends ActualInput>(
      variables?: I,
      localOptions?: ExtraOptionsType<T, any, Output>
    ): WithMeta<OptionType<T, I, Output>> {
      if (isGraphQLOptions<ExtraOptionsType<T, Input, Output>>(variables)) {
        localOptions = variables;
        variables = undefined;
      }

      let mergedOptions: (Omit<QueryOptions, 'query'> & AynQueryOptions) | undefined = options;

      if (typeof mergedOptions !== 'object') {
        mergedOptions = {};
      }

      if (typeof localOptions === 'object') {
        mergedOptions = { ...mergedOptions, ...localOptions };
      }

      mergedOptions.variables = variables;

      let actionObject: { [key in 'query' | 'mutation']?: typeof query };
      if (type === 'mutation') {
        actionObject = { mutation: query };
      } else {
        actionObject = { query };
      }
      return {
        ...actionObject,
        _type: type,
        _actionName: actionName,
        _resultPath: mergedOptions.resultPath,
        ...mergedOptions
      } as WithMeta<{}> as unknown as WithMeta<OptionType<T, I, Output>>;
    }

    return _makeQuery;
  };
}

export const makeQuery = make('query');
export const makeMutation = make('mutation');
export const makeSubscription = make('subscription');

/**
 * Checks whether the passed object is a GraphQL options object for any of the
 * 3 kinds.
 *
 * @template T The type of the passed object.
 * @param {*} it The passed object.
 * @return {it is T} A boolean indicating if the passed object was a GraphQL
 * options object.
 */
function isGraphQLOptions<T extends object>(it: any): it is T {
  if (typeof it !== 'object') return false;
  for (const key of [
    'errorPolicy',
    'context',
    'fetchPolicy',
    'pollInterval',
    'notifyOnNetworkStatusChange',
    'returnPartialData',
    'partialRefetch',
    'canonizeResults',
    'nextFetchPolicy',
    'refetchWritePolicy',
    'awaitRefetchQueries',
    'onQueryUpdated',
    'updateQueries',
    'optimisticResponse',
    'keepRootFields'
  ]) {
    if (key in it) return true;
  }

  return false;
}

/**
 * Extracts the function name of the given GraphQL document node.
 *
 * @template R The return value of the response of the GraphQL document node.
 * @template V The variables type of the GraphQL document node.
 * @param {TypedDocumentNode<R, V>} input The GraphQL document input.
 * @return {string | undefined} The name of the function in the GraphQL document
 * node.
 */
function extractFunctionName<R, V>(input: TypedDocumentNode<R, V>) {
  const [definition] = input.definitions;
  if (definition.kind === Kind.OPERATION_DEFINITION) {
    const { selectionSet } = definition;
    const [firstSelection] = selectionSet.selections;

    if (firstSelection.kind === Kind.FIELD) {
      const { value } = firstSelection.name;
      return value;
    }
  }

  return;
}

export type QueryResult<T extends string, R extends any> = {
  [key in T]: R;
};

export type PaginationOutput<T extends Base.IPageInfo = Base.IPageInfo> = {
  totalCount: number;
  pageInfo: T;
};
export type Edge<T extends any> = {
  node: T;
};

export type Edges<T extends any> = {
  edges: Edge<T>[];
};

export type EdgeResult<T extends string, R extends any> = {
  [key in T]: Edges<R>;
};
export type PaginatedQueryResult<T extends string, R extends any, P extends Base.IPageInfo> = {
  [key in T]: {
    edges: Edge<R>[];
  } & PaginationOutput<P>;
};

export type CursorPaginatedQueryResult<T extends string, R extends any> = PaginatedQueryResult<
  T,
  R,
  Base.CursorPageInfo
>;
export type OffsetPaginatedQueryResult<T extends string, R extends any> = PaginatedQueryResult<
  T,
  R,
  Base.OffsetPageInfo
>;
