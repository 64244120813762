import { ICreateBusinessRequest, ICreateBusinessResponse } from '@core/models';

import { makeMutation } from '../types';

type Input = ICreateBusinessRequest;
type Output = ICreateBusinessResponse;

export const createBusiness = makeMutation<Output, Input>(`
mutation CreateBusiness($data: CreateBusinessDto!) {
  createBusiness(data: $data)
}
`);
