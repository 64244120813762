import { AfterViewInit, ContentChildren, Directive, EventEmitter, Output, QueryList } from '@angular/core';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { CarouselCardComponent } from '@shared/components/carousel-card';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[dragControl]' })
export class DragControlDirective implements AfterViewInit {
  @ContentChildren(CarouselCardComponent, { descendants: true })
  carouselCardComponents!: QueryList<CarouselCardComponent>;

  @Output() aynDrag = new EventEmitter<CdkDragDrop<any>>();

  constructor(private dropList: CdkDropList) {}

  ngAfterViewInit(): void {
    this.carouselCardComponents.changes.subscribe(console.log);

    this.dropList.dropped.pipe(untilDestroyed(this)).subscribe((event) => {
      const { currentIndex } = event;
      const carouselCards = this.carouselCardComponents.toArray();
      const toCard = carouselCards[currentIndex];
      if (toCard.collapsed) {
        this.aynDrag.emit(event);
      }
    });
  }
}
