<section class="aayn-ad-preview-header">
  <ng-container *ngIf="displayImage">
    <img
      [src]="profileImage | aynSafe: 'url'"
      [alt]="businessName || ('[Business Name]' | translate)"
      class="aayn-ad-preview-header--img"
      *ngIf="profileImage?.length; else logoPlaceholder"
    />
  </ng-container>

  <div class="aayn-ad-preview-header--texts">
    <p class="aayn-ad-preview-header--title">{{ businessName || ('[Business Name]' | translate) }}</p>
    <p class="aayn-ad-preview-header--description">{{ description }} <ayn-icon name="globe"></ayn-icon></p>
  </div>
</section>
<ng-template #logoPlaceholder>
  <span class="aayn-ad-preview-header--img"></span>
</ng-template>
