import { createSubject } from '@core/utils';
import { FormGroup } from '@angular/forms';
import { PlatformStep } from '@core/models';
import { Subject } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';

export abstract class ValidationService {
  /**
   * Objective Step
   */
  public objectiveValid$ = createSubject(false);
  public objectiveMarkAsTouched$ = createSubject(false);

  /**
   * Audience Step
   */
  public audienceValid$ = createSubject(false);

  /**
   * Ad Step
   */
  public adTypeValid$ = createSubject(false);

  reset(): void {
    this.objectiveValid$.complete();
    this.objectiveMarkAsTouched$.complete();
    this.audienceValid$.complete();
    this.adTypeValid$.complete();

    this.objectiveValid$ = createSubject(false);
    this.objectiveMarkAsTouched$ = createSubject(false);
    this.audienceValid$ = createSubject(false);
    this.adTypeValid$ = createSubject(false);
  }

  getStepValidation(step: PlatformStep) {
    switch (step) {
      case PlatformStep.Objective:
        return this.objectiveValid$;
      case PlatformStep.Audience:
        return this.audienceValid$;
      case PlatformStep.AdType:
      case PlatformStep.BudgetReview:
        return this.adTypeValid$;
      default:
        return createSubject(false);
    }
  }

  registerForm(form: FormGroup, step: PlatformStep, componentInstance: any) {
    switch (step) {
      case PlatformStep.Objective:
        return this.register(form, this.objectiveValid$, componentInstance);
      case PlatformStep.Audience:
        return this.register(form, this.audienceValid$, componentInstance);
      case PlatformStep.AdType:
        return this.register(form, this.adTypeValid$, componentInstance);
      default:
        return createSubject(false);
    }
  }

  protected register(form: FormGroup, subject: Subject<boolean>, componentInstance: any) {
    return form.statusChanges
      .pipe(distinctUntilChanged(), startWith(form.status), untilDestroyed(componentInstance))
      .subscribe({
        next: (status) => {
          subject.next(status == 'VALID');
        }
      });
  }
}
