<header class="modal-v3--header">
  <ayn-icon name="link-2"></ayn-icon>

  <div class="modal-v3--header-content">
    <h5>Are you ready to disconnect?</h5>
    <p>
      This will delete the Ad Account connections for [{{ PlatformKeyByValues[platformContext?.source || 0] }}] in your
      business(es) below.
    </p>
  </div>
</header>

<main class="modal-v3--content">
  <ul class="disonnect-confirmation-businesses" *ngIf="businessesByConnectedPlatform.length">
    <li *ngFor="let business of businessesByConnectedPlatform">
      <div>
        <h5 class=":business-name">{{ business.name }}</h5>
        <p class=":business-category">{{ BusinessCategoryMap[business.category] | translate }}</p>
      </div>

      <div class=":icons">
        <ayn-icon
          *ngFor="let platform of business.connectedAdAccounts"
          [name]="'platforms:' + PlatformIcons[platform.source]"
        ></ayn-icon>
      </div>
    </li>
  </ul>
  <span *ngIf="!businessesByConnectedPlatform.length">
    {{
      'There is no related business with ' + PlatformKeyByValues[platformContext?.source || 0] + ' platform' | translate
    }}
  </span>
</main>

<footer class="modal-v3--footer :space-between">
  <a class="modal-v3--footer:go-back" (click)="goBack()">Go back</a>

  <button ayn-button="primary" (click)="disconnect$()" [loading]="loader$ | async">Disconnect and Continue</button>

  <div style="width: 55px"></div>
</footer>
