import { Observable } from 'rxjs';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from '@ayn-ui/public-api';
import { Google } from '@core/models';
import { GoogleService } from '@core/services';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import {
    GoogleFormService, GoogleStateService, GoogleValidationService
} from '@pages/create-ad/state';

@Component({
  selector: 'aayn-conversion-my-website-google',
  templateUrl: './conversion-my-website.component.html'
})
export class ConversionMyWebsiteGoogleComponent implements OnInit {
  protected adCreationModel = this.googleStateService.adCreationModel;

  searchTerm?: string;

  form = new FormGroup({
    campaignName: new FormControl(this.adCreationModel?.campaign?.campaignName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ]),
    conversionEvent: new FormControl(this.adCreationModel?.campaign?.conversionGoal)
  });

  conversionEvents: IOption[] = [];

  @Select(LoaderState.getAny(['ConversionGoal'])) loader$?: Observable<boolean>;

  constructor(
    private googleValidationService: GoogleValidationService,
    private googleStateService: GoogleStateService,
    private googleService: GoogleService,
    private cdr: ChangeDetectorRef,
    private googleFormService: GoogleFormService
  ) {}

  ngOnInit() {
    this.googleFormService.registerForm(this.form);

    this.googleStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.form.statusChanges.subscribe((status) => {
      this.googleValidationService.objectiveValid$.next(status == 'VALID');
    });

    this.form.valueChanges.subscribe((x) => {
      this.adCreationModel!.campaign.campaignName = x.campaignName!;
      this.adCreationModel!.campaign.conversionGoal = x.conversionEvent!;
    });

    this.searchValueChange('');
  }

  searchValueChange(term: string) {
    if (this.searchTerm === term) {
      return;
    }
    this.searchTerm = term;
    this.googleService
      .conversionGoal({ searchKey: term } as Google.CreateAd.IGoogleConversionGoalOutputRequest)
      .subscribe((result) => {
        this.conversionEvents =
          result?.map(
            (o) =>
              ({
                label: o.name,
                value: { id: o.id, conversionActionIds: o.conversionActionIds, category: o.category }
              } as IOption)
          ) || [];
        this.cdr.detectChanges();
      });
  }
}
