<ng-container *ayn-loader="!!(loader$ | async); maxWidth: 85">
  <ayn-table
    type="primary"
    *ngIf="(adAccounts || [] | aynFilterEqual: { selected: true })?.length"
    [showPagination]="false"
    class="aayn-account-select-modal--stepper__body--table table-selected"
  >
    <ng-template #header>
      <tr>
        <th class="aayn-account-select-modal--stepper__body--table--header--name">{{ 'Name' | translate }}</th>
        <th class="aayn-account-select-modal--stepper__body--table--header--id">{{ 'Account Id' | translate }}</th>
        <th class="text-center aayn-account-select-modal--stepper__body--table--header--status">
          {{ 'Account Status' | translate }}
        </th>
        <th class="aayn-account-select-modal--stepper__body--table--header--action">&nbsp;</th>
      </tr>
    </ng-template>
    <ng-template #body>
      <ng-container>
        <tr *ngFor="let item of adAccounts || [] | aynFilterEqual: { selected: true } | aynFilter: query">
          <td class="name">
            {{ item.name }}
          </td>
          <td class="account-id">
            {{ item.id || '' | aynFilterText: 'act_' }}
          </td>
          <td class="status" [ngClass]="{ active: getStatus(item), passive: !getStatus(item) }">
            <span>
              <strong>&nbsp;</strong>
              {{ getStatusText(item) }}
            </span>
          </td>
          <td class="select">
            <button
              class="select-btn"
              ayn-button="accent"
              ayn-button-block
              type="submit"
              (click)="changeSelected(item, false)"
            >
              {{ 'Selected' | translate }}
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </ayn-table>
  <ng-container *ngIf="adAccounts?.length; else noContent">
    <cdk-virtual-scroll-viewport
      style="height: 350px"
      *ngIf="(adAccounts! | aynFilterEqual: { selected: false }).length"
    >
      <ayn-table
        #scrollTable
        [showPagination]="false"
        class="aayn-account-select-modal--stepper__body--table"
        type="primary"
      >
        <ng-template #header>
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-template>

        <ng-template #body>
          <tr #cdkFor *cdkVirtualFor="let item of adAccounts! | aynFilterEqual: { selected: false } | aynFilter: query">
            <td class="name">
              {{ item.name }}
            </td>
            <td class="account-id">
              {{ item.id | aynFilterText: 'act_' }}
            </td>

            <td class="status" [ngClass]="{ active: getStatus(item), passive: !getStatus(item) }">
              <span>
                {{ getStatusText(item) }}
              </span>
            </td>
            <td class="select" *ngIf="{ loader: buttonLoader$ | async } as data">
              <button
                class="select-btn"
                ayn-button="{{ getStatus(item) ? (item.selected ? 'accent' : 'primary') : 'disabled' }}"
                ayn-button-block
                (click)="changeSelected(item, !item.selected)"
                type="submit"
                [disabled]="item !== itemClicked ? data.loader : false"
                [loading]="item === itemClicked ? data.loader : false"
              >
                {{ item.selected ? ('Selected' | translate) : ('Select' | translate) }}
              </button>
            </td>
          </tr>
        </ng-template>
      </ayn-table>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</ng-container>
<ng-template #noContent>
  <ayn-table [showPagination]="false" class="aayn-account-select-modal--stepper__body--table" type="primary">
    <ng-template #header>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-template>

    <ng-template #body>
      <tr>
        <td colspan="5">
          <aayn-empty-content
            title="{{ 'No Records Found' | translate }}"
            description="{{ 'Please try again.' | translate }}"
            [showButton]="false"
          ></aayn-empty-content>
        </td>
      </tr>
    </ng-template>
  </ayn-table>
</ng-template>

<ng-template #headerTemplate>
  <tr>
    <th class="aayn-account-select-modal--stepper__body--table--header--name">{{ 'Name' | translate }}</th>
    <th class="aayn-account-select-modal--stepper__body--table--header--id">{{ 'Account Id' | translate }}</th>
    <th class="text-center aayn-account-select-modal--stepper__body--table--header--status">
      {{ 'Account Status' | translate }}
    </th>
    <th class="aayn-account-select-modal--stepper__body--table--header--action">&nbsp;</th>
  </tr>
</ng-template>
