import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AdPlatforms, PlatformConfig, Platforms } from '@core/models';

import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { AdPreviewSmartCampaignService } from '../../ad-preview.service';
import { FacebookService, LinkedinService } from '@core/services';
import { CreateAdState } from '@pages/create-ad/state';
import { Store } from '@ngxs/store';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoaderState } from '@core/state/loader.state';
import {
  AdPreviewInstagramPlatform,
  AdPreviewPlatform
} from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-dynamic-creative-preview--smart-campaign',
  templateUrl: './dynamic-creative-preview.component.html'
})
export class SmartCampaignDynamicCreativePreviewComponent implements OnInit {
  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  Platforms = Platforms;

  @Input() title = '';

  PlatformConfig = PlatformConfig;

  platforms?: AdPreviewPlatform[];

  selectedPlatform?: Platforms;

  business = {
    businessLogo: '',
    businessName: ''
  };

  loader$ = this.store.select(LoaderState.getAny(['GetBusinessPageDetail', 'GetOrganizationsWithLogos']));

  facebookBusiness$ = this.facebookService.getBusinessPageDetail$().pipe(
    map(
      (res) => ({
        businessLogo: res?.data.businessPageDetail.imageUrl,
        businessName: res?.data.businessPageDetail.name
      }),
      shareReplay()
    )
  );

  linkedinBusiness$ = of('').pipe(
    switchMap(() => {
      const platforms = this.store.selectSnapshot(CreateAdState.selectedSmartCampaignPlatformsDetails);
      const liPlatform = platforms?.find((x) => x?.source === Platforms.LinkedIn);
      if (liPlatform?.pageId) {
        const pageId = liPlatform.pageId.split(':')[3];
        return this.linkedinService.getOrganizationWithLogos([+pageId]).pipe(
          map((result) => ({
            businessName: result.getOrganizations.results[pageId!].localizedName,
            businessLogo: result.getOrganizationLogos.results[pageId!].downloadUrl
          }))
        );
      }
      return of({ businessName: '', businessLogo: '' });
    }),
    shareReplay()
  );

  constructor(
    public adPreviewSmartCampaignService: AdPreviewSmartCampaignService,
    private smartCampaignStateService: SmartCampaignStateService,
    private facebookService: FacebookService,
    private linkedinService: LinkedinService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.platforms = this.PlatformConfig?.filter((x) =>
      this.adCreationModel.selectedPlatforms.includes(x.value as AdPlatforms)
    ).map((p) => ({ icon: p.icon, label: p.label.replace(' Ads', ''), platform: p.value }));

    if (this.adCreationModel.selectedPlatforms.includes(Platforms.Meta)) {
      const metaPlatformIndex = this.platforms.findIndex((x) => x.platform === Platforms.Meta);
      this.platforms?.splice(metaPlatformIndex + 1, 0, AdPreviewInstagramPlatform);
    }

    this.selectedPlatform = this.platforms?.[0]?.platform;
    this.getBusiness(this.selectedPlatform);
    this.cdr.detectChanges();
  }

  getBusiness(platform: Platforms) {
    switch (platform) {
      case Platforms.TikTok:
        this.business.businessLogo =
          this.adPreviewSmartCampaignService.dynamicCreative.tiktokBusiness?.profileImage || '';
        this.business.businessName =
          this.adPreviewSmartCampaignService.dynamicCreative.tiktokBusiness?.displayName || '';
        break;

      case Platforms.Bing:
      case Platforms.Google:
        break;

      case Platforms.Meta:
      case Platforms.Instagram:
        this.facebookBusiness$.pipe(take(1)).subscribe((res) => {
          this.business.businessLogo = res.businessLogo;
          this.business.businessName = res.businessName;
          this.cdr.detectChanges();
        });
        break;
      case Platforms.LinkedIn:
        this.linkedinBusiness$.pipe(take(1)).subscribe((res) => {
          this.business.businessLogo = res.businessLogo;
          this.business.businessName = res.businessName;
          this.cdr.detectChanges();
        });
        break;
      default:
        this.business.businessLogo = '';
        this.business.businessName = '';
    }
  }
}
