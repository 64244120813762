import { makeQuery } from '@core/queries/types';

type Output = {
  minimumBudgetAmount: number;
};

export const minimumBudgetAmount = makeQuery<Output>(`
  query GetMinimumBudgetAmount {
    google {
      minimumBudgetAmount
    }
  }
`);
