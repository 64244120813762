import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseResponse } from '@core/models/base.model';

export interface FilterError {
  [key: string]: (error: HttpErrorResponse | BaseResponse) => boolean;
}

@Injectable({ providedIn: 'root' })
export class GqlFilterErrorService {
  private _filterErrorMap = {} as FilterError;

  registerFilterError(operationName: string, predicate: (error: HttpErrorResponse | BaseResponse) => boolean) {
    this._filterErrorMap[operationName] = predicate;
  }

  unregisterFilterError(operationName: string) {
    this._filterErrorMap = omit(this._filterErrorMap, operationName);
  }

  getFilterError(operationName: string) {
    return this._filterErrorMap[operationName] || (() => false);
  }
}
