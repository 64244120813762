import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Linkedin } from '@core/models';
import { CreateAdState } from '@pages/create-ad/state';
import { LinkedinService } from '@core/services';
import { Store } from '@ngxs/store';

@Component({
  selector: 'aayn-ad-preview--linkedin',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewLinkedinComponent implements OnInit {
  @Input() selectedAdType: Linkedin.AdType = Linkedin.AdType.SingleImage;

  protected AdTypes = Linkedin.AdType;
  businessName!: string;
  organizationImage!: string;

  constructor(private linkedinService: LinkedinService, private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const state = this.store.selectSnapshot(CreateAdState.selectedPlatformDetail);
    if (state?.pageId) {
      const pageId = state.pageId.split(':')[3];
      this.linkedinService.getOrganizationWithLogos([+pageId]).subscribe((result) => {
        this.businessName = result.getOrganizations.results[pageId!].localizedName;
        this.organizationImage = result.getOrganizationLogos.results[pageId!].downloadUrl;

        this.cdr.detectChanges();
      });
    }
  }
}
