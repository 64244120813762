import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { keyword: string };
type Output = {
  detailedTargetings: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
};

export const getDetailedTargeting = makeQuery<Output, Input>(`
  query GetDetailedTargetings($keyword: String!) {
    facebook {
      detailedTargetings(keyword: $keyword) {
        id
        name
        type
        path
        audienceSizeLowerBound
        audienceSizeUpperBound
      }
    }
  }
`);
