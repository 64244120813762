import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleInterestRequest;
type Output = Google.CreateAd.IGoogleInterestResponse;

export const interestsSearch = makeMutation<Output, Input>(`
  query InterestsSearch($limit: Float, $searchKey: String) {
    google {
      userInterestsSearch(limit: $limit, searchKey: $searchKey) {
        id
        name
        type
        interestType
      }
    }
  }
`);
