import { FormArray } from '@angular/forms';

export function moveArrayItem<T>(array: T[], prevIndex: number, currentIndex: number) {
  const newArray = [...array];
  const itemToMove = newArray.splice(prevIndex, 1)[0];
  newArray.splice(currentIndex, 0, itemToMove);
  return newArray;
}

export function moveFormArrayItem<T>(array: FormArray, prevIndex: number, currentIndex: number) {
  const item = array.at(prevIndex);
  array.removeAt(prevIndex);
  array.insert(currentIndex, item);
}
