import type { Eq } from 'fp-ts/Eq';

/**
 * Creates a default equality comparer.
 * @param {boolean} [strict=true] Indicates if the comparison should be strict.
 * @return {Eq<T>} An equality comparer for given type.
 * @__PURE__
 */
export function makeDefaultEq<T>(strict = true): Eq<T> {
  return {
    equals(left: T, right: T): boolean {
      if (strict) {
        return left === right;
      } else {
        return left == right;
      }
    }
  };
}

/**
 * Creates an equality comparer for iterable instances.
 *
 * The equality comparer requires both iterables to be in order.
 *
 * @typedef T The value type.
 * @param {Eq<T>} valueEq The value equality comparer.
 * @return {Eq<Iterable<T>>} An equality comparer for iterable of `T` values.
 * @__PURE__
 */
export function makeIterableEq<T>(valueEq: Eq<T>): Eq<Iterable<T>> {
  return {
    equals(left: Iterable<T>, right: Iterable<T>): boolean {
      if (left === right) return true;
      if (Array.isArray(left) && Array.isArray(right)) {
        return makeArrayEq<T>(valueEq).equals(left, right);
      } else if (left instanceof Set && right instanceof Set) {
        return makeSetEq<T>(valueEq).equals(left, right);
      }

      const leftGen = left[Symbol.iterator]();
      const rightGen = right[Symbol.iterator]();
      while (true) {
        const lhs = leftGen.next();
        const rhs = rightGen.next();

        if (lhs.done && rhs.done) return true;
        if (lhs.done || rhs.done) return false;

        if (!valueEq.equals(lhs.value, rhs.value)) return false;
      }
    }
  };
}

/**
 * Creates an equality comparer for array instances.
 * @typedef T The value type.
 * @param {Eq<T>} valueEq The value equality comparer.
 * @return {Eq<Array<T>>} An equality comparer for `T` array.
 * @__PURE__
 */
export function makeArrayEq<T>(valueEq: Eq<T>): Eq<Array<T>> {
  return {
    equals(left: Array<T>, right: Array<T>): boolean {
      if (left === right) return true;
      if (left.length !== right.length) return false;

      for (let i = 0, limit = left.length; i < limit; ++i) {
        const lhs = left[i];
        const rhs = right[i];
        if (!valueEq.equals(lhs, rhs)) return false;
      }

      return true;
    }
  };
}

/**
 * Creates an equality comparer for set instances.
 * @typedef T The value type.
 * @param {Eq<T>} valueEq The value equality comparer.
 * @return {Eq<Set<T>>} An equality comparer for `T` set.
 * @__PURE__
 */
export function makeSetEq<T>(valueEq: Eq<T>): Eq<Set<T>> {
  return {
    equals(left: Set<T>, right: Set<T>): boolean {
      if (left === right) return true;
      if (left.size !== right.size) return false;

      for (const lhs of left) {
        let found = false;
        for (const rhs of right) {
          found = right.has(lhs) || valueEq.equals(lhs, rhs);
          if (found) break;
        }

        if (!found) return false;
      }

      return true;
    }
  };
}

/**
 * Creates an equality comparer for record instances.
 * @typedef T The value type.
 * @param {Eq<T>} valueEq The value equality comparer.
 * @return {Eq<Record<PropertyKey, T>>} An equality comparer for `T` record.
 * @__PURE__
 */
export function makeRecordEq<T>(valueEq: Eq<T>): Eq<Record<PropertyKey, T>> {
  return {
    equals(left: Record<PropertyKey, T>, right: Record<PropertyKey, T>): boolean {
      if (left === right) return true;

      const leftKeys = Object.keys(left);
      return (
        leftKeys.every((key) => key in right && valueEq.equals(left[key], right[key])) &&
        Object.keys(right).length === leftKeys.length
      );
    }
  };
}

/**
 * Creates an equality comparer for map instances.
 * @typedef T The value type.
 * @param {Eq<T>} valueEq The value equality comparer.
 * @return {Eq<Map<PropertyKey, T>>} An equality comparer for `T` map.
 * @__PURE__
 */
export function makeMapEq<T>(valueEq: Eq<T>): Eq<Map<PropertyKey, T>> {
  return {
    equals(left: Map<PropertyKey, T>, right: Map<PropertyKey, T>): boolean {
      if (left === right) return true;
      if (left.size !== right.size) return false;

      for (const [key, lhs] of left) {
        if (!right.has(key)) return false;
        const rhs = right.get(key);
        if (!valueEq.equals(lhs, rhs!)) return false;
      }

      return true;
    }
  };
}

/**
 * Creates an equality comparer for object instances
 * whose properties will be checked.
 * @param {string[]} propList The props to check.
 * @return {Eq} An equality comparer.
 *
 * @__PURE__
 */
export function makePropEq<T extends readonly string[]>(...propList: T) {
  type O = { [K in T[number]]?: any };

  return {
    equals(left: any, right: any): boolean {
      return propList.every((prop) => {
        const lhs = left[prop];
        const rhs = right[prop];
        return lhs === rhs;
      });
    }
  } as Eq<O>;
}
