/* eslint-disable no-dupe-class-members */
import type { Eq } from 'fp-ts/Eq';
import { makeDefaultEq, makePropEq, makeSetEq } from './eq-singletons';

/**
 * A set instance that uses an {@link Eq} internally.
 */
export class EqSet<T> extends Set<T> {
  /**
   * The equality comparer for the values.
   * @protected
   */
  protected readonly eq: Eq<T>;

  constructor(valueEq: Eq<T>, values?: Iterable<T>) {
    super();
    this.eq = valueEq;

    if (!values) return;
    this.union(values);
  }

  override add(value: T): this {
    if (this.has(value)) return this;
    super.add(value);
    return this;
  }

  override has(value: T): boolean {
    if (super.has(value)) return true;

    const eq = this.eq;

    for (const current of this) {
      if (eq.equals(current, value)) {
        return true;
      }
    }

    return false;
  }

  override delete(value: T): boolean {
    if (super.delete(value)) return true;

    const eq = this.eq;

    let found = false;
    let foundValue: T | undefined;
    for (const current of this) {
      if (eq.equals(current, value)) {
        found = true;
        foundValue = current;
        break;
      }
    }

    if (found) {
      super.delete(foundValue!);
    }

    return found;
  }

  /**
   * Checks if all of the given values are contained by this set.
   * @param {Iterable<T>} values The values to check.
   * @return {boolean} `true` if all the values were contained by the set.
   * @__PURE__
   */
  hasAll(values: Iterable<T>): boolean {
    for (const value of values) {
      if (!this.has(value)) return false;
    }

    return true;
  }

  /**
   * Deletes all the given values.
   * @param {Iterable<T>} values The values to delete from the set.
   */
  deleteAll(values: Iterable<T>) {
    for (const value of values) this.delete(value);
  }

  /**
   * Removes the values that meet the condition.
   * @param {(value: T) => boolean} f The function to test whether
   * the value should be removed.
   * @param {number} [count=Infinity] The number of items that can
   * be removed at most.
   *
   * @return {EqSet<T>} The instance itself to allow chaining.
   */
  deleteIf(f: (value: T) => boolean, count = Infinity): EqSet<T> {
    if (count <= 0) return this;

    const source = this.toArray();
    if (isFinite(count)) {
      for (const value of source) {
        if (!f(value)) continue;
        this.delete(value);
        if (--count <= 0) break;
      }
    } else {
      this.toArray()
        .filter(f)
        .forEach((it) => this.delete(it));
    }

    return this;
  }

  /**
   * Creates another instance that does not contain the given value.
   * @param {T} value The value to exclude from the set.
   * @return {EqSet<T>} A new instance without the removed value.
   * @__PURE__
   */
  excluded(value: T): EqSet<T> {
    const cloned = this.clone();
    cloned.delete(value);
    return cloned;
  }

  /**
   * Toggles the set by adding the given element if that did not exist,
   * otherwise adds the element to the set.
   * @param {T} value The value to toggle.
   */
  toggle(value: T) {
    if (!this.delete(value)) {
      this.add(value);
    }
  }

  /**
   * Creates another instance where the value is toggled.
   * @param {T} value The value to toggle.
   * @return {EqSet<T>} A new instance whose value is toggled.
   * @__PURE__
   */
  toggled(value: T): EqSet<T> {
    const cloned = this.clone();
    cloned.toggle(value);
    return cloned;
  }

  /**
   * Checks if there all the elements meet the condition.
   * @param f The condition to check.
   *
   * @return {boolean} `true` if all of the elements meet the condition.
   * @__PURE__
   */
  every(f: (item: T) => boolean): boolean {
    return this.some((it) => !f(it));
  }

  /**
   * Checks if there is at least one element meets the condition.
   * @param f The condition to check.
   *
   * @return {boolean} `true` if there was at least one element meets
   * the condition.
   * @__PURE__
   */
  some(f: (item: T) => boolean): boolean {
    for (const value of this) {
      if (f(value)) return true;
    }

    return false;
  }

  /**
   * Creates an array from this instance.
   * @return {T[]} An array containing all the elements in this set.
   * @__PURE__
   */
  toArray(): T[] {
    return Array.from(this);
  }

  /**
   * Filters the elements and returns another instance of set
   * containing the elements that are passed the filter condition.
   * @param f The filter condition.
   * @return {EqSet<T>} A new instance of set containing the elements
   * from the filter.
   *
   * @__PURE__
   */
  filter(f: (item: T) => boolean): EqSet<T> {
    const filteredValues = new Array<T>();
    for (const value of this) {
      if (f(value)) {
        filteredValues.push(value);
      }
    }

    return new EqSet<T>(this.eq, filteredValues);
  }

  /**
   * Maps the elements of the set and returns another instance.
   * @param f The mapper function.
   * @return {EqSet<T>} A new instance that contains the mapped elements.
   * @__PURE__
   */
  map(f: (item: Readonly<T>) => T): EqSet<T>;

  /**
   * Maps the elements of the set and returns another instance.
   * @param f The mapper function.
   * @param vEq The new equality comparer.
   * @return {EqSet<T>} A new instance that contains the mapped elements.
   * @__PURE__
   */
  map<V>(f: (item: Readonly<T>) => V, vEq: Eq<V>): EqSet<V>;
  map<V>(f: (item: Readonly<T>) => V, vEq?: Eq<V>): EqSet<V> {
    const newSet = new EqSet((vEq ?? this.eq) as Eq<any>);

    for (const value of this) {
      newSet.add(f(value));
    }

    return newSet;
  }

  /**
   * Finds the first element meets the condition.
   * @param f The predicate function.
   * @return {T | undefined} The first element that meets the condition.
   * @__PURE__
   */
  find(f: (item: Readonly<T>, eq: Eq<T>) => boolean): T | undefined {
    for (const value of this) {
      if (f(value, this.eq)) {
        return value;
      }
    }
    return undefined;
  }

  /**
   * Checks if the set is equal to given other set.
   * @param {Set<T>} other The other set.
   * @return {boolean} `true` if the other set equals to this one.
   * @__PURE__
   */
  equals(other: Set<T>): boolean {
    return makeSetEq<T>(this.eq).equals(this, other);
  }

  /**
   * Removes the values from this set if that is not found in the
   * given iterable.
   *
   * @param {Iterable<T>} iterable The iterable source to keep the
   * values that are common.
   *
   * @return {T[]} The removed values
   */
  intersectInPlace(iterable: Iterable<T>): Array<T> {
    const eq = this.eq;
    const generator = this[Symbol.iterator]();
    const removedValues = [] as T[];

    for (let current = generator.next(); !current.done; current = generator.next()) {
      const { value } = current;

      let found = false;
      for (const other of iterable) {
        if (eq.equals(value, other)) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.delete(value);
        removedValues.push(value);
      }
    }

    return removedValues;
  }

  /**
   * Creates a new set where its values are in common with the given iterable.
   * @param {iterable<T>} iterable The iterable source to keep the values
   * that are common.
   *
   * @return {EqSet<T>} A new instance containing the values that are in
   * common with given iterable.
   * @__PURE__
   */
  intersect(iterable: Iterable<T>): EqSet<T> {
    const instance = new EqSet(this.eq, this);
    instance.intersectInPlace(iterable);
    return instance;
  }

  /**
   * Unions the given iterable of values with the current values.
   * @param {Iterable<T>} iterable The iterable of values to include
   * to this set.
   *
   * @return {EqSet<T>} The same instance of the set for chaining.
   */
  union(iterable: Iterable<T>): EqSet<T> {
    for (const value of iterable) {
      this.add(value);
    }

    return this;
  }

  /**
   * Creates a new set where its values are unioned with given iterable.
   * @param {Iterable<T>} iterable The iterable of values to include to
   * this set.
   *
   * @return {EqSet<T>} A new instance of the set for chaining.
   *
   * @__PURE__
   */
  unioned(iterable: Iterable<T>): EqSet<T> {
    return this.clone().union(iterable);
  }

  /**
   * Clones the set and returns another instance.
   */
  clone(): EqSet<T> {
    return new EqSet<T>(this.eq, this);
  }

  /**
   * Removes all elements from a Set object
   * @__PURE__
   */
  cleared(): EqSet<T> {
    const instance = this.clone();
    instance.clear();
    return instance;
  }

  /**
   * Replaces the value in the set with the given value.
   * @param value The new value to replace.
   */
  replace(value: T): EqSet<T> {
    const instance = this.clone();
    instance.delete(value);
    instance.add(value);
    return instance;
  }

  static of<K, T extends Eq<K>>(baseValue: K): EqSet<T>;
  static of(baseValue: any) {
    const eq: Eq<any> = {
      equals(left: any, right: any): boolean {
        if (typeof baseValue.equals === 'function') {
          return baseValue.equals(left, right);
        } else {
          throw new Error(`[EqSet of:${baseValue}] - Source did not provide a comparer`);
        }
      }
    };

    return new EqSet(eq, [baseValue]);
  }

  /**
   * Creates an instance that compares the values by strict equals.
   * @__PURE__
   */
  static createDefault<T>(): EqSet<T> {
    return new EqSet<T>(makeDefaultEq<T>());
  }

  /**
   * Creates an instance that compares two objects by their properties.
   * @param {string[]} props The properties to compare.
   * @return {EqSet} A new instance of the set.
   * @__PURE__
   */
  static forProps<P extends readonly [string, ...string[]]>(...props: P): EqSet<{ [K in P[number]]: any }> {
    return new EqSet<{ [K in P[number]]: any }>(makePropEq(...props));
  }
}
