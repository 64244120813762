import { bounceOutDownOnLeaveAnimation, fadeInUpOnEnterAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'aayn-collapse-card',
  templateUrl: './collapse-card.component.html',
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100, translate: '30px' }),
    bounceOutDownOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 200, translate: '40px' })
  ]
})
export class CollapseCardComponent implements OnInit, OnDestroy {
  @Input() title?: string | TemplateRef<HTMLElement>;

  @Input() subTitle?: string;

  @Input() description?: string;

  @Input() active = true;

  @Input() error$?: Observable<string>;

  @Input() errorIcon: string = 'refresh';

  @Input() errorButtonName: string = 'Retry';

  @Input() isShowHeader: boolean = true;

  @Input() disabled: boolean = false;

  @Input() clickable = true;

  @Input() isCollapseIcon: boolean = true;

  @Input() collapseIconDirection: 'left' | 'right' = 'left';

  @Input() comingSoon?: string;

  @Input() headerIcon = '';

  @Output() retryButton: EventEmitter<Event> = new EventEmitter<Event>();

  @Output() activeChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.error$?.subscribe((data) => {
      if (data) {
        this.active = false;
        this.activeChange.emit(this.active);
      }
    });
  }

  changeStatus() {
    if (this.disabled) {
      return;
    }
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }

  open() {
    if (!this.active) {
      this.changeStatus();
    }
  }

  close() {
    if (this.active) {
      this.changeStatus();
    }
  }

  get isTitleTemplate() {
    return this.title instanceof TemplateRef;
  }

  ngOnDestroy(): void {
    this.retryButton.complete();
    this.activeChange.complete();
  }
}
