<aayn-ad-preview
  [businessName]="businessName"
  [profileImage]="businessImage"
  className="aayn-ad-preview--meta-carousel"
>
  <p class="aayn-ad-preview--meta-carousel_header">
    {{ headline || (headlinePlaceholder | translate) }}
  </p>
  <ayn-carousel
    class="aayn-ad-preview--meta-carousel_slide"
    [activeIndex]="activeIndex!"
    [itemsPerSlide]="2"
    (activeIndexChange)="activeIndexChange.emit($event)"
    previewType="ordered"
  >
    <ng-template ayn-carousel-item *ngFor="let card of cards; index as index">
      <div class="aayn-ad-preview--meta-carousel_slide-item">
        <div *ngIf="!card.image?.[0]" class="aayn-ad-preview-placeholder">
          <ayn-icon name="null-image-icon"></ayn-icon>
        </div>
        <img *ngIf="card.image?.[0]" class="aayn-ad-preview--meta-carousel_slide-item__image" [src]="card.image?.[0]" />

        <section class="aayn-ad-preview--meta-carousel_slide-item__content">
          <div class="aayn-ad-preview--meta-carousel_slide-item__content__labels">
            <p class="aayn-ad-preview--meta-carousel_slide-item__content__body">
              {{ card.body || (bodyPlaceholder | translate) }}
            </p>
            <p class="aayn-ad-preview--meta-carousel_slide-item__content__cta">
              {{ cta || (ctaPlaceholder | translate) }}
            </p>
          </div>
        </section>
      </div>
    </ng-template>
  </ayn-carousel>
</aayn-ad-preview>
