<div class="aayn-create-ad--item">
  <ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
    <div class="aayn-review-and-publish__container" [ngSwitch]="selectedPlatform">
      <div *ngSwitchCase="Platforms.Meta" [@fadeInOnEnter]>
        <aayn-select-and-review--facebook></aayn-select-and-review--facebook>
      </div>

      <div *ngSwitchCase="Platforms.Google" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--google></aayn-select-review-and-publish--google>
      </div>

      <div *ngSwitchCase="Platforms.Bing" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--bing></aayn-select-review-and-publish--bing>
      </div>

      <div *ngSwitchCase="Platforms.LinkedIn" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--linkedin></aayn-select-review-and-publish--linkedin>
      </div>

      <div *ngSwitchCase="Platforms.SmartCampaign" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--smart-campaign></aayn-select-review-and-publish--smart-campaign>
      </div>

      <div *ngSwitchCase="Platforms.TikTok" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--tiktok></aayn-select-review-and-publish--tiktok>
      </div>

      <div *ngSwitchCase="Platforms.InstantAd" [@fadeInOnEnter]>
        <aayn-select-review-and-publish--instant-ad></aayn-select-review-and-publish--instant-ad>
      </div>
    </div>
  </ng-container>
</div>
