import { environment } from '@environment';
import { pipe } from 'fp-ts/lib/function';
import { tap } from 'rxjs/operators';
import { rename } from 'spectacles-ts';

import { Injectable } from '@angular/core';

import { SmartCampaign } from '../';
import { LazyAssetService } from './lazy-asset.service';

@Injectable({ providedIn: 'root' })
export class GoogleMapService {
  private _loaded = false;

  private _placeService?: google.maps.places.PlacesService;

  constructor(private lazyAssetService: LazyAssetService) {}

  loadScript$() {
    return this.lazyAssetService
      .loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapKey}&libraries=places&language=en`
      )
      .pipe(
        tap(() => {
          this._placeService = new google.maps.places.PlacesService(document.createElement('div'));
        })
      );
  }

  searchLocation$(input: string): Promise<google.maps.places.AutocompleteResponse> {
    const autocomplete = new google.maps.places.AutocompleteService();
    const types = ['(regions)'];
    return autocomplete.getPlacePredictions({ input, types });
  }

  getLocationDetailAddressComponents$(placeId: string): Promise<SmartCampaign.Backend.AddressComponentItem[]> {
    if (!this._placeService) throw new Error(`PlaceService was not initialized.`);

    return new Promise((res, rej) => {
      this._placeService!.getDetails({ placeId }, (o) => {
        if (!o) rej();

        const result = toAddressComponentByGoogleModel(o!.address_components!);

        res(result);
      });
    });
  }
}

export function toAddressComponentByGoogleModel(addressComponent: google.maps.GeocoderAddressComponent[]) {
  return pipe(
    addressComponent,
    rename('[]>.long_name', 'longName'),
    rename('[]>.short_name', 'shortName')
  ) as unknown as SmartCampaign.Backend.AddressComponentItem[];
}
