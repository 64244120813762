import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { FacebookCreateAd } from '@core/ad-platforms';
import { Draft, Facebook } from '@core/models';
import { CreateAnAdSteps } from '@pages/create-ad/models';

import { SaveDraft } from '../base';

@Injectable({ providedIn: 'root' })
export class FacebookStateService extends SaveDraft<FacebookCreateAd.ICreateAdModel> {
  createAd = new FacebookCreateAd.CreateAd();

  adCreationModel$ = new BehaviorSubject(this.createAd.createAdModel);

  get adCreationModel() {
    return this.adCreationModel$.value;
  }

  constructor() {
    super();
  }

  setAdModel(value: FacebookCreateAd.ICreateAdModel) {
    this.adCreationModel$.next(value);
    this.createAd.createAdModel = value;
  }

  reset() {
    this.createAd = new FacebookCreateAd.CreateAd();
    this.setAdModel(this.createAd.createAdModel);
    this.draftModel = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;
  }

  changeAdType(adType: Facebook.AdType) {
    const changedModel = this.createAd.changeType!('ad', adType);

    this.adCreationModel.selectedTypes.ad = adType;

    this.setAdModel(changedModel);
  }

  updateActiveStep(step: CreateAnAdSteps) {
    this.adCreationModel.activeStep = step;
  }

  mapToApiRequest() {
    const selectedAdType = this.adCreationModel.selectedTypes.ad;

    if (!selectedAdType) return;

    return this.createAd.mapToApiRequest(selectedAdType);
  }
}
