import { ToastrService } from 'ngx-toastr';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { ApolloError } from '@apollo/client/errors';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector, private zone: NgZone) {}

  handleError(error: Error) {
    const toastrService = this.injector.get(ToastrService);

    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        toastrService.warning('Internet connection disconnected.', 'Warning');
      }
    }

    if (error instanceof ApolloError) {
      console.error(error.networkError);
    } else {
      console.error(error);
    }
  }
}
