import { Component } from '@angular/core';
import { IntercomService } from '@core/services/intercom.service';

@Component({
  templateUrl: './first-brand-created.component.html'
})
export class FirstBrandCreatedComponent {
  videoId = '817578348';

  constructor(private intercomService: IntercomService) {}

  guideMe() {
    this.intercomService.startTour('CREATE_AD_STEP_1');
  }

  skipAdTours() {
    this.intercomService.updateIntercomState({
      CREATE_AD_STEP_1: true,
      CREATE_AD_STEP_2: true,
      CREATE_AD_STEP_3: true,
      CREATE_AD_STEP_4: true,
      CREATE_AD_STEP_5: true
    });
  }
}
