import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-select-audience--smart-campaign',
  templateUrl: 'select-audience-content.component.html'
})
export class SelectAudienceContentSmartCampaignComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
