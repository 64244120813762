import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-existing-post-list',
  template: ` <ul class="existing-post--list">
    <ng-content select="aayn-existing-post-list-item"></ng-content>
  </ul>`
})
export class ExistingPostListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
