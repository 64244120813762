import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getLinkedinAdAccountId, LinkedinService, mapDefined, switchMapDefined } from '@core/index';
import { Linkedin } from '@core/models';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { filter, mergeAll, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--linkedin-form',
  templateUrl: './form.component.html'
})
export class ConversionMyWebsiteSMLinkedinFormComponent implements OnInit {
  @Input() form?: FormGroup;

  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected conversionEvents: Linkedin.LinkedInConversion[] = [];
  protected selectedConversionEvent?: Linkedin.LinkedInConversion;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private linkedinService: LinkedinService,
    private store: Store
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;
    });

    this.getConversionEvents();
  }

  getConversionEvents() {
    of(getLinkedinAdAccountId(this.store))
      .pipe(
        switchMapDefined((accountId) => this.linkedinService.findConversionsByAccount(accountId)),
        mapDefined((result) => result.edges.map((it) => it.node)),
        tap((it) => (this.conversionEvents = it)),
        mergeAll(),
        filter((it) => it.id === this.adCreationModel.campaign.platforms?.linkedin?.conversionType?.id),
        take(1),
        tap((conversionEvent) => this.form!.patchValue({ conversionEvent }))
      )
      .subscribe();
  }

  onChangedSelectedConversionEvent($event?: Linkedin.LinkedInConversion) {
    if (!$event) return;

    this.adCreationModel.campaign.platforms!.linkedin!.conversionType = $event;
  }
}
