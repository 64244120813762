import { Component, Input } from '@angular/core';
import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import { IconModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'aayn-ad-preview-search-ads',
  templateUrl: './search-ads.component.html',
  imports: [NgForOf, NgIf, IconModule, SlicePipe, TranslateModule],
  standalone: true
})
export class SearchAdsComponent {
  @Input() linkUrl = '';
  @Input() headlines?: Array<string> = [];
  @Input() description = '';

  linkUrlPlaceholder = 'https://your-url.com';

  get titles() {
    return this.headlines?.filter(Boolean).slice(0, 3) || [];
  }
}
