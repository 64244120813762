import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { FormFieldComponent, InputDirective } from '@ayn-ui/lib/modules';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs/operators';
import { OpenAI } from '@core/models';
import { TypewriteService } from '@shared/services/typewrite.service';
import { TextAiContainerService } from '@shared/services/text-ai-container.service';
import { HasPreviewIcon } from '@shared/models';

@Component({
  selector: 'aayn-create-ad-input',
  templateUrl: './create-ad-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CreateAdInputComponent,
      multi: true
    },
    {
      provide: FormFieldComponent,
      useExisting: CreateAdInputComponent
    },
    TypewriteService
  ]
})
export class CreateAdInputComponent
  implements ControlValueAccessor, OnChanges, OnInit, OnDestroy, AfterViewInit, HasPreviewIcon
{
  @ViewChild(InputDirective, { static: false }) input?: InputDirective;
  @ViewChild('valueInput', { static: false }) inputElem!: ElementRef<HTMLInputElement>;

  @Input() placeholder = '';

  @Input() inputId = '';

  @Input() maxCharacterNumber!: number;

  @Input() requiredMessage = 'Required field.';

  @Input() textAiSection?: keyof OpenAI.GeneratedTextsOutputDto;

  @Input() previewVisible = true;

  @Input() textCraftUsable = true;

  @Input() previewActive = false;

  @Input() inputType: 'input' | 'textarea' = 'input';

  @Output() previewActiveChange = new EventEmitter<boolean>();

  @Output() previewValueChange = new EventEmitter<string>();

  @Output() inputBlur = new EventEmitter<void>();

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.valueHolder = value;
  }

  _value!: string;

  valueHolder!: string;

  disabled = false;

  onChange: any = () => {};

  onTouched: any = () => {};

  brainActive = false;

  loader$ = new BehaviorSubject(false);

  buttonClick = false;

  destroy$ = new Subject();

  get isRequired() {
    return this.control?.validator?.({} as AbstractControl)?.required;
  }

  get invalid() {
    return (this.control?.dirty || this.formGroupDirective?.submitted) && this.control?.invalid;
  }

  get errors() {
    return this.control?.errors
      ? combineLatest(Object.entries(this.control.errors).map(([key, value]) => this.errorMessage(key, value))).pipe(
          map((errors) => errors.join('\n'))
        )
      : of('');
  }

  get control() {
    return this.injector.get(NgControl, { control: {} })?.control as AbstractControl;
  }

  get validator() {
    return this.control?.validator?.({} as AbstractControl);
  }

  get length() {
    return this.input?.inputLength || 0;
  }

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private typewriteService: TypewriteService,
    public textAiContainerService: TextAiContainerService,
    @Optional() private formGroupDirective?: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loader$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      if (loading) {
        this.brainActive = false;
      }
    });
  }

  onInputBlur() {
    this.inputBlur.emit();
  }

  toggleActive() {
    this.previewActive = true;
    this.previewActiveChange.emit(this.previewActive);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  notifyValueChange() {
    this.control.updateValueAndValidity();
    this.onChange(this.value);
    if (this.previewActive) {
      this.previewValueChange.emit(this.value);
    }
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  valueChange(value: string) {
    if (this.valueHolder === this.value) {
      this.value = value;
      this.notifyValueChange();
    }
  }

  fill(value: string) {
    return this.typewriteService.typeWrite$(
      value,
      (value) => {
        this.value = value;
        this.inputElem.nativeElement.focus();
        this.inputElem.nativeElement.selectionStart = this.inputElem.nativeElement.selectionEnd = value.length;
      },
      1
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const maxCharacterNumber = changes?.maxCharacterNumber?.currentValue;

    if (this.input && maxCharacterNumber) {
      this.input.el.nativeElement.maxLength = maxCharacterNumber as number;
    }
  }

  private errorMessage(error: string, value: any): Observable<string> {
    switch (error) {
      case 'required':
        return this.translateService.get(this.requiredMessage);
      case 'minlength':
        return this.translateService.get('Must be a minimum of {requiredLength} characters.', {
          requiredLength: value.requiredLength
        });
      case 'maxlength':
        return this.translateService.get('Must be a maximum of {requiredLength} characters.', {
          requiredLength: value.requiredLength
        });
      case 'email':
        return this.translateService.get('Email format must be correct.');
      case 'min':
        return this.translateService.get('Must be at least {min}.', { min: value.min });
      case 'max':
        return this.translateService.get('Must be at most {max}.', { max: value.max });
      case 'isUniqueControls':
        return this.translateService.get('The texts must be different from each other.');
      case 'pattern':
        return this.translateService.get('Please enter in the appropriate format.');

      default:
        return of('');
    }
  }

  toggleTextCraft($event: MouseEvent) {
    this.brainActive = !this.brainActive;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {}

  textCraftValueChange(value: string) {
    this._value = value;
  }

  textCraftValueSave(value: string) {
    this.value = value;
    this.notifyValueChange();
  }
}
