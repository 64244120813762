import { SmartCampaign, SmartCampaignPlatform, SmartCampaignPlatforms } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = SmartCampaign.Backend.SmartCampaignSeperatedLocationResultDto;

const platformsObject = {
  [SmartCampaignPlatform.Meta]: `facebook 
          {  
            platformSpecificName
            key
            type
            countryCode
            countryCodes
            countryName
            region
            regionId
            primaryCity
            primaryCityId 
          }`,
  [SmartCampaignPlatform.LinkedIn]: `linkedIn { platformSpecificName }`,
  [SmartCampaignPlatform.Google]: `google { platformSpecificName }`,
  [SmartCampaignPlatform.Bing]: `bing { platformSpecificName }`
};

export const getSeperatedLocations = (selectedPlatforms: SmartCampaignPlatform[], params: string) =>
  makeQuery<Output>(`
  query GetSeperatedLocations {
    ayn {
      audience {
        getSeperatedLocations(params: { keyword: ${params} }) { 
        {
          failures {
           ${selectedPlatforms.map((o) => o).join(' ')}
          }
          statuses {
            ${selectedPlatforms.map((o) => o).join(' ')}
          }
          results {
            ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
          }
        }
      }
    }
  }
`);
