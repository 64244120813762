import { IEventResponse } from '@core/models';
import { makeSubscription } from '../types';

type Output = IEventResponse;

export const event = makeSubscription<Output>(`
subscription {
  event {
    eventName
    data
  }
}
`);
