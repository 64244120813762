import { Injectable } from '@angular/core';
import { FormListener } from '@core/utils';

import { BingStateService } from './bing.state';

@Injectable({ providedIn: 'root' })
export class BingFormService extends FormListener {
  constructor(private bingStateService: BingStateService) {
    super();

    this.subscribeForms(() => {
      this.bingStateService.saveDraft$();
    });
  }
}
