import { Settings } from '@core/models';

import { makeMutation } from '../types';

type Input = { brandingInput: Partial<Settings.BrandingInput> };
type Output = Settings.BrandingOutput;

export const updateCustomBranding = makeMutation<Output, Input>(`
mutation UpdateCustomBranding($brandingInput: PartialBrandingInputDto!) {
  updateCustomBranding(brandingInput: $brandingInput) {
    mainColor
    additionalColor
    paragraphColor
    backgroundColor
  }
}
`);
