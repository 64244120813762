import { makeMutation } from '../types';

type Input = {
  coupon: string;
};
type Output = boolean;

export const applyCoupon = makeMutation<Output, Input>(`
   mutation ApplyCoupon($coupon: String!) {
    applyCouponCode(coupon: $coupon)
  }
`);
