import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdAccount,
  AdAccountState,
  BingService,
  ConnectAdAccount,
  ConnectAdAccountMap,
  FacebookService,
  GoogleService,
  Linkedin,
  LinkedinService,
  PlatformAdAccountMap,
  WindowService
} from '@core/index';
import { ContextStatus, IPlatformContext, Platforms } from '@core/models';
import { BusinessState } from '@core/state/business.state';
import { PlatformContextState } from '@core/state/platform-context.state';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { TiktokService } from '@core/services/tiktok.service';
import { forkJoin, Observable, of } from 'rxjs';
import {
  mapBingAdAccount,
  mapGoogleAdAccount,
  mapLinkedinAdAccount,
  mapMetaAdAccount,
  mapTikTokAdAccount
} from '@shared/utils/ad-account-map';

export type ObservableData<T> = T extends Observable<infer U> ? U : never;

export type ConnectAdAccountReturn<T extends AdAccount.AdAccountPlatforms> = Observable<
  {
    [Platforms.Meta]: ObservableData<ReturnType<FacebookService['connectAdAccount$']>>;
    [Platforms.TikTok]: ObservableData<ReturnType<TiktokService['connectAdAccount$']>>;
    [Platforms.Google]: ObservableData<ReturnType<GoogleService['connectAdAccount$']>>;
    [Platforms.Bing]: ObservableData<ReturnType<BingService['connectAdAccount$']>>;
    [Platforms.LinkedIn]: ObservableData<ReturnType<LinkedinService['connectAdAccount']>>;
  }[T]
>;
@Injectable({ providedIn: 'root' })
export class SharedAdAccountService {
  constructor(
    private store: Store,
    private router: Router,
    private facebookService: FacebookService,
    private googleService: GoogleService,
    private bingService: BingService,
    private linkedinService: LinkedinService,
    private tiktokService: TiktokService,
    private windowService: WindowService
  ) {}

  updateBusiness(platform: Platforms) {
    const platformContext = this.store.selectSnapshot(PlatformContextState.getPlatformContext(platform));
    this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped);
    if (platformContext?.status === ContextStatus.Connected) {
      this.router.navigate([
        {
          outlets: {
            dialog: `ad-account-select-modals/${Platforms[platform]}`
          }
        }
      ]);
    } else {
      this.openAuthWindowByPlatform(platformContext);
    }
  }

  openAuthWindowByPlatform(platform?: IPlatformContext) {
    if (platform) {
      const streamMap = {
        [Platforms.Meta]: this.facebookService
          .getOAuthUri$(platform.id)
          .pipe(map((oAuthUrl) => oAuthUrl.data.oauth.oauthUrl)),
        [Platforms.Google]: this.googleService.getOAuthUri(platform.id).pipe(map((oAuthUrl) => oAuthUrl?.oauthUrl)),
        [Platforms.Bing]: this.bingService.getOAuthUri$(platform.id).pipe(map((oAuthUrl) => oAuthUrl?.oauthUrl)),
        [Platforms.LinkedIn]: this.linkedinService.getOAuthUri(platform.id).pipe(map((oAuthUrl) => oAuthUrl?.oauthUrl)),
        [Platforms.TikTok]: this.tiktokService.getOAuthUri$(platform.id).pipe(map((oAuthUrl) => oAuthUrl?.oauthUrl))
      };
      streamMap[platform.source].subscribe((oauthUrl) => this.openWindow(`${oauthUrl}`));
    }
  }

  getPlatformAdAccounts<T extends AdAccount.AdAccountPlatforms>(
    platform: T,
    mapWithoutSelect?: boolean
  ): Observable<PlatformAdAccountMap<T>[]> {
    const { Meta, Google, Bing } = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped);
    const selectedAdAccount = this.store.selectSnapshot(AdAccountState.selectedPlatformAdAccount(platform));
    const connectedAdAccount = this.store.selectSnapshot(BusinessState.SelectedBusinessAdAccountByPlatform(platform));
    const adAccountId = mapWithoutSelect ? '' : selectedAdAccount?.adAccountId || connectedAdAccount?.externalId || '';
    switch (platform) {
      case Platforms.Meta:
        return this.facebookService
          .getAdAccounts$(Meta.id)
          .pipe(
            map((res) => mapMetaAdAccount(res.data?.adAccounts.edges || [], adAccountId) as PlatformAdAccountMap<T>[])
          );
      case Platforms.Google:
        return this.googleService
          .getAdAccounts(Google.id)
          .pipe(map((res) => mapGoogleAdAccount(res || [], adAccountId) as PlatformAdAccountMap<T>[]));
      case Platforms.Bing:
        return this.bingService
          .getAdAccounts$(Bing.id)
          .pipe(map((res) => mapBingAdAccount(res || [], adAccountId) as PlatformAdAccountMap<T>[]));
      case Platforms.LinkedIn:
        return this.linkedinService
          .getAdAccounts({
            statuses: [Linkedin.LinkedInAdAccountStatus.Active],
            after: '',
            first: 10
          })
          .pipe(map((res) => mapLinkedinAdAccount(res?.edges || [], adAccountId) as PlatformAdAccountMap<T>[]));
      case Platforms.TikTok:
        return this.tiktokService
          .getAdAccounts$()
          .pipe(map((res) => mapTikTokAdAccount(res || [], adAccountId) as PlatformAdAccountMap<T>[]));

      default:
        return of([]);
    }
  }

  private openWindow(url: string) {
    const w = this.windowService.openCenteredWindow(url, '', 470, 690);

    return w;
  }

  connectAdAccounts(adAccounts: ConnectAdAccount[]) {
    return forkJoin(adAccounts.map((p) => this.connectAdAccount(p.platform, p)));
  }

  connectAdAccount<T extends AdAccount.AdAccountPlatforms>(
    platform: T,
    adAccount: ConnectAdAccountMap<T>
  ): ConnectAdAccountReturn<T> {
    const platformContexts = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped);
    const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    const isConnected = selectedBusiness?.connectedAdAccounts?.some(
      (connectAdAccount) =>
        connectAdAccount.source === platform && String(connectAdAccount.externalId) === String(adAccount.adAccountId)
    );
    if (isConnected) {
      return of({}) as ConnectAdAccountReturn<T>;
    }
    switch (platform) {
      case Platforms.Meta:
        return this.facebookService.connectAdAccount$({
          adAccountId: adAccount.adAccountId,
          pageId: (adAccount as ConnectAdAccountMap<Platforms.Meta>).pageId,
          contextId: platformContexts.Meta.id,
          instagramAccountId: (adAccount as ConnectAdAccountMap<Platforms.Meta>).instagramAccountId
        }) as ConnectAdAccountReturn<T>;
      case Platforms.Google:
        return this.googleService.connectAdAccount$({
          adAccountId: adAccount.adAccountId,
          loginId: (adAccount as ConnectAdAccountMap<Platforms.Google>).loginId,
          contextId: platformContexts.Google.id
        }) as ConnectAdAccountReturn<T>;
      case Platforms.Bing:
        return this.bingService.connectAdAccount$({
          adAccountId: adAccount.adAccountId,
          contextId: platformContexts.Bing.id,
          loginId: (adAccount as ConnectAdAccountMap<Platforms.Bing>).loginId
        }) as ConnectAdAccountReturn<T>;
      case Platforms.LinkedIn:
        return this.linkedinService.connectAdAccount({
          adAccountId: adAccount.adAccountId,
          contextId: platformContexts.LinkedIn.id,
          loginId: (adAccount as ConnectAdAccountMap<Platforms.Bing>).loginId
        }) as ConnectAdAccountReturn<T>;
      case Platforms.TikTok:
        return this.tiktokService.connectAdAccount$({
          adAccountId: adAccount.adAccountId,
          contextId: platformContexts.TikTok.id,
          loginId: (adAccount as ConnectAdAccountMap<Platforms.TikTok>).loginId,
          pageId: (adAccount as ConnectAdAccountMap<Platforms.TikTok>).pageId
        }) as ConnectAdAccountReturn<T>;
      default:
        throw new Error('Platform not supported');
    }
  }
}
