import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aayn-friend-invite',
  templateUrl: 'friend-invite.component.html'
})
export class FriendInviteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
