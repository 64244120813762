<div class="aayn-select-platform-item {{ status }}" [ngClass]="{ selected }" (click)="this.btnClick.emit($event)">
  <div class="aayn-select-platform-item--status">
    <ayn-icon class="aayn-select-platform-item--status__icon" *ngIf="status" [name]="status"></ayn-icon>
    <span class="aayn-select-platform-item--status__text">{{ status | aynTitleCase }}</span>
  </div>

  <ayn-icon [name]="platform.platform"></ayn-icon>

  <h4 class="aayn-select-platform-item--title">{{ platform.name }} Ads</h4>

  <p class="aayn-select-platform-item--description">
    {{ description }}
  </p>
</div>
