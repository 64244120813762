import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from '@ayn-ui/public-api';
import { Facebook } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { Store } from '@ngxs/store';
import { Observable, ReplaySubject } from 'rxjs';
import { CustomVirtualScrollStrategy } from '../account-ad-account-select';
import { CdkTableService } from '@shared/components/account-select-modal/services/cdk-table.service';

@Component({
  selector: 'aayn-account-page-select',
  templateUrl: './account-page-select.component.html',
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }, CdkTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountPageSelectComponent implements AfterViewInit, OnChanges {
  @Input() selectedPageAccount?: Facebook.IPage;

  @Input() socialAccounts: IOption[] = [];
  @Input() socialAccountId = '';

  @Input() pages: Facebook.IPage[] = [];

  @Output() onSelectPage = new EventEmitter<Facebook.IPage | undefined>();

  @Output() onSocialAccountChange = new EventEmitter<string>();

  pageSelectForm = new FormGroup({
    account: new FormControl('', [Validators.required])
  });

  @Input()
  loader$?: Observable<boolean> = this.store.select(LoaderState.getAny('GetAdAccounts'));

  @ViewChildren(CdkVirtualScrollViewport) viewport!: QueryList<CdkVirtualScrollViewport>;

  calculateHeight$ = new ReplaySubject(1);

  constructor(private store: Store, private cdkTableService: CdkTableService) {}

  ngAfterViewInit(): void {
    this.cdkTableService.registerViewport(this.viewport, this.calculateHeight$, () =>
      this.pages.filter((page) => this.selectedPageAccount?.id !== page.id)
    );
    this.cdkTableService.listen();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.socialAccounts) {
      const socialAccounts = changes.socialAccounts.currentValue as IOption[];

      if (!socialAccounts?.length) {
        this.pageSelectForm.patchValue({ account: '' });
        return;
      }

      this.pageSelectForm.patchValue({ account: socialAccounts[0].value as string });
      this.pageSelectForm.updateValueAndValidity();
    }
    if (changes.socialAccountId) {
      this.pageSelectForm.patchValue({ account: changes.socialAccountId.currentValue });
      this.pageSelectForm.updateValueAndValidity();
    }
  }

  public selectPage(page: Facebook.IPage): void {
    this.selectedPageAccount = page;

    this.calculateHeight$.next();
    this.onSelectPage.emit(page);
  }

  public getButtonName(page: Facebook.IPage): string {
    if (this.selectedPageAccount?.id == page.id) {
      return 'Selected';
    }

    return 'Select';
  }

  public removeSelectPage(): void {
    this.selectedPageAccount = undefined;

    this.calculateHeight$.next();
    this.onSelectPage.emit(undefined);
  }
}
