import { Component } from '@angular/core';
import { Platforms, SmartCampaign } from '@core/models';
import { mapAdTypes, selectedItem } from '@pages/create-ad/components/select-objective/utils';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { AdPreviewSmartCampaignService } from './components/ad-preview/ad-preview.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { INPUT_LENGTH_CALCULATOR, LengthCalculator } from '@ayn-ui/lib/modules';
import { FACEBOOK_INPUT_LENGTH_CALCULATOR } from '@pages/create-ad/components/select-adtype/platforms/facebook/select-ad-type-content.component';
import {
  GOOGLE_INPUT_LENGTH_CALCULATOR,
  TIKTOK_INPUT_LENGTH_CALCULATOR
} from '@pages/create-ad/components/select-adtype/platforms';
import { BING_INPUT_LENGTH_CALCULATOR } from '@pages/create-ad/components/select-adtype/platforms/bing';

const AD_TYPES__SMART_CAMPAIGN: ICreateAdSelectItem<SmartCampaign.Client.AdType>[] = [
  {
    id: 1,
    title: 'Default',
    description: '',
    icon: 'dynamic',
    empty: false,
    active: true,
    type: SmartCampaign.Client.AdType.Default
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: 'carousel',
    empty: true,
    active: false
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: 'existing-post',
    empty: true,
    active: false
  }
];

const SMART_CAMPAIGN_INPUT_LENGTH_CALCULATOR: (state: SmartCampaignStateService) => LengthCalculator = (
  state: SmartCampaignStateService
) => {
  return (val: string) => {
    if (!val.length) {
      return 0;
    }
    const calculatorsByPlatform = {
      [Platforms.Meta]: FACEBOOK_INPUT_LENGTH_CALCULATOR,
      [Platforms.Google]: GOOGLE_INPUT_LENGTH_CALCULATOR,
      [Platforms.Bing]: BING_INPUT_LENGTH_CALCULATOR,
      [Platforms.TikTok]: TIKTOK_INPUT_LENGTH_CALCULATOR
    };
    return state.adCreationModel.selectedPlatforms.reduce((count, platform) => {
      if (calculatorsByPlatform[platform]) {
        const length = calculatorsByPlatform[platform](val);
        return count > length ? count : length;
      }
      return count;
    }, val.length);
  };
};

@UntilDestroy()
@Component({
  selector: 'aayn-select-ad-type--smart-campaign',
  templateUrl: 'select-ad-type-content.component.html',
  providers: [
    AdPreviewSmartCampaignService,
    {
      provide: INPUT_LENGTH_CALCULATOR,
      useFactory: SMART_CAMPAIGN_INPUT_LENGTH_CALCULATOR,
      deps: [SmartCampaignStateService]
    }
  ]
})
export class SelectAdTypeContentSmartCampaignComponent {
  adTypes$ = this.smartCampaignStateService.adCreationModel$.pipe(
    mapAdTypes(AD_TYPES__SMART_CAMPAIGN, 'ad'),
    selectedItem((ad) => {
      if (ad.type) {
        this.selectedAdType = ad.type;
      }
    })
  );

  protected AdTypes = SmartCampaign.Client.AdType;

  protected selectedAdType: SmartCampaign.Client.AdType = SmartCampaign.Client.AdType.Default;

  constructor(private smartCampaignStateService: SmartCampaignStateService) {}

  adTypeChangeStatus(item: ICreateAdSelectItem<SmartCampaign.Client.AdType>) {
    if (this.smartCampaignStateService.adCreationModel.selectedTypes.ad !== item.type) {
      this.smartCampaignStateService.changeAdType(item.type!);
    }
  }
}
