import { Settings } from '@core/models';

import { makeMutation } from '../types';

type Input = Settings.IChangeUserPasswordRequest;
type Output = Settings.IChangeUserPasswordResponse;

export const changeUserPassword = makeMutation<Output, Input>(`
  mutation changeUserPasswordUnsafe($password: String!, $passwordAgain: String!) {
    changeUserPasswordUnsafe(input: { password: $password, passwordAgain: $passwordAgain }) {
      accessToken
      refreshToken
    }
  }
`);
