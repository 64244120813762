import { Draft } from '@core/models';

import { makeQuery } from '../types';

type Input = Draft.IGetDraftsRequest;
type Output = Draft.IDraftItem[];

export const getDrafts = makeQuery<Output, Input>(`
 query GetDrafts(
    $platforms: [ Int! ]
    $name: String
    $sort: AdDraftWithName_Id_Createdat_Updatedat_SourceOrdering
  ) {
    drafts(
      name: $name
      sort: $sort
      platforms: $platforms
    ) {
      id
      createdAt
      updatedAt
      name
      businessId
      content
      source
      adAccountId
    }
  }
`);
