import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = { contextId: string; name?: string };
type Output = {
  adAccounts: {
    edges: {
      node: Facebook.IAdAccount;
    }[];
  };
};

export const getAdAccounts = makeQuery<Output, Input>(`
query GetAdAccounts($contextId: String!) {
  facebook {
    adAccounts(contextId: $contextId) {
      edges {
        cursor
        node {
          id
          name
          accountStatus
          currency
        }
      }
    }
  }
}
`);
