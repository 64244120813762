import { Observable } from 'rxjs';
import { switchMapMultiple } from './switch-map-multiple';

/**
 * Returns an observable from an array of observables that ordered with switchMap.
 * @param streams {Array<Observable<T>>} The array of observables.
 *
 * @example
 * const streams = [of(1, 2, 3), of(4, 5, 6), of(7, 8, 9)];
 * const example = createOrderedStream(streams);
 * example.subscribe(val => console.log(val));
 * //7, 8, 9
 */
export function createOrderedStream<T>(streams: Array<Observable<T>>): Observable<T> {
  const [first, ...rest] = streams;
  return first.pipe(switchMapMultiple(rest));
}
