import { Google } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Google.CreateAd.IGoogleAudienceRequest;
type Output = Google.CreateAd.IGoogleAudienceResponse;

export const audienceSearch = makeMutation<Output, Input>(`
query AudienceSearch($number: Float, $searchKey: String) {
  google {
    audienceSearch(number: $number, searchKey: $searchKey) {
      id
      name
      description
    }
  }
}

`);
