import { Observable, OperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

export function tapWhen<T>(predicate: (value: T) => boolean, func: (value: T) => void): OperatorFunction<T, T> {
  return function _tapWhen(source: Observable<T>) {
    return source.pipe(
      tap<T>((value) => {
        if (predicate(value)) {
          func(value);
        }
      })
    );
  };
}
