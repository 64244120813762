import { environment } from '@environment';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay } from '@ayn-ui/lib/modules';
import { TikTok, TiktokService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { IDetailedTargetingOverlayItemActive } from '@pages/create-ad/components/shared/manuel-audience-setup/components/detailed-targeting/components/detailed-targeting-overlay-item/detailed-targeting-overlay-item.component';
import { TiktokStateService } from '@pages/create-ad/state';
import { TabValidateFn, TabValidateStatus } from '@shared/directives';
import { UntilDestroy } from '@ngneat/until-destroy';

type TargetingTypes = 'interests' | 'keywords' | 'customAudiences';

@UntilDestroy()
@Component({
  selector: 'aayn-detailed-targeting--tiktok',
  templateUrl: './detailed-targeting.component.html'
})
export class DetailedTargetingTiktokComponent implements OnInit {
  @Input() form?: FormGroup;

  @ViewChild(Overlay) overlay!: Overlay;

  validateTrigger$ = new ReplaySubject<void>();

  protected activeTab: TargetingTypes = 'interests';

  protected detailedTargetingSearchControl = new FormControl('', [
    Validators.minLength(1),
    (_) => {
      return this.selectedInterests?.length ? null : { required: true };
    }
  ]);

  protected interests: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[] = [];
  protected keywords: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[] = [];
  protected customAudiences: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[] = [];

  @Select(LoaderState.getAny(['InterestsSearch', 'TikTokKeywordsSearch', 'TikTokCustomAudienceSearch']))
  loader$?: Observable<boolean>;

  protected adCreationModel = this.tiktokStateService.adCreationModel;

  get _inclusions() {
    return [
      ...this.addItemType(this.adCreationModel.audience.interests, 'interests'),
      ...this.addItemType(this.adCreationModel.audience.keywords, 'keywords'),
      ...this.addItemType(this.adCreationModel.audience.includedCustomAudiences, 'customAudiences')
    ];
  }

  get _exclusions() {
    return this.adCreationModel.audience.excludedCustomAudiences || [];
  }

  get selectedInterests() {
    return this.form?.value.interests;
  }

  validateTabInterest: TabValidateFn = (tabItem) => {
    if (tabItem.index !== 0) {
      return TabValidateStatus.Raw;
    }

    if (!this.detailedTargetingSearchControl.dirty && !this._inclusions?.length) {
      return TabValidateStatus.Raw;
    }
    return this.selectedInterests?.length ? TabValidateStatus.Valid : TabValidateStatus.Invalid;
  };

  constructor(
    private tiktokService: TiktokService,
    private cdr: ChangeDetectorRef,
    private tiktokStateService: TiktokStateService
  ) {}

  ngOnInit() {
    this.tiktokStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.detailedTargetingSearchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        if (this.detailedTargetingSearchControl.invalid && !term?.length) return;
        this.searchTargeting(term);
      });
  }

  searchTargeting(searchValue: string | null) {
    if (this.activeTab === 'interests') {
      this.getInterestCategories(searchValue || '');
    } else if (this.activeTab === 'keywords') {
      if (searchValue) {
        this.getKeywords(searchValue);
      }
    } else if (this.activeTab === 'customAudiences') {
      this.getCustomAudiences();
    }
  }

  getInterestCategories(searchKey: string) {
    this.tiktokService.interestSearch$({ searchKey, limit: 10 }).subscribe((result) => {
      try {
        this.interests = result || [];

        if (!this.overlay?.render) this.overlay.show(null);

        this.cdr.detectChanges();
      } catch (error) {
        !environment.production && console.log(`[CreateAd.Tiktok.DetailTargeting]: `, error);
      }
    });
  }

  getKeywords(searchKey: string) {
    this.tiktokService.keywordSearch$({ searchKey }).subscribe((result) => {
      try {
        this.keywords = result || [];

        if (!this.overlay?.render) this.overlay.show(null);

        this.cdr.detectChanges();
      } catch (error) {
        !environment.production && console.log(`[CreateAd.Tiktok.DetailTargeting]: `, error);
      }
    });
  }

  getCustomAudiences() {
    if (this.customAudiences.length) return;
    this.tiktokService.customAudienceSearch().subscribe((result) => {
      try {
        this.customAudiences = result || [];

        if (!this.overlay?.render) this.overlay.show(null);

        this.cdr.detectChanges();
      } catch (error) {
        !environment.production && console.log(`[CreateAd.Tiktok.DetailTargeting]: `, error);
      }
    });
  }

  protected onIncludeClick(detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto) {
    if (this.activeTab === 'interests') {
      this.form!.patchValue({
        interests: [...(this.adCreationModel.audience.interests || []), detailedTargeting]
      });
    } else if (this.activeTab === 'keywords') {
      this.form!.patchValue({
        keywords: [...(this.adCreationModel.audience.keywords || []), detailedTargeting]
      });
    } else if (this.activeTab === 'customAudiences') {
      this.form!.patchValue({
        includedCustomAudiences: [...(this.adCreationModel.audience.includedCustomAudiences || []), detailedTargeting]
      });
    }
    this.updateValidity();
  }

  onIncludeRemove(detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto) {
    if (this.activeTab === 'interests') {
      this.form!.patchValue({
        interests: this.adCreationModel.audience.interests?.filter((o) => o.id !== detailedTargeting.id)
      });
    } else if (this.activeTab === 'keywords') {
      this.form!.patchValue({
        keywords: this.adCreationModel.audience.keywords?.filter((o) => o.id !== detailedTargeting.id)
      });
    } else if (this.activeTab === 'customAudiences') {
      this.form!.patchValue({
        includedCustomAudiences: this.adCreationModel.audience.includedCustomAudiences?.filter(
          (o) => o.id !== detailedTargeting.id
        )
      });
    }
    this.updateValidity();
  }

  private getArrayNameByType(type: TargetingTypes) {
    const propertyName = type === 'customAudiences' ? 'includedCustomAudiences' : type;
    return this.adCreationModel.audience[propertyName] as TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[];
  }

  protected onCloseClick(
    detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto & { itemType: TargetingTypes }
  ) {
    const originalIndex = this.getArrayNameByType(detailedTargeting.itemType).findIndex(
      (o) => o.name === detailedTargeting.name
    );

    this.getArrayNameByType(detailedTargeting.itemType).splice(originalIndex, 1);

    this.form!.patchValue({
      [detailedTargeting.itemType]: this.getArrayNameByType(detailedTargeting.itemType)
    });
    this.updateValidity();
  }

  onCloseExclusions(detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto) {
    const originalIndex = this.adCreationModel.audience.excludedCustomAudiences?.findIndex(
      (o) => o.name === detailedTargeting.name
    );
    if (originalIndex !== -1 && typeof originalIndex === 'number') {
      this.adCreationModel.audience.excludedCustomAudiences!.splice(originalIndex, 1);
      this.form!.patchValue({
        excludedCustomAudiences: this.adCreationModel.audience.excludedCustomAudiences
      });
    }
  }

  protected isActive(
    targetingType: TargetingTypes,
    detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto
  ) {
    const { audience } = this.adCreationModel;
    const hasAddedInclusions = audience.interests?.some((o) => o.id === detailedTargeting.id);
    let isActive = false;
    let type = 'includes';
    switch (targetingType) {
      case 'interests':
        isActive = audience.interests?.some((o) => o.id === detailedTargeting.id) || false;
        break;
      case 'keywords':
        isActive = audience.keywords?.some((o) => o.id === detailedTargeting.id) || false;
        break;
      case 'customAudiences':
        isActive =
          [...(audience.includedCustomAudiences || []), ...(audience.excludedCustomAudiences || [])].some(
            (o) => o.id === detailedTargeting.id
          ) || false;
        if (isActive) {
          type = audience.includedCustomAudiences?.some((o) => o.id === detailedTargeting.id) ? 'includes' : 'excludes';
        }
    }
    return {
      isActive,
      type
    } as IDetailedTargetingOverlayItemActive;
  }

  changeActiveTab(tab: TargetingTypes) {
    this.activeTab = tab;
    this.searchTargeting(this.detailedTargetingSearchControl.value);
  }

  onCustomAudienceExclude(detailedTargeting: TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto) {
    this.form!.patchValue({
      excludedCustomAudiences: [...(this.adCreationModel.audience.excludedCustomAudiences || []), detailedTargeting]
    });
  }

  showList(overlay: Overlay, $event: MouseEvent) {
    overlay.show($event);
    this.validateTrigger$.next();
  }

  updateValidity() {
    this.validateTrigger$.next();
    this.form?.updateValueAndValidity();
    this.detailedTargetingSearchControl.updateValueAndValidity();
  }

  private addItemType<T>(array: T[] | undefined, itemType: TargetingTypes) {
    return (array || []).map((o) => ({ ...o, itemType }));
  }
}
