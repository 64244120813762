import { Component, Input } from '@angular/core';
import { Google } from '@core/models';

@Component({
  selector: 'aayn-ad-preview--google',
  templateUrl: './ad-preview.component.html'
})
export class AdPreviewGoogleComponent {
  @Input() selectedAdType: Google.AdType = Google.AdType.PerformanceMax;

  protected AdTypes = Google.AdType;
}
