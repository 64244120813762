import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SmartCampaign } from '@core/models';

@Component({
  selector: 'aayn-gender-selection--smart-campaign',
  template: `
    <section class="aayn-audience--gender-selection">
      <button
        ayn-button="primary"
        type="button"
        icon="male"
        [class.active]="!isSelectedAllGenders && isSelectedMale"
        (click)="selectGender(Gender.Male)"
      >
        {{ 'Male' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="female"
        [class.active]="!isSelectedAllGenders && isSelectedFemale"
        (click)="selectGender(Gender.Female)"
      >
        {{ 'Female' | translate }}
      </button>

      <button
        ayn-button="primary"
        type="button"
        icon="all-genders"
        [class.active]="isSelectedAllGenders"
        (click)="selectAllGender()"
      >
        {{ 'All Genders' | translate }}
      </button>
    </section>
  `
})
export class GenderSelectionSmartCampaignComponent implements OnInit {
  @Input() form?: FormGroup;

  protected Gender = SmartCampaign.Client.Gender;

  constructor() {}

  ngOnInit() {}

  protected get isSelectedMale() {
    return this.form?.value?.male;
  }

  protected get isSelectedFemale() {
    return this.form?.value?.female;
  }

  protected get isSelectedAllGenders() {
    return this.form?.value.female && this.form.value.male;
  }

  protected selectGender(gender: SmartCampaign.Client.Gender) {
    if (gender == SmartCampaign.Client.Gender.Male) {
      this.form!.patchValue({ male: true, female: false });
    }
    if (gender == SmartCampaign.Client.Gender.Female) {
      this.form!.patchValue({ male: false, female: true });
    }
  }

  protected selectAllGender() {
    this.form?.patchValue({
      male: true,
      female: true
    });
  }
}
