import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Draft } from '@core/models';
import { DraftService } from '@core/services/draft.service';

import { draftLog } from '@shared/utils';
import { environment } from '@environment';

export abstract class ISaveDraft<AdCreationModel> {
  abstract adCreationModel: AdCreationModel;
}

// @ts-ignore
export class SaveDraft<AdCreationModel> extends ISaveDraft<AdCreationModel> {
  private _router = inject(Router);
  private _draftService = inject(DraftService);

  draftModel: Draft.IDraftItem | Draft.IUpsertAdDraft = {} as Draft.IDraftItem | Draft.IUpsertAdDraft;

  saveDraft$(routeCreateAd = true, cb?: () => void): void {
    if (!this.draftModel.id) {
      draftLog('[saveDraft$]: draftModel.id is null or not defined!');
      return;
    }

    const content = JSON.stringify(this.adCreationModel);
    if (this.draftModel.content === content) {
      draftLog(`[saveDraft$]: draftModel.content === content, no need to update draftModel.content!`);
      return;
    }

    this.draftModel = {
      ...this.draftModel,
      content
    };

    this._draftService
      .upsertDraft$({
        adAccountId: this.draftModel.adAccountId,
        content: this.draftModel.content,
        name: this.draftModel.name,
        source: this.draftModel.source,
        id: this.draftModel.id
      })
      .subscribe((result) => {
        if (environment.draftLog) {
          console.log(`[saveDraft$(update)]: `, this.adCreationModel, result);
        }

        cb?.();

        if (!routeCreateAd) return;

        this._router.navigate([`/main/create-ad`], {
          queryParams: {
            draftId: this.draftModel.id
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  addCampaignNameToDraft(name?: string) {
    const nameFromDraft = this.draftModel.name.split(' - ');
    if (!name) {
      this.draftModel.name = `Draft - ${nameFromDraft[1]}`;
    } else {
      if (nameFromDraft.length > 1) {
        this.draftModel.name = `${name} [Draft] - ${nameFromDraft[1]}`;
      } else {
        this.draftModel.name = `${name} ${this.draftModel.name}`;
      }
    }
  }
}
