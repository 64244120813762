import type { Ord } from 'fp-ts/Ord';

/**
 * Controls given argument is Order object.
 */
export function isOrd<T>(obj: any): obj is Ord<T> {
  if (typeof obj !== 'object') return false;
  if (obj === null) return false;

  if (typeof obj['compare'] !== 'function') return false;
  if (typeof obj['equals'] !== 'function') return false;
  return true;
}
