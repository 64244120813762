import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { PlatformContextState } from '@core/state/platform-context.state';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { IPlatformContext } from '@core/models';

@Component({
  selector: 'aayn-platform-reconnection',
  templateUrl: 'platform-reconnection-modal.component.html'
})
export class PlatformReconnectionModalComponent implements OnInit {
  @Select(PlatformContextState.RequireReconnectionPlatforms) reconnectPlatforms$!: Observable<IPlatformContext[]>;

  constructor(private ngbActiveModal: NgbActiveModal, private store: Store, private toastr: ToastrService) {}

  ngOnInit() {}

  closeModal() {
    const platforms = this.store.selectSnapshot(PlatformContextState.RequireReconnectionPlatforms);
    if (platforms.length) {
      this.toastr.error('Please reconnect or disconnect platforms before proceeding', 'Error');
      return;
    }
    this.ngbActiveModal.dismiss();
  }
}
