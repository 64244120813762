import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdModelService } from '@shared/services';

@UntilDestroy()
@Directive({
  selector: '[aaynDraftName]'
})
export class DraftNameUpdateDirective implements OnInit {
  constructor(private control: NgControl, private adModelService: AdModelService) {}

  ngOnInit() {
    this.control.valueChanges?.pipe(untilDestroyed(this)).subscribe((value) => {
      this.adModelService.getStateService()?.addCampaignNameToDraft(value);
    });
  }
}
