import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgModule,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbCalendar,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModule,
  NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap';

import { AynUITranslateModule } from '../../translate/translate.module';
import { ButtonModule } from '../button';
import { IconModule } from '../icon/icon';
import { InputDirective, InputModule } from '../input';
import { CustomDatepickerI18n, I18n } from './datepicker-i18n';
import { ReplaySubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil } from 'rxjs/operators';

function toDateByNgbDateStruct(date: NgbDateStruct) {
  return new Date(Date.UTC(date.year, date.month - 1, date.day));
}

export function toNgbDateStruct(date: Date) {
  if (typeof date != 'object') date = new Date(date);

  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
}

export type DatepickerValue = Date | null;

type SetDateValue = DatepickerValue | NgbDateStruct | string | undefined | number | null;

@UntilDestroy()
@Component({
  selector: 'ayn-datepicker',
  templateUrl: './datepicker.html',
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePicker),
      multi: true
    }
  ]
})
export class DatePicker implements AfterViewInit, ControlValueAccessor {
  model?: NgbDateStruct | null;
  today = this.calendar.getToday();

  time = { hour: 12, minute: 30 };

  @Input() inputText: string = 'Start Date';

  @Input() placeholder: string = 'dd/mm/yyyy';

  @Input() readonly = false;

  @Input() disabled = false;

  @Input() optional = false;

  @Input() valueType: 'date' | 'number' = 'date';

  @HostBinding('class.with-time-picker')
  @Input()
  timePickerShow: boolean = true;

  @Input() container: '' | 'body' = '';

  @Input() set maxDate(value: Date) {
    if (!value) return;

    this._maxDate = toNgbDateStruct(value);
  }

  @Input() inModal = false;

  @Input() set minDate(value: Date) {
    if (!value) return;
    this._minDate = toNgbDateStruct(value);
  }

  @Input() set date(value: SetDateValue) {
    if (value === undefined) return;
    if (value === null) {
      this.model = null;
    } else if (typeof value === 'number') {
      const parsed = new Date(value);
      this.model = {
        day: parsed.getDate(),
        month: parsed.getMonth() + 1,
        year: parsed.getFullYear()
      };
    } else if (value instanceof Date || typeof value === 'string') {
      const date = typeof value === 'string' ? new Date(value) : value;

      this.model = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
      };
    }
  }

  @Output() dateChange = new EventEmitter<DatepickerValue>();

  withFooter: any;

  icon = 'datepicker';

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  @ViewChild(InputDirective) input?: InputDirective;

  modelChangeRegister$ = new ReplaySubject<void>();
  writeValueTrigger$ = new Subject<void>();

  protected _minDate: NgbDateStruct = this.today;

  protected _maxDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: new Date().getFullYear() + 100
  };

  protected uuid = Math.random().toString(16).slice(2);

  constructor(private calendar: NgbCalendar, private cdr?: ChangeDetectorRef) {}

  ngAfterViewInit(): void {}

  writeValue(value: any): void {
    this.writeValueTrigger$.next();
    this.writeValueTrigger$.complete();
    this.writeValueTrigger$ = new Subject();
    this.date = value;
    if (value) {
      const shouldEmitDate = this.valueType === 'date' && !(value instanceof Date);
      const shouldEmitNumber = this.valueType === 'number' && typeof value !== 'number';
      if (shouldEmitDate || shouldEmitNumber) {
        this.modelChangeRegister$.pipe(untilDestroyed(this), takeUntil(this.writeValueTrigger$)).subscribe(() => {
          this.onModelChange(this.getParsedDate());
        });
      }
    }

    this.cdr?.detectChanges();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
    this.modelChangeRegister$.next();
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  dateChange$($event: NgbDateStruct | null) {
    if (!$event) return;
    const date = toDateByNgbDateStruct($event);
    this.dateChange.emit(date);
    this.date = date;
    this.onModelChange(this.getParsedDate());
  }

  clearDate() {
    this.dateChange.emit(null);
    this.date = null;
    this.onModelChange(null);
  }

  changeIconIfOptional() {
    if (this.optional && this.model) {
      this.icon = 'exit';
    } else {
      this.icon = 'datepicker';
    }
  }

  selectOrClearDate(event: MouseEvent, d: NgbInputDatepicker) {
    if (this.disabled) return;
    const isDateSelect = (event?.target as HTMLElement)?.attributes?.getNamedItem('ngbdatepickerdayview');
    if (isDateSelect) {
      return;
    }
    if (this.optional && this.model) {
      this.clearDate();
      d.close();
    } else {
      d.open();
      this.onModelTouched();
    }
  }

  getParsedDate() {
    if (!this.model) {
      return null;
    }
    const date = toDateByNgbDateStruct(this.model);
    if (this.valueType === 'number') {
      return date.getTime();
    }
    return date;
  }

  timeChange(time: NgbTimeStruct) {
    //TODO: Implement time change
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, ButtonModule, IconModule, AynUITranslateModule, InputModule],
  exports: [DatePicker],
  declarations: [DatePicker]
})
export class DatePickerModule {}
