import { Component, Input } from '@angular/core';
import { Facebook, Platforms } from '@core/models';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';
import {
  AdPreviewInstagramPlatform,
  AdPreviewMetaPlatform
} from '@pages/create-ad/components/shared/ad-preview/card/ad-preview-card.component';

@Component({
  selector: 'aayn-carausel-preview--facebook',
  templateUrl: './carausel-preview.component.html'
})
export class CarauselPreviewComponent {
  @Input() businessPageDetail?: Facebook.IBusinessPageDetail;

  Platforms = Platforms;
  selectedPlatform = Platforms.Meta;

  platforms = [AdPreviewMetaPlatform, AdPreviewInstagramPlatform];

  constructor(public adPreviewService: AdPreviewService) {}
}
