import { Base } from '@core/models';

export module __Business {
  export interface TikTokAdAccountResponse {
    adAccounts: TikTokAdAccountOutputDto[];
  }
  export interface TikTokAdAccountRequest {
    contextId: string;
  }

  export interface TikTokAdAccountOutputDto {
    name: string;
    adAccountId: string;
    status: TikTokAdAccountStatus;
    accessRole: TikTokAccessRole;
    accountType: string;
    ownerBusinessCenterId?: string;
  }

  export enum TikTokAdAccountStatus {
    Disable = 'Disable',
    PendingConfirm = 'Pending Confirm',
    PendingVerified = 'Pending Verified',
    ConfirmFail = 'Confirm Fail',
    Enable = 'Enable',
    ConfirmFailEnd = 'Confirm Fail End',
    PendingConfirmModify = 'Pending Confirm Modify',
    ConfirmModifyFail = 'Confirm Modify Fail',
    Limit = 'Limit',
    WaitForBpmAudit = 'Wait For Bpm Audit',
    WaitForPublicAuth = 'Wait For Public Auth',
    SelfServiceUnaudited = 'Self Service Unaudited',
    ContractPending = 'Contract Pending'
  }

  export enum TikTokAccessRole {
    Advertiser = 'Advertiser',
    ChildAdvertiser = 'Child Advertiser',
    ChildAgent = 'Child Agent',
    Agent = 'Agent'
  }

  export interface TiktokConnectAdAccountResponse {
    connectAdAccount: Base.Business.ConnectedAdAccountOutputDto;
  }

  export type TiktokConnectAdAccountRequest = Base.Business.ConnectAdAccountDto;

  export enum TikTokIdentityEnumType {
    CUSTOMIZED_USER = 'CUSTOMIZED_USER',
    AUTH_CODE = 'AUTH_CODE',
    TT_USER = 'TT_USER',
    BC_AUTH_TT = 'BC_AUTH_TT'
  }

  export interface TikTokCreateIdentityDto {
    displayName: string;
    imageUri?: string;
  }

  export interface TikTokCreateIdentityOutputDto {
    identityId: string;
  }

  export interface TikTokIdentityOutputDto {
    displayName: string;
    availableStatus: string;
    identityAuthorizedBcId: string;
    profileImage: string;
    canPushVideo: boolean;
    identityId: string;
    canUseLiveAds: boolean;
    canPullVideo: boolean;
    identityType: TikTokIdentityEnumType;
  }

  export enum TikTokCurrency {
    USD = 'USD',
    GBP = 'GBP',
    CNY = 'CNY',
    EUR = 'EUR',
    HKD = 'HKD',
    JPY = 'JPY',
    DZD = 'DZD',
    ARS = 'ARS',
    AUD = 'AUD',
    BDT = 'BDT',
    BOB = 'BOB',
    BRL = 'BRL',
    CAD = 'CAD',
    CLP = 'CLP',
    COP = 'COP',
    CRC = 'CRC',
    CZK = 'CZK',
    DKK = 'DKK',
    EGP = 'EGP',
    GTQ = 'GTQ',
    HNL = 'HNL',
    HUF = 'HUF',
    ISK = 'ISK',
    INR = 'INR',
    IDR = 'IDR',
    ILS = 'ILS',
    KES = 'KES',
    MOP = 'MOP',
    MYR = 'MYR',
    MXN = 'MXN',
    TWD = 'TWD',
    NZD = 'NZD',
    NIO = 'NIO',
    NGN = 'NGN',
    NOK = 'NOK',
    PKR = 'PKR',
    PYG = 'PYG',
    PEN = 'PEN',
    PHP = 'PHP',
    PLN = 'PLN',
    QAR = 'QAR',
    RON = 'RON',
    RUB = 'RUB',
    SAR = 'SAR',
    SGD = 'SGD',
    ZAR = 'ZAR',
    KRW = 'KRW',
    SEK = 'SEK',
    CHF = 'CHF',
    THB = 'THB',
    TRY = 'TRY',
    AED = 'AED',
    UAH = 'UAH',
    UYU = 'UYU',
    VEF = 'VEF',
    VND = 'VND'
  }
}
