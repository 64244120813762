import { Base, Bing } from '@core/models';
import { makeQuery, OffsetPaginatedQueryResult } from '@core/queries';

type Output = OffsetPaginatedQueryResult<'accountImages', Bing.AudienceCampaign.BingAccountImageAssetOutputDto>;
type Input = { filter: Base.CreateAd.ImageAssetFilter };
export const getAccountImages = makeQuery<Output, Input>(`
query GetAccountImages($filter: ImageAssetFilter) {
  bing {
    accountImages(filter: $filter) {
      edges {
        node {
          id
          width
          height
          url
          mediaType
        }
      }
    }
  }
}
`);
