<ng-container *ngIf="selectedPlatform$ | async as selectedPlatform">
  <div [ngSwitch]="selectedPlatform">
    <div *ngSwitchCase="Platforms.Meta">
      <aayn-select-an-ad-type--header
        [url]="facebookAdCreationModel?.ad?.creative?.dynamicAssets?.linkUrls?.[0]?.websiteUrl"
      ></aayn-select-an-ad-type--header>
    </div>

    <div *ngSwitchCase="Platforms.Google">
      <aayn-select-an-ad-type--header [url]="googleAdCreationModel?.ad?.url"></aayn-select-an-ad-type--header>
    </div>

    <div *ngSwitchCase="Platforms.Bing">
      <aayn-select-an-ad-type--header
        [url]="bingAdCreationModel.campaign?.assets?.finalUrl"
      ></aayn-select-an-ad-type--header>
    </div>

    <div *ngSwitchCase="Platforms.LinkedIn">
      <aayn-select-an-ad-type--header
        [url]="linkedinAdCreationModel.ad?.creative?.contentLandingPage"
      ></aayn-select-an-ad-type--header>
    </div>

    <div *ngSwitchCase="Platforms.TikTok">
      <aayn-select-an-ad-type--header [url]="tiktokAdCreationModel.ad?.url"></aayn-select-an-ad-type--header>
    </div>

    <div *ngSwitchCase="Platforms.SmartCampaign">
      <aayn-select-an-ad-type--header
        [url]="smartCampaignAdCreationModel.ad.websiteUrl"
      ></aayn-select-an-ad-type--header>
    </div>
  </div>
</ng-container>
