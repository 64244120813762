import { Component } from '@angular/core';
import { AuthService } from '@core/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-payment-modal',
  templateUrl: './payment-modal.component.html'
})
export class PaymentModalComponent {
  title = 'It’s time to fill in <br /> the payment details.';

  description = 'You won’t be charged during your trial period. <span>We promise.</span>';

  constructor(private authService: AuthService, private modalService: NgbModal) {
    console.log('PaymentModalComponent::constructor');
  }

  logout() {
    this.authService.logoutWithOperations();

    this.modalService.dismissAll();
  }

  changeTexts() {
    this.title = 'Welcome back <br> Sumoling 🫶';
    this.description = 'It’s good to have you back with us. <br> Enjoy our platform for a <b>lifetime<b/>.';
  }
}
