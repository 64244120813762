import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ModalPageComponent } from '@ayn-ui/public-api';
import { AdAccountState, BingAdAccount, BingService, GetPlatformAdAccounts } from '@core/index';
import { IAdAccountType, IPlatformContext, Platforms } from '@core/models';
import { PlatformContextState } from '@core/state/platform-context.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { AdAccountCreationComponent } from '../ad-account-creation';
import { take } from 'rxjs/operators';
import { ConnectAdAccountService } from '@shared/components/account-select-modal/services';

@Component({
  selector: 'aayn-ad-account-select--bing',
  templateUrl: 'ad-account-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConnectAdAccountService]
})
export class AdAccountSelectBingComponent implements OnInit {
  platforms = Platforms;

  protected activeStep = 0;

  protected query = '';

  protected adAccounts$ = this.store.select(AdAccountState.adAccounts<Platforms.Bing>);

  protected platformContext: IPlatformContext = this.store.selectSnapshot(PlatformContextState.PlatformContextsMapped)
    .Bing;
  private selectedAccount?: IAdAccountType<BingAdAccount>;

  get completeDisabled() {
    return !this.selectedAccount?.selected;
  }

  constructor(
    private bingService: BingService,
    private store: Store,
    private modalService: NgbModal,
    private connectAdAccountService: ConnectAdAccountService
  ) {}

  protected isCreationPossible = false;

  @ViewChild(ModalPageComponent) modal!: ModalPageComponent;

  ngOnInit() {
    this.fetchIsCreationPossible();
  }

  fetchIsCreationPossible() {
    this.bingService
      .isCreationPossible$({ contextId: this.platformContext.id })
      .pipe(take(1))
      .subscribe((isPossible: boolean) => {
        this.isCreationPossible = isPossible;
      });
  }

  complete() {
    if (this.completeDisabled) return;
    this.connectAdAccountService.connectAdAccount(
      Platforms.Bing,
      {
        adAccountId: this.selectedAccount!.id!,
        loginId: String(this.selectedAccount!.loginCustomerId!)
      },
      this.modal.modalRef!
    );
  }

  showCreateNewAccountAccountModal() {
    const modalRef = this.modalService.open(AdAccountCreationComponent);

    modalRef.componentInstance.platformContext = this.platformContext!;

    modalRef.closed.subscribe(() => {
      this.store.dispatch(new GetPlatformAdAccounts({ platforms: Platforms.Bing }));
    });
  }

  selectAdAccount(account: IAdAccountType<BingAdAccount>) {
    this.selectedAccount = account;
  }
}
