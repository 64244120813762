import { Component, Input } from '@angular/core';
import { IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { AdPreviewHeaderComponent } from '@pages/create-ad/components/shared/ad-preview/header';

@Component({
  selector: 'aayn-ad-preview',
  templateUrl: './ad-preview.component.html',
  imports: [PipesModule, TranslateModule, IconModule, NgIf, AdPreviewHeaderComponent, NgClass],
  standalone: true
})
export class AdPreviewComponent {
  @Input() businessName?: string;

  @Input() profileImage?: string;

  @Input() showImage = true;

  @Input() showHeader = true;

  @Input() description = 'Sponsored';

  @Input() className = '';
}
