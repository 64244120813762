import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BadgeList, IOption } from '@ayn-ui/lib/modules';
import { FacebookService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { Select } from '@ngxs/store';
import { FacebookStateService } from '@pages/create-ad/state';

@Component({
  selector: 'aayn-language-selection--facebook',
  templateUrl: 'language-selection.component.html'
})
export class LanguageSelectionFacebookComponent implements OnInit {
  @Input() form?: FormGroup;

  @Select(LoaderState.getAny('GetLocales')) loader$?: Observable<boolean>;

  @ViewChild(BadgeList) badgeList?: BadgeList;

  protected adCreationModel = this.facebookStateService.adCreationModel;

  protected languages: IOption[] = [];

  constructor(
    private facebookStateService: FacebookStateService,
    private facebookService: FacebookService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.facebookStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
  }

  searchValueChange(term: string) {
    this.facebookService
      .getLocales$(term)
      .pipe(map((o) => o.data))
      .subscribe((result) => {
        this.languages = result.locales.map((o) => ({ label: o.name, value: o.key } as IOption));

        this.cdr.detectChanges();
      });
  }

  protected valueChange(option: IOption) {
    const localeIndex = this.adCreationModel!.adSet.targeting!.locales.findIndex((o) => o.key == option.value);

    if (localeIndex > -1) {
      this.removeLocale(localeIndex);
      return;
    }

    this.adCreationModel!.adSet.targeting!.locales.push({
      name: option.label,
      key: option.value as string
    });

    this.form?.patchValue({
      locales: this.adCreationModel.adSet.targeting.locales
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }

  optionDisabled(option: IOption) {
    return this.adCreationModel.adSet.targeting.locales.some((o) => o.key == option.value);
  }

  removeLocale(index: number) {
    this.adCreationModel!.adSet.targeting!.locales.splice(index, 1);

    this.form?.patchValue({
      locales: this.adCreationModel.adSet.targeting.locales
    });

    this.badgeList?.calculateLanguageInnerWidth();
  }

  isSelected(option: IOption) {
    return this.adCreationModel.adSet.targeting.locales.some((o) => o.key == option.value);
  }
}
