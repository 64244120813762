import { ISubmitCustomOfferRequest } from '@core/models';

import { makeMutation } from '../types';

type Input = {
  payload: ISubmitCustomOfferRequest;
};
type Output = boolean;

export const submitCustomOffer = makeMutation<Output, Input>(`
  mutation SubmitCustomOffer($payload: SubmitCustomOfferDto!) {
    submitCustomOffer(payload: $payload)
  }
`);
