import { Component, OnInit } from '@angular/core';
import { Facebook, FacebookService, LinkedinService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aayn-library--linkedin',
  templateUrl: './library.component.html'
})
export class LibraryLinkedinComponent implements OnInit {
  query = '';

  @Select(LoaderState.getAny(['GetAdImages'])) loader$?: Observable<boolean>;

  // TODO: LINKEDIN: Add image model
  images?: Facebook.IGetAdImagesResponse;

  constructor(public modal: NgbActiveModal, private linkedinService: LinkedinService) {}

  ngOnInit() {
    this.getImages();
  }

  protected getImages() {
    /*
    // TODO: LINKEDIN: Add image request
    this.facebookService.getAdImages$().subscribe((result) => {
      if (result.error) return;

      this.images = result.data.adImages;
    });

     */
  }
  // TODO: LINKEDIN: Add image model
  protected toggleImage(item: Facebook.AdImageModel) {
    if (this.selectedImagesLength! >= 10) return;

    item.selected = !item.selected;
  }

  protected get selectedImagesLength() {
    return this.images?.edges.filter((o) => o.node.selected).length;
  }

  protected continueWithSelected() {
    this.modal.close({
      images: this.images?.edges
        .filter((o) => o.node.selected)
        // TODO: LINKEDIN: Add image model
        .map((o) => ({ id: o.node.hash, url: o.node.url } as Facebook.IdWithUrl))
    });
  }
}
