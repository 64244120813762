<ayn-form-field>
  <ayn-select
    [class]="'aayn-language-selection'"
    (searchControlChange)="searchValueChange($event)"
    (valueChange)="valueChange($event)"
    [loading]="!!(loader$ | async)"
    [placeholder]="
      adCreationModel.adSet.targeting.locales.length
        ? adCreationModel.adSet.targeting.locales.length + ' Language Selected'
        : 'Choose language'
    "
    [dontSelect]="true"
    [hideOnSelectOverlay]="false"
  >
    <ayn-option *ngFor="let option of languages" [value]="option">
      {{ option.label }}

      <button ayn-button [icon]="isSelected(option) ? 'minus' : 'plus'" type="button">
        {{ isSelected(option) ? 'Remove Language' : 'Add Language' }}
      </button>
    </ayn-option>
  </ayn-select>
</ayn-form-field>

<ayn-badge-list [list]="adCreationModel.adSet.targeting.locales" (onRemoveItem)="removeLocale($event)"></ayn-badge-list>

<footer class="aayn-language-selection--footer" *ngIf="!adCreationModel.adSet.targeting.locales.length">
  Selected Language

  <b>
    All Languages
    <ayn-icon name="globe"></ayn-icon>
  </b>
</footer>
