import { Pipe, PipeTransform } from '@angular/core';

import { arraySearch } from '../../helpers';

@Pipe({
  name: 'aynFilter'
})
export class FilterPipe implements PipeTransform {
  transform<T>(values: T[], term: string): T[] {
    return arraySearch(values, term) as T[];
  }
}

@Pipe({
  name: 'aynFilterObj'
})
export class FilterObjPipe implements PipeTransform {
  transform<T = any>(values: T[], filter: { [key: string]: string | number | boolean }): T[] {
    if (!filter) return values;

    const _keys = Object.keys(filter).filter((o) => {
      if (typeof filter[o] == 'string' && !filter[o].toString().length) return false;

      return true;
    });

    return values?.filter((o) => {
      return (
        _keys
          .map((k) => (o[k] || '').toLowerCase()?.includes(filter[k].toString().trim().toLowerCase()))
          .filter(Boolean).length == _keys.length
      );
    });
  }
}

@Pipe({
  name: 'aynFilterEqual',
  pure: false
})
export class FilterObjEqualPipe implements PipeTransform {
  transform<T>(values: T[], filter?: { [key: string]: string | number | boolean | null }): T[] {
    if (!filter) return values;

    const _filter = {
      key: Object.keys(filter)[0],
      value: Object.values(filter)[0]
    };

    return values?.filter((o) => {
      const zz = o[_filter.key] == _filter.value;

      return zz;
    }) as T[];
  }
}

@Pipe({
  name: 'aynFilterNotEqual'
})
export class FilterObjNotEqualPipe implements PipeTransform {
  transform<T>(values: T[], filter: { [key: string]: string | number | boolean | null }): T[] {
    if (!filter) return values;

    const _filter = {
      key: Object.keys(filter)[0],
      value: Object.values(filter)[0]
    };

    return values?.filter((o) => o[_filter.key] != _filter.value);
  }
}
