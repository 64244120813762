import { Component, Input, ViewChild } from '@angular/core';
import { ModalPageComponent } from '@ayn-ui/public-api';

@Component({
  selector: 'aayn-video-modal',
  templateUrl: './video-modal.component.html'
})
export class VideoModalComponent {
  @Input()
  videoId!: string;

  @ViewChild(ModalPageComponent)
  modalPage!: ModalPageComponent;

  closeModal() {
    this.modalPage.closeModal();
  }
}
