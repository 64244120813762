import { Overlay } from 'apps/ayn-ui/src/public-api';
import { Observable } from 'rxjs';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessCategoryMap, IBusiness } from '@core/models';
import { BusinessState, SetSelectedBusiness } from '@core/state/business.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { CreateAdAllPlatformsService } from '@pages/create-ad/services/all-platforms.service';
import { ErrorModalContent } from '@shared/components';

@Component({
  selector: 'aayn-business-selection',
  templateUrl: 'business-selection.component.html'
})
export class BusinessSelectionComponent implements OnInit {
  @ViewChild(Overlay) overlay!: Overlay;

  @Select(BusinessState.Businesses)
  businesses$!: Observable<IBusiness[]>;

  @Select(BusinessState.SelectedBusiness)
  selectedBusiness$!: Observable<IBusiness>;

  @Input()
  businessSelectionDisabled = false;

  BusinessCategoryMap = BusinessCategoryMap;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private createAdAllPlatformsService: CreateAdAllPlatformsService
  ) {}

  ngOnInit() {}

  get isOnCreateAdPage() {
    return this.router.url.startsWith('/main/create-ad') && this.activatedRoute.snapshot.queryParams.draftId;
  }

  private openSelectedBusinessConfirmModal() {
    const modalRef = this.modalService.open(ErrorModalContent);

    (modalRef.componentInstance as ErrorModalContent).title = 'Change Selected Business';
    (modalRef.componentInstance as ErrorModalContent).subContent =
      'If you change the selected business on this page, the information you entered will be saved as a draft and the form will be reset.';

    return modalRef;
  }

  selectBusiness(business: IBusiness) {
    const selectBusiness = () => {
      this.store.dispatch(new SetSelectedBusiness(business));

      this.overlay.hide();
    };

    if (this.isOnCreateAdPage) {
      this.openSelectedBusinessConfirmModal().closed.subscribe((result) => {
        if (result) {
          selectBusiness();

          this.createAdAllPlatformsService.resetCreateAdState();

          this.router.navigate(['/main/create-ad'], { queryParams: { step: 1 } });
        }
      });

      return;
    }

    selectBusiness();
  }
}
