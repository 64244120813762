import { Component } from '@angular/core';
import { Platforms } from '@core/models';
import { Select } from '@ngxs/store';
import { CreateAdState } from '@pages/create-ad/state';
import { Observable } from 'rxjs';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';

@Component({
  selector: 'aayn-describe-project-platforms--header',
  templateUrl: './describe-project-platforms-header.component.html'
})
export class DescribeProjectPlatformsHeaderComponent {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  protected Platforms = Platforms;

  constructor(protected instantAdStateService: InstantAdStateService) {}
}
