import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { TikTok, TiktokService } from '@core/index';
import { LoaderState } from '@core/state/loader.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';

type TikTokLibraryImage = TikTok.CreateAd.Client.TikTokLibraryAsset<TikTok.CreateAd.Backend.TikTokAccountImage>;
type TikTokLibraryVideo = TikTok.CreateAd.Client.TikTokLibraryAsset<TikTok.CreateAd.Backend.TikTokAccountVideo>;

@Component({
  selector: 'aayn-library--tiktok',
  templateUrl: './library.component.html'
})
export class LibraryTiktokComponent implements OnInit {
  query = '';

  @Select(LoaderState.getAny(['TiktokAccountImages', 'TiktokAccountVideos'])) loader$?: Observable<boolean>;

  images?: TikTokLibraryImage[];

  videos?: TikTokLibraryVideo[];

  maxImages = 30;

  constructor(public modal: NgbActiveModal, private tiktokService: TiktokService) {}

  ngOnInit() {
    this.tiktokService.getAccountImages$().subscribe((images) => {
      if (!images) return;

      this.images = images.edges.map((o) => o.node);
    });

    this.tiktokService.getAccountVideos$().subscribe((videos) => {
      if (!videos) return;

      this.videos = videos.edges.map((o) => o.node);
    });
  }

  protected toggleImage(item: TikTokLibraryImage) {
    if (this.selectedImagesLength! >= this.maxImages && !item.selected) return;

    item.selected = !item.selected;
  }

  protected toggleVideo(item: TikTokLibraryVideo) {
    if (this.selectedImagesLength! >= this.maxImages && !item.selected) return;

    item.selected = !item.selected;
  }

  protected get selectedImagesLength() {
    return this.images?.filter((o) => o.selected).map((o) => o.imageId).length || 0;
  }

  protected get selectedVideosLength() {
    return this.videos?.filter((o) => o.selected).map((o) => o.videoId).length || 0;
  }

  protected continueWithSelected() {
    const images =
      this.images?.filter((o) => o.selected).map((o) => ({ id: o.imageId, url: o.imageUrl, type: 'image' })) || [];

    const videos =
      this.videos
        ?.filter((o) => o.selected)
        .map((o) => ({ id: o.videoId, url: this.getUrlFromVideo(o), type: 'video' })) || [];

    this.modal.close({
      images: images.concat(videos)
    });
  }

  getUrlFromVideo(video: TikTokLibraryVideo) {
    return video.videoCoverUrl;
  }
}
