<div
  class="ayn-badge"
  [ngClass]="{'rounded': rounded, 'ayn-badge__has-slider': isSlider}"
  [style.background]="getColorName"
  [title]="text"
  [style.--color]="getColorName"
>
  <span>{{ text }}</span>

  <ng-content></ng-content>

  <ayn-icon *ngIf="icon" [name]="icon"></ayn-icon>

  <div class="slider" *ngIf="isSlider">
    <ayn-slider
      [minValue]="sliderValue"
      (sliderValueChange)="sliderValueChange($event)"
      [options]="sliderOptions"
    ></ayn-slider>

    <span class="slider-text">{{'{badgeSliderValue} km' | aynTranslate:{badgeSliderValue: sliderValue} | async}}</span>
  </div>
</div>
