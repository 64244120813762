import { makeQuery, QueryResult } from '@core/queries';
import { TikTok } from '@core/models';

const queryField = 'keywordSearch';

type Input = TikTok.CreateAd.Backend.InterestSearch;
type Output = QueryResult<typeof queryField, TikTok.CreateAd.Backend.TikTokInterestSearchOutputDto[]>;

export const keywordsSearchQuery = makeQuery<Output, Input>(`
query TikTokKeywordsSearch($searchKey:String!, $limit:SafeInt) {
  tiktok {
    ${queryField}(searchKey: $searchKey, limit:$limit){
      name
      id
    }
  }
}
`);
