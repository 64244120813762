import { Observable } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import { IPackage, MySubscription, PeriodUnit } from '@core/models';
import { ChargebeeService } from '@core/services';
import { GetMySubscription } from '@core/state';
import { LoaderState } from '@core/state/loader.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { DowngradeSuccessComponent } from '../downgrade-success';

@Component({
  selector: 'aayn-downgrade-confirmation',
  templateUrl: 'downgrade-confirmation.component.html'
})
export class DowngradeConfirmationComponent implements OnInit {
  @Input() oldPackage!: Pick<MySubscription, 'package'>['package'];

  @Input() newPackage!: IPackage;

  @Input() totalDiscountPercentage = 0;

  @Select(LoaderState.getAny('SubscribeToPlan')) loader$?: Observable<boolean>;

  get isMonthly() {
    return this.newPackage?.periodUnit === PeriodUnit.Month;
  }

  constructor(private chargebeeService: ChargebeeService, private modalService: NgbModal, private store: Store) {}

  ngOnInit() {}

  downgradeNow() {
    if (!this.newPackage) return;

    this.chargebeeService.subscribeToPlan$(this.newPackage.id).subscribe((result) => {
      if (result.data) {
        this.modalService.open(DowngradeSuccessComponent, {
          modalDialogClass: 'aayn-modal-for-payments',
          backdrop: 'static'
        });

        this.store.dispatch(new GetMySubscription());
      }
    });
  }
}
