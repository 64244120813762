import { makeQuery } from '../types';

type Output = {
  emailConfirmed: boolean;
  inviter: {
    inviterId: string;
  };
};

export const userInfo = makeQuery<Output>(`
  query UserInfo {
     userInfo {
      emailConfirmed
      inviter { inviterId }
     }
  }
`);
