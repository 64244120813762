import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  adPixels: {
    edges: {
      node: Facebook.IAdPixel;
    }[];
  };
};

export const getAdPixels = makeQuery<Output>(`
  query {
    facebook {
      adPixels {
        edges {
          node {
              id
              name
          }
        }
      }
    }
  }
`);
