import { Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { AynUITranslateModule } from '../../translate/translate.module';
import { IconModule } from '../icon';
import { SliderModule } from '../slider';

export type Color = 'primary' | 'accent' | 'success' | 'orange' | 'gray-300' | 'gray-500';

@Component({
  selector: 'ayn-badge',
  templateUrl: './badge.html'
})
export class Badge {
  @Input() text: string = '';

  @Input() icon: string = '';

  @Input() color: Color = 'primary';

  @Input() rounded = false;

  @Input() isSlider: boolean = false;

  @Input() sliderValue: number = 76;

  @Input() sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    hidePointerLabels: true,
    hideLimitLabels: true
  };

  get getColorName(): string {
    return `var(--${this.color})`;
  }

  sliderValueChange($event: number) {
    this.sliderValue = $event;
  }
}

@NgModule({
  imports: [CommonModule, IconModule, SliderModule, AynUITranslateModule],
  exports: [Badge],
  declarations: [Badge],
  providers: []
})
export class BadgeModule {}
