<aayn-ad-preview-card [(selectedPlatform)]="selectedPlatform" [platforms]="platforms">
  <aayn-ad-preview-meta-single
    *ngIf="selectedPlatform === Platforms.Meta"
    [businessName]="businessPageDetail?.name || ''"
    [businessImage]="businessPageDetail?.imageUrl || ''"
    [cta]="adPreviewService.dynamicCreative.callToAction || ''"
    [headline]="adPreviewService.dynamicCreative.headline || ''"
    [shortHeadline]="adPreviewService.dynamicCreative.shortHeadline || ''"
    [imageUrls]="adPreviewService.dynamicCreative.images || []"
    [websiteUrl]="adPreviewService.dynamicCreative.websiteUrl || ''"
    [description]="adPreviewService.dynamicCreative.description || ''"
  >
  </aayn-ad-preview-meta-single>

  <aayn-ad-preview-instagram-single
    *ngIf="selectedPlatform === Platforms.Instagram"
    [businessName]="businessPageDetail?.name || ''"
    [businessImage]="businessPageDetail?.imageUrl || ''"
    [cta]="adPreviewService.dynamicCreative.callToAction || ''"
    [headline]="adPreviewService.dynamicCreative.headline || ''"
    [imageUrls]="adPreviewService.dynamicCreative.images || []"
    [websiteUrl]="adPreviewService.dynamicCreative.websiteUrl || ''"
  >
  </aayn-ad-preview-instagram-single>
</aayn-ad-preview-card>
