import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'aayn-audience-age-range',
  templateUrl: 'age-range.component.html'
})
export class AudienceAgeRangeComponent implements OnInit, OnChanges {
  @Input() minSliderValue?: number;
  @Input() maxSliderValue?: number;

  @Input() value = 18;

  @Input() maxValue = 65;

  @Input() maxRange = 65;

  @Input() sliderOptions?: Options;

  @Input() texts = {
    age: 'Age'
  };

  @Input() labelFormatter = (value: number, label: LabelType.Low | LabelType.High): string => value.toString();

  protected options: Options = {
    floor: this.value,
    maxRange: this.maxValue,
    ceil: this.maxRange,
    step: 1,
    showSelectionBar: true,

    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return `<span>${this.texts.age}</span>&nbsp; ${value}`;
        case LabelType.High:
          return `&nbsp;<span>${this.texts.age}</span>&nbsp; ${value}`;
        default:
          return `+${value}`;
      }
    }
  };

  @Output() ageRangeChanged = new EventEmitter<[number, number]>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sliderOptions) {
      this.options = {
        ...this.options,
        ...changes.sliderOptions.currentValue
      };
    }

    if (changes.minSliderValue) {
      this.options = {
        ...this.options,
        floor: changes.minSliderValue.currentValue
      };
    }

    if (changes.maxSliderValue) {
      this.options = {
        ...this.options,
        ceil: changes.maxSliderValue.currentValue,
        maxRange: changes.maxSliderValue.currentValue
      };
    }
  }
}
