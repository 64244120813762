import { Platforms, SmartCampaign, SupportedSmartCampaignObject } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = SmartCampaign.Backend.SmartCampaignLanguageResultDto;

const platformsObject = {
  [Platforms.Meta]: `facebook { id, code, platformSpecificName }`,
  [Platforms.LinkedIn]: `linkedIn { id, code, platformSpecificName }`,
  [Platforms.Google]: `google { id, code, platformSpecificName }`,
  [Platforms.TikTok]: `tiktok { id, code, platformSpecificName }`
};

export const languages = (selectedPlatforms: Platforms[]) =>
  makeQuery<Output>(
    `
  query GetLanguagesSmartCampaign {
    ayn {
      audience {
        languages {
          failures {
           ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }
          statuses {
            ${selectedPlatforms.map((o) => SupportedSmartCampaignObject[o]).join(' ')}
          }
          results {
            name
            ${selectedPlatforms.map((o) => platformsObject[o]).join(' ')}
          }
        }
      }
    }
  }
`,
    { resultPath: 'ayn.audience.languages' }
  );
