<section class="aayn-create-ad--item--container">
  <section class="aayn-create-ad--item--row">
    <aayn-select-ad-item
      *ngFor="let adType of adTypes$ | async"
      [title]="adType.title"
      [active]="adType.active"
      [empty]="adType.empty"
      [description]="adType.description"
      [adsType]="adType.icon"
      [disabled]="adType.disabled"
      (btnClick)="adTypeChangeStatus(adType)"
    >
    </aayn-select-ad-item>
  </section>
</section>

<div class="aayn-ad-type-container">
  <div class="aayn-ad-type-container--content mt:5">
    <div [ngSwitch]="selectedAdType">
      <div *ngSwitchCase="AdTypes.DynamicCreative">
        <aayn-dynamic-creative-ads--facebook></aayn-dynamic-creative-ads--facebook>
      </div>

      <div *ngSwitchCase="AdTypes.CarouselAds">
        <aayn-carousel-ads--facebook></aayn-carousel-ads--facebook>
      </div>

      <div *ngSwitchCase="AdTypes.InstantExperienceAds">
        <aayn-existing-post-ads--facebook></aayn-existing-post-ads--facebook>
      </div>
    </div>

    <aayn-ad-preview--facebook
      class="aayn-ad-type-container--content--preview"
      [selectedAdType]="selectedAdType"
    ></aayn-ad-preview--facebook>
  </div>
</div>
