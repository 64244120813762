import { makeMutation } from '@core/queries';

type Input = { adObjectId: string };
type Output = null;

export const deleteAdObject = makeMutation<Output, Input>(`
  mutation DeleteAdObject($adObjectId: String!) {
    facebook {
      deleteAdObject(adObjectId: $adObjectId)
    }
  }
`);
