import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppValidations } from '@auth/utils';
import { FileUploadAreaEvent, FileUploadGroup, URLObject } from '@ayn-ui/lib/modules/base/file-upload-area';
import { IdWithUrlGlobal, Platforms, PlatformStep, SmartCampaign, SmartCampaignPlatform, TikTok } from '@core/models';
import { CanvaService } from '@core/services/canva.service';
import { SmartCampaignService } from '@core/services/smart-campaign.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartCampaignFormService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.form';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

import { environment } from '@environment';
import { AdPreviewSmartCampaignService } from '../ad-preview';
import { isImagesValid, UploadAdsImagesSmartCampaignComponent } from '../modal';
import { createRequiredValidator, requiredObjectValueValidator } from '@core/utils';
import { parseAspectRatio } from '@shared/utils';
import { isEqual } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'aayn-dynamic-creative-ads--smart-campaign',
  templateUrl: 'dynamic-creative-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class DynamicCreativeAdsSmartCampaignComponent implements OnInit {
  protected adCreationModel = this.smartCampaignStateService.adCreationModel;

  private _images?: SmartCampaign.Client.ImageAssets;
  protected uploadedImageUrls: FileUploadGroup[] = [];

  imageConfig: SmartCampaign.Backend.ImageConfigDto[] = [];

  Platforms = Platforms;

  get isImagesValid() {
    return this._images && isImagesValid(this.imageConfig, this._images).isValid;
  }

  protected form = new FormGroup(
    {
      shortHeadlines: new FormArray(
        [
          new FormControl(this.adCreationModel.ad?.shortHeadlines[0] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.shortHeadlines[1] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.shortHeadlines[2] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.shortHeadlines[3] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.shortHeadlines[4] || '', [Validators.required])
        ],
        [AppValidations.uniqueValidator()]
      ),
      headlines: new FormArray(
        [
          new FormControl(this.adCreationModel.ad?.headlines[0] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.headlines[1] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.headlines[2] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.headlines[3] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.headlines[4] || '', [Validators.required])
        ],
        [AppValidations.uniqueValidator()]
      ),
      descriptions: new FormArray(
        [
          new FormControl(this.adCreationModel.ad?.descriptions[0] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.descriptions[1] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.descriptions[2] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.descriptions[3] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.descriptions[4] || '', [Validators.required])
        ],
        [AppValidations.uniqueValidator()]
      ),
      primaryTexts: new FormArray(
        [
          new FormControl(this.adCreationModel.ad?.primaryTexts[0] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.primaryTexts[1] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.primaryTexts[2] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.primaryTexts[3] || '', [Validators.required]),
          new FormControl(this.adCreationModel.ad?.primaryTexts[4] || '', [Validators.required])
        ],
        [AppValidations.uniqueValidator()]
      ),

      callToActionType: new FormControl(this.adCreationModel.ad.callToAction, [requiredObjectValueValidator]),
      websiteUrl: new FormControl(this.adCreationModel.ad.websiteUrl, [Validators.required]),
      name: new FormControl(this.adCreationModel.ad.name, [Validators.required]),
      businessName: new FormControl(this.adCreationModel.ad.businessName, [
        this.smartCampaignStateService.getValidator(Platforms.Google, Platforms.Bing)
      ]),
      identity: new FormControl(
        this.adCreationModel.campaign.platforms?.tiktok?.identity || ({} as TikTok.Business.TikTokIdentityOutputDto),
        [this.smartCampaignStateService.getValidator(Platforms.TikTok)]
      )
    },
    {
      validators: [createRequiredValidator(() => !!this.isImagesValid)]
    }
  );

  get shortHeadlinesFormArray() {
    return this.form.controls['shortHeadlines'] as FormArray;
  }

  get headlinesFormArray() {
    return this.form.controls['headlines'] as FormArray;
  }

  get primaryTextsFormArray() {
    return this.form.controls['primaryTexts'] as FormArray;
  }

  get descriptionsFormArray() {
    return this.form.controls['descriptions'] as FormArray;
  }

  configInitialized$ = new ReplaySubject(1);

  callToActions: any[] = [];

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private smartCampaignFormService: SmartCampaignFormService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private canvaService: CanvaService,
    public adPreviewSmartCampaignService: AdPreviewSmartCampaignService,
    private smartCampaignService: SmartCampaignService
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      if (!environment.production) {
        console.error(`CreateAd.SmartCampaign.DynamicCreativeAds.CanvaService`, error);
      }
    }

    this.smartCampaignFormService.registerForm(this.form);

    this.listenModel();

    this.smartCampaignValidationService.registerForm(this.form, PlatformStep.AdType, this);

    this.listenForm();
    this.getImageConfig();
    this.getCallToActions();
  }

  getCallToActions() {
    const supportedPlatforms = SmartCampaign.Backend.filterPlatforms(this.adCreationModel.selectedPlatforms, [
      Platforms.Bing
    ]);

    this.smartCampaignService
      .callToActions$(supportedPlatforms.map((platform) => SmartCampaignPlatform[Platforms[platform]]))
      .subscribe((result) => {
        this.callToActions = result?.data!.results;
      });
  }

  runAfterConfig(cb: () => void) {
    this.configInitialized$.pipe(take(1)).subscribe(() => {
      cb.call(this);
    });
  }

  getImageConfig() {
    this.smartCampaignService
      .getImageConfig(
        this.adCreationModel.selectedPlatforms.map((platform) => SmartCampaignPlatform[Platforms[platform]])
      )
      .subscribe((result) => {
        if (result?.imageConfig) {
          this.imageConfig = result.imageConfig;
        }

        this.configInitialized$.next();
      });
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesSmartCampaignComponent, { backdrop: 'static' });

    (modalRef.componentInstance as UploadAdsImagesSmartCampaignComponent).initialize(this.imageConfig);

    if (this._images)
      (modalRef.componentInstance as UploadAdsImagesSmartCampaignComponent).setUploadImages(this._images);

    modalRef.closed.pipe(take(1)).subscribe((result: { images: SmartCampaign.Client.ImageAssets }) => {
      if (!result?.images) return;
      this.updateImages(result.images);
    });
  }

  removeFile(event: FileUploadAreaEvent) {
    Object.values(this._images!).forEach((groups) => {
      groups.forEach((group) => {
        if (group.groupName === event.group) {
          group.images = group.images.filter(
            (image) => image.globalId !== (event.image as URLObject<IdWithUrlGlobal<string>>).globalId
          );
        }
      });
    });
    this.updateImages({
      ...this._images!
    });
  }

  updateImages(images: SmartCampaign.Client.ImageAssets) {
    this._images = images;

    this.runAfterConfig(() => {
      this.uploadedImageUrls = this.mapUploadedImageUrls(images);
      this.cdr.detectChanges();
    });
    const uploadedPlatform = images.google || images.bing;
    if (uploadedPlatform) {
      this.adPreviewSmartCampaignService.dynamicCreative.businessLogo = uploadedPlatform.find(
        (x) => x.typeName === 'logo'
      )?.images[0].url;
    }

    this.adCreationModel.ad!.images! = images;
    this.cdr.detectChanges();
    this.form.updateValueAndValidity();
    this.smartCampaignStateService.saveDraft$();
  }

  private mapUploadedImageUrls(images: SmartCampaign.Client.ImageAssets): FileUploadGroup[] {
    return Object.values(images).reduce((acc, groups) => {
      groups.forEach((group) => {
        if (
          group.groupName === SmartCampaign.Backend.ImageGroupName.Logo ||
          group.groupName === SmartCampaign.Backend.ImageGroupName.LogoLandscape
        ) {
          return;
        }
        const config = this.imageConfig.find((x) => x.groupName === group.groupName);

        const groupIndex = acc.findIndex((x) => x.groupName === group.groupName);
        if (groupIndex === -1) {
          acc.push({
            label: group.groupName,
            imageUrls: group.images,
            maxImageCount: config?.common.quantity.max || 0,
            groupName: group.groupName,
            required: config?.required,
            minRequired: config?.common.quantity.min || 0,
            aspectRatio: parseAspectRatio(config?.common.aspectRatio || '1:1')
          });
        } else {
          const imageUrls = group.images.filter((newImage) =>
            acc[groupIndex].imageUrls.every(
              (image) => (image as URLObject<IdWithUrlGlobal>).globalId !== newImage.globalId
            )
          );
          acc[groupIndex].imageUrls = [...acc[groupIndex].imageUrls, ...imageUrls];
        }
      });

      return acc;
    }, [] as FileUploadGroup<IdWithUrlGlobal>[]);
  }

  listenModel() {
    this.smartCampaignStateService.adCreationModel$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.adCreationModel = o;

      this.form.patchValue({
        descriptions: this.adCreationModel.ad.descriptions,
        headlines: this.adCreationModel.ad.headlines,
        primaryTexts: this.adCreationModel.ad.primaryTexts,
        shortHeadlines: this.adCreationModel.ad.shortHeadlines,
        callToActionType: this.adCreationModel.ad.callToAction,
        websiteUrl: this.adCreationModel.ad.websiteUrl,
        name: this.adCreationModel.ad.name,
        businessName: this.adCreationModel.ad.businessName
      });
      if (this.adCreationModel.selectedPlatforms.includes(Platforms.Google)) {
        this.adPreviewSmartCampaignService.dynamicCreative.googleHeadlines = this.adCreationModel.ad.headlines?.filter(
          Boolean
        ) as string[];
      }

      this.updateImages(o.ad.images);
    });
  }

  listenForm() {
    this.form.valueChanges.pipe(distinctUntilChanged(isEqual)).subscribe((o) => {
      this.adCreationModel.ad.descriptions = o.descriptions?.filter(Boolean) as string[];
      this.adCreationModel.ad.headlines = o.headlines?.filter(Boolean) as string[];
      this.adCreationModel.ad.primaryTexts = o.primaryTexts?.filter(Boolean) as string[];
      this.adCreationModel.ad.shortHeadlines = o.shortHeadlines?.filter(Boolean) as string[];
      this.adCreationModel.ad.callToAction = o.callToActionType!;
      this.adCreationModel.ad.websiteUrl = o.websiteUrl!;
      this.adCreationModel.ad.name = o.name!;
      this.adCreationModel.ad.businessName = o.businessName!;
      if (this.adCreationModel.selectedPlatforms.includes(Platforms.TikTok)) {
        if (this.adCreationModel.campaign.platforms?.tiktok) {
          this.adCreationModel.campaign.platforms.tiktok.identity = o.identity!;
        } else {
          this.adCreationModel.campaign.platforms!.tiktok = {
            identity: o.identity!
          };
        }
      }

      if (this.adCreationModel.selectedPlatforms.includes(Platforms.Google)) {
        this.adPreviewSmartCampaignService.dynamicCreative.googleHeadlines = o.headlines?.filter(Boolean) as string[];
      }
    });
  }

  getImages(index: number) {
    //TODO: platform bazında eksik olan image çekilecek...
  }
}
