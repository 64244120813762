import { makeQuery } from '@core/queries';
import { Linkedin } from '@core/models';
type Input = { ids: number[] };
type Output = Linkedin.CreateAd.GetOrganizationsWithLogosResponse;
export const getOrganizationsWithLogos = makeQuery<Output, Input>(`
query GetOrganizationsWithLogos($ids: [Int!]!) {
  linkedin {
    getOrganizations(organizationIds: $ids){
      results
      statuses
      errors
    }
    getOrganizationLogos(organizationIds: $ids){
      results
      statuses
      errors
    }
  }
}
`);
