import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  businessAdAccountDetail: Facebook.IBusinessAdAccountDetail;
};

export const getBusinessAdAccountDetail = makeQuery<Output>(`
  query FacebookMinimumBudget{
    facebook {
      businessAdAccountDetail {
        minimumBudget
        currency
      }
    }
  }
`);
