<form
  [formGroup]="form"
  #_form="ngForm"
  autocomplete="off"
  [adPreviewContainer]="adPreviewService.dynamicImage"
  aaynTextAiContainer
>
  <ayn-file-upload-area
    [imageCount]="5"
    (fileUploadClick)="openFileModal()"
    (fileRemove)="removeFile($event)"
    [description]="'Linkedin maximum pixel count is 36152320' | translate"
    [imageUrls]="uploadedImageUrls"
    adPreview="images"
    [standAlone]="true"
    [showRequiredAlert]="!uploadedImageUrls.length && _form.submitted"
  ></ayn-file-upload-area>
  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections>
    <aayn-ad-type-section header="Headline for ad" description="Write a headline">
      <aayn-create-ad-input
        [maxCharacterNumber]="100"
        [placeholder]="'Tell people what your ad is about' | translate"
        formControlName="body"
        [ngModel]="adCreationModel.ad.creative.title"
        adPreview="title"
        textAiSection="headlines"
      ></aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Description for ad" description="Write a description">
      <aayn-create-ad-input
        [placeholder]="'Write a description here' | translate"
        [ngModel]="adCreationModel.ad.creative.commentary"
        [maxCharacterNumber]="70"
        formControlName="description"
        adPreview="description"
        textAiSection="descriptions"
      ></aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToActionType"
            [ngModel]="adCreationModel.ad.creative.ctaLabel"
            [placeholder]="'Select Call To Action' | translate"
            [showTick]="true"
            [showSearch]="false"
            [standAlone]="true"
            adPreview="callToAction"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction | translate }}"
            >
              {{ callToAction | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="text"
            placeholder="https://adyouneed.com"
            formControlName="websiteUrl"
            adPreview="websiteUrl"
            [standAlone]="true"
            ayn-input
            #urlInput
          />
        </ayn-form-field>
      </section>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Ad name" description="Write ad name">
      <ayn-form-field class="aayn-ad-type--control">
        <input type="text" ayn-input [placeholder]="'Ad Name' | translate" formControlName="name" />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
