import { Bing, Facebook } from '@core/models';
import { makeMutation } from '@core/queries/types';

type Input = Facebook.Dashboard.IUpdateCampaignRequest;
type Output = { updateCampaign: boolean };

export const updateCampaignFacebook = makeMutation<Output, Input>(`
mutation updateCampaignFacebook(
  $campaignId: String!
  $payload: FacebookUpdateCampaignInputDto!
) {
  facebook {
    updateCampaign(campaignId: $campaignId, payload: $payload)
  }
}

`);
