import {
  AfterViewInit,
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

import { TooltipComponent } from './tooltip';
import { TooltipPosition } from './tooltip.enum';
import { fromEvent, Observable, ReplaySubject, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[ayn-tooltip]'
})
export class TooltipDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input('ayn-tooltip') tooltip: string | TemplateRef<HTMLElement> | HTMLElement = '';

  @Input() position: TooltipPosition | string = TooltipPosition.DEFAULT;

  @Input('ayn-tooltip-style') style = '';

  @Input() tooltipClass = '';

  @Input() showDelay = 0;

  @Input() hideDelay = 0;

  @Input() zIndex = '1000';

  @Input() show = true;

  @Input() dismissible = true;

  @Input() calculatePosition$?: Observable<any>;

  viewInit$ = new ReplaySubject<() => void>();

  private componentRef: ComponentRef<any> | null = null;
  private showTimeout?: number;
  private hideTimeout?: number;
  private touchTimeout?: number;
  private tooltipPosition$?: Subscription;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.dismissible) {
      this.initializeTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.dismissible) {
      this.setHideTooltipTimeout();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    if (this.componentRef !== null && this.position === TooltipPosition.DYNAMIC && this.dismissible) {
      this.componentRef.instance.left = $event.clientX;
      this.componentRef.instance.top = $event.clientY;
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.zIndex = this.zIndex;
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event: TouchEvent): void {
    if (this.dismissible) {
      $event.preventDefault();
      window.clearTimeout(this.touchTimeout);
      this.touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
    }
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    if (this.dismissible) {
      window.clearTimeout(this.touchTimeout);
      this.setHideTooltipTimeout();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dismissible && !changes.dismissible.currentValue) {
      if (changes.dismissible.previousValue && !changes.dismissible.firstChange) {
        this.destroy();
      }
      this.viewInit$.next(this.initializeTooltip);
    }
  }

  ngAfterViewInit() {
    this.viewInit$.pipe(untilDestroyed(this)).subscribe((cb) => cb.call(this));
  }

  private initializeTooltip() {
    if (!this.show) return;

    if (this.componentRef === null) {
      window.clearInterval(this.hideDelay);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

      this.setTooltipComponentProperties();
      this.listenPositionChange();
      document.body.appendChild(tooltipDOMElement);
      this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
    }
  }

  listenPositionChange() {
    if (!this.tooltipPosition$?.closed) {
      this.tooltipPosition$?.unsubscribe();
      this.tooltipPosition$ = new Subscription();
    }
    this.tooltipPosition$.add(
      fromEvent(document, 'scroll')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.setTooltipComponentProperties();
        })
    );
    if (this.calculatePosition$) {
      this.tooltipPosition$.add(
        this.calculatePosition$.pipe(untilDestroyed(this), delay(100)).subscribe(() => {
          this.setTooltipComponentProperties();
        })
      );
    }
  }

  setPositionChangeTrigger(observable: Observable<any>) {
    this.calculatePosition$ = observable;
    this.listenPositionChange();
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      const instance = this.componentRef.instance;
      instance.tooltip = this.tooltip;
      instance.position = this.position;
      instance.zIndex = this.zIndex;
      instance.style = this.style;
      instance.classList = this.tooltipClass.split(' ');

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case TooltipPosition.BOTTOM: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case TooltipPosition.TOP: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case TooltipPosition.RIGHT: {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.LEFT: {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setHideTooltipTimeout() {
    this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
