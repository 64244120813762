import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconModule, PipesModule } from '@ayn-ui/lib/modules';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'aayn-ad-preview-header',
  templateUrl: './ad-preview-header.component.html',
  imports: [PipesModule, TranslateModule, IconModule, NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdPreviewHeaderComponent {
  @Input() businessName?: string;

  @Input() profileImage?: string;

  @Input() description = 'Sponsored';

  @Input() displayImage = true;
}
