import { Bing } from '@core/models';
import { makeQuery } from '@core/queries';

type Output = { getGoogleCredentialId: string };

export const getGoogleCredentialId = makeQuery<Output>(`
query GetGoogleCredentialId {
  bing {
    getGoogleCredentialId
  }
}
`);
