import { InvoiceItem } from '@core/models';

import { makeQuery } from '../types';

type Output = InvoiceItem[];

export const invoices = makeQuery<Output>(`
  query Invoices {
    invoices {
      id
      notes
      total
      subTotal
      date
      status
      currency
    }
  }
`);
