import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Input = Facebook.Dashboard.IGetAdsetsOrAdRequest;
type Output = {
  ads: {
    edges: {
      node: Facebook.Dashboard.IGetAdsetsResponse & {
        creative: {
          id: string;
          name: string;
          thumbnail: string;
        };
      };
    }[];
  };
};

export const getAds = makeQuery<Output, Input>(`
query GetFacebookAds(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $name: String
  $nodeId: String
  $dateRange: DateRangeInput
) {
  facebook {
    ads (
      nodeId: $nodeId
      first: $first
      after: $after
      last: $last
      before: $before,
      name: $name,
      dateRange: $dateRange 
      ) {
        edges {
          node {
            id
            name
            adsetId
            status
            creative {
              id
              name
              thumbnail
            }
            insights {
              costPerClick
              clicks
              averageCost1KImpressions
              averageCost1KReach
              spend
              impressions
              ctr
              actions {
                type
                value
              }
              reach
              startDate
              endDate
              gender
              age
              country
            }
          }
        }
      }
    }
  }
`);
