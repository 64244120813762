import { fromCompare, Ord } from 'fp-ts/Ord';
import { Comparer } from '@core/models/ord';
import { isOrd } from '@core/utils/ord/is-ord';
import { BiPredicate } from '@core/models/function';
import { Eq } from 'fp-ts/Eq';

/**
 * Converts comparer function to Order.
 *
 * @param {(Ord<T> | Comparer<T>)} ordOrComparer: Order or comparer function
 */
export function toOrd<T>(ordOrComparer: Ord<T> | Comparer<T>): Ord<T> {
  if (isOrd<T>(ordOrComparer)) return ordOrComparer;
  return fromCompare(ordOrComparer);
}

/**
 * Creates order generator function with equals function.
 *
 * @param {(BiPredicate<T> | Eq<T>)} equalsFn
 */
export function toOrdWithEquality<T>(equalsFn: BiPredicate<T> | Eq<T>) {
  /**
   * Converts comparer function to Order.
   *
   * @param {(Ord<T> | Comparer<T>)} ordOrComparer: Order or comparer function
   */
  return function _toOrdWithEquality(ordOrComparer: Ord<T> | Comparer<T>): Ord<T> {
    if (isOrd<T>(ordOrComparer)) return ordOrComparer;

    equalsFn = typeof equalsFn === 'function' ? equalsFn : equalsFn.equals;

    return {
      equals: equalsFn,
      compare: ordOrComparer
    };
  };
}
