import { Linkedin, SmartCampaign } from '@core/models';
import { CursorPaginatedQueryResult, makeMutation } from '@core/queries/types';

const queryField = 'campaignsSmartSorting';
type Input = Linkedin.Dashboard.ICampaignRequest;
type Output = CursorPaginatedQueryResult<
  typeof queryField,
  SmartCampaign.Client.SmartCampaignResult<Linkedin.Dashboard.ILinkedInCampaignOutput>
>;

export const campaignsSmartSorting = makeMutation<Output, Input>(`
query CampaignsLinkedin(
  $after: String
  $first: Int
  $startDate: SafeInt!
  $endDate: SafeInt
  $names: [String!]
  $statuses: [LinkedInCampaignStatus!]
  ) {
  linkedin {
    ${queryField}(
      after: $after
      first: $first
      startDate: $startDate
      endDate: $endDate
      names: $names
      statuses: $statuses
    ) {
      edges {
        cursor
        node {
          isSmartCampaign
          externalId
          status
          name
          budget
          budgetPeriod
          dateRange {
            start
            end
          }
          conversionValue
          conversion
          impressions
          clicks
          costPerClick
          totalSpent
          currencyCode
          campaignGroup
          
          type
          costType
          objectiveType
          optimizationTargetType
          targetingCriteria
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
`);
