import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ChargebeeService, ChargebeeState, CURRENCIES, MySubscription } from '@core/index';
import { Select } from '@ngxs/store';

@Component({
  selector: 'aayn-package',
  templateUrl: 'package.component.html'
})
export class PackageComponent implements OnInit {
  @Select(ChargebeeState.subscription) subscription$?: Observable<MySubscription>;

  @Select(ChargebeeState.isAppsumoUser) isAppSumoUser$!: Observable<boolean>;

  spend = 0;

  CURRENCIES = CURRENCIES;

  constructor(private chargebeeService: ChargebeeService) {}

  ngOnInit() {
    this.chargebeeService.getCurrentTermSpends$().subscribe((result) => {
      this.spend = result.data;
    });
  }
}
