import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ConnectAdAccountMap } from '@core/models';
import { GetBusinesses, UpdateBusinessFromServer } from '@core/state/business.state';
import { AdAccount, SetAdAccountForPlatform } from '@core/state';
import { SharedAdAccountService } from '@core/shared';
import { switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ConnectAdAccountService {
  constructor(private router: Router, private store: Store, private sharedAdAccountService: SharedAdAccountService) {}

  connectAdAccount<T extends AdAccount.AdAccountPlatforms>(
    platform: T,
    adAccount: ConnectAdAccountMap<T>,
    modalRef: NgbModalRef
  ) {
    const url = this.router.url;
    let stream: Observable<any>;
    if (url.indexOf('main/create-ad') !== -1) {
      stream = this.sharedAdAccountService.connectAdAccount(platform, adAccount).pipe(
        switchMap(() => this.store.dispatch(new GetBusinesses())),
        switchMap(() => this.store.dispatch(new UpdateBusinessFromServer()))
      );
    } else {
      stream = this.store.dispatch(SetAdAccountForPlatform.create(platform, adAccount));
    }
    stream.pipe(take(1)).subscribe(() => modalRef!.close());
  }
}
