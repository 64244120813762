import { Settings } from '@core/models';

import { makeMutation } from '../types';

type Input = { brandingInput: Settings.BrandingInput };
type Output = Settings.BrandingOutput;

export const createCustomBranding = makeMutation<Output, Input>(`
mutation CreateCustomBranding($brandingInput: BrandingInputDto!) {
  createCustomBranding(brandingInput: $brandingInput) {
    mainColor
    additionalColor
    paragraphColor
    backgroundColor
  }
}
`);
