import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../../pipes';
import { AynUITranslateModule } from '../../translate/translate.module';
import { IconModule } from '../icon/';
import { LoaderModule } from '../loader';
import { OverlayModule } from '../overlay';
import { TooltipModule } from '../tooltip';
import { Option } from './option';
import { OptionFilterPipe } from './option-filter.pipe';
import { Select } from './select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    //TODO: Select module e forRoot yapılıp providerlar öyle alınabilir.
    // Bu şekilde SelectModule lazy bir module e import edildiğinde ICON_SRC_PREFIX ezilecektir.
    IconModule.forRoot(),
    PipesModule,
    TooltipModule,
    AynUITranslateModule,
    LoaderModule
  ],
  exports: [Select, Option, OptionFilterPipe],
  declarations: [Select, Option, OptionFilterPipe],
  providers: []
})
export class SelectModule {}
