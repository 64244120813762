import { ToastrService } from 'ngx-toastr';

import { Component, OnInit } from '@angular/core';
import { AuthService, ChargebeeService } from '@core/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aayn-canceled-subscription-modal',
  templateUrl: 'canceled-subscription-modal.component.html'
})
export class CanceledSubscriptionModalComponent implements OnInit {
  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private modalService: NgbModal,
    private chargebeeService: ChargebeeService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {}

  abortCancellation() {
    this.chargebeeService.abortCancellationSubscription$().subscribe(() => {
      this.toastrService.success('Reactivation successful.');

      this.modal.dismiss();
    });
  }

  logout() {
    this.authService.logoutWithOperations(() => this.modalService.dismissAll());
  }
}
