<div class="aayn-mobile-nav">
  <ul>
    <li
      class="list"
      *ngFor="let route of routes"
      routerLinkActive="active"
      [ngClass]="{
        'hover:disabled disabled': route.disabled || !hasAnyBusiness || (route.needAdminAccount && isInviterUser),
        'create-ad': route.icon === 'create-ad'
      }"
      [title]="route.name | translate"
    >
      <a
        (click)="route.name === 'Menu' ? openMobileMenu() : null"
        [routerLink]="
          route.disabled || !hasAnyBusiness || route.name === 'Menu' || (route.needAdminAccount && isInviterUser)
            ? null
            : route.url
        "
      >
        <ayn-icon *ngIf="route.icon" [name]="route.icon"></ayn-icon>
      </a>
    </li>
  </ul>
</div>
