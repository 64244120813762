import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Platforms } from '@core/models';
import { IntercomService } from '@core/services';
import { findAndRequestSubmit } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import {
  BingValidationService,
  CreateAdState,
  FacebookValidationService,
  GoogleValidationService,
  SelectedAdType
} from '@pages/create-ad/state';
import { LinkedinValidationService } from '@pages/create-ad/state/platforms/linkedin';
import { SmartCampaignValidationService } from '@pages/create-ad/state/platforms/smart-campaign/smart-campaign.validation';
import { TiktokValidationService } from '@pages/create-ad/state/platforms/tiktok';

@Component({
  selector: 'aayn-select-audience',
  templateUrl: './select-audience.component.html',
  animations: [fadeInOnEnterAnimation()]
})
export class SelectAudienceComponent implements OnInit {
  @Select(CreateAdState.selectedPlatform)
  selectedPlatform$?: Observable<Platforms | undefined>;

  @Select(CreateAdState.selectedAdType) selectedAdType$?: Observable<SelectedAdType>;

  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  protected Platforms = Platforms;

  constructor(
    private facebookValidationService: FacebookValidationService,
    private googleValidationService: GoogleValidationService,
    private bingValidationService: BingValidationService,
    private linkedinValidationService: LinkedinValidationService,
    private smartCampaignValidationService: SmartCampaignValidationService,
    private el: ElementRef<HTMLElement>,
    private intercomService: IntercomService,
    private tiktokValidationService: TiktokValidationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const selectedPlatform = this.store.selectSnapshot(CreateAdState.selectedPlatform);

    if (selectedPlatform == Platforms.SmartCampaign) {
      this.intercomService.startTour('CREATE_AD_STEP_3_FOR_SMART_CAMPAIGN');
    } else {
      this.intercomService.startTour('CREATE_AD_STEP_3');
    }
  }

  nextStep(selectedPlatform: Platforms) {
    findAndRequestSubmit(this.el.nativeElement);
    this.nextStepValidator(selectedPlatform)
      .pipe(take(1))
      .subscribe((isValid) => {
        if (isValid) {
          this.next.emit();
        }
      });
  }

  nextStepValidator(selectedPlatform: Platforms): Observable<boolean> {
    switch (selectedPlatform) {
      case Platforms.Meta:
        return this.facebookValidationService.audienceValid$;
      case Platforms.Google:
        return this.googleValidationService.audienceValid$;
      case Platforms.Bing:
        return this.bingValidationService.audienceValid$;
      case Platforms.LinkedIn:
        return this.linkedinValidationService.audienceValid$;
      case Platforms.SmartCampaign:
        return this.smartCampaignValidationService.audienceValid$;
      case Platforms.TikTok:
        return this.tiktokValidationService.audienceValid$;

      default:
        return of(false);
    }
  }
}
