<section class="aayn-audience">
  <header
    class="aayn-audience--header"
    [ngClass]="{ 'aayn-audience--header__has-warning': showPotentialDailyReachWarning }"
  >
    <section class="aayn-audience--header-right">
      <p class="aayn-audience--header-info" *ngIf="showPotentialDailyReach">
        Potential Daily Reach:&nbsp;<strong>{{ potentialDailyReach | number }} people</strong>
        <ayn-icon [name]="showPotentialDailyReachWarning ? 'warning' : 'user'"></ayn-icon>
      </p>

      <p class="aayn-audience--header-warning-message" *ngIf="showPotentialDailyReachWarning" [@enter] [@leave]>
        {{ potentialDailyReachWarningMessage }}
      </p>
    </section>
  </header>

  <section class="aayn-audience--content">
    <ng-content></ng-content>
  </section>
</section>
