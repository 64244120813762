<aayn-manuel-audience-setup
  [showPotentialDailyReach]="true"
  [potentialDailyReach]="estimatedReach"
  [showPotentialDailyReachWarning]="_form.submitted && estimatedReach == 0"
  [potentialDailyReachWarningMessage]="'Facebook requires a minimum audience size of 500 people.' | translate"
>
  <form
    [formGroup]="form"
    #_form="ngForm"
    [ngClass]="{ 'estimate_reach-invalid': estimatedReach == 0 }"
    extraClassName="estimate_reach-invalid"
  >
    <aayn-audience-group>
      <aayn-audience-item
        title="Location"
        description="Choose your target locations. Your ads will only be shown to people in these areas."
      >
        <aayn-location-selection--facebook [form]="form"></aayn-location-selection--facebook>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group class="aayn-audience--height:300">
      <aayn-audience-item
        [title]="'Age Range' | translate"
        description="Define your target age range. Your ads will only appear to individuals within this age bracket."
      >
        <aayn-audience-age-range
          [value]="adCreationModel.adSet.targeting.minAge"
          [maxValue]="adCreationModel.adSet.targeting.maxAge"
          (ageRangeChanged)="ageRangeChanged($event)"
          [texts]="{ age: 'Age' | translate }"
        ></aayn-audience-age-range>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Gender' | translate"
        description="Select the gender of your target audience. Your ads will be displayed exclusively to the selected gender."
      >
        <aayn-gender-selection--facebook [form]="form"></aayn-gender-selection--facebook>
      </aayn-audience-item>

      <aayn-audience-item
        [title]="'Audience Language' | translate"
        description="Specify your target language(s). Your ads will only be shown to users who speak the selected language(s)."
      >
        <aayn-language-selection--facebook [form]="form"></aayn-language-selection--facebook>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item
        [title]="'Detailed Targeting' | translate"
        description="Determine your target audience to avoid ad display for uninterested individuals."
      >
        <aayn-detailed-targeting--facebook [form]="form"></aayn-detailed-targeting--facebook>
      </aayn-audience-item>
    </aayn-audience-group>

    <aayn-audience-group>
      <aayn-audience-item [title]="'Audience Name' | translate" [flexed]="true">
        <ayn-form-field [style.max-width]="'65%'" class="ml:auto">
          <input
            type="text"
            ayn-input
            [placeholder]="'Write a audience name' | translate"
            formControlName="name"
            [ngModel]="adCreationModel.adSet.name"
          />
        </ayn-form-field>
      </aayn-audience-item>
    </aayn-audience-group>
  </form>
</aayn-manuel-audience-setup>
